import { useEffect, useState } from "react"

const useBrowserAgent = () => {
	const [browser, setBrowser] = useState({
		isChrome: false,
		isEdge: false,
		isFirefox: false,
		isIE: false,
		isOpera: false,
		isSafari: false,
	})

	useEffect(() => {
		const userAgent = navigator.userAgent

		// Browser detection
		const chrome =
			/chrome|chromium|crios/i.test(userAgent) &&
			!/edge|edg|opr/i.test(userAgent)
		const firefox = /firefox|fxios/i.test(userAgent)
		const safari = /^((?!chrome|android).)*safari/i.test(userAgent)
		const opera = /opera|opr|opios/i.test(userAgent)
		const ie = /msie|trident/i.test(userAgent)
		const edge = /edge|edg/i.test(userAgent)

		setBrowser({
			isChrome: chrome,
			isEdge: edge,
			isFirefox: firefox,
			isIE: ie,
			isOpera: opera,
			isSafari: safari,
		})
	}, [])

	return browser
}

export default useBrowserAgent
