import { useMemo } from "react"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { LoanEvent } from "../../types/LoanEventTypes"
import { LoanListedRow } from "./LoanListedRow"
import { IdentifierToPaymentToken } from "flowty-sdk"
import { LoanFundedRow } from "./LoanFundedRow"
import { LoanRepaidRow } from "./LoanRepaidRow"
import { LoanDelistedRow } from "./LoanDelistedRow"
import { LoanDefaultedRow } from "./LoanDefaultedRow"

interface Props {
	loan: LoanEvent
}

export const LoanRow: React.FunctionComponent<Props> = ({ loan }) => {
	const loanListingRow = useMemo(() => {
		switch (loan.type) {
			case "LISTED":
				return (
					<LoanListedRow
						borrower={loan.data.flowtyStorefrontAddress}
						amount={loan.data.amount}
						paymentTokenType={
							IdentifierToPaymentToken[loan.data.paymentTokenType]
						}
						interestRate={loan.data.interestRate}
						term={loan.data.term}
					/>
				)
			case "FUNDED":
				return <LoanFundedRow lender={loan.data.lender} />
			case "REPAID":
				return (
					<LoanRepaidRow
						borrower={loan.data.borrower}
						repaymentAmount={loan.data.repaymentAmount}
						tokenIdentifier={loan.paymentTokenName}
					/>
				)
			case "DELISTED":
				return <LoanDelistedRow listedBy={loan.data.flowtyStorefrontAddress} />
			case "SETTLED":
				return (
					<LoanDefaultedRow
						borrower={loan.data.borrower}
						repaymentAmount={loan.data.repaymentAmount}
						tokenIdentifier={loan.paymentTokenName}
					/>
				)
		}
	}, [loan])

	return (
		<div className='flex flex-col md:flex-row gap-2 md:gap-6'>
			<ListingIndicatorPill symbol={"LOAN"} />
			{loanListingRow}
		</div>
	)
}
