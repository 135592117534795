import numeral from "numeral"

interface SpotPriceValueProps {
	value: number
	includeParenthesis?: boolean
	classNames?: string
}

export const SpotPriceValue = ({
	value,
	includeParenthesis,
	classNames,
}: SpotPriceValueProps) => {
	const formattedValue = numeral(value).format("0,0.00")

	return (
		<span className={`${classNames}`}>
			{includeParenthesis ? `($${formattedValue})` : `$${formattedValue}}`}
		</span>
	)
}
