import { Dialog, Transition } from "@headlessui/react"
import { FlowtyButton } from "ds-flowty"
import { Fragment } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PromotionButton } from "../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import { useBreakpoint } from "../utils/useBreakpoint/useBreakpoint"
import { ReactComponent as CloseIcon } from "./../assets/close-x.svg"

interface PromotionalMessageProps {
	promotionId: string
	title: string
	message: React.ReactNode
	primaryButton?: PromotionButton
	secondaryButton?: PromotionButton
	image: string
	handleClose: () => void
	isVisible: boolean
}

export const PromotionalMessage: React.FC<PromotionalMessageProps> = ({
	promotionId,
	title,
	message,
	primaryButton,
	secondaryButton,
	image,
	handleClose,
	isVisible,
}) => {
	const breakpoint = useBreakpoint()
	const location = useLocation()
	const navigate = useNavigate()

	return (
		<Transition appear show={isVisible} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-[1000]'
				static
				onClose={() => null}
			>
				<Transition.Child
					enter='transition duration-000 ease-out'
					enterFrom='transform scale-95 opacity-0'
					enterTo='transform scale-100 opacity-100'
					leave='transition duration-75 ease-out'
					leaveFrom='transform scale-100 opacity-100'
					leaveTo='transform scale-95 opacity-0'
					as={Fragment}
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div className='fixed w-screen max-md:h-full max-md:overflow-hidden inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-1000'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='shadow-xl max-md:min-h-[100svh] max-md:h-[100svh] flex flex-col w-[90vw] h-full md:h-auto max-md:w-full md:w-[600px] lg:w-[764px] bg-[#04070B] md:rounded-[10px] md:border border-[#343A40]'>
								<Dialog.Title
									as='div'
									className='flex flex-row justify-between px-[25px] py-[20px] 3xl:px-[39px] 3xl:py-[36px]'
								>
									<h2 className='text-white font-montserrat text-12px md:text-[20px] 3xl:text-[24px] font-black leading-none [text-shadow:0px_4px_4px_rgba(0,0,0,0.25)]'>
										{title}
									</h2>
									<CloseIcon
										className='cursor-pointer w-[22px] h-[22px]'
										onClick={handleClose}
									/>
								</Dialog.Title>

								<div className='max-md:flex-grow overflow-y-scroll md:max-h-[75vh] 3xl:max-h-full flex flex-col py-4 px-[25px] md:py-[36px] md:px-[38px] gap-[10px] md:gap-[40px] border border-r-0 border-l-0 border-t-solid [border-top-color:#343A40] border-b-solid [border-bottom-color:#343A40]'>
									<img
										src={image}
										alt='promotion'
										className='w-auto h-auto'
										aria-label={promotionId}
									/>

									{message}
								</div>

								<div className='flex md:h-full justify-center items-center gap-[24px] py-[20px] 3xl:py-[30px] px-[25px] md:px-[38px] 3xl:px-[24px]'>
									{secondaryButton && (
										<FlowtyButton
											className='w-full text-[10px] py-0 3xl:text-[14px]'
											text={
												breakpoint === "xs"
													? secondaryButton?.mobileLabel
													: secondaryButton?.label
											}
											variant={"secondary"}
											bgColor='white'
											onClick={() => {
												window.open(secondaryButton?.redirectUrl)
											}}
										/>
									)}
									{primaryButton && (
										<FlowtyButton
											className='w-full text-[10px] py-0 3xl:text-[14px]'
											text={
												breakpoint === "xs"
													? primaryButton.mobileLabel
													: primaryButton?.label
											}
											variant={"primary"}
											onClick={() => {
												if (location.pathname === "/create") {
													handleClose()
													return
												}
												navigate(primaryButton?.redirectUrl)
												handleClose()
											}}
										/>
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
