import { useField } from "formik"
import React, { useEffect, useRef } from "react"
import { FlowtyAccordion } from "../../../../FlowtyAccordion"
import { FlowtyDiscountBanner } from "../../../../FlowtyDiscountBanner"
import { FlowtyListBoxTokenSelector } from "../../../../FlowtyListboxTokenSelector/FlowtyListboxTokenSelection"

import { AccountSummaries, AccountSummary, Flowty } from "flowty-sdk"
import { FlowtyButton } from "../../../../FlowtyButton"
import {
	FlowtyWalletSelector,
	ValidTokenReceiverFilter,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { FlowtyFMVIndicator } from "../../../../Shared/Molecules/FlowtyFMVIndicator"
import {
	StorefrontAcceptOfferOrderType,
	ValuationData,
} from "../../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { NftPrice } from "../../common/NftPrice"
import { IS_MAINNET } from "../../../contexts/FlowtyListingModalContext/utils/constants"
import {
	allowDecimalInDuration,
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../../utils/constants"
import { FlowtyNumericInput } from "../../../../FlowtyNumericInput"
import { ChildWalletListing } from "../../common/ChildWalletListing"

interface SaleListingProps {
	isDapper: boolean
	accountSummaries: AccountSummaries
	valuationData: ValuationData
	saleListingError: boolean
	orderData: StorefrontAcceptOfferOrderType
	flowty: Flowty
}

export const SaleListing: React.FunctionComponent<SaleListingProps> = ({
	isDapper,
	accountSummaries,
	valuationData,
	saleListingError,
	orderData,
	flowty,
}) => {
	const inputErrorRef = useRef<HTMLDivElement>(null)
	const serviceTermRef = useRef<HTMLDivElement>(null)
	const salePrice = Number(orderData.salePrice)

	useEffect(() => {
		if (saleListingError && (salePrice <= 0.01 || salePrice > 10000000)) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
			return
		}
		if (saleListingError) {
			if (inputErrorRef?.current?.scrollIntoView) {
				serviceTermRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [saleListingError])

	const [tokenType] = useField({ name: `tokenType` })
	const [priceField] = useField({ name: `saleListingValue` })
	const {
		saleFees,
		isChildAccountAsset,
		hasChildAccounts,
		selectedAccount,
		setSelectedAccount,
		spotPrice,
		hasOffer,
		acceptOffer,
		isLoadingValuation,
		isLoadingGetFees,
		isMainnet,
	} = useFlowtyListingModalContext()

	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	const priceValue = priceField.value

	const flowtyFee = priceValue ? Number(saleFees?.flowty) ?? 0.0 : 0.0
	const flowtyRate = priceValue > 0 ? flowtyFee / priceValue : 0

	const royaltyFee = priceValue ? Number(saleFees?.royalties) ?? 0.0 : 0.0
	const royaltyRate = priceValue > 0 ? royaltyFee / priceValue : 0

	const receivedValue = priceValue - (royaltyFee + flowtyFee)

	const isInvalidListingDuration =
		Number(orderData?.storefrontListingDuration) < MIN_LISTING_DURATION ||
		orderData?.storefrontListingDuration === undefined ||
		Number(orderData?.storefrontListingDuration) > MAX_LISTING_DURATION

	return (
		<div className='w-full h-full flex flex-col' ref={inputErrorRef}>
			<div className='w-full h-full flex flex-col justify-between gap-4'>
				<div className='flex flex-col gap-6'>
					{hasChildAccounts && (
						<FlowtyWalletSelector
							selected={selectedAccount}
							setSelected={setSelectedAccount}
							label='Pay To'
							summaries={accountSummaries}
							listingType={"storefront"}
							disabledMessage={walletDisabledMessage.payTo}
							selectorFilter={
								new ValidTokenReceiverFilter({
									flowty,
									listingType: "storefront",
								})
							}
						/>
					)}
					{hasChildAccounts && isChildAccountAsset && <ChildWalletListing />}
					<div className='flex flex-col gap-4'>
						<p className='text-base font-semibold text-white'>Listing Price</p>
						<div className='flex gap-3'>
							<div className='w-full'>
								<FlowtyListBoxTokenSelector
									name='tokenType'
									orderType={"storefront"}
									isDapper={isDapper}
									selectedAccount={selectedAccount as AccountSummary}
									flowty={flowty}
								/>
							</div>
							<FlowtyNumericInput
								name={`saleListingValue`}
								valueError={
									saleListingError && (salePrice < 0.01 || salePrice > 10000000)
								}
								inputError={
									saleListingError && (salePrice < 0.01 || salePrice > 10000000)
								}
								errorMessage={
									saleListingError && (salePrice < 0.01 || salePrice > 10000000)
										? "Between 0.01 - 10000000"
										: null
								}
							/>
						</div>
					</div>
					<div>
						<FlowtyAccordion
							items={[
								{
									addOn: (
										<div className='flex items-start font-montserrat space-x-2'>
											{isLoadingGetFees ? (
												<div className='w-[60px] h-[15px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
											) : (
												<>
													<span className='font-bold'>-</span>
													<NftPrice
														price={royaltyFee}
														token={tokenType.value}
														spotPriceValue={
															Number(spotPrice?.value) * royaltyFee
														}
														toolTipCustomId='saleListingCreatorRoyalty'
													/>
												</>
											)}
										</div>
									),
									content: (
										<div className='text-neutral-500 font-montserrat'>
											Transferred directly to the creator of an NFT collection.
											This is not a Flowty fee.{" "}
											<a
												href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
												target='_blank'
												className='text-[#04E5A3] font-montserrat underline'
												rel='noreferrer'
											>
												Learn More
											</a>
										</div>
									),
									id: "item-1",
									title: `Creator Royalty (${(royaltyRate * 100).toFixed(2)}%)`,
								},
								{
									addOn: (
										<div className='flex items-start space-x-2'>
											{isLoadingGetFees ? (
												<div className='w-[60px] h-[15px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
											) : (
												<>
													<span className='font-bold font-montserrat'>-</span>
													<NftPrice
														price={flowtyFee}
														token={tokenType.value}
														spotPriceValue={
															Number(spotPrice?.value) * flowtyFee
														}
														toolTipCustomId='saleListingFlowtyFee'
													/>
												</>
											)}
										</div>
									),
									content: (
										<div className='text-neutral-500 font-montserrat'>
											A fee charged by Flowty for providing the services on the
											platform.{" "}
											<a
												href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
												target='_blank'
												className='text-[#04E5A3] font-montserrat underline'
												rel='noreferrer'
											>
												Learn More
											</a>
										</div>
									),
									id: "item-2",
									title: `Flowty Fee (${(flowtyRate * 100).toFixed(2)}%)`,
								},
							]}
						/>
					</div>
					<hr className='border-[#495057]'></hr>
					<div className='flex justify-between text-white text-sm font-normal ml-1'>
						<p>You&apos;ll Receive</p>
						<div className='flex flex-col items-end'>
							{isLoadingGetFees ? (
								<div className='w-[60px] h-[15px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
							) : (
								<NftPrice
									price={receivedValue}
									token={tokenType.value}
									spotPriceValue={Number(spotPrice?.value) * receivedValue}
									toolTipCustomId='saleListingReceivedValue'
								/>
							)}
						</div>
					</div>
					<div className='w-full flex items-center mt-auto justify-between'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Listing Duration
							</p>
						</div>
						<div className='flex gap-3 items-center'>
							<div className='w-[9.125rem]'>
								<FlowtyNumericInput
									name={`storefrontListingDuration`}
									valueError={saleListingError && isInvalidListingDuration}
									inputError={saleListingError && isInvalidListingDuration}
									decimalsAllowed={allowDecimalInDuration(IS_MAINNET)}
									errorMessage={
										saleListingError && isInvalidListingDuration
											? `Between ${MIN_LISTING_DURATION} and ${MAX_LISTING_DURATION} days`
											: null
									}
								/>
							</div>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Days
							</p>
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-6' ref={serviceTermRef}>
					{isLoadingValuation ? (
						<div className='w-full h-[28px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
					) : (
						<>
							{valuationData?.usdValue ? (
								<FlowtyFMVIndicator
									source={valuationData.source}
									value={valuationData?.usdValue}
								/>
							) : null}
						</>
					)}
					{valuationData?.percentage ? (
						<FlowtyDiscountBanner
							source={valuationData.source}
							percent={valuationData?.percentage || 0}
						/>
					) : null}
					{hasOffer ? (
						<FlowtyButton
							onClick={acceptOffer}
							text='ACCEPT BEST OFFER'
							variant='secondary'
							bgColor='white'
							btnSize='small'
						/>
					) : null}
				</div>
			</div>
		</div>
	)
}
