import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { getTokenFromType, SupportedTokens } from "flowty-common"
import { Fragment, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { AccountSummary } from "flowty-sdk"
import { User } from "../../models/user"
import { FundingAvailableData } from "../../services/flow/FundingService"
import { actions as Mixpanel } from "../../util/Mixpanel"
import {
	getUserFriendlyErrorMessage,
	invariantFramesError,
} from "../../util/errorUtil"
import { getBestFtPrivatePath } from "../../util/getBestPrivatePath"
import { flowscanBaseURL } from "../../util/settings"
import { selectedWalletFromLoggedUser } from "../Modals/ListedActions/MultiActionModal/Reducer/checkAccount"
import {
	TokenProviderFilter,
	WalletSelector,
	walletDisabledMessage,
} from "../Modals/Listings/shared/WalletSelector"
import TxNotSuccessful from "../Modals/listing/TxNotSuccessful"
import RepayReadyToBePlaced from "../Modals/repay/RepayReadyToBePlaced"
import { TxSuccessful } from "../Modals/shared/TxSuccessful"
import { flowty } from "../../config/config"
import AuthStore from "../../stores/AuthStore"

interface Props {
	className?: string
	funding: FundingAvailableData
	authStore: AuthStore | undefined
}

export const RepayLoan = ({ className, funding, authStore }: Props) => {
	const [show, setShow] = useState(false)
	const [loader, setLoader] = useState(false)
	const [activeIndex, setActiveIndex] = useState<number>(0)
	const [userSelectedWallet, setUserSelectedWallet] = useState<AccountSummary>(
		selectedWalletFromLoggedUser(authStore?.loggedUser as User)
	)
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [error, setError] = useState<string | null>(null)
	const [sealed, setIsSealed] = useState(false)

	const handleSetError = (err: string): void => {
		// This seems to be an error we can ignore. It happens when fcl already has a frame present for
		// the logged-in user to approve/reject. If another window is attempted to be opened this shows up.
		if (err && err.includes(invariantFramesError)) {
			return
		}

		setError(err)
	}

	const token =
		"paymentTokenType" in funding.listingAvailable
			? getTokenFromType(funding.listingAvailable.paymentTokenType)
			: SupportedTokens.FLOW

	//FT PROVIDER PATH -> FT PROVIDER PATH FOR THE REPAYMENT
	let ftPrivatePathIdentifier = ""

	useEffect(() => {
		const providerPaths =
			userSelectedWallet?.tokens?.[funding.listingAvailable.paymentTokenType]
				.providerPaths || []
		if (userSelectedWallet.address !== authStore?.loggedUser?.addr) {
			ftPrivatePathIdentifier = getBestFtPrivatePath(providerPaths)
		}
	}, [userSelectedWallet])

	const closeModal = (): void => {
		setShow(false)
		setTransactionID(null)
		setError(null)
		Mixpanel.track("Repay Modal Closed", {
			funding,
			step: activeIndex,
		})
		window.location.reload()
		setIsSealed(false)
	}
	const showModal = (): void => {
		setShow(true)
		Mixpanel.track("Repay Modal Opened", {
			funding,
		})
	}

	const repayLoan = async (prevSliding: boolean = false): Promise<void> => {
		setLoader(true)
		setError(null)
		setTransactionID(null)
		setIsSealed(false)

		const tokenInfo = flowty.tokens.getTokenInfo(token as SupportedTokens)

		try {
			await flowty.repayLoan({
				address: process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT || "0",
				ftPrivatePathIdentifier,
				ftProviderAddress: userSelectedWallet.address,
				fundItemID: funding.fundingResourceID || 0,
				token: tokenInfo,
				txAvailableCallback: setTransactionID,
			})

			await Mixpanel.track("Repayment Submit Successfully", {
				funding,
			})

			setIsSealed(true)
		} catch (err) {
			handleSetError(`${err}`)
			Mixpanel.track("Repayment Submit Error", {
				err,
				funding,
			})
		}
		setLoader(false)

		if (prevSliding) {
			setActiveIndex(activeIndex)
		} else {
			setActiveIndex(activeIndex + 2)
		}
	}

	return (
		<div className={`${className}`}>
			<button
				className='w-[10em] md:w-[12em]  flex justify-center items-center uppercase  px-[1.25rem] py-2 bg-primary hover-button border-transparent rounded-md border-2 text-base font-extrabold text-black'
				onClick={showModal}
			>
				Repay
			</button>

			<Transition.Root show={show} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto'>
						<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							>
								<Dialog.Panel className='relative transform overflow-hidden rounded-lg text-left Max-w-full md:max-w-4xl shadow-xl transition-all sm:my-8 sm:w-full flowty-modal loan-listing-modal bg-black'>
									<Dialog.Title
										as='div'
										className='border-b border-modal px-4 py-4 w-full flex justify-between items-center'
									>
										<div>
											<span className='text-white text-2xl leading-6 font-extrabold px-1'>
												Confirm
											</span>
											<span className='text-primary text-2xl leading-6 font-extrabold px-1'>
												Repayment
											</span>
										</div>
										<XIcon
											onClick={closeModal}
											className='h-8 w-8 cursor-pointer hover:fill-gray-400'
										/>
									</Dialog.Title>
									{!sealed &&
										Object.values(authStore?.loggedUser?.accountSummaries || {})
											.length > 1 && (
											<div className='px-3 pt-4'>
												<WalletSelector
													label='Pay With'
													disabledMessage={walletDisabledMessage.payWith}
													selected={userSelectedWallet as AccountSummary}
													setSelected={(account: AccountSummary) =>
														setUserSelectedWallet(account)
													}
													selectorFilter={
														new TokenProviderFilter({
															tokenName:
																funding.listingAvailable.paymentTokenName,
														})
													}
												/>
											</div>
										)}
									<>
										{loader ? (
											<div className='my-2 py-4'>
												<div className='w-full flex flex-col justify-center items-center h-full'>
													{transactionID ? (
														<p className='h-12'>
															Follow your transaction on&nbsp;
															<a
																className='modal-external-link'
																href={`${flowscanBaseURL}/${transactionID}`}
																target='_blank'
																rel='noreferrer'
															>
																Flowscan
															</a>
														</p>
													) : (
														<div className='h-12' />
													)}
													<span className='text-lg font-montserrat-bold font-bold'>
														Processing...
													</span>
													<Oval height={150} width={150} />
												</div>
											</div>
										) : error ? (
											<div className='my-2 py-4'>
												<TxNotSuccessful
													errorMsg={getUserFriendlyErrorMessage(error)}
													onTryAgainClick={() => repayLoan(true)}
												/>
											</div>
										) : (
											sealed && (
												<div className='my-2 py-4'>
													<TxSuccessful msg='Loan was successfully repaid!' />
												</div>
											)
										)}
									</>
									{!sealed && (
										<RepayReadyToBePlaced
											funding={funding}
											onFundClick={() => repayLoan()}
											userSelectedWallet={userSelectedWallet}
											loading={loader}
										/>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}
