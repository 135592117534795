const fcl = require("@onflow/fcl")

export class CheckerService {
	// eslint-disable-next-line class-methods-use-this
	verifyAddress = async (address: string): Promise<boolean> => {
		let isVerified = false
		try {
			const account = await fcl.account(address)
			if (account.address) isVerified = true
		} catch (error) {
			console.log(`Couldn't find account [${address}] for private listing`)
		}
		return isVerified
	}
}
