/* eslint-disable @typescript-eslint/explicit-function-return-type */
import mixpanel, { Dict } from "mixpanel-browser"

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN

mixpanel.init(mixpanelToken as string)

export const actions = {
	alias: (id: string) => {
		mixpanel.alias(id)
	},
	identify: (id: string) => {
		mixpanel.identify(id)
	},
	people: {
		set: (props: Dict) => {
			mixpanel.people.set(props)
		},
	},
	track: (name: string, props?: Dict | undefined) => {
		mixpanel.track(name, props)
	},
	track_links: (name: string, props: string) => {
		mixpanel.track_links(name, props)
	},
}
