import { apiURL } from "./../util/settings"
import { auth } from "../firebase"
import axios from "axios"
import { delay } from "../util/delay"
import * as Yup from "yup"
import AuthStore from "../stores/AuthStore"
import { Log } from "../util/Log"

interface EmailSettingResponse {
	settings: EmailSetting[]
}

interface EmailSetting {
	name: string
	title: string
	description: string
	suppressed: boolean
}

export async function getEmailSettings(): Promise<EmailSettingResponse> {
	let authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	if (authToken === "") {
		await delay(1000)
		authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	}

	const url = `${apiURL}/notifications/settings`
	const headers = {
		Authorization: authToken,
	}

	try {
		const response = await axios.get(url, { headers })
		return response.data as EmailSettingResponse
	} catch (error) {
		Log("getEmailSettings", { error })
		return { settings: [] }
	}
}

export const EmailSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Required"),
})

export async function subscribeEmailToNotification(
	email: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
	let authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	if (authToken === "") {
		await delay(1000)
		authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	}

	const url = `${apiURL}/notifications/subscribe`
	const headers = {
		Authorization: authToken,
	}

	try {
		const response = await axios.post(
			url,
			{
				email,
			},
			{ headers }
		)

		return response.data
	} catch (error) {
		console.log("subscribeEmailToNotification", { error })
	}
}

async function updateEmailNotificationSubscriptions({
	resubscribeNames,
	unsubscribeNames,
	email,
}: {
	resubscribeNames: string[]
	unsubscribeNames: string[]
	email?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
	if (!resubscribeNames.length && !unsubscribeNames.length) return
	let authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	if (authToken === "") {
		await delay(1000)
		authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""
	}

	const url = `${apiURL}/notifications/save`
	const headers = {
		Authorization: authToken,
	}

	try {
		const response = await axios.post(
			url,
			{
				email,
				resubscribeNames,
				unsubscribeNames,
			},
			{ headers }
		)
		return response.data
	} catch (error) {
		Log("updateEmailNotificationSubscriptions", { error })
	}
}

export const handleSubscribe = async (
	authStore: AuthStore | undefined,
	email: string,
	subscribeOffers?: boolean,
	subscribeNewsletter?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	try {
		const resubscribeNamesArray = []
		const unsubscribeNamesArray = []

		if (subscribeOffers == true) {
			resubscribeNamesArray.push("offers")
		} else if (subscribeOffers == false) {
			unsubscribeNamesArray.push("offers")
		}
		if (subscribeNewsletter == true) {
			resubscribeNamesArray.push("newsletter")
		} else if (subscribeNewsletter == false) {
			unsubscribeNamesArray.push("newsletter")
		}
		await updateEmailNotificationSubscriptions({
			email: email,
			resubscribeNames: resubscribeNamesArray,
			unsubscribeNames: unsubscribeNamesArray,
		}).then(res => {
			authStore?.setMarketingEmail(email)
			return res
		})
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		Log("handleSubscribe", error)
		return error
	}
}
