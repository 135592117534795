import { FC } from "react"

export interface GlobalAlertProps {
	id: string
	active: boolean
	component: FC
}
export interface GlobalAlertConfig {
	alerts: GlobalAlertProps[]
}

const globalAlertConfig: GlobalAlertConfig = {
	alerts: [],
}

export default globalAlertConfig
