export const InsufficientFundsWarning: React.FunctionComponent = () => {
	return (
		<div className='flex justify-end gap-[4px] items-center text-lightDanger'>
			<svg
				width='19'
				height='18'
				viewBox='0 0 19 18'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.93135 2.78911C8.6195 1.56574 10.3809 1.56574 11.069 2.78911L16.0913 11.7176C16.7662 12.9175 15.8991 14.4001 14.5225 14.4001H4.47791C3.10123 14.4001 2.23414 12.9175 2.90908 11.7176L7.93135 2.78911ZM10.4001 11.7002C10.4001 12.1972 9.99717 12.6002 9.50011 12.6002C9.00305 12.6002 8.60011 12.1972 8.60011 11.7002C8.60011 11.2031 9.00305 10.8002 9.50011 10.8002C9.99717 10.8002 10.4001 11.2031 10.4001 11.7002ZM9.50011 4.50017C9.00305 4.50017 8.60011 4.90311 8.60011 5.40017V8.10017C8.60011 8.59723 9.00305 9.00017 9.50011 9.00017C9.99717 9.00017 10.4001 8.59723 10.4001 8.10017V5.40017C10.4001 4.90311 9.99717 4.50017 9.50011 4.50017Z'
					fill='#FF6969'
				/>
			</svg>
			<div className='text-[12px] font-[400] leading-[12px]'>
				Insufficient Funds
			</div>
		</div>
	)
}
