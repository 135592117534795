import { ListingPillIndicator } from "ds-flowty"
import React, { useContext } from "react"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { FilterPill } from "./FilterPill"

/* eslint-disable import/no-unused-modules */
export const ListingTypeTags: React.FC = () => {
	const { setOrderFilters, orderFilters, setSort } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const orders = Object.keys(orderFilters)
	const order = orders[0]
	const label =
		order === "loan"
			? "LOAN"
			: order === "rental"
			? "RENT"
			: order === "storefront"
			? "BUY NOW"
			: "ALL"

	return (
		<>
			{orders.length > 0 && (
				<div className='fade-in'>
					<FilterPill
						addon={<ListingPillIndicator label={label} />}
						label='Listing Type:'
						dataTestId={label}
						onClick={() => {
							const copyFilters = { ...orderFilters }
							delete copyFilters[order as "loan" | "rental" | "storefront"]
							setSort({
								direction: "desc",
								listingKind: null,
								path: "blockTimestamp",
								prefix: undefined,
							})
							copyFilters.all = {}
							setOrderFilters(copyFilters)
						}}
					/>
				</div>
			)}
		</>
	)
}
