import {
	ChildAccountDisplay,
	NotificationData,
	NotificationType,
} from "flowty-common"
import { useMemo } from "react"
import { FlowtyUserNotifications } from "../../FlowtyUserNotifications"
import { cn } from "../../utils/cn"
import { SignInButton } from "./SignInButton"
import { MyAccountMenu, UserData } from "./MyAccountMenu/MyAccountMenu"

type Props = {
	isLogged: boolean
	isLoading: boolean
	notifications: NotificationData[]
	queueNotification?: ({
		type,
		id,
	}: {
		type: NotificationType
		id: string
	}) => void
	logUser: () => void
	hasMore: boolean
	fetchMoreNotifications: () => void
	loadingNotifications: boolean
	loggedUserAddress: string
	disconnectWallet: () => void
	user?: UserData
	isDapper?: boolean
	childAccounts?: ChildAccountDisplay[]
}

export const NavBarSignInAndNotifications: React.FunctionComponent<Props> = ({
	isLogged,
	isLoading,
	logUser,
	notifications,
	hasMore,
	queueNotification,
	loadingNotifications,
	fetchMoreNotifications,
	loggedUserAddress,
	disconnectWallet,
	user,
	isDapper,
	childAccounts,
}): JSX.Element => {
	const authComponent = useMemo(() => {
		if (!isLogged) {
			return <SignInButton isLoading={isLoading} logUser={logUser} />
		}
		if (isLogged && !isLoading) {
			return (
				<MyAccountMenu
					disconnectWallet={disconnectWallet}
					user={user}
					isDapper={isDapper}
					childAccounts={childAccounts}
				/>
			)
		}
	}, [isLogged, isLoading])
	return (
		<div
			className={cn(
				"flex items-center justify-items-end flex-shrink-0 relative z-[999] gap-[12px] md:gap-[16px]"
			)}
		>
			{isLogged && !isLoading && (
				<FlowtyUserNotifications
					fetchMoreNotifications={fetchMoreNotifications}
					loading={loadingNotifications}
					hasMore={hasMore}
					notifications={notifications}
					queueMessage={queueNotification}
					loggedUserAddress={loggedUserAddress}
				/>
			)}
			{authComponent}
		</div>
	)
}
