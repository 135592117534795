import {
	calcAPRInterest,
	FLOWTY_INTEREST_FEE,
	ListingAvailableData,
	ONE_DAY_IN_SECONDS,
	OpenSearchListingAvailableData,
} from "flowty-common"
import numeral from "numeral"
import React from "react"
import { getDurationPeriod } from "../../../../../utils/GlobalUtils"
import { NftPrice } from "../../NftPrice"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"
import { useField } from "formik"
import { useFlowtyListingModalContext } from "../../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"

interface LoanOrderDisplayProps {
	order: OpenSearchListingAvailableData | ListingAvailableData
	isLoading?: boolean
	blendedLTV?: number | null
}

export const LoanOrderDisplay: React.FC<LoanOrderDisplayProps> = ({
	isLoading,
	order,
	blendedLTV,
}) => {
	const { royaltyRate } = useFlowtyListingModalContext()

	const [amountToBorrow] = useField({ name: `amountToBorrow` })
	const [amountToRepay] = useField({ name: `amountToRepay` })

	const feeCalcValue = amountToRepay.value - amountToBorrow.value

	const flowtyFee = feeCalcValue > 0 ? FLOWTY_INTEREST_FEE * feeCalcValue : 0
	const royaltyFee = (royaltyRate || 0) * amountToBorrow.value

	const lenderFunds = Number(amountToBorrow.value) + (royaltyFee + flowtyFee)

	const interest = (Number(amountToRepay.value) + royaltyFee) / lenderFunds - 1

	const loanLTV = blendedLTV
		? blendedLTV
		: Number(order?.valuations?.aggregate?.loanToValueRatio) * 100

	return (
		<div className='h-[10rem] flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-center '>
						<div>
							<span className='text-white leading-6 text-base font-montserrat font-normal'>
								Loan Amount
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 leading-6 text-bold mb-2'>
								{order?.derivations?.calculatedValues?.marketplaceAmount &&
								order?.derivations?.calculatedValues?.marketplaceAmount != 0 ? (
									<NftPrice
										price={
											order?.derivations?.calculatedValues?.marketplaceAmount
										}
										token={order?.paymentTokenName}
										toolTipCustomId='loan-amount-tooltip'
									/>
								) : (
									"-"
								)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center '>
						<div>
							<span className='text-white text-base font-montserrat font-normal'>
								APR
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 font-medium text-base font-montserrat'>
								{order?.term ||
								(amountToRepay.value && amountToBorrow.value && order.term)
									? `${numeral(
											calcAPRInterest(
												amountToRepay?.value
													? Number(interest)
													: Number(
															order?.derivations?.calculatedValues
																?.periodicInterest
													  ),
												order
													? order.state === "LISTED"
														? Number(order.term)
														: Number(order.term) * ONE_DAY_IN_SECONDS
													: 0
											)
									  ).format("0,0.00")}% `
									: "-"}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div className='text-white text-base font-montserrat font-normal'>
							Duration
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 font-medium text-base font-montserrat'>
								{order?.term
									? getDurationPeriod(
											order.state === "LISTED"
												? Number(order.term)
												: Number(order.term) * ONE_DAY_IN_SECONDS
									  )
									: "-"}
							</span>
						</div>
					</div>
					<div className='my-1 border-gradient' />
					<div className='flex flex-row justify-between items-center'>
						<div className='text-neutral-500 text-base font-montserrat font-normal'>
							Blended LTV
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 font-medium text-base font-montserrat'>
								{loanLTV ? `${numeral(loanLTV).format("0,0.0")}%` : "-"}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
