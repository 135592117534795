import { OpensearchRentalAvailableData } from "flowty-common"
import React from "react"
import { NftPrice } from "../../FlowtyModal/components/common/NftPrice"
import { useFlowtyPurchaseModalContext } from "../../FlowtyModal/contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { getDurationPeriod } from "../../utils/GlobalUtils"
import { cn } from "../../utils/cn"

interface RentalCarouselCardProps {
	order: OpensearchRentalAvailableData
	selectedOrder: OpensearchRentalAvailableData | null
}

export const RentalCarouselCard: React.FunctionComponent<
	RentalCarouselCardProps
> = ({ order, selectedOrder }) => {
	const { spotPrice } = useFlowtyPurchaseModalContext()
	return (
		<div
			className={cn(
				"w-[288px] h-[270px] flex flex-col justify-between p-6 rounded-lg border border-[#495057] bg-[#ffffff14] font-montserrat cursor-pointer",
				{
					"border-primary":
						selectedOrder?.listingResourceID === order?.listingResourceID,
				}
			)}
		>
			<div className='w-full flex flex-col gap-2'>
				<div className='flex w-full justify-between items-start'>
					<span className='text-base text-white'>Rental Fee</span>
					<div className='text-white font-semibold'>
						<NftPrice
							price={order?.amount}
							token={order?.paymentTokenName}
							spotPriceValue={
								spotPrice ? spotPrice.value * Number(order?.amount) : undefined
							}
						/>
					</div>
				</div>
				<div className='flex w-full justify-between items-start'>
					<span className='text-base text-white'>Refundable Deposit</span>
					<div className='text-white font-semibold'>
						<NftPrice
							price={order?.deposit}
							token={order?.paymentTokenName}
							spotPriceValue={
								spotPrice ? spotPrice.value * Number(order?.amount) : undefined
							}
						/>
					</div>
				</div>
				<div className='border-gradient js-show-on-scroll' />
				<div className='flex w-full justify-between items-start'>
					<span className='text-base text-white'>Total Due Upfront</span>
					<span className='text-white font-semibold'>
						<NftPrice
							price={Number(order?.deposit) + Number(order?.amount)}
							token={order?.paymentTokenName}
							spotPriceValue={
								spotPrice ? spotPrice.value * Number(order?.amount) : undefined
							}
						/>
					</span>
				</div>
				<div className='flex w-full justify-between items-center'>
					<span className='text-base text-white'>Duration</span>
					<span className='text-base text-white font-bold'>
						{getDurationPeriod(Number(order?.term))}
					</span>
				</div>
				{order?.valuations?.aggregate?.depositToValueRatio && (
					<div className='flex w-full justify-between items-center'>
						<span className='text-base text-white'>DTV</span>
						<span className='text-base text-white font-bold'>
							{order?.valuations?.aggregate?.depositToValueRatio}X
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
