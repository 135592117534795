import numeral from "numeral"
import { FlowtyTokenIcon } from "../../../FlowtyTokenIcon"
import { ListingType } from "../../types"

export function getDescriptiveText({
	listingType,
	amount,
	title,
	token,
	transferAddress,
}: {
	listingType: ListingType
	amount: number
	title: string
	token: string
	transferAddress?: string
}): React.ReactNode {
	const formattedTitle = (
		<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
			{title}
		</strong>
	)

	const tokenIcon = <FlowtyTokenIcon token={token?.toLowerCase()} />

	const formattedTransferAddress = (
		<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
			{" "}
			{transferAddress}{" "}
		</strong>
	)

	const formattedAmount = (
		<span className='inline-flex items-center gap-[4px]'>
			{tokenIcon}
			<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
				{numeral(amount).format("0,0.00")}
			</strong>
		</span>
	)

	switch (listingType) {
		case "bulkDelisting":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Bulk Delisted {formattedTitle} for sale
				</span>
			)
		case "bulkListing":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Bulk Listed {formattedTitle} for sale at {formattedAmount}
				</span>
			)
		case "storefront":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Listed {formattedTitle} for sale at {formattedAmount}
				</span>
			)
		case "purchase":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Bought {formattedTitle} for {formattedAmount}
				</span>
			)
		case "rental":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Listed {formattedTitle} for rent
				</span>
			)
		case "rent":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Rented {formattedTitle} for {formattedAmount}
				</span>
			)
		case "loan":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Listed {formattedTitle} for a {formattedAmount} loan
				</span>
			)
		case "fundLoan":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Funded {formattedAmount} loan on {formattedTitle}
				</span>
			)
		case "loanBorrower":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Repaid {formattedAmount} loan on {formattedTitle}
				</span>
			)
		case "transfer":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Transfer {formattedTitle} to {formattedTransferAddress}
				</span>
			)
		case "makeOffer":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Make offer to buy {formattedTitle} for {formattedAmount}
				</span>
			)
		case "acceptOffer":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Accepted offer {formattedTitle} for {formattedAmount}
				</span>
			)
		case "cancelOffer":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Cancel offer to buy {formattedTitle} for {formattedAmount}
				</span>
			)
		case "delistSale":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Delisted sale listing for {formattedTitle}
				</span>
			)
		case "delistRental":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Delisted rental listing for {formattedTitle}
				</span>
			)
		case "delistLoan":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Delisted loan listing for {formattedTitle}
				</span>
			)
		case "fundRental":
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					You rented {formattedTitle}
				</span>
			)
	}
	return ""
}
