import { ListingStatus } from "flowty-common"
import { AuthStoreProp } from "../../stores/AuthStore"
import NftLabel from "./NftLabel"

interface Props extends AuthStoreProp {
	nftState: ListingStatus
}

const NftStatePlaceholder = ({ nftState }: Props) => (
	<div className='absolute w-3/4 h-full bg-opacity-75 rounded-3xl flex justify-center items-center'>
		<NftLabel type={nftState} className='mt-3' />
	</div>
)

export default NftStatePlaceholder
