const getMinListingDuration = (isMainnet: boolean) => {
	return isMainnet ? 1 : 0.0001
}

const MAX_LISTING_DURATION = 180

const allowDecimalInDuration = (isMainnet: boolean) => {
	return !isMainnet
}

export { getMinListingDuration, MAX_LISTING_DURATION, allowDecimalInDuration }
