export interface DapperSignInBarProps {
	onDismiss: () => void
	connectDapperWallet: () => void
}
export const DapperSignInBar: React.FC<DapperSignInBarProps> = ({
	onDismiss,
	connectDapperWallet,
}) => {
	return (
		<div className='w-full flex flex-row justify-between px-[18px] md:px-[64px] py-[18px] bg-[#e2e2e2] custom-opacity-dapperbar'>
			<div></div>
			<div className='flex flex-col md:flex-row justify-center items-center gap-[8px]'>
				<h2 className='text-[#ADB5BD] font-montserrat text-[12px] md:text-[16px] font-black leading-normal tracking-[.125em] uppercase'>
					Top shot user?
				</h2>
				<button
					onClick={connectDapperWallet}
					className='uppercase text-[#F8F9FA] font-montserrat text-[12px] md:text-[16px] font-black leading-normal tracking-[.125em] underline underline-offset-[3px]'
					rel='noopener noreferrer'
				>
					Sign in with your dapper wallet
				</button>
			</div>
			<button onClick={onDismiss}>
				<svg
					className='justify-end'
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
				>
					<path
						d='M6 18L18 6M6 6L18 18'
						stroke='#ADB5BD'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</button>
		</div>
	)
}
