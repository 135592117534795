import React from "react"

interface FilterPillProps {
	children?: React.ReactNode | React.ReactNode[]
	label?: string
	dataTestId?: string
	text?: string
	icon?: React.ReactNode
	addon?: React.ReactNode
	onClick?: () => void
	isClearAll?: boolean
}

const formatText = (text: string) => {
	return text.split(/(?=[A-Z][a-z])/).join(" ")
}

// eslint-disable-next-line import/no-unused-modules
export const FilterPill: React.FC<FilterPillProps> = ({
	label,
	dataTestId,
	text,
	icon,
	addon,
	children,
	onClick,
	isClearAll = false,
}) => {
	return (
		<div
			data-testid={dataTestId}
			onKeyDown={event => {
				if (event.key === "Enter" && onClick) {
					onClick()
				}
			}}
			onClick={onClick && onClick}
			className={`py-[0.38rem] lg:py-[1.125rem] tracking-[.00625rem] lg:tracking-[0.1px] font-montserrat text-[.75rem] lg:text-[0.9375rem] font-[500] px-[.5rem] lg:px-[1rem] rounded-[0.375rem] text-white bg-[#e1e1e114] lg:bg-glassFillGray hover:bg-glassFillGrayHover h-[1.75rem] lg:h-[2.5rem]  flex items-center justify-center gap-[0.75rem] shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)] cursor-pointer`}
		>
			{icon}
			{children}
			<div className='flex flex-row items-center whitespace-nowrap'>
				{label && (
					<span className='text-[#ffffff50] mr-2'>{` ${formatText(
						label
					)}`}</span>
				)}
				<span className='flex text-center'>{text}</span>
			</div>
			{addon}
			{!isClearAll && (
				<div className='cursor-pointer'>
					<svg
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5'
							stroke='white'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
			)}
		</div>
	)
}
