import { OpensearchFlowNFT, getImageURL } from "flowty-common"
import { AccountSummaries } from "flowty-sdk"
import { Tooltip } from "react-tooltip"

interface Props {
	accountSummaries?: AccountSummaries
	nft: OpensearchFlowNFT
}

export const WalletImageComponent: React.FunctionComponent<Props> = ({
	accountSummaries,
	nft,
}) => {
	return (
		<>
			<Tooltip
				id={`${nft.uuid}-nft-origin-tooltip`}
				style={{
					backgroundColor: "white",
					color: "#031021",
					fontSize: "0.75rem",
				}}
			/>
			<img
				data-tooltip-id={`${nft.uuid}-nft-origin-tooltip`}
				data-tooltip-content={`From ${
					accountSummaries?.[nft.owner]?.display?.name ||
					accountSummaries?.[nft.owner]?.address
				} account`}
				data-tooltip-place='top'
				className='rounded-full h-6 w-6 aspect-square'
				src={getImageURL(
					accountSummaries?.[nft.owner]?.display?.thumbnail || ""
				)}
				alt='Account owner icon'
			/>
		</>
	)
}
