import { OfferCreated, SupportedTokens } from "flowty-common"
import React from "react"
import { ValuationData } from "../../../../../Types/GlobalTypes"
import {
	getSourceFromSourceList,
	truncateNumber,
} from "../../../../../utils/GlobalUtils"
import { PuchaseModalOfferType } from "../../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { NftCardFMVTooltip } from "../../NftCardFMVTooltip/NftCardFMVTooltip"
import { NftPrice } from "../../NftPrice"
import { BestOfferDisplay } from "../BestOfferDisplay/BestOfferDisplay"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"

interface CancelOfferOrderDisplayProps {
	dapperOffer?: OfferCreated | null
	nonCustodialOffer?: OfferCreated | null
	isLoading?: boolean
	isLoadingValuation?: boolean
	valuationData: ValuationData
	offer: PuchaseModalOfferType
}

export const CancelOfferOrderDisplay: React.FC<
	CancelOfferOrderDisplayProps
> = ({
	dapperOffer,
	nonCustodialOffer,
	isLoading,
	valuationData,
	offer,
	isLoadingValuation,
}) => {
	const valuationSource = getSourceFromSourceList(valuationData.source)

	return (
		<div className='h-full flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-start border-gradient mb-3'>
						<p className='text-white text-[14px] leading-6 mb-2 font-normal'>
							Offer Price
						</p>
						<span className='text-neutral-600 leading-6 text-bold mb-2'>
							{offer?.amount ? (
								<NftPrice
									price={offer?.amount}
									token={offer?.paymentTokenName as SupportedTokens}
									toolTipCustomId='cancel-offer-price-tooltip'
								/>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						{isLoadingValuation ? (
							<div className='w-[87px] h-[56px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						) : (
							<>
								{valuationData?.usdValue && valuationData.usdValue > 0 ? (
									<div className='flex flex-col justify-start items-start h-24 relative group'>
										<div className='text-neutral-900 text-[12px] font-montserrat font-normal'>
											3rd Party FMV
										</div>
										<div className='self-center text-end py-1 w-full'>
											<span className='text-neutral-600 font-medium text-base font-montserrat w-full flex justify-start'>
												$
												{truncateNumber(valuationData.usdValue, {
													shouldUseDecimals: true,
												})}
											</span>
										</div>
										<NftCardFMVTooltip valuationSource={valuationSource} />
									</div>
								) : (
									<div />
								)}
							</>
						)}
						<BestOfferDisplay
							dapperOffer={dapperOffer}
							nonCustodialOffer={nonCustodialOffer}
						/>
					</div>
				</>
			)}
		</div>
	)
}
