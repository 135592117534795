import format from "date-fns/format"
import { OpensearchRentalAvailableData } from "flowty-common"
import React from "react"
import { getDurationPeriod } from "../../../../../utils/GlobalUtils"
import { NftPrice } from "../../../common/NftPrice"

interface SingleRentalItemViewProps {
	order: OpensearchRentalAvailableData
}

export const SingleRentalItemView: React.FunctionComponent<
	SingleRentalItemViewProps
> = ({ order }) => {
	return (
		<div className='w-full flex flex-col gap-3 justify-between mt-4'>
			<div className='flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Rental Fee</p>
				<NftPrice price={order?.amount} token={order?.paymentTokenName} />
			</div>
			<div className='flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Refundable Deposit</p>
				<NftPrice price={order?.deposit} token={order?.paymentTokenName} />
			</div>
			<div className='flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Total Due Upfront</p>
				<NftPrice
					price={Number(order?.deposit) + Number(order?.amount)}
					token={order?.paymentTokenName}
				/>
			</div>
			<div className='flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Duration</p>
				<span className='text-base text-white font-bold pr-1'>
					{getDurationPeriod(Number(order?.term))}
				</span>
			</div>
			{order?.valuations?.aggregate?.depositToValueRatio && (
				<div className='flex justify-between'>
					<p className='font-[400] text-[14px] text-white'>DTV</p>
					<span className='text-base text-white font-bold pr-1'>
						{order?.valuations?.aggregate?.depositToValueRatio}X
					</span>
				</div>
			)}
		</div>
	)
}
