import { FCLTransactionResult } from "flowty-common"
import {
	Err,
	expirationDaysToSeconds,
	getCatalogEntryForType,
	sendMutation,
	termNumberToSeconds,
} from "../../utils/Utils"
import { CreateLoanListingProps, RemoveLoanListing } from "./LoanListingTypes"
import { Config } from "../../types"
import {
	getDelistLoanListingTransactionContent,
	getLoanListingTransactionContent,
} from "./transaction"
import { PaymentTokenToIdentifier } from "../../utils/TokensIdentifier"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class LoanListing {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	createLoanListing = async ({
		nftData,
		loanAmount,
		loanInterestRate,
		loanDuration,
		loanAutoRepaymentEnabled,
		loanExpiresAfterDays,
		token,
		ftPrivatePathIdentifier,
		nftProviderPathIdentifier,
		ftReceiverAddress,
		txAvailableCallback,
	}: CreateLoanListingProps): Promise<FCLTransactionResult> => {
		if (!nftData) throw new Error("Flow NFT it's null")
		const { id, type, nftOwner } = nftData

		const termNumber = Number(loanDuration)
		const termInSeconds = termNumberToSeconds(termNumber)

		const expiresAfter: number = expirationDaysToSeconds(loanExpiresAfterDays)
		const catalogIdentifier = await getCatalogEntryForType(type, this.config)

		const txContent = getLoanListingTransactionContent(
			this.config,
			token,
			nftData
		)

		// For now, our crescendo and non-crescendo transaction inputs mostly match.
		// The only change is that private paths instead become controller ids in crescendo
		// but the input order is the same.
		let ftArg = fcl.arg(ftPrivatePathIdentifier, t.String)
		let nftArg = fcl.arg(nftProviderPathIdentifier, t.String)
		if (this.config.crescendo) {
			// We need to default to "0" for both of these args so that they can be valid
			// UInt64 values. This controller ID will always be invalid, so the worst case
			// is that the transaction fails.
			ftArg = fcl.arg(ftPrivatePathIdentifier || "0", t.UInt64)
			nftArg = fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
		}

		const txArguments = [
			fcl.arg(id.toString(), t.UInt64),
			fcl.arg(loanAmount.toFixed(2), t.UFix64),
			fcl.arg(loanInterestRate.toFixed(6), t.UFix64),
			fcl.arg(termInSeconds.toFixed(1), t.UFix64),
			fcl.arg(loanAutoRepaymentEnabled, t.Bool),
			fcl.arg(expiresAfter.toFixed(2), t.UFix64),
			ftArg,
			nftArg,
			fcl.arg(catalogIdentifier || "", t.String),
			fcl.arg(nftOwner, t.Address),
			fcl.arg(ftReceiverAddress, t.Address),
		]

		if (this.config.crescendo) {
			txArguments.push(
				fcl.arg(PaymentTokenToIdentifier[token.symbol], t.String)
			)
		}

		console.log("txArguments", { txArguments, txContent })

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}

	// eslint-disable-next-line class-methods-use-this
	removeLoanListing = async ({
		listingResourceID,
		txAvailableCallback,
	}: RemoveLoanListing): Promise<FCLTransactionResult> => {
		const txArguments = [fcl.arg(listingResourceID.toString(), t.UInt64)]

		const txContent = getDelistLoanListingTransactionContent(this.config)
		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)

			return res
		} catch (e) {
			console.log("TX ERROR", e)
			throw e
		}
	}
}
