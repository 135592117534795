/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { Order } from "flowty-common"
import React, { useState } from "react"
import "swiper/css"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { CarouselCard } from "./CarouselCard/CarouselCard"
import { CarouselNavigation } from "./CarouselNavigation/CarouselNavigation"
import { Pagination } from "swiper/modules"

interface FlowtyModalCarouselProps {
	orders: Order[]
	selectedOrder?: Order | null
	updateSelectedOrder: (order: Order) => void
}

export const FlowtyModalCarousel: React.FunctionComponent<
	FlowtyModalCarouselProps
> = ({ orders, selectedOrder, updateSelectedOrder }) => {
	const [isFirstIndex, setIsFirstIndex] = useState(true)
	return (
		<div className='max-w-[400px]'>
			<Swiper
				slidesPerView={"auto"}
				spaceBetween={10}
				effect={"slide"}
				speed={800}
				centeredSlides={!isFirstIndex}
				modules={[Pagination]}
			>
				{orders.length > 1 && (
					<span slot='container-end' className='h-32 w-32'>
						<CarouselNavigation
							itemsLength={orders.length}
							setIsFirstIndex={setIsFirstIndex}
						/>
					</span>
				)}
				{orders.map((order, idx) => {
					return (
						<SwiperSlide key={order.listingResourceID} className='!w-auto'>
							<CarouselCard
								index={idx}
								order={order}
								selectedOrder={selectedOrder}
								updateSelectedOrder={updateSelectedOrder}
							/>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}
