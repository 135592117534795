import React from "react"
import { ReactComponent as Pentagon } from "../../../assets/media/Pentagon.svg"
import { ReactComponent as Triangle } from "../../../assets/media/Triangle.svg"
import { getFMVDisplayLabelFromSource } from "../../../util/fmv"

interface DiscountIndicatorProps {
	percent: number
	tooltip?: boolean
	source?: string
}

export const DiscountIndicator: React.FC<DiscountIndicatorProps> = ({
	percent,
	source,
	tooltip = false,
}) => {
	if (percent < 5) return null
	return (
		<div className='relative group'>
			<Pentagon className='fill-[#5308B2] drop-shadow-[0_0_2px_#5308B2]' />
			<div className='absolute top-[46%] left-[54%] transform -translate-x-1/2 -translate-y-1/2 text-white font-montserrat-bold font-bold'>
				{percent}%
			</div>
			{tooltip && (
				<div
					onClick={e => {
						e.stopPropagation()
					}}
					className='absolute hidden group-hover:block'
				>
					<div className='relative -left-3/4 top-3 bg-[#373D3F] rounded-md min-w-[18rem] z-10 cursor-default'>
						<div className='p-[12px]'>
							<p className='text-sm font-normal'>
								Listed Purchase Price is {percent}% lower than Fair Market Value
								(FMV) per {getFMVDisplayLabelFromSource(`${source}`)}. FMV is an
								estimate and is not financial advice.
							</p>
						</div>
						<Triangle className='fill-[#373D3F] absolute h-6 w-6 -top-4 right-[9%]' />
					</div>
				</div>
			)}
		</div>
	)
}
