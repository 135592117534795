import { Config } from "../types"

export const getValidateOwnershipScript = (
	config: Config,
	nftContractName: string,
	nftContractAddress: string,
	resourceName: string,
	storagePath: string
): string => {
	return !config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _validateOwnershipScript(
				config,
				nftContractName,
				nftContractAddress,
				resourceName,
				storagePath
		  )
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _validateOwnershipScriptCrescendo(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _validateOwnershipScript = (
	config: Config,
	nftContractName: string,
	nftContractAddress: string,
	resourceName: string,
	storagePath: string
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ${nftContractName} from ${nftContractAddress}

pub fun main(address: Address, nftId: UInt64, typeIdentifier: String): Bool {
    // see note in AccountService.ts about using NFT resource name
    let expectedType = Type<@${nftContractName}.${resourceName}>()
    let statedType = CompositeType(typeIdentifier) ?? panic("Invalid type identifier")

    let collection = getAuthAccount(address)
        .borrow<&{NonFungibleToken.CollectionPublic}>(from: /storage/${storagePath})
    if collection == nil {
      return false
    }

    return statedType == expectedType && collection!.getIDs().contains(nftId)
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _validateOwnershipScriptCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

access(all) fun main(address: Address, nftId: UInt64, typeIdentifier: String): Bool {
    let nftType = CompositeType(typeIdentifier) ?? panic("Invalid type identifier")

    var storagePath: StoragePath? = nil
    if let identifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) {
        var identifier = ""
        for i in identifiers.keys {
            if identifiers[i]! == true {
                identifier = i
                break
            }
        }

        if identifier != "" {
            let entry = NFTCatalog.getCatalogEntry(collectionIdentifier: identifier)!
            storagePath = entry.collectionData.storagePath
        }
    } else {
        let nftAddress = AddressUtils.parseAddress(nftType)!
        let nftContractName = typeIdentifier.split(separator: ".")[2]
        let nftContract = getAccount(nftAddress).contracts.borrow<&{ViewResolver}>(name: nftContractName)
            ?? panic("contract not found or does not implement ViewResolver interface")

        let md = nftContract.resolveContractView(resourceType: nftType, viewType: Type<MetadataViews.NFTCollectionData>())
            ?? panic("NFTCollectionData metadata view not found")

        let collectionData = md as! MetadataViews.NFTCollectionData
        storagePath = collectionData.storagePath
    }

    assert(storagePath != nil, message: "unable to find storage path for type: ".concat(nftType.identifier))

    let collection = getAuthAccount<auth(Storage) &Account>(address).storage.borrow<&{NonFungibleToken.Collection}>(from: storagePath!)
    if collection == nil {
      return false
    }

    let nft = collection!.borrowNFT(nftId)
    return nft != nil && nft!.getType() == nftType
}`
