import {
	differenceInMilliseconds,
	differenceInMinutes,
	differenceInHours,
	differenceInSeconds,
} from "date-fns"
import { useEffect, useState } from "react"
import { getExpiresOnDate } from "../../util/returnPastAndTodaysNotifications"

const formatTime = (expiresOnDate: Date, now: Date): string => {
	const millisecondsLeft = differenceInMilliseconds(expiresOnDate, now)
	if (millisecondsLeft <= 0) return "Expired"

	const hours = differenceInHours(expiresOnDate, now)
	const minutes = differenceInMinutes(expiresOnDate, now) % 60
	const seconds = differenceInSeconds(expiresOnDate, now) % 60

	const hoursPart = hours > 0 ? `${String(hours).padStart(2, "0")}h ` : ""
	const minutesPart =
		hours > 0 || minutes > 0 ? `${String(minutes).padStart(2, "0")}m ` : ""
	const secondsPart =
		hours > 0 || minutes > 0 || seconds > 0
			? `${String(seconds).padStart(2, "0")}s`
			: ""

	return `${hoursPart}${minutesPart}${secondsPart}`.trim()
}

export const useCountdown = (expiresOn: Date): string | null => {
	const [timeLeft, setTimeLeft] = useState("00:00:00")
	const expiresOnDate = getExpiresOnDate(expiresOn)
	useEffect(() => {
		const updateCountdown = () => {
			setTimeLeft(formatTime(expiresOnDate, new Date()))
		}

		const intervalId = setInterval(updateCountdown, 1000)

		updateCountdown()

		return () => clearInterval(intervalId)
	}, [expiresOn])

	return timeLeft
}
