/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	NftMetadata,
	NFTMetadata,
	flowSeriesToTopShotSeries,
	truncateString,
} from "flowty-common"

export const getMetadataFields = (
	metadata: NftMetadata | NFTMetadata,
	type: string
): Record<string, any> => {
	const initialFields = metadata?.metadata ?? {}
	let data: Record<string, any> = {}

	if (type.includes("TopShot")) {
		const tsSeries = flowSeriesToTopShotSeries(
			initialFields.TopShotSeries ?? initialFields.SeriesNumber
		)
		// "editionNumber" gets you the same value, but as a number instead of string
		const tsSerial =
			initialFields.Serial ??
			initialFields.SerialNumber ??
			initialFields.editionNumber
		const tsSupply = initialFields.Supply ?? initialFields.editionMax

		data.Name = initialFields.FullName
		data.Set = initialFields.Set ?? initialFields.SetName
		data.Tier = initialFields?.Tier || "Unknown"
		data.Series = Number.isNaN(+tsSeries) ? tsSeries : `Series ${tsSeries}`
		data.Serial = `${tsSerial} / ${tsSupply}`
		data.Team = initialFields.TeamAtMoment
		data["Play Type"] = initialFields.PlayType
	} else {
		data = { ...initialFields }
	}

	return data
}

export const getCardTerms = (
	metadata: NftMetadata | NFTMetadata,
	type: string
): string[] | null[] => {
	const fields = getMetadataFields(metadata, type)
	if (type.includes("TopShot")) {
		return [
			`${truncateString(fields.Set, 15)} (${fields.Series})`,
			`${fields.Tier} ${fields.Serial}`,
		]
	}

	if (type.includes("AllDay")) {
		const setName = fields["Set Name"] || fields.setName
		const series = fields["Series Number"]
			? `Series ${fields["Series Number"]}`
			: fields.seriesName
		const serialNumber = fields["Serial Number"] || fields.serialNumber
		const tier = fields.Tier || fields.editionTier
		const numMinted = fields["Num Minted"] || fields.editionMax

		return [
			`${truncateString(setName, 15)} (${series})`,
			`${tier} #${serialNumber} / ${numMinted}`,
		]
	}

	if (type.includes("UFC_NFT")) {
		// TODO: need to handle this for strike as well!
		return [null, null]
	}

	return [null, null]
}
