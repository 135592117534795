import axios from "axios"
import { format, fromUnixTime, toDate } from "date-fns"
import { FlowtyButton } from "ds-flowty"
import {
	nftTypeAndIdToLocationData,
	OfferCreated,
	OpensearchFlowNFT,
} from "flowty-common"
import { useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { SortDirection } from "../../../../../../components/MarketPlace/SortButton/shared"
import { SortColumnOrNull } from "../../../../../../components/SortableTable"
import { TokenAmount } from "../../../../../../components/Tokens/tokenDisplays"
import { useFlowtyModalsContext } from "../../../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { useHybridCustodyContext } from "../../../../../../contexts/HybridCustodyContext"
import useInfiniteScroll from "../../../../../../hooks/infiniteScroll"
import { hideOffer } from "../../../../../../services/OffersService"
import { AuthStoreProp } from "../../../../../../stores/AuthStore"
import { Log } from "../../../../../../util/Log"
import { actions as mixpanel } from "../../../../../../util/Mixpanel"
import { apiURL } from "../../../../../../util/settings"
import { getTimeToDate } from "../../../../../../util/timestamps"
import { truncateCurrencyAmount } from "../../../../../../util/truncateValueUtil"
import { OffersTableNftRow } from "../../common/OffersTableNftRow"
import { ProfileOffersTableField } from "../../ProfileOffersTable"
import { AcceptHide } from "../components/AcceptHide"

interface ReceivedOffersTableValues {
	dataTotal: number
	hasMore: boolean
	isFetching: boolean
	isLoadingResults: boolean
	nftsWithOffers: OpensearchFlowNFT[]
	receivedOffersFields: ProfileOffersTableField[]
	scroll: (node: HTMLElement | null) => void
	setSort: (sort: SortColumnOrNull) => void
	sort: SortColumnOrNull
}

interface ReceivedOffersTableProps extends AuthStoreProp {
	address: string
	addresses: string[]
	isPublic: boolean
}

export const useReceivedOffersTable = ({
	isPublic,
	authStore,
	address,
	addresses,
}: ReceivedOffersTableProps): ReceivedOffersTableValues => {
	const [nftsWithOffers, setNftsWithOffers] = useState<OpensearchFlowNFT[]>([])
	const [page, setPage] = useState(0)
	const [dataTotal, setDataTotal] = useState(0)
	const [isFetching, setIsFetching] = useState(true)
	const [hasMore, setHasMore] = useState(true)

	const isLoading = useRef(false)
	const limit = 5

	const { selectCard } = useFlowtyModalsContext()

	const selectOffer = useCallback(
		({
			offer,
			singleOfferType,
			selectedNft,
		}: {
			offer?: OfferCreated
			singleOfferType?: "make-offer" | "cancel-offer"
			selectedNft: OpensearchFlowNFT
		}) => {
			selectCard({
				selected: {
					contractAddress: selectedNft.contractAddress,
					contractName: selectedNft.contractName,
					nftID: selectedNft.id,
					nftType: selectedNft.type,
				},
				selectedSingleOffer: offer as OfferCreated,
				singleOfferType,
			})
		},
		[selectCard]
	)

	const [isLoadingResults, setIsLoadingResults] = useState(false)

	const { hybridCustodyNFTStatus } = useHybridCustodyContext()

	const scroll = useInfiniteScroll(
		{
			isLoading: isLoading.current,
			onInfiniteScroll() {
				if (hasMore) {
					Log("Scroll Triggered", { hasMore, page })
					setPage(page + 1)
				}
			},
		},
		[nftsWithOffers.length, hasMore, isLoading.current]
	)

	const [sort, setSort] = useState<SortColumnOrNull>({
		column: "blockTimestamp",
		order: SortDirection.Descending,
	})

	const fetchOffers = useCallback(async () => {
		setNftsWithOffers([])
		isLoading.current = true
		setPage(0)
		setHasMore(true)
		setIsLoadingResults(true)
		setIsFetching(true)

		try {
			const response = await axios.post(
				`${apiURL}/user/${address}/offers/received`,
				{
					addresses,
					from: limit * 0,
					limit: limit,
					sort: {
						direction: sort?.order,
						path: sort?.column,
					},
				}
			)

			if (
				nftsWithOffers.length + response.data.nfts.length ===
				response.data.total
			) {
				setHasMore(false)
			}

			setNftsWithOffers(response.data.nfts)
			setDataTotal(response.data.total)
			isLoading.current = false
			setIsFetching(false)
			setIsLoadingResults(false)
		} catch (err) {
			if (axios.isCancel(err)) {
				mixpanel.track("PROFILE_OFFERS_RECEIVED", { event: "cancelled" })
			}
			isLoading.current = false
			setIsFetching(false)
			setIsLoadingResults(false)
		}
	}, [addresses, address, sort, page])

	useEffect(() => {
		fetchOffers()
	}, [address, sort, addresses])

	useEffect(() => {
		if (page === 0) return
		isLoading.current = true
		setIsFetching(true)
		axios
			.post(`${apiURL}/user/${address}/offers/received`, {
				addresses,
				from: limit * page,
				limit: limit,
				sort: {
					direction: sort?.order,
					path: sort?.column,
				},
			})
			.then(res => {
				if (nftsWithOffers.length + res.data.nfts.length === res.data.total) {
					setHasMore(false)
				}

				setNftsWithOffers([...nftsWithOffers, ...res.data.nfts])

				setDataTotal(res.data.total)
				isLoading.current = false
				setIsFetching(false)
			})
			.catch(_ => {
				isLoading.current = false
				setIsFetching(false)
			})
	}, [page, addresses])

	const receivedOffersFields: ProfileOffersTableField[] = [
		{
			customRender: ({ offer, key }) => {
				const locationData = nftTypeAndIdToLocationData(
					offer?.typeAndIDOffer?.nftType || "",
					offer?.typeAndIDOffer?.nftID || ""
				)

				return (
					<td key={key} className='px-3 py-8 text-sm font-medium sm:pl-3'>
						<OffersTableNftRow locationData={locationData} offer={offer} />
					</td>
				)
			},
			name: "NFT",
			title: "NFT",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						<TokenAmount
							amount={offer?.amount}
							token={offer?.paymentTokenName}
							isSmall
							largeText
							justifyStart={true}
						/>
					</td>
				)
			},
			name: "usdValue",
			sortable: true,
			title: "Offer",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8'>
						<div className='flex flex-col'>
							<Link to={`/user/${offer?.storefrontAddress}`}>
								<span className='text-[#1B8062] text-sm hover:underline cursor-pointer'>
									{offer?.storefrontAddress}
								</span>
							</Link>
						</div>
					</td>
				)
			},
			name: "From",
			title: "From",
		},
		{
			customRender: ({ offer, key }) => {
				const usdValue = offer?.valuations?.blended?.usdValue
				if (usdValue) {
					return (
						<td
							key={key}
							className='whitespace-nowrap px-3 py-8 text-sm text-bold'
						>
							${truncateCurrencyAmount(usdValue)}
						</td>
					)
				}
				return <td className='whitespace-nowrap px-3 py-8 text-lg'>-</td>
			},
			name: "FMV",
			title: "FMV",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						{format(toDate(offer?.blockTimestamp), "yyyy-MM-dd HH:mm")}
					</td>
				)
			},
			name: "blockTimestamp",
			sortable: true,
			title: "Date Received",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						{getTimeToDate(fromUnixTime(offer?.expiry))}
					</td>
				)
			},
			name: "Expiration",
			title: "Expiration",
		},
		...(isPublic
			? ([
					{
						customRender: ({ nft, offer, key }) => {
							const isLoggedUsersOffer =
								authStore?.loggedUser?.addr === offer.storefrontAddress

							return (
								<td key={key} className='whitespace-nowrap px-3 py-8 text-sm'>
									<div className='space-x-3'>
										{isLoggedUsersOffer ? (
											<FlowtyButton
												text={"Cancel"}
												onClick={() => {
													selectOffer({
														offer,
														selectedNft: nft as OpensearchFlowNFT,
														singleOfferType: "cancel-offer",
													})
												}}
												variant={"secondary"}
												btnSize='small'
												bgColor='white'
											/>
										) : (
											<div className='w-36' />
										)}
									</div>
								</td>
							)
						},
						name: "Cancel",
						title: "Cancel",
					},
			  ] as ProfileOffersTableField[])
			: ([
					{
						customRender: ({ nft, offer, callback, key }) => {
							const isLoggedUsersOffer =
								authStore?.loggedUser?.addr === nft?.owner
							const collectionIdentifier = `${nft?.contractAddress}.${nft?.contractName}`
							const hasProvider =
								hybridCustodyNFTStatus?.[nft?.owner || ""]?.[
									collectionIdentifier
								]?.length > 0 || isLoggedUsersOffer

							return (
								<td key={key} className='whitespace-nowrap px-3 py-8 text-sm'>
									<AcceptHide
										callBack={callback}
										onAccept={() =>
											selectOffer({
												offer,
												selectedNft: nft as OpensearchFlowNFT,
											})
										}
										onHide={async () => {
											return hideOffer(offer?.offerResourceID).then(_ => {
												callback()
											})
										}}
										hasProvider={hasProvider}
									/>
								</td>
							)
						},
						name: "Accept/Hide",
						title: "Accept/Hide",
					},
			  ] as ProfileOffersTableField[])),
	]

	return {
		dataTotal,
		hasMore,
		isFetching,
		isLoadingResults,
		nftsWithOffers,
		receivedOffersFields,
		scroll,
		setSort,
		sort,
	}
}
