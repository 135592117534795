import { AdjustmentsIcon, ArrowDownIcon } from "@heroicons/react/solid"
import { SaveIcon } from "../Icons/SaveIcon"

export const ListingFilters = () => {
	return (
		<div
			data-testid='preview-listing-filters'
			className='flex items-center gap-2 text-white'
		>
			<button className='h-[40px] bg-[#ffffff14] text-[12px] rounded-md px-2 3xl:px-4 py-1 3xl:py-2 flex items-center justify-center'>
				<AdjustmentsIcon
					aria-hidden='true'
					className='mr-2 h-[20px] w-[20px]'
				/>
				<span className='hidden 2xl:flex capitalize'>Show filters</span>
				<span className='flex 2xl:hidden capitalize'>Show filters</span>
			</button>
			<button className='h-[40px] bg-[#ffffff14] text-[12px] rounded-md px-2 3xl:px-4 py-1 3xl:py-2 flex items-center justify-center'>
				<div className='mr-2'>
					<SaveIcon aria-hidden='true' />
				</div>
				Save View
			</button>
			<button className='h-[40px] bg-[#ffffff14] text-[12px] rounded-md px-2 3xl:px-4 py-1 3xl:py-2 flex items-center justify-center'>
				Sort By: Recently Listed
				<ArrowDownIcon aria-hidden='true' className='ml-1 h-5 w-5' />
			</button>
			<div className='font-bold text-[12px] whitespace-nowrap'>
				10,000+ items
			</div>
		</div>
	)
}
