/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { composeRentalListingPath } from "../../../../routes"
import { subscribeToAllActivity } from "../../../../services/firestore/ActivityService"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { Log } from "../../../../util/Log"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { defaultNftPageSize } from "../../../../util/settings"
import { RentalActivityFilter } from "../../../Activity/Filters/RentalActivityFilter"
import { ScrollToLoadMsg } from "../../../Loaders/ScrollToLoadMsg"
import SortableTable from "../../../SortableTable"
import { userRentalActivityTableFields } from "../ActivityTable/UserRentalActivityTableFields"

const UserRentalActivityTableComponent: React.FunctionComponent<
	AuthStoreProp
> = ({ authStore }) => {
	const [prevLength, setPrevLength] = useState(0)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [showFilter, setShowFilter] = useState(true)
	const [filterBy, setFilterBy] = useState<any>({
		max: null,
		min: null,
		path: null,
	})
	const [allBorrowerActivityDocs, setAllBorrowerActivityDocs] = useState<
		Array<any>
	>([])
	const [allLenderActivityDocs, setAllLenderActivityDocs] = useState<
		Array<any>
	>([])

	const navigate = useNavigate()

	const listingLimit = useMemo(() => page * defaultNftPageSize, [page])
	const allUserActivity = useMemo(
		() =>
			allLenderActivityDocs
				.map((doc: any) => ({
					...doc,
					activityType: "Renter",
				}))
				.concat(
					allBorrowerActivityDocs.map((doc: any) => ({
						...doc,
						activityType: "Owner",
					}))
				),
		[allLenderActivityDocs, allBorrowerActivityDocs]
	)

	const userActivityLength = useMemo(
		() => allUserActivity.length,
		[allUserActivity]
	)
	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: loading,
			onInfiniteScroll() {
				if (!loading) {
					setPage(page + 1)
					setPrevLength(userActivityLength)
				}
				Mixpanel.track(`Inifinite Scroll Rentals Table`)
			},
		},
		[userActivityLength, loading]
	)
	useEffect((): any => {
		if (prevLength === userActivityLength && userActivityLength) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [prevLength, userActivityLength])

	useEffect(() => {
		if (authStore?.loggedUser?.addr) {
			const accountIDs = authStore?.getAccountSummaryAddresses() || []

			return subscribeToAllActivity({
				accountIDs,
				accountId: authStore?.loggedUser?.addr,
				addressPath: "data.renterAddress",
				eventCollection: "rentalEvents",
				filterActivityBy: filterBy,
				limit: listingLimit,
				listingCollection: "rentalAvailable",
				makerCb(docs) {
					setAllBorrowerActivityDocs(docs)
				},
				takerCb(docs) {
					setAllLenderActivityDocs(docs)
				},
			})
		}
		return () => {}
	}, [listingLimit, authStore?.loggedUser, filterBy])
	useEffect(() => {
		setPage(1)
	}, [filterBy])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Profile Rentals Page Filtered`, {
				filter: filterBy,
				user: authStore?.loggedUser,
			})
		}
	}, [filterBy.path])

	Log({ allLenderActivityDocs })
	return (
		<div className='w-full flex flex-col'>
			<RentalActivityFilter
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={setFilterBy}
			/>
			<button
				className='flex justify-center w-20 relative self-end -top-24 float-right bg-blue-500 text-white active:bg-blue-600 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-4 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
				type='button'
				onClick={() => {
					setShowFilter(!showFilter)
				}}
			>
				<span>Filter</span>
			</button>
			<div className='relative -top-16 '>
				<SortableTable
					onClickRow={({ item }) =>
						item?.data?.listingResourceID &&
						navigate(composeRentalListingPath(item.data.listingResourceID))
					}
					fields={userRentalActivityTableFields}
					items={allUserActivity}
					stickyHeaders
					composeRowHref={item =>
						composeRentalListingPath(item.data.listingResourceID)
					}
				/>
			</div>
			{loading ? (
				<div />
			) : (
				<>
					<ScrollToLoadMsg />
					<div ref={bottomOfPageRef} />
				</>
			)}
		</div>
	)
}

export const UserRentalActivityTable = inject("authStore")(
	observer(UserRentalActivityTableComponent)
)
