/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import {
	Trait,
	getImageURL,
	getNFTDetail,
	getNFTIdentifier,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import { SortableTableField } from ".."
import noImage from "../../../assets/media/image_not_available.svg"
import { flowty } from "../../../config/config"
import { Log } from "../../../util/Log"
import { isFlowRewards } from "../../../util/settings"
import { ActivityTableImage } from "../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { ActivityItem } from "../../Shared/ActivityTableFields"
import { TokenAmount } from "../../Tokens/tokenDisplays"

export const platformStorefrontActivityFields: (
	navigate: (path: string) => void
) => Array<SortableTableField<ActivityItem>> = navigate => [
	{
		customRender: ({ item }) => {
			let eventType: string
			const segments = item?.type.split("_")
			if (item?.type?.includes("OFFER")) {
				eventType = `${segments[1]} ${segments[2]}`
			} else {
				eventType = segments[segments.length - 1]
			}

			eventType = eventType.toLowerCase()

			return (
				<div className='flex flex-row justify-start items-center capitalize'>
					{eventType === "destroyed" ? "delisted" : eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Event",
	},
	{
		customRender: ({ item }) => (
			<div className='flex flex-row justify-start items-center'>
				{format(item?.blockTimestamp?.toDate(), "yyyy-MM-dd HH:mm")}
			</div>
		),
		name: "blockTimestamp",
		sortable: true,
		title: "Date",
	},
	{
		customRender: ({ item }) => {
			const details = getNFTDetail(null, item?.additionalData?.card)
			if (!details && item?.data?.nftID) {
				const ld = nftTypeAndIdToLocationData(
					item?.data?.nftType,
					item?.data?.nftID.toString()
				)
				const identifier = getNFTIdentifier(ld)
				flowty.api
					.refreshMetadata(identifier)
					.then()
					.catch((err: unknown) => Log(" Failed to refresh Metadata", err))
			}

			const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
				2
			)}.${item.additionalData?.card?.collectionName}.NFT`

			return (
				<div>
					{details && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.additionalData?.card?.collectionAddress || ""
									}
									collectionName={
										item?.additionalData?.card?.collectionName || ""
									}
									srcImg={getImageURL(
										item?.additionalData?.card?.images?.[0]?.url ?? noImage,
										isFlowRewards(itemType)
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item.additionalData ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item?.additionalData?.card?.title}
									</span>
								) : (
									<div className='flex flex-row'>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item?.additionalData?.card?.title}
										</span>
										{item?.additionalData?.card?.num &&
											!item.additionalData?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-1'>
													#{item?.additionalData?.card?.num}
												</span>
											)}
									</div>
								)}
								{item?.additionalData?.card?.additionalDetails ? (
									<>
										{item?.additionalData?.card?.additionalDetails ? (
											item?.additionalData?.card?.additionalDetails?.map(
												(detail: string) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.additionalData?.card?.headerTraits &&
											item?.additionalData?.card?.headerTraits?.map(
												(trait: Trait) => (
													<span
														key={trait?.displayType}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{trait?.value}
													</span>
												)
											)}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={Number(item?.amount)}
					isSmall
					token={item?.paymentTokenName}
				/>
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Price",
	},
	{
		customRender: ({ item }: any) => {
			const isOffer = item?.type?.includes("OFFER")
			const seller = isOffer
				? typeof item?.data?.taker === "object"
					? "-"
					: item?.data?.taker
				: item?.data?.storefrontAddress

			return (
				<div
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						navigate(`/user/${seller}`)
					}}
					className='seller flex flex-row justify-start items-center hover:text-green-200'
				>
					<a href={`/user/${seller}`}>{seller || "--"}</a>
				</div>
			)
		},
		name: "seller",
		sortable: false,
		title: "Seller",
	},
	{
		customRender: ({ item }: any) => {
			const isOffer = item?.type?.includes("OFFER")
			const buyer = isOffer ? item?.data?.storefrontAddress : item?.data?.buyer
			return (
				<div
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						navigate(`/user/${buyer}`)
					}}
					className='buyer flex flex-row justify-start items-center hover:text-green-200'
				>
					<a href={`/user/${buyer}`}>{buyer || "--"}</a>
				</div>
			)
		},
		name: "buyer",
		sortable: false,
		title: "Buyer",
	},
]
