import { FlowNFT, SupportedTokens } from "flowty-common"
import { useEffect, useRef, useState } from "react"
import { Flowty } from "../flowty"
import { Fees } from "../types"
import { Err } from "./Utils"

interface GetFeesProps {
	fees: Fees | null
	isLoading: boolean
}

interface UseCollectionRoyaltyProps {
	nft: FlowNFT | null
	price: number | null
	token: SupportedTokens | null
	flowty: Flowty
}

export const useGetFees = ({
	nft,
	price,
	token,
	flowty,
}: UseCollectionRoyaltyProps): GetFeesProps => {
	const [fees, setFees] = useState<Fees | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const ref = useRef(price)

	const getScriptFees = async (
		owner: string,
		storagePath: string,
		nftId: string,
		tokenIdentifier: string,
		listPrice: number
	): Promise<void> => {
		try {
			const feesResult = await flowty.scripts.getStorefrontFees(
				owner,
				storagePath,
				nftId,
				tokenIdentifier,
				listPrice
			)
			if (ref.current !== 0) setFees(feesResult)
			setIsLoading(false)
		} catch (err) {
			setFees(null)
			Err(err)
		}
	}

	useEffect(() => {
		ref.current = price
		if (nft && price && token) {
			setIsLoading(true)

			const storagePath = nft?.nftView?.collectionData?.storagePath?.identifier
			if (!storagePath || !nft.owner) {
				setFees(null)
				setIsLoading(false)
				return
			}

			getScriptFees(
				nft.owner,
				storagePath,
				nft.id.toString(),
				flowty.tokens.getTokenIdentifier(token),
				price
			)
		} else {
			setFees(null)
		}
	}, [nft, price, token])

	return { fees, isLoading }
}
