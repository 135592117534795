import { FlowtyTabs, useBreakpoint } from "ds-flowty"
import { CollectionActivityScreen } from "./components/CollectionActivityScreen"
import { CollectionRentalActivityScreen } from "./components/CollectionRentalActivityScreen"
import { CollectionStorefrontActivityScreen } from "./components/CollectionStorefrontActivityScreen"
import {
	ActivityTableTabsType,
	useCollectionActivityTables,
} from "./hooks/useCollectionActivityTables"
import { ActivityItem } from "../../../../components/Shared/ActivityTableFields"
import { CollectionActivityItem } from "../../../../components/SortableTable/PlatformActivity/collectionLoanActivityFields"

export const CollectionActivityTablesWrapper: React.FunctionComponent = () => {
	const {
		allActivityDocs,
		belowTableRef,
		filterBy,
		isLoading,
		onFilterChangeFn,
		onSortChangeFn,
		showFilter,
		setShowFilter,
		tablePage,
		sort,
		tableSelectedTab,
		onTableChangeTabFn,
	} = useCollectionActivityTables()
	const breakpoint = useBreakpoint()

	const tabs: { id: ActivityTableTabsType; label: string }[] = [
		{
			id: "SALE",
			label: "Sales",
		},
		{
			id: "LOAN",
			label: "Loans",
		},
		{
			id: "RENTAL",
			label: "Rentals",
		},
	]

	return (
		<>
			<div className='my-[4px]'>
				<FlowtyTabs
					activeTab={tableSelectedTab}
					tabs={tabs}
					onChange={tabId => onTableChangeTabFn(tabId as ActivityTableTabsType)}
					type={
						breakpoint === "xs" || breakpoint === "mobile"
							? "secondary"
							: "primary"
					}
					isCenter
				/>
			</div>

			{tableSelectedTab === "SALE" ? (
				<CollectionStorefrontActivityScreen
					filterBy={filterBy}
					isLoading={isLoading}
					onFilterChangeFn={onFilterChangeFn}
					onSortChangeFn={onSortChangeFn}
					sort={sort}
					allActivityDocs={allActivityDocs as ActivityItem[]}
					belowTableRef={belowTableRef}
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					tablePage={tablePage}
				/>
			) : tableSelectedTab === "LOAN" ? (
				<CollectionActivityScreen
					filterBy={filterBy}
					isLoading={isLoading}
					onFilterChangeFn={onFilterChangeFn}
					onSortChangeFn={onSortChangeFn}
					sort={sort}
					allActivityDocs={allActivityDocs as CollectionActivityItem[]}
					belowTableRef={belowTableRef}
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					tablePage={tablePage}
				/>
			) : (
				<CollectionRentalActivityScreen
					filterBy={filterBy}
					isLoading={isLoading}
					onFilterChangeFn={onFilterChangeFn}
					onSortChangeFn={onSortChangeFn}
					sort={sort}
					allActivityDocs={allActivityDocs as ActivityItem[]}
					belowTableRef={belowTableRef}
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					tablePage={tablePage}
				/>
			)}
		</>
	)
}
