import { FCLTransactionResult } from "flowty-common"
import { Config } from "../../types"
import { Err, sendMutation } from "../../utils/Utils"
import { setChildAccountDisplayTx } from "../transactions/setChildAccountDisplayTx"
import { EditChildAccountProps } from "./EditChildAccountTypes"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class EditChildAccount {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	editChildAccountDisplay = async ({
		childAddress,
		name,
		desc,
		thumbnail,
		txAvailableCallback,
	}: EditChildAccountProps): Promise<FCLTransactionResult> => {
		const transaction = setChildAccountDisplayTx(
			this.config.contractAddresses.HybridCustody,
			this.config.contractAddresses.MetadataViews
		)

		try {
			const txArguments = [
				fcl.arg(childAddress, t.Address),
				fcl.arg(name, t.String),
				fcl.arg(desc, t.String),
				fcl.arg(thumbnail, t.String),
			]

			const res = await sendMutation(
				transaction,
				txArguments,
				txAvailableCallback
			)

			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("Edit Child Account Error", e)
			throw e
		}
	}
}
