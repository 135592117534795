import React from "react"
import { ReactComponent as TwitterIcon } from "../../../../../../../assets/Twitter.svg"
import { ReactComponent as WebsiteIcon } from "../../../../../../../assets/WebsiteIcon.svg"
import { ReactComponent as InstagramIcon } from "../../../../../../../assets/Instagram.svg"
import { ReactComponent as DiscordIcon } from "../../../../../../../assets/Discord.svg"
import { CreatorHubSocialFieldInput } from "./SocialsInputSection/SocialsInputSection"
import { CreatorHubTooltipIcon } from "../../../../../../../../FlowtyCreatorHub/components/common/CreatorHubTooltipIcon/CreatorHubTooltipIcon"

export const CustomizeSocialIcons: React.FunctionComponent = () => {
	return (
		<div className='w-full flex flex-col gap-[12px]'>
			<div className='flex items-center gap-[4px]'>
				<div className='text-white text-xs 3xl:text-lg font-bold'>
					External Links
				</div>
				<div className='text-[#6C757D] text-xs 3xl:text-lg  font-bold'>
					{"(Optional)"}
				</div>
			</div>
			<div className='flex items-center space-x-3'>
				<CreatorHubSocialFieldInput
					socialIcon={<WebsiteIcon />}
					socialName='collectionWebsiteURL'
					socialTitle='Website'
					placeholder='https://flowty.io'
				/>
				<CreatorHubSocialFieldInput
					socialIcon={<TwitterIcon />}
					socialName='twitterHandle'
					socialTitle='Twitter'
					placeholder='https://twitter.com/flowty_io'
				/>
			</div>
			<div className='flex items-center space-x-3'>
				<CreatorHubSocialFieldInput
					socialIcon={<DiscordIcon />}
					socialName='discordHandle'
					socialTitle='Discord Invite Link'
					toolTip={
						<CreatorHubTooltipIcon
							toolTipCustomId='discordTooltip'
							renderContent={
								<span>
									Please enter an invite link that never expires. See more at
									<a
										target='_blank'
										rel='noreferrer'
										href='https://support.discord.com/hc/en-us/articles/208866998-Invites-101'
										className='ml-1 text-[#04E5A3] font-montserrat underline'
									>
										Discord Invites 101
									</a>
									.
								</span>
							}
							clickable={true}
						/>
					}
					placeholder='https://discord.gg/46URkm'
				/>
				<CreatorHubSocialFieldInput
					socialIcon={<InstagramIcon />}
					socialName='instagramHandle'
					socialTitle='Instagram'
					placeholder='https://instagram.com/flowty_io'
				/>
			</div>
		</div>
	)
}
