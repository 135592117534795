import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getFundLoanListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
) =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  getTxnCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  getTxn(config, token, nftData)

const getTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const getTxnCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import Flowty from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import AddressUtils from ${config.contractAddresses.AddressUtils}

transaction(
    listingResourceID: UInt64,
    flowtyStorefrontAddress: Address,
    ftProviderControllerID: UInt64,
    nftReceiverAddress: Address,
    ftProviderAddress: Address
) {
    let paymentVault: @{FungibleToken.Vault}
    let storefront: &{Flowty.FlowtyStorefrontPublic}
    let listing: &{Flowty.ListingPublic}

    let tokenReceiver: Capability<&{FungibleToken.Receiver}>
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: auth(Capabilities, Storage) &Account) {
        if(acct.storage.borrow<&Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath) == nil) {
            let storefront <- Flowty.createStorefront()

            acct.storage.save(<-storefront, to: Flowty.FlowtyStorefrontStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{Flowty.FlowtyStorefrontPublic}>(Flowty.FlowtyStorefrontStoragePath),
                at: Flowty.FlowtyStorefrontPublicPath
            )
        }

        self.storefront = getAccount(flowtyStorefrontAddress).capabilities.get<&{Flowty.FlowtyStorefrontPublic}>(Flowty.FlowtyStorefrontPublicPath)
            .borrow() ?? panic("Could not borrow FlowtyStorefront from provided address")

        assert(self.storefront.getType() == Type<@Flowty.FlowtyStorefront>(), message: "unexpected storefront type")

        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Listing with that ID in FlowtyStorefront. Already funded or delisted.")
        let details = self.listing.getDetails()

        let nft = self.listing.borrowNFT() ?? panic("failed to borrow listing nft")
        let nftType = nft.getType()

        let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
        var catalogIdentifier = ""
        for k in catalogIdentifiers.keys {
            if catalogIdentifiers[k] == true {
                catalogIdentifier = k
                break
            }
        }

        assert(catalogIdentifier != "", message: "no valid catalog identifier found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if nftReceiverAddress == acct.address {
            // ensure collection exists
            if acct.storage.borrow<&{NonFungibleToken.Collection}>(from: storageCollectionPath) == nil {

                // create a new Collection
                let collection <- nft.createEmptyCollection()
                // Put the new Collection in storage
                acct.storage.save(<-collection, to: storageCollectionPath)
            }

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")
            assert(manager.borrowAccount(addr: nftReceiverAddress) != nil, message: "No child account with that address")

            self.nftReceiver = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        }

        assert(self.nftReceiver.borrow() != nil, message: "Missing or mis-typed NFT Collection")

        let ftAddress = AddressUtils.parseAddress(details.paymentVaultType)!
        let contractName = details.paymentVaultType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: details.paymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        if ftProviderAddress == acct.address {
            let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
                ?? panic("Cannot borrow token vault from acct storage")
            self.paymentVault <- vault.withdraw(amount: self.listing.getDetails().getTotalPayment())

            self.tokenReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")
            let child = manager.borrowAccount(addr: ftProviderAddress) ?? panic("no child account with that address")

            let ftProviderCap = child.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("no ft provider found")
            let tokenProvider = ftProviderCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
            assert(tokenProvider.check(), message: "invalid provider capability")

            let ftProviderVault = tokenProvider.borrow() ?? panic("child account token vault could not be borrowed")
            self.paymentVault <- ftProviderVault.withdraw(amount: self.listing.getDetails().getTotalPayment())
            self.tokenReceiver = getAccount(ftProviderAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        }

        assert(self.tokenReceiver.borrow() != nil, message: "Missing or mis-typed token receiver")
    }

    execute {
        self.listing.fund(
            payment: <-self.paymentVault,
            lenderFungibleTokenReceiver: self.tokenReceiver,
            lenderNFTCollection: self.nftReceiver
        )
    }
}`
