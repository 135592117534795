import { CheckIcon } from "@heroicons/react/outline"
import React from "react"
import { cn } from "../../../util/cn"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { Tooltip } from "react-tooltip"

interface TraitFilterCheckboxProps {
	isLocked?: boolean
	isChecked: boolean
	onChange: (newCheckedState: boolean) => void
	label: string
	count: string | number
}

export const TraitFilterCheckbox: React.FC<TraitFilterCheckboxProps> = ({
	isLocked,
	isChecked,
	onChange,
	label,
	count,
}) => {
	const { isLoadingFacets } = useOpenSearchContext()
	const baseStyles =
		"text-black flex justify-center rounded-[0.25rem] items-center focus:border-none cursor-pointer h-[1rem] w-[1rem]"
	const isTruncatedLabel = label.length >= 28

	const traitLabel = isTruncatedLabel ? label.slice(0, 26) + ".." : label
	const checkboxLabel = isLocked
		? traitLabel === "true"
			? "Locked"
			: "Not Locked"
		: traitLabel

	return (
		<div
			className='w-full pt-1'
			onClick={() => {
				onChange(!isChecked)
			}}
		>
			<div
				className={cn(
					"min-w-min flex items-center text-white hover:bg-[#ffffff1f] py-2 rounded-[4px] px-2"
				)}
			>
				<div className='flex items-center space-x-3'>
					<div
						className={cn(baseStyles, {
							"bg-primary": isChecked,
							"bg-transparent border border-white": !isChecked,
							"border-none": isChecked,
						})}
					>
						{isChecked && <CheckIcon />}
					</div>
					{isTruncatedLabel && (
						<Tooltip
							id={`${traitLabel}-tooltip`}
							style={{
								backgroundColor: "white",
								color: "#031021",
								fontSize: "0.875rem",
								fontWeight: 500,
								zIndex: 10,
							}}
						/>
					)}
					<div
						data-tooltip-id={`${traitLabel}-tooltip`}
						data-tooltip-content={label}
						data-tooltip-place='top'
						className='text-[0.875rem] font-semibold'
					>
						{checkboxLabel}
					</div>
				</div>
				<div className='flex items-center ml-auto space-x-2'>
					{isLoadingFacets ? (
						<div className='w-[20px] h-[15px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
					) : (
						<div className='font-[500] text-[0.75rem]'>{count || "-"}</div>
					)}
				</div>
			</div>
		</div>
	)
}
