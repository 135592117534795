import { CreatorHubRadioSelectGroup } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubRadioGroup/CreatorHubRadioSelectGroup"
import { CreatorHubFieldInput } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubFieldInput/CreatorHubFieldInput"
import { CreatorHubUploadImage } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubUploadImage/CreatorHubUploadImage"
import { useFlowtyCreatorHubContext } from "../../../../../../../FlowtyCreatorHub/contexts/FlowtyCreatorHubContext"
import { FlowtyCreatorHubFormValues } from "../../../../../../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../../../../FlowtyCreatorHub/utils/formValidation"
import { collectionTypeOptions } from "../../utils/CollectionTypeOptions"

export const CustomizeFirstStep: React.FunctionComponent = () => {
	const { errors, touched, uploadImage, setFieldError, loggedUser } =
		useFlowtyCreatorHubContext()

	return (
		<div className='flex flex-col gap-[16px] 3xl:gap-[24px] items-center justify-center'>
			<div className='flex flex-col gap-[6.667px] 3xl:gap-[12px]'>
				<h1 className='text-white text-xs 3xl:text-lg font-bold'>
					Collection Type
				</h1>
				<CreatorHubRadioSelectGroup
					options={collectionTypeOptions}
					classNames='flex'
				/>
			</div>
			<div className='flex flex-1 w-full'>
				<CreatorHubUploadImage
					title={"Upload NFT Image*"}
					name='nftImage'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"nftImage",
						touched,
						errors
					)}
					errorMessage={
						renderErrorMessage<FlowtyCreatorHubFormValues>(
							"nftImage",
							touched,
							errors
						) as string
					}
					imageType={"THUMBNAIL"}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
			</div>
			<CreatorHubFieldInput
				title={"Collection Name*"}
				name='collectionName'
				className={`h-[26.667px] 3xl:h-[40px] w-full text-xs 3xl:text-lg bg-[#04070B] border border-[#6C757D] font-semibold rounded-[4px] 3xl:rounded-md px-3 focus:outline-none text-white shrink-0 ${
					renderInputError<FlowtyCreatorHubFormValues>(
						"collectionName",
						touched,
						errors
					)
						? "border-[#FF6E25]"
						: "border-[#6C757D]"
				}`}
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"collectionName",
					touched,
					errors
				)}
				errorMessage={
					renderErrorMessage<FlowtyCreatorHubFormValues>(
						"collectionName",
						touched,
						errors
					) as string
				}
			/>
			<CreatorHubFieldInput
				title='Description*'
				name='collectionDescription'
				className={`h-[26.667px] 3xl:h-[40px] w-full text-xs 3xl:text-lg bg-[#04070B] border border-[#6C757D] font-semibold rounded-[4px] 3xl:rounded-md px-3 focus:outline-none text-white shrink-0 ${
					renderInputError<FlowtyCreatorHubFormValues>(
						"collectionDescription",
						touched,
						errors
					)
						? "border-[#FF6E25]"
						: "border-[#6C757D]"
				}`}
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"collectionDescription",
					touched,
					errors
				)}
				errorMessage={
					renderErrorMessage<FlowtyCreatorHubFormValues>(
						"collectionDescription",
						touched,
						errors
					) as string
				}
			/>
		</div>
	)
}
