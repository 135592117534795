import { FlowtyButton } from "../../../../../FlowtyButton"
import { OngoingPromotions } from "../../../../../Shared/OngoingPromotions/OngoingPromotions"
import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { ReactComponent as CollectionDetailsIcon } from "./assets/collectionDetailsIcon.svg"
import { ReactComponent as DropDetailsIcon } from "./assets/dropDetailsIcon.svg"
import { ReactComponent as MintPriceIcon } from "./assets/mintPriceIcon.svg"
import { ReactComponent as RoyaltyIcon } from "./assets/royaltyIcon.svg"

export const WelcomeCreatorHubStep: React.FunctionComponent = () => {
	const { handleSubmit, promotion } = useFlowtyCreatorHubContext()
	return (
		<div className='w-full container creator-hub-wrapper bg-[#04070B] flex flex-col gap-12 justify-between max-lg:pt-[92px]'>
			<div className='flex h-full overflow-scroll'>
				<div
					className='lg:hidden w-full fixed top-[-60px] left-0 bg-cover bg-no-repeat bg-center max-lg:min-h-[200px] justify-center z-4'
					style={{
						backgroundImage: `url(https://storage.googleapis.com/flowty-images/drops/mobileBgCreatorHub.webp)`,
						backgroundPositionY: "bottom",
					}}
				/>
				<div className='w-fit flex items-center gap-[180px] 3xl:gap-[250px] m-auto'>
					<div className='flex flex-col gap-[30px] 3xl:gap-[40px] w-fit max-w-[374px] tablet:max-w-max'>
						<div className='flex flex-col gap-[30px] 3xl:gap-[40px]'>
							<div className='flex flex-col gap-[12px]'>
								{promotion && promotion.length > 0 && (
									<OngoingPromotions promotion={promotion[0]} />
								)}
								<h1
									className='text-white font-bold text-[24px] tablet:text-[34px] 3xl:text-[40px] leading-10 3xl:leading-tight'
									style={{
										WebkitBackgroundClip: "text",
										WebkitTextFillColor: "transparent",
										background: "linear-gradient(90deg, #FFF 0%, #AEAEAE 100%)",
										backgroundClip: "text",
									}}
									data-testid='welcome-to-flowty'
								>
									Welcome to Flowty{"'"}s Self-
									<br />
									Service NFT Launchpad!
								</h1>
								<p className='text-[12px] 3xl:text-[14px] text-[#CED4DA] font-semibold leading-5'>
									Whether you are an experienced or a first-time NFT creator,
									you can{" "}
									<span className='font-bold text-[12px] 3xl:text-[14px]'>
										create <br className='hidden tablet:flex' /> a new NFT
										collection for FREE in less than 5 minutes
									</span>{" "}
									by providing:
								</p>
							</div>
						</div>
						<div className='flex flex-col gap-[20px] 3xl:gap-[36px]'>
							<div className='flex items-center gap-[14px]'>
								<div className='rounded-lg p-[8px] 3xl:p-[17px] border border-[#ADB5BD]'>
									<CollectionDetailsIcon className='w-7 h-7 3xl:w-[32px] 3xl:h-[32px]' />
								</div>
								<div className='flex flex-col'>
									<p className='text-[16px] 3xl:text-[20px] text-[#F8F9FA] font-bold'>
										Collection Details
									</p>
									<span className='text-[12px] 3xl:text-[14px] text-[#CED4DA]'>
										Design your NFT collection by customizing images and more
									</span>
								</div>
							</div>
							<div className='flex items-center gap-[14px]'>
								<div className='rounded-lg p-[8px] 3xl:p-[17px] border border-[#ADB5BD]'>
									<MintPriceIcon className='w-7 h-7 3xl:w-[32px] 3xl:h-[32px]' />
								</div>
								<div className='flex flex-col'>
									<p className='text-[16px] 3xl:text-[20px] text-[#F8F9FA] font-bold'>
										Mint Price
									</p>
									<span className='text-[12px] 3xl:text-[14px] text-[#CED4DA]'>
										Amount collectors pay to mint one of your NFTs
									</span>
								</div>
							</div>
							<div className='flex items-center gap-[14px]'>
								<div className='rounded-lg p-[8px] 3xl:p-[17px] border border-[#ADB5BD]'>
									<RoyaltyIcon className='w-7 h-7 3xl:w-[32px] 3xl:h-[32px]' />
								</div>
								<div className='flex flex-col'>
									<p className='text-[16px] 3xl:text-[20px] text-[#F8F9FA] font-bold'>
										Royalty %
									</p>
									<span className='text-[12px] 3xl:text-[14px] text-[#CED4DA]'>
										Share of Flowty marketplace sales of your NFTs that you
										receive
									</span>
								</div>
							</div>
							<div className='flex items-center gap-[14px]'>
								<div className='rounded-lg p-[8px] 3xl:p-[17px] border border-[#ADB5BD]'>
									<DropDetailsIcon className='w-7 h-7 3xl:w-[32px] 3xl:h-[32px]' />
								</div>
								<div className='flex flex-col'>
									<p className='text-[16px] 3xl:text-[20px] text-[#F8F9FA] font-bold'>
										Drop Details
									</p>
									<span className='text-[12px] 3xl:text-[14px] text-[#CED4DA]'>
										When and where collectors mint your NFT on Flowty
									</span>
								</div>
							</div>
						</div>
						<div className='flex flex-col gap-[8px] 3xl:gap-[24px]'>
							<p className='text-[14px] 3xl:text-[16px] text-[#ADB5BD] font-medium leading-6'>
								Post-drop you can do as much or as little as you want for NFT{" "}
								<br />
								holders and collectors will be able to buy, sell, or hold your
								NFTs
							</p>
							<p className='text-[14px] 3xl:text-[16px] text-[#ADB5BD] font-medium'>
								Try it today! As a reminder, there is no cost to launch a
								collection
							</p>
						</div>
						<FlowtyButton
							type='submit'
							onClick={handleSubmit}
							text='GET STARTED'
							variant='secondary'
							bgColor='white'
						/>
					</div>
					<div className='[320px] 3xl:w-[430px] hidden lg:flex'>
						<img
							src='https://storage.googleapis.com/flowty-images/drops/rocketWelcomeView.webp'
							alt='rocket with a sunset background'
							className='max-w-[320px] 3xl:max-w-[430px] hidden lg:flex'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
