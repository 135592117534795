import { FlowtyButton } from "ds-flowty"
import { useNavigate } from "react-router-dom"

interface Props {
	isReceived?: boolean
}

export const OfferNotFound = ({ isReceived }: Props) => {
	const navigate = useNavigate()
	return (
		<div className='flex flex-col items-center'>
			<div className='relative mr-5'>
				<svg
					width='82'
					height='105'
					viewBox='0 0 82 105'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M76.5 22.5L81.5 0H44C30.8 2.4 23.5 15.3333 21.5 21.5L0 105H26L35.5 67H53.5L58.5 43.5H41.5C46 27.5 47.5 22.5 50.5 22.5H76.5Z'
						className='fill-[#b3bbbe]'
					/>
				</svg>
				<div className='flex space-x-2 left-11 bottom-0 absolute'>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
				</div>
			</div>
			<div className='uppercase text-[#B3B7BC] mt-3 pb-2'>No Offers Found</div>
			{!isReceived && (
				<FlowtyButton
					text={"View all Collections"}
					onClick={() => navigate("/collections")}
					variant={"secondary"}
					btnSize='small'
				/>
			)}
		</div>
	)
}
