import {
	assertNever,
	ContractsWithValuations,
	NFTValuationProvider,
} from "flowty-common"
import React, { ReactElement } from "react"
import { ReactComponent as ExternalLinkIcon } from "../../assets/media/external-link.svg"
import { Tooltip } from "react-tooltip"

type ValuationType = "LTV" | "DTV"

interface OverlayValuationProps {
	children: ReactElement
	valuationType: ValuationType
}

export const OverlayValuation: React.FC<OverlayValuationProps> = ({
	children,
	valuationType,
}) => {
	return (
		<div className='z-10 relative'>
			<>
				<Tooltip id={`${valuationType}-overlay`} />
				<div
					data-tooltip-id={`${valuationType}-overlay`}
					data-tooltip-content={`	Any valuation metric on flowty is derived from third-party estimations
				and metrics that do not represent financial advice nor the actual
				expected ${valuationType} or valuation of a listing or NFT. Please do
				your own research.`}
				/>
				{children}
			</>
		</div>
	)
}

interface ValuationLabelProps {
	contractName: ContractsWithValuations
	nftID: string
	labelType: ValuationType
}

export const ValuationLabel: React.FC<ValuationLabelProps> = ({
	contractName,
	nftID,
	labelType,
}) => {
	switch (contractName) {
		case "Flovatar":
			return (
				<a
					target='_blank'
					rel='noreferrer'
					href={`https://flov.dev/?mint=${nftID}`}
				>
					<OverlayValuation valuationType={labelType}>
						<p className='flex pt-1'>
							{NFTValuationProvider.FlovDev} {labelType}
							<ExternalLinkIcon className='inline-block pb-1' />
						</p>
					</OverlayValuation>
				</a>
			)
		case "TopShot":
		case "AllDay":
			return (
				<OverlayValuation valuationType={labelType}>
					<p className='flex text-lightText text-lg font-light'>
						Blended {labelType}
					</p>
				</OverlayValuation>
			)
		default:
			return assertNever(contractName)
	}
}
