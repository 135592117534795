import { useEffect, useState } from "react"

import classNames from "classnames"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { ProfileLayout } from "./ProfileLayout"
import UserStorefrontActivityTable from "./Tables/Storefront/AllActivity/UserStorefrontActivityTable"

const storefrontTabs = [
	{
		content: <UserStorefrontActivityTable userRole='buyerAndSeller' />,
		name: "Activity",
	},
	{
		content: <UserStorefrontActivityTable userRole='buyer' />,
		name: "Buyer",
	},
	{
		content: <UserStorefrontActivityTable userRole='seller' />,
		name: "Seller",
	},
]

export const StorefrontHistory = () => {
	const [currentTab, setCurrentTab] = useState(storefrontTabs[0].name)

	useEffect(() => {
		Mixpanel.track("Profile Activity Storefront Visited", { currentTab })
	}, [currentTab])

	return (
		<>
			<ProfileLayout />
			<div className='mt-4 transition duration-700'>
				<div>
					<nav className='-mb-px flex space-x-8' aria-label='loanTabs'>
						{storefrontTabs.map(tab => (
							<div
								key={tab.name}
								onClick={() => {
									setCurrentTab(tab.name)
									Mixpanel.track(
										`Profile Activity Storefront Toggled: ${tab.name}`,
										{
											tab: tab.name,
										}
									)
								}}
								className={classNames(
									tab.name === currentTab
										? "border-indigo-500 text-white"
										: "border-transparent text-gray-500 hover:text-white hover:border-indigo-500",
									"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
								)}
							>
								{tab.name}
							</div>
						))}
					</nav>
					<div className='line-separator bg-gradient-to-r from-darkBg via-secondary to-darkBg' />
				</div>
				<div className='mt-8 mb-3'>
					{storefrontTabs.map(tab => {
						if (tab.name === currentTab) {
							return <div key={tab.name}>{tab.content}</div>
						}
						return null
					})}
				</div>
			</div>
		</>
	)
}
