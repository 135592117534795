export const truncateString = (
	text: string | null | undefined,
	maxLength: number = 60
): string => {
	if (!text) return ""

	return text.length > maxLength ? `${text.substring(0, maxLength)}..` : text
}

const utf8Encoder = new TextEncoder()

export const utf8ToHexString = (text: string): string => {
	const buffer = utf8Encoder.encode(text)

	let hexStrResult = ""
	buffer.forEach(currByte => {
		const byteHexString = currByte.toString(16)
		hexStrResult += `0${byteHexString}`.slice(-2)
	})

	return hexStrResult
}

export const safeParseJSON = <T extends object>(
	str?: string | null
): T | null => {
	try {
		return JSON.parse(str ?? "")
	} catch (err) {
		return null
	}
}

export const formatCollectionDisplayName = (s: string): string => {
	if (s === "") {
		return "<NO NAME>"
	}
	return s
		.split("-")
		.map(sp => sp[0].toUpperCase() + sp.substring(1))
		.join(" ")
}

export const formatAddress = (address: string): string => {
	if (!address) {
		return ""
	}

	if (address.startsWith("0x")) {
		return address
	}

	return `0x${address}`
}
