import { cn } from "../utils/cn"

export interface FlowtyBadgeProps {
	text: string
	type?: "primary" | "secondary" | "tertiary"
	size?: "small" | "large"
	status?: "PROCESSING" | "SUCCESSFUL" | "FAILED"
}

export const FlowtyBadge = ({
	text,
	type,
	size = "large",
	status,
}: FlowtyBadgeProps) => {
	const baseStyles =
		"flex items-center px-[0.375rem] gap-[0.625rem] font-montserrat text-navy-blue-900 font-[800] text-[0.625rem] uppercase rounded-[0.375rem]"

	return (
		<span
			className={cn(baseStyles, {
				"bg-[#41625E] text-[#89F6C2] text-[10px] font-[800] tracking-[.55px]":
					status === "SUCCESSFUL",
				"bg-[#624141] text-[#F68989] text-[10px] font-[800] tracking-[.55px]":
					status === "FAILED",
				"bg-[#727272] text-[#E9E9E9] text-[10px] font-[800] tracking-[.55px]":
					status === "PROCESSING",
				"bg-cyan-300": type === "tertiary",
				"bg-primary": type === "primary",
				"bg-white": type === "secondary",
				"py-[0.125rem]": size === "large",
			})}
		>
			{text}
		</span>
	)
}
