import React from "react"
import { Log } from "../../util/Log"
import { flowty } from "../../config/config"
import { getNFTIdentifier, nftTypeAndIdToLocationData } from "flowty-common"

interface RefreshButtonProps {
	nftID: string
	nftType: string
}

export const NftRefreshButton: React.FC<RefreshButtonProps> = ({
	nftID,
	nftType,
}) => {
	const ld = nftTypeAndIdToLocationData(nftType, nftID)
	const nftIdentifier = getNFTIdentifier(ld)

	const refreshNft = (): void => {
		flowty.api
			.refreshMetadata(nftIdentifier)
			.then(() => window.location.reload())
			.catch((err: unknown) => Log(" Failed to refresh Metadata", err))
	}
	return (
		<div onClick={refreshNft} className='flex items-end justify-center'>
			<svg
				className='h-6 w-6 hover:stroke-emerald-500 cursor-pointer stroke-2'
				viewBox='0 0 24 24'
				stroke='currentColor'
			>
				<path d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15' />
			</svg>
		</div>
	)
}
