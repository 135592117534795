import React from "react"

const NoResults = () => {
	return (
		<svg
			width='158'
			height='132'
			viewBox='0 0 158 132'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M78.9404 132C115.389 132 144.94 102.449 144.94 66C144.94 29.5515 115.389 0 78.9404 0C42.4919 0 12.9404 29.5515 12.9404 66C12.9404 102.449 42.4919 132 78.9404 132Z'
				fill='#6C757D'
				fillOpacity='0.4'
			/>
			<path
				d='M105.088 19.008H54.1906C49.3305 19.008 45.3906 22.9479 45.3906 27.808V95.4745C45.3906 100.335 49.3305 104.274 54.1906 104.274H105.088C109.948 104.274 113.888 100.335 113.888 95.4745V27.808C113.888 22.9479 109.948 19.008 105.088 19.008Z'
				fill='#F8F9FA'
				fillOpacity='0.14'
			/>
			<path
				d='M54.1354 91.168H103.745C105.236 91.168 106.44 92.3725 106.44 93.863C106.44 95.3535 105.236 96.558 103.745 96.558H54.1354C52.6449 96.558 51.4404 95.3535 51.4404 93.863C51.4404 92.3725 52.6449 91.168 54.1354 91.168Z'
				fill='#D5D5D5'
			/>
			<path
				d='M154.923 9.58099H121.879C120.339 9.58099 119.091 10.8955 119.091 12.5125V28.8585C119.091 30.481 120.339 31.7955 121.879 31.7955H154.923C156.463 31.7955 157.712 30.481 157.712 28.8585V12.5125C157.712 10.89 156.463 9.58099 154.923 9.58099Z'
				fill='#575A5D'
			/>
			<g opacity='0.7'>
				<path
					d='M84.5994 46.9151L86.5805 38H71.722C66.4919 38.9509 63.5994 44.0755 62.807 46.5189L54.2881 79.6038H64.59L68.3541 64.5472H75.4862L77.4673 55.2358H70.7315C72.5145 48.8962 73.1088 46.9151 74.2975 46.9151H84.5994Z'
					fill='white'
				/>
				<path
					d='M78.2599 75.6414C78.2599 78.0486 76.3086 79.9999 73.9015 79.9999C71.4943 79.9999 69.543 78.0486 69.543 75.6414C69.543 73.2343 71.4943 71.283 73.9015 71.283C76.3086 71.283 78.2599 73.2343 78.2599 75.6414Z'
					fill='#B3BBBE'
				/>
				<path
					d='M89.5519 75.6414C89.5519 78.0486 87.6006 79.9999 85.1935 79.9999C82.7863 79.9999 80.835 78.0486 80.835 75.6414C80.835 73.2343 82.7863 71.283 85.1935 71.283C87.6006 71.283 89.5519 73.2343 89.5519 75.6414Z'
					fill='#B3BBBE'
				/>
				<path
					d='M100.845 75.6414C100.845 78.0486 98.8935 79.9999 96.4864 79.9999C94.0793 79.9999 92.1279 78.0486 92.1279 75.6414C92.1279 73.2343 94.0793 71.283 96.4864 71.283C98.8935 71.283 100.845 73.2343 100.845 75.6414Z'
					fill='#B3BBBE'
				/>
			</g>
			<path
				d='M127.341 24.2C129.162 24.2 130.641 22.7205 130.641 20.9C130.641 19.0795 129.162 17.6 127.341 17.6C125.521 17.6 124.041 19.0795 124.041 20.9C124.041 22.7205 125.521 24.2 127.341 24.2Z'
				fill='#989EA1'
			/>
			<path
				d='M138.34 17.6H149.34C151.161 17.6 152.64 19.0795 152.64 20.9C152.64 22.7205 151.161 24.2 149.34 24.2H138.34C136.52 24.2 135.04 22.7205 135.04 20.9C135.04 19.0795 136.52 17.6 138.34 17.6Z'
				fill='#D5D5D5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M98.2513 108.108C105.335 108.108 111.919 105.963 117.391 102.294L139.776 123.662L147.894 114.246L126.296 93.6265C130.283 88.011 132.632 81.147 132.632 73.7385C132.632 54.7525 117.243 39.3635 98.2568 39.3635C79.2708 39.3635 63.8818 54.7525 63.8818 73.7385C63.8818 92.7245 79.2708 108.113 98.2568 108.113L98.2513 108.108ZM127.572 73.733C127.572 89.804 114.542 102.833 98.4713 102.833C82.4003 102.833 69.3708 89.804 69.3708 73.733C69.3708 57.662 82.4003 44.6325 98.4713 44.6325C114.542 44.6325 127.572 57.662 127.572 73.733Z'
				fill='#BEFFEC'
			/>
			<path
				d='M103.647 73.7L110.902 66.473C111.584 65.758 111.952 64.8065 111.941 63.822C111.93 62.8375 111.529 61.9025 110.83 61.204C110.132 60.511 109.186 60.1095 108.196 60.0985C107.206 60.0875 106.249 60.456 105.528 61.1325L98.2573 68.3595L91.0028 61.1325C90.6563 60.764 90.2328 60.4725 89.7708 60.269C89.3033 60.0655 88.8028 59.9555 88.2968 59.95C87.7908 59.9445 87.2848 60.038 86.8118 60.225C86.3388 60.4175 85.9153 60.698 85.5523 61.0555C85.1948 61.413 84.9088 61.8365 84.7163 62.304C84.5238 62.7715 84.4303 63.272 84.4358 63.778C84.4413 64.284 84.5513 64.779 84.7548 65.2465C84.9583 65.7085 85.2553 66.1265 85.6238 66.473L92.8893 73.7L85.6238 80.927C85.2553 81.2735 84.9583 81.6915 84.7548 82.1535C84.5513 82.6155 84.4413 83.116 84.4358 83.622C84.4303 84.128 84.5238 84.6285 84.7163 85.096C84.9088 85.5635 85.1893 85.987 85.5523 86.3445C85.9098 86.702 86.3388 86.9825 86.8118 87.175C87.2848 87.3675 87.7853 87.461 88.2968 87.45C88.8083 87.439 89.3088 87.3345 89.7708 87.131C90.2383 86.9275 90.6563 86.636 91.0028 86.2675L98.2738 79.0405L105.545 86.2675C106.271 86.9 107.211 87.241 108.179 87.208C109.147 87.175 110.066 86.779 110.748 86.1025C111.43 85.4205 111.826 84.5075 111.853 83.545C111.881 82.5825 111.54 81.6475 110.896 80.927L103.642 73.7H103.647Z'
				fill='#BEFFEC'
			/>
			<path
				d='M139.771 123.662L147.888 114.24L149.109 115.406C150.297 116.539 151.007 118.118 151.084 119.806C151.161 121.489 150.6 123.139 149.528 124.388C148.45 125.636 146.948 126.384 145.347 126.467C143.747 126.549 142.179 125.961 140.997 124.828L139.776 123.662H139.771Z'
				fill='#BEFFEC'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4114 34.1935C27.4004 33.407 27.3014 32.6205 27.1089 31.823C26.4159 29.029 23.3414 27.236 20.0634 26.8125C16.7854 26.389 13.3809 27.3515 12.0664 29.7825C11.3129 31.174 11.2139 32.373 11.5164 33.3795C11.8244 34.386 12.5449 35.2165 13.5294 35.8655C16.2739 37.675 21.0754 38.0435 23.2699 37.2845C24.2819 36.9325 25.2719 36.5145 26.2399 36.0305C25.6899 39.193 23.6329 42.185 20.8939 44.88C14.9319 50.743 5.68641 55.1925 0.648407 56.8315C0.378907 56.9195 0.224906 57.2165 0.312906 57.5025C0.395406 57.783 0.686905 57.9425 0.956405 57.8545C6.09341 56.188 15.5204 51.6395 21.5979 45.661C24.7384 42.57 26.9769 39.094 27.3564 35.442C34.4514 31.482 40.2814 24.2935 45.2644 18.249C45.4514 18.029 45.4239 17.688 45.2094 17.4955C44.9949 17.303 44.6704 17.325 44.4834 17.5505C39.7039 23.353 34.1544 30.25 27.4114 34.1935ZM26.3829 34.771C26.4214 33.891 26.3389 32.9945 26.1134 32.0925C25.5139 29.6725 22.7749 28.2425 19.9369 27.8795C18.1989 27.654 16.4114 27.841 15.0089 28.4955C14.1289 28.908 13.4029 29.502 12.9629 30.316C12.3909 31.3775 12.2644 32.2905 12.5009 33.0605C12.7374 33.836 13.3204 34.463 14.0849 34.9635C16.5874 36.6135 20.9599 36.96 22.9564 36.2725C24.1334 35.8655 25.2774 35.3595 26.3884 34.771H26.3829Z'
				fill='#BDBDBD'
			/>
			<path
				d='M153.191 88.55C155.013 88.55 156.491 87.0726 156.491 85.25C156.491 83.4275 155.013 81.95 153.191 81.95C151.368 81.95 149.891 83.4275 149.891 85.25C149.891 87.0726 151.368 88.55 153.191 88.55Z'
				fill='#E3E3E3'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M28.5492 108.015C29.1377 107.795 29.7592 107.503 30.2377 107.074C30.8042 106.568 31.0352 105.919 31.1892 105.237C31.3872 104.357 31.4697 103.422 31.7117 102.542C31.7997 102.212 31.9702 102.091 32.0472 102.036C32.2342 101.898 32.4212 101.86 32.5972 101.877C32.8062 101.893 33.0977 101.975 33.2847 102.349C33.3122 102.404 33.3452 102.482 33.3727 102.597C33.3892 102.68 33.4002 102.932 33.4222 103.037C33.4662 103.295 33.5047 103.554 33.5432 103.812C33.6642 104.676 33.7302 105.407 34.1097 106.199C34.6212 107.277 35.1327 107.932 35.8312 108.223C36.5022 108.504 37.3107 108.454 38.3392 108.229C38.4382 108.201 38.5317 108.185 38.6307 108.163C39.0817 108.08 39.5162 108.394 39.6042 108.867C39.6922 109.34 39.4062 109.802 38.9552 109.906C38.8617 109.928 38.7682 109.95 38.6802 109.967C37.2887 110.33 35.6827 111.622 34.7477 112.75C34.4617 113.096 34.0382 114.075 33.6092 114.697C33.2902 115.153 32.9327 115.456 32.6357 115.566C32.4322 115.637 32.2672 115.626 32.1242 115.588C31.9207 115.533 31.7502 115.417 31.6237 115.236C31.5522 115.137 31.4862 114.999 31.4587 114.829C31.4422 114.746 31.4422 114.537 31.4422 114.438C31.3542 114.119 31.2497 113.811 31.1672 113.492C30.9802 112.728 30.6172 112.244 30.1827 111.606C29.7757 111.007 29.3412 110.632 28.6977 110.33C28.6152 110.308 27.9442 110.137 27.7077 110.038C27.3612 109.895 27.1962 109.648 27.1357 109.516C27.0312 109.296 27.0257 109.098 27.0422 108.938C27.0697 108.696 27.1742 108.493 27.3557 108.328C27.4657 108.223 27.6362 108.124 27.8617 108.075C28.0377 108.036 28.4942 108.015 28.5602 108.015H28.5492ZM32.4927 106.771C32.5257 106.843 32.5587 106.92 32.5917 106.991C33.3397 108.57 34.1812 109.45 35.1987 109.873L35.2317 109.89C34.5497 110.423 33.9337 111.018 33.4607 111.59C33.2682 111.826 33.0097 112.315 32.7292 112.816C32.4762 111.952 32.0637 111.342 31.5467 110.577C31.1507 109.994 30.7327 109.555 30.2212 109.197C30.6172 108.983 30.9967 108.729 31.3267 108.438C31.8767 107.948 32.2397 107.387 32.4872 106.771H32.4927Z'
				fill='#CCC6D9'
			/>
		</svg>
	)
}

export default NoResults
