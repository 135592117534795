import { Field, useField } from "formik"
import React from "react"
import { cn } from "../utils/cn"

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement>

export type FlowtyCheckboxProps = {
	id: string
	name: string
	label?: string | React.ReactNode
	disabled?: boolean
	checkboxSize?: "small" | "medium"
	isError?: boolean
	errorMessage?: string
	className?: string
}

export const FlowtyCheckbox: React.FC<FlowtyCheckboxProps & CheckboxProps> = ({
	id,
	name,
	label,
	disabled = false,
	checkboxSize = "small",
	isError = false,
	errorMessage = "",
	className = "",
}) => {
	const baseStyles = `text-black flex justify-center rounded-[4px] items-center border focus:border-none cursor-pointer`

	const [field] = useField({ name })
	const isChecked = field.value

	return (
		<label
			className='font-montserrat flex items-center max-w-md gap-[8px] text-[12px] font-[400] leading-[12px] text-neutral-700 hover:text-white focus:text-white cursor-pointer'
			htmlFor={id}
		>
			<div className='flex flex-col gap-[4px]'>
				{isError && !isChecked && (
					<span className='text-[#FF6969] text-base h-[8px] w-[4px]'>*</span>
				)}
				<div className='flex items-center gap-2'>
					<Field
						role='checkbox'
						type='checkbox'
						id={id}
						name={name}
						disabled={disabled}
						className='hidden'
						data-testid={name}
					/>
					<div
						className={cn(baseStyles, {
							"bg-primary": isChecked,
							"bg-transparent": !isChecked && checkboxSize === "small",
							"bg-white": !isChecked && checkboxSize === "medium",
							"border-neutral-300  hover:border-neutral-300": disabled,
							"border-neutral-300  hover:border-neutral-500":
								checkboxSize === "medium",
							"border-none": isChecked,
							"border-white hover:border-neutral-700": checkboxSize === "small",
							"h-[1.25rem] w-[1.25rem]": checkboxSize === "small",
							"h-[1.5rem] w-[1.5rem]": checkboxSize === "medium",
						})}
					>
						{isChecked && (
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M13.3657 4.23431C13.6781 4.54673 13.6781 5.05327 13.3657 5.36569L6.96571 11.7657C6.65329 12.0781 6.14676 12.0781 5.83434 11.7657L2.63434 8.56569C2.32192 8.25327 2.32192 7.74673 2.63434 7.43431C2.94676 7.1219 3.45329 7.1219 3.76571 7.43431L6.40002 10.0686L12.2343 4.23431C12.5468 3.9219 13.0533 3.9219 13.3657 4.23431Z'
									fill='#031021'
								/>
							</svg>
						)}
					</div>
					<span
						className={`${
							disabled ? "text-neutral-300" : "text-white"
						} w-fit ${className}`}
					>
						{label}
					</span>
				</div>
				{isError && !isChecked && errorMessage && (
					<div className='flex items-center gap-1'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='18'
							height='18'
							viewBox='0 0 18 18'
							fill='none'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M7.43086 2.78911C8.11901 1.56574 9.88039 1.56574 10.5685 2.78911L15.5908 11.7176C16.2657 12.9175 15.3987 14.4001 14.022 14.4001H3.97743C2.60074 14.4001 1.73365 12.9175 2.40859 11.7176L7.43086 2.78911ZM9.89962 11.7002C9.89962 12.1972 9.49668 12.6002 8.99962 12.6002C8.50257 12.6002 8.09962 12.1972 8.09962 11.7002C8.09962 11.2031 8.50257 10.8002 8.99962 10.8002C9.49668 10.8002 9.89962 11.2031 9.89962 11.7002ZM8.99962 4.50017C8.50257 4.50017 8.09962 4.90311 8.09962 5.40017V8.10017C8.09962 8.59723 8.50257 9.00017 8.99962 9.00017C9.49668 9.00017 9.89962 8.59723 9.89962 8.10017V5.40017C9.89962 4.90311 9.49668 4.50017 8.99962 4.50017Z'
								fill='#FF6969'
							/>
						</svg>
						<span className='text-[#FF6969] font-normal text-xs'>
							{errorMessage}
						</span>
					</div>
				)}
			</div>
		</label>
	)
}
