import { ProfileLink } from "../common/ProfileLink"

interface Props {
	lender: string
	renter: string
}

export const RentSettledRow: React.FunctionComponent<Props> = ({
	lender,
	renter,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<ProfileLink address={renter} /> did not return NFT and the{" "}
				<span className='font-bold text-[14px]'>refundable deposit</span> was
				sent to <ProfileLink address={lender} />
			</p>
		</div>
	)
}
