import { useFlowtyCreatorHubContext } from "../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../types/FlowtyCreatorHubContextTypes"
import { WelcomeCreatorHubStep } from "./FormView/components/WelcomeCreatorHubStep/WelcomeCreatorHubStep"
import { FormView } from "./FormView/FormView"
import { PreviewView } from "./PreviewView/PreviewView"

export const CreatorHubWrapper: React.FunctionComponent = () => {
	const { formTab } = useFlowtyCreatorHubContext()

	if (formTab === FormTabs.WelcomeCreatorHubTab)
		return <WelcomeCreatorHubStep />

	return (
		<div className='bg-[#04070B] w-full flex flex-col gap-[18px] px-[14px] md:pr-[8px] md:pl-[40px] lg:px-[40px] md:py-[22px] pt-[15px] 3xl:py-[52px] creator-hub-wrapper'>
			<div className='flex gap-[26px] lg:gap-[52px] h-full overflow-hidden'>
				<FormView />

				<div className='hidden md:flex flex-1 overflow-x-auto'>
					<PreviewView />
				</div>
			</div>
		</div>
	)
}
