import ashImage from "./SVGs/ash.png"
import austinImage from "./SVGs/austin.png"
import egtImage from "./SVGs/egt.png"
import felipeImage from "./SVGs/felipe.png"
import michaelImage from "./SVGs/michael.png"
import trevorImage from "./SVGs/trevor.png"

export const FlowtyTeam: React.FC = () => {
	const teamMembers = [
		{
			image: michaelImage,
			name: "MBL267",
			twitterUrl: "https://twitter.com/mbl267_NFT",
		},
		{
			image: austinImage,
			name: "AUSTIN",
			twitterUrl: "https://twitter.com/austin_flowty",
		},
		{
			image: felipeImage,
			name: "FRLABS33",
			twitterUrl: "https://twitter.com/Frlabs33",
		},
		{
			image: ashImage,
			name: "WEB3ASHLEE",
			twitterUrl: "https://twitter.com/web3ashlee",
		},
		{
			image: egtImage,
			name: "EGT",
			twitterUrl: "https://twitter.com/egt_flow",
		},
		{
			image: trevorImage,
			name: "TREVOR",
			twitterUrl: "https://twitter.com/jamesdillonbond",
		},
	]

	return (
		<div className='container mx-auto py-[40px] md:py-[80px] lg:py-[128px] 2xl:max-w-[1920px]'>
			<h2 className='text-center font-extrabold text-[18px] md:text-[32px] md:text-4xl leading-normal text-white'>
				Built For Collectors, By Collectors
			</h2>
			<div className='mt-[40px] md:mt-[52px] lg-mt-[64px] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-y-[24px] md:gap-[60px] justify-items-center'>
				{teamMembers.map(member => (
					<a
						target='_blank'
						href={member.twitterUrl}
						rel='noopener noreferrer'
						key={member.name}
						className='flex flex-col items-center gap-[12px] md:gap-[16px] group opacity-60 hover:opacity-100'
					>
						<img
							src={member.image}
							alt={member.name}
							className='rounded-full w-[107px] h-[107px] md:w-[140px] md:h-[140px]'
						/>
						<div className='flex flex-row justify-center items-center'>
							<span className='font-montserrat text-[#6C757D] text-[14px] md:text-[18px] leading-normal font-bold group-hover:text-[#CED4DA]'>
								{member.name}
							</span>
							<span className='font-montserrat text-[#6C757D] text-[14px] md:text-[18px] leading-normal mx-[8px] font-bold group-hover:text-[#CED4DA]'>
								|
							</span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='21'
								viewBox='0 0 20 21'
								fill='none'
								className='hidden group-hover:block'
							>
								<g clipPath='url(#clip0_10410_653)'>
									<path
										d='M11.8608 9.06426L19.1424 0.599976H17.4169L11.0943 7.9494L6.04445 0.599976H0.220032L7.85641 11.7136L0.220032 20.5897H1.94564L8.62249 12.8285L13.9555 20.5897H19.7799L11.8604 9.06426H11.8608ZM9.49737 11.8115L8.72365 10.7048L2.5674 1.89899H5.21783L10.186 9.0056L10.9597 10.1123L17.4177 19.3498H14.7673L9.49737 11.8119V11.8115Z'
										fill='#CED4DA'
									/>
								</g>
								<defs>
									<clipPath id='clip0_10410_653'>
										<rect
											width='19.5599'
											height='20'
											fill='white'
											transform='translate(0.220032 0.599976)'
										/>
									</clipPath>
								</defs>
							</svg>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='20'
								viewBox='0 0 21 20'
								fill='none'
								className='block group-hover:hidden'
							>
								<g clipPath='url(#clip0_10772_360)'>
									<path
										d='M12.1398 8.46429L19.4214 0H17.6959L11.3733 7.34942L6.32344 0H0.499023L8.13541 11.1136L0.499023 19.9897H2.22463L8.90149 12.2285L14.2345 19.9897H20.0589L12.1394 8.46429H12.1398ZM9.77637 11.2115L9.00264 10.1049L2.84639 1.29901H5.49682L10.465 8.40562L11.2387 9.51229L17.6967 18.7498H15.0463L9.77637 11.212V11.2115Z'
										fill='#6C757D'
									/>
								</g>
								<defs>
									<clipPath id='clip0_10772_360'>
										<rect
											width='19.5599'
											height='20'
											fill='white'
											transform='translate(0.499023)'
										/>
									</clipPath>
								</defs>
							</svg>
						</div>
					</a>
				))}
			</div>
		</div>
	)
}
