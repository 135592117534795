import { FCLTransactionResult } from "flowty-common"
import { Config } from "../types"
import { Err, sendMutation } from "../utils/Utils"
import {
	RedeemAllTicketsLostAndFoundProps,
	RedeemLostAndFoundProps,
} from "./LostAndFoundTypes"
import { redemAllItemsTx } from "./transactions/redeemAllItems"
import { redeemItemTx } from "./transactions/redeemItem"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class LostAndFound {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	redeemLostAndFound = async ({
		catalogIdentifier,
		nftTypeIdentifier,
		receiverAddress,
		ticketID,
		txAvailableCallback,
	}: RedeemLostAndFoundProps): Promise<FCLTransactionResult> => {
		const txArgs = [
			fcl.arg(receiverAddress, t.Address),
			fcl.arg(ticketID.toString(), t.UInt64),
			fcl.arg(catalogIdentifier, t.String),
			fcl.arg(nftTypeIdentifier, t.String),
		]

		const redeemTx = redeemItemTx(this.config)

		try {
			const res = await sendMutation(redeemTx, txArgs, txAvailableCallback)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("redeem LostAndFound Error", e)
			throw e
		}
	}

	redeemAllTicketsLostAndFound = async ({
		catalogIdentifier,
		nftTypeIdentifier,
		receiverAddress,
		txAvailableCallback,
	}: RedeemAllTicketsLostAndFoundProps): Promise<FCLTransactionResult> => {
		const txArgs = [
			fcl.arg(receiverAddress, t.Address),
			fcl.arg(catalogIdentifier, t.String),
			fcl.arg(nftTypeIdentifier, t.String),
		]

		const redeemTx = redemAllItemsTx(this.config)

		try {
			const res = await sendMutation(redeemTx, txArgs, txAvailableCallback)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("redeem LostAndFound Error", e)
			throw e
		}
	}
}
