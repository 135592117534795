import {
	and,
	collection,
	limit as firebaseLimit,
	getDocs,
	onSnapshot,
	or,
	orderBy,
	query,
	where,
} from "firebase/firestore"

import { Unsubscribe } from "firebase/auth"
import { db } from "../../../firebase"
import { LoanEvent } from "../components/HistoryTab/types/LoanEventTypes"
import { RentEvent } from "../components/HistoryTab/types/RentEventTypes"
import { StorefrontEvent } from "../components/HistoryTab/types/StorefrontEventTypes"

interface SubscribeProps {
	limit: number
	cb: ({
		data,
		totalCount,
	}: {
		data: Array<StorefrontEvent | RentEvent | LoanEvent>
		totalCount: number
	}) => void
	nftId: string
	nftType: string
}

export const getAllNFTActivity = ({
	limit = 45,
	cb,
	nftId,
	nftType,
}: SubscribeProps): Unsubscribe => {
	const baseQuery = collection(db, "events")

	let countQuery = query(baseQuery)

	let storefrontQuery = query(baseQuery)

	storefrontQuery = query(
		storefrontQuery,
		or(
			and(
				where("data.nftType", "==", nftType),
				where("data.nftID", "==", nftId)
			),
			and(
				where("data.typeAndIDOffer.nftType", "==", nftType),
				where("data.typeAndIDOffer.nftID", "==", nftId)
			)
		)
	)

	countQuery = query(
		countQuery,
		or(
			and(
				where("data.nftType", "==", nftType),
				where("data.nftID", "==", nftId)
			),
			and(
				where("data.typeAndIDOffer.nftType", "==", nftType),
				where("data.typeAndIDOffer.nftID", "==", nftId)
			)
		)
	)

	storefrontQuery = query(storefrontQuery, orderBy("blockTimestamp", "desc"))
	storefrontQuery = query(storefrontQuery, firebaseLimit(limit))

	const fetchCount = getDocs(countQuery).then(
		countSnapshot => countSnapshot.size
	)

	const fetchPageData = onSnapshot(storefrontQuery, snapshot => {
		const storefrontData = snapshot.docs.map(
			doc =>
				({
					id: doc.id,
					...doc.data(),
				} as StorefrontEvent | RentEvent | LoanEvent)
		)

		if (storefrontData) {
			fetchCount.then(totalCount => {
				cb({ data: storefrontData, totalCount })
			})
		}
	})

	return fetchPageData
}
