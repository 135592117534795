import React, { ReactNode } from "react"
import { FlowtyFieldInput } from "../../../../../../../../../FlowtyFieldInput"

interface CreatorHubSocialFieldInputProps {
	socialTitle: string
	socialName: string
	socialIcon: ReactNode
	toolTip?: ReactNode
	placeholder?: string
}

export const CreatorHubSocialFieldInput: React.FC<
	CreatorHubSocialFieldInputProps
> = ({ socialName, socialTitle, socialIcon, toolTip, placeholder }) => {
	return (
		<div className='flex flex-col gap-[8px] w-full'>
			<div className='flex items-center gap-x-2'>
				{socialIcon}
				<p className='text-white text-xs 3xl:text-lg font-bold'>
					{socialTitle}
				</p>
				{toolTip}
			</div>
			<FlowtyFieldInput
				name={socialName}
				placeholder={placeholder}
				className={
					"h-[26.667px] 3xl:h-[40px] w-full text-xs 3xl:text-lg bg-[#04070B] border border-[#6C757D] font-semibold rounded-[4px] 3xl:rounded-md px-3 focus:outline-none text-white shrink-0"
				}
			/>
		</div>
	)
}
