import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"

export const getRepayLoanTxn = (config: Config, token: TokenMetadata): string =>
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  repayLoanCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  repayLoan(config, token)

const repayLoan = (config: Config, token: TokenMetadata): string => ``

const repayLoanCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import Flowty from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import AddressUtils from ${config.contractAddresses.AddressUtils}

transaction(fundingResourceID: UInt64, flowtyMarketplaceAddress: Address, ftProviderAddress: Address, ftProviderControllerID: UInt64) {
    let paymentVault: @{FungibleToken.Vault}
    let marketplace: &{Flowty.FlowtyMarketplacePublic}
    let funding: &{Flowty.FundingPublic}

    prepare(acct: auth(Capabilities, Storage) &Account) {
        self.marketplace = Flowty.borrowMarketplacePublic()

        self.funding = self.marketplace.borrowFunding(fundingResourceID: fundingResourceID)
            ?? panic("No Funding with that ID in FlowtyMarketplace")

        let details = self.funding.getDetails()
        
        let ftAddress = AddressUtils.parseAddress(details.paymentVaultType)!
        let contractName = details.paymentVaultType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: details.paymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        let repaymentAmount = self.funding.getDetails().repaymentAmount

        if ftProviderAddress == acct.address{
            let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: ftVaultData.storagePath)
                ?? panic("Cannot borrow vault from acct storage")
            self.paymentVault <- vault.withdraw(amount: repaymentAmount)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

            let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("no cap found")
            let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
            let ftProviderVault = ftProvider.borrow() ?? panic("no vault found")

            self.paymentVault <- ftProviderVault.withdraw(amount: repaymentAmount)
        }
    }

    execute {
        self.funding.repay(payment: <-self.paymentVault)
    }

    post {
        self.funding.getDetails().repaid == true: "loan is not repaid"
    }
}`
