import { cn } from "../../../utils/cn"
import { LockClosedIcon } from "@heroicons/react/solid"

interface ListingPillIndicatorProps {
	label: string
	count?: number
}

const getPillColor = (label: string) => {
	switch (label) {
		case "LOAN":
			return "bg-[#2F2FA5] text-[#9D9DFE] border-[#9D9DFE]"
		case "RENT":
			return "bg-[#973C19] text-[#F3B59C] border-[#F3B59C]"
		case "LOCKED":
			return "bg-[#4C5B69] text-[#c2c2c2] border-[#7EE7C7]"
		case "FORBIDDEN":
			return "bg-[#C70039] text-[#F3B59C] border-[#F3B59C]"
		default:
			return "bg-[#1B8062] text-[#7EE7C7] border-[#7EE7C7]"
	}
}

export const ListingPillIndicator: React.FC<ListingPillIndicatorProps> = ({
	label,
	count,
}) => {
	const baseStyle =
		"rounded-md px-[.5rem] lg:px-[0.75rem] py-[0.25rem] text-[0.75rem] mx-0.5 border-[1px] font-bold flex items-center justify-center h-[15px] lg:h-[21px] shadow-xl"

	return (
		<div className={cn(baseStyle, getPillColor(label))}>
			{label === "LOCKED" ? (
				<LockClosedIcon className='w-4 h-4 fill-white' />
			) : (
				<span className='whitespace-nowrap text-xs lg:text-sm'>
					{label} {`${Number(count) > 1 ? `x${count}` : ""}`}
				</span>
			)}
		</div>
	)
}
