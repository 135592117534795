export const setChildAccountDisplayTx = (
	hybridCustodyAddress: string,
	metadataViewsAddress: string
): string => {
	return `
import HybridCustody from ${hybridCustodyAddress}
import MetadataViews from ${metadataViewsAddress}

	transaction(childAddress: Address, name: String, description: String, thumbnail: String) {
		prepare(acct: auth(Storage, Capabilities) &Account) {
				let m = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
						?? panic("manager not found")
				
				let d = MetadataViews.Display(
						name: name,
						description: description,
						thumbnail: MetadataViews.HTTPFile(url: thumbnail)
				)
				m.setChildAccountDisplay(address: childAddress, d)
		}
	}
`
}
