import React from "react"
import { FlowtyButton } from "../../../FlowtyButton"
import { FlowtyModalNavBtnProps } from "../../types/FlowtyModalTypes"

interface ModalNavProps {
	closeBtnProps: FlowtyModalNavBtnProps
	confirmBtnProps: FlowtyModalNavBtnProps
}

export const ModalNav: React.FC<ModalNavProps> = ({
	closeBtnProps,
	confirmBtnProps,
}) => {
	return (
		<>
			<div
				className={`flex w-full items-center justify-center lg:justify-end gap-6 md:px-[40px]`}
			>
				{!closeBtnProps?.notDisplay && (
					<FlowtyButton
						text={closeBtnProps?.text || ""}
						onClick={closeBtnProps?.onClick}
						variant='secondary'
						btnSize='small'
						disabled={closeBtnProps?.disabled}
						bgColor={closeBtnProps?.bgColor}
					/>
				)}
				{!confirmBtnProps?.notDisplay && (
					<FlowtyButton
						text={confirmBtnProps?.text || ""}
						onClick={confirmBtnProps?.onClick}
						variant='secondary'
						btnSize='small'
						disabled={confirmBtnProps?.disabled}
						bgColor={confirmBtnProps?.bgColor}
					/>
				)}
			</div>
		</>
	)
}
