interface PromotionButton {
	mobileLabel: string
	label: string
	redirectUrl: string
}

interface Promotion {
	image: string
	promotionId: string
	title: string
	message: React.ReactNode
	primaryButton?: PromotionButton
	secondaryButton?: PromotionButton
}

export const promotion: Promotion[] = [
	{
		image:
			"https://storage.googleapis.com/flowty-images/drops/PromoLaunchpadFlow.webp",
		message: (
			<div className='flex flex-col gap-[24px]'>
				<p className='text-[#DEE2E6] text-[14px]'>
					To celebrate Flowty’s new self-service NFT launchpad, we’re giving
					away <span className='font-bold text-[14px]'>20,000 FLOW!</span>{" "}
				</p>
				<div className='flex max-lg:flex-col gap-[14px]'>
					<div className='w-full flex flex-col gap-[12px] text-[#DEE2E6] text-[12px]'>
						<p className='font-bold'>Flowty Contests* (10,000 FLOW)</p>
						<div className='h-full w-full flex flex-col gap-[6px]'>
							<div className='h-full w-full flex flex-col border border-[#B9B9B9]'>
								<div className='flex'>
									<p className='w-[110px] border-r border-[#B9B9B9] text-[11px] px-[14px] py-[11px] text-white font-bold'>
										2,500 FLOW
									</p>
									<p className='text-[10px] px-[14px] py-[11px] w-max'>
										Most unique wallets minted
									</p>
								</div>
								<div className='flex border-t border-[#B9B9B9]'>
									<p className='w-[110px] border-r border-[#B9B9B9] text-[11px] px-[14px] py-[11px] text-white font-bold'>
										5,000 FLOW
									</p>
									<p className='text-[10px] px-[14px] py-[11px] w-max'>
										2nd - 11th most unique wallets (500ea)
									</p>
								</div>
								<div className='flex border-t border-[#B9B9B9]'>
									<p className='w-[110px] border-r border-[#B9B9B9] text-[11px] px-[14px] py-[11px] text-white font-bold'>
										2,500 FLOW
									</p>
									<p className='text-[10px] px-[14px] py-[11px] w-max'>
										5 winners for best art/creativity (500ea)
									</p>
								</div>
							</div>
							<p className='text-[10px]'>
								*Must include{" "}
								<span className='font-bold text-[10px]'>Flowty</span> logo /
								reference in NFT art
							</p>
						</div>
					</div>
					<div className='w-full flex flex-col gap-[12px] text-[#DEE2E6] text-[12px]'>
						<p className='font-bold '>Partner Contests** (10,000 FLOW)</p>
						<div className='h-full w-full flex flex-col gap-[6px]'>
							<div className='h-full w-full flex flex-col border border-[#B9B9B9]'>
								<div className='h-full flex'>
									<p className='w-[110px] border-r border-[#B9B9B9] text-[11px] px-[14px] py-[11px] text-white font-bold'>
										10,000 FLOW
									</p>
									<ul className='px-[14px] py-[11px] ml-[14px] text-[10px] list-disc'>
										<li>20+ community specific contests</li>
										<li>
											Collection with most unique <br /> wallets minted for each
											costest <br /> will win ~500 FLOW each
										</li>
									</ul>
								</div>
							</div>
							<p className='text-[10px]'>
								**Must include{" "}
								<span className='font-bold text-[10px]'>Partner</span> logo /
								reference in NFT art
							</p>
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-[8px]'>
					<div className='text-[#DEE2E6] text-[12px]'>
						<p className='text-[12px]'>Key Rules:</p>
						<ul className='text-[12px] list-disc ml-[14px]'>
							<li>Must be FREE mint</li>
							<li>
								Must include{" "}
								<span className='font-bold text-[12px]'>
									exactly one hashtag
								</span>{" "}
								(see details) in the collection description
							</li>
							<li>
								Snapshot for all contests will be 2 PM PST on October 2, 2024
							</li>
						</ul>
					</div>
					<p className='text-[#DEE2E6] text-[12px] italic'>
						Please read{" "}
						<a
							href='https://flowty.substack.com/p/nft-launchpad-promo'
							target='_blank'
							rel='noreferrer'
							className='underline'
							tabIndex={-1}
						>
							Full Promotional Details
						</a>{" "}
						for all rules on how to qualify and win
					</p>
					<p className='text-[#DEE2E6] text-[12px]'>
						HAVE FUN AND HAPPY CREATING!
					</p>
				</div>
			</div>
		),
		primaryButton: {
			label: "VISIT LAUNCHPAD",
			mobileLabel: "VISIT LAUNCHPAD",
			redirectUrl: "/create",
		},
		promotionId: "creators-hub-live",
		secondaryButton: {
			label: "FULL PROMOTIONAL DETAILS",
			mobileLabel: "FULL DETAILS",
			redirectUrl: "https://flowty.substack.com/p/nft-launchpad-promo",
		},
		title: "NFT Launchpad Live on Flowty",
	},
]
