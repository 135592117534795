import axios from "axios"
import { OpensearchFlowNFT, Valuation } from "flowty-common"
import { AccountSummaries } from "flowty-sdk"
import { inject, observer } from "mobx-react"
import React, {
	Suspense,
	createContext,
	lazy,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { Oval } from "react-loader-spinner"
import { useLocation } from "react-router-dom"
import { BulkActionsFooter } from "../../components/BulkActions/BulkActionsFooter"
import {
	BulkFormValues,
	BulkNftFormType,
} from "../../components/BulkActions/BulkActionsPopUp/hooks/useBulkActionsPopUp"
import { useViewport } from "../../components/BulkActions/BulkActionsPopUp/hooks/useViewport"
import { SnackbarAlert } from "../../components/Shared/Snackbar/SnackbarAlert"
import { AuthStoreProp } from "../../stores/AuthStore"
import { Log } from "../../util/Log"
import { apiURL } from "../../util/settings"

interface MarketplaceAppContextValues {
	accountSummaries: AccountSummaries
	bulkFormCachedValues: BulkFormValues | null
	bulkLimit: number
	bulkPurchaseSelecteds: Map<string, BulkNftFormType>
	bulkSelectedsValuation: Map<string, Promise<Valuation>>
	cacheValues: (values: BulkFormValues) => void
	clearBulkListModal: () => void
	closeBulkPopUp: (values: BulkFormValues) => void
	closeBulkListAndClean: () => void
	onBulkPurchaseSelected: (nft: OpensearchFlowNFT) => void
	openBulkPopUp: (bulkType: "list" | "delist") => void
	bulkSelectedLength: number
	bulkListActive: boolean
	isBulkPopUpOpen: BulkStateProps
	loggedUserAddress: string
	toggleBulkList: () => void
	unSelectAll: () => void
	isDapper: boolean
	listedSelected: BulkNftFormType[]
	notListedSelected: BulkNftFormType[]
	renderMobile: boolean
}

interface MarketplaceAppProviderProps extends AuthStoreProp {
	children: React.ReactNode
}

const MarketplaceAppContext = createContext<MarketplaceAppContextValues>({
	accountSummaries: {},
	bulkFormCachedValues: null,
	bulkLimit: 75,
	bulkListActive: false,
	bulkPurchaseSelecteds: new Map(),
	bulkSelectedLength: 0,
	bulkSelectedsValuation: new Map(),
	cacheValues: (_: BulkFormValues) => {},
	clearBulkListModal: () => {},
	closeBulkListAndClean: () => {},
	closeBulkPopUp: (_: BulkFormValues) => {},
	isBulkPopUpOpen: {
		bulkType: null,
		isOpen: false,
	},
	isDapper: false,
	listedSelected: [],
	loggedUserAddress: "",
	notListedSelected: [],
	onBulkPurchaseSelected: (_: OpensearchFlowNFT) => {},
	openBulkPopUp: (_: "list" | "delist") => {},
	renderMobile: false,
	toggleBulkList: () => {},
	unSelectAll: () => {},
})

interface BulkStateProps {
	bulkType: "list" | "delist" | null
	isOpen: boolean
}

const MarketplaceAppProvider: React.FC<MarketplaceAppProviderProps> = ({
	authStore,
	children,
}) => {
	const [bulkListActive, setBulkListActive] = useState(false)
	const [isBulkPopUpOpen, setIsBulkPopUpOpen] = useState<BulkStateProps>({
		bulkType: "list",
		isOpen: false,
	})
	const [bulkFormCachedValues, setBulkFormCachedValues] =
		useState<BulkFormValues | null>(null)
	const [bulkPurchaseSelecteds, setBulkPurchaseSelecteds] = useState<
		Map<string, BulkNftFormType>
	>(new Map())

	const [bulkSelectedsValuation, setBulkSelectedsValuation] = useState<
		Map<string, Promise<Valuation>>
	>(new Map())

	const { width } = useViewport()
	const breakpoint = 768
	const renderMobile = width < breakpoint

	const bulkLimit = 75

	const cacheValues = (values: BulkFormValues): void => {
		setBulkFormCachedValues(prev => {
			if (prev) {
				return Object.assign({}, prev, values)
			}
			return values
		})
	}
	const cleanCachedBulkFormValues = (): void => {
		setBulkFormCachedValues(null)
	}

	const toggleBulkList = useCallback(() => {
		setBulkListActive(prev => !prev)
	}, [])

	const unSelectAll = useCallback(() => {
		setBulkFormCachedValues(null)
		setBulkPurchaseSelecteds(new Map())
	}, [])

	const body = document.body
	const openBulkPopUp = (bulkType: "list" | "delist"): void => {
		if (bulkPurchaseSelecteds.size > 0) {
			body.classList.add("overflow-hidden")
			setIsBulkPopUpOpen({
				bulkType: bulkType,
				isOpen: true,
			})
		}
	}

	const closeBulkPopUp = (values: BulkFormValues): void => {
		setIsBulkPopUpOpen({
			bulkType: null,
			isOpen: false,
		})
		body.classList.remove("overflow-hidden")
		cacheValues(values)
	}

	const closeBulkListAndClean = (): void => {
		cleanCachedBulkFormValues()
		setBulkListActive(false)
		setIsBulkPopUpOpen({
			bulkType: null,
			isOpen: false,
		})
		body.classList.remove("overflow-hidden")
		unSelectAll()
	}

	const [warningBar, setWarningBar] = useState<boolean>(false)

	const getBulkFormType = useCallback(
		(nft: OpensearchFlowNFT): BulkNftFormType => {
			const storefrontOrder = nft.orders?.find(
				order => order.listingKind === "storefront"
			)

			return {
				discountToFMV: 0,
				flowNftType: null,
				flowtyRate: "",
				nft: nft as OpensearchFlowNFT,
				nftValuation: undefined,
				priceValue: storefrontOrder ? storefrontOrder.amount : "",
				receivedValue: "",
				royaltyRate: "",
				storefrontListedToken: storefrontOrder
					? storefrontOrder.paymentTokenName
					: "",
			}
		},
		[]
	)

	const onBulkPurchaseSelected = useCallback(
		(nft: OpensearchFlowNFT) => {
			const nftCustomId = `${nft.contractAddress}.${nft.contractName}.${nft.id}`
			if (
				bulkPurchaseSelecteds.size === bulkLimit &&
				!bulkPurchaseSelecteds.has(nftCustomId)
			) {
				setWarningBar(true)
				return
			}

			const newSelected = new Map(bulkPurchaseSelecteds)

			if (newSelected.has(nftCustomId)) {
				newSelected.delete(nftCustomId)
				if (bulkFormCachedValues) {
					setBulkFormCachedValues(prev => {
						if (prev) {
							return {
								...prev,
								items: prev.items.filter(item => {
									const nftId = `${item.nft.contractAddress}.${item.nft.contractName}.${item.nft.id}`
									return nftCustomId !== nftId
								}),
							}
						}
						return prev
					})
				}
			} else {
				newSelected.set(nftCustomId, getBulkFormType(nft))
				if (bulkFormCachedValues) {
					setBulkFormCachedValues(prev => {
						if (prev) {
							const storefrontOrder = nft.orders?.find(
								order => order.listingKind === "storefront"
							)

							prev.items.push({
								discountToFMV: 0,
								flowNftType: null,
								flowtyRate: "",
								nft: nft,
								nftValuation: undefined,
								priceValue: storefrontOrder ? storefrontOrder.amount : "",
								receivedValue: "",
								royaltyRate: "",
								storefrontListedToken: storefrontOrder
									? storefrontOrder.paymentTokenName
									: "",
							})
						}
						return prev
					})
				}

				const nftDetails = {
					contractAddress: `${nft?.contractAddress}`,
					contractName: `${nft?.contractName}`,
					nftID: nft?.id.toString() || "",
				}

				const valuationResult = axios
					.post(`${apiURL}/nft/valuation`, nftDetails)
					.then(res => res.data)
					.catch((err: Error) => {
						Log({ err })
					})

				setBulkSelectedsValuation(prev => {
					prev.set(nftCustomId, valuationResult)
					return prev
				})
			}
			if (newSelected.size === 0) {
				setIsBulkPopUpOpen({
					bulkType: null,
					isOpen: false,
				})
			}
			setBulkPurchaseSelecteds(newSelected)
		},
		[bulkPurchaseSelecteds, bulkFormCachedValues]
	)

	const bulkSelectedLength = useMemo(() => {
		return bulkPurchaseSelecteds.size
	}, [bulkPurchaseSelecteds])

	const listedSelected = useMemo(() => {
		return Array.from(bulkPurchaseSelecteds.values()).filter(nft => {
			return nft?.priceValue !== ""
		})
	}, [bulkPurchaseSelecteds])

	const notListedSelected = useMemo(() => {
		return Array.from(bulkPurchaseSelecteds.values()).filter(nft => {
			return nft?.priceValue === ""
		})
	}, [bulkPurchaseSelecteds])

	const isDapper = authStore?.loggedUser?.isDapper || false
	const accountSummaries = authStore?.loggedUser?.accountSummaries || {}
	const loggedUserAddress = authStore?.loggedUser?.addr || ""

	const location = useLocation()
	const isProfilePage = location.pathname.includes("profile")

	const clearBulkListModal = useCallback(() => {
		closeBulkListAndClean()
	}, [])

	useEffect(() => {
		if ((bulkListActive || isBulkPopUpOpen) && !isProfilePage) {
			clearBulkListModal()
		}
	}, [location.pathname])

	const value: MarketplaceAppContextValues = useMemo(() => {
		return {
			accountSummaries,
			bulkFormCachedValues,
			bulkLimit,
			bulkListActive,
			bulkPurchaseSelecteds,
			bulkSelectedLength,
			bulkSelectedsValuation,
			cacheValues,
			clearBulkListModal,
			closeBulkListAndClean,
			closeBulkPopUp,
			isBulkPopUpOpen,
			isDapper,
			listedSelected,
			loggedUserAddress,
			notListedSelected,
			onBulkPurchaseSelected,
			openBulkPopUp,
			renderMobile,
			toggleBulkList,
			unSelectAll,
		}
	}, [bulkPurchaseSelecteds, bulkListActive, bulkFormCachedValues])

	const BulkActionsPopUp = lazy(
		() =>
			import("../../components/BulkActions/BulkActionsPopUp/BulkActionsPopUp")
	)

	return (
		<MarketplaceAppContext.Provider value={value}>
			{children}
			<SnackbarAlert
				setShow={setWarningBar}
				show={warningBar}
				seconds={4}
				message={`You can only select ${bulkLimit} items at a time.`}
				type='WARNING'
			/>
			{bulkListActive && <BulkActionsFooter />}
			{isBulkPopUpOpen.isOpen && (
				<Suspense
					fallback={
						<div className='absolute top-0 bg-[#000000cc] w-full h-screen mx-auto my-auto flex items-center justify-center z-40'>
							<Oval height={150} width={150} />
						</div>
					}
				>
					<BulkActionsPopUp bulkType={isBulkPopUpOpen.bulkType} />
				</Suspense>
			)}
		</MarketplaceAppContext.Provider>
	)
}

const MarketplaceAppProviderAuthStore = inject("authStore")(
	observer(MarketplaceAppProvider)
)

const useMarketplaceAppContext = (): MarketplaceAppContextValues => {
	const context = useContext(MarketplaceAppContext)
	if (context === undefined) {
		throw new Error(
			"useMarketplaceAppContext must be used within a MarketplaceAppProvider"
		)
	}
	return context
}

export {
	MarketplaceAppProviderAuthStore as MarketplaceAppProvider,
	useMarketplaceAppContext,
}
