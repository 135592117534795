import { CheckIcon, UploadIcon, XIcon } from "@heroicons/react/solid"
import React, { useRef, useState } from "react"
import { Oval } from "react-loader-spinner"

interface EditWalletDetailsProps {
	clearIsEditing: () => void
	saveNewImage: (address: string, name: string, image: string) => void
	onCancel: () => void
	onChangeHandler: (
		event: React.ChangeEvent<HTMLInputElement>,
		setImage: (image: string) => void,
		setIsLoading: (isLoading: boolean) => void,
		fileName: string
	) => Promise<void | undefined>
	defaultName: string
	defaultImage: string
	childWalletAddress: string
	parentWalletAddress: string
}

export const EditWalletDetails: React.FunctionComponent<
	EditWalletDetailsProps
> = ({
	clearIsEditing,
	saveNewImage,
	onCancel,
	onChangeHandler,
	defaultName,
	defaultImage,
	childWalletAddress,
	parentWalletAddress,
}) => {
	const [name, setName] = useState(defaultName)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [image, setImage] = useState(defaultImage)
	const fileInput = useRef<HTMLInputElement>(null)

	const fileName = `${parentWalletAddress}_${childWalletAddress}`
	const onUploadBtnClick = (): void => {
		if (fileInput && fileInput.current) {
			fileInput.current?.click()
		}
	}

	const edit = async (): Promise<void> => {
		setIsLoading(true)
		await saveNewImage(childWalletAddress, name, image)

		clearIsEditing()
		setIsLoading(false)
	}

	return (
		<div
			className='absolute top-[14px] flex gap-2 items-center'
			onClick={e => e.stopPropagation()}
		>
			<input
				type='file'
				ref={fileInput}
				style={{ display: "none" }}
				onChange={event =>
					onChangeHandler(event, setImage, setIsLoading, fileName)
				}
			/>
			<button
				className='flex flex-col focus:border-none focus:ring-0 rounded-[0.25rem] h-8 w-8 bg-cover bg-center text-white cursor-pointer items-center justify-center'
				onClick={e => {
					e.stopPropagation()
					onUploadBtnClick()
				}}
				style={{
					backgroundColor: image && !isLoading ? "transparent" : "",
					backgroundImage: image && !isLoading ? `url('${image}')` : "",
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
				}}
			>
				<div className='bg-black/60 border border-white rounded-[0.25rem] w-full h-full flex items-center justify-center p-1'>
					{isLoading ? (
						<Oval
							width={14}
							height={14}
							color='white'
							strokeWidth={6}
							secondaryColor='#FFFFFF1F'
						/>
					) : (
						<UploadIcon className='text-white' />
					)}
				</div>
			</button>
			<input
				onChange={e => setName(e.target.value)}
				className='bg-[#3434347f] font-bold border py-1 border-white rounded-[0.25rem] px-2 max-w-[80%] focus:outline-none focus:ring-0 active:ring-0 text-[1rem]'
				value={name}
				maxLength={20}
				onClick={e => e.stopPropagation()}
			/>
			<div className='absolute z-2 flex flex-row gap-1 bottom-[-40px] right-0 pr-[8px]'>
				<button
					disabled={!name}
					className='bg-white rounded-[0.5rem] flex p-[0.625rem] border-[1px] white-box-shadow'
					onClick={e => {
						e.stopPropagation()
						edit()
					}}
				>
					<CheckIcon className='h-[12px] w-[12px] text-black' />
				</button>
				<button
					className='bg-white rounded-[0.5rem] flex p-[0.625rem] border-[1px] white-box-shadow'
					onClick={e => {
						e.stopPropagation()
						onCancel()
					}}
				>
					<XIcon className='h-[12px] w-[12px] text-black' />
				</button>
			</div>
		</div>
	)
}
