import { SetStateAction } from "react"
import { firestore } from "../../../../firebase"
import { FilterView } from "../hooks/useSaveView"

export const getUserProfileFilters = async (
	userAddress: string,
	setFilterViews: (value: SetStateAction<FilterView[]>) => void
): Promise<void> => {
	await firestore
		.collection(`/accounts/${userAddress}/filterViews`)
		.where("isUserProfile", "==", true)
		.orderBy("createdAt", "asc")
		.onSnapshot(async snapshot => {
			const docs = snapshot.docs.map(doc => ({
				...(doc.data() as FilterView),
				id: doc.id,
			}))
			setFilterViews(docs)
		})
}

export const getUserCollectionFilters = async (
	userAddress: string,
	collectionAddressNameIdentifier: string,
	setFilterViews: (value: SetStateAction<FilterView[]>) => void
): Promise<void> => {
	await firestore
		.collection(`/accounts/${userAddress}/filterViews`)
		.where("collectionAddressName", "==", collectionAddressNameIdentifier)
		.orderBy("createdAt", "asc")
		.onSnapshot(async snapshot => {
			const docs = snapshot.docs.map(doc => ({
				...(doc.data() as FilterView),
				id: doc.id,
			}))
			setFilterViews(docs)
		})
}
