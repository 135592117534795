/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlowtyButton, FlowtyNumericInput } from "ds-flowty"
import { Formik } from "formik"
import { useState } from "react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export interface MinMaxFilter {
	min: string | null
	max: string | null
}

export const Filters: React.FunctionComponent<any> = ({
	showFilter,
	setShowFilter,
	FilterFields,
	filterOptions,
	setAllFieldsNull,
	filterBy,
	setFilterBy,
}) => {
	const [isFiltered, setIsFiltered] = useState(false)

	const handleSelectInputChange = (
		value: any,
		stateFunc: any,
		index: string
	): void => {
		setAllFieldsNull()
		setIsFiltered(true)
		stateFunc(value)
		setFilterBy({
			...filterBy,
			max: "",
			min: value,
			path: index,
		})
	}

	const handleTextInputChange = (
		value: any,
		stateFunc: any,
		existingValues: Record<string, any>,
		targetName: string,
		index: string
	): void => {
		setAllFieldsNull()
		setIsFiltered(true)
		if (value === "") {
			setIsFiltered(false)
		}
		stateFunc({ ...existingValues, [targetName]: value })
		setFilterBy({
			...existingValues,
			path: index,
			[targetName]: value,
		})
	}

	return (
		<div
			className={`h-full z-50 rounded-r-md fixed top-0 right-0 bg-[#232d39] overflow-x-auto pb-[40px] transition duration-700 overflow-y-auto flex flex-col ${
				!showFilter ? "w-400" : "w-0"
			}`}
		>
			<button
				className={`${
					isFiltered
						? "bg-green-500 hover:bg-green-600"
						: "bg-red-500 hover:bg-red-600"
				} float-right text-black font-bold uppercase text-sm px-6 py-4 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150`}
				type='button'
				onClick={() => setShowFilter(!showFilter)}
			>
				{isFiltered ? "View" : "Close"}
			</button>
			<div className='p-6'>
				<Formik initialValues={{}} onSubmit={() => {}}>
					{() => (
						<>
							{FilterFields.map((formField: any) => (
								<div className='my-4' key={formField.path}>
									<p className='text-base font-semibold text-start text-white mb-1'>
										{formField.title}
									</p>
									<div className='grid gap-y-6 gap-x-4 grid-cols-6'>
										{formField.selectField ? (
											<div className='col-span-12 flex-col'>
												<select
													className={`h-[37px] w-full text-sm bg-transparent border font-semibold justify-end rounded-md px-4 hover:border-white focus:border-primary focus:outline-none `}
													name='status'
													onChange={event =>
														handleSelectInputChange(
															event.target.value,
															formField.setValue,
															formField.path
														)
													}
													value={formField?.value || ""}
												>
													<option value='' hidden>
														Choose here
													</option>
													{filterOptions.map(
														(filterOption: any, index: number) => (
															<option
																key={index}
																value={filterOption?.value || ""}
																className='text-black'
															>
																{filterOption.label}
															</option>
														)
													)}
												</select>
											</div>
										) : formField.dateField ? (
											<>
												<div className='col-span-3'>
													<div className='mt-1'>
														<DatePicker
															className={`h-[37px] w-full text-sm bg-transparent border font-semibold justify-end rounded-md px-3 hover:border-white focus:border-primary focus:outline-none `}
															selected={formField.value.min}
															onChange={date =>
																handleTextInputChange(
																	date,
																	formField.setValue,
																	formField.value,
																	"min",
																	formField.path
																)
															}
															placeholderText='Min'
															maxDate={formField.value.max}
														/>
													</div>
												</div>
												<div className='col-span-3'>
													<div className='mt-1'>
														<DatePicker
															className={`h-[37px] w-full text-sm bg-transparent border font-semibold justify-end rounded-md px-3 hover:border-white focus:border-primary focus:outline-none `}
															selected={formField.value.max}
															onChange={date =>
																handleTextInputChange(
																	date,
																	formField.setValue,
																	formField.value,
																	"max",
																	formField.path
																)
															}
															placeholderText='Max'
															minDate={formField.value.min}
														/>
													</div>
												</div>
											</>
										) : (
											<>
												<div className='col-span-3'>
													<div className='mt-1'>
														<FlowtyNumericInput
															placeholder={"Min"}
															className={`h-[37px] w-full text-sm bg-transparent border font-semibold justify-end rounded-md px-3 hover:border-white focus:border-primary focus:outline-none `}
															name={`${formField.title}min`}
															value={formField.value.min}
															onChange={(event: any) =>
																handleTextInputChange(
																	event.target.value,
																	formField.setValue,
																	formField.value,
																	"min",
																	formField.path
																)
															}
														/>
													</div>
												</div>
												<div className='col-span-3'>
													<div className='mt-1'>
														<FlowtyNumericInput
															placeholder={"Max"}
															className={`h-[37px] w-full text-sm bg-transparent border font-semibold justify-end rounded-md px-3 hover:border-white focus:border-primary focus:outline-none `}
															name={`${formField.title}max`}
															value={formField.value.max}
															onChange={(event: any) =>
																handleTextInputChange(
																	event.target.value,
																	formField.setValue,
																	formField.value,
																	"max",
																	formField.path
																)
															}
														/>
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							))}
						</>
					)}
				</Formik>
			</div>
			<div className='px-6 mt-2.5'>
				<FlowtyButton
					type='button'
					text={"Reset"}
					onClick={() => {
						setIsFiltered(false)
						setAllFieldsNull()
						setFilterBy({ ...filterBy, max: "", min: "", path: "" })
					}}
					variant={"secondary"}
					btnSize='small'
				/>
			</div>
			<p className='text-sm text-left px-6 mt-6 text-white'>
				Filter by the fields above. As of now, you can filter by one field at a
				time. We will be rolling out multi-field filtering soon.
			</p>
		</div>
	)
}
