import { User } from "../../../models/user"
import {
	getAccountData,
	updateAccountData,
} from "../../../services/firestore/AccountService"
import { useEffect, useState } from "react"
import { ReactComponent as CloseIcon } from "../../../assets/media/close-icon-2.svg"
import { ReactComponent as ExternalLink } from "../../../assets/media/external-link.svg"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { Log } from "../../../util/Log"

interface AccountLinkingProps {
	profile: User
}

export const AccountLinkingButton: React.FC<AccountLinkingProps> = ({
	profile,
}) => {
	const [show, setShow] = useState<boolean>(false)
	// const dapperAccountLinkingURL = IS_MAINNET
	// 	? "https://accounts.meetdapper.com/linked-accounts"
	// 	: "https://staging.accounts.meetdapper.com/linked-accounts"

	const dapperAccountLinkingBody = `Dapper Wallet Email Address - ${profile.email}%0D%0A
Dapper Wallet Address - ${profile.addr}%0D%0A
%0D%0A
I am requesting access to Dapper's Account Linking portal for the above Dapper Wallet.%0D%0A
%0D%0A
Thank you`
	const dapperAccountLinkingURL = `mailto:developers@dapperlabs.com?subject=Account Linking&body=${dapperAccountLinkingBody}`

	useEffect(() => {
		if (!profile.isDapper || !profile.addr) return
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getAccountData(profile?.addr).then((accountData: any) => {
			if (!accountData?.hideDWAccountLinkingBanner) {
				setShow(true)
			}
		})
	}, [profile.addr])

	if (show) {
		return (
			<div className='flex flex-col gap-3 md:flex-row bg-[#ffffff] bg-opacity-[2%] backdrop-blur-lg border-[1px] border-opacity-20 rounded-md border-[#7c7c7c] p-4 justify-around lg:max-w-[70%] items-center mt-2'>
				<div className='text-sm text-white'>
					Do you own multiple wallets on the FLOW blockchain? Link them together
					and access all of them at one place today!
				</div>
				<a
					href={dapperAccountLinkingURL}
					target='_blank'
					rel='noreferrer'
					onClick={() => {
						Log("Account Linking Requested")
						Mixpanel.track("Account Linking Requested")
					}}
				>
					<div className='bg-white rounded-lg text-[#011020] px-4 py-3 flex flex-nowrap font-extrabold text-base uppercase whitespace-nowrap'>
						Request Access
						<ExternalLink className='inline-block pb-2 pl-2' />
					</div>
				</a>
				<button
					onClick={() => {
						if (profile?.addr) {
							updateAccountData(profile?.addr, {
								hideDWAccountLinkingBanner: true,
							}).then(() => {
								setShow(false)
							})
						}
					}}
				>
					<CloseIcon />
				</button>
			</div>
		)
	}
	return <></>
}
