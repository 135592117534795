import { useEffect, useState } from "react"
import { db } from "../../../../firebase"
import { Tooltip } from "react-tooltip"
import { TwitterIcon } from "../../../../screens/CollectionPage/components/SocialLinks/TwitterIcon"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

interface TwitterProviderDocument {
	handle: string
}

export const TwitterIntegration: React.FC<{ walletAddress: string }> = ({
	walletAddress,
}) => {
	const [twitterHandle, setTwitterHandle] = useState<string | null>(null)

	useEffect(() => {
		if (walletAddress) {
			db.doc(`/accounts/${walletAddress}/providers/twitter`)
				.get()
				.then(twitterDoc => {
					if (twitterDoc.exists) {
						const twitterData = twitterDoc.data() as TwitterProviderDocument
						setTwitterHandle(twitterData.handle ?? null)
					}
				})
				.catch(e => {
					Mixpanel.track("ERROR_FIREBASE_FETCH_TWITTER", {
						e,
					})
				})
		}
	}, [walletAddress])

	if (twitterHandle) {
		return (
			<div>
				<Tooltip
					id={`view-twitter-profile-tooltip`}
					style={{
						backgroundColor: "white",
						color: "#031021",
						fontSize: "0.75rem",
					}}
				/>
				<div
					data-tooltip-id={`view-twitter-profile-tooltip`}
					data-tooltip-content={`View Twitter Profile`}
					data-tooltip-place='right'
				>
					<TwitterIcon link={`https://twitter.com/${twitterHandle}`} />
				</div>
			</div>
		)
	}

	return <></>
}
