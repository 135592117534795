import { OpensearchFlowNFT } from "flowty-common"
import { FilterFormValues } from "../types/FilterFormValues"
import { useHistory } from "../hooks/useHistory"
import { StorefrontEvent } from "../types/StorefrontEventTypes"
import { RentEvent } from "../types/RentEventTypes"
import { LoanEvent } from "../types/LoanEventTypes"
import { HistoryRow } from "../HistoryRow/HistoryRow"
import { ReactComponent as NoneFoundIcon } from "../../../../../assets/icons/none-found.svg"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { Oval } from "react-loader-spinner"

interface Props {
	values: FilterFormValues
	openSearchNFT: OpensearchFlowNFT
	page: number
	setPage: (page: number) => void
}

export const HistoryForm: React.FunctionComponent<Props> = ({
	values,
	openSearchNFT,
	page,
	setPage,
}) => {
	const { historyData, initialLoading, loading, finalLength, bottomOfPageRef } =
		useHistory({
			openSearchNFT,
			page,
			setPage,
			values,
		})
	return (
		<div
			style={{ height: "calc(100vh - 800px)" }}
			className='w-full flex flex-col gap-3 pr-1 overflow-scroll min-h-[600px]'
		>
			{initialLoading ? (
				<div className='w-full flex flex-col gap-3'>
					{Array.from({ length: 5 }).map((_, index) => (
						<div
							key={index}
							className='rounded flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait w-full h-[72px]'
						/>
					))}
				</div>
			) : (
				<>
					{historyData.length === 0 && (
						<div className='w-full h-full flex items-center pt-[1.5rem] flex-col animate-pulse gap-3'>
							<div className='relative mr-5'>
								<NoneFoundIcon />
								<div className='flex space-x-2 left-11 bottom-0 absolute'>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
								</div>
							</div>
							<div className='flex flex-col gap-1'>
								<p className='text-white text-montserrat text-[0.875rem] leading-normal font-bold'>
									No History Found
								</p>
								<p className='text-white text-montserrat text-[0.875rem] font-normal leading-normal'>
									Try applying filters
								</p>
							</div>
						</div>
					)}
					{historyData.map(
						(historyRow: StorefrontEvent | RentEvent | LoanEvent) => {
							if (
								historyRow.type.includes("DELISTED") ||
								historyRow.type.includes("DESTROYED")
							)
								return null

							return <HistoryRow key={historyRow.id} historyRow={historyRow} />
						}
					)}
					{loading || finalLength ? (
						<>
							{loading && (
								<div className='w-full flex justify-center items-center py-4'>
									<Oval
										secondaryColor='#4f4f4f'
										color='#B3BBBE'
										height={40}
										width={40}
									/>
								</div>
							)}
						</>
					) : (
						<div className='w-full h-full'>
							<ScrollToLoadMsg />
							<div className='w-full h-[2px]' ref={bottomOfPageRef} />
						</div>
					)}
				</>
			)}
		</div>
	)
}
