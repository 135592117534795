import Countdown, { zeroPad } from "react-countdown"
import { getListingActionTime, isNftActive } from "../../../../util/nftDataUtil"
import React, { useState } from "react"

interface CardCountdownTimerProps {
	blockTimestamp: Date | null
}

type CountdownProps = {
	minutes: number
	seconds: number
	completed: boolean
}

export const CardCountdownTimer: React.FC<CardCountdownTimerProps> = ({
	blockTimestamp,
}) => {
	const [isActive, setIsActive] = useState(false)
	const countDownRenderer = ({
		minutes,
		seconds,
		completed,
	}: CountdownProps): React.ReactNode => {
		if (completed) setIsActive(true)
		return (
			<span className='text-white text-bold'>
				{zeroPad(minutes)}:{zeroPad(seconds)}
			</span>
		)
	}

	if (!blockTimestamp || isNftActive(blockTimestamp)) {
		return null
	}

	return (
		<>
			{!isActive && (
				<div className='absolute top-0 left-0 w-full h-[97%] flex justify-center items-end'>
					<div className='w-full h-full flex justify-center items-end group'>
						<div
							className={`bg-darkBg bg-opacity-70 w-full flex justify-center items-center h-36 border-y-2  border-white group-hover:hidden`}
						>
							<p className='text-white  font-bold me-2 pb-0.5'>Active in</p>
							<Countdown
								className='text-white font-bold'
								renderer={countDownRenderer}
								date={getListingActionTime(blockTimestamp)}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
