import { useState, useEffect } from "react"

type Breakpoint = "desktop" | "laptop" | "mobile" | "tablet" | "xl" | "xs"

export const useBreakpoint = (): Breakpoint => {
	const breakpoints = {
		desktop: 1440,
		laptop: 1024,
		mobile: 640,
		tablet: 768,
		xl: 1440,
		xs: 345,
	}

	const getBreakpoint = (width: number): Breakpoint => {
		if (width < breakpoints.mobile) return "xs"
		if (width < breakpoints.tablet) return "mobile"
		if (width < breakpoints.laptop) return "tablet"
		if (width < breakpoints.desktop) return "laptop"
		if (width < breakpoints.xl) return "desktop"

		return "xl"
	}

	const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth))

	useEffect(() => {
		const handleResize = (): void => {
			setBreakpoint(getBreakpoint(window.innerWidth))
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return breakpoint
}
