import { useMemo, useState } from "react"
import { useBreakpoint } from "../../utils/useBreakpoint/useBreakpoint"

interface ConfirmationHookProps {
	activeConfirmationPreviewTab: string
	confirmationPagePreviewTabs: string[]
	confirmationPageMobilePreviewTabs: string[]
	onConfirmationPageTabClick: (tab: string) => void
}

export const useConfirmation = (): ConfirmationHookProps => {
	const breakpoint = useBreakpoint()
	const isMobile = breakpoint === "xs" || breakpoint === "mobile"
	const [activeConfirmationPreviewTab, setActiveConfirmationPreviewTab] =
		useState<string>(isMobile ? "Collection" : "Collection Page")
	const confirmationPagePreviewTabs: string[] = ["Collection Page", "Drop Page"]
	const confirmationPageMobilePreviewTabs: string[] = ["Collection", "Drop"]

	const handleConfirmationPageTabClick = (tab: string): void => {
		setActiveConfirmationPreviewTab(tab)
	}

	const memoizedValues = useMemo(
		() => ({
			activeConfirmationPreviewTab,
			confirmationPageMobilePreviewTabs,
			confirmationPagePreviewTabs,
			onConfirmationPageTabClick: handleConfirmationPageTabClick,
		}),
		[activeConfirmationPreviewTab, confirmationPagePreviewTabs]
	)

	return memoizedValues
}
