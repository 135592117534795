/* eslint-disable @typescript-eslint/no-use-before-define */

import { FCLTransactionResult } from "flowty-common"
import { Config } from "../types"
import { getSetupCollectionTxn } from "./transactions"
import { sendMutation } from "../utils/Utils"

const t = require("@onflow/types")
const fcl = require("@onflow/fcl")

export class CollectionService {
	config: Config

	setupCollection = async (
		address: string,
		name: string,
		isDapper: boolean,
		isNFTCatalog: boolean
	): Promise<FCLTransactionResult | null> => {
		if (!address || !name) return null

		const txContent = getSetupCollectionTxn(
			this.config,
			isDapper,
			address,
			name,
			isNFTCatalog
		)
		const txArguments = [fcl.arg(address, t.Address), fcl.arg(name, t.String)]
		return sendMutation(txContent, txArguments)
	}

	constructor(config: Config) {
		this.config = config
	}
}
