import { Trait } from "flowty-common"

export const getTraitDisplayValue = (trait: Trait): string | void => {
	if (typeof trait.value === "object") {
		return
	}
	switch (trait.displayType) {
		case "String":
			return trait.value
		default:
			return trait.value
	}
}
