interface DapperWarningProps {
	isLoanOrRental?: boolean
	isDapper?: boolean
	isNonDapper?: boolean
}

export const DapperWarning: React.FunctionComponent<DapperWarningProps> = ({
	isLoanOrRental,
	isDapper = false,
	isNonDapper = false,
}) => {
	return (
		<div
			data-testid='dapper-warning-message'
			className='dapper-warning bg-[#FFFFFF14] flex flex-col px-[0.75rem] py-[0.5rem] self-stretch rounded-[0.5rem]'
		>
			<div className='flex items-center gap-[0.5rem] self-stretch text-warning font-montserrat text-[0.75rem] font-[400] leading-[0.75rem]'>
				<svg
					width='18'
					height='18'
					viewBox='0 0 18 18'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.43123 2.78899C8.11938 1.56562 9.88075 1.56562 10.5689 2.78899L15.5912 11.7175C16.2661 12.9174 15.399 14.3999 14.0223 14.3999H3.97779C2.6011 14.3999 1.73402 12.9174 2.40896 11.7175L7.43123 2.78899ZM9.89999 11.7C9.89999 12.1971 9.49704 12.6 8.99999 12.6C8.50293 12.6 8.09999 12.1971 8.09999 11.7C8.09999 11.203 8.50293 10.8 8.99999 10.8C9.49704 10.8 9.89999 11.203 9.89999 11.7ZM8.99999 4.50005C8.50293 4.50005 8.09999 4.90299 8.09999 5.40005V8.10005C8.09999 8.59711 8.50293 9.00005 8.99999 9.00005C9.49704 9.00005 9.89999 8.59711 9.89999 8.10005V5.40005C9.89999 4.90299 9.49704 4.50005 8.99999 4.50005Z'
						fill='#FF6E25'
					/>
				</svg>
				{isLoanOrRental ? (
					<span className='w-fit'>
						Loans and Rentals are not supported on Dapper Wallet. Please connect
						with a non-Dapper Wallet to use those features. For more information
						on Wallets, see our{" "}
						<a
							target='_blank'
							rel='noreferrer'
							className='underline'
							href='https://docs.flowty.io/reference/general-platform/supported-crypto-wallets'
						>
							supported wallets FAQ
						</a>
						.
					</span>
				) : (
					<span className='w-fit' data-testid='warning-wallet-message'>
						A {isDapper && "non-Dapper"}
						{isNonDapper && "Dapper"} Wallet with sufficient funds is required
						to proceed with this transaction. Learn more{" "}
						<a
							target='_blank'
							rel='noreferrer'
							className='underline'
							href='https://docs.flowty.io/reference/general-platform/supported-crypto-wallets'
						>
							here
						</a>
						.
					</span>
				)}
			</div>
		</div>
	)
}
