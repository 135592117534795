import { PreviewBanner } from "../../common/PreviewBanner/PreviewBanner"
import { PreviewPageTab } from "../../common/PreviewPageTab/PreviewPageTab"
import { PreviewNftList } from "../../components/CustomizeCollectionPreviewStep/PreviewNftList/PreviewNftList"
import { ListingFilters } from "./ListingFilters/ListingFilters"

export const CustomizeCollectionPreviewStep: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col gap-3 2xl:gap-6'>
			<PreviewBanner />
			<div className='flex flex-col gap-2 2xl:gap-3 whitespace-nowrap'>
				<PreviewPageTab type='LISTINGS' />
				<ListingFilters />
				<div className='flex flex-row gap-3 w-max lg:w-full'>
					<PreviewNftList />
				</div>
			</div>
		</div>
	)
}
