import { useFlowtyMessages } from "./useFlowtyMessages"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { CustomLink, CustomParagraph } from "./CustomComponents"

interface LoadingScreenMessagesProps {
	strapiUrl: string
	mixPanelFn: (event: string, data: unknown) => void
}

export const LoadingScreenMessages: React.FunctionComponent<
	LoadingScreenMessagesProps
> = ({ strapiUrl, mixPanelFn }) => {
	const { selectedMessage, renderTitle } = useFlowtyMessages(
		strapiUrl,
		mixPanelFn
	)

	return (
		<div
			className={`${
				selectedMessage ? "opacity-100" : "opacity-0"
			} transition-opacity duration-500 ease-in-out flex flex-col justify-center w-auto md:w-[28rem] bg-[#ffffff] bg-opacity-[8%] backdrop-blur-lg rounded-lg py-2 p-4 gap-2 leading-normal`}
		>
			{selectedMessage && (
				<>
					<h2 className='text-base font-bold text-gray-300 leading-normal'>
						{renderTitle()}{" "}
					</h2>
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						components={{
							a: CustomLink,
							p: CustomParagraph,
						}}
						className='text-gray-300 markdown-messages'
					>
						{selectedMessage.Message}
					</ReactMarkdown>
				</>
			)}
		</div>
	)
}
