import * as React from "react"
import { ReactComponent as ToggleOn } from "../../../assets/toggle-on.svg"
import { ReactComponent as ToggleOff } from "../../../assets/toggle-off.svg"
import { actions as Mixpanel } from "../../../utils/MockMixpanel"

interface SettingsToggleProps {
	onToggle: (newToggled: boolean) => void
	toggled?: boolean
	label?: string | React.ReactNode
	name?: string
	loading?: boolean
	disabled?: boolean
}

export const SettingsToggle: React.FunctionComponent<SettingsToggleProps> =
	React.memo(({ onToggle, toggled, label, loading, name, disabled }) => {
		const handleClick = (): void => {
			const newToggled = !toggled
			onToggle(newToggled)
			Mixpanel.track("SETTINGS_TOGGLE_CLICKED", {
				name,
				toggled,
			})
		}

		return (
			<div className='flex justify-between items-center'>
				{label && <span>{label}</span>}{" "}
				<button
					type='button'
					className={`h-8 flex rounded-full outline-none ${
						disabled && "cursor-not-allowed"
					}`}
					onClick={handleClick}
					disabled={disabled && disabled}
				>
					<div className='inset-0 flex justify-center items-center'>
						<div
							className={`flex justify-center items-center transition-opacity duration-1000 ease-in-out ${
								loading ? "opacity-0" : disabled ? "opacity-50" : "opacity-100"
							}`}
						>
							{toggled ? <ToggleOn /> : <ToggleOff />}
						</div>

						<div
							className={`absolute flex justify-center items-center transition-opacity duration-1000 ease-in-out ${
								loading ? "opacity-100" : "opacity-0"
							}`}
						>
							<div
								className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
								role='status'
								aria-label='loading'
							/>
						</div>
					</div>
				</button>
			</div>
		)
	})
