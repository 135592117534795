import { OpensearchFlowNFT, SupportedTokens } from "flowty-common"
import { Dispatch, SetStateAction } from "react"
import Swal from "sweetalert2"
import { Flowty } from "flowty-sdk"
import { ListingState } from "../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"

const IS_STORYBOOK = process.env.IS_STORYBOOK

interface AcceptOfferFnBuilderProps {
	openSearchFlowNFT: OpensearchFlowNFT
	txAvailableCallback: (txId: string | null) => void
	setIsLoading: Dispatch<SetStateAction<ListingState>>
	setError: Dispatch<SetStateAction<ListingState>>
	setIsSealed: Dispatch<SetStateAction<ListingState>>
	offerToken: string
	mixPanelFn: (event: string, data: unknown) => void
	nftProviderPathIdentifier: string
	ftReceiverAddress: string
	nftStoragePath: string
	offerResourceID: string
	offerStorefrontAddress: string
	flowty: Flowty
}

export const acceptOfferFnBuilder = async ({
	txAvailableCallback,
	setIsLoading,
	setError,
	setIsSealed,
	openSearchFlowNFT,
	offerToken,
	mixPanelFn,
	nftProviderPathIdentifier,
	ftReceiverAddress,
	nftStoragePath,
	offerResourceID,
	offerStorefrontAddress,
	flowty,
}: AcceptOfferFnBuilderProps): Promise<undefined> => {
	setIsLoading((prevState: ListingState) => ({
		...prevState,
		acceptOffer: true,
	}))
	setError((prevState: ListingState) => ({
		...prevState,
		acceptOffer: false,
	}))
	setIsSealed((prevState: ListingState) => ({
		...prevState,
		acceptOffer: false,
	}))
	if (IS_STORYBOOK) {
		txAvailableCallback("mockTxId")
		setTimeout(() => {
			setIsLoading((prevState: ListingState) => ({
				...prevState,
				acceptOffer: false,
			}))
			if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
				setError((prevState: ListingState) => ({
					...prevState,
					acceptOffer: true,
				}))
				return
			} else {
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					acceptOffer: true,
				}))
			}
		}, 3000)
		return
	}
	try {
		const token = flowty.tokens.getTokenInfo(offerToken as SupportedTokens)
		const response = await flowty.acceptOffer({
			ftReceiverAddress,
			nftData: {
				contractAddress: openSearchFlowNFT?.contractAddress,
				contractName: openSearchFlowNFT?.contractName,
				id: openSearchFlowNFT?.id.toString(),
				nftOwner: openSearchFlowNFT?.owner,
				type: openSearchFlowNFT?.type,
			},
			nftProviderPathIdentifier,
			nftStoragePath,
			offerResourceID,
			offerStorefrontAddress,
			token,
			txAvailableCallback: txAvailableCallback,
		})
		if (response?.status === 4) {
			setIsSealed((prevState: ListingState) => ({
				...prevState,
				acceptOffer: true,
			}))
			setIsLoading((prevState: ListingState) => ({
				...prevState,
				acceptOffer: false,
			}))
			mixPanelFn(`Storefront offer accepted successful transaction`, {
				listingData: openSearchFlowNFT,
				offer: offerResourceID,
			})
			return
		}
		if (
			(response?.status !== 4 && response?.toString()?.includes("Error")) ||
			response?.toString()?.includes("Declined")
		) {
			throw new Error(response)
		}
	} catch (e) {
		setError((prevState: ListingState) => ({
			...prevState,
			acceptOffer: true,
		}))
		setIsLoading((prevState: ListingState) => ({
			...prevState,
			acceptOffer: false,
		}))
		if (e?.toString()?.includes("Popup failed to open")) {
			await Swal.fire({
				background: "#031021",
				color: "#FFFFFF",
				confirmButtonColor: "#FF6969",
				icon: "error",
				showConfirmButton: true,
				text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
				timer: 5000,
			})
		}
		mixPanelFn(`Storefront offer accepted failed transaction`, {
			err: e,
			listingData: openSearchFlowNFT,
			offer: offerResourceID,
		})
	}
	return
}
