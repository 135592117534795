import { FlowNFTContract, getSquareImage } from "flowty-common"
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ReactComponent as VerifiedCheck } from "../../../../assets/media/verifiedCheck.svg"
import { fetchSpecificFlowNFTContract } from "../../../../services/firestore/nftIInfo"
import { COLLECTION_PAGE_ENABLED } from "../../../../util/settings"
import { Oval } from "react-loader-spinner"
import FallbackImg from "../../../../assets/new_fallback_image.svg"

export const CollectionPreviewDisplay: React.FC = () => {
	const { collectionAddress, collectionName } = useParams()
	const [isLoading, setIsLoading] = useState(true)
	const [collection, setCollection] = useState<FlowNFTContract | null>(null)

	const [isInCatalog, setIsInCatalog] = useState(false)

	useEffect(() => {
		fetchSpecificFlowNFTContract({
			collectionAddress: `${collectionAddress}`,
			collectionName: `${collectionName}`,
		})
			.then(contract => {
				setCollection(contract)
				setIsInCatalog(!!contract?.isNFTCatalog)
			})
			.then(() => setIsLoading(false))
	}, [])

	return (
		<>
			{isLoading ? (
				<Oval width={20} height={20} />
			) : (
				<>
					{COLLECTION_PAGE_ENABLED ? (
						<>
							<Link
								className='flex items-center group font-montserrat'
								to={`/collection/${collectionAddress}/${collectionName}`}
							>
								<div
									className='w-[1.5rem] h-[1.5rem] bg-cover bg-no-repeat bg-center rounded-[0.1875rem]'
									style={{
										backgroundImage: `url(${
											collectionName
												? getSquareImage(collectionName, collection)
												: FallbackImg
										})`,
									}}
								/>
								<p className='mx-2 text-primary text-[1rem] md:text-[1.125rem] font-bold'>
									{collection?.name}
								</p>
								{isLoading ? (
									<div className='w-[16px] h-[16px]' />
								) : (
									<>
										{isInCatalog && (
											<VerifiedCheck
												className='group-hover:stroke-primary'
												width={16}
												height={16}
											/>
										)}
									</>
								)}
							</Link>
						</>
					) : (
						<p className='text-primary text-[1rem] md:text-[1.125rem] font-bold'>
							{collection?.name}
						</p>
					)}
				</>
			)}
		</>
	)
}
