import { MappedFacet } from "../../../hooks/data/collections/flowNFTContract"
import { Facet, MappedFilterTraits } from "../types"

interface MappedFacets {
	[key: string]: MappedFacet
}

export function formatToMappedFacets(facets: Facet[]) {
	return facets.reduce((result: MappedFacets, facet) => {
		const copyResult = { ...result }
		copyResult[facet.collection] = {
			count: facet.count,
			traits: facet?.traits?.reduce(
				(traitResult: MappedFilterTraits, currentTrait) => {
					const copyTraitsResult = { ...traitResult }

					copyTraitsResult[currentTrait.name] = {
						count: currentTrait.count,
						values: {},
					}
					currentTrait.values.forEach(traitVal => {
						copyTraitsResult[currentTrait.name].values[traitVal.value] =
							traitVal.count
					})
					return copyTraitsResult
				},
				{}
			),
		}

		return copyResult
	}, {})
}
