import { DapperSignInBar, FlowtyNavbar } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import { toJS } from "mobx"
import { inject, observer } from "mobx-react"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { GlobalAlert } from "../components/LandingPage/GlobalAlert/index"
import TermsHaveBeenUpdatedModal from "../components/Modals/UpdatedTerms/UpdatedTerms"
import WelcomeMessageModal from "../components/Modals/WelcomeMessage/WelcomeMessage"
import { PageBanner } from "../components/PageBanner/PageBanner"
import { flowty } from "../config/config"
import { useHandleDapperSignInBar } from "../hooks/useHandleDapperSignInBar"
import { useNotifications } from "../hooks/useNotifications"
import { logOut } from "../services/AuthService"
import { AuthStoreProp } from "../stores/AuthStore"
import { RootStoreProp } from "../stores/RootStore"
import { connectDapperWallet } from "../util/ConnectDapperWallet"
import { connectWallet } from "../util/ConnectWallet"
import { actions as Mixpanel } from "../util/Mixpanel"
import { useLayout } from "./hooks/useLayout"

interface LayoutProps extends AuthStoreProp, RootStoreProp {
	children: React.ReactElement | React.ReactElement[]
	landingPage?: boolean
}

const Layout: React.FC<LayoutProps> = ({
	children,
	authStore,
	landingPage,
}) => {
	const { pathname } = useLocation()

	const {
		showWelcomeMessage,
		showLatestTermsModal,
		isShowing,
		getPaddingBottom,
		selectedItem,
		setAuthLoading,
		authLoading,
		setNavbarOffsetHeight,
		navbarOffsetHeight,
	} = useLayout({ authStore, landingPage, pathname })

	const {
		notifications,
		queueNotification,
		fetchMoreNotifications,
		hasMore,
		loading,
	} = useNotifications({
		autoResolve: false,
		loggedUserAddress: authStore?.loggedUser?.addr || "",
	})

	const { showDapperSignInBar, setShowDapperSignInBar } =
		useHandleDapperSignInBar()

	const disconnectWallet = (): void => {
		logOut()
		window.location.reload()
		Mixpanel.track("Successful Logout")
	}

	const childAccounts = useMemo(() => {
		const accountsJS = toJS(authStore?.loggedUser?.childAccounts)
		return Object.values(accountsJS ?? {})
	}, [authStore?.loggedUser?.childAccounts])

	return (
		<div className={`App h-full ${landingPage && "bg-[#04070b]"}`}>
			<GlobalAlert />
			{showWelcomeMessage && <WelcomeMessageModal authStore={authStore} />}
			{showLatestTermsModal && (
				<TermsHaveBeenUpdatedModal authStore={authStore} />
			)}
			<div
				style={{
					paddingBottom: isShowing ? 0 : getPaddingBottom(),
				}}
			>
				<FlowtyNavbar
					disconnectWallet={disconnectWallet}
					isDapper={authStore?.loggedUser?.isDapper}
					childAccounts={childAccounts}
					user={{
						authServiceUid: authStore?.loggedUser?.services[0]?.uid,
						userAddress: authStore?.loggedUser?.addr ?? "",
						userAvatar: authStore?.loggedUser?.avatar ?? "",
						userFlowBalance:
							authStore?.loggedUser?.balance?.balances?.[
								flowty.tokens.getTokenIdentifier(SupportedTokens.FLOW)
							],
						userName: authStore?.loggedUser?.userName ?? "",
						userUsdcBalance:
							authStore?.loggedUser?.balance?.balances?.[
								flowty.tokens.getTokenIdentifier(SupportedTokens.USDC)
							],
					}}
					notificationData={{
						fetchMoreNotifications,
						hasMore,
						loadingNotifications: loading,
						notifications,
						queueNotification,
					}}
					registerHeight={(height: number) => setNavbarOffsetHeight(height)}
					isLandingPage={!!landingPage}
					isCreatePage={pathname === "/create"}
					isLogged={!!authStore?.loggedUser?.loggedIn}
					selectedItem={selectedItem}
					logUser={() => connectWallet(setAuthLoading)}
					authLoading={authLoading}
					loggedUserAddress={authStore?.loggedUser?.addr || ""}
				/>
				<div style={{ marginTop: isShowing ? navbarOffsetHeight : 0 }}>
					<PageBanner />
				</div>
			</div>
			{!authStore?.loggedUser?.loggedIn &&
				showDapperSignInBar &&
				pathname !== "/create" && (
					<DapperSignInBar
						onDismiss={() => {
							setShowDapperSignInBar(false)
						}}
						connectDapperWallet={() => connectDapperWallet()}
					/>
				)}
			{children}
		</div>
	)
}

export default inject("rootStore", "authStore")(observer(Layout))
