import { IdentifierToPaymentToken } from "flowty-sdk"
import { useMemo } from "react"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { StorefrontEvent } from "../../types/StorefrontEventTypes"
import { OfferAcceptedRow } from "./OfferAcceptedRow"
import { OfferCancelledRow } from "./OfferCancelledRow"
import { OfferCreatedRow } from "./OfferCreatedRow"
import { StorefrontDeListedRow } from "./StorefrontDeListedRow"
import { StorefrontListedRow } from "./StorefrontListedRow"
import { StorefrontPurchasedRow } from "./StorefrontPurchasedRow"

interface Props {
	storefront: StorefrontEvent
}

export const StorefrontRow: React.FunctionComponent<Props> = ({
	storefront,
}) => {
	const storefrontPaymentTokenIdentifier =
		storefront.data.salePaymentVaultType ?? ""

	const storefrontListingRow = useMemo(() => {
		switch (storefront.type) {
			case "STOREFRONT_LISTED":
				return (
					<StorefrontListedRow
						storefrontAddress={storefront.data.storefrontAddress}
						salePrice={storefront.data.salePrice}
						tokenIdentifier={
							IdentifierToPaymentToken[storefrontPaymentTokenIdentifier]
						}
					/>
				)
			case "STOREFRONT_DELISTED":
				return <StorefrontDeListedRow />
			case "STOREFRONT_PURCHASED":
				return (
					<StorefrontPurchasedRow
						storefrontAddress={storefront.data.storefrontAddress}
						buyer={storefront?.data?.buyer ?? ""}
						salePrice={storefront.data.salePrice}
						tokenIdentifier={
							IdentifierToPaymentToken[storefrontPaymentTokenIdentifier]
						}
					/>
				)
			case "STOREFRONT_OFFER_CREATED":
				const paymentTokenName = storefront?.data?.paymentTokenName
				return (
					<OfferCreatedRow
						storefrontAddress={storefront.data.storefrontAddress}
						offeredAmount={storefront?.data?.offeredAmount ?? 0}
						tokenIdentifier={paymentTokenName}
					/>
				)
			case "STOREFRONT_OFFER_ACCEPTED":
				return (
					<OfferAcceptedRow
						taker={
							typeof storefront?.data?.taker === "object"
								? "-"
								: storefront?.data?.taker ?? "-"
						}
						storefrontAddress={storefront.data.storefrontAddress}
						offerAmount={storefront?.data?.offeredAmount ?? 0}
						tokenIdentifier={storefront?.data?.paymentTokenName}
					/>
				)
			case "STOREFRONT_OFFER_CANCELLED":
				return (
					<OfferCancelledRow
						offeredAmount={storefront?.data?.offeredAmount ?? 0}
						offerAddress={storefront.data.flowtyStorefrontAddress}
						paymentTokenType={storefront?.data?.paymentTokenName}
					/>
				)
			default:
				return null
		}
	}, [storefront])

	return (
		<div className='flex flex-col md:flex-row gap-2 md:gap-6'>
			<ListingIndicatorPill symbol={"BUY NOW"} />
			{storefrontListingRow}
		</div>
	)
}
