import { DocumentDuplicateIcon as CopyIcon } from "@heroicons/react/outline"
import {
	DocumentDuplicateIcon as CopiedIcon,
	EyeOffIcon,
	PencilIcon,
} from "@heroicons/react/solid"
import { SupportedTokens, TOKEN_TO_READABLE_TOKEN } from "flowty-common"
import { AccountSummary } from "flowty-sdk"
import React, { useMemo, useState } from "react"
import PlaceholderImage from "../../assets/missing-collection-image-icon.svg"
import ViewOnly from "../assets/viewOnly.png"
import { FlowtyBadge } from "../../FlowtyBadge"
import { cn } from "../../utils/cn"
import { ReactComponent as CheckIcon } from "../assets/checkIcon.svg"
import { LinkedAccountBalance } from "./LinkedAccountBalance"
import { EditWalletDetails } from "./EditWalletDetails"

type Props = {
	acct: AccountSummary
	isSelected: boolean
	onSelect: (id: string) => void
	onUploadNewImage: (
		event: React.ChangeEvent<HTMLInputElement>,
		setImage: (image: string) => void,
		setIsLoading: (isLoading: boolean) => void,
		fileName: string
	) => Promise<void | undefined>
	saveNewImage: (address: string, name: string, image: string) => void
	canEdit?: boolean
	tokenIdentifiers: Record<string, SupportedTokens>
	parentWalletAddress: string
}

export const LinkedAccountBox: React.FunctionComponent<Props> = ({
	acct,
	isSelected,
	canEdit,
	onSelect,
	onUploadNewImage,
	saveNewImage,
	tokenIdentifiers,
	parentWalletAddress,
}) => {
	const [isCopied, setIsCopied] = useState(false)
	const [isEditing, setIsEditing] = useState(false)

	const modifiedDisplayName = useMemo(() => {
		const originalName = acct.display?.name

		if (originalName && originalName.startsWith("0x")) {
			return originalName.substring(0, 7)
		} else {
			return originalName.length > 20
				? originalName.substring(0, 18) + "..."
				: originalName
		}
	}, [acct])

	const copyId = (id: string): void => {
		setIsCopied(true)
		;(navigator as Navigator).clipboard.writeText(id)
	}

	const resetCopy = (): void => {
		setIsCopied(false)
	}

	const handleCopyClick = (id: string): void => {
		copyId(id)
		setTimeout(resetCopy, 5000)
	}

	const openEdit = (): void => {
		setIsEditing(true)
	}

	const closeEdit = (): void => {
		setIsEditing(false)
	}

	function getKeyByValue(
		obj: { [key: string]: string },
		value: string
	): string {
		return Object.entries(obj).find(([_, val]) => val === value)?.[0] ?? ""
	}

	const border = isSelected
		? "border border-[#ADB5BD] border-[2px]"
		: "border border-[#47515d] hover:border-[#606973] border-[2px]"
	const idTextColor = isCopied ? "text-primary" : "text-white"
	const isEditingStyle = "blur-[1px] opacity-30"

	return (
		<div
			onClick={() => onSelect(acct.address ?? "")}
			key={acct.address}
			className={cn(
				border,
				"bg-[#FFFFFF1F] min-w-[18rem] min-h-[11rem] h-full md:h-auto flex flex-col gap-2 rounded-lg mr-4 py-[1rem] px-[1.25rem] relative cursor-pointer"
			)}
		>
			<div className='flex flex-col gap-2'>
				<div className='flex items-center justify-between'>
					<div className='flex gap-2 items-center'>
						{isEditing && <div className='w-[28px] h-[28px]' />}
						<img
							className={cn(
								"w-[28px] h-[28px] rounded-[0.25rem]",
								isEditing && "hidden"
							)}
							src={acct?.display?.thumbnail}
							alt='thumbnail'
							onError={({ currentTarget }) => {
								// eslint-disable-next-line no-param-reassign
								currentTarget.onerror = null // prevents looping
								// eslint-disable-next-line no-param-reassign
								currentTarget.src = PlaceholderImage
								// eslint-disable-next-line no-param-reassign
								currentTarget.className =
									"w-8 h-8 rounded-[0.25rem] text-white bg-glassFillGray"
							}}
						/>

						<p
							className={cn(
								"font-bold text-[1rem] truncate border border-transparent",
								isEditing && "hidden"
							)}
						>
							{modifiedDisplayName}
						</p>
					</div>
					{isSelected ? (
						<CheckIcon className={cn(isEditing && isEditingStyle)} />
					) : (
						<EyeOffIcon
							className={cn(
								`w-[1.125rem] h-[1.125rem] text-white`,
								isEditing && isEditingStyle
							)}
						/>
					)}
				</div>
				<div
					className={cn(`flex items-center gap-1`, isEditing && isEditingStyle)}
					onClick={e => {
						e.stopPropagation()
						handleCopyClick(acct.address)
					}}
				>
					<p
						className={`text-[#DEDEDE] text-[0.875rem] font-normal ${idTextColor}`}
					>
						{acct.address}
					</p>
					<div>
						<button className={cn("flex items-center group text-white")}>
							<div className='px-1'>
								<div className='px-1'>
									{isCopied ? (
										<CopiedIcon className='w-[1.125rem] h-[1.125rem] text-primary' />
									) : (
										<CopyIcon className='w-[1.125rem] h-[1.125rem]' />
									)}
								</div>
							</div>
						</button>
					</div>
				</div>
			</div>
			<div
				className={cn(
					`flex flex-wrap space-x-2 items-center`,
					isEditing && isEditingStyle
				)}
			>
				{acct.isMain ? (
					<>
						<FlowtyBadge text={"MAIN"} type={"primary"} size={"small"} />
						<FlowtyBadge text={"FULL"} type={"tertiary"} size={"small"} />
					</>
				) : (
					<FlowtyBadge text={"CHILD"} type={"secondary"} size={"small"} />
				)}
				{!acct.isMain && (
					<img src={ViewOnly} alt='view only' className=' w-[1rem] h-[1rem] ' />
				)}
			</div>
			<div className={"mt-3"}>
				<LinkedAccountBalance
					isEditing={isEditing}
					flowTokenSummary={
						acct.tokens?.[
							getKeyByValue(tokenIdentifiers, TOKEN_TO_READABLE_TOKEN.FLOW)
						]
					}
					usdcTokenSummary={
						acct.tokens?.[
							getKeyByValue(tokenIdentifiers, TOKEN_TO_READABLE_TOKEN.USDC)
						]
					}
				/>
				<div
					className={cn(
						"bottom-[1.125rem] right-[1.125rem]",
						!isEditing && "absolute"
					)}
				>
					{!acct.isMain && canEdit && (
						<>
							{isEditing ? (
								<EditWalletDetails
									clearIsEditing={() => setIsEditing(false)}
									onChangeHandler={onUploadNewImage}
									saveNewImage={saveNewImage}
									onCancel={closeEdit}
									defaultName={acct.display?.name ?? acct.address}
									defaultImage={acct.display?.thumbnail ?? PlaceholderImage}
									childWalletAddress={acct.address}
									parentWalletAddress={parentWalletAddress}
								/>
							) : (
								<button
									className='rounded-[0.5rem] backdrop-blur-[30px] bg-[#ffffff1f] p-[0.625rem] border-[1px] border-[#7C7C7C] hover:bg-[#ffffff33] hover:border-[#ffffff85]'
									onClick={e => {
										e.stopPropagation()
										openEdit()
									}}
								>
									<PencilIcon className='text-white h-[12px] w-[12px]' />
								</button>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
