import { useEffect, useState } from "react"
import {
	RecentBigSaleResponse,
	TopAndRecentSalesResponse,
} from "ds-flowty/src/LandingPage/LandingTopSales/types"

export const useFetchTopAndRecentSales = (): {
	topSalesData: TopAndRecentSalesResponse["data"] | null
	recentBigSaleData: RecentBigSaleResponse["data"] | null
	isLoading: boolean
} => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [topSalesData, setTopSalesData] = useState<
		TopAndRecentSalesResponse["data"] | null
	>(null)
	const [recentBigSaleData, setRecentBigSaleData] = useState<
		RecentBigSaleResponse["data"] | null
	>(null)

	const url = `https://confident-crystal-513bf384d1.strapiapp.com`

	useEffect(() => {
		const abortController = new AbortController()
		const fetchTopSalesListData = async (): Promise<void> => {
			try {
				const response = await fetch(
					`${url}/api/top-sales-lists?populate=image`,
					{
						signal: abortController.signal,
					}
				)
				if (!response.ok) {
					throw new Error("Network response was not ok")
				}
				const parsedResponse =
					(await response.json()) as TopAndRecentSalesResponse
				const sortParsedResponse = parsedResponse.data.sort((a, b) => {
					return +b.attributes.salePrice - +a.attributes.salePrice
				})

				setTopSalesData(sortParsedResponse)
			} catch (error) {
				if (error instanceof Error) {
					if (error.name !== "AbortError") {
						console.error("Fetch error:", error.message)
					}
				} else {
					console.error("Unexpected error:", error)
				}
			}
		}

		const fetchRecentBigSaleData = async (): Promise<void> => {
			try {
				const response = await fetch(
					`${url}/api/recent-big-sale?populate=collectionImage,videoUrl,recent_big_sale_attributes.name,recent_big_sale_attributes.imgUrl`,
					{ signal: abortController.signal }
				)
				if (!response.ok) {
					throw new Error("Network response was not ok")
				}
				const parsedResponse = (await response.json()) as RecentBigSaleResponse
				setRecentBigSaleData(parsedResponse.data)
			} catch (error) {
				if (error instanceof Error) {
					if (error.name !== "AbortError") {
						console.error("Fetch error:", error.message)
					}
				} else {
					console.error("Unexpected error:", error)
				}
			}
		}

		const fetchData = async (): Promise<void> => {
			setIsLoading(true)
			await Promise.all([fetchTopSalesListData(), fetchRecentBigSaleData()])
		}

		fetchData().then(() => {
			setTimeout(() => {
				setIsLoading(false)
			}, 300)
		})

		return () => {
			abortController.abort()
		}
	}, [url])

	return { isLoading, recentBigSaleData, topSalesData }
}
