import { cn } from "../utils/cn"
import { TabConfig } from "./FlowtyTabs"

export const SecondaryFlowtyTab: React.FC<TabConfig> = props => {
	return (
		<button
			role='tab'
			key={props.id}
			id={`tab-${props.id}`}
			aria-controls={`tabpanel-${props.id}`}
			aria-selected={props.isActive}
			disabled={props.disabled}
			onClick={() => props.onClick && props.onClick(props.id)}
			className={cn(
				`flex gap-[0.25rem] py-2 px-3 items-center justify-center font-montserrat text-[0.875rem] rounded-[0.25rem] border-none focus:ring-0 outline-none ${
					props.isActive
						? "bg-primary text-black"
						: "text-white hover:bg-navy-blue-900"
				} border-2 ${props.disabled ? "cursor-not-allowed opacity-50" : ""}`,
				{
					"bg-[#646c73] text-white": props.isActive,
					"flex-1 px-[12px] py-[6px]": props.condensed,
				}
			)}
		>
			<span className='font-[700] capitalize text-[0.875rem]'>
				{props.label.toUpperCase()}
			</span>

			{props.badgeNotification && (
				<span className='hidden md:flex font-[700] p-[0.25rem] md:flex-col rounded-sm flex-shrink-0 items-center justify-center text-[0.75rem] font-montserrat text-white text-center bg-black w-4 h-[0.875rem]'>
					{props.badgeNotification}
				</span>
			)}
		</button>
	)
}
