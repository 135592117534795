import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import React from "react"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	storefrontAddress: string
	taker: string
	offerAmount: number
	tokenIdentifier: SupportedTokens
}

export const OfferAcceptedRow: React.FC<Props> = ({
	storefrontAddress,
	offerAmount,
	tokenIdentifier,
	taker,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap items-center'>
			<span className='font-bold text-[14px]'>Offer</span> for{" "}
			<NftPrice price={offerAmount} token={tokenIdentifier} />
			<p className='text-start'>
				<span className='font-bold text-[14px]'>from</span>{" "}
				<ProfileLink address={storefrontAddress} /> was{" "}
				<span className='font-bold text-[14px]'>accepted by</span>{" "}
				<ProfileLink address={taker} />
			</p>
		</div>
	)
}
