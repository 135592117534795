import React from "react"
import { TooltipElement } from "../InfoText"

interface ConditionalTooltipProps {
	children: React.ReactElement
	tooltipText: string
	showTooltip: boolean
}

export const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
	showTooltip,
	tooltipText,
	children,
}) =>
	showTooltip ? (
		<TooltipElement
			Comp={children}
			tooltipText={tooltipText}
			tooltipId='conditional-tooltip'
		/>
	) : (
		children
	)
