import { OpensearchFlowNFT } from "flowty-common"
import React from "react"
import { useFlowtyListingModalContext } from "../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { ModalWrapper } from "./ModalWrapper"

interface ModalWrapperProps {
	isOpen: boolean
	openSearchFlowNFT: OpensearchFlowNFT
	hasProvider: boolean
	orderContent: React.ReactNode
	mixPanelFn: (event: string, data: unknown) => void
}

export const ModalWrapperListing: React.FunctionComponent<
	ModalWrapperProps
> = ({ isOpen, openSearchFlowNFT, hasProvider, orderContent, mixPanelFn }) => {
	const {
		blendedLTV,
		valuationData,
		onCloseModal,
		listingType,
		closeBtnProps,
		confirmBtnProps,
		orderData,
		singleAction,
		collectionImage,
		isDelist,
		isDapper,
		isLoadingValuation,
		isMainnet,
	} = useFlowtyListingModalContext()

	return (
		<ModalWrapper
			collectionImage={collectionImage}
			isDelist={
				Boolean(singleAction) || isDelist.sale || isDelist.loan || isDelist.rent
			}
			blendedLTV={blendedLTV}
			isOpen={isOpen}
			openSearchFlowNFT={openSearchFlowNFT}
			hasProvider={hasProvider}
			valuationData={valuationData}
			onCloseModal={onCloseModal}
			listingType={listingType}
			closeBtnProps={closeBtnProps}
			confirmBtnProps={confirmBtnProps}
			orderData={orderData}
			orderContent={orderContent}
			isDapper={isDapper}
			isLoadingValuation={isLoadingValuation}
			mixPanelFn={mixPanelFn}
			isMainnet={isMainnet}
		/>
	)
}
