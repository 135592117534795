import { useMemo } from "react"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { RentEvent } from "../../types/RentEventTypes"
import { RentListedRow } from "./RentListedRow"
import { IdentifierToPaymentToken } from "flowty-sdk"
import { RentRentedRow } from "./RentRentedRow"
import { RentSettledRow } from "./RentSettledRow"
import { RentReturnedRow } from "./RentReturnedRow"
import { RentDelistedRow } from "./RentDelistedRow"

interface Props {
	rent: RentEvent
}

export const RentRow: React.FunctionComponent<Props> = ({ rent }) => {
	const rentListingRow = useMemo(() => {
		switch (rent.type) {
			case "RENTAL_LISTED":
				return (
					<RentListedRow
						flowtyStorefrontAddress={rent.data.flowtyStorefrontAddress}
						deposit={rent.data.deposit}
						amount={rent.data.amount}
						term={rent.data.term}
						paymentTokenType={
							IdentifierToPaymentToken[rent.data.paymentTokenType]
						}
					/>
				)
			case "RENTAL_DESTROYED":
				return (
					<RentDelistedRow
						flowtyStorefrontAddress={rent.data.flowtyStorefrontAddress}
					/>
				)
			case "RENTAL_RENTED":
				return (
					<RentRentedRow
						renterAddress={rent.data.renterAddress}
						owner={rent.data.flowtyStorefrontAddress}
					/>
				)
			case "RENTAL_SETTLED":
				return (
					<RentSettledRow lender={rent.data.lender} renter={rent.data.renter} />
				)
			case "RENTAL_RETURNED":
				return <RentReturnedRow renterAddress={rent.data.renterAddress} />
		}
	}, [rent])

	return (
		<div className='flex flex-col md:flex-row gap-2 md:gap-6'>
			<ListingIndicatorPill symbol={"RENT"} />
			{rentListingRow}
		</div>
	)
}
