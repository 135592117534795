import React, { useEffect, useRef } from "react"
import { FlowtyNavbarProps } from "../types/NavbarTypes"
import { NavBarLogoAndLinks } from "./NavBarLogoAndLinks"
import { NavBarSignInAndNotifications } from "./NavBarSignInAndNotifications"

export const NavbarLaptop: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	authLoading,
	logUser,
	registerHeight,
	notificationData,
	selectedItem,
	loggedUserAddress,
	disconnectWallet,
	childAccounts,
	isDapper,
	user,
}) => {
	const baseStyles = `${bgColor} py-[18px] flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-50 font-black text-white`

	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = (): void => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = (): void => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	return (
		<div>
			<div
				className={baseStyles}
				ref={navbarRef}
				data-testid='navbar-component'
			>
				<nav className={`flex w-full justify-between relative px-12 h-[40px]`}>
					<NavBarLogoAndLinks selectedItem={selectedItem} />

					<NavBarSignInAndNotifications
						fetchMoreNotifications={notificationData.fetchMoreNotifications}
						loadingNotifications={notificationData.loadingNotifications}
						hasMore={notificationData.hasMore}
						notifications={notificationData.notifications}
						queueNotification={notificationData.queueNotification}
						isLogged={isLogged}
						logUser={logUser}
						isLoading={authLoading}
						loggedUserAddress={loggedUserAddress || ""}
						disconnectWallet={disconnectWallet}
						childAccounts={childAccounts}
						isDapper={isDapper}
						user={user}
					/>
				</nav>
			</div>
		</div>
	)
}
