import { FlowtyNumericInput } from "ds-flowty"
import { useEffect } from "react"
import { MappedRangeFilter } from "../../../OpenSearch/types/orders"
import { SerialRangeType } from "../SerialRangeFilter"

interface SerialInputsContainerProps {
	values: SerialRangeType
	setSerialFilter: (val: MappedRangeFilter | null) => void
	serialFilter: MappedRangeFilter | null
	setFieldValue: (field: string, value: string) => void
	disabled?: boolean
}

export const SerialInputsContainer: React.FunctionComponent<
	SerialInputsContainerProps
> = ({
	values,
	serialFilter,
	setSerialFilter,
	setFieldValue,
	disabled = false,
}) => {
	const errorField =
		Number(values?.serialNumberMax) < Number(values?.serialNumberMin) &&
		Number(values?.serialNumberMax) > 0
	useEffect(() => {
		if (!errorField) {
			setSerialFilter({
				max: values?.serialNumberMax,
				min: values?.serialNumberMin,
			})
		}
	}, [values])

	useEffect(() => {
		if (disabled) {
			setSerialFilter(null)
			setFieldValue("serialNumberMin", "")
			setFieldValue("serialNumberMax", "")
		}
	}, [disabled])

	return (
		<>
			<FlowtyNumericInput
				name={`serialNumberMin`}
				valueError={errorField}
				placeholder={serialFilter?.min?.toString() || "Min"}
				disabled={disabled}
			/>
			<FlowtyNumericInput
				name={`serialNumberMax`}
				valueError={errorField}
				placeholder={serialFilter?.max?.toString() || "Max"}
				disabled={disabled}
			/>
		</>
	)
}
