import { ReactComponent as Arrow } from "../../../assets/arrow.svg"
import { ReactComponent as Book } from "../../../assets/book.svg"
import Carousel from "../Carousel"

interface DesktopProps {
	isUserLoggedIn: boolean
	connectWallet: () => void
}

const Desktop: React.FC<DesktopProps> = ({ isUserLoggedIn, connectWallet }) => {
	return (
		<div className='flex flex-col items-center pt-[61px] gap-[53px] 2xl:max-w-[1920px] mx-auto rounded-[24px] relative'>
			<div
				style={{
					background: "rgba(255, 255, 255, 0.04)",
					height: "65%",
				}}
				className='absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-[24px] z-0'
			></div>
			<div className='flex flex-col items-center leading-normal z-10'>
				<div className='text-[#FFF] text-[48px] font-[800]'>
					3 Steps To Get Started
				</div>
				<div className='text-neutral-500 text-[24px] font-[400]'>
					Don&apos;t miss out on great deals and awesome features only available
					on Flowty!
				</div>
			</div>
			<div className='flex items-center justify-start gap-[24px] z-10'>
				<a
					href='https://docs.flowty.io/reference'
					className='flex w-[340px] flex-1 py-[12px] px-[24px] gap-[16px] items-center justify-center rounded-[6px] border-2 border-neutral-300 hover:opacity-80'
					target='_blank'
					rel='noreferrer noopener'
				>
					<span className='text-neutral-900 text-center text-[18px] font-[900] leading-[16px]'>
						LEARN MORE
					</span>
					<Book />
				</a>
				{!isUserLoggedIn && (
					<button
						onClick={connectWallet}
						className='flex w-[340px] flex-1 bg-neutral-900 py-[12px] px-[24px] gap-[16px] items-center justify-center rounded-[6px] hover:opacity-80'
					>
						<span className='text-center text-[18px] font-[900] leading-[16px] text-navy-blue-900'>
							SIGN IN
						</span>
						<Arrow />
					</button>
				)}
			</div>
			<Carousel />
		</div>
	)
}

export default Desktop
