import { EmbeddedTweetResponse } from "ds-flowty"
import { useEffect, useState } from "react"

export const useFetchTweets = (): {
	tweetIds: string[]
	isLoading: boolean
} => {
	const [tweetIds, setTweetIds] = useState<string[]>([])

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const url = `https://confident-crystal-513bf384d1.strapiapp.com`

	useEffect(() => {
		const abortController = new AbortController()
		const fetchTweets = async () => {
			try {
				const response = await fetch(`${url}/api/embedded-tweets`, {
					signal: abortController.signal,
				})
				if (!response.ok) {
					throw new Error("Network response was not ok")
				}
				const parsedResponse = (await response.json()) as EmbeddedTweetResponse

				const tweets = parsedResponse.data.map(
					tweet => tweet.attributes.tweetId
				)

				setTweetIds(tweets)
			} catch (error) {
				if (error instanceof Error) {
					if (error.name !== "AbortError") {
						console.error("Fetch error:", error.message)
					}
				} else {
					console.error("Unexpected error:", error)
				}
			}
		}

		const fetchData = async () => {
			setIsLoading(true)
			await Promise.all([fetchTweets()])
			setIsLoading(false)
		}

		fetchData()

		return () => {
			abortController.abort()
		}
	}, [url])

	return { isLoading, tweetIds }
}
