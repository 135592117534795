import React, { PropsWithChildren, useEffect, useState } from "react"

export interface SnackbarProps extends PropsWithChildren {
	setShow: (val: boolean) => void
	show: boolean
	seconds?: number
}

export const Snackbar: React.FC<SnackbarProps> = ({
	children,
	setShow,
	show,
	seconds,
}) => {
	const [mount, setMount] = useState(false)
	useEffect(() => {
		if (show) {
			setMount(true)
			seconds &&
				setTimeout(() => {
					setMount(false)
					setTimeout(() => setShow(false), 1000)
				}, seconds * 1000)
		} else {
			setMount(false)
		}
	}, [show])

	return (
		<>
			{show ? (
				<div
					className={`fixed flex w-full left-0 justify-center  z-[99999] small-only:top-0  md:bottom-0 h-[25%] overflow-hidden`}
				>
					<div className='relative w-full h-full flex items-center z-[99999]'>
						<div
							className={`w-full flex justify-center transition-all duration-500 ease-in-out absolute ${
								mount
									? "small-only:top-1/4  md:bottom-1/2 "
									: "small-only:-top-1/2  md:-bottom-1/2"
							}`}
						>
							{children}
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}
