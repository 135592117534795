import React, { useContext } from "react"
import { ReactComponent as DapperDUCIcon } from "../../../assets/media/tokens/dapperDUC.svg"
import { ReactComponent as DapperFUTIcon } from "../../../assets/media/tokens/dapperFUT.svg"
import { ReactComponent as FlowIcon } from "../../../assets/media/tokens/flow.svg"
import { ReactComponent as USDCIcon } from "../../../assets/media/tokens/newUsdc.svg"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { FilterPill } from "./FilterPill"
import { SupportedTokens } from "flowty-common"

const addonMap = {
	DUC: { icon: <DapperDUCIcon />, label: "Dapper Balance" },
	FLOW: { icon: <FlowIcon height={18} width={18} />, label: "FLOW" },
	FUT: { icon: <DapperFUTIcon />, label: "Dapper FLOW" },
	USDC: { icon: <USDCIcon />, label: "USDC" },
}

/* eslint-disable import/no-unused-modules */
export const CurrencyTags: React.FC = () => {
	const { paymentTokens, setPaymentTokens } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const currencies = Object.entries(paymentTokens ?? {})
		.filter(([, value]) => value && typeof value === "object")
		.map(([, value]) => Object.keys(value).filter(key => value[key] === true))
		.reduce((acc, arr) => acc.concat(arr), [])

	return (
		<>
			{currencies.map(currency => {
				return (
					<div className='flex' key={currency}>
						<div className='fade-in'>
							<FilterPill
								text={addonMap[currency as SupportedTokens].label}
								addon={addonMap[currency as SupportedTokens].icon}
								label='Currency:'
								onClick={() => {
									const copyFilters = { ...paymentTokens }

									if (currency === "DUC") {
										delete copyFilters?.dapper?.[currency]
									}

									if (currency === "FLOW" || currency === "USDC") {
										delete copyFilters?.other?.[currency]
									}

									setPaymentTokens(copyFilters)
								}}
							/>
						</div>
					</div>
				)
			})}
		</>
	)
}
