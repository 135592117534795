import { FlowtyTabs } from "ds-flowty"
import { OpensearchFlowNFT, Order } from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useFlowtyModalsContext } from "../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { useHybridCustodyContext } from "../../../../contexts/HybridCustodyContext"
import { OpensearchNftOrders } from "../../../../screens/AssetScreen/AssetScreen"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { LoansPreview } from "./Components/Listings/LoansPreview"
import { RentalsPreview } from "./Components/Listings/RentalsPreview"
import { StorefrontsPreview } from "./Components/Listings/StorefrontsPreview"
import { TransferPreview } from "./Components/Listings/TransferPreview/TransferPreview"
import { ModalAction } from "../../../../components/Modals/ListedActions/SingleActionModals/SingleListedActionModalManager"
import { ViewOnlyDisplay } from "../../../../components/Modals/Listings/ViewOnly/ViewOnlyDisplay"

interface ListingsTabProps extends AuthStoreProp {
	asset: OpensearchFlowNFT
	orders: OpensearchNftOrders
}

const ListingsTabComponent: React.FC<ListingsTabProps> = ({
	asset,
	orders,
	authStore,
}) => {
	const [activeTab, setActiveTab] = useState<string>(
		orders.storefront.length > 0
			? "storefront"
			: orders.loans.length > 0
			? "loan"
			: orders.rentals.length > 0
			? "rental"
			: "storefront"
	)

	const { selectCard } = useFlowtyModalsContext()

	const updateSelectedCard = useCallback(
		({
			listing,
			listingType,
			singleDelistAction,
			singleDelistOrder,
		}: {
			listing?: Order
			listingType?: "loan" | "rent" | "transfer"
			singleDelistOrder?: Order
			singleDelistAction?: "delistLoan" | "delistRental" | "delistSale"
		}) => {
			selectCard({
				initListingType: listingType as
					| "loan"
					| "rent"
					| "transfer"
					| undefined,
				selected: {
					contractAddress: asset.contractAddress,
					contractName: asset.contractName,
					nftID: asset.id,
					nftType: asset.type,
				},
				selectedSinglePurchase: listing,
				singleDelistAction: singleDelistAction,
				singleDelistOrder: singleDelistOrder,
			})
		},
		[asset, selectCard]
	)

	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []
	const isOwner = userAddresses.includes(asset?.owner || "")

	const { hybridCustodyNFTStatus, iterateAndRunScript } =
		useHybridCustodyContext()

	useEffect(() => {
		const facets = `${asset.contractAddress}.${asset.contractName}`
		iterateAndRunScript(
			[facets],
			authStore?.loggedUser?.childAccounts || {},
			authStore?.loggedUser?.addr || ""
		)
	}, [])

	const hasProvider = useCallback(
		(nft: OpensearchFlowNFT): boolean | null => {
			if (nft.owner === authStore?.loggedUser?.addr) {
				return true
			}

			const paths = hybridCustodyNFTStatus[nft.owner]
			if (paths === undefined) {
				return null
			}

			return (
				paths?.[nft.contractAddress.concat(".").concat(nft.contractName)]
					?.length > 0
			)
		},
		[hybridCustodyNFTStatus]
	)

	const tabs: {
		badgeNotification?: string
		content: React.ReactNode
		id: ModalAction
		label: string
		disabled?: boolean
	}[] = useMemo(() => {
		if (isOwner) {
			return [
				{
					badgeNotification: orders?.storefront?.length
						? orders?.storefront?.length.toString()
						: undefined,
					content: (
						<StorefrontsPreview
							setSelectedListing={updateSelectedCard}
							asset={asset}
							orders={orders?.storefront}
							isAssetOwner={isOwner}
						/>
					),
					id: "storefront",
					label: "Buy",
				},
				{
					badgeNotification: orders?.loans?.length
						? orders?.loans?.length?.toString()
						: undefined,
					content: (
						<LoansPreview
							asset={asset}
							setSelectedListing={updateSelectedCard}
							orders={orders?.loans}
							isAssetOwner={isOwner}
						/>
					),
					id: "loan",
					label: "Loan",
				},
				{
					badgeNotification: orders?.rentals?.length
						? orders.rentals.length?.toString()
						: undefined,
					content: (
						<RentalsPreview
							asset={asset}
							setSelectedListing={updateSelectedCard}
							orders={orders?.rentals}
							isAssetOwner={isOwner}
						/>
					),
					id: "rental",
					label: "Rent",
				},
				{
					content: <TransferPreview asset={asset} />,
					disabled: !isOwner,
					id: "accept-storefront-offer",
					label: "Transfer",
				},
			]
		} else {
			return [
				{
					badgeNotification: orders?.storefront?.length
						? orders?.storefront?.length.toString()
						: undefined,
					content: (
						<StorefrontsPreview
							setSelectedListing={updateSelectedCard}
							asset={asset}
							orders={orders?.storefront}
							isAssetOwner={isOwner}
						/>
					),
					id: "storefront",
					label: "Buy",
				},
				{
					badgeNotification: orders?.loans?.length
						? orders?.loans?.length?.toString()
						: undefined,
					content: (
						<LoansPreview
							asset={asset}
							setSelectedListing={updateSelectedCard}
							orders={orders?.loans}
							isAssetOwner={isOwner}
						/>
					),
					id: "loan",
					label: "Loan",
				},
				{
					badgeNotification: orders?.rentals?.length
						? orders.rentals.length?.toString()
						: undefined,
					content: (
						<RentalsPreview
							asset={asset}
							setSelectedListing={updateSelectedCard}
							orders={orders?.rentals}
							isAssetOwner={isOwner}
						/>
					),
					id: "rental",
					label: "Rent",
				},
			]
		}
	}, [isOwner, orders])

	const handleTabChange = useCallback(
		(tabId: string) => {
			setActiveTab(tabId)
		},
		[setActiveTab]
	)

	return (
		<>
			{!hasProvider(asset) && isOwner ? (
				<ViewOnlyDisplay />
			) : (
				<FlowtyTabs
					tabs={tabs}
					activeTab={activeTab}
					onChange={handleTabChange}
					type='secondary'
				/>
			)}
		</>
	)
}

export const ListingsTab = inject("authStore")(observer(ListingsTabComponent))
