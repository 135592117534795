export const OfferWarning: React.FunctionComponent = () => {
	return (
		<div className='flex items-center self-end gap-[0.25rem] text-warning font-montserrat text-[10px] font-[400] leading-[0.75rem]'>
			<svg
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.43123 2.78899C8.11938 1.56562 9.88075 1.56562 10.5689 2.78899L15.5912 11.7175C16.2661 12.9174 15.399 14.3999 14.0223 14.3999H3.97779C2.6011 14.3999 1.73402 12.9174 2.40896 11.7175L7.43123 2.78899ZM9.89999 11.7C9.89999 12.1971 9.49704 12.6 8.99999 12.6C8.50293 12.6 8.09999 12.1971 8.09999 11.7C8.09999 11.203 8.50293 10.8 8.99999 10.8C9.49704 10.8 9.89999 11.203 9.89999 11.7ZM8.99999 4.50005C8.50293 4.50005 8.09999 4.90299 8.09999 5.40005V8.10005C8.09999 8.59711 8.50293 9.00005 8.99999 9.00005C9.49704 9.00005 9.89999 8.59711 9.89999 8.10005V5.40005C9.89999 4.90299 9.49704 4.50005 8.99999 4.50005Z'
					fill='#FF6E25'
				/>
			</svg>
			<p className='text-[10px]'>
				Your offer is above the listing price for this item
			</p>
		</div>
	)
}
