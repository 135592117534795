import { useState } from "react"
import { AccordionItemType } from "./types/FlowtyAccordionTypes"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"

interface AccordionItemProps {
	item: AccordionItemType
	isCreatorHub?: boolean
}

export const AccordionItem: React.FunctionComponent<AccordionItemProps> = ({
	item,
	isCreatorHub = false,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	if (item.notDisplay) return null

	return (
		<div
			className={`flex flex-col ${
				isCreatorHub ? "gap-[4px] 3xl:gap-[8px]" : "gap-2"
			}`}
			aria-checked
			aria-disabled
			onClick={() => setIsOpen(!isOpen)}
		>
			<div className='flex justify-between cursor-pointer items-start'>
				<div className='flex justify-center items-center'>
					{isOpen ? (
						<ChevronUpIcon className='h-[1.125rem] w-[1.125rem]' />
					) : (
						<ChevronDownIcon className='h-[1.125rem] w-[1.125rem]' />
					)}
					<span
						className={`${
							isCreatorHub
								? "text-[9.333px] 3xl:text-[14px]"
								: "text-sm font-normal ml-1"
						}`}
					>
						{item.title}
					</span>
					{item.badge && (
						<div
							className={`flex-shrink-0 mx-2 flex justify-center items-center bg-[#2F2FA5] shadow-xl rounded-md bg-opacity-50 font-montserrat-bold font-bold text-[#9D9DFE] uppercase ${
								isCreatorHub
									? "text-[9.333px] 3xl:text-[12px]"
									: "py-1 px-2 text-xs"
							}`}
						>
							{" "}
							{item.badge}
						</div>
					)}
				</div>
				{item.addOn && item.addOn}
			</div>
			<div
				className={`ml-1 font-montserrat ${
					isCreatorHub
						? "text-[9.333px] leading-normal 3xl:text-[14px]"
						: "text-sm"
				}`}
			>
				{isOpen && item.content}
			</div>
		</div>
	)
}
