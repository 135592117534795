import { useEffect } from "react"
import { Snackbar, SnackbarProps } from "../Snackbar/Snackbar"

interface SnackbarGeneralMsgProps extends SnackbarProps {
	title: string
	message: string
}

export const SnackbarGeneralMsg: React.FunctionComponent<
	SnackbarGeneralMsgProps
> = ({ setShow, show, seconds, title, message }) => {
	useEffect(() => {
		const modals = document.querySelectorAll("[id^='headlessui-dialog-panel']")

		const modal = modals[0]

		modal?.addEventListener("click", () => {
			setShow(false)
		})

		return () => {
			modal?.removeEventListener("click", () => {
				setShow(false)
			})
		}
	}, [])

	return (
		<Snackbar setShow={setShow} show={show} seconds={seconds}>
			<div className='rounded-md bg-blue-50 p-4'>
				<div className='flex'>
					<div className='flex-shrink-0'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='h-6 w-6 text-blue-600'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
							/>
						</svg>
					</div>
					<div className='ml-3'>
						<div className='flex flex-row justify-between'>
							<h3 className='text-sm font-medium text-blue-800'>{title}</h3>
							<button
								className='whitespace-nowrap font-medium text-blue-700 hover:text-blue-600'
								onClick={() => setShow(false)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='w-5 h-5'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</div>
						<p className='mt-2 text-sm text-blue-700'>{message}</p>
					</div>
				</div>
			</div>
		</Snackbar>
	)
}
