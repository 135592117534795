/* eslint-disable @typescript-eslint/no-use-before-define */
import { Config } from "../types"
import { FlowNFTData } from "../common/CommonTypes"

export const getTransferNftTxn = (
	config: Config,
	nftData: FlowNFTData,
	useCatalog: boolean
): string =>
	config.crescendo
		? transferNftTxnCrescendo(config, nftData, useCatalog)
		: transferNftTxn(config, nftData, useCatalog)

const transferNftTxn = (
	config: Config,
	nftData: FlowNFTData,
	useCatalog: boolean
): string => {
	if (nftData.contractName === "TopShot") {
		return transferNftTopShot(config)
	}

	if (useCatalog) {
		return transferNftWithCatalog(config)
	}

	return transferNftViewResolver(config)
}

const transferNftTxnCrescendo = (
	config: Config,
	nftData: FlowNFTData,
	useCatalog: boolean
): string => {
	if (nftData.contractName === "TopShot") {
		return transferNftTopShotCrescendo(config)
	}

	if (useCatalog) {
		return transferNftWithCatalogCrescendo(config)
	}

	return transferNftViewResolverCrescendo(config)
}

const transferNftTopShot = (config: Config): string => ``

const transferNftTopShotCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

import HybridCustody from ${config.contractAddresses.HybridCustody}

import TopShot from ${config.contractAddresses.TopShot}

/*
Flowty - NFT Transfer - Catalog w/ TopShot special handling
Transfer an NFT from the nftProvider (childAccount) to a specified recipient.
 */

transaction(tokenID: UInt64, to: Address, nftProviderAddress: Address, nftProviderControllerID: UInt64, collectionIdentifier: String) {
  let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}
  prepare(signer: auth(Capabilities, Storage) &Account) {
    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    if nftProviderAddress == signer.address {
      self.nftProvider = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(from: catalogEntry.collectionData.storagePath)
        ?? panic("could not find sender collection")
    } else {
      let manager = signer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no cap found")
      let nftProviderCap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
      self.nftProvider = nftProviderCap.borrow() ?? panic("unable to borrow child account collection provider")
    }

    let nft <- self.nftProvider.withdraw(withdrawID: tokenID)
    let recipient = getAccount(to)

    let standardCap = recipient.capabilities.get<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.publicPath)
    if standardCap.check() {
      let r = standardCap.borrow() ?? panic("invalid receiver collection")
      r.deposit(token: <-nft)
      return
    }
    
    assert(nft.getType() == Type<@TopShot.NFT>(), message: "No receiver found for Item")
    assert(catalogEntry.collectionData.publicPath.toString() == "MomentCollection", message: "incorrect path for TopShot collection")

    // Special handling for accounts that expose TopShot.MomentCollectionPublic instead of standard
    let tsCap = recipient.capabilities.get<&{TopShot.MomentCollectionPublic}>(catalogEntry.collectionData.publicPath)
    if tsCap.check() {
      let r = tsCap.borrow() ?? panic("invalid TopShot.MomentCollectionPublic")
      r.deposit(token: <-nft)
      return
    }

    // we should not reach here.
    panic("no valid receiver found")
  }
}`

const transferNftWithCatalog = (config: Config): string => ``

const transferNftWithCatalogCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/*
Flowty - NFT Transfer - Catalog w/ TopShot special handling
Transfer an NFT from the nftProvider (childAccount) to a specified recipient.
 */

transaction(tokenID: UInt64, to: Address, nftProviderAddress: Address, nftProviderControllerID: UInt64, collectionIdentifier: String) {
  let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}
  prepare(signer: auth(Capabilities, Storage) &Account) {
    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    if nftProviderAddress == signer.address {
      self.nftProvider = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(from: catalogEntry.collectionData.storagePath)
        ?? panic("could not find sender collection")
    } else {
      let manager = signer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no cap found")
      let nftProviderCap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
      self.nftProvider = nftProviderCap.borrow() ?? panic("unable to borrow child account collection provider")
    }

    let nft <- self.nftProvider.withdraw(withdrawID: tokenID)
    let recipient = getAccount(to)

    let standardCap = recipient.capabilities.get<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.publicPath)
    if standardCap.check() {
      let r = standardCap.borrow() ?? panic("invalid receiver collection")
      r.deposit(token: <-nft)
      return
    }
    
    // we should not reach here.
    panic("no valid receiver found")
  }
}`

export const transferNftViewResolver = (config: Config): string => ``

export const transferNftViewResolverCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import AddressUtils from ${config.contractAddresses.AddressUtils}

/*
Flowty - NFT Transfer - No Catalog
Transfer an NFT from the nftProvider (childAccount) to a specified recipient.
 */

transaction(
    tokenID: UInt64,
    to: Address,
    nftProviderAddress: Address,
    nftProviderControllerID: UInt64,
    typeIdentifier: String
) {
  let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}
  prepare(signer: auth(Capabilities, Storage) &Account) {
    let nftType = CompositeType(typeIdentifier) ?? panic("invalid type identifier")
    let contractAddress = AddressUtils.parseAddress(nftType)!
    let contractName = typeIdentifier.split(separator: ".")[2]

    let c = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
    let md = c.resolveContractView(resourceType: nftType, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
    let collectionData = md as! MetadataViews.NFTCollectionData

    if nftProviderAddress == signer.address {
      self.nftProvider = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(from: collectionData.storagePath)
        ?? panic("could not find sender collection")
    } else {
      let manager = signer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no cap found")
      let nftProviderCap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
      self.nftProvider = nftProviderCap.borrow() ?? panic("unable to borrow child account collection provider")
    }

    let recipient = getAccount(to).capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath).borrow() ?? panic("invalid receiver collection")
    let nft <- self.nftProvider.withdraw(withdrawID: tokenID)
    recipient.deposit(token: <-nft)
  }
}`
