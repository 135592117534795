import { useEffect, useState } from "react"
import { Flowty } from "../flowty"

interface GetCollectionRoyaltyProps {
	flowtyStorefrontFee: number | null
	isLoading: boolean
}

interface UseCollectionRoyaltyProps {
	identifier: string
	flowty: Flowty
}

export const useGetFlowtyStorefrontFee = ({
	identifier,
	flowty,
}: UseCollectionRoyaltyProps): GetCollectionRoyaltyProps => {
	const [flowtyStorefrontFee, setFlowtyStorefrontFee] = useState<number | null>(
		null
	)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		flowty.scripts.getFlowtyStorefrontFee(identifier).then(res => {
			setFlowtyStorefrontFee(res)
			setIsLoading(false)
		})
	}, [identifier])

	return { flowtyStorefrontFee, isLoading }
}
