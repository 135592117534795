/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unused-modules */
import {
	getTokenFromType,
	ListingAvailableData,
	RentalListingAvailableData,
	StorefrontV2ListingAvailable,
} from "flowty-common"
import chunk from "lodash/chunk"
import keyBy from "lodash/keyBy"
import { SortColumnOrNull } from "../../components/SortableTable"
import { firestore } from "../../firebase"
import { Log } from "../../util/Log"

interface SubscribeProps {
	limit?: number
	accountId: string
	cb: (arg: Array<any>) => void // TODO add typing
	filterBy?: any
	isRentalView?: boolean
	asOwner?: boolean
	asBuyer?: boolean
	accountIDs?: string[]
}

interface ActivitySubscribeProps {
	limit?: number
	accountId?: string
	makerCb: (arg: Array<any>) => void // TODO add typing
	takerCb?: (arg: Array<any>) => void // TODO add typing
	sort?: SortColumnOrNull
	filterActivityBy?: any
	eventCollection?: string
	listingCollection?: string
	addressPath?: string
	storefrontAccountId?: string
	nftTypes?: string[]
	accountIDs?: string[]
}

export const subscribeToActivity = ({
	limit = 25,
	accountId,
	makerCb,
	sort,
	filterActivityBy,
	eventCollection = "p2pEvents",
	addressPath = "data.lender",
	storefrontAccountId,
	nftTypes,
}: ActivitySubscribeProps): void => {
	const excludedHotWheelsContractAddress = "0xd0bcefdf1e67ea85"

	let eventsQuery = firestore
		.collection(eventCollection)
		.orderBy(sort?.column ?? "blockTimestamp", sort?.order ?? "desc")
		.where(
			"additionalData.card.collectionAddress",
			"!=",
			excludedHotWheelsContractAddress
		)

	if (filterActivityBy?.min) {
		eventsQuery = eventsQuery.where("type", "==", filterActivityBy?.min)
	}

	if (accountId) {
		eventsQuery = eventsQuery.where("accountAddress", "in", [accountId])
	}

	if (storefrontAccountId) {
		eventsQuery = eventsQuery.where(addressPath, "in", [storefrontAccountId])
	}

	if (nftTypes && nftTypes.length > 0) {
		eventsQuery = eventsQuery.where("data.nftType", "in", nftTypes)
	}

	eventsQuery.limit(limit).onSnapshot(async ({ docs }) => {
		if (eventCollection === "storefrontEvents") {
			const cbDocs = docs.map(doc => {
				const event = doc.data()

				const formattedEvent = {
					...event,
					amount: event.data.salePrice || event.data.amount,
					card: event?.additionalDetails?.card || event?.data?.card || null,
					commissionAmount: event.data.commissionAmount,
					id: doc.id,
					paymentTokenName:
						event.data.paymentTokenName ||
						getTokenFromType(
							event.data.salePaymentVaultType || event.data.paymentTokenType
						),
					salePaymentVaultType: event.data.salePaymentVaultType,
					type: event.type,
				}
				return formattedEvent
			})

			makerCb(cbDocs)
		}
	})
}

export const subscribeToAllActivity = ({
	limit = 25,
	accountId,
	makerCb,
	takerCb,
	sort,
	filterActivityBy,
	eventCollection = "p2pEvents",
	listingCollection = "listingAvailable",
	addressPath = "data.lender",
	storefrontAccountId,
	accountIDs,
}: ActivitySubscribeProps) => {
	const accounts = accountIDs ? accountIDs : [accountId]
	const storefrontAccounts = accountIDs ? accountIDs : [storefrontAccountId]

	Log("subscribeToAllActivity accountIDs", {
		accountIDs,
	})

	let borrowerQuery = firestore
		.collection(eventCollection)
		.orderBy(sort?.column ?? "blockTimestamp", sort?.order ?? "desc")

	let lenderQuery = firestore
		.collection(eventCollection)
		.orderBy("blockTimestamp", "desc")

	if (filterActivityBy?.min) {
		borrowerQuery = borrowerQuery.where("type", "==", filterActivityBy?.min)
		lenderQuery = lenderQuery.where("type", "==", filterActivityBy?.min)
	}

	if (accountId) {
		borrowerQuery = borrowerQuery.where("accountAddress", "in", accounts)
		lenderQuery = lenderQuery.where(addressPath, "in", accounts)
	}

	// maker/taker lender/borrower seller/buyer
	if (storefrontAccountId) {
		borrowerQuery = borrowerQuery.where(addressPath, "in", storefrontAccounts)
		lenderQuery = lenderQuery.where("accountAddress", "in", storefrontAccounts)
	}

	const unsubscribeFromBorrowerEvents = borrowerQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			// Same batching stuff... getNFTinfos
			const listingAvailableGroups = chunk(docs, 10) // max batch size;

			await Promise.all(
				listingAvailableGroups.map(listingAvailableGroup =>
					firestore
						.collection(listingCollection)
						.where(
							"listingResourceID",
							"in",
							listingAvailableGroup
								.map(doc => doc.data().data?.listingResourceID)
								.filter(i => !!i) // prevent null NFTs query
						)
						.get()
				)
			).then(updatedListingAvailableGroups => {
				if (eventCollection === "p2pEvents") {
					const associatedListingAvailables = keyBy(
						updatedListingAvailableGroups.flatMap(group =>
							group.docs.map(
								doc => ({ ...doc.data(), id: doc.id } as ListingAvailableData)
							)
						),
						"listingResourceID"
					)

					makerCb(
						docs.map(doc => ({
							...doc.data(),
							amount:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.amount,
							id: doc.id,
							nftInfo:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.detail,
							paymentTokenName:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.paymentTokenName,
							rate: associatedListingAvailables[
								doc.data().data.listingResourceID
							]?.interestRate,
							royaltyRate:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.royaltyRate,
							term: associatedListingAvailables[
								doc.data().data.listingResourceID
							]?.term,
							valuations:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.valuations,
						}))
					)
				} else if (eventCollection === "rentalEvents") {
					const associatedListingAvailables = keyBy(
						updatedListingAvailableGroups.flatMap(group =>
							group.docs.map(
								doc =>
									({ ...doc.data(), id: doc.id } as RentalListingAvailableData)
							)
						),
						"listingResourceID"
					)
					makerCb(
						docs.map(doc => ({
							...doc.data(),
							amount:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.amount,
							deposit:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.deposit,
							id: doc.id,
							nftInfo:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.detail,
							paymentTokenName:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.paymentTokenName,
							royaltyRate:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.royaltyRate,
							term: associatedListingAvailables[
								doc.data().data.listingResourceID
							]?.term,
							valuations:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.valuations,
						}))
					)
				} else if (eventCollection === "storefrontEvents") {
					const associatedListingAvailables = keyBy(
						updatedListingAvailableGroups.flatMap(group =>
							group.docs.map(
								doc =>
									({
										...doc.data(),
										id: doc.id,
									} as StorefrontV2ListingAvailable)
							)
						),
						"listingResourceID"
					)
					makerCb(
						docs.map(doc => ({
							...doc.data(),
							amount:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.salePrice,
							card: associatedListingAvailables[
								doc.data().data.listingResourceID
							]?.card,
							commissionAmount:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.commissionAmount,
							id: doc.id.toString(),
							paymentTokenName:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.paymentTokenName,
							salePaymentVaultType:
								associatedListingAvailables[doc.data().data.listingResourceID]
									?.salePaymentVaultType,
						}))
					)
				}
			})
		})

	const unsubscribeFromLenderEvents =
		accountId || storefrontAccountId
			? lenderQuery.limit(limit).onSnapshot(async ({ docs }) => {
					// Same batching stuff... getNFTinfos
					const listingAvailableGroups = chunk(docs, 10) // max batch size;
					await Promise.all(
						listingAvailableGroups.map(listingAvailableGroup =>
							firestore
								.collection(listingCollection)
								.where(
									"listingResourceID",
									"in",
									listingAvailableGroup
										.map(doc => doc.data().data?.listingResourceID)
										.filter(i => !!i) // prevent null NFTs query
								)
								.get()
						)
					).then(listingAvailableGroupsUpdated => {
						const associatedListingAvailables = keyBy(
							listingAvailableGroupsUpdated.flatMap(group =>
								group.docs.map(doc => ({ ...doc.data(), id: doc.id } as any))
							),
							"listingResourceID"
						)

						takerCb &&
							takerCb(
								docs.map(doc => ({
									...doc.data(),
									id: doc.id,
									nftInfo:
										associatedListingAvailables[
											doc.data().data.listingResourceID
										]?.detail,
								}))
							)
					})
			  })
			: () => {}

	return () => {
		unsubscribeFromBorrowerEvents()
		unsubscribeFromLenderEvents()
	}
}

/**
 * The sequence for this subscriber is listen to `listingAvailable` which represents listings that I created,
 *  but then matching to the `fundingAvailable` which represents the actual loands
 */
export const subscribeToMyLoans = ({
	isRentalView = false,
	limit = 25,
	accountId,
	cb,
	filterBy,
}: SubscribeProps) => {
	const fundedCollection = isRentalView ? "listingRented" : "fundingAvailable"
	const lenderOrRenter = isRentalView ? "renter" : "lender"

	let lenderQuery = firestore
		.collection(fundedCollection)
		.where(lenderOrRenter, "in", [accountId])

	if (filterBy.path) {
		if (
			filterBy.path ===
			"listingAvailable.derivations.calculatedValues.periodicInterest"
		) {
			if (filterBy.min) {
				lenderQuery = lenderQuery.where(
					filterBy.path,
					">=",
					Number(filterBy.min) / 100
				)
			}
			if (filterBy.max) {
				lenderQuery = lenderQuery.where(
					filterBy.path,
					"<=",
					Number(filterBy.max) / 100
				)
			}
			lenderQuery = lenderQuery.orderBy(filterBy.path, "desc")
		} else if (
			filterBy.path === "listingAvailable.blockTimestamp" ||
			filterBy.path === "listingAvailable.settleDeadline"
		) {
			if (filterBy.min) {
				lenderQuery = lenderQuery.where(filterBy.path, ">=", filterBy.min)
			}
			if (filterBy.max) {
				lenderQuery = lenderQuery.where(filterBy.path, "<=", filterBy.max)
			}
			lenderQuery = lenderQuery.orderBy(filterBy.path, "desc")
		} else {
			if (filterBy.path !== "state") {
				if (filterBy.min) {
					lenderQuery = lenderQuery.where(
						filterBy.path,
						">=",
						Number(filterBy.min)
					)
				}
				if (filterBy.max) {
					lenderQuery = lenderQuery.where(
						filterBy.path,
						"<=",
						Number(filterBy.max)
					)
				}
				lenderQuery = lenderQuery.orderBy(filterBy.path, "desc")
			}
		}
	} else {
		lenderQuery = lenderQuery.orderBy("listingAvailable.blockTimestamp", "desc")
	}

	const unsubscribeFromListingAvailable = lenderQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const fundingAvailableGroups = chunk(docs, 10)
			await Promise.all(
				fundingAvailableGroups.map(fundingAvailableGroup => {
					if (filterBy.path === "state") {
						return firestore
							.collection("listingAvailable")
							.where("state", "==", filterBy.min)
							.where(
								"listingResourceID",
								"in",
								fundingAvailableGroup.map(doc => doc.data().listingResourceID)
							)
							.get()
					} else {
						return firestore
							.collection("listingAvailable")
							.where(
								"listingResourceID",
								"in",
								fundingAvailableGroup.map(doc => doc.data().listingResourceID)
							)
							.get()
					}
				})
			).then(listingGroups => {
				const associatedFundingsByResourceID = keyBy(
					docs.map(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)
				cb(
					listingGroups
						.flatMap(group =>
							group?.docs.map(doc => ({ ...doc.data(), id: doc.id }))
						)
						.map((data: any) => ({
							...data,
							fundingAvailable:
								associatedFundingsByResourceID[data.listingResourceID],
						}))
				)
			})
		})

	return unsubscribeFromListingAvailable
}

/**
 * The sequence for this subscriber is listen to `fundingAvailable` which represents borrows,
 *  but then matching to the `listingAvailable` which represents the initial listing and has some
 *   additional data. This is the opposite of subscribe to my loans
 */
export const subscribeToMyBorrows = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	const accounts = accountIDs || [accountId]

	let borrowerQuery = firestore
		.collection("listingAvailable")
		.where("flowtyStorefrontAddress", "in", accounts)

	const defaultStateFilters: string[] = []

	if (filterBy.path !== "state" && defaultStateFilters.length > 0) {
		borrowerQuery = borrowerQuery.where("state", "in", defaultStateFilters)
	}

	if (filterBy.path) {
		if (filterBy.path === "state") {
			borrowerQuery = borrowerQuery.where(filterBy.path, "==", filterBy.min)
		} else if (filterBy.path === "interestRate") {
			if (filterBy.min) {
				borrowerQuery = borrowerQuery.where(
					filterBy.path,
					">=",
					Number(filterBy.min) / 100
				)
			}
			if (filterBy.max) {
				borrowerQuery = borrowerQuery.where(
					filterBy.path,
					"<=",
					Number(filterBy.max) / 100
				)
			}
			borrowerQuery = borrowerQuery.orderBy(filterBy.path, "desc")
		} else if (
			filterBy.path === "blockTimestamp" ||
			filterBy.path === "settleDeadline"
		) {
			if (filterBy.min) {
				borrowerQuery = borrowerQuery.where(filterBy.path, ">=", filterBy.min)
			}
			if (filterBy.max) {
				borrowerQuery = borrowerQuery.where(filterBy.path, "<=", filterBy.max)
			}
			borrowerQuery = borrowerQuery.orderBy(filterBy.path, "desc")
		} else {
			if (filterBy.min) {
				borrowerQuery = borrowerQuery.where(
					filterBy.path,
					">=",
					Number(filterBy.min)
				)
			}
			if (filterBy.max) {
				borrowerQuery = borrowerQuery.where(
					filterBy.path,
					"<=",
					Number(filterBy.max)
				)
			}
			borrowerQuery = borrowerQuery.orderBy(filterBy.path, "desc")
		}
	} else {
		borrowerQuery = borrowerQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromFundingAvailable = borrowerQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			// Need to fetch the appropriate funding availables
			const listingAvailableGroups = chunk(docs, 10) // max batch size;
			await Promise.all(
				listingAvailableGroups.map(listingAvailableGroup =>
					firestore
						.collection("fundingAvailable")
						.where(
							"listingResourceID",
							"in",
							listingAvailableGroup.map(doc => doc.data().listingResourceID)
						)
						.get()
				)
			).then(fundingGroups => {
				const associatedListingsByResourceID = keyBy(
					docs.map(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)
				cb(
					fundingGroups
						.flatMap(group =>
							group.docs.map(doc => ({ ...doc.data(), id: doc.id }))
						)
						.map((data: any) => ({
							...data,
							listingAvailable:
								associatedListingsByResourceID[data.listingResourceID],
						}))
				)
			})
		})

	// Now as a result need to fetch the listing availables associated with these funding availables

	return () => {
		unsubscribeFromFundingAvailable()
	}
}

export const subscribeToMyRentals = ({
	asOwner = false,
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	const accounts = accountIDs || [accountId]

	const role = asOwner ? "flowtyStorefrontAddress" : "renter"
	Log({ filterBy })
	let rentQuery = firestore
		.collection("rentalAvailable")
		.where(role, "in", accounts)

	if (filterBy?.path) {
		if (filterBy?.path === "state") {
			rentQuery = rentQuery.where(filterBy.path, "==", filterBy.min)
			rentQuery = rentQuery.orderBy("blockTimestamp", "desc")
		} else {
			rentQuery = rentQuery.orderBy(filterBy.path, "desc")
			if (filterBy.path === "term") {
				if (filterBy.min) {
					rentQuery = rentQuery.where(
						filterBy.path,
						">=",
						Number(filterBy.min) * 60 * 60 * 24
					)
				}
				if (filterBy.max !== "") {
					rentQuery = rentQuery.where(
						filterBy.path,
						"<=",
						Number(filterBy.max) * 60 * 60 * 24
					)
				}
			} else if (filterBy.path === "valuations.aggregate.depositToValueRatio") {
				if (filterBy.min) {
					rentQuery = rentQuery.where(filterBy.path, ">=", Number(filterBy.min))
				}
				if (filterBy.max !== "") {
					rentQuery = rentQuery.where(filterBy.path, "<=", Number(filterBy.max))
				}
			} else {
				if (filterBy.min) {
					rentQuery = rentQuery.where(filterBy.path, ">=", Number(filterBy.min))
				}
				if (filterBy.max !== "") {
					rentQuery = rentQuery.where(filterBy.path, "<=", Number(filterBy.max))
				}
			}
		}
	} else {
		rentQuery = rentQuery.orderBy("blockTimestamp", "desc")
	}
	const unsubscribeFromListingRented = rentQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const listingRentedGroups = chunk(docs, 10)
			await Promise.all(
				listingRentedGroups.map(listingRentedGroup =>
					firestore
						.collection("rentalAvailable")
						.where(
							"listingResourceID",
							"in",
							listingRentedGroup.map(doc => doc.data().listingResourceID)
						)
						.get()
				)
			).then(listingGroups => {
				const associatedFundingsByResourceID = keyBy(
					docs.map(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)
				cb(
					listingGroups
						.flatMap(group =>
							group.docs.map(doc => ({ ...doc.data(), id: doc.id }))
						)
						.map((data: any) => ({
							...data,
							fundingAvailable:
								associatedFundingsByResourceID[data.listingResourceID],
						}))
				)
			})
		})

	return unsubscribeFromListingRented
}

export const subscribeToMyStorefront = ({
	asBuyer = false,
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	const accounts = accountIDs || [accountId]

	const role = "accountAddress"
	let storefrontQuery = firestore
		.collection("storefrontEvents")
		.where(role, "in", accounts)

	if (filterBy?.path) {
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where(filterBy.path, "==", filterBy.min)
			storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery.orderBy(filterBy.path, "desc")
			if (filterBy.path === "salePrice") {
				if (filterBy.min) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						">=",
						Number(filterBy.min)
					)
				}
				if (filterBy.max) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						"<=",
						Number(filterBy.max)
					)
				}
			}
		}
	} else {
		storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}

export const subscribeToMyStorefrontSellerPurchase = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	let storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("data.buyer", "!=", null)

	if (filterBy?.path) {
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where("type", "==", filterBy.min)
			storefrontQuery = storefrontQuery
				.orderBy("data.buyer", "desc")
				.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery
				.orderBy("data.buyer", "desc")
				.orderBy(filterBy.path, "desc")
		}
	} else {
		storefrontQuery = storefrontQuery
			.orderBy("data.buyer", "desc")
			.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}
export const subscribeToMyStorefrontSellerPurchaseSalePrice = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	const storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("accountAddress", "in", accountIDs)
		.where(
			"data.salePrice",
			filterBy.min ? ">=" : "<=",
			Number(!!filterBy.min ? filterBy.min : filterBy?.max)
		)

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}

export const subscribeToMyStorefrontBuyer = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	let storefrontQuery: any = firestore.collection("storefrontEvents")

	storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("data.buyer", "==", accountId)

	if (filterBy?.path) {
		if (filterBy?.min === "STOREFRONT_PURCHASED") {
			storefrontQuery = storefrontQuery.where("data.buyer", "==", accountId)
		}
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where("type", "==", filterBy.min)
			storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery.orderBy(filterBy.path, "desc")
		}
	} else {
		storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}
export const subscribeToMyStorefrontBuyerOffers = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	let storefrontQuery: any = firestore.collection("storefrontEvents")

	storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("accountAddress", "==", accountId)

	if (filterBy?.path) {
		if (filterBy?.min === "STOREFRONT_PURCHASED") {
			storefrontQuery = storefrontQuery.where("data.buyer", "==", accountId)
		}
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where("type", "==", filterBy.min)
			storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery.orderBy(filterBy.path, "desc")
		}
	} else {
		storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}
export const subscribeToMyStorefrontBuyerPurchases = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	let storefrontQuery: any = firestore.collection("storefrontEvents")

	storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("data.buyer", "!=", accountId)

	if (filterBy?.path) {
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where("type", "==", filterBy.min)
			storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery.orderBy(filterBy.path, "desc")

			if (filterBy.path === "salePrice") {
				if (filterBy.min) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						">=",
						Number(filterBy.min)
					)
				}
				if (filterBy.max) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						"<=",
						Number(filterBy.max)
					)
				}
			}
		}
	} else {
		storefrontQuery = storefrontQuery
			.orderBy("data.buyer", "desc")
			.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}

export const subscribeToMyStorefrontBuyerPurchaseSalePrice = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	const storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("accountAddress", "in", accountIDs)
		.where(
			"data.salePrice",
			filterBy.min ? ">=" : "<=",
			Number(!!filterBy.min ? filterBy.min : filterBy?.max)
		)

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}

export const subscribeToMyStorefrontSellerActivity = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
}: SubscribeProps) => {
	let storefrontQuery = firestore
		.collection("storefrontEvents")
		.where("accountAddress", "in", accountIDs)

	if (filterBy?.path) {
		if (filterBy?.path === "state") {
			storefrontQuery = storefrontQuery.where("type", "==", filterBy.min)
			storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
		} else {
			storefrontQuery = storefrontQuery.orderBy(filterBy.path, "desc")

			if (filterBy.path === "salePrice") {
				if (filterBy.min) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						">=",
						Number(filterBy.min)
					)
				}
				if (filterBy.max) {
					storefrontQuery = storefrontQuery.where(
						filterBy.path,
						"<=",
						Number(filterBy.max)
					)
				}
			}
		}
	} else {
		storefrontQuery = storefrontQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListingStorefront = storefrontQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingStorefrontGroups = chunk(docs, 10)
			await Promise.all(
				listingStorefrontGroups.flatMap(listingStorefrontGroup => {
					const listingAvailables = listingStorefrontGroup.map(doc => {
						return firestore
							.collection("storefrontListingAvailable")
							.doc(`${doc.data().listingResourceID}`)
							.get()
					})
					return listingAvailables
				})
			).then(listingAvailableDocGroups => {
				const associatedListingsByResourceID = keyBy(
					listingAvailableDocGroups.flatMap(doc => ({
						...doc.data(),
						id: doc.id,
					})),
					"listingResourceID"
				)

				const combinedAvailableAndCompleted = docs.map(listingCompletedDoc => {
					return {
						...associatedListingsByResourceID[
							listingCompletedDoc.data().listingResourceID
						],
						completed: listingCompletedDoc.data(),
					}
				})

				cb(combinedAvailableAndCompleted)
			})
		})

	return unsubscribeFromListingStorefront
}

export const subscribeToMyDelistLoansActivity = ({
	limit = 25,
	accountId,
	makerCb,
	accountIDs,
	filterActivityBy,
	listingCollection = "listingAvailable",
}: ActivitySubscribeProps) => {
	const accounts = accountIDs ? accountIDs : [accountId]

	let delistQuery: any = firestore.collection("p2pEvents")

	delistQuery = firestore
		.collection("p2pEvents")
		.where("type", "==", filterActivityBy?.min || "DELISTED")
		.where("data.flowtyStorefrontAddress", "in", accounts)
		.orderBy("blockTimestamp", "desc")

	const unsubscribeFromMyDelistEvents = delistQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingAvailableGroups = chunk(docs, 10)

			await Promise.all(
				listingAvailableGroups.map(listingAvailableGroup =>
					firestore
						.collection(listingCollection)
						.where(
							"listingResourceID",
							"in",
							listingAvailableGroup
								.map(doc => doc.data().data?.listingResourceID)
								.filter(i => !!i) // prevent null NFTs query
						)
						.get()
				)
			).then(updatedListingAvailableGroups => {
				const associatedListingAvailables = keyBy(
					updatedListingAvailableGroups.flatMap(group =>
						group.docs.map(
							doc => ({ ...doc.data(), id: doc.id } as ListingAvailableData)
						)
					),
					"listingResourceID"
				)

				makerCb(
					docs.map(doc => ({
						...doc.data(),
						nftInfo:
							associatedListingAvailables[doc.data().data.listingResourceID]
								?.detail,
					}))
				)
			})
		})
	return () => {
		unsubscribeFromMyDelistEvents()
	}
}

export const subscribeToMyListedOrDelistBorrow = ({
	limit = 25,
	accountId,
	cb,
	filterBy,
	accountIDs,
	stateType,
}: SubscribeProps & { stateType: "LISTED" | "DELISTED" }) => {
	const accounts = accountIDs ? accountIDs : [accountId]

	let delistQuery: any = firestore.collection("listingAvailable")

	delistQuery = firestore
		.collection("listingAvailable")
		.where("state", "==", stateType)
		.where("flowtyStorefrontAddress", "in", accounts)

	const defaultStateFilters: string[] = []

	if (filterBy.path !== "state" && defaultStateFilters.length > 0) {
		delistQuery = delistQuery.where("state", "in", defaultStateFilters)
	}

	if (filterBy.path) {
		if (filterBy.path === "state") {
			delistQuery = delistQuery.where(filterBy.path, "==", filterBy.min)
		} else if (filterBy.path === "interestRate") {
			if (filterBy.min) {
				delistQuery = delistQuery.where(
					filterBy.path,
					">=",
					Number(filterBy.min) / 100
				)
			}
			if (filterBy.max) {
				delistQuery = delistQuery.where(
					filterBy.path,
					"<=",
					Number(filterBy.max) / 100
				)
			}
			delistQuery = delistQuery.orderBy(filterBy.path, "desc")
		} else if (
			filterBy.path === "blockTimestamp" ||
			filterBy.path === "settleDeadline"
		) {
			if (filterBy.min) {
				delistQuery = delistQuery.where(filterBy.path, ">=", filterBy.min)
			}
			if (filterBy.max) {
				delistQuery = delistQuery.where(filterBy.path, "<=", filterBy.max)
			}
			delistQuery = delistQuery.orderBy(filterBy.path, "desc")
		} else {
			if (filterBy.min) {
				delistQuery = delistQuery.where(
					filterBy.path,
					">=",
					Number(filterBy.min)
				)
			}
			if (filterBy.max) {
				delistQuery = delistQuery.where(
					filterBy.path,
					"<=",
					Number(filterBy.max)
				)
			}
			delistQuery = delistQuery.orderBy(filterBy.path, "desc")
		}
	} else {
		delistQuery = delistQuery.orderBy("blockTimestamp", "desc")
	}

	const unsubscribeFromListOrDelistBorrow = delistQuery
		.limit(limit)
		.onSnapshot(async ({ docs }: { docs: any[] }) => {
			const listingAvailableGroups = chunk(docs, 10)

			await Promise.all(
				listingAvailableGroups.map(listingAvailableGroup =>
					firestore
						.collection("listingAvailable")
						.where(
							"listingResourceID",
							"in",
							listingAvailableGroup.map(doc => doc.data().listingResourceID)
						)
						.get()
				)
			).then(updatedListingAvailableGroups => {
				const associatedListingAvailables = keyBy(
					updatedListingAvailableGroups.flatMap(group =>
						group.docs.map(
							doc => ({ ...doc.data(), id: doc.id } as ListingAvailableData)
						)
					),
					"listingResourceID"
				)

				cb(
					docs.map(doc => ({
						...doc.data(),
						listingAvailable:
							associatedListingAvailables[doc.data().listingResourceID],
						nftInfo:
							associatedListingAvailables[doc.data().listingResourceID]?.detail,
					}))
				)
			})
		})

	return () => {
		unsubscribeFromListOrDelistBorrow()
	}
}
