import { useContext } from "react"
import { FlowtyButton } from "../../../FlowtyButton"
import { FlowtyDropModalContext } from "../../contexts/FlowtyDropModalContext"
import { InsufficientFundsWarning } from "../../../FlowtyModal/components/common/InsufficientFundsWarning"

export const DropModalViewNav: React.FunctionComponent = () => {
	const { modalNavbarProps, showNoFundsMessage } = useContext(
		FlowtyDropModalContext
	)

	if (!modalNavbarProps) return null
	if (Array.isArray(modalNavbarProps)) {
		return (
			<div className='w-full flex items-center gap-[24px]'>
				{modalNavbarProps.map((btn, index) => (
					<FlowtyButton
						key={index}
						text={btn.text}
						onClick={btn.onClick}
						variant='secondary'
						btnSize='large'
						disabled={btn.disabled}
						bgColor={btn.bgColor}
					/>
				))}
			</div>
		)
	}

	return (
		<div className='w-full flex flex-col gap-1'>
			<FlowtyButton
				text={modalNavbarProps.text}
				onClick={modalNavbarProps.onClick}
				variant='secondary'
				btnSize='large'
				disabled={modalNavbarProps.disabled}
			/>
			{showNoFundsMessage && <InsufficientFundsWarning />}
		</div>
	)
}
