import { ReactComponent as LockClosedIcon } from "../../../../assets/lock-closed.svg"

export const LockedDisplayView: React.FunctionComponent = () => (
	<div className='flex flex-col gap-3 w-full md:w-[400px] justify-between'>
		<div className='p-4 bg-[#FFFFFF] bg-opacity-10 w-full h-full rounded-md flex'>
			<div className='h-full pl-2'>
				<LockClosedIcon className='h-6 w-6' />
			</div>
			<div className='w-full px-6'>
				<div className='leading-4'>
					<span className='text-white text-xs font-normal'>
						Icon indicates that an NFT is{" "}
					</span>
					<span className='text-white text-xs font-bold'>Locked</span>
					<span className='text-white text-xs font-normal'>
						, meaning that a user cannot perform any Flowty actions on the NFT.
						This is usually an action taken by users on the NBA Top Shot
						platform
						<br />
					</span>
				</div>
			</div>
		</div>
	</div>
)
