import React from "react"
import { PageHeader } from "../components/PageHeader"
import { UserRenterProfileData } from "../components/UserProfile/UserRenterProfileData"
import Layout from "../layouts/Layout"

const metaTitle = `Flowty: Your NFT Rentals Activity`
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

export const UserRentalScreen: React.FC = () => (
	<Layout>
		<PageHeader
			metaTitle={metaTitle}
			metaDescription={metaDescription}
			metaImage={metaImage}
		/>
		<div className='container'>
			<UserRenterProfileData />
		</div>
	</Layout>
)
