import React from "react"
import { Tooltip } from "react-tooltip"
import { ReactComponent as TooltipIcon } from "../../../assets/exclamation-circle.svg"

interface CreatorHubTooltipProps {
	toolTipCustomId?: string
	clickable?: boolean
	renderContent?: JSX.Element
}
export const CreatorHubTooltipIcon: React.FC<CreatorHubTooltipProps> = ({
	toolTipCustomId,
	clickable = false,
	renderContent,
}) => {
	return (
		<div className='flex items-center'>
			<Tooltip
				id={`${toolTipCustomId}-tooltip`}
				style={{
					backgroundColor: "#F8F9FA",
					color: "#031021",
					fontSize: "12px",
					lineHeight: "1rem",
					padding: "10px",
					pointerEvents: "auto",
					textAlign: "center",
					width: "248px",
					zIndex: 40,
				}}
				opacity={100}
				clickable={clickable}
				render={() => <>{renderContent}</>}
			/>
			<div
				data-tooltip-id={`${toolTipCustomId}-tooltip`}
				data-tooltip-place='top'
			>
				<TooltipIcon className='h-[13.333px] w-[13.333px] 3xl:h-5 3xl-w:5' />
			</div>
		</div>
	)
}
