import { ListingStatus } from "../firestore"
import { SupportedTokens } from "../tokens"

export enum NotificationType {
	Transaction = "TRANSACTION", // Currently covers all user initiated transactions
	StorefrontPurchased = "STOREFRONT_PURCHASED",
	OfferReceived = "OFFER_RECEIVED",
	OfferAccepted = "OFFER_ACCEPTED",
	RentalFunded = "RENTAL_FUNDED",
	RentalReturned = "RENTAL_RETURNED",
	RentalSettled = "RENTAL_SETTLED",
	PrivateRental = "PRIVATE_RENTAL",
	LoanFunded = "LOAN_FUNDED",
	LoanRepaid = "LOAN_REPAID",
	LoanSettled = "LOAN_SETTLED",
	LoanExpirationWarning = "LOAN_EXPIRATION",
	RentalExpirationWarning = "RENTAL_EXPIRATION",
	ListingInvalid = "LISTING_INVALID",
	OfferExpired = "OFFER_EXPIRED",
}

type NotificationMapping = {
	[NotificationType.StorefrontPurchased]: StorefrontPurchasedNotification
	[NotificationType.OfferReceived]: OfferReceivedNotification
	[NotificationType.OfferAccepted]: OfferAcceptedNotification
	[NotificationType.RentalFunded]: RentalFundedNotification
	[NotificationType.RentalReturned]: RentalReturnedNotification
	[NotificationType.RentalSettled]: RentalSettledNotification
	[NotificationType.PrivateRental]: PrivateRentalListedNotification
	[NotificationType.LoanFunded]: LoanFundedNotification
	[NotificationType.LoanRepaid]: LoanRepaidNotification
	[NotificationType.LoanSettled]: LoanSettledNotification
	[NotificationType.LoanExpirationWarning]: LoanExpirationWarningNotification
	[NotificationType.RentalExpirationWarning]: RentalExpirationWarningNotification
	[NotificationType.ListingInvalid]: ListingInvalidNotification
	[NotificationType.OfferExpired]: OfferExpiredNotification
	[NotificationType.Transaction]: InitiatedTransactionNotification
}

export type NotificationData = NotificationMapping[keyof NotificationMapping]

export interface BaseNotification {
	id?: string
	timestamp: number
	type: NotificationType
	status?: "PROCESSING" | "SUCCESSFUL" | "FAILED"
}
export interface ListingNotification extends BaseNotification {
	title: string
	imageUrl: string
	listingType: string
	listingStatus?: ListingStatus
	token: SupportedTokens
	nftId: string
	nftType: string
	flowtyStorefrontAddress?: string
	listingResourceID: string
}

export type ListingType =
	| "storefront"
	| "rental"
	| "loan"
	| "transfer"
	| "acceptOffer"
	| "purchase"
	| "rent"
	| "fundRental"
	| "lend"
	| "makeOffer"
	| "fundLoan"
	| "loanBorrower"
	| "rentalBorrower"
	| "cancelOffer"
	| "bulkListing"
	| "bulkDelisting"
	| "delistSale"
	| "delistLoan"
	| "delistRental"

// This type covers all user initiated transactions
export interface InitiatedTransactionNotification extends BaseNotification {
	listingType: ListingType
	amount?: number
	title: string
	token?: string
	transferAddress?: string
	imageUrl?: string
	transactionID: string
	nftId?: string
	collectionAddress?: string
	collectionName?: string
	nftType?: string
	type: NotificationType.Transaction
}

export interface TransactionNotification extends ListingNotification {
	transactionID: string
}

// STOREFRONT PURCHASED NOTIFICATION - Allows user to navigate to asset detail page and flowscan
export interface StorefrontPurchasedNotification
	extends TransactionNotification {
	type: NotificationType.StorefrontPurchased
	amount: number
	buyerAddress: string
}

// OFFER NOTIFICATIONS - Allows user to navigate to asset detail page and flowscan / modal to accept offer
export type OfferNotificationContent =
	| OfferAcceptedNotification
	| OfferExpiredNotification
	| OfferReceivedNotification
export interface OffersNotification extends BaseNotification {
	title: string
	imageUrl: string
	token: SupportedTokens
	amount: number
	offerResourceID: string
	flowtyStorefrontAddress: string
	payerAddress: string
	nftId: string
	nftType: string
	transactionID: string
	listingType: string
}
export interface OfferReceivedNotification extends OffersNotification {
	type: NotificationType.OfferReceived
	expiry: number
}

export interface OfferAcceptedNotification extends OffersNotification {
	type: NotificationType.OfferAccepted
}

export interface OfferExpiredNotification extends OffersNotification {
	type: NotificationType.OfferExpired
}

export type RentalNotificationContent =
	| RentalFundedNotification
	| RentalReturnedNotification
	| RentalSettledNotification
	| PrivateRentalListedNotification

// RENTAL NOTIFICATIONS - Allows user to navigate to asset detail page and flowscan
export interface RentalNotification extends TransactionNotification {
	rentalFee: number
	renterAddress?: string
}

export interface RentalFundedNotification extends RentalNotification {
	type: NotificationType.RentalFunded
}

export interface RentalReturnedNotification extends RentalNotification {
	type: NotificationType.RentalReturned
}

export interface RentalSettledNotification extends RentalNotification {
	type: NotificationType.RentalSettled
}

export interface PrivateRentalListedNotification extends RentalNotification {
	type: NotificationType.PrivateRental
}

export type LoanNotificationContent =
	| LoanFundedNotification
	| LoanRepaidNotification
	| LoanSettledNotification
	| ListingInvalidNotification

// LOAN NOTIFICATIONS - Allows user to navigate to asset detail page and flowscan
export interface LoanNotification extends TransactionNotification {
	amount: number
	marketplaceAmount: number
	lenderAddress?: string
}
export interface LoanFundedNotification extends LoanNotification {
	type: NotificationType.LoanFunded
}
export interface LoanRepaidNotification extends LoanNotification {
	fundingResourceID: string
	type: NotificationType.LoanRepaid
}
export interface LoanSettledNotification extends LoanNotification {
	fundingResourceID: string
	type: NotificationType.LoanSettled
}

export interface ListingInvalidNotification extends LoanNotification {
	type: NotificationType.ListingInvalid
}

// Maker/Taker Expiration Notification
// allows taker to navigate to return/repay nft

export interface LoanExpirationWarningNotification extends LoanNotification {
	type: NotificationType.LoanExpirationWarning
	fundingResourceID: string
	expiresOn: Date
}
export interface RentalExpirationWarningNotification
	extends RentalNotification {
	type: NotificationType.RentalExpirationWarning
	timeRemaining: string
	rentalResourceID: number
}
