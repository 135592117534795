export enum SortDirection {
	Ascending = "asc",
	Descending = "desc",
}

export interface SortConfig {
	sortTypeIndex: number
	sortDirection: SortDirection
}

export const DEFAULT_SORT_CONFIG: SortConfig = {
	sortDirection: SortDirection.Ascending,
	sortTypeIndex: 0,
}

const SORT_MAPPING: Record<string, number> = {
	amount: 3,
	blockTimestamp: 0,
	"derivations.apr": 2,
	interestRate: 1,
	term: 4,
	"valuations.aggregate.loanToValueRatio": 5,
}

// TODO fix tech debt (coupling of surface to a UI component)
export const getFirestorePropBySortIndex = (sortIndex: number) =>
	Object.entries(SORT_MAPPING).find(([, value]) => value === sortIndex)?.[0] ??
	null

export const getSortIndexByFirestoreProp = (firestoreProp: string) =>
	SORT_MAPPING[firestoreProp] ?? null
