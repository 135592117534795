import { db } from "../../firebase"
import {
	FlowNFT,
	FlowNFTContract,
	getNFTIdentifier,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import { actions as Mixpanel } from "../../util/Mixpanel"

export const fetchSpecificFlowNFT = async ({
	nftType,
	nftId,
}: {
	nftType: string
	nftId: string
}): Promise<FlowNFT | null> => {
	const ld = nftTypeAndIdToLocationData(nftType, nftId)
	const identifier = getNFTIdentifier(ld)

	try {
		const doc = await db.collection("flowNFT").doc(identifier).get()
		return doc.data() as FlowNFT
	} catch (e) {
		Mixpanel.track("ERROR_FIREBASE_FETCH_NFT", {
			e,
		})
		return null
	}
}

export const fetchSpecificFlowNFTContract = async ({
	collectionAddress,
	collectionName,
}: {
	collectionAddress: string
	collectionName: string
}): Promise<FlowNFTContract> => {
	const doc = await db
		.collection("flowNFTContract")
		.doc(`${collectionAddress}.${collectionName}`)
		.get()
	return doc.data() as FlowNFTContract
}
