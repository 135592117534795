import { formatAddress } from "./string"

export type SupportedTokenContractNames =
	| "USDCFlow"
	| "FlowToken"
	| "DapperUtilityCoin"

export enum SupportedTokens {
	FLOW = "FLOW",
	USDC = "USDC",
	DUC = "DUC",
}

export const SUPPORTED_NON_DAPPER_TOKENS: SupportedTokens[] = [
	SupportedTokens.FLOW,
	SupportedTokens.USDC,
]
export const SUPPORTED_DAPPER_TOKENS: SupportedTokens[] = [SupportedTokens.DUC]

export const SUPPORTED_TOKEN_LIST: SupportedTokens[] = [
	...SUPPORTED_NON_DAPPER_TOKENS,
	...SUPPORTED_DAPPER_TOKENS,
]

export interface TokenMetadata {
	symbol: SupportedTokens
	contractName: SupportedTokenContractNames
	contractAddress: string
	receiverPath: string
	storagePath: string
	balancePath: string
}

export const TOKEN_TYPE_TO_TOKEN: Record<
	SupportedTokenContractNames,
	SupportedTokens
> = {
	DapperUtilityCoin: SupportedTokens.DUC,
	FlowToken: SupportedTokens.FLOW,
	USDCFlow: SupportedTokens.USDC,
}

export const TOKEN_TO_READABLE_TOKEN: Record<SupportedTokens, string> = {
	DUC: "Dapper Balance",
	FLOW: "FLOW",
	USDC: "USDC",
}

export const getContractNameFromType = (typeIdentifier: string): string => {
	const tokenSegments = typeIdentifier.split(".")
	if (tokenSegments.length < 3) {
		throw Error("invalid contract type identifier")
	}

	return tokenSegments[2]
}

export const getContractAddressFromType = (typeIdentifier: string): string => {
	const tokenSegments = typeIdentifier.split(".")
	if (tokenSegments.length < 3) {
		throw Error("invalid contract type identifier")
	}

	return formatAddress(tokenSegments[1])
}

export const getTokenFromType = (tokenType: string): SupportedTokens => {
	const uncheckedTokenContractName = getContractNameFromType(tokenType)

	if (uncheckedTokenContractName in TOKEN_TYPE_TO_TOKEN) {
		const tokenContractName =
			uncheckedTokenContractName as SupportedTokenContractNames
		return TOKEN_TYPE_TO_TOKEN[tokenContractName]
	}

	return SupportedTokens.FLOW
}
