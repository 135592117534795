import { SupportedTokens } from "flowty-common"
import {
	AccountSummaries,
	AccountSummary,
	LoanRentalFilteredData,
} from "flowty-sdk"
import {
	FlowtyWalletSelector,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { useFlowtyPurchaseModalContext } from "../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { NftPrice } from "../../common/NftPrice"
import { getCountdownValues } from "../../../../FlowtyDropPage/utils/getCountdownValues"
import { useEffect, useMemo, useState } from "react"
import CountdownTimer from "../../../../FlowtyDropPage/components/common/CountdownTimer/CountdownTimer"

interface LoanRentalActionsSectionProps {
	accountSummaries: AccountSummaries
	loanRentalActionsData: LoanRentalFilteredData
}

export const LoanRentalActionsSection: React.FunctionComponent<
	LoanRentalActionsSectionProps
> = ({ accountSummaries, loanRentalActionsData }) => {
	const {
		payWithSelectedAccount,
		updateSelectedAccount,
		hasChildAccounts,
		hasBalance,
		tokenProviderFilter,
		isLoggedUser,
		spotPrice,
	} = useFlowtyPurchaseModalContext()

	const dueDateFormat = (dateValue: number, dateOnly?: boolean): string => {
		const date = new Date(dateValue * 1000)

		const formattedDate = new Intl.DateTimeFormat("en-US", {
			day: "numeric",
			month: "long",
			year: "numeric",
		}).format(date)
		const formattedTime = new Intl.DateTimeFormat("en-US", {
			hour: "2-digit",
			hour12: true,
			minute: "2-digit",
		}).format(date)

		return dateOnly ? formattedDate : `${formattedDate}, ${formattedTime}`
	}

	const countDownValue =
		(loanRentalActionsData.settleDeadline - new Date().getTime() / 1000) * 1000
	const [countDown, setCountDown] = useState(countDownValue)

	useEffect(() => {
		const interval = setInterval(() => {
			const newCountDownValue =
				(loanRentalActionsData.settleDeadline - new Date().getTime() / 1000) *
				1000
			setCountDown(newCountDownValue)
		}, 1000)

		return () => clearInterval(interval)
	}, [countDown])

	const countdownCurrentValue = useMemo(() => {
		return getCountdownValues(countDownValue)
	}, [countDownValue])

	const actionAmount =
		loanRentalActionsData.type === "loan"
			? loanRentalActionsData?.loanAmount
			: loanRentalActionsData.rentalFee
	const repaymentAmount =
		loanRentalActionsData.type === "loan"
			? loanRentalActionsData?.repaymentDue
			: loanRentalActionsData.rentalRefundableDeposit

	return (
		<div className='w-full h-full flex flex-col justify-between gap-5'>
			<div className='h-full flex flex-col gap-3'>
				{hasChildAccounts && loanRentalActionsData.type === "loan" && (
					<div className='flex flex-col gap-3'>
						<FlowtyWalletSelector
							selected={payWithSelectedAccount}
							setSelected={(account: AccountSummary) =>
								updateSelectedAccount(account, "payWith")
							}
							label='Pay With'
							summaries={accountSummaries}
							listingType={"storefront"}
							selectorFilter={tokenProviderFilter}
							disabledMessage={walletDisabledMessage.payWith}
						/>
					</div>
				)}
				<div className='w-full flex flex-col gap-3 justify-between mt-4'>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>
							{loanRentalActionsData.type === "loan"
								? "Loan Amount"
								: "Rental Fee"}
						</p>
						<NftPrice
							token={loanRentalActionsData.paymentTokenName as SupportedTokens}
							price={Number(actionAmount)}
							spotPriceValue={
								spotPrice ? spotPrice.value * Number(actionAmount) : undefined
							}
						/>
					</div>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>
							{loanRentalActionsData.type === "loan"
								? "Repayment Amount"
								: "Refundable Deposit"}
						</p>
						<NftPrice
							token={loanRentalActionsData.paymentTokenName as SupportedTokens}
							price={Number(repaymentAmount)}
							spotPriceValue={
								spotPrice
									? spotPrice.value * Number(repaymentAmount)
									: undefined
							}
						/>
					</div>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>
							{loanRentalActionsData.type === "loan"
								? "Loan Funded Date"
								: "Date of Rental"}
						</p>
						<span className='text-base text-white font-bold'>
							{dueDateFormat(
								+loanRentalActionsData.settleDeadline -
									loanRentalActionsData.term,
								true
							)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>
							{loanRentalActionsData.type === "loan"
								? "Repayment Due"
								: "Rental Due Back"}
						</p>
						<span className='text-base text-white font-bold'>
							{dueDateFormat(+loanRentalActionsData.settleDeadline)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>
							{loanRentalActionsData.type === "loan"
								? "Auto Repayment"
								: "Auto Return"}
						</p>
						<span className='text-base text-white font-bold'>
							{loanRentalActionsData.enabledAutoRepayment ? "On" : "Off"}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>Time Left</p>
						<CountdownTimer
							countdownCurrentValue={countdownCurrentValue}
							isCustomColor={!Boolean(countdownCurrentValue.days)}
						/>
					</div>
				</div>
			</div>
			<div className='flex flex-col gap-3'>
				{!hasBalance && isLoggedUser && (
					<p className='w-full text-[#FF6E25] text-sm'>
						Insufficient funds to complete the transaction!
					</p>
				)}
			</div>
		</div>
	)
}
