import { PropsWithChildren } from "react"

import { ReactComponent as FLOWIcon } from "../../src/assets/tokens/flow.svg"
import { ReactComponent as FUSDIcon } from "../../src/assets/tokens/fusd.svg"
import { ReactComponent as SloppyIcon } from "../../src/assets/tokens/sloppy.svg"
import { ReactComponent as USDCIcon } from "../../src/assets/tokens/usdc.svg"
import { ReactComponent as USDTIcon } from "../../src/assets/tokens/usdt.svg"
import { cn } from "../utils/cn"

type FlowtyTokenIconProps = PropsWithChildren<{
	token: string
	isLarge?: boolean
}>

export const FlowtyTokenIcon: React.FC<FlowtyTokenIconProps> = ({
	token = "fusd",
	isLarge,
}) => {
	let icon
	switch (token.toLowerCase()) {
		case "fusd":
			icon = (
				<FUSDIcon
					className={cn(
						"flex-shrink-0 w-[0.875rem] h-[0.875rem]",
						isLarge && "w-[24px] h-[24px] "
					)}
				/>
			)
			break
		case "flow":
			icon = (
				<FLOWIcon
					className={cn(
						"flex-shrink-0 w-[0.875rem] h-[0.875rem]",
						isLarge && "w-[24px] h-[24px] "
					)}
				/>
			)
			break
		case "usdc":
			icon = (
				<USDCIcon
					className={cn(
						"flex-shrink-0 w-[0.875rem] h-[0.875rem]",
						isLarge && "w-[24px] h-[24px] "
					)}
				/>
			)
			break
		case "duc":
			icon = (
				<div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='14'
						height='15'
						viewBox='0 0 14 15'
						fill='none'
					>
						<rect y='0.5' width='14' height='14' rx='7' fill='#762FBE' />
						<path
							d='M7.1945 5.42609C6.33539 5.42609 5.63894 5.89039 5.63894 6.46313C5.63894 7.03587 6.33539 7.50016 7.1945 7.50016C8.05361 7.50016 8.75007 7.96446 8.75007 8.5372C8.75007 9.10994 8.05362 9.57424 7.19451 9.57424M7.1945 5.42609C7.77027 5.42609 8.27299 5.63464 8.54195 5.94461M7.1945 5.42609V4.90757M7.1945 5.42609L7.19451 9.57424M7.19451 9.57424L7.1945 10.0928M7.19451 9.57424C6.61874 9.57424 6.11602 9.36569 5.84706 9.05572M11.8612 7.50016C11.8612 10.0775 9.77183 12.1668 7.1945 12.1668C4.61717 12.1668 2.52783 10.0775 2.52783 7.50016C2.52783 4.92283 4.61717 2.8335 7.1945 2.8335C9.77183 2.8335 11.8612 4.92283 11.8612 7.50016Z'
							stroke='white'
							strokeLinecap='round'
						/>
					</svg>
				</div>
			)
			break
		case "sloppy":
		case "sloppystakes":
			icon = (
				<SloppyIcon
					className={cn(
						"flex-shrink-0 w-[0.875rem] h-[0.875rem]",
						isLarge && "w-[24px] h-[24px] "
					)}
				/>
			)
			break
		case "usdt":
			icon = (
				<USDTIcon
					className={cn(
						"flex-shrink-0 w-[0.875rem] h-[0.875rem]",
						isLarge && "w-[24px] h-[24px] "
					)}
				/>
			)
			break
		default:
			return null
	}

	return icon
}
