import {
	OpensearchFlowNFT,
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import React from "react"
import { LoanOrderDisplay } from "./LoanOrderDisplay"
import { NoOrdersDisplay } from "./NoOrdersDisplay"
import { RentalOrderDisplay } from "./RentalOrderDisplay"
import { StorefrontOrderDisplay } from "./StorefrontOrderDisplay"

interface CardOrderDisplayProps {
	order: Order | null
	isLoading?: boolean
	nft: OpensearchFlowNFT
}

export const NftCardOrderDisplay: React.FC<CardOrderDisplayProps> = ({
	isLoading,
	nft,
	order,
}) => {
	const listingType = order?.listingKind

	switch (listingType) {
		case "storefront":
			return (
				<div className='h-[9.325rem]'>
					<StorefrontOrderDisplay
						isLoading={isLoading}
						order={order as OpensearchStorefrontAvailableData}
						nonCustodialOffer={nft?.bestNonCustodialOffer}
						dapperOffer={nft?.bestDapperOffer}
					/>
				</div>
			)
		case "loan":
			return (
				<LoanOrderDisplay
					order={order as OpenSearchListingAvailableData}
					isLoading={isLoading}
				/>
			)
		case "rental":
			return (
				<RentalOrderDisplay
					order={order as OpensearchRentalAvailableData}
					isLoading={isLoading}
				/>
			)
		default:
			return (
				<div className='h-[9.325rem]'>
					<NoOrdersDisplay isLoading={!!isLoading} nft={nft} />
				</div>
			)
	}
}
