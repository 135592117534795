import { FCLTransactionResult } from "flowty-common"
import { Err, getCatalogEntryForType, sendMutation } from "../../utils/Utils"
import { AcceptOfferProps } from "./AcceptOfferTypes"
import { Config } from "../../types"
import { getAcceptOfferTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class AcceptOffer {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	acceptOffer = async ({
		nftData,
		txAvailableCallback,
		token,
		ftReceiverAddress,
		nftProviderPathIdentifier,
		offerResourceID,
		offerStorefrontAddress,
		nftStoragePath,
	}: AcceptOfferProps): Promise<FCLTransactionResult> => {
		if (!nftData) throw new Error("Flow NFT it's null")
		const { contractAddress, contractName, id, type, nftOwner } = nftData

		const catalogIdentifier = await getCatalogEntryForType(type, this.config)
		const isDapper = ["DUC", "FUT"].includes(token.symbol)

		const path = isDapper ? nftStoragePath : nftProviderPathIdentifier
		const pathArg =
			isDapper || !this.config.crescendo
				? fcl.arg(path, t.String)
				: fcl.arg(path || "0", t.UInt64)

		const txArguments = []

		if (!catalogIdentifier && !isDapper) {
			txArguments.push(fcl.arg(contractAddress, t.Address))
		}

		txArguments.push(
			fcl.arg(id.toString(), t.UInt64),
			fcl.arg(offerResourceID.toString(), t.UInt64),
			fcl.arg(offerStorefrontAddress, t.Address),
			pathArg
		)

		if (!isDapper) {
			txArguments.push(
				fcl.arg(catalogIdentifier || contractName, t.String),
				fcl.arg(nftOwner, t.Address),
				fcl.arg(ftReceiverAddress, t.Address),
				fcl.arg(nftData.type, t.String)
			)
		}

		const txContent = getAcceptOfferTxn(this.config, token, !!catalogIdentifier)
		console.debug("txContent", { txArguments, txContent })

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
