import { apiURL } from "./settings"

interface GuessAssetExtensionOptions {
	shouldUseContentType?: boolean
}

type GuessAssetExtension = (
	assetUrl: string,
	predictedExtension: "mp4" | "png" | "jpeg" | "gif",
	options: GuessAssetExtensionOptions
) => Promise<boolean>

const guessAssetExtension: GuessAssetExtension = async (
	assetUrl,
	predictedExtension
) => {
	// assume not a .png when ends in .svg etc etc
	// little shortcut that optimizes a bunch of unnecessary requests
	for (const extension of [".svg", ".png", ".jpeg", ".jpg", ".mp4"].filter(
		i => !i.includes(predictedExtension)
	)) {
		if (assetUrl.toLowerCase().endsWith(extension)) {
			return false
		}
	}

	return fetch(`${apiURL}/imageMimeType?url=${assetUrl}`).then(resp => {
		return resp.json().then(({ mimetype }: { mimetype: string }) => {
			return mimetype === `.${predictedExtension}`
		})
	})
}

export { guessAssetExtension }
