import { OfferCreated, OpensearchStorefrontAvailableData } from "flowty-common"
import { SetStateAction } from "react"
import { ListingState } from "../types/ListingModalContextTypes"

type ButtonTextProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isDelist: ListingState
	error: ListingState
	sealed: ListingState
	isDapper: boolean
	isLocked: boolean
	existingLoanOrder: boolean
	existingRentalOrder: boolean
	isAcceptOffer: boolean
	isSelectedOffer: boolean
	isSingleOrder: boolean
	hasProvider: boolean
	existingPurchaseOrder?: OpensearchStorefrontAvailableData[] | null
	isUpdateListing: boolean
}

export const getSecondaryButtonText = ({
	activeTab,
	loanStep,
	rentStep,
	isDelist,
	error,
	sealed,
	isDapper,
	isLocked,
	existingLoanOrder,
	existingRentalOrder,
	isAcceptOffer,
	isSelectedOffer,
	isSingleOrder,
	hasProvider,
	existingPurchaseOrder,
	isUpdateListing,
}: ButtonTextProps): string => {
	switch (activeTab) {
		case "1":
			if (isAcceptOffer && !sealed.acceptOffer && !isSelectedOffer) {
				return "BACK"
			} else if (
				existingPurchaseOrder &&
				!isDelist.sale &&
				!isUpdateListing &&
				!error.delist &&
				!isSelectedOffer &&
				hasProvider &&
				!sealed.acceptOffer
			) {
				return "DELIST"
			} else if (
				isDelist.sale &&
				!error.delist &&
				!sealed.delist &&
				!isSingleOrder
			) {
				return "BACK"
			} else if (
				sealed.sale ||
				!hasProvider ||
				sealed.delist ||
				sealed.acceptOffer ||
				isLocked
			) {
				return "CLOSE"
			} else if (isUpdateListing) {
				return "BACK"
			} else {
				return "CANCEL"
			}
		case "2":
			if (loanStep === 1) {
				if (
					existingLoanOrder &&
					!isDelist.loan &&
					!isUpdateListing &&
					!error.delist &&
					!sealed.delist
				) {
					return "DELIST"
				} else if (
					(existingLoanOrder || isDelist.loan) &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return "BACK"
				} else if (sealed.transfer || !hasProvider || sealed.delist) {
					return "CLOSE"
				} else {
					return "CANCEL"
				}
			} else {
				if (sealed.loan) {
					return "CLOSE"
				} else {
					return "BACK"
				}
			}
		case "3":
			if (rentStep === 1) {
				if (
					existingRentalOrder &&
					!isDelist.rent &&
					!isUpdateListing &&
					!error.delist &&
					!sealed.delist
				) {
					return "DELIST"
				} else if (
					(isDelist.rent || existingRentalOrder) &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return "BACK"
				} else if (
					sealed.transfer ||
					!hasProvider ||
					sealed.delist ||
					isDapper
				) {
					return "CLOSE"
				} else {
					return "CANCEL"
				}
			} else {
				if (sealed.rent) {
					return "CLOSE"
				} else {
					return "BACK"
				}
			}
		case "4":
			if (sealed.transfer || !hasProvider || isDapper) {
				return "CLOSE"
			} else if (error.transfer) {
				return "BACK"
			}
			return "CANCEL"
		default:
			return "ERROR"
	}
}

type FunctionCallProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isDelist: ListingState
	error: ListingState
	sealed: ListingState
	existingLoanOrder: boolean
	existingRentalOrder: boolean
	isAcceptOffer: boolean
	hasProvider: boolean
	selectedOffer?: OfferCreated
	existingPurchaseOrder?: OpensearchStorefrontAvailableData[] | null
	isUpdateListing: boolean
	isSingleOrder: boolean
	setIsAcceptOffer: (isAcceptOffer: boolean) => void
	setError: (value: SetStateAction<ListingState>) => void
	updateIsDelist: (listingType: "sale" | "loan" | "rent") => void
	cancelIsDelist: (listingType: "sale" | "loan" | "rent") => void
	setIsUpdateListing: (isUpdateListing: boolean) => void
	setLoanStep: (value: SetStateAction<1 | 2>) => void
	setRentStep: (value: SetStateAction<1 | 2>) => void
	onCloseModal: () => void
}

export const getSecondaryBtnFuncionCall = ({
	activeTab,
	loanStep,
	rentStep,
	isDelist,
	error,
	sealed,
	existingLoanOrder,
	existingRentalOrder,
	isAcceptOffer,
	hasProvider,
	existingPurchaseOrder,
	isUpdateListing,
	isSingleOrder,
	selectedOffer,
	setError,
	updateIsDelist,
	cancelIsDelist,
	setIsUpdateListing,
	setIsAcceptOffer,
	setLoanStep,
	setRentStep,
	onCloseModal,
}: FunctionCallProps) => {
	switch (activeTab) {
		case "1":
			if (isAcceptOffer && !sealed.acceptOffer && !selectedOffer) {
				return () => {
					setError((prevState: ListingState) => ({
						...prevState,
						acceptOffer: false,
					}))
					setIsAcceptOffer(false)
				}
			} else if (
				existingPurchaseOrder &&
				!isDelist.sale &&
				!isUpdateListing &&
				!Boolean(selectedOffer) &&
				hasProvider &&
				!sealed.acceptOffer
			) {
				return () => updateIsDelist("sale")
			} else if (
				isDelist.sale &&
				!error.delist &&
				!sealed.delist &&
				!isSingleOrder
			) {
				return () => cancelIsDelist("sale")
			} else if (isUpdateListing && !sealed.sale && hasProvider) {
				return () => {
					setError((prevState: ListingState) => ({
						...prevState,
						sale: false,
					}))
					setIsUpdateListing(false)
				}
			} else {
				return () => onCloseModal()
			}
		case "2":
			if (loanStep === 1) {
				if (
					existingLoanOrder &&
					!isDelist.loan &&
					!isUpdateListing &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => updateIsDelist("loan")
				} else if (
					isDelist.loan &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => cancelIsDelist("loan")
				} else if (
					existingLoanOrder &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => setIsUpdateListing(false)
				} else {
					return () => onCloseModal()
				}
			} else {
				return sealed.loan
					? () => onCloseModal()
					: () => {
							setError((prevState: ListingState) => ({
								...prevState,
								loan: false,
							}))
							setLoanStep(1)
							setRentStep(1)
					  }
			}
		case "3":
			if (rentStep === 1) {
				if (
					existingRentalOrder &&
					!isDelist.rent &&
					!isUpdateListing &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => updateIsDelist("rent")
				} else if (
					isDelist.rent &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => cancelIsDelist("rent")
				} else if (
					existingRentalOrder &&
					!error.delist &&
					!sealed.delist &&
					!isSingleOrder
				) {
					return () => setIsUpdateListing(false)
				} else {
					return () => onCloseModal()
				}
			} else {
				return sealed.rent
					? () => onCloseModal()
					: () => {
							setError((prevState: ListingState) => ({
								...prevState,
								rent: false,
							}))
							setLoanStep(1)
							setRentStep(1)
					  }
			}
		case "4":
			if (error.transfer) {
				return () => {
					setError((prevState: ListingState) => ({
						...prevState,
						transfer: false,
					}))
				}
			} else {
				return () => onCloseModal()
			}
		default:
			return () => {}
	}
}

type DisplayProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isLoading: ListingState
	sealed: ListingState
}

export const getSecondaryButtonIsNotDisplay = ({
	activeTab,
	loanStep,
	rentStep,
	isLoading,
	sealed,
}: DisplayProps): boolean | undefined => {
	switch (activeTab) {
		case "1":
			return isLoading.sale || isLoading.delist || isLoading.acceptOffer
		case "2":
			if (loanStep === 1) {
				return isLoading.delist
			} else if (!sealed.loan) {
				return isLoading.delist || isLoading.loan
			} else {
				return false
			}
		case "3":
			if (rentStep === 1) {
				return isLoading.delist
			} else if (!sealed.rent) {
				return isLoading.delist || isLoading.rent
			} else {
				return false
			}
		case "4":
			return isLoading.transfer
		default:
			return false
	}
}
