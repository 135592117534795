import React, { useState } from "react"
import { ListingType } from "../../../../../components/Facets/ListingTypeFilter/ListingType/ListingType"

interface Props {
	storefrontForm: React.ReactNode
	rentalForm: React.ReactNode
	loanForm: React.ReactNode
	setEventType: (eventType: "storefront" | "loan" | "rent" | "all") => void
}

export const EventTypesWrapper: React.FunctionComponent<Props> = ({
	storefrontForm,
	rentalForm,
	loanForm,
	setEventType,
}) => {
	const [selectedListingType, setSelectedListingType] = useState<
		"loan" | "rent" | "storefront" | "all"
	>("all")
	const [listingIsOpen, setListingIsOpen] = useState({
		loan: false,
		rent: false,
		storefront: false,
	})
	const changeListingType = (
		listingType: "loan" | "rental" | "storefront" | "all"
	): void => {
		setEventType(listingType as "loan" | "rent" | "storefront" | "all")

		setListingIsOpen({
			loan: false,
			rent: false,
			storefront: false,
			[listingType as "loan" | "rental" | "storefront"]: true,
		})

		setSelectedListingType(listingType as "loan" | "rent" | "storefront")
	}

	return (
		<div className='space-y-2'>
			<ListingType
				name='listing-type'
				value='all'
				label='Show All'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "all"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
			<ListingType
				name='listing-type'
				value='storefront'
				label='Buy Now'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "storefront"}
				icon={undefined}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.storefront}
				setIsOpen={(isOpen: boolean) =>
					setListingIsOpen({ ...listingIsOpen, storefront: isOpen })
				}
			>
				<div className='w-full pb-1 mt-2.5'>{storefrontForm}</div>
			</ListingType>
			<ListingType
				name='listing-type'
				value='rent'
				label='Rentals'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "rent"}
				icon={undefined}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.rent}
				setIsOpen={(isOpen: boolean) =>
					setListingIsOpen({ ...listingIsOpen, rent: isOpen })
				}
			>
				<div className='w-full pb-1 mt-2.5'>{rentalForm}</div>
			</ListingType>
			<ListingType
				name='listing-type'
				value='loan'
				label='Loans'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "loan"}
				icon={undefined}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.loan}
				setIsOpen={(isOpen: boolean) =>
					setListingIsOpen({ ...listingIsOpen, loan: isOpen })
				}
			>
				<div className='w-full pb-1 mt-2.5'>{loanForm}</div>
			</ListingType>
		</div>
	)
}
