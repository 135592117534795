import { SupportedTokens } from "flowty-common"
import { IS_MAINNET } from "./Utils"

export const DUC_IDENTIFIER = IS_MAINNET
	? "A.ead892083b3e2c6c.DapperUtilityCoin.Vault" // mainnet
	: "A.82ec283f88a62e65.DapperUtilityCoin.Vault" // testnet

export const FUT_IDENTIFIER = IS_MAINNET
	? "A.ead892083b3e2c6c.FlowUtilityToken.Vault" // mainnet
	: "A.82ec283f88a62e65.FlowUtilityToken.Vault" // testnet

export const FLOW_IDENTIFIER = IS_MAINNET
	? "A.1654653399040a61.FlowToken.Vault" // mainnet
	: "A.7e60df042a9c0868.FlowToken.Vault" // testnet

export const USDC_IDENTIFIER = IS_MAINNET
	? "A.f1ab99c82dee3526.USDCFlow.Vault" // mainnet
	: "A.64adf39cbc354fcb.USDCFlow.Vault" // testnet

export const PaymentTokenToIdentifier: Record<SupportedTokens, string> = {
	DUC: DUC_IDENTIFIER,
	FLOW: FLOW_IDENTIFIER,
	USDC: USDC_IDENTIFIER,
}

export const IdentifierToPaymentToken: Record<string, SupportedTokens> = {
	[DUC_IDENTIFIER]: SupportedTokens.DUC,
	[FLOW_IDENTIFIER]: SupportedTokens.FLOW,
	[USDC_IDENTIFIER]: SupportedTokens.USDC,
}
