import { CreatorHubTooltipIcon } from "../../../../../FlowtyCreatorHub/components/common/CreatorHubTooltipIcon/CreatorHubTooltipIcon"
import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FlowtyCreatorHubFormValues } from "../../../../types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../utils/formValidation"
import { CreatorHubRadioSelectGroup } from "../../../common/CreatorHubRadioGroup/CreatorHubRadioSelectGroup"
import { CreatorHubUploadImage } from "../../../common/CreatorHubUploadImage/CreatorHubUploadImage"
import { CustomizeNumberInput } from "../CustomizeCollectionFormStep/components/CustomizeNumberInput/CustomizeNumberInput"
import { DateTimeSection } from "./components/DateTimeSection"
import { options } from "./utils/DropTypeRadioOptions"

export const CustomizeDropFormStep: React.FunctionComponent = () => {
	const {
		errors,
		touched,
		uploadImage,
		setFieldError,
		loggedUser,
		flowtyMarketplaceFee,
	} = useFlowtyCreatorHubContext()

	return (
		<div className='flex flex-1 flex-col justify-between self-stretch h-full'>
			<div className='flex flex-col gap-[16px] 3xl:gap-[24px] text-white'>
				<CreatorHubUploadImage
					title='Drop Page Image*'
					name='dropThumbnailImage'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"dropThumbnailImage",
						touched,
						errors
					)}
					errorMessage={
						renderErrorMessage<FlowtyCreatorHubFormValues>(
							"dropThumbnailImage",
							touched,
							errors
						) as string
					}
					imageType={"THUMBNAIL"}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
				<DateTimeSection
					label='Start Date & Time (Local Time Zone)'
					name={"dropStartDateTime"}
					selectsStart={true}
					errors={errors}
					touched={touched}
				/>
				<DateTimeSection
					label='End Date & Time (Local Time Zone)'
					name={"dropEndDateTime"}
					selectsEnd={true}
					errors={errors}
					touched={touched}
				/>
				<CustomizeNumberInput
					inputName='mintPrice'
					type='flow'
					dropDownText='Funds received per minted NFT'
					title='Mint Price'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"mintPrice",
						touched,
						errors
					)}
					errorMessage={
						renderErrorMessage<FlowtyCreatorHubFormValues>(
							"mintPrice",
							touched,
							errors
						) as string
					}
					placeHolder='0.00'
					toolTip={
						<CreatorHubTooltipIcon
							toolTipCustomId='cratorMintTooltip'
							renderContent={
								<span>
									Price per NFT that a user mints. For a Free Mint, please input
									zero or leave blank.
								</span>
							}
						/>
					}
				/>
				<CustomizeNumberInput
					inputName='royalty'
					type='percentage'
					dropDownText='Funds received if NFT is sold by a collector'
					title='Royalty'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"royalty",
						touched,
						errors
					)}
					errorMessage={
						renderErrorMessage<FlowtyCreatorHubFormValues>(
							"royalty",
							touched,
							errors
						) as string
					}
					flowtyMarketplaceFee={flowtyMarketplaceFee}
					placeHolder='0'
					toolTip={
						<CreatorHubTooltipIcon
							toolTipCustomId='creatorRoyaltytTooltip'
							renderContent={
								<span>
									The percentage of each marketplace sale that the collection
									creator receives (see example). Many collections choose a 5%
									royalty.
								</span>
							}
						/>
					}
				/>
				<div className='flex flex-col gap-[6.667px] 3xl:gap-[12px]'>
					<div className='flex-col text-white text-xs 3xl:text-lg font-bold'>
						Edition Type
					</div>
					<CreatorHubRadioSelectGroup options={options} classNames='flex-row' />
				</div>
			</div>
		</div>
	)
}
