import React, { useState } from "react"
import CurrentStepButton from "../CurrentStepButton"
import step01Image from "../../../assets/step-01-image.png"
import step02Image from "../../../assets/getting-started-step-02.png"
import step03Image from "../../../assets/getting-started-step-03.png"
import Seek from "../Seek"
import { Tab } from "@headlessui/react"
import clsx from "clsx"

export type Step = {
	step: string
	text: string
	subText: string
	image: string
}

export const steps: Step[] = [
	{
		image: step01Image,
		step: "01",
		subText: "Flowty supports 5 different wallet types",
		text: "Sign in / Register",
	},
	{
		image: step02Image,
		step: "02",
		subText:
			"Check out thousands of listings from Top Shot, All Day and other collections",
		text: "Visit",
	},
	{
		image: step03Image,
		step: "03",
		subText: "Use our advanced filter and sort options to find NFTs to buy!",
		text: "Buy Now!",
	},
]

const Carousel = () => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const handleSeek = (direction: "left" | "right") => {
		if (direction === "left") {
			setSelectedIndex(prev => (prev === 0 ? steps.length - 1 : prev - 1))
		} else {
			setSelectedIndex(prev => (prev === steps.length - 1 ? 0 : prev + 1))
		}
	}

	return (
		<div>
			<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
				<>
					<div className='flex justify-between items-center'>
						<Tab.List className='inline-flex gap-[64px] items-stretch justify-between w-full container 2xl:px-[192px]'>
							{steps.map((step, featureIndex) => (
								<CurrentStepButton
									key={step.step}
									step={step.step}
									text={step.text}
									subText={step.subText}
									isCurrentStep={featureIndex === selectedIndex}
								/>
							))}
						</Tab.List>
					</div>
					<Tab.Panels className='px-[192px] mt-[63px]'>
						<div
							className='flex flex-col items-start rounded-[25px] relative shadow-gettingStartedBoxShadow p-[18px]'
							style={{
								background: "rgba(255, 255, 255, 0.08)",
								boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<div className='absolute -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer'>
								<Seek handleSeek={handleSeek} direction='right' />
							</div>
							<div className='absolute -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer'>
								<Seek handleSeek={handleSeek} direction='left' />
							</div>
							{steps.map((step, featureIndex) => (
								<Tab.Panel
									static
									key={step.subText}
									className={clsx(
										"transition duration-500 ease-in-out ui-not-focus-visible:outline-none",
										featureIndex !== selectedIndex && "opacity-0"
									)}
									aria-hidden={featureIndex !== selectedIndex}
								>
									<img
										alt={step.subText}
										src={step.image}
										className={`w-full rounded-[25px] ${
											featureIndex !== selectedIndex ? "h-[0px]" : "h-full"
										}`}
										style={{
											boxShadow:
												"8.194px 8.194px 40.968px 0px rgba(55, 55, 55, 0.54)",
										}}
									/>
								</Tab.Panel>
							))}
						</div>
					</Tab.Panels>
				</>
			</Tab.Group>
		</div>
	)
}

export default Carousel
