import { SpotPrice } from "flowty-common"
import { useField } from "formik"
import React, { useEffect, useRef } from "react"
import { FlowtyCheckbox } from "../../../../FlowtyCheckbox"
import { NftPrice } from "../../common/NftPrice"
import { ListingExpiration } from "../../common/ListingExpiration"

interface LoanListingStep2Props {
	loanFormError: boolean
	spotPrice?: SpotPrice
	loanStep: number
	listingDuration: number
}

export const LoanListingStep2: React.FunctionComponent<
	LoanListingStep2Props
> = ({ loanFormError, spotPrice, loanStep, listingDuration }) => {
	const modalTop = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (loanFormError) {
			if (modalTop?.current?.scrollIntoView) {
				modalTop?.current?.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [loanFormError, loanStep])

	const [tokenType] = useField({ name: `tokenType` })
	const [amountToBorrow] = useField({ name: `amountToBorrow` })
	const [amountToRepay] = useField({ name: `amountToRepay` })
	const [duration] = useField({ name: `loanDuration` })

	return (
		<div
			className='w-full h-full flex flex-col justify-between gap-4'
			ref={modalTop}
		>
			<div className='flex flex-col'>
				<p className='text-base font-semibold text-white'>
					If Your Loan is Funded
				</p>
				<div className='flex flex-col mt-4 mb-6 gap-2'>
					<div className='flex items-start justify-between'>
						<p className='text-sm font-normal text-white'>
							You&apos;ll Receive
						</p>
						<NftPrice
							price={amountToBorrow.value}
							token={tokenType.value}
							spotPriceValue={amountToBorrow.value * (spotPrice?.value || 0)}
						/>
					</div>
					<div className='flex items-start justify-between'>
						<p className='text-sm font-normal text-white'>
							You&apos;ll Have{" "}
							<span className='font-bold'>{duration.value} Days</span> to Repay
						</p>
						<NftPrice
							price={amountToRepay.value}
							token={tokenType.value}
							spotPriceValue={amountToRepay.value * (spotPrice?.value || 0)}
						/>
					</div>
				</div>
				<hr className='border-[#495057]'></hr>
				<ListingExpiration
					listingDuration={listingDuration}
					text={"Loan is Not Funded"}
				/>
			</div>
			<div className='flex flex-col gap-4 text-white font-normal'>
				<p className='text-xs text-white'>
					Note: Failure to fully repay this loan will lead to a loan default and
					forfeiture of collateral
				</p>

				<div className='flex flex-col gap-4'>
					<FlowtyCheckbox
						label={
							<span className='text-[#ADB5BD]'>
								Enable automatic repayment if funds available
							</span>
						}
						name='loanEnableAutoRepayment'
						id='loanEnableAutoRepayment'
					/>
				</div>
			</div>
		</div>
	)
}
