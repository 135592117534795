import { FCLTransactionResult } from "flowty-common"
import { Err, sendMutation } from "../../utils/Utils"
import { FundLoanListingProps } from "./FundLoanListingTypes"
import { Config } from "../../types"
import { getFundLoanListingTxn } from "./transaction"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class FundLoanListing {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	fundLoanListing = async ({
		listingData,
		token,
		nftData,
		nftReceiverAddress,
		ftPrivatePathIdentifier,
		ftProviderAddress,
		txAvailableCallback,
	}: FundLoanListingProps): Promise<FCLTransactionResult> => {
		const listItemID = listingData.listingResourceID || ""
		const address = listingData.flowtyStorefrontAddress?.toString() || "0"

		const ftProviderArg = this.config.crescendo
			? fcl.arg(ftPrivatePathIdentifier || "0", t.UInt64)
			: fcl.arg(ftPrivatePathIdentifier, t.String)

		const txArguments = [
			fcl.arg(listItemID.toString(), t.UInt64),
			fcl.arg(address, t.Address),
			ftProviderArg,
			fcl.arg(nftReceiverAddress, t.Address),
			fcl.arg(ftProviderAddress, t.Address),
		]

		const txContent = getFundLoanListingTxn(this.config, token, nftData)

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
