import { useField } from "formik"
import { FLOWTY_QUICKNODE_IPFS_URL } from "../../../../../utils/constants"
import React from "react"

export const PreviewBanner: React.FunctionComponent = () => {
	const [nftThumbnailImage] = useField("nftThumbnailImage")
	const [collectionName] = useField("collectionName")
	const [collectionDescription] = useField("collectionDescription")

	return (
		<div
			data-testid='preview-banner'
			className='w-full flex flex-col gap-[8px] 3xl:gap-[20px]'
		>
			<div className='flex flex-row space-x-4 items-center flex-grow'>
				<div className='h-[60px] flex flex-row space-x-4 items-center gap-2 3xl:gap-4 grow'>
					<div className='flex items-center justify-center space-x-8'>
						<div>
							{nftThumbnailImage.value &&
							(nftThumbnailImage.value?.fileCid ||
								nftThumbnailImage.value?.fileBase64) ? (
								<img
									src={
										nftThumbnailImage.value?.fileCid
											? `${FLOWTY_QUICKNODE_IPFS_URL}${nftThumbnailImage.value?.fileCid}`
											: nftThumbnailImage.value?.fileBase64
									}
									alt='Drop Custom'
									className='h-[60px] w-[60px] min-w-[60px] 3xl:h-[92px] 3xl:w-[92px] 3xl:min-w-[92px] bg-cover bg-no-repeat rounded-[6px] sm:h-18 sm:w-18 bg-[#edf3f60a]'
								/>
							) : (
								<div className='animate-pulse h-[60px] w-[60px] min-w-[60px] 3xl:h-[92px] 3xl:w-[92px] 3xl:min-w-[92px] bg-cover bg-no-repeat rounded-[12px] sm:h-18 sm:w-18 bg-[#edf3f60a]' />
							)}
						</div>
						<div className='flex flex-col items-start justify-start mb-auto'>
							{collectionName.value ? (
								<div
									data-testid='preview-collection-name'
									className='text-white font-montserrat text-[16px] 3xl:text-[1.67475rem] font-extrabold leading-normal'
								>
									{collectionName.value}
								</div>
							) : (
								<div className='animate-pulse w-[120px] h-[25px] lg:w-[240px] bg-[#edf3f60a] rounded-md' />
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='flex items-center justify-between w-full gap-[50px]'>
				{collectionDescription.value ? (
					<div className='w-[400px] 3xl:w-[580px] h-[60px] overflow-y-auto py-4 px-6 rounded-md bg-white bg-opacity-5 backdrop-blur-lg break-words'>
						<p
							data-testid='preview-description'
							className='text-[#ADB5BD] leading-3 text-[10px] 3xl:text-sm font-medium overflow-hidden text-ellipsis'
						>
							{collectionDescription.value}
						</p>
					</div>
				) : (
					<div className='animate-pulse w-[300px] 3xl:w-[480px] h-[60px] bg-[#edf3f60a] rounded-md' />
				)}
			</div>
		</div>
	)
}
