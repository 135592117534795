import React from "react"
import SaleTag from "../PNGs/SaleTag.png"
import BackgroundSquares from "../SVGs/BackgroundSquares.png"
import { ReactComponent as Flowty } from "../SVGs/Flowty.svg"
import { ReactComponent as FlowtyBot } from "../SVGs/FlowtyBot.svg"
import GreenHover from "../SVGs/GreenHover.svg"
import TopGreenHover from "../SVGs/TopGreenHover.svg"
import backgroundGradient from "../SVGs/backgroundGradient.png"
import { RecentBigSaleResponse } from "../types"
interface RecentBigSaleProps {
	saleItem: RecentBigSaleResponse["data"] | null
	isLoading: boolean
}

export const RecentBigSale: React.FC<RecentBigSaleProps> = ({
	saleItem,
	isLoading,
}) => {
	return (
		<div className='grid md:grid-cols-2 mx-auto'>
			<div
				className='flex flex-col justify-center items-center gap-[16px] md:gap-[38px] relative z-10 overflow-hidden bg-cover bg-center bg-no-repeat py-[54px] rounded-t-[8px] md:rounded-l-[8px] 2xl:rounded-l-[0px]'
				style={{
					background: `
					  url(${BackgroundSquares}),
					  radial-gradient(50% 50% at 50% 50%, rgba(9, 185, 255, 0.08) 0%, rgba(3, 16, 33, 0.03) 99.99%, rgba(3, 16, 33, 0) 100%)
					`,
				}}
			>
				<div className='flex flex-col sm:flex-row md:flex-col items-center md:items-start justify-start gap-x-[8px] flex-wrap sm:flex-nowrap'>
					<p className='recent-text text-[22px] md:text-[26px] tracking-[4px] leading-normal md:tracking-[5px] uppercase text-MontserratBold'>
						Featured
					</p>
					<p className='font-montserrat text-[22px] md:text-[44px] font-extrabold leading-normal tracking-[4px] md:tracking-[1.74px] uppercase'>
						Purchase
					</p>
				</div>
				<div className='flex flex-col gap-[7px]'>
					<img
						src={TopGreenHover}
						alt='Top Green Hover'
						className='h-[640px] absolute top-[-320px] left-[-100px] rounded-[1177px] -z-50'
						style={{
							background:
								"radial-gradient(50% 50% at 50% 50%, rgba(9, 185, 255, 0.08) 0%, rgba(3, 16, 33, 0.03) 99.99%, rgba(3, 16, 33, 0.00) 100%)",
						}}
					/>
					{isLoading ? (
						<div className='space-y-4'>
							<div className='w-[242px] lg:w-[200px] py-[2rem] bg-gray-400 animate-pulse'></div>
							<div className='flex justify-start lg:justify-end'>
								<div className='w-[100px] h-[25px] bg-gray-400 animate-pulse'></div>
							</div>
						</div>
					) : (
						<>
							<a
								className='text-right ml-auto text-[14px] tracking-[0.5px] leading-normal font-normal text-white opacity-80 max-w-[16em] truncate'
								href={saleItem?.attributes.nft_detail_link}
							>
								{saleItem?.attributes.nft_name}
							</a>
							<a
								className='text-right ml-auto text-[12px] tracking-[0.5px] leading-normal font-normal text-white opacity-80 max-w-[16em] truncate'
								href={saleItem?.attributes.nft_detail_link}
							>
								{saleItem?.attributes.nft_description}
							</a>
							<div
								style={{
									backgroundImage: `url(${SaleTag})`,
								}}
								className='bg-auto bg-center bg-no-repeat saleTagPadding'
							>
								<p
									className='font-montserrat text-[28px] lg:text-[33px] font-bold leading-normal tracking-[1px] lg:tracking-[1.32px]'
									style={{
										WebkitBackgroundClip: "text",
										WebkitTextFillColor: "transparent",
										background: "linear-gradient(90deg, #000 0%, #505050 100%)",
										backgroundClip: "text",
									}}
								>
									{saleItem?.attributes.salePrice}
								</p>
							</div>
							<div className='flex flex-col justify-end items-end'>
								<a
									className='text-left lg:text-right text-[13px] tracking-[0.5px] leading-normal font-normal text-white opacity-80'
									href={saleItem?.attributes.buyerUrl}
								>
									Buyer: {saleItem?.attributes.buyerName}
								</a>
								<a
									className='text-left lg:text-right text-[13px] tracking-[0.5px] leading-normal font-normal text-white opacity-80'
									href={saleItem?.attributes.nft_detail_link}
								>
									Purchased: {saleItem?.attributes.purchase_date}
								</a>
							</div>
						</>
					)}
				</div>
				{isLoading ? (
					<div className='h-[39px] bg-gray-400 animate-pulse w-[244px]'></div>
				) : (
					<>
						<p
							className='font-montserrat font-extrabold text-[22px] lg:text-[26px] leading-normal tracking-[0.64px] lg:tracking-[0.9px]'
							style={{
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
								background:
									"var(--text-gradient, linear-gradient(90deg, rgba(255, 255, 255, 0.78) 38.95%, rgba(255, 255, 255, 0.24) 99.95%))",
								backgroundClip: "text",
							}}
						>
							{saleItem?.attributes.hashtag}
						</p>
						<img
							src={GreenHover}
							alt='Green Hover'
							className='h-[846px] absolute bottom-[-400px] rounded-[1177px] -z-50 left-[-100px]'
							style={{
								background: `radial-gradient(50% 50% at 50% 50%, rgba(157, 157, 157, 0.19) 0%, rgba(3, 16, 33, 0.10) 99.99%, rgba(3, 16, 33, 0.00) 100%)`,
							}}
						/>
					</>
				)}
			</div>
			<div
				style={{
					backgroundImage: `url(${backgroundGradient})`,
				}}
				className='bg-cover bg-center bg-no-repeat flex flex-row justify-around 2xl:justify-center items-center py-[54px] relative z-10 rounded-b-[8px] md:rounded-r-[8px] md:rounded-b-[0px]'
			>
				{isLoading ? (
					<div className='w-[185px] h-[320px] p-[9px] rounded-[8px] block md:absolute customPaddingVideo -mt-[83px] md:mt-[0px] animate-pulse bg-gray-400 cursor-wait'></div>
				) : (
					<>
						<img
							className='w-[24px] h-[24px] z-0 md:z-10 hidden sm:block sm:absolute top-[8px] right-[8px] rounded-[3px]'
							src={saleItem?.attributes.collectionImage.data.attributes.url}
							alt='Collection Name'
						/>
						<div
							className='p-[9px] rounded-[8px] block md:absolute customPaddingVideo -mt-[83px] md:mt-[0px] custom-video-border'
							style={{
								backdropFilter: "blur(19.63503074645996px)",
								background:
									"linear-gradient(153deg, rgba(205, 205, 205, 0.31) 0%, rgba(255, 255, 255, 0.19) 100%)",
							}}
						>
							<video
								src={saleItem?.attributes.videoUrl.data?.[0].attributes.url}
								autoPlay
								loop
								muted
								playsInline
								preload='auto'
								className='w-[169px] h-[300px] rounded-[8px]'
							/>
						</div>
					</>
				)}

				{isLoading ? (
					<div className='py-[43px] z-10 rounded-[8px] h-[320px] w-[80px] items-center gap-[43px] customPaddingAttr -mt-[83px] md:mt-[0px] bg-gray-400 animate-pulse cursor-wait'></div>
				) : (
					<div
						className='py-[43px] z-10 rounded-[8px] flex flex-col justify-center items-center gap-[43px] customPaddingAttr -mt-[83px] md:mt-[0px] custom-attributes-border'
						style={{
							backdropFilter: "blur(13px)",
							background:
								"linear-gradient(177deg, rgba(4, 7, 11, 0.80) 0.35%, rgba(41, 72, 113, 0.80) 99.69%)",
						}}
					>
						{saleItem?.attributes.recent_big_sale_attributes.data.map(
							(attribute, index) => (
								<div
									key={index}
									className='flex flex-col gap-[7px] text-center mx-auto'
								>
									<img
										src={attribute.attributes.imgUrl.data.attributes.url}
										alt={attribute.attributes.name}
										className='h-[26px] w-[26px] mx-auto'
									/>
									<p className='text-white text-[7.6px] font-montserrat font-light tracking-[0.3px] leading-normal text-center w-[80px] px-1'>
										{attribute.attributes.name}
									</p>
								</div>
							)
						)}
					</div>
				)}

				<div className='absolute bottom-[8px] right-[8px] flex flex-row gap-x-[5px] items-center'>
					<span
						className='font-montserrat font-normal leading-[0.23px]'
						style={{ color: "rgba(0, 0, 0, 0.75)" }}
					>
						Powered By
					</span>
					<Flowty />
					<FlowtyBot />
				</div>
			</div>
		</div>
	)
}
