import { isAfter } from "date-fns"
import {
	LoanExpirationWarningNotification,
	NotificationData,
	NotificationType,
} from "flowty-common"
import { Timestamp } from "firebase/firestore"

export const getExpiresOnDate = (expiresOn: Timestamp | Date): Date => {
	if (expiresOn instanceof Timestamp) {
		return expiresOn.toDate()
	}
	return expiresOn
}

interface ReturnPastAndTodaysNotifications {
	notificationsFromBeforeToday: NotificationData[]
	notificationsFromToday: NotificationData[]
	pinnedNotifications: LoanExpirationWarningNotification[]
}

export const returnPastAndTodaysNotifications = (
	notifications: NotificationData[]
): ReturnPastAndTodaysNotifications => {
	const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000
	const pinnedNotifications: LoanExpirationWarningNotification[] = []
	const notificationsFromToday: NotificationData[] = []
	const notificationsFromBeforeToday: NotificationData[] = []

	notifications.forEach(notification => {
		if (NotificationType.LoanExpirationWarning === notification.type) {
			const expiresOnDate = getExpiresOnDate(notification.expiresOn)

			const now = new Date()
			if (!isAfter(expiresOnDate, now)) {
				return
			}
			pinnedNotifications.push(notification)
		} else if (notification.timestamp > oneDayAgo) {
			notificationsFromToday.push(notification)
		} else {
			notificationsFromBeforeToday.push(notification)
		}
	})

	pinnedNotifications.sort((a, b) => {
		const dateA = getExpiresOnDate(a.expiresOn).getTime()
		const dateB = getExpiresOnDate(b.expiresOn).getTime()
		return dateB - dateA
	})
	notificationsFromToday.sort((a, b) => b.timestamp - a.timestamp)
	notificationsFromBeforeToday.sort((a, b) => b.timestamp - a.timestamp)

	return {
		notificationsFromBeforeToday,
		notificationsFromToday,
		pinnedNotifications,
	}
}
