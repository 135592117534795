import React from "react"
import { useLocation } from "react-router-dom"
import { STOREFRONT_ENABLED } from "../../util/settings"
import { AccountsFilter } from "../Facets/AccountsFilter/AccountsFilter"
import { CollectionsFilter } from "../Facets/CollectionsFilter/CollectionsFilter"
import { ListingTypeFilter } from "../Facets/ListingTypeFilter/ListingTypeFilter"
import { SerialRangeFilter } from "../Facets/SerialRangeFilter/SerialRangeFilter"

interface SidebarProps {
	showAccounts?: boolean
}

export const Sidebar: React.FC<SidebarProps> = ({ showAccounts = true }) => {
	const location = useLocation()
	const isUserPage = location.pathname.includes("/user")
	return (
		<div className='border-glassFillGrayBorder p-[1rem] rounded-[0.5rem] border-[0.3px] hidden lg:overflow-y-auto overflow-x-hidden lg:flex lg:flex-col items-stretch'>
			<div className='mb-[1rem]'>
				<CollectionsFilter />
			</div>
			<div className='mb-[1rem]'>
				<SerialRangeFilter />
			</div>
			<div className='mb-[1rem]'>
				<ListingTypeFilter />
			</div>
			{STOREFRONT_ENABLED && showAccounts && !isUserPage && (
				<div className='mb-[1rem]'>
					<AccountsFilter />
				</div>
			)}
		</div>
	)
}
