/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, MutableRefObject } from "react"

interface InfiniteScroll {
	onInfiniteScroll: () => void
	isLoading?: boolean
}

const useInfiniteScroll = (
	{ onInfiniteScroll, isLoading }: InfiniteScroll,
	dependencies: Array<any> = []
): ((node: HTMLElement | null) => void) => {
	const observer: MutableRefObject<HTMLElement | null> = useRef(null)

	// TODO what should this type be? based on usage, a ref, but we are returning
	// a fn here
	return useCallback(
		node => {
			if (isLoading) {
				return null
			}

			if (observer.current) {
				;(observer.current as any).disconnect()
			}

			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// fetchAccountNFts()
					onInfiniteScroll()
				}
			}) as any

			if (node) {
				;(observer.current as any).observe(node)
			}
			return null
		},
		[isLoading, ...dependencies]
	)
}

export default useInfiniteScroll
