import { Trait } from "flowty-common"

interface GenerateTraitUrl {
	collectionAddress: string
	collectionName: string
	trait: Trait
	traitDisplayValue: string
}

export const generateTraitUrl = ({
	collectionAddress,
	collectionName,
	trait,
	traitDisplayValue,
}: GenerateTraitUrl): string => {
	const urlParams = new URLSearchParams()

	urlParams.set(
		"collectionFilters",
		JSON.stringify({
			[`${collectionAddress}.${collectionName}`]: {
				[trait.name]: {
					[traitDisplayValue]: true,
				},
			},
		})
	)

	urlParams.set("orderFilters", JSON.stringify({}))

	urlParams.set(
		"sort",
		JSON.stringify({
			direction: "desc",
			listingKind: null,
			path: "blockTimestamp",
		})
	)
	urlParams.set("paymentTokens", "null")

	const url = `/collection/${collectionAddress}/${collectionName}?${urlParams.toString()}`

	return url
}
