import { useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import { AccountSummaries, AccountSummary } from "flowty-sdk"
import { LinkedAccountBox } from "./components/LinkedAccountBox"
import { SupportedTokens } from "flowty-common"
import { MobileWalletCarousel } from "./components/MobileWalletCarousel"

type Props = {
	mainAccount?: AccountSummary
	childAccounts?: AccountSummaries
	isLoggedUserAcct: boolean
	selectedWalletAddresses: string[]
	updateSelectedAddresses: (address: string) => void
	clearAllAddresses: () => void
	selectAllAddresses: () => void
	onUploadNewImage: (
		event: React.ChangeEvent<HTMLInputElement>,
		setImage: (image: string) => void,
		setIsLoading: (isLoading: boolean) => void,
		fileName: string
	) => Promise<void | undefined>
	saveNewImage: (address: string, name: string, image: string) => void
	tokenIdentifiers: Record<string, SupportedTokens>
}

export const FlowtyChildWalletCarousel: React.FunctionComponent<Props> = ({
	mainAccount,
	childAccounts,
	selectedWalletAddresses,
	updateSelectedAddresses,
	clearAllAddresses,
	selectAllAddresses,
	onUploadNewImage,
	saveNewImage,
	isLoggedUserAcct,
	tokenIdentifiers,
}) => {
	const [isOpen, setIsOpen] = useState(true)

	return (
		<div className='bg-transparent md:bg-[#FFFFFF0A] rounded-md md:p-[18px] md:border border-[#4B4B4B7A] text-white'>
			<div className='!border-none !rounded-md  w-full relative'>
				<div
					className='w-full flex justify-between cursor-pointer'
					onClick={() => setIsOpen(prev => !prev)}
				>
					<div className='w-full flex flex-row justify-between md:justify-start items-center gap-3'>
						<span className='text-white text-[1.125rem] font-[700]'>
							Accounts
						</span>
						<div className='flex items-center gap-2'>
							<button
								onClick={e => {
									e.stopPropagation()
									selectAllAddresses()
								}}
								className='bg-[#606E7D33] hover:bg-[#606E7D52] border border-[#FFFFFF1F] rounded-md px-[12px] py-[6px] cursor-pointer leading-none'
							>
								Select All
							</button>
							<button
								onClick={e => {
									e.stopPropagation()
									clearAllAddresses()
								}}
								className='bg-[#606E7D33] hover:bg-[#606E7D52] border border-[#FFFFFF1F] rounded-md px-[12px] py-[6px] cursor-pointer leading-none'
							>
								Clear All
							</button>
						</div>
					</div>
					<div className='hidden md:flex'>
						{isOpen ? (
							<ChevronUpIcon height={24} width={24} />
						) : (
							<ChevronDownIcon height={24} width={24} />
						)}
					</div>
				</div>
				{isOpen && (
					<div className='py-2 px-0 mt-4 overflow-x-auto styled-scroll'>
						<div className='hidden md:flex flex-col items-start'>
							<div className='flex'>
								{mainAccount && (
									<LinkedAccountBox
										saveNewImage={saveNewImage}
										onUploadNewImage={onUploadNewImage}
										parentWalletAddress={mainAccount.address}
										acct={mainAccount as AccountSummary}
										isSelected={selectedWalletAddresses.includes(
											`${mainAccount.address}`
										)}
										onSelect={updateSelectedAddresses}
										tokenIdentifiers={tokenIdentifiers}
									/>
								)}
								{childAccounts &&
									Object.values(childAccounts).map(childAcct => {
										if (childAcct.address === mainAccount?.address) return null
										return (
											<LinkedAccountBox
												saveNewImage={saveNewImage}
												onUploadNewImage={onUploadNewImage}
												parentWalletAddress={mainAccount?.address ?? ""}
												canEdit={isLoggedUserAcct}
												key={childAcct.address}
												acct={childAcct}
												isSelected={selectedWalletAddresses.includes(
													childAcct.address
												)}
												onSelect={updateSelectedAddresses}
												tokenIdentifiers={tokenIdentifiers}
											/>
										)
									})}
							</div>
						</div>
						<MobileWalletCarousel
							mainAccount={mainAccount}
							childAccounts={childAccounts}
							isLoggedUserAcct={isLoggedUserAcct}
							selectedWalletAddresses={selectedWalletAddresses}
							updateSelectedAddresses={updateSelectedAddresses}
							onUploadNewImage={onUploadNewImage}
							saveNewImage={saveNewImage}
							tokenIdentifiers={tokenIdentifiers}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
