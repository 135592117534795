import { useMemo } from "react"
import { ReactComponent as Twitter } from "./../../../assets/twitter_icon.svg"
import { ReactComponent as CloseX } from "../../../assets/close-x.svg"
import { FlowtyButton } from "../../../FlowtyButton"
import { actions as Mixpanel } from "../../../utils/MockMixpanel"

interface TwitterHandleProps {
	walletAddress: string | null
	twitterHandle: string | null
	handleTwitterDisconnect: () => Promise<void>
}

export const TwitterHandle: React.FunctionComponent<TwitterHandleProps> = ({
	walletAddress,
	twitterHandle,
	handleTwitterDisconnect,
}) => {
	const twitterRedirect: string | null = useMemo(() => {
		if (typeof window !== "undefined") {
			if (window.location.host.startsWith("flowty-test")) {
				return "https://testnet.flowty.io/_twitter-oauth"
			} else if (window.location.host.startsWith("flowty-prod")) {
				return "https://www.flowty.io/_twitter-oauth"
			}
			return `${window.location.origin}/_twitter-oauth`
		}
		return null
	}, [])

	return (
		<div className='flex justify-between w-full mt-[2rem]'>
			<div className='flex items-center'>
				<Twitter className='mr-2 w-[1.5rem]' />
				<div className='text-md font-semibold'>Twitter</div>
			</div>

			<div className='flex items-center transition-transform duration-300 ease-in-out'>
				{twitterHandle ? (
					<div className='flex h-[2rem] px-[.875rem] items-center gap-[.5rem] bg-[#ffffff14] rounded-md border border-[#6C757D]/25'>
						<a
							href={`https://twitter.com/${twitterHandle}`}
							target='_blank'
							rel='noreferrer'
						>
							<div className='text-[#ADB5BD] text-xs font-extrabold'>
								@{twitterHandle}
							</div>
						</a>
						<button onClick={handleTwitterDisconnect}>
							<CloseX />
						</button>
					</div>
				) : (
					<a
						href={`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=eVpUT3cyQ0doaGJYdzF3Mk4xdUk6MTpjaQ&redirect_uri=${twitterRedirect}&scope=tweet.read%20users.read%20follows.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`}
						target='_blank'
						rel='noreferrer'
					>
						<FlowtyButton
							text='Connect'
							variant='secondary'
							btnSize='small'
							bgColor='white'
							onClick={() =>
								Mixpanel.track("TWITTER_CONNECT_CLICKED", {
									twitterHandle,
									walletAddress,
								})
							}
						/>
					</a>
				)}
			</div>
		</div>
	)
}
