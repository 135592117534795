import { useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { SortDirection } from "../../../../../components/MarketPlace/SortButton/shared"
import { ActivityItem } from "../../../../../components/Shared/ActivityTableFields"
import { SortColumnOrNull } from "../../../../../components/SortableTable"
import { CollectionActivityItem } from "../../../../../components/SortableTable/PlatformActivity/collectionLoanActivityFields"
import { FilterBy } from "../../../../../components/UserProfile/Tables/Storefront/AllActivity/types"
import useInfiniteScroll from "../../../../../hooks/infiniteScroll"
import { subscribeToActivity } from "../../../../../services/firestore/ActivityService"
import { subscribeToAllCollectionActivity } from "../../../../../services/firestore/CollectionActivityService"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import { flowty } from "../../../../../config/config"

export type ActivityTableTabsType = "SALE" | "LOAN" | "RENTAL"

export const DEFAULT_ACTIVITY_PAGE_SIZE = 24

interface CollectionActivityTablesValues {
	allActivityDocs: Array<ActivityItem | CollectionActivityItem>
	belowTableRef: (node: HTMLElement | null) => void
	filterBy: FilterBy
	isLoading: boolean
	onFilterChangeFn: (filter: FilterBy) => void
	onSortChangeFn: (sort: SortColumnOrNull) => void
	onTableChangeTabFn: (tab: ActivityTableTabsType) => void
	setShowFilter: (show: boolean) => void
	showFilter: boolean
	sort: SortColumnOrNull
	tablePage: number
	tableSelectedTab: ActivityTableTabsType
}

export const useCollectionActivityTables =
	(): CollectionActivityTablesValues => {
		const { collectionAddress, collectionName } = useParams()
		const [isLoading, setIsLoading] = useState(false)
		const [tablePage, setTablePage] = useState<number>(1)
		const [loanTabActivityDocs, setLoanTabActivityDocs] = useState<
			Array<CollectionActivityItem>
		>([])
		const [rentalTabActivityDocs, setRentalTabActivityDocs] = useState<
			Array<ActivityItem>
		>([])
		const [saleTabActivityDocs, setSaleTabActivityDocs] = useState<
			Array<ActivityItem>
		>([])
		const [filterBy, setFilterBy] = useState<FilterBy>({
			min: null,
			path: null,
		})
		const [sort, setSort] = useState<SortColumnOrNull>({
			column: "blockTimestamp",
			order: SortDirection.Descending,
		})
		const [showFilter, setShowFilter] = useState(true)
		const [nftTypes, setNftTypes] = useState<string[]>([])

		const [searchParams, setSearchParams] = useSearchParams()
		const currentParam =
			(searchParams?.get("type")?.toUpperCase() as ActivityTableTabsType) ||
			null
		const [tableSelectedTab, setTableSelectedTab] =
			useState<ActivityTableTabsType>(currentParam ?? "SALE")

		useEffect(() => {
			if (!collectionAddress || !collectionName) {
				return
			}

			flowty.scripts
				.getNftResourceIdentifiers(
					collectionAddress || "",
					collectionName || ""
				)
				.then((types: string[]) => {
					setNftTypes(types)
				})
		}, [collectionAddress, collectionName])

		const allActivityDocs: Array<ActivityItem | CollectionActivityItem> =
			useMemo(() => {
				switch (tableSelectedTab) {
					case "SALE":
						return saleTabActivityDocs.map(doc => ({
							...doc,
						}))
					case "LOAN":
						return loanTabActivityDocs.map(doc => ({
							...doc,
						}))
					case "RENTAL":
						return rentalTabActivityDocs.map(doc => ({
							...doc,
						}))
					default:
						return []
				}
			}, [
				saleTabActivityDocs,
				loanTabActivityDocs,
				rentalTabActivityDocs,
				tableSelectedTab,
			])

		const onTableChangeTabFn = (tab: ActivityTableTabsType): void => {
			if (tab === "LOAN" || tab === "RENTAL") {
				searchParams.set("type", tab.toLowerCase())
				setSearchParams(searchParams)
			} else {
				searchParams.delete("type")
				setSearchParams(searchParams)
			}
			setTableSelectedTab(tab)
			setFilterBy({ min: null, path: null })
			setSort({ column: "blockTimestamp", order: SortDirection.Descending })
		}

		const onSortChangeFn = (newSort: SortColumnOrNull): void => {
			setSort(newSort)
			setTablePage(1)
		}

		const onFilterChangeFn = (newFilter: FilterBy): void => {
			setSort({ column: "blockTimestamp", order: SortDirection.Descending })
			setFilterBy(newFilter)
			setTablePage(1)
		}

		useEffect(() => {
			const hasTypeParam =
				(searchParams?.get("type")?.toUpperCase() as ActivityTableTabsType) ||
				null

			if (!hasTypeParam) {
				setTableSelectedTab("SALE")
			} else if (hasTypeParam && hasTypeParam !== tableSelectedTab) {
				setTableSelectedTab(hasTypeParam)
			}
		}, [searchParams])

		useEffect(() => {
			if (!nftTypes || nftTypes.length === 0) {
				return
			}

			if (tableSelectedTab === "SALE") {
				subscribeToActivity({
					addressPath: "data.storefrontAddress",
					eventCollection: "storefrontEvents",
					filterActivityBy: filterBy,
					limit: DEFAULT_ACTIVITY_PAGE_SIZE * tablePage,
					listingCollection: "storefrontEvents",
					makerCb: docs => setSaleTabActivityDocs(docs),
					nftTypes,
					sort,
				})
			} else {
				subscribeToAllCollectionActivity({
					borrowerCb(docs) {
						tableSelectedTab === "RENTAL"
							? setRentalTabActivityDocs(docs)
							: setLoanTabActivityDocs(docs)
					},
					eventCollection:
						tableSelectedTab === "RENTAL" ? "rentalEvents" : undefined,
					filterActivityBy: filterBy,
					limit: DEFAULT_ACTIVITY_PAGE_SIZE * tablePage,
					nftTypes,
					sort,
				})
			}
		}, [tableSelectedTab, tablePage, filterBy, sort, nftTypes])

		const belowTableRef = useInfiniteScroll(
			{
				isLoading: false,
				onInfiniteScroll() {
					setTimeout(() => {
						setIsLoading(false)
					}, 2000)
					if (
						tablePage * DEFAULT_ACTIVITY_PAGE_SIZE ===
						allActivityDocs.length
					) {
						setTablePage(prev => prev + 1)
						setIsLoading(true)
					}
					Mixpanel.track("Inifinite Scroll Global Rentals Page")
				},
			},
			[tablePage, allActivityDocs]
		)

		return {
			allActivityDocs,
			belowTableRef,
			filterBy,
			isLoading,
			onFilterChangeFn,
			onSortChangeFn,
			onTableChangeTabFn,
			setShowFilter,
			showFilter,
			sort,
			tablePage,
			tableSelectedTab,
		}
	}
