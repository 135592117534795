import { CollectionType } from "../../../../../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import { ReactComponent as multiImage } from "../../../../../assets/multiImage.svg"
import { ReactComponent as singleImage } from "../../../../../assets/singleImage.svg"

const collectionTypeNames: Record<CollectionType, string> = {
	[CollectionType.SingleImage]: "Single Image",
	[CollectionType.MultiImage]: "Multi-Image",
}

export const collectionTypeOptions = [
	{
		description:
			"Create and manage a single image NFT collection. Customize collection metadata to highlight your unique creation.",
		disabled: false,
		image: singleImage,
		labelMapping: collectionTypeNames,
		name: "collectionType",
		pillContent: null,
		value: CollectionType.SingleImage,
	},
	{
		description: "Create and manage a multi-image NFT collection with varied..",
		disabled: true,
		image: multiImage,
		labelMapping: collectionTypeNames,
		name: "collectionType",
		pillContent: "Coming Soon",
		value: CollectionType.MultiImage,
	},
]
