import Countdown from "react-countdown"

interface Props {
	settleDeadline: Date
	className?: string
	onCountdownEnd?: () => void
}

const NftRepayExpiration = ({
	settleDeadline,
	className,
	onCountdownEnd,
}: Props) => (
	<div
		className={`nft-expiration flex justify-center items-center ${
			className || ""
		}`}
	>
		<div className='p-2 flex justify-center items-center nft-expiration-left'>
			<p className='text-bold ms-1'>{`Expiration Date ${settleDeadline.toLocaleDateString(
				"en-US"
			)}`}</p>
		</div>
		<div className='p-2 flex justify-center items-center nft-expiration-right'>
			<p className='text-bold ms-1 me-1'>Expires in </p>

			<Countdown date={settleDeadline} onComplete={onCountdownEnd} />
		</div>
	</div>
)

export default NftRepayExpiration
