import { FCLTransactionResult } from "flowty-common"
import { Err, getCatalogEntryForType, sendMutation } from "../utils/Utils"
import { TransferServiceTypes } from "./TransferServiceTypes"
import { Config } from "../types"
import { getTransferNftTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class TransferService {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	transferNft = async ({
		nftData,
		transferRecipient,
		nftProviderPathIdentifier,
		txAvailableCallback,
	}: TransferServiceTypes): Promise<FCLTransactionResult> => {
		if (!nftData) throw new Error("Flow NFT it's null")
		const { id, type, nftOwner } = nftData

		const catalogIdentifier = await getCatalogEntryForType(type, this.config)

		const txContent = getTransferNftTxn(
			this.config,
			nftData,
			!!catalogIdentifier
		)

		const providerArg = this.config.crescendo
			? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
			: fcl.arg(nftProviderPathIdentifier, t.String)

		const txArguments = [
			fcl.arg(id.toString(), t.UInt64),
			fcl.arg(transferRecipient, t.Address),
			fcl.arg(nftOwner, t.Address),
			providerArg,
		]

		if (catalogIdentifier) {
			txArguments.push(fcl.arg(catalogIdentifier, t.String))
		} else {
			txArguments.push(fcl.arg(nftData.type, t.String))
		}

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
