import { TimeSegmentsTypes } from "../hooks/useDropPage"

export const getCountdownValues = (countDown: number): TimeSegmentsTypes => {
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
	const hours = Math.floor(
		(countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	)
	const mins = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
	const secs = Math.floor((countDown % (1000 * 60)) / 1000)

	return days >= 1
		? {
				days: days >= 0 ? days : 0,
				hours: hours >= 0 ? hours : 0,
		  }
		: hours >= 1
		? {
				hours: hours >= 0 ? hours : 0,
				mins: mins >= 0 ? mins : 0,
				secs: secs >= 0 ? secs : 0,
		  }
		: {
				mins: mins >= 0 ? mins : 0,
				secs: secs >= 0 ? secs : 0,
		  }
}
