import { Oval as OvalLoader } from "react-loader-spinner"

interface Props {
	className?: string
}

const LayoutLoader = ({ className }: Props) => (
	<div
		className={`layout-loader flex justify-center items-center ${
			className || ""
		}`}
	>
		<OvalLoader color='#04E5A3' height={80} width={80} />
	</div>
)

export default LayoutLoader
