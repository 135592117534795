import React, { useEffect, useRef } from "react"
import { FlowtyNavbarProps } from "../types/NavbarTypes"
import { NavBarLogoAndLinks } from "./NavBarLogoAndLinks"
import { NavBarSignInAndNotifications } from "./NavBarSignInAndNotifications"

export const NavbarDesktop: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	authLoading,
	logUser,
	selectedItem,
	notificationData,
	registerHeight,
	loggedUserAddress,
	disconnectWallet,
	childAccounts,
	isDapper,
	user,
}) => {
	const baseStyles = `${bgColor} bg-darkBg flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-40 font-black text-white py-[18px]`

	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = (): void => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = (): void => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	return (
		<div className={baseStyles} ref={navbarRef} data-testid='navbar-component'>
			<nav
				className={`px-12 flex justify-between items-center w-full gap-[16px] relative h-[40px]`}
			>
				<NavBarLogoAndLinks selectedItem={selectedItem} />
				<NavBarSignInAndNotifications
					loadingNotifications={notificationData.loadingNotifications}
					hasMore={notificationData.hasMore}
					fetchMoreNotifications={notificationData.fetchMoreNotifications}
					notifications={notificationData.notifications}
					queueNotification={notificationData.queueNotification}
					isLoading={authLoading}
					isLogged={isLogged}
					logUser={logUser}
					loggedUserAddress={loggedUserAddress || ""}
					disconnectWallet={disconnectWallet}
					childAccounts={childAccounts}
					isDapper={isDapper}
					user={user}
				/>
			</nav>
		</div>
	)
}
