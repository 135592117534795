import * as fcl from "@onflow/fcl"
import { ACCESS_API } from "./settings"

export const connectDapperWallet = async (): Promise<void> => {
	const endpoint = ACCESS_API.includes("mainnet")
		? "https://accounts.meetdapper.com/fcl/authn-restricted"
		: "https://staging.accounts.meetdapper.com/fcl/authn-restricted"
	fcl.config().put("discovery.wallet", endpoint)
	fcl.config().put("discovery.wallet.method", "POP/RPC")

	await fcl.authenticate()
}
