import { useField } from "formik"
import React, { useMemo, useState } from "react"
import { FlowtyNumericInput } from "../../../../../../../FlowtyNumericInput"
import { ReactComponent as ChevronDownIcon } from "../../../../../../assets/chevron-down.svg"
import { FlowIconWithTooltip } from "./components/FlowIconWithTooltip"

interface CustomizeNumberDropdownProps {
	inputName: string
	flowtyMarketplaceFee?: number | null
}

export const CustomizeNumberDropdown: React.FC<
	CustomizeNumberDropdownProps
> = ({ inputName, flowtyMarketplaceFee }) => {
	const [isRoyaltyOpen, setIsRoyaltyOpen] = useState(false)
	const [isFlowtyFeeOpen, setIsFlowtyFeeOpen] = useState(false)

	const [fieldValue] = useField(inputName)
	const [exampleSalePrice] = useField("exampleSalePrice")

	const FLOWTY_LAUNCHPAD_FEE = 0.1
	const FLOWTY_MARKETPLACE_FEE = flowtyMarketplaceFee ?? 0.02
	const MARKETPLACE_EXAMPLE_SALE_PRICE = exampleSalePrice.value ?? 0

	const numberValue = fieldValue?.value ? fieldValue.value : 0

	const flowtyFee =
		inputName === "mintPrice"
			? numberValue * FLOWTY_LAUNCHPAD_FEE
			: MARKETPLACE_EXAMPLE_SALE_PRICE * FLOWTY_MARKETPLACE_FEE

	const royaltyFee = (MARKETPLACE_EXAMPLE_SALE_PRICE * numberValue) / 100

	const sellerReceived =
		MARKETPLACE_EXAMPLE_SALE_PRICE - (flowtyFee + royaltyFee)

	const valueReceived = useMemo(() => {
		if (inputName === "mintPrice") {
			return numberValue - flowtyFee
		}

		if (inputName === "royalty") {
			return royaltyFee
		}
	}, [inputName, numberValue, exampleSalePrice])

	return (
		<div
			data-testid={`${inputName}-dropdown`}
			className='flex flex-col gap-[8px] font-montserrat text-sm 3xl:text-lg'
		>
			<div className='flex flex-row justify-between items-center'>
				<span className='font-normal text-white'>
					{inputName === "mintPrice" ? "Mint Price" : "Example Sale Price"}
				</span>
				<div className='flex flex-row items-center'>
					<FlowIconWithTooltip name={inputName} />
					{inputName === "mintPrice" ? (
						<span className='ml-1 font-bold	text-white'>
							{numberValue.toFixed(2)}
						</span>
					) : (
						<FlowtyNumericInput
							name='exampleSalePrice'
							className={`h-[26.667px] 3xl:h-[40px] max-w-[52px] 3xl:max-w-[102px] text-[9.333px] 3xl:text-sm px-2 3xl:px-3 ml-1 flex justify-center items-end rounded-lg border bg-[#ffffff0a] text-right text-white font-bold border-[#343A40]`}
							placeholder={"100.00"}
						/>
					)}
				</div>
			</div>
			{inputName === "royalty" && (
				<div className='flex flex-col'>
					<button
						className='flex flex-row justify-between items-center'
						onClick={() => setIsRoyaltyOpen(prev => !prev)}
					>
						<div className='flex items-center gap-x-2'>
							<ChevronDownIcon
								className={`w-[9.333px] h-[5.333px] 3xl:w-[14px] 3xl:h-2 ${
									isRoyaltyOpen ? "transform rotate-180" : ""
								}`}
							/>
							<span className='font-normal text-white'>
								{`Creator Royalty (${numberValue.toFixed(2)}%)`}
							</span>
						</div>

						<div className='flex flex-row items-center'>
							<span className='mr-1 font-bold text-white'>-</span>
							<FlowIconWithTooltip name={"royalty"} />
							<span
								data-testid='royalty-fee'
								className='ml-1 font-bold text-white '
							>
								{royaltyFee.toFixed(2)}
							</span>
						</div>
					</button>
					{isRoyaltyOpen && (
						<div className='text-neutral-500 font-montserrat pt-1.5 font-[400]'>
							Transferred directly to the creator of an NFT collection. This is
							not a Flowty fee.{" "}
							<a
								href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
								target='_blank'
								className='text-[#04E5A3] font-montserrat underline'
								rel='noreferrer'
							>
								Learn More
							</a>
						</div>
					)}
				</div>
			)}

			<div className='flex flex-col'>
				<button
					className='flex flex-row justify-between items-center'
					onClick={() => setIsFlowtyFeeOpen(prev => !prev)}
				>
					<div className='flex items-center gap-x-2'>
						<ChevronDownIcon
							className={`w-[9.333px] h-[5.333px] 3xl:w-[14px] 3xl:h-2 ${
								isFlowtyFeeOpen ? "transform rotate-180" : ""
							}`}
						/>
						<span className='font-normal text-white'>
							{inputName === "mintPrice"
								? `Flowty Launchpad Fee (${Number(
										FLOWTY_LAUNCHPAD_FEE * 100
								  ).toFixed(2)}%)`
								: `Flowty Marketplace Fee (${Number(
										FLOWTY_MARKETPLACE_FEE * 100
								  ).toFixed(2)}%)`}
						</span>
					</div>
					<div className='flex flex-row items-center'>
						<span className='mr-1 font-bold text-white'>-</span>
						<FlowIconWithTooltip name={`${inputName}-flowty-fee`} />
						<span
							data-testid={`${inputName}-flowty-fee`}
							className='ml-1 font-bold text-white'
						>
							{flowtyFee.toFixed(2)}
						</span>
					</div>
				</button>
				{isFlowtyFeeOpen && (
					<div className='text-neutral-500 font-montserrat pt-1.5 font-[400]'>
						{inputName === "mintPrice"
							? "Behind the scenes we are building a new, safe, metadata standard-compliant smart contract for you that will be immediately compatible with Flowty's marketplace."
							: "A fee charged by Flowty for providing the services on the platform."}{" "}
						<a
							href={
								inputName === "mintPrice"
									? "https://docs.flowty.io/reference/nft-launchpad/launchpad-fees"
									: "https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees"
							}
							target='_blank'
							className='text-[#04E5A3] font-montserrat underline'
							rel='noreferrer'
						>
							Learn More
						</a>
					</div>
				)}
			</div>

			<div className='border-gradient h-[1px]'></div>
			{inputName === "royalty" && (
				<div className='flex justify-between items-center'>
					<div className='font-normal text-white'>Seller Receives</div>
					<div className='flex items-center'>
						<FlowIconWithTooltip name={`${inputName}-seller-received`} />
						<span
							data-testid={`${inputName}-seller-received`}
							className='ml-1 font-bold text-white'
						>
							{sellerReceived.toFixed(2)}
						</span>
					</div>
				</div>
			)}
			<div className='flex justify-between items-center'>
				<div className=' font-bold text-white'>Creator (You) Receives</div>
				<div className='flex items-center'>
					<FlowIconWithTooltip name={`${inputName}-value-received`} />
					<span
						data-testid={`${inputName}-value-received`}
						className='ml-1 font-bold text-white '
					>
						{valueReceived?.toFixed(2)}
					</span>
				</div>
			</div>
		</div>
	)
}
