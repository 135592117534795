import {
	FlowtyFooter,
	FlowtyTeam,
	GettingStarted,
	LandingPageHead,
	LandingTopSales,
	TwitterFeed,
	WhyFlowty,
} from "ds-flowty"
import { inject, observer } from "mobx-react"
import React, { useEffect, useMemo } from "react"
import { PageHeader } from "../components/PageHeader"
import { useFetchTopAndRecentSales } from "../hooks/useFetchTopAndRecentSales"
import { subscribeEmailToNotification } from "../services/EmailService"
import { AuthStoreProp } from "../stores/AuthStore"
import { connectWallet } from "../util/ConnectWallet"
import { actions as Mixpanel } from "../util/Mixpanel"
import Layout from "../layouts/Layout"
import { useFetchFlipSideData } from "../hooks/useFetchFlipSideData"
import { useFetchTweets } from "../hooks/useFetchTweets"

const HomeScreen: React.FunctionComponent<AuthStoreProp> = ({ authStore }) => {
	const {
		recentBigSaleData,
		topSalesData,
		isLoading: isLoadingTopSalesData,
	} = useFetchTopAndRecentSales()
	const { data, isLoading: isLoadingFlowtyStats } = useFetchFlipSideData()

	const { tweetIds, isLoading: isLoadingTweets } = useFetchTweets()

	const isUserLoggedIn: boolean = useMemo(() => {
		return !!authStore?.loggedUser?.loggedIn
	}, [authStore?.loggedUser])

	useEffect(() => {
		Mixpanel.track("Landing Page Visited")
	}, [])

	const mixPanelFn = (eventName: string, properties?: unknown) => {
		Mixpanel.track(eventName, properties)
	}

	const metaTitle =
		"Flowty: Built for Collectors, by Collectors | Secondary NFT Marketplace | NFT Rentals | NFT-Backed Loans"

	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."

	const metaImage = "https://www.flowty.io/icons/icon192.png"

	return (
		<Layout landingPage={true}>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<LandingPageHead />
			<LandingTopSales
				isLoading={isLoadingTopSalesData}
				topSalesData={topSalesData}
				recentBigSaleData={recentBigSaleData}
			/>
			<WhyFlowty
				topSalesData={data}
				isLoadingTopSalesData={isLoadingFlowtyStats}
			/>
			<GettingStarted
				connectWallet={() => connectWallet()}
				isUserLoggedIn={isUserLoggedIn}
			/>
			<FlowtyTeam />
			<TwitterFeed tweetIds={tweetIds} isLoading={isLoadingTweets} />
			<FlowtyFooter
				subscribeEmailToNotification={subscribeEmailToNotification}
				mixPanelFn={mixPanelFn}
			/>
		</Layout>
	)
}

export default inject("rootStore", "authStore")(observer(HomeScreen))
