import { PropsWithChildren } from "react"

type LoadingSpinnerProps = PropsWithChildren<{
	size?: number
	borderThickness?: number
}>

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
	size = 4,
	borderThickness = 4,
	children,
}) => {
	return (
		<div>
			<div
				className={`animate-spin inline-block w-${size} h-${size} border-${borderThickness} border-current border-t-transparent rounded-full`}
				aria-label='loading'
			/>
			{children}
		</div>
	)
}
