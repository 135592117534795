/* eslint-disable import/no-unused-modules */
import React from "react"
import { DisplayImage } from "../../../../../../FlowtyCreatorHub/components/common/CreatorHubDisplayImage/CreatorHubDispalyImage"
import { FLOWTY_QUICKNODE_IPFS_URL } from "../../../../../../utils/constants"
import { useField } from "formik"
import { FileType } from "../../../../../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"

export const CollectionImagesConfirmation: React.FC = () => {
	const [dropThumbnailImage] = useField<FileType>("dropThumbnailImage")
	const [nftThumbnailImage] = useField<FileType>("nftThumbnailImage")
	const [nftImage] = useField<FileType>("nftImage")
	const [nftBannerImage] = useField<FileType>("nftBannerImage")

	return (
		<div className='flex flex-col md:hidden gap-6'>
			<div className='text-white text-[18px] font-black'>Images</div>
			<div className='flex flex-wrap'>
				<div className='w-1/2'>
					<DisplayImage
						imageType='NFT Image'
						imageSrc={
							nftImage.value?.fileCid
								? `${FLOWTY_QUICKNODE_IPFS_URL}${nftImage.value?.fileCid}`
								: nftImage.value?.fileBase64 ?? ""
						}
						className='w-[80px] h-[80px]'
					/>
				</div>
				<div className='w-1/2'>
					<DisplayImage
						imageType='Thumbnail Image'
						imageSrc={
							nftThumbnailImage.value?.fileCid
								? `${FLOWTY_QUICKNODE_IPFS_URL}${nftThumbnailImage.value?.fileCid}`
								: nftThumbnailImage.value?.fileBase64 ?? ""
						}
						className='w-[80px] h-[80px]'
					/>
				</div>
			</div>
			<div className='w-full'>
				<DisplayImage
					imageType='Banner Image'
					imageSrc={
						nftBannerImage.value?.fileCid
							? `${FLOWTY_QUICKNODE_IPFS_URL}${nftBannerImage.value?.fileCid}`
							: nftBannerImage.value?.fileBase64 ?? ""
					}
					className='h-[80px] w-full xs:w-[320px]'
				/>
			</div>
			<div>
				<DisplayImage
					imageType='Drop Image'
					imageSrc={
						dropThumbnailImage.value?.fileCid
							? `${FLOWTY_QUICKNODE_IPFS_URL}${dropThumbnailImage.value?.fileCid}`
							: dropThumbnailImage.value?.fileBase64 ?? ""
					}
					className='w-[80px] h-[80px]'
				/>
			</div>
		</div>
	)
}
