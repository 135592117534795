interface Props {
	address: string
}

export const ProfileLink: React.FunctionComponent<Props> = ({ address }) => {
	return (
		<a
			onClick={event => event.stopPropagation()}
			href={`/user/${address}`}
			target='_blank'
			rel='noreferrer'
			className='text-[#04E5A3] font-bold'
		>
			{address}
		</a>
	)
}
