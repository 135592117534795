import { FlowtyFilterMenu, useBreakpoint } from "ds-flowty"
import { nftTypeAndIdToLocationData } from "flowty-common"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../../../../../assets/media/loadingArrow.svg"
import { RentalActivityFilter } from "../../../../../components/Activity/Filters"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { ActivityItem } from "../../../../../components/Shared/ActivityTableFields"
import SortableTable, {
	SortColumnOrNull,
} from "../../../../../components/SortableTable"
import collectionRentalActivityFields from "../../../../../components/SortableTable/PlatformActivity/collectionRentalActivityFields"
import {
	FilterBy,
	RentalEventType,
} from "../../../../../components/UserProfile/Tables/Storefront/AllActivity/types"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import { useCollectionPage } from "../../../hooks/useCollectionPage"
import { DEFAULT_ACTIVITY_PAGE_SIZE } from "../hooks/useCollectionActivityTables"

interface CollectionRentalActivityScreenProps {
	filterBy: FilterBy
	isLoading: boolean
	onFilterChangeFn: (filter: FilterBy) => void
	onSortChangeFn: (sort: SortColumnOrNull) => void
	sort: SortColumnOrNull
	allActivityDocs: ActivityItem[]
	belowTableRef: (node: HTMLElement | null) => void
	showFilter: boolean
	setShowFilter: (show: boolean) => void
	tablePage: number
}

export const CollectionRentalActivityScreen: React.FunctionComponent<
	CollectionRentalActivityScreenProps
> = ({
	filterBy,
	isLoading,
	onFilterChangeFn,
	onSortChangeFn,
	sort,
	allActivityDocs,
	belowTableRef,
	showFilter,
	setShowFilter,
	tablePage,
}) => {
	const navigate = useNavigate()
	const { collectionName } = useCollectionPage()

	const [selectedRow, setSelectedRow] = useState<ActivityItem | null>(null)

	const holdThreshold = 500

	const [touchStartTimestamp, setTouchStartTimestamp] = useState(0)
	const [touchEndTimestamp, setTouchEndTimestamp] = useState(0)

	const breakpoint = useBreakpoint()

	useEffect(() => {
		// Only proceed if in a touch device environment based on breakpoint
		if (breakpoint === "xs" || breakpoint === "mobile") {
			if (touchStartTimestamp && touchEndTimestamp) {
				const touchDuration = touchEndTimestamp - touchStartTimestamp

				if (touchDuration >= holdThreshold) {
				} else {
					if (selectedRow) {
						const ld = nftTypeAndIdToLocationData(
							selectedRow?.data?.nftType ||
								selectedRow?.data?.typeAndIDOffer?.nftType ||
								"",
							selectedRow?.data?.nftID?.toString() ||
								selectedRow?.data?.typeAndIDOffer?.nftID ||
								""
						)

						navigate(
							`/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`
						)
					}
				}

				setTouchStartTimestamp(0)
				setTouchEndTimestamp(0)
			}
		} else {
			if (selectedRow) {
				const ld = nftTypeAndIdToLocationData(
					selectedRow?.data?.nftType ||
						selectedRow?.data?.typeAndIDOffer?.nftType ||
						"",
					selectedRow?.data?.nftID?.toString() ||
						selectedRow?.data?.typeAndIDOffer?.nftID ||
						""
				)

				navigate(
					`/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`
				)
			}
		}
	}, [
		touchStartTimestamp,
		touchEndTimestamp,
		selectedRow,
		breakpoint,
		navigate,
		holdThreshold,
	])

	useEffect(() => {
		// Handler for touch start
		const handleTouchStart = (_: TouchEvent): void => {
			setTouchStartTimestamp(Date.now())
			Mixpanel.track("TOUCH_START")
		}

		// Handler for touch end
		const handleTouchEnd = (_: TouchEvent): void => {
			setTouchEndTimestamp(Date.now())
			Mixpanel.track("TOUCH_END")

			// You can calculate the duration of the touch here if needed
			const touchDuration = Date.now() - touchStartTimestamp
			Mixpanel.track("TOUCH_DURATION MS:", touchDuration)
		}

		// Add event listeners
		window.addEventListener("touchstart", handleTouchStart)
		window.addEventListener("touchend", handleTouchEnd)

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener("touchstart", handleTouchStart)
			window.removeEventListener("touchend", handleTouchEnd)
		}
	}, [])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`${collectionName} Rental Activity Page Filtered`, {
				filter: filterBy,
			})
		}
		Mixpanel.track(`${collectionName} Activity page - Rental tab visited`, {
			tab: "Activity",
		})
	}, [filterBy])

	const rentalFilterOptions = [
		{ label: "All", value: "" },
		{ label: "Listed", value: "RENTAL_LISTED" },
		{ label: "Delisted", value: "RENTAL_DESTROYED" },
		{ label: "Rented", value: "RENTAL_RENTED" },
		{ label: "Returned", value: "RENTAL_RETURNED" },
		{ label: "Settled", value: "RENTAL_SETTLED" },
		{ label: "Expired", value: "EXPIRED" },
	]

	const [selectedFilter, setSelectedFilter] = useState(
		rentalFilterOptions[0].value
	)

	const updateSelectedFilter = (value: string): void => {
		setSelectedFilter(value)
		onFilterChangeFn({
			max: "",
			min: value as RentalEventType,
			path: "state",
		})
	}

	return (
		<div>
			<RentalActivityFilter
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={onFilterChangeFn}
			/>

			<div className='flex py-2 md:py-3 mb-[14px] md:mb-0'>
				<FlowtyFilterMenu
					fields={rentalFilterOptions}
					selectedField={selectedFilter}
					updateSelectedField={updateSelectedFilter}
				/>
			</div>

			<SortableTable
				onClickRow={({ item }) => {
					setSelectedRow(item)
				}}
				fields={collectionRentalActivityFields}
				items={allActivityDocs}
				onSort={onSortChangeFn}
				initialSortConfig={sort}
				stickyHeaders
				composeRowHref={item => {
					const ld = nftTypeAndIdToLocationData(
						item?.data?.nftType || item?.data?.typeAndIDOffer?.nftType || "",
						item?.data?.nftID?.toString() ||
							item?.data?.typeAndIDOffer?.nftID ||
							""
					)

					return `/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`
				}}
			/>
			<div ref={belowTableRef}>
				{isLoading ? (
					<div className='h-10 w-full flex justify-center items-center'>
						<h2 className='justify-center items-center flex flex-row animate-pulse'>
							<RefreshArrow />
							Loading...
						</h2>
					</div>
				) : (
					tablePage * DEFAULT_ACTIVITY_PAGE_SIZE === allActivityDocs.length && (
						<ScrollToLoadMsg />
					)
				)}
			</div>
		</div>
	)
}
