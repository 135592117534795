import {
	FlowNFT,
	getNFTIdentifier,
	nftTypeAndIdToLocationData,
	PublicAccount,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useCallback, useEffect, useState } from "react"
import { Alert } from "../../../../components/Alert/Alert"
import { NftListTabWrapper } from "../../../../components/NftListComponents/NftListTabWrapper/NftListTabWrapper"
import { flowty } from "../../../../config/config"
import { useFlowtyModalsContext } from "../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { NftDetails } from "../../../../contexts/FlowtyModalsContext/types/FlowtyModalsContextTypes"
import { getNftStoragePath } from "../../../../services/firestore/NftCollectionService"
import { fetchSpecificFlowNFT } from "../../../../services/firestore/nftIInfo"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

interface UserCollectionTabProps extends AuthStoreProp {
	profile: PublicAccount | null
	isPublic?: boolean
}

const UserCollectionTabComponent: React.FC<UserCollectionTabProps> = ({
	profile,
	authStore,
	isPublic = false,
}) => {
	const [showAlert, setShowAlert] = useState<boolean>(false)

	const [addressToIcon, setAddressToIcon] = useState<{
		[key: string]: string
	}>({})

	const { selectCard } = useFlowtyModalsContext()

	useEffect(() => {
		const copy = { ...addressToIcon }
		if (!profile?.childAccounts) return

		Object.entries(profile.childAccounts).forEach(([_, child]) => {
			if (!child.display?.thumbnail) {
				delete copy[child.address]
				return
			}

			copy[child.address] = child.display.thumbnail
		})
		setAddressToIcon(copy)
	}, [])

	const handleSelectCard = useCallback(
		async (selected: NftDetails): Promise<void> => {
			const copy = { ...selected }

			const firebaseFlowNFT = (await fetchSpecificFlowNFT({
				nftId: String(copy.nftID),
				nftType: copy.nftType,
			})) as FlowNFT

			const storagePath = await getNftStoragePath(firebaseFlowNFT)

			const ld = nftTypeAndIdToLocationData(
				firebaseFlowNFT.type,
				firebaseFlowNFT.id
			)
			const identifier = getNFTIdentifier(ld)

			const checkOwnsSelected = async () => {
				if (!firebaseFlowNFT.owner) return false
				try {
					const validateResponse = await flowty.scripts.validateUserOwnsNft(
						firebaseFlowNFT.owner || "",
						firebaseFlowNFT.id.toString(),
						firebaseFlowNFT.contractName,
						firebaseFlowNFT.contractAddress,
						ld.resourceName,
						storagePath
					)

					return validateResponse
				} catch (err) {
					Mixpanel.track("ERROR_VALIDATING_USER_OWNS_NFT", { err })
					return false
				}
			}

			const userOwnsSelected = await checkOwnsSelected()

			if (userOwnsSelected || isPublic) {
				selectCard({
					selected: {
						contractAddress: selected.contractAddress,
						contractName: selected.contractName,
						nftID: selected.nftID,
						nftType: selected.nftType,
					},
				})
			} else {
				setShowAlert(true)
				await flowty.api.refreshMetadata(identifier)
				await setTimeout(() => {
					setShowAlert(false)
				}, 4000)
			}
		},
		[isPublic]
	)
	const address = isPublic
		? profile?.walletAddress
		: authStore?.loggedUser?.addr

	return (
		<>
			<div className='z-0 flex flex-wrap justify-end'>
				{showAlert && (
					<Alert
						className='flex flex-col items-center justify-center w-full p-3 z-10'
						variant='danger'
						onClose={(): void => {
							setShowAlert(false)
						}}
					>
						<h2>Missing Item</h2>
						<p>
							Our system does not identify you as the owner of this nft, please
							be sure to refresh your account.
						</p>
					</Alert>
				)}
			</div>
			<NftListTabWrapper
				handleSelectCard={handleSelectCard}
				address={address || ""}
			/>
		</>
	)
}

export const UserCollectionTab = inject("authStore")(
	observer(UserCollectionTabComponent)
)
