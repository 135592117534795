import { ReactComponent as FLOWIcon } from "../../../../assets/tokens/flow.svg"

interface CreatorHubDisplayFieldProps {
	title: string
	details: string
	isPrice?: boolean
}
export const CreatorHubDisplayField: React.FC<CreatorHubDisplayFieldProps> = ({
	details,
	title,
	isPrice,
}) => {
	return (
		<div className='flex flex-col gap-[2px] w-full '>
			<div className='text-white leading-normal text-[14px] font-semibold'>
				{title}
			</div>
			<div className='flex text-white font-montserrat text-[12px] font-normal items-center leading-normal'>
				{isPrice ? (
					<div className='flex items-center flex-end gap-[2px]'>
						<FLOWIcon className='w-[16px] h-[16px] mr-1' />
						<span className='text-[14px] font-semibold'>{details}</span>
						<span className='text-[14px] font-bold'>FLOW</span>
					</div>
				) : (
					details
				)}
			</div>
		</div>
	)
}
