import React, { useContext } from "react"
import { TOPSHOT_ADDRESS } from "../../../util/settings"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { FilterPill } from "./FilterPill"

const TraitTag: React.FC<{
	trait: string
	value: string
	handleRemoval: () => void
}> = ({ trait, value, handleRemoval }) => {
	const text = `${trait} ${value != "" ? value : "Any"}`

	return (
		<FilterPill
			onClick={handleRemoval}
			text={text.replace(trait, "")}
			label={`${trait}:`}
		/>
	)
}

/* eslint-disable import/no-unused-modules */
export const FilterTags: React.FC = () => {
	const isCollectionPage = window.location.pathname.includes("/collection/")
	const { filters, setFilters } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const collections = Object.keys(filters)

	return (
		<>
			{collections.map((collection, index) => {
				const traits = Object.keys(filters[collection])

				return (
					<div
						className='contents flex-wrap gap-[4px]'
						key={`${collection}.${index}.${collection}`}
					>
						{!isCollectionPage && (
							<div
								className='fade-in'
								data-testid={`collections-${collection.split(".")[1]}`}
							>
								<FilterPill
									text={collection.split(".")[1]}
									label='Collections:'
									onClick={() => {
										const copyFilters = { ...filters }
										delete copyFilters[collection]
										setFilters(copyFilters)
									}}
								/>
							</div>
						)}
						{traits.map(traitKey => {
							const traitsValues = Object.keys(
								filters?.[collection]?.[traitKey]
							)

							const isTopShotCollection =
								collection === `${TOPSHOT_ADDRESS}.TopShot`
							const isLocked = traitKey === "Locked" && isTopShotCollection

							return (
								<>
									{traitsValues.map(traitValue => {
										let traitValueLabel = traitValue
										if (isLocked) {
											traitValueLabel =
												traitValue === "true" ? "Locked" : "Not Locked"
										}

										const handleRemoval = (): void => {
											const copyFilters = { ...filters }
											traitsValues?.length === 1
												? delete copyFilters[collection][traitKey]
												: delete copyFilters[collection][traitKey][
														traitValue || ""
												  ]
											setFilters(copyFilters)
										}
										return (
											<div key={traitKey} className='fade-in'>
												<TraitTag
													trait={isLocked ? "Lock Status" : traitKey}
													value={traitValueLabel}
													handleRemoval={handleRemoval}
												/>
											</div>
										)
									})}
								</>
							)
						})}
					</div>
				)
			})}
		</>
	)
}
