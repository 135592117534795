import { inject, observer } from "mobx-react"
import React, { createContext, useContext, useState } from "react"
import { AuthStoreProp } from "../stores/AuthStore"
import { ViewSetting } from "flowty-common"

const defaultValues: NftDisplayViewContextValues = {
	setView() {},
	view: "large" as ViewSetting,
}

interface NftDisplayViewContextValues {
	setView: (val: ViewSetting) => void
	view: ViewSetting
}
const NftDisplayViewContext =
	createContext<NftDisplayViewContextValues>(defaultValues)

interface NftDisplayViewProviderProps extends AuthStoreProp {
	children: React.ReactNode
}

const NftDisplayViewProvider: React.FC<NftDisplayViewProviderProps> = ({
	authStore,
	children,
}) => {
	const [view, setView] = useState<ViewSetting>(
		(authStore?.loggedUser?.preferredCardSize as ViewSetting) ||
			("large" as ViewSetting)
	)
	const values = {
		setView,
		view,
	}

	return (
		<NftDisplayViewContext.Provider value={values}>
			{children}
		</NftDisplayViewContext.Provider>
	)
}

const NftDisplayViewProviderAuthStore = inject("authStore")(
	observer(NftDisplayViewProvider)
)

const useNftDisplayViewContext = () => useContext(NftDisplayViewContext)

export {
	NftDisplayViewProviderAuthStore as NftDisplayViewProvider,
	useNftDisplayViewContext,
}
