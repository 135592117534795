/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { subscribeToMyRentals } from "../../../../services/firestore/ActivityService"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import SortableTable from "../../../SortableTable"
import { userOwnerTableFields } from "./UserOwnerTableFields"
import { userRenterTableFields } from "./UserRenterTableFields"
import { composeRentalListingPath } from "../../../../routes"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { defaultNftPageSize } from "../../../../util/settings"
import { ScrollToLoadMsg } from "../../../Loaders/ScrollToLoadMsg"
import { UserRentalTableFilters } from "../../Filters/UserRentalTableFilters"

interface Props extends AuthStoreProp {
	asOwner?: boolean
}
const UserRentalTable: React.FunctionComponent<Props> = ({
	authStore,
	asOwner = false,
}) => {
	const [prevLength, setPrevLength] = useState(0)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [showFilter, setShowFilter] = useState(true)
	const [filterBy, setFilterBy] = useState<any>({
		max: "",
		min: "",
		path: "",
	})
	const [userRenterDocs, setUserRenterDocs] = useState<Array<any>>([])

	const navigate = useNavigate()

	const listingLimit = useMemo(() => page * defaultNftPageSize, [page])
	const userRenterDocsLength = useMemo(
		() => userRenterDocs.length,
		[userRenterDocs]
	)

	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: loading,
			onInfiniteScroll() {
				if (!loading) {
					setPage(page + 1)
					setPrevLength(userRenterDocsLength)
					Mixpanel.track("Inifinite Scroll Rentals Activity Table")
				}
			},
		},
		[userRenterDocsLength]
	)
	useEffect((): any => {
		if (prevLength === userRenterDocsLength && userRenterDocsLength) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [prevLength, userRenterDocsLength])

	useEffect(() => {
		if (authStore?.loggedUser?.addr) {
			return subscribeToMyRentals({
				accountIDs: authStore?.getAccountSummaryAddresses() || [],
				accountId: authStore?.loggedUser?.addr,
				asOwner,
				cb(docs) {
					setUserRenterDocs(docs)
				},
				filterBy,
				limit: listingLimit,
			})
		}
		return () => {}
	}, [listingLimit, authStore?.loggedUser, filterBy])

	useEffect(() => {
		setPage(1)
	}, [filterBy])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Profile Rentals Activity Page Filtered`, {
				filter: filterBy,
				user: authStore?.loggedUser,
			})
		}
	}, [filterBy.path])

	return (
		<div className=' w-full flex flex-col '>
			<UserRentalTableFilters
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={setFilterBy}
				isRenter={!asOwner}
			/>
			<button
				className='flex justify-center w-20 relative self-end -top-24 float-right bg-blue-500 text-white active:bg-blue-600 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-4 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
				type='button'
				onClick={() => {
					setShowFilter(!showFilter)
				}}
			>
				<span>Filter</span>
			</button>
			<div className='relative -top-16 '>
				<SortableTable
					onClickRow={({ item }) =>
						item?.listingResourceID &&
						navigate(composeRentalListingPath(item.listingResourceID))
					}
					fields={
						asOwner
							? userOwnerTableFields(navigate)
							: userRenterTableFields(navigate)
					}
					items={userRenterDocs}
					stickyHeaders
					composeRowHref={item =>
						composeRentalListingPath(item.listingResourceID)
					}
				/>
			</div>
			{loading ? (
				<div />
			) : (
				<>
					<ScrollToLoadMsg />
					<div ref={bottomOfPageRef} />
				</>
			)}
		</div>
	)
}

export default inject("authStore")(observer(UserRentalTable))
