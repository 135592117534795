import React from "react"
import FlowtyLink from "../FlowtyLink"
import { flowtyDiscordLink } from "flowty-common"
import { TransactionFollowBox } from "../../../../TransactionFollowBox"

interface TransactionFailureProps {
	transactionID?: string | null
	isMainnet: boolean
}

export const TransactionFailure: React.FunctionComponent<
	TransactionFailureProps
> = ({ transactionID, isMainnet }) => {
	return (
		<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<div className='flex flex-col justify-center items-center gap-[8px]'>
				<svg
					className='w-[7.75rem] h-[7.75rem]'
					width='124'
					height='125'
					viewBox='0 0 124 125'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M61.9999 112.1C89.3932 112.1 111.6 89.8933 111.6 62.5C111.6 35.1067 89.3932 12.9 61.9999 12.9C34.6066 12.9 12.3999 35.1067 12.3999 62.5C12.3999 89.8933 34.6066 112.1 61.9999 112.1ZM53.984 45.716C51.5627 43.2947 47.6371 43.2947 45.2158 45.716C42.7946 48.1372 42.7946 52.0628 45.2158 54.4841L53.2318 62.5L45.2158 70.516C42.7946 72.9372 42.7946 76.8628 45.2158 79.2841C47.6371 81.7053 51.5627 81.7053 53.984 79.2841L61.9999 71.2681L70.0158 79.2841C72.4371 81.7053 76.3627 81.7053 78.784 79.2841C81.2052 76.8628 81.2052 72.9372 78.784 70.516L70.768 62.5L78.784 54.4841C81.2052 52.0628 81.2052 48.1372 78.784 45.716C76.3627 43.2947 72.4371 43.2947 70.0158 45.716L61.9999 53.7319L53.984 45.716Z'
						fill='#FF6969'
					/>
				</svg>
				<p className='text-white text-[16px] font-semibold text-center'>
					Transaction Failed
				</p>
			</div>
			<div className='flex flex-col gap-[8px] h-[100px]'>
				<div className='text-white text-center text-[14px]'>
					Your transaction failed. Please try again. If you continue to have
					issues, please open a support in{" "}
					<FlowtyLink text='Discord' href={flowtyDiscordLink} />
				</div>
				<TransactionFollowBox
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
			</div>
		</div>
	)
}
