import { ReactComponent as Arrow } from "../../../assets/arrow.svg"
import { ReactComponent as Book } from "../../../assets/book.svg"
import { steps } from "../Carousel/Carousel"
import CurrentStepButton from "../CurrentStepButton"
import { useState } from "react"
import { Tab } from "@headlessui/react"
import { clsx } from "clsx"

interface TabletProps {
	isUserLoggedIn: boolean
	connectWallet: () => void
}

const Tablet: React.FC<TabletProps> = ({ isUserLoggedIn, connectWallet }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	return (
		<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
			<div className='inline-flex flex-col items-center container pt-[61px] gap-[53px]'>
				<div className='flex flex-col items-center'>
					<div className='text-[#FFF] text-[40px] font-[800] leading-normal'>
						3 Steps To Get Started
					</div>
					<div className='text-neutral-500 text-[18px] font-[400] leading-normal'>
						Don&apos;t miss out on great deals and awesome features only
						available on Flowty!
					</div>
				</div>
				<Tab.List className='flex gap-[32px] w-full justify-center'>
					{steps.map((step, featureIndex) => (
						<CurrentStepButton
							key={step.step}
							step={step.step}
							text={step.text}
							subText={step.subText}
							isCurrentStep={featureIndex === selectedIndex}
						/>
					))}
				</Tab.List>
				<div className='flex items-center justify-start gap-[24px]'>
					<a
						href='https://docs.flowty.io/reference'
						className='flex w-[261px] flex-1 py-[9.2px] px-[18.5px] gap-[16px] items-center justify-center rounded-[6px] border-2 border-neutral-300'
						target='_blank'
						rel='noreferrer noopener'
					>
						<span className='text-neutral-900 text-center text-[13px] font-[900] leading-[16px]'>
							LEARN MORE
						</span>
						<Book />
					</a>
					{!isUserLoggedIn && (
						<button
							onClick={connectWallet}
							className='flex w-[261px] flex-1 bg-neutral-900 py-[9.2px] px-[18.5px] gap-[16px] items-center justify-center rounded-[6px]'
						>
							<span className='text-center text-[13px] font-[900] leading-[16px] text-navy-blue-900'>
								SIGN IN
							</span>
							<Arrow />
						</button>
					)}
				</div>
				<Tab.Panels>
					{steps.map((step, featureIndex) => (
						<Tab.Panel
							static
							key={step.subText}
							className={clsx(
								"transition duration-500 ease-in-out ui-not-focus-visible:outline-none",
								featureIndex !== selectedIndex && "opacity-0"
							)}
							aria-hidden={featureIndex !== selectedIndex}
						>
							<img
								alt={step.subText}
								src={step.image}
								className={`w-full rounded-[25px] ${
									featureIndex !== selectedIndex ? "h-[0px]" : "h-full"
								}`}
								style={{
									boxShadow:
										"8.194px 8.194px 40.968px 0px rgba(55, 55, 55, 0.54)",
								}}
							/>
						</Tab.Panel>
					))}
				</Tab.Panels>
			</div>
		</Tab.Group>
	)
}

export default Tablet
