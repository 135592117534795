import React from "react"
import { cn } from "../../../../utils/cn"

interface FlowtyLinkProps {
	href: string
	text: string
	size?: "small" | "medium"
}

export const FlowtyLink = ({
	href,
	text,
	size = "medium",
}: FlowtyLinkProps) => {
	return (
		<a
			href={href}
			target='_blank'
			className={cn("text-primary font-montserrat font-[600] underline", {
				"text-[0.75rem]": size === "small",
				"text-[1rem]": size === "medium",
			})}
			rel='noreferrer'
		>
			{text}
		</a>
	)
}
