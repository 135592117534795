import React from "react"
import { getFMVHrefFromSource } from "../../../utils/GlobalUtils"

interface FlowtyFMVIndicatorProps {
	value: number
	source: string
}

export const FlowtyFMVIndicator: React.FC<FlowtyFMVIndicatorProps> = ({
	value,
	source,
}) => {
	const fmvHref = getFMVHrefFromSource(source)
	return (
		<div className='w-full flex justify-between text-white text-[14px]'>
			<p className='font-[400]'>
				FMV (Per{" "}
				<a
					href={fmvHref}
					target='_blank'
					rel='noreferrer'
					className='text-primary cursor-pointer'
				>
					{source.toUpperCase()}
				</a>
				)
			</p>
			<p className='font-[700]'>${value.toFixed(2)}</p>
		</div>
	)
}
