/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export const Log = (...data: any[]): void => {
	if (
		process.env.NODE_ENV === "production" ||
		process.env.REACT_APP_REMOVE_LOGS === "true"
	)
		return
	console.log(data)
}

export const Err = (...data: any[]): void => {
	console.error(data)
}
