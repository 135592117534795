import { Config } from "../types"

export const getNftResourceTypesScript = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}

access(all) fun main(addr: Address, name: String): [String] {

    let acct = getAccount(addr)
    let c = acct.contracts.get(name: name)
    if c == nil {
        return []
    }

    let types: [String] = []

    let publicTypes = c!.publicTypes()
    let nftResourceType = Type<@{NonFungibleToken.NFT}>()
    for pt in publicTypes {
        if pt.isSubtype(of: nftResourceType) {
           types.append(pt.identifier)
        }
    }

    return types
}`
