import React from "react"
import UserProfileData, {
	UserProfileTabButtons,
} from "../components/UserProfile/UserProfileData"

import Layout from "../layouts/Layout"
import { PageHeader } from "../components/PageHeader"

interface UserProfileScreenProps {
	activeTabBtn?: UserProfileTabButtons
}

const metaTitle = `Flowty: Your NFT Loans Activity`
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

const UserProfileScreen: React.FC<UserProfileScreenProps> = ({
	activeTabBtn = UserProfileTabButtons.History,
}) => (
	<Layout>
		<PageHeader
			metaTitle={metaTitle}
			metaDescription={metaDescription}
			metaImage={metaImage}
		/>
		<div className='container'>
			<UserProfileData activeTabBtn={activeTabBtn} />
		</div>
	</Layout>
)

export default UserProfileScreen
