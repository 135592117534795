import { db } from "../../firebase"
import { actions as Mixpanel } from "../../util/Mixpanel"

const ACCOUNT_GLOBAL_ALERT_COLLECTION = "accountGlobalAlert"

export interface AccountGlobalAlert {
	seen: boolean
	dismissed: boolean
}
export interface AccountGlobalAlertConfig {
	alerts: Record<string, AccountGlobalAlert>
}

export const getAccountGlobalAlertInfoByAddress = async (
	address: string
): Promise<AccountGlobalAlertConfig | null> => {
	try {
		const response = db.collection(ACCOUNT_GLOBAL_ALERT_COLLECTION)
		const currDoc = await response.doc(address).get()

		if (currDoc.exists) {
			const docData = currDoc.data() as AccountGlobalAlertConfig
			return docData
		}
	} catch (e) {
		Mixpanel.track("ERROR_FIREBASE_GET_ACCOUNT_GLOBAL_ALERT", {
			e,
		})
	}

	return null
}

export const setAccountGlobalAlert = async (
	address: string | null | undefined,
	accountGlobalAlerts: AccountGlobalAlertConfig
): Promise<void> => {
	if (!address) {
		throw Error("Not receiveed required parameter address!")
	}

	try {
		db.collection(ACCOUNT_GLOBAL_ALERT_COLLECTION)
			.doc(address)
			.set(accountGlobalAlerts)
	} catch (e) {
		Mixpanel.track("ERROR_FIREBASE_SET_ACCOUNT_GLOBAL_ALERT", {
			e,
		})
	}
}
