import { ChildAccountDisplay, OpensearchFlowNFT } from "flowty-common"
import { AccountSummaries } from "flowty-sdk"
import { useState } from "react"
import { flowty } from "../../../config/config"
import { getBestCollectionPrivatePath } from "../../../util/getBestPrivatePath"
import { Err } from "../../../util/Log"
import { useHybridCustodyContext } from "../../HybridCustodyContext"
import { checkHasProviderFn } from "../utils/FlowtyModalsUtilFunctions"

interface ModalHybridCustodyValues {
	addressesWithCollectionPublic: string[]
	checkHasProvider: (
		loggedUserAddress: string,
		selectedNft: OpensearchFlowNFT | null
	) => boolean | null
	getNftProviderPathIdentifier: (
		loggedUserAddr: string,
		selectedNft: OpensearchFlowNFT | null,
		collectionIdentifier: string
	) => string
	getPublicAddresses: (
		collectionIdentifier: string,
		childAccounts: { [key: string]: ChildAccountDisplay },
		address: string,
		accountSummaries: AccountSummaries,
		contractAddress: string,
		contractName: string
	) => Promise<void>
}

export const useModalHybridCustody = (): ModalHybridCustodyValues => {
	const { hybridCustodyNFTStatus, iterateAndRunScript } =
		useHybridCustodyContext()
	const [addressesWithCollectionPublic, setAddressesWithCollectionPublic] =
		useState<string[]>([])

	const checkHasProvider = (
		loggedUserAddress: string,
		selectedNft: OpensearchFlowNFT | null
	): boolean | null => {
		const nftOwner = selectedNft?.owner ?? ""
		const paths = hybridCustodyNFTStatus[nftOwner]

		return checkHasProviderFn(selectedNft, loggedUserAddress, paths)
	}

	const getNftProviderPathIdentifier = (
		loggedUserAddr: string,
		selectedNft: OpensearchFlowNFT | null,
		collectionIdentifier: string
	): string => {
		return loggedUserAddr !== selectedNft?.owner
			? getBestCollectionPrivatePath(
					hybridCustodyNFTStatus?.[selectedNft?.owner || ""]?.[
						collectionIdentifier
					] || [],
					selectedNft?.contractAddress,
					selectedNft?.contractName
			  )
			: ""
	}

	const getPublicAddresses = async (
		collectionIdentifier: string,
		childAccounts: { [key: string]: ChildAccountDisplay },
		address: string,
		accountSummaries: AccountSummaries,
		contractAddress: string,
		contractName: string
	): Promise<void> => {
		try {
			iterateAndRunScript([collectionIdentifier], childAccounts || {}, address)

			const publicAddressesResult =
				await flowty.scripts.getAddressesWithCollectionPublic(
					Object.keys(accountSummaries) || [],
					contractAddress,
					contractName
				)

			setAddressesWithCollectionPublic(publicAddressesResult)
		} catch (err) {
			Err("Failed to fetch hybrid custody", err)
		}
	}

	return {
		addressesWithCollectionPublic,
		checkHasProvider,
		getNftProviderPathIdentifier,
		getPublicAddresses,
	}
}
