import { useMemo } from "react"
import FlowtyLink from "../FlowtyModal/components/common/FlowtyLink"
import {
	FLOW_SCAN_TX_MAINNET_URL,
	FLOW_SCAN_TX_TESTNET_URL,
} from "flowty-common"

interface TransactionFollowBoxProps {
	transactionID?: string | null
	isMainnet: boolean
}

export const TransactionFollowBox: React.FC<TransactionFollowBoxProps> = ({
	transactionID,
	isMainnet,
}) => {
	const transactionExplorerLink = useMemo(() => {
		const flowscanBaseURL = isMainnet
			? FLOW_SCAN_TX_MAINNET_URL
			: FLOW_SCAN_TX_TESTNET_URL
		return `${flowscanBaseURL}/${transactionID}`
	}, [transactionID])

	if (!transactionID) return null

	return (
		<div className='text-white text-center text-[14px] min-h-[21px] flex items-center justify-center gap-1'>
			Follow your transaction on{" "}
			<FlowtyLink href={transactionExplorerLink} text='Flowscan' />
		</div>
	)
}
