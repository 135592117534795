import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline"
import { FlowtyFacetButton } from "ds-flowty"
import { truncateString } from "flowty-common"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { cn } from "../../../util/cn"
import { SnackbarMessage } from "../../Shared/Snackbar/SnackbarAlert"
import { SaveIcon } from "./assets/SaveIcon"
import { ConfirmDeleteCustomViewModal } from "./components/ConfirmDeleteCustomViewModal"
import { SaveCustomViewModal } from "./components/SaveCustomViewModal"
import { FilterView, useSaveView } from "./hooks/useSaveView"
import { useEffect, useRef } from "react"

interface SaveViewProps extends AuthStoreProp {
	onShowSnackbar?: (vals: SnackbarMessage) => void
}

const SaveViewComponent: React.FC<SaveViewProps> = ({
	authStore,
	onShowSnackbar,
}) => {
	const {
		applyPreset,
		collectionAddress,
		collectionName,
		defaultPresetLabel,
		filterViews,
		toggleMenuOpen,
		selectedValue,
		isMenuOpen,
		isSaveModalOpen,
		isUserProfile,
		defaultPreset,
		selectedForDelete,
		setSelectedForDelete,
		onSaveView,
		toggleIsSaveModalOpen,
		addFilterView,
		newPresetTitle,
		deleteFilterView,
		setNewPresetTitle,
		query,
	} = useSaveView({
		address: authStore?.loggedUser?.addr,
		onShowSnackbar,
	})

	const buttonRef = useRef<HTMLButtonElement>(null)

	function handleClickOutside(event: MouseEvent): void {
		if (
			buttonRef.current &&
			!buttonRef.current.contains(event.target as Node)
		) {
			toggleMenuOpen()
		}
	}
	useEffect(() => {
		if (isMenuOpen) {
			document.addEventListener("mousedown", handleClickOutside)
			return () => {
				document.removeEventListener("mousedown", handleClickOutside)
			}
		}
	}, [buttonRef, isMenuOpen])

	if (!authStore?.loggedUser) {
		return null
	}

	return (
		<div className='flex items-center w-full'>
			{filterViews.length ? (
				<div className='relative w-full pr-2'>
					<button
						ref={buttonRef}
						onClick={toggleMenuOpen}
						className='min-h-[2.5rem] w-[160px] max-md:w-full px-[1.25rem] flex justify-between items-center rounded-[0.375rem] bg-glassFillGray hover:bg-[#ffffff29]'
					>
						<div
							className={`whitespace-nowrap capitalize text-[0.875rem] font-montserrat font-[600] ${
								selectedValue?.name && "text-primary"
							}`}
						>
							{truncateString(selectedValue?.name || "Saved Searches", 15)}
						</div>
						<ChevronDownIcon
							className={`h-6 w-6 transition-transform ${
								isMenuOpen && "rotate-180"
							} ${selectedValue?.name && "text-primary"}`}
						/>
					</button>

					<div className='absolute w-full top-[110%] pr-2 z-10'>
						<div
							className={`w-full bg-[#474747] shadow-lg rounded-md  transition-all duration-500 ease-in-out overflow-y-auto hide-scroll ${
								isMenuOpen ? "max-h-56 opacity-100 " : "max-h-0 opacity-0"
							}`}
						>
							{[...filterViews, defaultPreset as FilterView].map(
								(view: FilterView) => (
									<button
										onClick={() => {
											applyPreset(view)
										}}
										key={view.name}
										className='flex items-center justify-between w-full px-4 py-2 capitalize cursor-pointer select hover:bg-slate-500 hover:bg-opacity-20 '
									>
										<div
											className={`select ${
												selectedValue &&
												selectedValue?.id === view.id &&
												"text-primary"
											}`}
										>
											{truncateString(view.name, 12)}
										</div>
										{view.name === defaultPresetLabel ? null : (
											<TrashIcon
												onClick={(event: React.MouseEvent<SVGSVGElement>) => {
													event.stopPropagation()
													setSelectedForDelete(view)
												}}
												className='w-6 h-6 cursor-pointer delete text-danger border-none'
											/>
										)}
									</button>
								)
							)}
						</div>
					</div>
				</div>
			) : null}

			<FlowtyFacetButton
				onClick={onSaveView}
				text={!filterViews.length ? "Save View" : ""}
				icon={<SaveIcon />}
				className={cn("justify-center hover:bg-[#ffffff29] w-full px-2", {
					"w-auto": filterViews.length,
				})}
			/>

			<SaveCustomViewModal
				collectionAddress={collectionAddress}
				collectionName={collectionName}
				isSaveModalOpen={isSaveModalOpen}
				isUserProfile={isUserProfile}
				toggleIsSaveModalOpen={toggleIsSaveModalOpen}
				addFilterView={addFilterView}
				query={query}
				onShowSnackbar={onShowSnackbar}
				newPresetTitle={newPresetTitle}
				setNewPresetTitle={setNewPresetTitle}
			/>

			{!!selectedForDelete && (
				<ConfirmDeleteCustomViewModal
					selectedForDelete={selectedForDelete}
					setSelectedForDelete={setSelectedForDelete}
					deleteFilterView={deleteFilterView}
				/>
			)}
			<div className='fixed bottom-0 w-full'></div>
		</div>
	)
}

export const SaveView = inject("authStore")(observer(SaveViewComponent))
