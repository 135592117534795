import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { db } from "../../../firebase"
import {
	SnackbarAlert,
	SnackbarSeverity,
} from "../../Shared/Snackbar/SnackbarAlert"
import { DiscordHandle } from "ds-flowty"
import { TwitterHandle } from "ds-flowty"
import { actions as Mixpanel } from "../../../util/Mixpanel"

interface TwitterProviderDocument {
	handle: string
}
interface DiscordProviderDocument {
	username: string
	id: string | number
}
const UserSocialNetworks: React.FC<AuthStoreProp> = ({ authStore }) => {
	const [twitterHandle, setTwitterHandle] = useState<string | null>(null)
	const [discordHandle, setDiscordHandle] = useState<string | null>(null)
	const [snackbar, setSnackbar] = useState<{
		show: boolean
		message: string
		type: SnackbarSeverity
	}>({
		message: "",
		show: false,
		type: "SUCCESS",
	})

	const walletAddress = authStore?.loggedUser?.addr || null

	useEffect(() => {
		let unsubscribeTwitter: () => void = () => {}
		let unsubscribeDiscord: () => void = () => {}

		if (walletAddress) {
			const twitterDocRef = db.doc(
				`/accounts/${walletAddress}/providers/twitter`
			)
			const discordDocRef = db.doc(
				`/accounts/${walletAddress}/providers/discord`
			)

			unsubscribeTwitter = twitterDocRef.onSnapshot(
				twitterDoc => {
					if (twitterDoc.exists) {
						const twitterData = twitterDoc.data() as TwitterProviderDocument
						setTwitterHandle(twitterData.handle ?? null)
					} else {
						setTwitterHandle(null)
					}
				},
				error => {
					Mixpanel.track("FETCHING_TWITTER_DATA_ERROR", {
						error,
						twitterHandle,
						walletAddress,
					})
				}
			)
			unsubscribeDiscord = discordDocRef.onSnapshot(
				discordDoc => {
					if (discordDoc.exists) {
						const discordData = discordDoc.data() as DiscordProviderDocument
						setDiscordHandle(discordData.username ?? null)
					} else {
						setDiscordHandle(null)
					}
				},
				error => {
					Mixpanel.track("FETCHING_DISCORD_DATA_ERROR", {
						discordHandle,
						error,
						walletAddress,
					})
				}
			)
		}
		return () => {
			unsubscribeTwitter()
			unsubscribeDiscord()
		}
	}, [walletAddress])

	const handleTwitterDisconnect = async () => {
		Mixpanel.track("TWITTER_DISCONNECT_CLICKED", {
			twitterHandle,
			walletAddress,
		})
		try {
			await db.doc(`/accounts/${walletAddress}/providers/twitter`).delete()
			setSnackbar({
				message: "Successfully Disconnected Twitter",
				show: true,
				type: "SUCCESS",
			})
			Mixpanel.track("TWITTER_DISCONNECT_SUCCESS", {
				twitterHandle,
				walletAddress,
			})
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setSnackbar({
				message: "Something went wrong. Please try again later.",
				show: true,
				type: "ERROR",
			})
			Mixpanel.track("TWITTER_DISCONNECT_ERROR", {
				error,
				twitterHandle,
				walletAddress,
			})

			return error
		}
	}

	const handleDiscordDisconnect = async (): Promise<void> => {
		Mixpanel.track("DISCORD_DISCONNECT_CLICKED", {
			discordHandle,
			walletAddress,
		})
		try {
			await db.doc(`/accounts/${walletAddress}/providers/discord`).delete()
			setSnackbar({
				message: "Successfully Disconnected Discord",
				show: true,
				type: "SUCCESS",
			})
			Mixpanel.track("DISCORD_DISCONNECT_SUCCESS", {
				discordHandle,
				walletAddress,
			})
		} catch (error) {
			setSnackbar({
				message: "Something went wrong. Please try again later.",
				show: true,
				type: "ERROR",
			})
			Mixpanel.track("DISCORD_DISCONNECT_ERROR", {
				discordHandle,
				error,
				walletAddress,
			})
		}
	}

	return (
		<div className='flex-1 px-[2.5rem] py-[2rem] max-h-auto bg-neutral-400/10 rounded-lg w-full lg:min-w-[44.25rem]'>
			<SnackbarAlert
				setShow={show => setSnackbar({ ...snackbar, show })}
				show={snackbar.show}
				seconds={5}
				message={snackbar.message}
				type={snackbar.type}
			/>
			<div className='flex  flex-col items-start w-full'>
				<div className='font-semibold text-lg '>Social Connections</div>
				<TwitterHandle
					twitterHandle={twitterHandle}
					walletAddress={walletAddress}
					handleTwitterDisconnect={handleTwitterDisconnect}
				/>
				<DiscordHandle
					discordHandle={discordHandle}
					walletAddress={walletAddress}
					handleDiscordDisconnect={handleDiscordDisconnect}
				/>
			</div>
		</div>
	)
}
export default inject("authStore")(observer(UserSocialNetworks))
