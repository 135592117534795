import React from "react"

export const ChildWalletListing: React.FunctionComponent = () => {
	return (
		<div className='flex items-center gap-2 bg-[#FFFFFF14] rounded-lg p-3'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'
				className='w-fit min-w-[18px]'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M7.43135 2.78911C8.1195 1.56574 9.88088 1.56574 10.569 2.78911L15.5913 11.7176C16.2662 12.9175 15.3991 14.4001 14.0225 14.4001H3.97791C2.60123 14.4001 1.73414 12.9175 2.40908 11.7176L7.43135 2.78911ZM9.90011 11.7002C9.90011 12.1972 9.49717 12.6002 9.00011 12.6002C8.50305 12.6002 8.10011 12.1972 8.10011 11.7002C8.10011 11.2031 8.50305 10.8002 9.00011 10.8002C9.49717 10.8002 9.90011 11.2031 9.90011 11.7002ZM9.00011 4.50017C8.50305 4.50017 8.10011 4.90311 8.10011 5.40017V8.10017C8.10011 8.59723 8.50305 9.00017 9.00011 9.00017C9.49717 9.00017 9.90011 8.59723 9.90011 8.10017V5.40017C9.90011 4.90311 9.49717 4.50017 9.00011 4.50017Z'
					fill='#FF6E25'
				/>
			</svg>
			<p className='text-[12px] text-[#ADB5BD] leading-4'>
				Warning: You’re listing for your child account. Delisting can only be
				done from the account that listed the NFT.
			</p>
		</div>
	)
}
