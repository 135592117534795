import {
	and,
	collection,
	limit as firebaseLimit,
	getDocs,
	onSnapshot,
	query,
	where,
	orderBy,
} from "firebase/firestore"

import { Unsubscribe } from "firebase/auth"
import { IEventType } from "flowty-common"
import { db } from "../../../firebase"
import { LoanEvent } from "../components/HistoryTab/types/LoanEventTypes"
import { RentEvent } from "../components/HistoryTab/types/RentEventTypes"
import { StorefrontEvent } from "../components/HistoryTab/types/StorefrontEventTypes"

interface SubscribeProps {
	limit: number
	cb: ({
		data,
		totalCount,
	}: {
		data: Array<StorefrontEvent | RentEvent | LoanEvent>
		totalCount: number
	}) => void
	nftId: string
	nftType: string
	filterBy: IEventType[]
}

export const getNFTLoanActivity = ({
	limit = 25,
	cb,
	nftId,
	nftType,
	filterBy,
}: SubscribeProps): Unsubscribe => {
	const baseQuery = collection(db, "p2pEvents")

	let countQuery = query(baseQuery)
	let loanQuery = query(baseQuery)

	loanQuery = query(
		loanQuery,
		and(
			and(where("data.nftType", "==", nftType)),
			and(where("data.nftID", "==", nftId))
		)
	)

	countQuery = query(
		countQuery,
		and(
			and(where("data.nftType", "==", nftType)),
			and(where("data.nftID", "==", nftId))
		)
	)

	if (filterBy.length > 0) {
		loanQuery = query(loanQuery, where("type", "in", filterBy))
		countQuery = query(countQuery, where("type", "in", filterBy))
	} else {
		loanQuery = query(loanQuery, where("type", "==", "null"))
		countQuery = query(countQuery, where("type", "==", "null"))
	}

	loanQuery = query(loanQuery, orderBy("blockTimestamp", "desc"))
	loanQuery = query(loanQuery, firebaseLimit(limit))

	const fetchCount = getDocs(countQuery).then(
		countSnapshot => countSnapshot.size
	)

	const fetchPageData = onSnapshot(loanQuery, snapshot => {
		const storefrontData = snapshot.docs.map(
			doc =>
				({
					id: doc.id,
					...doc.data(),
				} as StorefrontEvent | RentEvent | LoanEvent)
		)

		if (storefrontData) {
			fetchCount.then(totalCount => {
				cb({ data: storefrontData, totalCount })
			})
		}
	})

	return fetchPageData
}
