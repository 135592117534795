import { FlipSideDataResponse } from "ds-flowty/src/LandingPage/WhyFlowty"
import { useEffect, useState } from "react"

export const useFetchFlipSideData = (): {
	data: FlipSideDataResponse | null
	isLoading: boolean
} => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<FlipSideDataResponse | null>(null)

	useEffect(() => {
		setIsLoading(true)
		const fetchTopSalesData = async () => {
			const response = await fetch(
				"https://flipsidecrypto.xyz/api/v1/queries/26c51ee5-d7e5-425b-8c71-78ce69700ddc/data/latest"
			)
			const responseData = await response.json()
			if (responseData?.[0]) {
				setData(responseData?.[0] as FlipSideDataResponse)
			}

			setIsLoading(false)
		}
		fetchTopSalesData()
	}, [])

	return { data, isLoading }
}
