/* eslint-disable jsx-a11y/anchor-is-valid */
import { getRecentSearches, storeSearchResult } from "../../util/searchHistory"
import SearchResult from "../SearchResult"

interface RecentSearchesProps {
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

export const RecentSearches: React.FunctionComponent<RecentSearchesProps> = ({
	onMouseEnter,
	onMouseLeave,
}) => {
	const recentSearches = getRecentSearches()

	return (
		<div
			className='flex rounded-[6px] searchResultsBackground flex-col gap-[14px] items-end'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className='flex flex-col items-start justify-center w-full'>
				<div className='flex p-[12px] w-full'>
					<span className='text-[#ADB5BD] text-[14px]'>
						{recentSearches.length > 0 ? "Recent" : "No Recent Results"}
					</span>
				</div>
				<div className='overflow-auto max-h-[300px] w-full'>
					{recentSearches
						.filter(result => !!result.name)
						.map(result => (
							<a
								key={result.address}
								onClick={() => storeSearchResult(result)}
								href={
									!process.env.IS_STORYBOOK
										? `/collection/${result.address}/${result.name}`
										: "#"
								}
							>
								<SearchResult result={result} isRecent />
							</a>
						))}
				</div>
			</div>
		</div>
	)
}
