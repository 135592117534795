export const calculateFlowtyFee = (token: string, amount: number): number => {
	return 0.0 // we are taking no fee for now
}

export const calculateDapperFee = (token: string, amount: number): number => {
	if (amount === 0.0) {
		return 0.0
	}

	if (token === "DUC") {
		return Math.max(0.44, amount * 0.01)
	}

	return 0.0 // there is no fee for now except for Dapper's own fees.
}

export const calculateCommission = (token: string, amount: number): number => {
	return calculateFlowtyFee(token, amount) + calculateDapperFee(token, amount)
}
