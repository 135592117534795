import { useCallback, useEffect, useState } from "react"

interface ControlNotificationsMenuValues {
	showMenuNotifications: boolean
	setMouseExited: (value: boolean) => void
	toggleMenu: () => void
	closeMenu: () => void
}

export const useControlNotificationsMenu =
	(): ControlNotificationsMenuValues => {
		const [showMenuNotifications, setShowMenuNotifications] = useState(false)

		const [mouseExited, setMouseExited] = useState(false)

		const toggleMenu = (): void => {
			setShowMenuNotifications(!showMenuNotifications)
		}

		const closeMenu = useCallback(() => {
			if (mouseExited && showMenuNotifications) {
				setShowMenuNotifications(false)
				setMouseExited(false)
			}
		}, [mouseExited, showMenuNotifications])

		useEffect(() => {
			window.addEventListener("click", closeMenu)

			return () => {
				window.removeEventListener("click", closeMenu)
			}
		}, [closeMenu])

		return { closeMenu, setMouseExited, showMenuNotifications, toggleMenu }
	}
