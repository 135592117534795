import { useField } from "formik"
import numeral from "numeral"
import { FlowtyButton } from "../../../../FlowtyButton"
import { DropStatusPill } from "../../../../FlowtyDropPage/components/common/DropStatusPill/DropStatusPill"
import { ReactComponent as FLOWIcon } from "../../../../assets/tokens/flow.svg"
import CountdownTimer from "../../../../FlowtyDropPage/components/common/CountdownTimer/CountdownTimer"
import { getCountdownValues } from "../../../../FlowtyDropPage/utils/getCountdownValues"
import { useEffect, useState } from "react"
import { FLOWTY_QUICKNODE_IPFS_URL } from "../../../../utils/constants"
import { DateTimeType } from "../../../types/FlowtyCreatorHubTypes"

const isNeverEndingDrop = false

export const DropPageComponent = (): JSX.Element => {
	const [dropThumbnailImage] = useField("dropThumbnailImage")
	const [mintPrice] = useField("mintPrice")
	const [dropStartDateTime] = useField<DateTimeType>("dropStartDateTime")
	const [dropEndDateTime] = useField<DateTimeType>("dropEndDateTime")
	const startTime = dropStartDateTime.value
	const [countDown, setCountDown] = useState(
		startTime.dateTime - new Date().getTime()
	)

	const dateFormatter = (date: number): string => {
		const dateObj = new Date(date)
		const options: Intl.DateTimeFormatOptions = {
			day: "numeric",
			hour: "numeric",
			hour12: true,
			minute: "numeric",
			month: "long",
			year: "numeric",
		}
		return dateObj.toLocaleString("en-US", options)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			const countDownValue =
				dropStartDateTime.value.dateTime - new Date().getTime()
			setCountDown(countDownValue)
		}, 1000)

		return () => clearInterval(interval)
	}, [countDown])

	return (
		<div className='flex flex-row justify-center gap-10 py-4 3xl:gap-20 3xl:px-10'>
			{/* Drop phase image */}
			{dropThumbnailImage.value &&
			(dropThumbnailImage.value.fileCid ||
				dropThumbnailImage.value.fileBase64) ? (
				<div className='w-full md:w-[250px] md:h-[250px] 3xl:h-[388px] 3xl:w-[388px] h-full my-auto object-fill aspect-square'>
					<img
						src={
							dropThumbnailImage.value?.fileCid
								? `${FLOWTY_QUICKNODE_IPFS_URL}${dropThumbnailImage.value?.fileCid}`
								: dropThumbnailImage.value?.fileBase64
						}
						alt='Drop Custom'
						className='rounded-[2.5rem] w-full h-full'
					/>
				</div>
			) : (
				<div
					className='flexw-full md:w-[250px] md:h-[250px] 3xl:h-[388px] 3xl:w-[388px] h-full my-auto animate-pulse bg-[#43ff640a] rounded-[2.5rem]
						object-fill aspect-square'
				></div>
			)}
			<div className='flex flex-col justify-center gap-[8px] 3xl:gap-[22px] w-full max-w-[300px] 3xl:max-w-[400px]'>
				<div>
					<DropStatusPill dropStatus={"PENDING"} smallChip />
				</div>

				{!isNeverEndingDrop && (
					<div className='flex flex-col gap-[8px] 2xl:gap-[14px]'>
						<div className='flex flex-col'>
							<p className='text-[#ADB5BD] text-[12px] 3xl:text-[14px]'>
								{"Drop Starts (Local Time Zone)"}
							</p>
							<p className='text-white text-[12px] 3xl:text-[14px] font-bold'>
								{dateFormatter(dropStartDateTime.value.dateTime)}
							</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-[#ADB5BD] text-[12px] 3xl:text-[14px]'>
								{"Drop Ends (Local Time Zone)"}
							</p>
							<p className='text-white text-[12px] 3xl:text-[14px] font-bold'>
								{dateFormatter(dropEndDateTime.value.dateTime)}
							</p>
						</div>
					</div>
				)}
				<div className='flex max-tablet:flex-col gap-14 max-tablet:gap-[10px] justify-between'>
					<div className='flex flex-col 3xl:gap-[10px]'>
						<p className='text-[#ADB5BD] text-[12px]'>Price</p>
						<div className='flex flex-col gap-1'>
							{mintPrice ? (
								<div className='flex items-center w-auto justify-start gap-x-[0.75rem]'>
									{mintPrice.value === 0.0 || mintPrice.value === null ? (
										<span className='text-white font-montserrat text-[16px] 3xl:text-[26px] font-[700]'>
											{" "}
											FREE{" "}
										</span>
									) : (
										<>
											<FLOWIcon className='w-[14px] 3xl:w-[20px] h-[14px] 3xl:h-[20px]' />
											<div className='flex gap-1'>
												<span className='text-white font-montserrat text-[16px] 3xl:text-[26px] font-[700]'>
													{numeral(mintPrice.value).format("0,0.00")}
												</span>
												<h4 className='text-[#ADB5BD] text-[11px] font-normal leading-normal'>
													FLOW
												</h4>
											</div>
										</>
									)}
								</div>
							) : (
								"-"
							)}
						</div>
					</div>
				</div>

				<div className='flex flex-col 3xl:gap-[10px]'>
					<p className='text-[#ADB5BD] text-[12px]'>Countdown</p>
					<CountdownTimer
						countdownCurrentValue={getCountdownValues(countDown)}
					/>
				</div>

				<div className='h-[35px] flex flex-col gap-1'>
					<FlowtyButton
						variant='secondary'
						bgColor='white'
						text={"BUY NOW • COMING SOON"}
						disabled={true}
						onClick={() => {}}
						className='py-[2px] text-[10px] 3xl:text-[14px]'
					/>
				</div>
			</div>
		</div>
	)
}
