/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fcl from "@onflow/fcl"
import axios from "axios"
import {
	AccountData,
	getMessageFromError,
	utf8ToHexString,
} from "flowty-common"
import Swal from "sweetalert2"
import { db } from "../../firebase"
import { Service, User } from "../../models/user"
import { delay } from "../../util/delay"
import { Err, Log } from "../../util/Log"
import { apiURL } from "../../util/settings"
import { actions as Mixpanel } from "../../util/Mixpanel"

const ACCOUNTS_COLLECTION = "accounts"
const SignatureRejectionPrefix = "Declined"

export const isAccountExists = async (accountId: string): Promise<boolean> =>
	(await db.collection(ACCOUNTS_COLLECTION).doc(accountId).get()).exists

export const setAccountData = async (
	accountId: string,
	accountData: Partial<AccountData>
): Promise<void> =>
	db.collection(ACCOUNTS_COLLECTION).doc(accountId).set(accountData)

export const updateAccountData = async (
	accountId: string,
	accountData: Partial<AccountData>
): Promise<boolean> => {
	try {
		await db.collection(ACCOUNTS_COLLECTION).doc(accountId).update(accountData)
		return true
	} catch (error) {
		Mixpanel.track("ERROR_FIREBASE_UPDATE_ACCOUNT_DATA", { error })
		Err("Error updating account data:", getMessageFromError(error))
		return false
	}
}

export const getAccountData = async (
	accountId: string
): Promise<AccountData> => {
	Log("getting account with id", accountId)
	const currDoc = await db.collection(ACCOUNTS_COLLECTION).doc(accountId).get()

	if (currDoc.exists) {
		Log("account exists...")
		const docData = currDoc.data() as AccountData
		if (!docData.email) {
			docData.email = ""
		}
		Log("obtained account", docData)
		return docData
	}

	throw new Error(`Could not find account for address ${accountId}`)
}

interface CreateTokenResponse {
	customToken: string
}

const getTokenWithSignature = async (_: User): Promise<string> => {
	try {
		const msg = utf8ToHexString(
			"By signing in, you agree to flowtyNFT Inc's \nTerms of Service and Privacy Policy"
		)

		// wait half a second so that the signature prompt doesn't lose focus
		await delay(500)
		const compositeSignature = await fcl.currentUser().signUserMessage(msg)
		if (
			typeof compositeSignature === "string" &&
			compositeSignature.startsWith(SignatureRejectionPrefix)
		) {
			Mixpanel.track("[PREVIEW 2-4233]CatchBlock RejectionError", {})
			return ""
		}

		if (
			typeof compositeSignature === "object" &&
			compositeSignature?.message?.includes("Popup failed to open")
		) {
			Err("pop up error detected")
			Mixpanel.track("[AuthService] PopupError", {})

			await Swal.fire({
				icon: "error",
				showConfirmButton: true,
				timer: 5000,
				title: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
			})

			return ""
		}

		if (!compositeSignature?.[0]?.addr) {
			return ""
		}

		const axiosResponse = await axios.post<CreateTokenResponse>(
			`${apiURL}/login`,
			{ compositeSignature, msg }
		)

		return axiosResponse.data.customToken
	} catch (error: unknown) {
		Err("failed to login with user signature", getMessageFromError(error))
		return ""
	}
}

export const getAccountToken = async (user: User): Promise<string> => {
	Log("creating account token...", { user })
	// get the account proof service
	let service: Service | null = null
	for (const s of user.services) {
		if (s.type === "account-proof") {
			service = s
			break
		}
	}

	if (service) {
		try {
			Log("creating custom token...", { service, user })
			const axiosResponse = await axios.post<CreateTokenResponse>(
				`${apiURL}/auth/verify-proof`,
				{ proof: service }
			)

			return axiosResponse.data.customToken
		} catch (error: any) {
			Err("createAccountToken error", {
				message: getMessageFromError(error),
				responseData: error.response?.data,
			})
		}
	}

	return getTokenWithSignature(user)
}
