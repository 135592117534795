/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthStore from "../../../stores/AuthStore"
import { updateAccountData } from "../../../services/firestore/AccountService"
import { AccountData } from "flowty-common"
import { ProfileSettingsValues } from "../Settings/UserPreferences"

export const onUserProfileFormSubmit = async (
	loggedUserAddress: string | null | undefined,
	values: ProfileSettingsValues,
	onSubmitted: () => void,
	formSource: "welcome" | "settings",
	onError?: (err: any) => void
): Promise<void> => {
	if (loggedUserAddress) {
		const data: Partial<AccountData> =
			formSource === "welcome"
				? {
						email: values.emailOptIn ? values?.email : "",
						emailOptIn: values?.emailOptIn,
						hasAcceptedTermsV2: true,
						preferredCardSize: values?.preferredCardSize || "large",
						welcomePopupAcknowledged: true,
				  }
				: formSource === "settings"
				? {
						avatar: values.avatar || "",
						email: values?.email || "",
						preferredCardSize: values?.preferredCardSize || "large",
						userName: values?.userName?.toLowerCase() || "",
				  }
				: {}

		await updateAccountData(loggedUserAddress, data)
			.then(() => {
				onSubmitted()
			})
			.catch((err: any) => {
				onError?.(err)
			})
	} else {
		throw new Error("Required parameter loggedUserAddress should be provided")
	}
}

export const getInitialValues = (
	authStore?: AuthStore
): ProfileSettingsValues => ({
	avatar: authStore?.loggedUser?.avatar || "",
	email: authStore?.loggedUser?.email || "",
	emailOptIn: authStore?.loggedUser?.emailOptIn || false,
	marketingEmail: authStore?.loggedUser?.marketingEmail || "",
	userName: authStore?.loggedUser?.userName || "",
})
