import { ReactComponent as Arrow } from "../../../assets/arrow.svg"
import { ReactComponent as Book } from "../../../assets/book.svg"
import CurrentStepButton from "../CurrentStepButton"
import { steps } from "../Carousel/Carousel"
// @ts-ignore: We will remove this import regardless
import * as fcl from "@onflow/fcl"
import { Tab } from "@headlessui/react"
import { useState } from "react"
import { clsx } from "clsx"

interface MobileProps {
	isUserLoggedIn: boolean
	connectWallet: () => void
}

const Mobile: React.FC<MobileProps> = ({ isUserLoggedIn }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const connectWallet = (): void => {
		fcl.authenticate()
	}

	return (
		<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
			<div className='container'>
				<div
					className='flex flex-col flex-shrink-0 gap-[52px] items-center py-[61px] px-[18px] sm:px-[44px] pb-[200px] rounded-[24px]'
					style={{
						background: "rgba(255, 255, 255, 0.08)",
					}}
				>
					<div className='flex flex-col'>
						<div className='text-[#FFF] text-[24px] font-[800] text-center'>
							3 Steps To Get Started
						</div>
						<div className='text-neutral-500 text-[10px] sm:text-[12px] font-[400] text-center leading-normal'>
							Don&#39;t miss out on great deals and awesome features only
							available on Flowty!
						</div>
					</div>
					<Tab.List className='flex flex-col gap-[32px] self-center'>
						{steps.map((step, featureIndex) => (
							<CurrentStepButton
								key={step.step}
								step={step.step}
								text={step.text}
								subText={step.subText}
								isCurrentStep={featureIndex === selectedIndex}
							/>
						))}
					</Tab.List>
					<div className='flex flex-col items-center justify-start gap-[12px] md:gap-[24px] w-full'>
						<a
							href='https://docs.flowty.io/reference'
							className='flex w-full flex-1 py-[12px] px-[24px] gap-[16px] items-center justify-center rounded-[6px] border-2 border-neutral-300'
							target='_blank'
							rel='noreferrer noopener'
						>
							<span className='text-neutral-900 text-center text-[13.8px] sm:text-[18px] font-[900] leading-[16px]'>
								LEARN MORE
							</span>
							<Book />
						</a>
						{!isUserLoggedIn && (
							<button
								onClick={connectWallet}
								className='flex w-full flex-1 bg-neutral-900 py-[12px] px-[24px] gap-[16px] items-center justify-center rounded-[6px]'
							>
								<span className='text-center text-[13.8px] sm:text-[18px] font-[900] leading-[16px] text-navy-blue-900'>
									SIGN IN
								</span>
								<Arrow />
							</button>
						)}
					</div>
				</div>
				<div className='w-full rounded-[12px] px-[22px] -mt-[140px]'>
					<Tab.Panels>
						{steps.map((step, featureIndex) => (
							<Tab.Panel
								static
								key={step.subText}
								className={clsx(
									"transition duration-500 ease-in-out ui-not-focus-visible:outline-none",
									featureIndex !== selectedIndex && "opacity-0"
								)}
								aria-hidden={featureIndex !== selectedIndex}
							>
								<img
									alt={step.subText}
									src={step.image}
									className={`w-full border-2 rounded-[12px] object-cover ${
										featureIndex !== selectedIndex ? "h-[0px]" : "h-full"
									}`}
									style={{
										boxShadow:
											"8.194px 8.194px 40.968px 0px rgba(55, 55, 55, 0.54)",
									}}
								/>
							</Tab.Panel>
						))}
					</Tab.Panels>
				</div>
			</div>
		</Tab.Group>
	)
}

export default Mobile
