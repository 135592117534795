import React, { useMemo } from "react"
import { FlowtyButton } from "../../../FlowtyButton"
import { ReactComponent as Discord } from "../../../assets/discordLogo.svg"
import { ReactComponent as CloseX } from "../../../assets/close-x.svg"
import { actions as Mixpanel } from "../../../utils/MockMixpanel"

interface DiscordHandleProps {
	discordHandle: string | null
	walletAddress: string | null
	handleDiscordDisconnect: () => Promise<void>
}

export const DiscordHandle: React.FunctionComponent<DiscordHandleProps> = ({
	discordHandle,
	walletAddress,
	handleDiscordDisconnect,
}) => {
	const discordRedirect: string | null = useMemo(() => {
		if (typeof window !== "undefined") {
			if (window.location.host.startsWith("flowty-test")) {
				return "https://testnet.flowty.io/_discord-oauth"
			} else if (window.location.host.startsWith("flowty-prod")) {
				return "https://www.flowty.io/_discord-oauth"
			}
			return `${window.location.origin}/_discord-oauth`
		}
		return null
	}, [])

	return (
		<div className='flex justify-between w-full mt-[2rem]'>
			<div className='flex items-center'>
				<Discord className='mr-2 w-[1.5rem]' />
				<div className='text-md font-semibold'>Discord</div>
			</div>

			<div className='flex items-center transition-transform duration-300 ease-in-out'>
				{discordHandle ? (
					<div className='flex h-[2rem] px-[.875rem] items-center gap-[.5rem] bg-[#ffffff14] rounded-md border border-[#6C757D]/25'>
						<div className='text-[#ADB5BD] text-xs font-extrabold'>
							@{discordHandle}
						</div>
						<button onClick={handleDiscordDisconnect}>
							<CloseX />
						</button>
					</div>
				) : (
					<a
						href={`https://discord.com/api/oauth2/authorize?client_id=1166104751847526512&redirect_uri=${discordRedirect}&response_type=code&scope=identify`}
						target='_blank'
						rel='noreferrer'
					>
						<FlowtyButton
							text='Connect'
							variant='secondary'
							btnSize='small'
							bgColor='white'
							onClick={() =>
								Mixpanel.track("DISCORD_CONNECT_CLICKED", {
									discordHandle,
									walletAddress,
								})
							}
						/>
					</a>
				)}
			</div>
		</div>
	)
}
