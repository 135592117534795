import { Config } from "../types"

export const getHybridCustodyNFTProviderPathsForStorageScript = (
	config: Config
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  getHybridCustodyNFTProviderPathsForStorageCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  getHybridCustodyNFTProviderPathsForStorage(config)

const getHybridCustodyNFTProviderPathsForStorage = (config: Config): string => `
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import HybridCustody from ${config.contractAddresses.HybridCustody}

pub fun main(parent: Address, child: Address, storagePath: String):AnyStruct {
  let storagePath = StoragePath(identifier: storagePath) ?? panic("invalid storage path")
  let storageStr = storagePath.toString()

  let account = getAuthAccount(parent)
  let manager = account.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("manager not found")
  let childAcct = manager.borrowAccount(addr: child)
    ?? panic("child account not found")

  // loop through each private path, and check that it
  // 1. targets our storage path
  // 2. is the correct subtype

  let paths: [PrivatePath] = []
  let childAuthAcct = getAuthAccount(child)
  childAuthAcct.forEachPrivate(fun (path: PrivatePath, type: Type): Bool {
    if let s = childAuthAcct.getLinkTarget(path) {
      if s.toString() != storageStr {
        return true
      }
    } else {
      return true
    }

    let cap = childAcct.getCapability(path: path, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>())
    if cap == nil {
    	return true
    }
    let provider = cap! as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    if !provider.check() {
        return true
    }
    
    paths.append(path)
    return true
  })

  return paths
}`

const getHybridCustodyNFTProviderPathsForStorageCrescendo = (
	config: Config
): string => `
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import HybridCustody from ${config.contractAddresses.HybridCustody}

access(all) fun main(parent: Address, child: Address, storagePath: String): [UInt64] {
    let storagePath = StoragePath(identifier: storagePath) ?? panic("invalid storage path")
    let storageStr = storagePath.toString()

    let account = getAuthAccount<auth(Storage, Capabilities) &Account>(parent)
    let manager = account.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager not found")
    let childAcct = manager.borrowAccount(addr: child)
        ?? panic("child account not found")

    // loop through each private path, and check that it
    // 1. targets our storage path
    // 2. is the correct subtype

    let capabilityIDs: [UInt64] = []
    let childAuthAcct = getAuthAccount<auth(Storage, Capabilities) &Account>(child)

    let targetType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
    childAuthAcct.capabilities.storage.forEachController(forPath: storagePath, fun(c: &StorageCapabilityController): Bool {
        if c.borrowType.isRecovered {
                return true
        }

        if !c.borrowType.isSubtype(of: targetType) {
            return true
        }

        // is this capability accessible?
        let cap = childAcct.getCapability(controllerID: c.capabilityID, type: targetType)
        if cap == nil {
            return true
        }

        capabilityIDs.append(c.capabilityID)
        return false // do not loop again since we've found a capability that matches what we're looking for
    })

    return capabilityIDs
}`
