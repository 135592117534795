import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
} from "date-fns"

export function getTimeToDate(target: Date): string {
	const today = new Date()

	const daysUntilDate = differenceInDays(target, today)

	if (daysUntilDate > 1) return `${daysUntilDate} Days`

	const hoursUntilDate = differenceInHours(target, today)

	if (hoursUntilDate > 1) return `${hoursUntilDate} Hours`

	const minutesUntilDate = differenceInMinutes(target, today)

	const result =
		minutesUntilDate > 1 ? `${minutesUntilDate} Minutes` : "1 Minute"

	return result
}
