import React, { useEffect, useMemo, useRef, useState } from "react"
import { cn } from "../../../../util/cn"
import { CheckIcon } from "@heroicons/react/outline"
import { FlowNFTContract, getSquareImage } from "flowty-common"
import { fetchCollectionData } from "../../../../hooks/data/collections/flowNFTContract"
import { useLocation } from "react-router-dom"
import { TooltipText } from "../../../TooltipText"
import { addFallbackImage } from "../../../../util/images"
import FallbackImg from "../../../../assets/new_fallback_image.svg"

interface CollectionFilterCheckboxProps {
	isCheckboxHidden: boolean
	collection: string
	name: string
	isChecked: boolean
	onChange: (newCheckedState: boolean) => void
	label: string
	isVerified: boolean
	count?: number | string
	isCollapsible: boolean
	children?: React.ReactNode | React.ReactNode[]
}

export const CollectionFilterCheckbox: React.FC<
	CollectionFilterCheckboxProps
> = ({
	collection,
	isChecked,
	name,
	isCheckboxHidden,
	onChange,
	label,
	count,
	isVerified,
	isCollapsible = true,
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const location = useLocation()

	const isCollectionPage = useMemo(() => {
		return location.pathname.includes("/collection/")
	}, [location])

	const [isExpanded, setIsExpanded] = useState(isCollectionPage)
	const [collectionData, setCollectionData] = useState<FlowNFTContract | null>(
		null
	)

	useEffect(() => {
		const fetchDocument = async (): Promise<void> => {
			const data = await fetchCollectionData(collection)
			setCollectionData(data)
		}
		fetchDocument()
	}, [collection])

	const baseStyles =
		"text-black flex justify-center rounded-[0.25rem] items-center focus:border-none cursor-pointer h-[1rem] w-[1rem]"

	const badgeCheckIcon = useMemo(() => {
		if (isVerified) {
			return (
				<svg
					className='w-[0.875rem] h-[0.875rem]'
					xmlns='http://www.w3.org/2000/svg'
					width='18'
					height='18'
					viewBox='0 0 14 14'
					fill='none'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.3869 2.41837C4.83705 2.38245 5.26439 2.20544 5.6081 1.91254C6.41016 1.22902 7.58983 1.22902 8.39189 1.91254C8.73559 2.20544 9.16294 2.38245 9.61308 2.41837C10.6635 2.5022 11.4977 3.33635 11.5815 4.38681C11.6174 4.83696 11.7945 5.2643 12.0874 5.60801C12.7709 6.41007 12.7709 7.58974 12.0874 8.3918C11.7945 8.7355 11.6174 9.16285 11.5815 9.61299C11.4977 10.6635 10.6635 11.4976 9.61308 11.5814C9.16294 11.6174 8.73559 11.7944 8.39189 12.0873C7.58983 12.7708 6.41016 12.7708 5.6081 12.0873C5.26439 11.7944 4.83705 11.6174 4.3869 11.5814C3.33644 11.4976 2.50229 10.6635 2.41846 9.61299C2.38254 9.16285 2.20553 8.7355 1.91263 8.3918C1.22912 7.58974 1.22912 6.41007 1.91263 5.60801C2.20553 5.2643 2.38254 4.83696 2.41846 4.38681C2.50229 3.33635 3.33644 2.5022 4.3869 2.41837ZM9.59497 6.09488C9.86834 5.82151 9.86834 5.37829 9.59497 5.10493C9.3216 4.83156 8.87839 4.83156 8.60502 5.10493L6.29999 7.40995L5.39497 6.50493C5.1216 6.23156 4.67839 6.23156 4.40502 6.50493C4.13165 6.7783 4.13165 7.22151 4.40502 7.49488L5.80502 8.89488C6.07839 9.16824 6.5216 9.16824 6.79497 8.89488L9.59497 6.09488Z'
						fill='#04E5A3'
					/>
				</svg>
			)
		}

		return null
	}, [isVerified])

	const collapsibleChevron = useMemo(() => {
		if (isCollapsible) {
			return (
				<svg
					data-testid='single-collection-chevron'
					className={cn({
						"rotate-0": !isExpanded,
						"rotate-180": isExpanded,
					})}
					width='14'
					height='8'
					viewBox='0 0 14 8'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0L7 8L14 0L0 0Z'
						fill={!isExpanded ? "#F8F9FA" : "#04e5a3"}
					/>
				</svg>
			)
		}
		return <div className='w-[14px] h-[8px]' />
	}, [isCollapsible, isExpanded])

	const renderLabel = useMemo(() => {
		const removeDashesName = collectionData?.collectionDisplay?.name?.replace(
			/-/g,
			" "
		)

		return removeDashesName ?? label
	}, [collectionData])

	return (
		<>
			{isCollectionPage ? (
				<>{children} </>
			) : (
				<div
					className={cn(
						"bg-[#ffffff0a] border-[1px] border-neutral-500 border-opacity-25 rounded-[0.375rem] px-[1rem] py-[0.75rem] cursor-pointer shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]",
						{
							"hover:bg-[#ffffff1f]": !isExpanded,
						}
					)}
				>
					<div
						key={collection}
						className={cn(
							" w-full font-montserrat text-[0.9375rem] font-[500] rounded-[0.375rem] text-white flex items-center justify-start gap-[0.375rem]"
						)}
					>
						<div
							className='flex items-center space-x-3 w-[70%]'
							onClick={() => {
								if (!isCheckboxHidden) {
									onChange(!isChecked)
								}
								if (!isCollapsible) {
									return
								}
								setIsExpanded(true)
							}}
						>
							{!isCheckboxHidden ? (
								<div
									className={cn(baseStyles, {
										"bg-primary": isChecked,
										"bg-transparent": !isChecked,
										"border-[1px] border-white": !isChecked,
										"border-none": isChecked,
									})}
								>
									{isChecked && <CheckIcon />}
								</div>
							) : null}
							<img
								className='w-[2rem] max-w-[2rem] max-h-[2rem] h-[2rem] rounded-[0.25rem]'
								src={
									collectionData
										? getSquareImage(name, collectionData)
										: FallbackImg
								}
								alt={name ? name : ""}
								onError={addFallbackImage}
							/>
							<TooltipText
								tooltipText={renderLabel}
								Comp={
									<div
										ref={ref}
										className='font-montserrat text-[0.875rem] max-w-[100px] truncate capitalize'
									>
										{renderLabel}
									</div>
								}
							/>
						</div>
						<div
							className='flex items-center justify-end ml-auto space-x-2 w-[30%]'
							onClick={() => {
								if (!isCollapsible) {
									return
								}
								setIsExpanded(!isExpanded)
							}}
						>
							<div className=''>{badgeCheckIcon}</div>
							<div className='font-[500] flex-shrink-0 w-fit text-[0.75rem]'>
								{count}
							</div>
							<div
								onClick={() => {
									if (!isCollapsible) {
										return
									}
									setIsExpanded(!isExpanded)
								}}
								className='hover:cursor-pointer'
							>
								{collapsibleChevron}
							</div>
						</div>
					</div>
					{isExpanded && isCollapsible ? (
						<div className='pt-2.5 gap-[8px]'> {children} </div>
					) : null}
				</div>
			)}
		</>
	)
}
