import PropTypes from "prop-types"
import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from "react"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { SortDirection } from "../MarketPlace/SortButton/shared"
import { ReactComponent as Caret } from "./icons/caret.svg"

type SortColumn = {
	column: string
	order: "asc" | "desc"
}

export type SortColumnOrNull = SortColumn | null

const getHeaderAlignmentClass = (
	align: "center" | "left" | "right" = "center"
): string => {
	switch (align) {
		case "center":
			return "mx-auto"
		case "left":
			return "mr-auto"
		case "right":
			return "ml-auto"
		default:
			return ""
	}
}

interface RenderParams<I> {
	field: {
		name: string
		title: string
	}
	item: I
}

export interface SortableTableField<I> {
	name: string
	// ideally generic but too strict ATM
	// name: Exclude<keyof ItemType, number | symbol>
	sortable?: boolean
	title: string
	align?: "center" | "left" | "right"
	customRender?: (renderParams: RenderParams<I>) => React.ReactNode
}

interface SortableTableProps<I> {
	onSort?: (args: SortColumnOrNull) => void
	initialSortConfig?: SortColumnOrNull
	items: Array<I>
	onClickRow?: ({
		e,
		item,
		index,
	}: {
		e: React.MouseEvent
		item: I
		index: number
	}) => void
	getItemKey?: (item: I) => string
	stickyHeaders?: boolean
	fields: Array<SortableTableField<I>>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	composeRowHref?: (item: any) => string
}

const SortableTable = <Item extends { id: string | number }>({
	fields,
	items,
	onSort = () => {},
	getItemKey = (item: Item) => `${item.id}`,
	initialSortConfig = null,
	onClickRow,
	stickyHeaders,
	composeRowHref,
}: PropsWithChildren<SortableTableProps<Item>>): React.ReactElement<
	SortableTableProps<Item>,
	React.FC<SortableTableProps<Item>>
> => {
	const [sortMode, setSortMode] = useState(initialSortConfig)

	const onSortClicked = useCallback(
		(name: string) => () => {
			if (sortMode?.column === name) {
				if (sortMode.order === SortDirection.Descending) {
					setSortMode({
						column: name,
						order: SortDirection.Ascending,
					})
					return
				} else {
					setSortMode({
						column: name,
						order: SortDirection.Descending,
					})
				}
			}
		},
		[sortMode]
	)

	useEffect(() => {
		onSort(sortMode)
	}, [sortMode])

	useEffect(() => {
		if (sortMode?.column === "blockTimestamp" && sortMode?.order === "desc") {
			Mixpanel.track(`Table Sorted`, {
				sortedBy: sortMode,
			})
		}
	}, [sortMode?.column])

	return (
		<div className='lg:px-1.5 lg:-mb-70'>
			<div
				className={`w-full rounded-md pb-2 SortableTable overflow-x-auto ${
					stickyHeaders ? "2xl:overflow-x-visible" : "2xl:overflow-x-auto "
				}`}
			>
				<table
					className='w-full border-separate bg-darkBg'
					style={{ borderSpacing: 0 }}
				>
					<thead
						className={`bg-[#04070B] ${
							stickyHeaders && "2xl:top-[130px] sticky"
						}`}
					>
						<tr>
							{fields?.map(
								({ name, title, sortable, align = "left" }, index) => (
									<th
										key={`${name}-${index}`}
										scope='col'
										className='th px-6 py-4 text-left text-base font-bold text-[#DDDDDD] uppercase tracking-wider'
									>
										<button
											type='button'
											className={`bg-transparent ${
												sortable ? "" : "cursor-default"
											} p-0 m-0 flex flex-row items-center ${getHeaderAlignmentClass(
												align
											)}`}
											disabled={!sortable}
											onClick={sortable ? onSortClicked(name) : () => {}}
										>
											{title}
											{sortable && (
												<Caret
													className={`fill-[#DDDDDD] ${
														sortMode?.column === name
															? "opacity-100"
															: "opacity-20 hover:opacity-30"
													} ${
														sortMode?.order === "desc"
															? "transform rotate-180"
															: ""
													} `}
													height={20}
												/>
											)}
										</button>
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody className='bg-transparent divide-y divide-white'>
						{items?.map((item, index) => (
							<tr
								onClick={e => onClickRow?.({ e, index, item })}
								key={`${getItemKey(item)}-${index}`} // Would need to imlpement as a part of the API a "get "
								className={`${
									onClickRow ? "cursor-pointer" : ""
								} hover:bg-gray-700 group`}
							>
								{fields?.map(field => (
									<td
										className={`group-hover:text-primary px-6 py-6 whitespace-nowrap text-${
											field.align ?? "left"
										}`}
										key={field.name}
									>
										{composeRowHref ? (
											<a
												href={composeRowHref(item)}
												className='group-hover:text-primary'
											>
												{field.customRender?.({ field, item }) ?? (
													<div
														className={`text-sm font-medium text-gray-900  text-${field.align}`}
													>
														{/*
															TS isn't sure name will be a valid key or that the resulting value will be renderable
															we could constrain SortableTableField.item to keyof I but that would break a lot of stuff
															we can't add an index signature cause it forces bad constaints on other stuff
															so we tell the compiler we got this
														*/}
														{
															item[
																field.name as keyof Item
															] as unknown as React.ReactNode
														}
													</div>
												)}
											</a>
										) : (
											<>
												{field.customRender?.({ field, item }) ?? (
													<div
														className={`text-sm font-medium text-gray-900 text-${field.align}`}
													>
														{/* see note above */}
														{
															item[
																field.name as keyof Item
															] as unknown as React.ReactNode
														}
													</div>
												)}
											</>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

SortableTable.defaultProps = {
	onSort: () => {},
}

SortableTable.propTypes = {
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	items: PropTypes.arrayOf(PropTypes.object).isRequired,

	onSort: PropTypes.func,
}

export default SortableTable
