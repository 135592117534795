import React, { useEffect, useMemo, useState } from "react"
import { inject, observer } from "mobx-react"
import { ReactComponent as NftCardTimer } from "../../../../assets/media/nftCard/nft_card_timer_icon.svg"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { getDurationPeriod } from "../../../../util/nftDataUtil"
import { ReactComponent as KeepInMindIcon } from "../../../../assets/media/loanInfo/keepInMindIcon.svg"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import {
	truncateString,
	getTokenFromType,
	RentalListingAvailableData,
	ListingStatus,
	getContractNameFromType,
	isContractWithValuation,
	FlowNFT,
	getContractAddressFromType,
	nftLocationDataFromListing,
	getNFTIdentifier,
	nftTypeAndIdToLocationData,
	SupportedTokens,
} from "flowty-common"
import { TooltipElement } from "../../../InfoText"
import {
	MAX_NAME_CHARACTERS,
	FLOWTY_FEE_RENTAL_TAX,
} from "../../../../util/settings"
import { ValuationLabel } from "../../../Shared/OverlayValuation"
import { getTokenSpotPrice } from "../../../../services/firestore/SpotPriceService"
import { Log } from "../../../../util/Log"
import { DelistRental, ReturnRental } from "../../../TxButtons/Rentals"
import { db, firestore } from "../../../../firebase"

import { getRoyaltyRateForNFT } from "../../../../services/firestore/RoyaltyService"
import numeral from "numeral"
import { Link } from "react-router-dom"
import { composeUserProfilePath } from "../../../../routes"
import { getItem } from "../../../../services/firestore/DocService"
import { useHybridCustodyContext } from "../../../../contexts/HybridCustodyContext"
import { getBestCollectionPrivatePath } from "../../../../util/getBestPrivatePath"
import { Alert } from "../../../Alert/Alert"
import { Row } from "../../../GridLayout/Row/Row"
import { Col } from "../../../GridLayout/Col/Col"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

interface RentalListingInfoProps extends AuthStoreProp {
	listing: RentalListingAvailableData
	isListingActive: boolean
	isListingExpired: boolean
	setListing: (listing: RentalListingAvailableData | null) => void
}

const RentalListingInfo: React.FC<RentalListingInfoProps> = ({
	authStore,
	listing,
	isListingActive,
	isListingExpired,
	setListing,
}) => {
	const token =
		"paymentTokenType" in listing
			? getTokenFromType(listing.paymentTokenType)
			: SupportedTokens.FLOW

	const [tokenPrice, setTokenPrice] = useState<number | null>(null)
	const [royaltyRate, setRoyaltyRate] = useState<number | null>(null)

	const contractName = getContractNameFromType(listing.nftType)
	const contractAddress = getContractAddressFromType(listing.nftType)
	const { iterateAndRunScript, hybridCustodyNFTStatus } =
		useHybridCustodyContext()
	const collectionIdentifier = `${contractAddress}.${contractName}`

	// We need to know who owns this NFT so that we can know who needs to be able
	// to return it
	const [owner, setOwner] = useState<string | null>(null)

	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []
	const isOwner = userAddresses.includes(owner || "")

	const isOrderListedByLoggedAccount =
		authStore?.loggedUser?.addr === listing?.flowtyStorefrontAddress

	useEffect(() => {
		const locationData = nftTypeAndIdToLocationData(
			listing.nftType,
			listing.nftID
		)
		const docID = getNFTIdentifier(locationData)

		firestore
			.collection("flowNFT")
			.doc(docID)
			.get()
			.then(doc => {
				if (!doc.exists) {
					return
				}

				const nft = doc.data() as FlowNFT
				setOwner(nft?.owner || "")
			})
			.catch(e => {
				Mixpanel.track("ERROR_FIREBASE_GETTING_NFT", {
					e,
				})
			})
	}, [])

	const nftProviderPathIdentifier = useMemo(() => {
		return authStore?.loggedUser?.addr !== owner
			? getBestCollectionPrivatePath(
					hybridCustodyNFTStatus?.[owner || ""]?.[collectionIdentifier] || [],
					contractAddress,
					contractName
			  )
			: ""
	}, [owner, authStore, hybridCustodyNFTStatus])

	useEffect(() => {
		iterateAndRunScript(
			[collectionIdentifier],
			authStore?.loggedUser?.childAccounts || {},
			authStore?.loggedUser?.addr || ""
		)
	}, [authStore?.loggedUser, listing])

	const hasValuationProvider = isContractWithValuation(contractName)
	const dtvLabel = hasValuationProvider ? (
		<ValuationLabel
			contractName={contractName}
			labelType={"DTV"}
			nftID={listing.nftID.toString()}
		/>
	) : undefined

	useEffect(() => {
		if (token === SupportedTokens.FLOW) {
			getTokenSpotPrice(token).then(sp => {
				Log("setTokenPrice", sp.value)
				setTokenPrice(sp.value)
			})
		} else {
			setTokenPrice(1)
		}
	}, [token])

	useEffect(() => {
		try {
			db.collection("rentalAvailable")
				.doc(listing.listingResourceID.toString())
				.onSnapshot(doc => {
					const snapshot = doc.data() as RentalListingAvailableData
					Log("snapshot", snapshot)
					if (snapshot.state === listing.state) {
						Log("states are equal, skipping..")
						return
					}

					setListing({
						...snapshot,
						blockTimestamp: listing.blockTimestamp,
					})
				})
		} catch (e) {
			Mixpanel.track("ERROR_FIREBASE_GETTING_RENTAL_AVAILABLE", {
				e,
			})
		}
	}, [])

	const renderActionBtn = (): React.ReactNode => {
		if (
			isOwner && // listing belongs to logged-in user
			(listing.state === "LISTED" || listing.state === "INVALID")
		) {
			return (
				<DelistRental
					isOwner={isOwner}
					isOrderListedByLoggedAccount={isOrderListedByLoggedAccount}
					rentalListing={listing}
				/>
			)
		}

		if (isListingActive) {
			if (
				owner &&
				isOwner &&
				listing.state === "RENTED" &&
				(owner === authStore?.loggedUser?.addr || nftProviderPathIdentifier)
			) {
				return (
					<ReturnRental
						rentalListing={listing}
						nftProviderPathIdentifier={nftProviderPathIdentifier}
						nftProviderAddress={owner}
					/>
				)
			}
			if (listing.state === "LISTED" && (listing.renter === null || isOwner)) {
				return null
			}
		}
		return null
	}

	const nameTooltip = (
		<h1 className='inline'>
			{truncateString(listing?.detail?.nft?.title, MAX_NAME_CHARACTERS)}
		</h1>
	)

	const calculatedValues = {
		costToRent: listing.amount + listing.deposit,
	}
	useEffect(() => {
		const handleAsync = async () => {
			const locationData = nftLocationDataFromListing(listing)
			const nftID = getNFTIdentifier(locationData)

			const nft = await getItem<FlowNFT>(nftID, "flowNFT")
			if (!nft) {
				throw new Error("could not find nft")
			}

			return getRoyaltyRateForNFT(nft)
		}

		handleAsync()
			.then(res => setRoyaltyRate(res))
			.catch(err => {
				Mixpanel.track("ERROR_GETTING_ROYALTY_RATE", {
					err,
				})
			})
	}, [listing])

	return (
		<>
			<div className='nft-info-card bg-greyDark w-full'>
				<Row className='nft-info-row flex'>
					<Col>
						<div>
							<TooltipElement
								Comp={nameTooltip}
								tooltipText={listing?.detail?.nft?.title || ""}
								tooltipId='nft-title-tooltip'
							/>
						</div>
					</Col>
				</Row>
				<div className='nft-info-separator bg-secondary' />
				<table className='table-borderless'>
					<tbody>
						<tr>
							<td>Rental Fee</td>
							<td className='text-end'>
								<div className='flex-row-reverse flex items-center'>
									{token === SupportedTokens.FLOW && tokenPrice && (
										<p className='text-sm'>
											(USD $
											{numeral((listing?.amount || 0) * tokenPrice).format(
												"0,0.00"
											)}
											)
										</p>
									)}
									<TokenAmount
										amount={listing?.amount || 0}
										isSmall
										token={token}
									/>
								</div>
							</td>
						</tr>
						<tr className='border-b border-white'>
							<td>Refundable Deposit</td>
							<td className='flex justify-end'>
								<div className='flex-row-reverse flex items-center'>
									{token === SupportedTokens.FLOW && tokenPrice && (
										<p className='text-sm'>
											(USD $
											{numeral((listing?.deposit || 0) * tokenPrice).format(
												"0,0.00"
											)}
											)
										</p>
									)}
									<TokenAmount
										amount={listing?.deposit || 0}
										isSmall
										token={token}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>Total Due Upfront</td>
							<td className='text-end'>
								<div className='flex-row-reverse flex items-center'>
									{token === SupportedTokens.FLOW && tokenPrice && (
										<p className='text-sm'>
											(USD $
											{numeral(
												(calculatedValues?.costToRent || 0) * tokenPrice
											).format("0,0.00")}
											)
										</p>
									)}
									<TokenAmount
										amount={calculatedValues?.costToRent || 0}
										isSmall
										token={token}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<NftCardTimer className='inline' />
								<p className='pt-1 inline ms-1'>Duration</p>
							</td>
							<td className='flex justify-end'>
								{listing && getDurationPeriod(listing.term)}
							</td>
						</tr>

						<tr>
							<td>
								<p className='pt-1 inline ms-1'>Listed By</p>
							</td>
							<td className='flex justify-end'>
								<Link
									to={composeUserProfilePath(listing.flowtyStorefrontAddress)}
								>
									{listing.flowtyStorefrontAddress ===
									authStore?.loggedUser?.addr
										? "You"
										: listing.flowtyStorefrontAddress}
								</Link>
							</td>
						</tr>
						{listing.renter && (
							<tr>
								<td>
									<p className='pt-1 inline ms-1'>Rented By</p>
								</td>
								<td className='flex justify-end'>
									<Link to={composeUserProfilePath(listing.renter)}>
										{listing.renter === authStore?.loggedUser?.addr
											? "You"
											: listing.renter}
									</Link>
								</td>
							</tr>
						)}
						{listing?.valuations?.aggregate && (
							<tr>
								<td>
									<td>{dtvLabel}</td>
								</td>
								<td className='flex justify-end'>
									{listing.valuations.aggregate.depositToValueRatio.toFixed(2)}x
								</td>
							</tr>
						)}
						{isOwner && listing.state !== ListingStatus.Expired && (
							<>
								{royaltyRate != null && (
									<>
										<tr>
											<td>
												<u>Owner Information:</u>
											</td>
										</tr>
										<tr>
											<td>Rental Fee Share:</td>
											<td className='text-end'>
												<div className='flex-row-reverse flex items-center'>
													{token === SupportedTokens.FLOW && tokenPrice && (
														<p className='text-sm'>
															(USD $
															{numeral(
																(calculatedValues?.costToRent || 0) * tokenPrice
															).format("0,0.00")}
															)
														</p>
													)}
													<TokenAmount
														amount={
															(listing?.amount ?? 0) -
															((listing?.amount ?? 0) * royaltyRate +
																(listing?.amount ?? 0) * FLOWTY_FEE_RENTAL_TAX)
														}
														isSmall
														token={token}
													/>
												</div>
											</td>
										</tr>
										<tr>
											<td className='pr-1.5'>Received if Renter Defaults:</td>
											<td className='text-end'>
												<div className='flex-row-reverse flex items-center'>
													{token === SupportedTokens.FLOW && tokenPrice && (
														<p className='text-sm'>
															(USD $
															{numeral(
																(calculatedValues?.costToRent || 0) * tokenPrice
															).format("0,0.00")}
															)
														</p>
													)}
													<TokenAmount
														amount={
															(listing?.deposit ?? 0) -
															(listing?.deposit ?? 0) * royaltyRate
														}
														isSmall
														token={token}
													/>
												</div>
											</td>
										</tr>
									</>
								)}
							</>
						)}
					</tbody>
				</table>
				<div className='flex m-auto justify-end w-fit'>
					{!isListingExpired &&
						listing?.state !== ListingStatus.Delisted &&
						listing?.state !== ListingStatus.Repaid &&
						renderActionBtn()}
				</div>
				{!isOrderListedByLoggedAccount &&
					isOwner &&
					listing.state === "LISTED" && (
						<div className='py-2 text-sm font-normal text-center text-orange-500'>
							NFT was listed while connected with
							{` ${listing?.flowtyStorefrontAddress}`} as Main. Please sign in
							with {` ${listing?.flowtyStorefrontAddress}`} to delist.
						</div>
					)}
			</div>
			{!isListingExpired && listing?.state === ListingStatus.Listed && (
				<Alert variant='dark' className='mt-2'>
					{isOwner ? (
						<div className='flex items-center'>
							<KeepInMindIcon className='mt-1 text-xl me-3' />
							<div className='w-full'>
								<h5 className='text-xl text-white'>Please Note</h5>
								<p className='mt-2 text-lightText'>
									There is no guarantee that your NFT will be returned. If the
									Renter does not return the NFT (defaults), you will receive
									the deposit (after collection royalty) instead of your NFT.
								</p>
							</div>
						</div>
					) : (
						<div className='flex items-center'>
							<KeepInMindIcon className='mt-1 text-xl me-3' />
							<div className='w-full'>
								<h5 className='text-xl text-white'>Please Note</h5>
								<p className='mt-2 text-lightText'>
									{listing.renter === null ||
									listing.renter === authStore?.loggedUser?.addr ? (
										<p>
											If you do not return the NFT by the end of the rental
											term, you will forfeit your refundable deposit.
											<br />
											<br />
											Please note that some platforms or wallets may have
											limitations on withdrawing NFTs, which is outside of
											Flowty&apos;s control. Please ensure that you fully
											understand any restrictions that may impact your ability
											to return a rented NFT.
										</p>
									) : (
										`This is a private listing and can only be filled by the address ${listing.renter}`
									)}
								</p>
							</div>
						</div>
					)}
				</Alert>
			)}
		</>
	)
}

export default inject("authStore")(observer(RentalListingInfo))
