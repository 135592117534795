import { useEffect, useRef, useCallback } from "react"

const useInfiniteScroll = (fetchMore: () => void, hasMore: boolean) => {
	const sentinelRef = useRef<HTMLDivElement | null>(null)
	const observer = useRef<IntersectionObserver | null>(null)

	const observerCallback = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			if (entries[0].isIntersecting && hasMore) {
				fetchMore()
			}
		},
		[fetchMore, hasMore]
	)

	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect()
		}

		observer.current = new IntersectionObserver(observerCallback, {
			rootMargin: "0px", // Adjust this value as needed
		})

		if (sentinelRef.current) {
			observer.current.observe(sentinelRef.current)
		}

		return () => {
			if (observer.current) {
				observer.current.disconnect()
			}
		}
	}, [observerCallback])

	return sentinelRef
}

export default useInfiniteScroll
