import { useField } from "formik"
import React, { useMemo } from "react"
import {
	AccountSummaries,
	AccountSummary,
	RentListingOrderType,
} from "../../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { RentListingStep1 } from "./RentListingStep1"
import { NoCatalogNFTWrapper } from "../../common/NoCatalogNFTWrapper"
import { OpensearchFlowNFT } from "flowty-common"
import { DapperDisplay } from "../../common/DapperDisplay"
import { Flowty } from "flowty-sdk"
import { RentListingStep2 } from "./RentListingStep2"

const FLOWTY_FEE_RENTAL_TAX = 0.05

interface RentListingProps {
	isCatalog: boolean
	isDapper: boolean
	accountSummaries: AccountSummaries
	selectedAccount: AccountSummary
	setSelectedAccount: (account: AccountSummary) => void
	hasChildAccounts: boolean
	rentStep: number
	rentData: RentListingOrderType
	rentFormError: boolean
	openSearchFlowNFT: OpensearchFlowNFT | null
	flowty: Flowty
}

export interface RentalFee {
	flowtyFee?: number
	refundableDeposit?: number
	rentalDepositReceived: number
	rentalDepositRoyalty?: number
	rentalFeeReceived: number
	rentalFee?: number
	rentalFeeRoyalty?: number
}

export const RentListing: React.FunctionComponent<RentListingProps> = ({
	isCatalog,
	isDapper,
	accountSummaries,
	selectedAccount,
	setSelectedAccount,
	hasChildAccounts,
	rentStep,
	rentData,
	rentFormError,
	openSearchFlowNFT,
	flowty,
}) => {
	const [rentalFee] = useField({ name: `rentalFee` })
	const [refundableDeposit] = useField({ name: `refundableDeposit` })

	const { royaltyRate, isParentDapper } = useFlowtyListingModalContext()

	const rentalFeeRoyalty = useMemo(
		() => rentalFee.value * (royaltyRate || 0),
		[rentalFee, royaltyRate]
	)
	const rentalDepositRoyalty = useMemo(
		() => refundableDeposit.value * (royaltyRate || 0),
		[refundableDeposit, royaltyRate]
	)
	const rentalDepositReceived = useMemo(
		() => refundableDeposit.value - rentalDepositRoyalty,
		[refundableDeposit]
	)

	const flowtyFee = useMemo(
		() => rentalFee.value * FLOWTY_FEE_RENTAL_TAX,
		[rentalFee]
	)
	const rentalFeeReceived = useMemo(
		() => rentalFee.value - (rentalFeeRoyalty + flowtyFee),
		[rentalFee]
	)

	return (
		<>
			{!isCatalog ? (
				<NoCatalogNFTWrapper
					text={"Rental listings are not permitted for this NFT collection"}
				/>
			) : (!hasChildAccounts && isDapper) || isParentDapper ? (
				<DapperDisplay openSearchFlowNFT={openSearchFlowNFT} />
			) : (
				<div className='w-full h-full flex flex-col justify-between'>
					{rentStep === 1 ? (
						<RentListingStep1
							isDapper={isDapper}
							hasChildAccounts={hasChildAccounts}
							selectedAccount={selectedAccount}
							setSelectedAccount={setSelectedAccount}
							accountSummaries={accountSummaries}
							rentData={rentData}
							rentFormError={rentFormError}
							flowty={flowty}
						/>
					) : (
						<RentListingStep2
							rentalFees={
								{
									flowtyFee,
									refundableDeposit: refundableDeposit.value,
									rentalDepositReceived,
									rentalDepositRoyalty,
									rentalFee: rentalFee.value,
									rentalFeeReceived,
									rentalFeeRoyalty,
								} as RentalFee
							}
							rentalListingDuration={Number(rentData.rentalListingDuration)}
							rentFormError={rentFormError}
						/>
					)}
				</div>
			)}
		</>
	)
}
