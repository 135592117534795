import axios from "axios"
import { apiURL } from "../util/settings"
import { auth } from "../firebase"

interface OfferHiddenResponse {
	offerID?: number
	hidden?: boolean
	success?: boolean
	error?: string
}

const setHidden = async (
	offerResourceID: string,
	hidden: boolean
): Promise<OfferHiddenResponse> => {
	/*
		POST apiURL/offers/hide
		{
			"offerID": 1234,
			"hidden": true
		}
 */

	const authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""

	const url = `${apiURL}/offers/hide`
	const data = {
		hidden,
		offerID: offerResourceID,
	}
	const headers = {
		Authorization: authToken,
	}
	const res = await axios.post<OfferHiddenResponse>(url, data, { headers })

	if (res.status !== 200) {
		throw new Error("Unable to hide offer, please try again later")
	}

	return res.data
}

export const unhideOffer = async (
	offerResourceID: string
): Promise<OfferHiddenResponse> => {
	return setHidden(offerResourceID, false)
}

export const hideOffer = async (
	offerResourceID: string
): Promise<OfferHiddenResponse> => {
	return setHidden(offerResourceID, true)
}
