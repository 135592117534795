import React from "react"
import { getListingExpirationTime } from "../../utils/FlowtyModalUtils"

interface ListingExpirationProps {
	listingDuration: number
	text: string
}

export const ListingExpiration: React.FunctionComponent<
	ListingExpirationProps
> = ({ listingDuration, text }) => {
	if (!listingDuration) {
		return null
	}
	return (
		<>
			{listingDuration && (
				<>
					<p className='text-base font-semibold mt-6 text-white'>
						If Your {text}
					</p>
					<div className='flex flex-col mt-4'>
						<div className='flex justify-between'>
							<p className='text-sm font-normal text-white'>
								Your Listing Will Expire On
							</p>
							<p className='text-sm font-bold text-white'>
								{" "}
								{getListingExpirationTime(listingDuration)}
							</p>
						</div>
					</div>
				</>
			)}{" "}
		</>
	)
}
