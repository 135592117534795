import { Timestamp } from "firebase/firestore"
import { Card } from "flowty-common"
import {
	LoanRentalFilteredData,
	LostAndFoundNFTTicketData,
	LostAndFoundScriptResponse,
} from "flowty-sdk"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Log } from "../../../../../util/Log"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import {
	subscribeToLoanBorrower,
	subscribeToRentalBorrower,
} from "../../../firebase/LoanRentalQueries"
import { FilterWrapper } from "../Filters/FilterWrapper"
import { UserActionsListingTypeFilter } from "../Filters/ActionsCenterListingTypeFilter/ActionsCenterListingTypeFilter"
import { ReactComponent as ListingIcon } from "../Filters/assets/listing-type-icon.svg"
import { flowty } from "../../../../../config/config"

interface LoanRentalActionsProps {
	loggedUserAddress?: string
	accountSummaryAddresses?: string[]
}

interface LoanRentalActionsValues {
	allUserActionCenterItems: Array<
		LoanRentalFilteredData | LostAndFoundNFTTicketData
	>
	isLoading: boolean
	traitsFilters: React.ReactNode
	isFiltered: boolean
}

export const useActionCenter = ({
	loggedUserAddress,
	accountSummaryAddresses,
}: LoanRentalActionsProps): LoanRentalActionsValues => {
	const [allLoanBorrowerActivity, setAllLoanBorrowerActivity] = useState<
		Array<LoanRentalFilteredData>
	>([])
	const [allRentalBorrowerActivity, setAllRentalBorrowerActivity] = useState<
		Array<LoanRentalFilteredData>
	>([])
	const [lostAndFoundTickets, setLostAndFoundTickets] = useState<
		Array<LostAndFoundNFTTicketData>
	>([])
	const [filterListingType, setFilterListingType] = useState<string>("all")
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const getLoanBorrowerDocs = useCallback(
		async (userAddress: string) => {
			try {
				await subscribeToLoanBorrower({
					callbackFn: docs => {
						const docsList: LoanRentalFilteredData[] = []
						docs.forEach(doc => {
							if (!doc) return
							docsList.push({
								enabledAutoRepayment: doc.enabledAutoRepayment,
								listingResourceID: doc.listingResourceID,
								loanAmount: +doc.amount,
								nftContractStoragePath: String(
									doc.detail?.nft?.contract?.contractMetadata?.storagePath
								),
								nftData: {
									...(doc.detail?.card as Card),
								},
								nftID: doc.nftID,
								nftType: doc.nftType,
								paymentTokenName: doc.paymentTokenName,
								repaymentDue:
									doc.derivations?.calculatedValues?.repaymentDue ?? 0,
								settleDeadline: Number(doc.settleDeadline?.seconds),
								term: +doc.term,
								type: "loan",
							})
						})
						setAllLoanBorrowerActivity(docsList)
					},
					userAddress: userAddress,
				})
			} catch (e) {
				Mixpanel.track("ACTIVITY_CENTER_LOAN_SNAPSHOT_ERROR", {
					error: e,
				})
			}
		},
		[loggedUserAddress]
	)

	const getRentalBorrowerDocs = useCallback(
		async (userAddresses: string[]) => {
			try {
				await subscribeToRentalBorrower({
					callbackFn: docs => {
						const docsList: LoanRentalFilteredData[] = []
						docs.forEach(doc => {
							if (!doc) return
							const settleDate = doc.settleDeadline as unknown as Timestamp
							docsList.push({
								enabledAutoRepayment: doc.enabledAutomaticReturn,
								nftContractStoragePath: String(
									doc.listingAvailable?.detail?.nft?.contract?.contractMetadata
										?.storagePath
								),
								nftData: {
									...(doc.listingAvailable?.detail?.card as Card),
								},
								nftID: doc.listingAvailable?.nftID,
								nftType: doc.listingAvailable?.nftType,
								paymentTokenName: doc.listingAvailable?.paymentTokenName,
								rentalFee: doc.amount,
								rentalRefundableDeposit: doc.deposit,
								rentalResourceID: String(doc.rentalResourceID),
								renterAddress: doc.renterAddress,
								settleDeadline: +settleDate.seconds,
								term: doc.listingAvailable?.term,
								type: "rental",
							})
						})
						setAllRentalBorrowerActivity(docsList)
					},
					userAddresses: userAddresses,
				})
			} catch (e) {
				Mixpanel.track("ACTIVITY_CENTER_RENTAL_SNAPSHOT_ERROR", {
					error: e,
				})
				Log("error getting rentals", e)
			}
		},
		[loggedUserAddress]
	)

	const getLostAndFoundTickets = useCallback(async () => {
		setIsLoading(true)
		try {
			const response: LostAndFoundScriptResponse[] =
				await flowty.scripts.getLostAndFoundTickets(String(loggedUserAddress))

			const lostAndFoundFormattedTickets: LostAndFoundNFTTicketData[] =
				response.map(ticket => {
					const collectionAddress = ticket.typeIdentifier.split(".")[1]
					const collectionName = ticket.typeIdentifier.split(".")[2]
					return {
						catalogIdentifier: ticket.catalogIdentifier,
						collectionAddress: `0x${collectionAddress}`,
						collectionName: collectionName,
						description: ticket.description,
						memo: ticket.memo,
						name: ticket.name,
						nftID: ticket.nftID,
						redeemed: ticket.redeemed,
						redeemer: ticket.redeemer,
						thumbnail: ticket.thumbnail,
						ticketID: ticket.ticketID,
						typeIdentifier: ticket.typeIdentifier,
					} as LostAndFoundNFTTicketData
				})
			setLostAndFoundTickets(lostAndFoundFormattedTickets)
			setIsLoading(false)
		} catch (e) {
			console.log("error getting lost and found", e)
			setIsLoading(false)
		}
	}, [loggedUserAddress])

	const sortFn = (
		a: LoanRentalFilteredData,
		b: LoanRentalFilteredData
	): number => {
		return a.settleDeadline - b.settleDeadline
	}

	const allUserActionCenterItems = useMemo(() => {
		if (filterListingType === "loan") {
			return allLoanBorrowerActivity.sort(sortFn)
		}
		if (filterListingType === "rental") {
			return allRentalBorrowerActivity.sort(sortFn)
		}
		if (filterListingType === "lostAndFound") {
			return lostAndFoundTickets
		}
		const loanAndRentals = [
			...allLoanBorrowerActivity,
			...allRentalBorrowerActivity,
		]
		loanAndRentals.sort(sortFn)
		return [...loanAndRentals, ...lostAndFoundTickets]
	}, [
		filterListingType,
		allLoanBorrowerActivity,
		allRentalBorrowerActivity,
		lostAndFoundTickets,
	])

	const fetchItems = async (): Promise<void> => {
		if (loggedUserAddress) {
			setIsLoading(true)
			if (filterListingType === "all") {
				await getLoanBorrowerDocs(loggedUserAddress)
				await getRentalBorrowerDocs(accountSummaryAddresses ?? [])
				await getLostAndFoundTickets()
			}
			if (filterListingType === "loan") {
				await getLoanBorrowerDocs(loggedUserAddress)
			}
			if (filterListingType === "rental") {
				await getRentalBorrowerDocs(accountSummaryAddresses ?? [])
			}
			if (filterListingType === "lostAndFound") {
				await getLostAndFoundTickets()
			}
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchItems()
	}, [filterListingType])

	const traitsFilters = (
		<FilterWrapper
			title={"Type"}
			child={
				<UserActionsListingTypeFilter
					filter={filterListingType}
					setFilter={setFilterListingType}
				/>
			}
			filterIcon={<ListingIcon />}
		/>
	)

	return {
		allUserActionCenterItems,
		isFiltered: filterListingType !== "all",
		isLoading,
		traitsFilters,
	}
}
