import React from "react"
import { ListingStatus, assertNever } from "flowty-common"

interface NftLabelProps {
	type: ListingStatus
	className?: string
}

const NftLabel: React.FC<NftLabelProps> = ({ type, className }) => {
	let nftLabelClassName = null
	let labelName = null

	switch (type) {
		case ListingStatus.Listed:
			nftLabelClassName = "listed"
			labelName = "LISTED"
			break
		case ListingStatus.Funded:
			nftLabelClassName = "funded"
			labelName = "FUNDED"
			break
		case ListingStatus.Repaid:
			nftLabelClassName = "repaid"
			labelName = "REPAID"
			break
		case ListingStatus.Delisted:
			nftLabelClassName = "delisted"
			labelName = "DELISTED"
			break
		case ListingStatus.Cancelled:
			nftLabelClassName = "cancelled"
			labelName = "CANCELLED"
			break
		case ListingStatus.Settled:
			nftLabelClassName = "settled"
			labelName = "DEFAULTED"
			break
		case ListingStatus.Invalid:
			nftLabelClassName = "invalid"
			labelName = "Invalid"
			break
		case ListingStatus.Rented:
			nftLabelClassName = "rented"
			labelName = "Rented"
			break
		case ListingStatus.Returned:
			nftLabelClassName = "returned"
			labelName = "Returned"
			break
		case ListingStatus.Expired:
			nftLabelClassName = "expired"
			labelName = "Expired"
			break
		case ListingStatus.Purchased:
			nftLabelClassName = "purchased"
			labelName = "Purchased"
			break
		default:
			assertNever(type)
	}

	return (
		<div className={className}>
			<p className={`nft-label ${nftLabelClassName}`}>{labelName}</p>
		</div>
	)
}

export default NftLabel
