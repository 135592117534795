export const ONE_MINUTE_IN_SECONDS = 60 * 60
export const ONE_DAY_IN_SECONDS = 60 * 60 * 24
// TODO: we need to pull this value from Flow
export const FLOWTY_INTEREST_FEE = 0.1 // 10%

export interface LoanInfoValues {
	amount?: number
	interest?: number
	term?: number
	marketplaceAmount?: number
	periodicInterest?: number
	repayment?: number
}

export interface CalculatedLoanValues {
	amount: number
	interest: number
	term: number
	marketplaceAmount: number
	periodicInterest: number

	royaltyFee: number
	flowtyInterestFee: number
	repaymentDue: number
	totalRepayment: number
}

export const calcInterestAmount = (interest: number, amount: number): number =>
	amount * interest

export const calcAPRWithLoanAmounts = (
	loanAmount: number,
	repaymentAmount: number,
	term: number
) => {
	try {
		const apr =
			(365 / (term / ONE_DAY_IN_SECONDS)) * (repaymentAmount / loanAmount - 1)
		return !Number.isNaN(apr) ? apr : 0
	} catch (e) {
		return 0
	}
}

export const calcAPRInterest = (interest: number, term: number): number => {
	// ((Repayment / Loan Amount) - 1)*(365 / Duration)
	try {
		const apr = (365 / (term / ONE_DAY_IN_SECONDS)) * interest * 100
		return !Number.isNaN(apr) ? apr : 0
	} catch (e) {
		return 0
	}
}

export const numToSeconds = (num: number) => num * ONE_DAY_IN_SECONDS

/*
										Borrower Loan Terms						Appears in Marketplace
Amount (Input one)	(A) Amount borrower receives	(B) Amount lender funds
Rate (Input one)		(C) Rate charged to borrower	(D) Rate paid to lender

Definitions:

(F) = Flowty Fee Rate
(R) = Royalty Rate
(Z) = Interest charged to borrower
(X) = Total Repayment from borrower
(Y) = Total Repayment received by lender
(M) = Flowty Fee
(N) = Royalty

Known metrics:

Z = A*C -> (Interest charged to borrower = loan amount * interest rate)
X = A + Z -> (Total repayment from borrower = Loan Amount + Interest)
M = F*Z -> (Flowty fee = Flowty fee rate * interest)
N = R*A -> (Royalty = Royalty Rate * Loan Amount)
B = A + M + N -> (Lender funds = Loan Amount + Flowty Fee + Royalty)
Y = X + N OR B*(1 + D) -> (Repayment received by lender = Repayment by borrower + Royalty = Lender Funds * (1 + Rate Paid to Lender)
D = Y/B – 1 -> (Rate paid to lender = Total Repayment to Lender / Lender funds – 1)
C = Z/A -> (Rate charged to borrower = interest / loan amount)
 */

// Z = A*C -> (Interest charged to borrower = loan amount * interest rate)
export const calcBorrowerInterestOwed = (loanInfoValues: LoanInfoValues) => {
	if (!loanInfoValues.amount || !loanInfoValues.interest) {
		return 0
	}

	return (
		Math.round(
			Math.round(loanInfoValues.amount * 100) * loanInfoValues.interest
		) / 100
	)
}

// X = A + Z -> (Total repayment from borrower = Loan Amount + Interest)
export const calcTotalBorrowerRepayment = (loanInfoValues: LoanInfoValues) => {
	if (!loanInfoValues?.amount || !loanInfoValues?.interest) {
		return 0
	}

	return (
		Math.round(
			Math.round(loanInfoValues.amount * 100) +
				calcBorrowerInterestOwed(loanInfoValues)
		) / 100
	)
}

// M = F*Z -> (Flowty fee = Flowty fee rate * interest)
export const calcFlowtyFeeAmount = (loanInfoValues: LoanInfoValues) =>
	FLOWTY_INTEREST_FEE * calcBorrowerInterestOwed(loanInfoValues)

// N = R*A -> (Royalty = Royalty Rate * Loan Amount)
export const calcRoyaltyFeeAmount = (
	loanInfoValues: LoanInfoValues,
	royaltyAmount?: number
) => {
	if (!loanInfoValues.amount || !royaltyAmount) {
		return 0
	}

	return (
		Math.round(Math.round(loanInfoValues.amount * 100) * royaltyAmount) / 100
	)
}

// B = A + M + N -> (Lender funds = Loan Amount + Flowty Fee + Royalty)
export const calcLenderFundingAmount = (
	loanInfoValues: LoanInfoValues,
	royaltyAmount?: number
) => {
	if (!loanInfoValues.amount) {
		return 0
	}

	const flowtyFee = calcFlowtyFeeAmount(loanInfoValues)
	const royaltyFee = calcRoyaltyFeeAmount(loanInfoValues, royaltyAmount)
	return (
		(Math.round(loanInfoValues.amount * 100) +
			Math.round(flowtyFee * 100) +
			Math.round(royaltyFee * 100)) /
		100
	)
}

// Y = X + N OR B*(1 + D) -> (Repayment received by lender = Repayment by borrower + Royalty = Lender Funds * (1 + Rate Paid to Lender)
export const calcLenderRepaymentAmount = (
	loanInfoValues: LoanInfoValues,
	royaltyAmount?: number
) => {
	const borrowerRepayment = calcTotalBorrowerRepayment(loanInfoValues)
	const royaltyFee = calcRoyaltyFeeAmount(loanInfoValues, royaltyAmount)
	return (
		(Math.round(borrowerRepayment * 100) + Math.round(royaltyFee * 100)) / 100
	)
}

// D = Y/B – 1 -> (Rate paid to lender = Total Repayment to Lender / Lender funds – 1)
export const calcLenderInterest = (
	loanInfoValues: LoanInfoValues,
	royaltyAmount?: number
) => {
	const lenderRepayment = calcLenderRepaymentAmount(
		loanInfoValues,
		royaltyAmount
	)
	const lenderFunds = calcLenderFundingAmount(loanInfoValues, royaltyAmount)
	return lenderRepayment / lenderFunds - 1
}

// C = Z/A -> (Rate charged to borrower = interest / loan amount)
export const calcBorrowerInterestRate = (loanInfoValues: LoanInfoValues) => {
	if (!loanInfoValues.amount) {
		return 0
	}

	const borrowerInterest = calcBorrowerInterestOwed(loanInfoValues)
	return borrowerInterest / loanInfoValues.amount
}

export const borrowerToLenderValues = (
	loanInfoValues: LoanInfoValues,
	royalty: number
): LoanInfoValues => {
	// Use inputs "Amount borrower receives (A)" and "Rate charged to borrower (C)"
	// Solve for "Amount lender funds (B)" and "Rate paid to lender (D)"
	// B = A + M + N
	// D = Y/B – 1
	const lenderAmount = calcLenderFundingAmount(loanInfoValues, royalty)
	const lenderRate = calcLenderInterest(loanInfoValues, royalty)

	return {
		amount: loanInfoValues.amount,
		interest: loanInfoValues.interest,
		marketplaceAmount: lenderAmount,
		periodicInterest: lenderRate,
		term: loanInfoValues.term,
	}
}

export const solveWithAmountAndInterest = (
	loanInfoValues: LoanInfoValues,
	royalty: number
): CalculatedLoanValues | null => {
	// User inputs A and C; solve for B and D
	// Z = A * C -> (Interest charged to borrower = loan amount * interest rate)
	// M = F * Z -> (Flowty fee = Flowty fee rate * interest)
	// N = R * A -> (Royalty = Royalty Rate * Loan Amount)
	// B = A + M + N
	// X = A + Z -> (Total repayment from borrower = Loan Amount + Interest)
	// Y = X + N OR B*(1 + D) -> (Repayment received by lender = Repayment by borrower + Royalty = Lender Funds * (1 + Rate Paid to Lender)
	// D = Y / B – 1

	// foo = null
	// foo = undefined
	// foo = 0

	if (
		loanInfoValues.amount === null ||
		loanInfoValues.amount === undefined ||
		loanInfoValues.interest === null ||
		loanInfoValues.interest === undefined
	) {
		return null
	}

	const interestCharged = loanInfoValues.amount * loanInfoValues.interest
	const flowtyFee = FLOWTY_INTEREST_FEE * interestCharged
	const royaltyAmount = royalty * loanInfoValues.amount
	const marketplaceAmount = loanInfoValues.amount + flowtyFee + royaltyAmount
	const borrowerRepayment = loanInfoValues.amount + interestCharged
	const calculatedLenderRepaymentAmount = borrowerRepayment + royaltyAmount
	const periodicInterest =
		calculatedLenderRepaymentAmount / marketplaceAmount - 1

	return {
		amount: loanInfoValues.amount,
		flowtyInterestFee: flowtyFee,
		interest: loanInfoValues.interest,
		marketplaceAmount,
		periodicInterest,
		repaymentDue: borrowerRepayment,
		royaltyFee: royaltyAmount,
		term: loanInfoValues.term ? loanInfoValues.term : 0,
		totalRepayment: borrowerRepayment + royaltyAmount,
	}
}

export const solveWithAmountAndRepayment = (
	loanInfoValues: LoanInfoValues,
	royalty: number
): CalculatedLoanValues | null => {
	if (
		loanInfoValues.amount === null ||
		loanInfoValues.amount === undefined ||
		loanInfoValues.repayment === null ||
		loanInfoValues.repayment === undefined
	) {
		return null
	}

	const borrowerRepayment = loanInfoValues.repayment
	const { amount } = loanInfoValues
	const interestCharged = borrowerRepayment - amount
	const flowtyFeePrice = FLOWTY_INTEREST_FEE * interestCharged
	const royaltyAmount = royalty * loanInfoValues.amount
	const marketplaceAmount =
		loanInfoValues.amount + flowtyFeePrice + royaltyAmount
	const calculatedLenderRepaymentAmount = borrowerRepayment + royaltyAmount
	const loanRate = borrowerRepayment / loanInfoValues.repayment - 1
	const periodicInterest =
		calculatedLenderRepaymentAmount / marketplaceAmount - 1

	return {
		amount,
		flowtyInterestFee: flowtyFeePrice,
		interest: loanRate,
		marketplaceAmount,
		periodicInterest,
		repaymentDue: borrowerRepayment,
		royaltyFee: royaltyAmount,
		term: loanInfoValues.term ? loanInfoValues.term : 0,
		totalRepayment: borrowerRepayment,
	}
}

export const solveWithMarketplaceAmountAndPeriodicInterest = (
	loanInfoValues: LoanInfoValues,
	royalty: number
): CalculatedLoanValues | null => {
	// User inputs B and D; solve for A and C
	// (F) = Flowty Fee Rate
	// (R) = Royalty Rate
	// Y = B * (1 + D) -> (Repayment received by lender = Lender Funds * (1 + Rate Paid to Lender)
	// Z = (Y – B) / (1 – F)
	// A = (Y – Z) / (1 + R)
	// C = Z / A
	if (
		loanInfoValues.marketplaceAmount === null ||
		loanInfoValues.marketplaceAmount === undefined ||
		loanInfoValues.periodicInterest === null ||
		loanInfoValues.periodicInterest === undefined
	) {
		return null
	}

	const repaymentReceived =
		loanInfoValues.marketplaceAmount * (1 + loanInfoValues.periodicInterest)

	const borrowerInterestAmount =
		(repaymentReceived - loanInfoValues.marketplaceAmount) /
		(1 - FLOWTY_INTEREST_FEE)

	const loanAmount =
		(repaymentReceived - borrowerInterestAmount) / (1 + royalty)

	const borrowerInterest = borrowerInterestAmount / loanAmount

	const borrowerRepayment = borrowerInterestAmount + loanAmount

	const royaltyFee = calcRoyaltyFeeAmount(
		{ amount: loanAmount, interest: borrowerInterest },
		royalty
	)

	const flowtyFee = calcFlowtyFeeAmount({
		amount: loanAmount,
		interest: borrowerInterest,
	})

	return {
		amount: loanAmount,
		flowtyInterestFee: flowtyFee,
		interest: borrowerInterest,
		marketplaceAmount: loanInfoValues.marketplaceAmount,
		periodicInterest: loanInfoValues.periodicInterest,
		repaymentDue: borrowerRepayment,
		royaltyFee,
		term: loanInfoValues.term ? loanInfoValues.term : 0,
		totalRepayment: repaymentReceived,
	}
}
