import axios from "axios"
import { format, fromUnixTime, toDate } from "date-fns"
import { FlowtyButton } from "ds-flowty"
import {
	nftTypeAndIdToLocationData,
	OfferCreated,
	OpensearchFlowNFT,
} from "flowty-common"
import { useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { SortDirection } from "../../../../../../components/MarketPlace/SortButton/shared"
import { SortColumnOrNull } from "../../../../../../components/SortableTable"
import { TokenAmount } from "../../../../../../components/Tokens/tokenDisplays"
import { useFlowtyModalsContext } from "../../../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import useInfiniteScroll from "../../../../../../hooks/infiniteScroll"
import { actions as mixpanel } from "../../../../../../util/Mixpanel"
import { apiURL } from "../../../../../../util/settings"
import { getTimeToDate } from "../../../../../../util/timestamps"
import { truncateCurrencyAmount } from "../../../../../../util/truncateValueUtil"
import { ProfileOffersTableField } from "../../ProfileOffersTable"
import { OffersTableNftRow } from "../../common/OffersTableNftRow"

interface MadeOffersTableValues {
	dataTotal: number
	hasMore: boolean
	isFetching: boolean
	isLoadingResults: boolean
	nftsWithOffers: OpensearchFlowNFT[]
	madeOfferFields: ProfileOffersTableField[]
	scroll: (node: HTMLElement | null) => void
	setSort: (sort: SortColumnOrNull) => void
	sort: SortColumnOrNull
}

interface MadeOffersTableProps {
	address: string
	addresses: Array<string>
	isPublic: boolean
}

export const useMadeOffersTable = ({
	address,
	isPublic,
	addresses,
}: MadeOffersTableProps): MadeOffersTableValues => {
	const [nftsWithOffers, setNftsWithOffers] = useState<OpensearchFlowNFT[]>([])
	const [page, setPage] = useState(0)
	const [dataTotal, setDataTotal] = useState(0)
	const [isFetching, setIsFetching] = useState(true)
	const [hasMore, setHasMore] = useState(true)
	const [isLoadingResults, setIsLoadingResults] = useState(false)

	const isLoading = useRef(false)
	const limit = 5

	const { selectCard } = useFlowtyModalsContext()

	const selectOffer = useCallback(
		({
			offer,
			singleOfferType,
			selectedNft,
		}: {
			offer?: OfferCreated
			singleOfferType?: "make-offer" | "cancel-offer"
			selectedNft: OpensearchFlowNFT
		}) => {
			selectCard({
				selected: {
					contractAddress: selectedNft.contractAddress,
					contractName: selectedNft.contractName,
					nftID: selectedNft.id,
					nftType: selectedNft.type,
				},
				selectedSingleOffer: offer as OfferCreated,
				singleOfferType,
			})
		},
		[selectCard]
	)

	const scroll = useInfiniteScroll(
		{
			isLoading: isLoading.current,
			onInfiniteScroll() {
				if (hasMore) {
					setPage(page + 1)
				}
			},
		},
		[nftsWithOffers.length, hasMore, isLoading.current]
	)

	const [sort, setSort] = useState<SortColumnOrNull>({
		column: "blockTimestamp",
		order: SortDirection.Descending,
	})

	const fetchOffers = useCallback(async () => {
		setNftsWithOffers([])
		isLoading.current = true
		setPage(0)
		setHasMore(true)
		setIsLoadingResults(true)
		setIsFetching(true)

		try {
			const response = await axios.post(
				`${apiURL}/user/${address}/offers/created`,
				{
					addresses,
					from: limit * 0,
					limit: limit,
					sort: {
						direction: sort?.order,
						path: sort?.column,
					},
				}
			)

			if (
				nftsWithOffers.length + response.data.nfts.length ===
				response.data.total
			) {
				setHasMore(false)
			}
			setNftsWithOffers(response.data.nfts)
			setDataTotal(response.data.total)
			isLoading.current = false
			setIsFetching(false)
			setIsLoadingResults(false)
		} catch (err) {
			if (axios.isCancel(err)) {
				mixpanel.track("PROFILE_OFFERS_CREATED", { event: "cancelled" })
			}
			isLoading.current = false
			setIsFetching(false)
			setIsLoadingResults(false)
		}
	}, [addresses, address, sort, page])

	useEffect(() => {
		fetchOffers()
	}, [address, sort, addresses])

	useEffect(() => {
		if (page === 0) return
		isLoading.current = true
		axios
			.post(`${apiURL}/user/${address}/offers/created`, {
				addresses,
				from: limit * page,
				limit: limit,
				sort: {
					direction: sort?.order,
					path: sort?.column,
				},
			})
			.then(res => {
				if (nftsWithOffers.length + res.data.nfts.length === res.data.total) {
					setHasMore(false)
				}
				setNftsWithOffers([...nftsWithOffers, ...res.data.nfts])
				setDataTotal(res.data.total)
				isLoading.current = false
				setIsFetching(false)
			})
			.catch(_ => {
				isLoading.current = false
				setIsFetching(false)
			})
	}, [page, addresses])

	const madeOfferFields: ProfileOffersTableField[] = [
		{
			customRender: ({ offer, key }) => {
				const locationData = nftTypeAndIdToLocationData(
					offer?.typeAndIDOffer?.nftType || "",
					offer?.typeAndIDOffer?.nftID || ""
				)

				return (
					<td key={key} className='px-3 py-8 text-sm font-medium sm:pl-3'>
						<OffersTableNftRow locationData={locationData} offer={offer} />
					</td>
				)
			},
			name: "NFT",
			title: "NFT",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						<TokenAmount
							amount={offer?.amount}
							token={offer?.paymentTokenName}
							isSmall
							largeText
							justifyStart={true}
						/>
					</td>
				)
			},
			name: "usdValue",
			sortable: true,
			title: "Offer",
		},
		{
			customRender: ({ nft, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8'>
						<div className='flex flex-col'>
							<Link to={`/user/${nft?.owner}`}>
								<span className='text-[#1B8062] text-sm hover:underline cursor-pointer'>
									{nft?.owner}
								</span>
							</Link>
						</div>
					</td>
				)
			},
			name: "To",
			title: "To",
		},
		{
			customRender: ({ offer, key }) => {
				const usdValue = offer?.valuations?.blended?.usdValue
				if (usdValue) {
					return (
						<td
							key={key}
							className='whitespace-nowrap px-3 py-8 text-sm text-bold'
						>
							${truncateCurrencyAmount(usdValue)}
						</td>
					)
				}
				return <td className='whitespace-nowrap px-3 py-8 text-lg'>-</td>
			},
			name: "FMV",
			title: "FMV",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						{format(toDate(offer?.blockTimestamp), "yyyy-MM-dd HH:mm")}
					</td>
				)
			},
			name: "blockTimestamp",
			sortable: true,
			title: "Date Sent",
		},
		{
			customRender: ({ offer, key }) => {
				return (
					<td key={key} className='whitespace-nowrap px-3 py-8 text-lg'>
						{getTimeToDate(fromUnixTime(offer?.expiry))}
					</td>
				)
			},
			name: "Expiration",
			title: "Expiration",
		},
		...(isPublic
			? ([] as ProfileOffersTableField[])
			: ([
					{
						customRender: ({ offer, nft, key }) => {
							return (
								<td key={key} className='whitespace-nowrap px-3 py-8 text-sm'>
									<div className='w-28'>
										<FlowtyButton
											onClick={() =>
												selectOffer({
													offer,
													selectedNft: nft as OpensearchFlowNFT,
													singleOfferType: "cancel-offer",
												})
											}
											text={"Cancel"}
											variant={"secondary"}
											bgColor={"white"}
										/>
									</div>
								</td>
							)
						},
						name: "Action",
						title: "Action",
					},
			  ] as ProfileOffersTableField[])),
	]

	return {
		dataTotal,
		hasMore,
		isFetching,
		isLoadingResults,
		madeOfferFields,
		nftsWithOffers,
		scroll,
		setSort,
		sort,
	}
}
