import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	PropsWithChildren,
} from "react"
import { StrapiApi } from "../services/StrapiApi"
import { actions as Mixpanel } from "../util/Mixpanel"

type MessageSeverity = "info" | "warning" | "danger"
interface SporkContext {
	disableActions: boolean
	sporkMessage: string | null
	messageType: MessageSeverity
	isShowing: boolean
	setIsShowing: (val: boolean) => void
}

const Context = createContext<SporkContext>({
	disableActions: false,
	isShowing: false,
	messageType: "info",
	setIsShowing(val) {},
	sporkMessage: "",
})

const SporkContext: React.FC<PropsWithChildren> = ({ children }) => {
	const [sporkMessage, setSporkMessage] = useState<string | null>(null)
	const [disableActions, setDisableActions] = useState(false)
	const [messageType, setMessageType] = useState<MessageSeverity>("info")
	const [isShowing, setIsShowing] = useState(false)

	useEffect(() => {
		const fetchSporkMessage = async () => {
			try {
				const strapiRes = await StrapiApi.getSpork()

				if (strapiRes.hasSpork) {
					setSporkMessage(strapiRes.message)
					setDisableActions(strapiRes.shouldDisable)
					setMessageType(strapiRes.messageType)
					setIsShowing(true)
				}
			} catch (err) {
				Mixpanel.track("ERROR_FETCHING_SPORK_MESSAGE", err)
			}
		}

		fetchSporkMessage()
	}, [])

	const values: SporkContext = {
		disableActions,
		isShowing,
		messageType,
		setIsShowing,
		sporkMessage,
	}

	return <Context.Provider value={values}>{children}</Context.Provider>
}

function useSporkContext(): SporkContext {
	const result = useContext<SporkContext>(Context)
	return result
}

export { SporkContext, useSporkContext }
