import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ReactComponent as SolidXIcon } from "../../assets/solidX.svg"
import { NavItemName } from "../types/NavbarTypes"
import { HelpPopoverItems } from "./HelpPopover"

interface SidebarNavigationProps {
	width: number
	onClose: () => void
	navItems: Record<NavItemName, string>
	selectedItem?: NavItemName
	isLogged: boolean
}

export const SidebarNavigation: React.FunctionComponent<
	SidebarNavigationProps
> = ({ width, navItems, selectedItem, isLogged, onClose }) => {
	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as='div' className='relative z-[1000]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-0'
						leaveFrom='opacity-0 scale-100'
						leaveTo='opacity-0 scale-95'
					>
						<Dialog.Panel
							className='bg-[#04070B]'
							style={{
								height: "100vh",
								left: 0,
								position: "fixed",
								top: 0,
								width,
								zIndex: 999,
							}}
						>
							<button onClick={onClose} className='py-[18px] pl-[14px]'>
								<SolidXIcon className='h-[32px] w-[32px]' />
							</button>
							<div className='absolute mt-[.31rem] pl-[14px] pt-3 flex flex-col gap-4'>
								{Object.entries(navItems).map(([name, href]) => {
									return (
										<div key={name}>
											<a
												href={
													name === "Profile"
														? isLogged
															? href
															: "/signIn"
														: href
												}
												className={`font-montserrat flex items-center hover-nav-item text-sm leading-[.875rem] rounded-md py-3 ${
													selectedItem === name ? "pl-3" : "pl-6"
												}  font-black uppercase text-white duration-300 ease-in-out`}
												aria-current={
													selectedItem === name ? "page" : undefined
												}
											>
												{selectedItem === name && (
													<div className='rounded bg-[#04E5A3] w-[.875rem] h-[.25rem] rotate-90'></div>
												)}
												{name === "Profile" ? "My Profile" : name}
											</a>
										</div>
									)
								})}
								<div className='pl-6'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='200'
										height='2'
										viewBox='0 0 200 2'
										fill='none'
										className='w-full mx-auto text-center'
									>
										<path d='M0 1H200' stroke='#343A40' strokeWidth='1px' />
									</svg>
									<p className='text-[var(--Neutral-500,#ADB5BD)] font-montserrat text-sm font-black leading-[.875rem] uppercase mt-[24px] mb-[12px]'>
										HELP
									</p>
									<HelpPopoverItems />
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}
