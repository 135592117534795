import { FlowtyFilterMenu, useBreakpoint } from "ds-flowty"
import { IEventType, nftTypeAndIdToLocationData } from "flowty-common"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../../../../../assets/media/loadingArrow.svg"
import { StorefrontActivityFilter } from "../../../../../components/Activity/Filters/StorefrontActivityFilter"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { ActivityItem } from "../../../../../components/Shared/ActivityTableFields"
import SortableTable, {
	SortColumnOrNull,
} from "../../../../../components/SortableTable"
import { collectionStorefrontActivityFields } from "../../../../../components/SortableTable/PlatformActivity/collectionStorefrontActivityFields"
import {
	FilterBy,
	StorefrontEventType,
} from "../../../../../components/UserProfile/Tables/Storefront/AllActivity/types"
import { DEFAULT_ACTIVITY_PAGE_SIZE } from "../hooks/useCollectionActivityTables"

interface CollectionStorefrontActivityScreenProps {
	filterBy: FilterBy
	isLoading: boolean
	onFilterChangeFn: (filter: FilterBy) => void
	onSortChangeFn: (sort: SortColumnOrNull) => void
	sort: SortColumnOrNull
	allActivityDocs: ActivityItem[]
	belowTableRef: (node: HTMLElement | null) => void
	showFilter: boolean
	setShowFilter: (show: boolean) => void
	tablePage: number
}

export const CollectionStorefrontActivityScreen: React.FunctionComponent<
	CollectionStorefrontActivityScreenProps
> = ({
	filterBy,
	isLoading,
	onFilterChangeFn,
	onSortChangeFn,
	sort,
	allActivityDocs,
	belowTableRef,
	showFilter,
	setShowFilter,
	tablePage,
}) => {
	const navigate = useNavigate()

	const [selectedRow, setSelectedRow] = useState<{
		item: ActivityItem | null
		href: string
	} | null>(null)

	const holdThreshold = 500

	const [touchStartTimestamp, setTouchStartTimestamp] = useState(0)
	const [touchEndTimestamp, setTouchEndTimestamp] = useState(0)

	const breakpoint = useBreakpoint()

	useEffect(() => {
		// Only proceed if in a touch device environment based on breakpoint
		if (breakpoint === "xs" || breakpoint === "mobile") {
			if (touchStartTimestamp && touchEndTimestamp) {
				const touchDuration = touchEndTimestamp - touchStartTimestamp

				if (touchDuration >= holdThreshold) {
				} else {
					if (selectedRow) {
						navigate(selectedRow.href)
					}
				}

				setTouchStartTimestamp(0)
				setTouchEndTimestamp(0)
			}
		} else {
			if (selectedRow) {
				navigate(selectedRow.href)
			}
		}
	}, [
		touchStartTimestamp,
		touchEndTimestamp,
		selectedRow,
		breakpoint,
		navigate,
		holdThreshold,
	])

	const storefrontFilterOptions = [
		{ label: "All", value: "" },
		{ label: "Purchased", value: IEventType.StorefrontPurchased.valueOf() },
		{ label: "Listed", value: IEventType.StorefrontListed.valueOf() },
		{ label: "Delisted", value: IEventType.StorefrontDelisted.valueOf() },
		{
			label: "Offer Created",
			value: IEventType.StorefrontOfferCreated.valueOf(),
		},
		{
			label: "Offer Accepted",
			value: IEventType.StorefrontOfferAccepted.valueOf(),
		},
		{
			label: "Offer Cancelled",
			value: IEventType.StorefrontOfferCancelled.valueOf(),
		},
	]

	const [selectedFilter, setSelectedFilter] = useState(
		storefrontFilterOptions[0].value
	)

	const updateSelectedFilter = (value: string): void => {
		setSelectedFilter(value)
		onFilterChangeFn({
			max: "",
			min: value as StorefrontEventType,
			path: "state",
		})
	}

	return (
		<div>
			<StorefrontActivityFilter
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={onFilterChangeFn}
			/>

			<div className='flex py-2 md:py-3 mb-[14px] md:mb-0'>
				<FlowtyFilterMenu
					fields={storefrontFilterOptions}
					selectedField={selectedFilter}
					updateSelectedField={updateSelectedFilter}
				/>
			</div>

			<SortableTable
				onClickRow={({ e, item }) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const event = e as any

					let buyer
					let seller

					if (item.type.toLowerCase().includes("offer")) {
						buyer = item.data?.storefrontAddress
						seller =
							typeof item?.data?.taker === "object" ? "-" : item?.data?.taker
					} else {
						buyer = item.data?.buyer
						seller = item.data?.storefrontAddress
					}

					if (event.target.classList[0] === "seller") {
						setSelectedRow({ href: `/user/${seller}`, item })
						return navigate(`/user/${seller}`)
					} else if (event.target.classList[0] === "buyer") {
						setSelectedRow({ href: `/user/${buyer}`, item })
					} else {
						const ld = nftTypeAndIdToLocationData(
							item?.data?.nftType || item?.data?.typeAndIDOffer?.nftType || "",
							item?.data?.nftID?.toString() ||
								item?.data?.typeAndIDOffer?.nftID ||
								""
						)

						setSelectedRow({
							href: `/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`,
							item,
						})
					}
				}}
				fields={collectionStorefrontActivityFields(navigate)}
				items={allActivityDocs}
				onSort={onSortChangeFn}
				initialSortConfig={sort}
				stickyHeaders
				composeRowHref={item => {
					const ld = nftTypeAndIdToLocationData(
						item?.data?.nftType || item?.data?.typeAndIDOffer?.nftType || "",
						item?.data?.nftID?.toString() ||
							item?.data?.typeAndIDOffer?.nftID ||
							""
					)

					return `/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`
				}}
			/>
			<div ref={belowTableRef}>
				{isLoading ? (
					<div className='h-10 w-full flex justify-center items-center'>
						<h2 className='justify-center items-center flex flex-row animate-pulse'>
							<RefreshArrow />
							Loading...
						</h2>
					</div>
				) : (
					tablePage * DEFAULT_ACTIVITY_PAGE_SIZE ===
						allActivityDocs?.length && <ScrollToLoadMsg />
				)}
			</div>
		</div>
	)
}
