import { ReactElement, useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Comp: ReactElement<any>
	tooltipText: string
}

export const TooltipText = ({ Comp, tooltipText }: Props) => {
	const [isOverflow, setIsOverflow] = useState(false)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const NoTypeComp = Comp as any
	useEffect(() => {
		if (NoTypeComp?.ref?.current) {
			setIsOverflow(
				NoTypeComp.ref.current.offsetWidth < NoTypeComp.ref.current.scrollWidth
			)
		}
	}, [])

	return (
		<>
			{isOverflow ? (
				<>
					<Tooltip id={tooltipText} />
					<div data-tooltip-content={tooltipText} data-tooltip-id={tooltipText}>
						{Comp}
					</div>
				</>
			) : (
				<>{Comp}</>
			)}
		</>
	)
}
