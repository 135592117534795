export const DropLoading: React.FunctionComponent = () => {
	return (
		<div className='py-20 flex max-lg:flex-col justify-center gap-20 max-lg:gap-0'>
			<div
				className={`flex w-full h-full max-w-[648px] tablet:w-[648px] tablet:h-[648px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
			></div>
			<div
				className={`flex flex-col justify-center gap-10 py-16 max-lg:w-full w-[400px]`}
			>
				<div
					className={`flex h-[30px] max-lg:w-full w-[135px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
				></div>
				<div
					className={`flex flex-col gap-4 max-lg:w-full w-[350px] rounded-[2.5rem]`}
				>
					<div
						className={`flex h-[20px] max-lg:w-full w-[150px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
					<div
						className={`flex h-[20px] max-lg:w-full w-[350px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
				</div>
				<div
					className={`flex flex-col gap-4 max-lg:w-full w-[350px] rounded-[2.5rem]`}
				>
					<div
						className={`flex h-[20px] max-lg:w-full w-[180px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
					<div
						className={`flex h-[20px] max-lg:w-full w-[300px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
				</div>
				<div
					className={`flex flex-col gap-4 max-lg:w-full w-[350px] rounded-[2.5rem]`}
				>
					<div
						className={`flex h-[20px] max-lg:w-full w-[180px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
					<div
						className={`flex h-[20px] max-lg:w-full w-[330px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
					></div>
				</div>
				<div
					className={`flex h-[35px] max-lg:w-full w-[400px] animate-pulse bg-[#43ff640a] rounded-[2.5rem]`}
				></div>
			</div>
		</div>
	)
}
