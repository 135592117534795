import * as fcl from "@onflow/fcl"
import { FlowtyButton } from "ds-flowty"
import {
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	Order,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { TokenAmount } from "../../../../../../components/Tokens/tokenDisplays"
import { AuthStoreProp } from "../../../../../../stores/AuthStore"
import { getDurationPeriod } from "../../../../../../util/nftDataUtil"
import { ListingCooldown } from "./ListingCooldownTimer/ListingCooldownTimer"
import { NoListingPreview } from "./NoListingsPreview/NoListingPreview"

interface RentalPreviewProps extends AuthStoreProp {
	asset: OpensearchFlowNFT
	orders: OpensearchRentalAvailableData[]
	setSelectedListing: ({
		listing,
		listingType,
		singleDelistAction,
		singleDelistOrder,
	}: {
		listing?: Order
		listingType?: "loan" | "rent" | "transfer"
		singleDelistOrder?: Order
		singleDelistAction?: "delistLoan" | "delistRental" | "delistSale"
	}) => void
	isAssetOwner: boolean
}

const RentalsPreviewComponent: React.FC<RentalPreviewProps> = ({
	asset,
	orders,
	authStore,
	setSelectedListing,
	isAssetOwner,
}) => {
	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []

	const loggedUser = authStore?.loggedUser

	const connectWallet = () => {
		fcl.authenticate()
	}

	const AuthService = useMemo(() => {
		return loggedUser?.services[0].uid.toLocaleLowerCase() || ""
	}, [loggedUser])

	const isDapper = AuthService.includes("dapper")

	const returnActionButtonsBasedOnAuth = useCallback(
		(isOwner: boolean, rental: OpensearchRentalAvailableData) => {
			if (!loggedUser) {
				return (
					<FlowtyButton
						onClick={connectWallet}
						text={
							<b>
								{loggedUser ? (
									<div
										className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
										role='status'
										aria-label='loading'
									>
										<span className='sr-only'>Loading...</span>
									</div>
								) : (
									<span className='text-base font-montserrat'>Sign in</span>
								)}
							</b>
						}
						variant={"secondary"}
						btnSize='small'
					/>
				)
			}
			if (isOwner) {
				return (
					<>
						<FlowtyButton
							text={"Delist"}
							variant={"secondary"}
							bgColor='danger'
							onClick={() =>
								setSelectedListing({
									listingType: "rent",
									singleDelistAction: "delistRental",
									singleDelistOrder: rental,
								})
							}
							btnSize='small'
						/>
						<FlowtyButton
							text={"Add rental"}
							variant={"secondary"}
							onClick={() =>
								setSelectedListing({
									listingType: "rent",
									singleDelistAction: undefined,
									singleDelistOrder: undefined,
								})
							}
							btnSize='small'
						/>
					</>
				)
			}
			return (
				<FlowtyButton
					onClick={() => setSelectedListing({ listing: rental })}
					text={"Rent"}
					variant={"secondary"}
					btnSize='small'
				/>
			)
		},
		[loggedUser]
	)

	return (
		<div className=' mt-[1.5rem] sm:min-h-[19.65rem] flex flex-row flex-wrap gap-[1.5rem] items-stretch'>
			{orders?.length > 0 ? (
				orders.map((rental, idx) => {
					const isOwner =
						userAddresses.includes(rental.flowtyStorefrontAddress) &&
						!!loggedUser?.loggedIn

					const isPrivateRental = rental?.renter

					return (
						<div
							className='rounded-[0.5rem] min-h-[17.5rem] flex flex-col p-[1.5rem] bg-[#606e7d33] w-[25rem] gap-y-[1.5rem] items-start'
							key={idx}
						>
							<ListingIndicatorPill symbol='RENT' />
							{isPrivateRental && (
								<div>
									<h5 className='text-white text-xl'>Please Note</h5>
									<p className='text-lightText text-12 line-height-19 mt-2'>
										This is a private listing and can only be filled by the
										address {rental.renter}.
									</p>
								</div>
							)}
							<div className='flex flex-col gap-y-[1.2rem] w-full'>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>Rental Fee</p>
									<TokenAmount
										fontSize='text-[0.875rem]'
										amount={Number(rental?.amount)}
										isSmall
										token={rental?.paymentTokenName}
									/>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>
										Rental Deposit
									</p>
									<TokenAmount
										fontSize='text-[0.875rem]'
										amount={Number(rental?.deposit)}
										isSmall
										token={rental?.paymentTokenName}
									/>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>
										Total Due Upfront
									</p>
									<TokenAmount
										fontSize='text-[0.875rem]'
										amount={Number(rental?.deposit) + Number(rental?.amount)}
										isSmall
										largeText
										token={rental?.paymentTokenName}
									/>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>Duration</p>
									<p className='font-montserrat text-[0.875rem] font-bold'>
										{getDurationPeriod(Number(rental?.term))}
									</p>
								</div>
								{rental?.valuations?.aggregate?.depositToValueRatio && (
									<div className='flex flex-row justify-between items-center'>
										<p className='font-montserrat text-[0.875rem]'>
											Blended DTV
										</p>
										<p className='font-montserrat text-[0.875rem] font-bold'>
											{rental?.valuations?.aggregate?.depositToValueRatio}
										</p>
									</div>
								)}
							</div>
							<ListingCooldown blockTimestamp={rental?.blockTimestamp} />
							<div className='w-full flex gap-[0.5rem]'>
								{returnActionButtonsBasedOnAuth(isOwner, rental)}
							</div>
						</div>
					)
				})
			) : (
				<div className='min-h-[280px] w-full flex flex-col items-stretch'>
					<NoListingPreview
						type={"RENT"}
						isOwner={isAssetOwner}
						isDapper={isDapper}
						updateListing={() => setSelectedListing({ listingType: "rent" })}
					/>
				</div>
			)}
		</div>
	)
}

export const RentalsPreview = inject("authStore")(
	observer(RentalsPreviewComponent)
)
