import { useState } from "react"
import { flowty } from "../../../config/config"
import { Err } from "../../../util/Log"
import {
	FlowNFT,
	OpensearchFlowNFT,
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
	OrdersType,
} from "flowty-common"
import { getLoanFundingId } from "../../../screens/UserFlowScreen/firebase/LoanRentalQueries"
import { fetchSpecificFlowNFT } from "../../../services/firestore/nftIInfo"

interface ModalGetItemValues {
	cleanItemOrder: () => void
	fetchFlowNFT: (nftID: string, nftType: string) => Promise<void>
	flowNFT: FlowNFT | null
	getItem: (
		contractAddress: string,
		contractName: string,
		nftID: string,
		resourceName: string
	) => Promise<void>
	getLoanOrder: (listingResourceID: string) => Promise<string | undefined>
	isOrderLoading: boolean
	orders: OrdersType
	selectedNft: OpensearchFlowNFT | null
}

export const useModalGetItem = (): ModalGetItemValues => {
	const [orders, setOrders] = useState<OrdersType>({
		loan: [],
		rental: [],
		storefront: [],
	})
	const [isOrderLoading, setIsOrderLoading] = useState(false)
	const [selectedNft, setSelectedNft] = useState<OpensearchFlowNFT | null>(null)
	const [flowNFT, setFlowNFT] = useState<FlowNFT | null>(null)

	const getOrderByListingKind = (order: Order) => {
		switch (order.listingKind) {
			case "loan":
				return order as OpenSearchListingAvailableData
			case "storefront":
				return order as OpensearchStorefrontAvailableData
			default:
				return order as OpensearchRentalAvailableData
		}
	}

	const getItem = async (
		contractAddress: string,
		contractName: string,
		nftID: string,
		resourceName: string
	): Promise<void> => {
		setIsOrderLoading(true)

		try {
			const nft = await flowty.api.getItem({
				contractAddress: contractAddress,
				contractName: contractName,
				nftID: nftID,
				resourceName: resourceName,
			})

			setSelectedNft(nft)
			const allOrders = nft.orders?.reduce(
				(result: OrdersType, order: Order): OrdersType => {
					const copyResult = { ...result }
					const listingKindOrders = copyResult[order.listingKind]?.length
						? [...copyResult[order.listingKind]]
						: []
					copyResult[order.listingKind] = [
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						...(listingKindOrders as any),
						getOrderByListingKind(order),
					]
					return copyResult
				},
				orders
			)
			setOrders(
				allOrders || {
					loan: [],
					rental: [],
					storefront: [],
				}
			)
		} catch (err) {
			Err("Failed to fetch asset orders.", err)
		} finally {
			setIsOrderLoading(false)
		}
	}

	const fetchFlowNFT = async (
		nftID: string,
		nftType: string
	): Promise<void> => {
		try {
			const flowNftResponse = await fetchSpecificFlowNFT({
				nftId: nftID,
				nftType: nftType,
			})
			setFlowNFT(flowNftResponse)
		} catch (err) {
			Err("Failed to fetch Flow NFT type.", err)
		}
	}

	const getLoanOrder = async (
		listingResourceID: string
	): Promise<string | undefined> => {
		setIsOrderLoading(true)
		try {
			const fundingId = await getLoanFundingId(listingResourceID)
			return fundingId
		} catch (err) {
			Err("Failed to fetch loan order.", err)
		} finally {
			setIsOrderLoading(false)
		}
	}

	const cleanItemOrder = (): void => {
		setOrders({
			loan: [],
			rental: [],
			storefront: [],
		})
		setSelectedNft(null)
		setFlowNFT(null)
	}

	return {
		cleanItemOrder,
		fetchFlowNFT,
		flowNFT,
		getItem,
		getLoanOrder,
		isOrderLoading,
		orders,
		selectedNft,
	}
}
