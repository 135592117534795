import { Provider } from "mobx-react"
import { HelmetProvider } from "react-helmet-async"
import { CatalogContext } from "./contexts/CatalogContext"
import { HybridCustodyProvider } from "./contexts/HybridCustodyContext"
import { MarketplaceAppProvider } from "./contexts/MarketplaceAppContext/MarketplaceAppContext"
import { NavbarViewProvider } from "./contexts/NavbarViewContext"
import { NftDisplayViewProvider } from "./contexts/NftDisplayViewContext"
import { SporkContext } from "./contexts/SporkContext"
import { RenderRoutes } from "./routes"
import stores from "./stores"
import { trackPWALaunch } from "./util/trackPWA"
import { FlowtyModalsProvider } from "./contexts/FlowtyModalsContext/FlowtyModalsContext"
import useScrollbarWidth from "./hooks/useScrollbarWidth"

export const App: React.FunctionComponent = () => {
	useScrollbarWidth()
	trackPWALaunch()
	return (
		<HelmetProvider>
			<Provider {...stores}>
				<SporkContext>
					<HybridCustodyProvider>
						<MarketplaceAppProvider>
							<FlowtyModalsProvider>
								<NavbarViewProvider>
									<NftDisplayViewProvider>
										<CatalogContext>
											<RenderRoutes />
										</CatalogContext>
									</NftDisplayViewProvider>
								</NavbarViewProvider>
							</FlowtyModalsProvider>
						</MarketplaceAppProvider>
					</HybridCustodyProvider>
				</SporkContext>
			</Provider>
		</HelmetProvider>
	)
}
