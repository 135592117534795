import React, { useEffect, useMemo, useState } from "react"
import { Filters, MinMaxFilter } from "../index"
import { useLocation } from "react-router-dom"

interface FilterBy {
	path: string | null
	min: number
	max: number
}

interface UserRentalTableFiltersProps {
	showFilter: boolean
	setShowFilter: (showFilter: boolean) => void
	filterBy: FilterBy
	setFilterBy: (filterBy: FilterBy) => void
	isRenter: boolean
}

export const UserRentalTableFilters: React.FunctionComponent<
	UserRentalTableFiltersProps
> = ({ showFilter, setShowFilter, filterBy, setFilterBy, isRenter }) => {
	const [status, setStatus] = useState<string | null>(null)
	const [fee, setFee] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [deposit, setDeposit] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [duration, setDuration] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [DTV, setDTV] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})

	const filterOptions = useMemo(() => {
		const baseOptions = [
			{ label: "Rented", value: "RENTED" },
			{ label: "Returned", value: "RETURNED" },
			{ label: "Settled", value: "SETTLED" },
		]

		const additionalOptions = !isRenter
			? [
					{ label: "Listed", value: "LISTED" },
					{ label: "Delisted", value: "DELISTED" },
			  ]
			: []

		return [...additionalOptions, ...baseOptions]
	}, [isRenter])

	const FilterFields = [
		{
			path: "state",
			selectField: true,
			setValue: setStatus,
			title: "Status",
			value: status,
		},
		{
			path: "amount",
			setValue: setFee,
			title: "Fee",
			value: fee,
		},
		{
			path: "deposit",
			setValue: setDeposit,
			title: "Deposit",
			value: deposit,
		},

		{
			path: "term",
			setValue: setDuration,
			title: "Duration (Days)",
			value: duration,
		},
		{
			path: "valuations.aggregate.depositToValueRatio",
			setValue: setDTV,
			title: "3rd Party DTV",
			value: DTV,
		},
	]

	const setAllFieldsNull = () => {
		setStatus(null)
		setFee({ max: null, min: null })
		setDeposit({ max: null, min: null })
		setDuration({ max: null, min: null })
		setDTV({ max: null, min: null })
	}

	useEffect(() => {
		if (filterBy.path && !filterBy.min && !filterBy.max) {
			setFilterBy({ ...filterBy, path: null })
		}
		if (!filterBy.path) {
			setAllFieldsNull()
		}
	}, [filterBy])

	return (
		<Filters
			showFilter={showFilter}
			setShowFilter={setShowFilter}
			FilterFields={FilterFields}
			filterOptions={filterOptions}
			setAllFieldsNull={setAllFieldsNull}
			filterBy={filterBy}
			setFilterBy={setFilterBy}
		/>
	)
}
