import { ListingType } from "../../../../../../components/Facets/ListingTypeFilter/ListingType/ListingType"

interface UserActionsListingTypeFilterProps {
	filter: string
	setFilter: (filter: string) => void
}

export const UserActionsListingTypeFilter: React.FunctionComponent<
	UserActionsListingTypeFilterProps
> = ({ filter, setFilter }) => {
	return (
		<div className='space-y-2'>
			<ListingType
				name='listing-type'
				value='all'
				label='Show All'
				disabled={false}
				onChange={setFilter}
				isChecked={filter === "all"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
			<ListingType
				name='listing-type'
				value='rental'
				label='Rentals'
				disabled={false}
				onChange={setFilter}
				isChecked={filter === "rental"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
			<ListingType
				name='listing-type'
				value='loan'
				label='Loans'
				disabled={false}
				onChange={setFilter}
				isChecked={filter === "loan"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
			<ListingType
				name='listing-type'
				value='lostAndFound'
				label='Inbox'
				disabled={false}
				onChange={setFilter}
				isChecked={filter === "lostAndFound"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
		</div>
	)
}
