export enum FlowtyContractErrors {
	CommissionNotSetUp = "Given recipient has not authorised to receive the commission",
	MissingNFT = "Collateral is no longer in Borrower's account; This listing should be removed from Marketplace shortly.",
	InsufficientFunds = "Account has insufficient funds for selected action.",

	Fund1 = "The selected listing is in cooldown and will be eligible for funding within five minutes.",
	Fund2 = "The selected listing has already been funded.",
	Fund3 = "The selected listing does not exist or has already funded",

	Repay1 = "The loan has already reached maturity and is no longer eligible for repayment.",
	Repay2 = "The loan has already been repaid.",

	Profile = "Usernames can be a maximum of 15 characters.",

	RejectTx = "User rejected signature.",

	SessionExpired = "Session expired for the current user. Please log out and connect your account again.",

	DefaultError = "Undefined error; please retry. If error persists, please file a support ticket via Discord.",

	InsufficientFlowBalance = "Account has insufficient FLOW balance to process transaction. Please add FLOW to your account and retry. If you need further assistance, please file a support ticket in Discord.",

	UnableToFollowTransactionResult = "Uh oh! Something went wrong while trying to follow your transaction results",
}
