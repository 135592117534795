import { useState } from "react"
import { ReactComponent as Share } from "../../../../assets/media/shareOutlined.svg"
import { ReactComponent as ShareFilled } from "../../../../assets/media/shareFilled.svg"

interface ShareIconProps {
	shareFn: () => void
}

export const ShareIcon: React.FunctionComponent<ShareIconProps> = ({
	shareFn,
}) => {
	const [onHover, setOnHover] = useState(false)
	return (
		<>
			{onHover ? (
				<ShareFilled
					className='cursor-pointer h-5'
					onClick={shareFn}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Share
					className='cursor-pointer h-5'
					onClick={shareFn}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
		</>
	)
}
