import axios, { AxiosError } from "axios"
import { addHours, parseISO, subHours } from "date-fns"
import { Log } from "../util/Log"
import { IS_PROD, strapiUrl } from "../util/settings"
import { actions as Mixpanel } from "../util/Mixpanel"
import * as Sentry from "@sentry/react"
import { FlowtyException } from "flowty-common"

type MessageSeverity = "info" | "warning" | "danger"
interface SporkReturnValue {
	message: string | null
	shouldDisable: boolean
	hasSpork: boolean
	messageType: MessageSeverity
}

interface StrapiSpork {
	attributes: {
		createdAt: string
		endTimestamp: string
		publishedAt: string
		recentSporkMessage: string
		recentSporkMessageType: MessageSeverity
		shouldDisable: boolean
		sporkMessage: string
		sporkMessageType: MessageSeverity
		startTimestamp: string
		upcomingSporkMessage: string
		upcomingSporkMessageType: MessageSeverity
		updatedAt: string
	}
	id: number
}

export class StrapiApi {
	static async getSpork(): Promise<SporkReturnValue> {
		const DEFAULT_VALUE: SporkReturnValue = {
			hasSpork: false,
			message: null,
			messageType: "info",
			shouldDisable: false,
		}
		const endpoint = IS_PROD ? "sporks" : "testnet-sporks"
		const today = new Date()
		const HOUR_WINDOW = 6
		const targetEndTime = subHours(today, HOUR_WINDOW)

		const targetStartTime = addHours(today, HOUR_WINDOW)

		const params = new URLSearchParams()
		params?.append(
			"filters[$and][0][endTimestamp][$gte]",
			encodeURIComponent(targetEndTime.getTime())
		)
		params?.append(
			"filters[$and][1][startTimestamp][$lte]",
			encodeURIComponent(targetStartTime.getTime())
		)
		params?.append("sort", encodeURIComponent("startTimestamp:asc"))

		const url = `${strapiUrl}/api/${endpoint}?${params.toString()}`

		try {
			const strapiResponse = await axios.get(url)
			const sporks = strapiResponse?.data?.data as StrapiSpork[]

			// if no sporks
			if (!sporks?.length) {
				return DEFAULT_VALUE
			}
			const spork = sporks[0]

			const endTimestamp = parseISO(spork.attributes.endTimestamp)

			if (endTimestamp < today) {
				Log("Spork is over", { endTimestamp, today })
				const result: SporkReturnValue = {
					hasSpork: true,
					message: spork.attributes.recentSporkMessage,
					messageType: spork.attributes.recentSporkMessageType,
					shouldDisable: false,
				}
				return result
			}
			const startTime = parseISO(spork.attributes.startTimestamp)

			if (startTime < today) {
				Log("Spork in progress", { startTime, today })
				const result: SporkReturnValue = {
					hasSpork: true,
					message: spork.attributes.sporkMessage,
					messageType: spork.attributes.sporkMessageType,
					shouldDisable: spork.attributes.shouldDisable,
				}
				return result
			} else {
				Log("Upcoming Spork Event", { startTime, today })
				const result: SporkReturnValue = {
					hasSpork: true,
					message: spork.attributes.upcomingSporkMessage,
					messageType: spork.attributes.upcomingSporkMessageType,
					shouldDisable: false,
				}
				return result
			}
		} catch (error) {
			Mixpanel.track("ERROR_FETCHING_SPORKS", { error })
			const axiosError = error as AxiosError
			if (
				axiosError.code === "ECONNABORTED" ||
				axiosError.code === "ERR_NETWORK"
			) {
				Sentry.captureException(
					new FlowtyException(axiosError as Error, {}, "sporks")
				)
			} else {
				Mixpanel.track("ERROR_FETCHING_SPORKS_NETWORK", { axiosError })
			}
			return DEFAULT_VALUE
		}
	}
}
