import {
	nftTypeAndIdToLocationData,
	TransactionNotification,
} from "flowty-common"
import { calculateTimeAgo } from "./getTimeAgo"
import fallback from "../../../assets/fallBack.png"
import React from "react"

/* eslint-disable import/no-unused-modules */
interface NotificationDisplayProps extends TransactionNotification {
	message: React.ReactNode
}

export const NotificationDisplay: React.FunctionComponent<
	NotificationDisplayProps
> = ({
	title,
	imageUrl,
	listingStatus,
	token,
	nftId,
	nftType,
	flowtyStorefrontAddress,
	listingResourceID,
	transactionID,
	timestamp,
	message,
}) => {
	const timeAgo = calculateTimeAgo(timestamp)

	const {
		contract: { address, name },
		resourceName,
		nftID,
	} = nftTypeAndIdToLocationData(nftType ?? "", nftId ?? "")

	const isLinkAvailable = address && name && resourceName && nftID

	return (
		<a
			href={
				isLinkAvailable
					? `/asset/${address}/${name}/${resourceName}/${nftID}`
					: undefined
			}
			className={`inline-flex items-start gap-[16px] ${
				!isLinkAvailable ? "pointer-events-none" : ""
			}`}
			data-testid={`notification-item-${transactionID}`}
		>
			<div className='hidden md:block md:relative'>
				<img
					src={imageUrl ?? fallback}
					className='w-[48px] h-[48px] rounded-[8px]'
					alt='Notification Icon'
				/>
			</div>
			<div className='flex flex-col gap-[8px] items-start flex-1'>
				<div className='flex flex-row items-center flex-wrap'>{message}</div>
				<div className='flex gap-[8px]'>
					<span className='text-neutral-500 text-[12px] font-[400]'>
						{timeAgo}
					</span>
				</div>
			</div>
		</a>
	)
}
