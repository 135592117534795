import {
	nftTypeAndIdToLocationData,
	OpensearchStorefrontAvailableData,
	StorefrontV2ListingAvailable,
} from "flowty-common"
import React from "react"

import { TwitterShareButton } from "./TwitterShareButton"
import { formatAmount, getDetails, ListingType } from "./utility"

function createCompletedListingTwitterHref({
	listingType,
	isMainnet,
	completedOrder,
}: {
	listingType:
		| ListingType
		| "fundRental"
		| "fundLoan"
		| "makeOffer"
		| "cancelOffer"
		| "acceptOffer"
		| "loanBorrower"
		| "rentalBorrower"
	isMainnet: boolean
	completedOrder?:
		| StorefrontV2ListingAvailable
		| OpensearchStorefrontAvailableData
}): string {
	const locationData = nftTypeAndIdToLocationData(
		completedOrder?.nftType || "",
		completedOrder?.nftID || ""
	)

	const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"

	const card = completedOrder?.card
	const assetPageLink = `${listingBaseURL}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID} `

	const title = card?.title?.includes("#")
		? card?.title.replace("#", "%23")
		: card?.title

	const postDetails = `${title}${getDetails(card)}`

	// Leaving switch so we can easily add loans/rentals/offers later
	switch (listingType) {
		case "purchase": {
			const { salePrice, paymentTokenName } =
				completedOrder as OpensearchStorefrontAvailableData

			const detail = `https://twitter.com/intent/tweet?text=I%20just%20purchased%20${postDetails}%20for%20${formatAmount(
				salePrice,
				paymentTokenName
			).trim()}%20on%20@flowty_io!%0A%0ACheck%20out%20my%20new%20NFT%20here:%20https://${assetPageLink}`
			return detail
		}
		default:
			return ""
	}
}

type ShareListingCompleteProps = {
	isMainnet: boolean
	listingType:
		| ListingType
		| "fundRental"
		| "fundLoan"
		| "makeOffer"
		| "cancelOffer"
		| "acceptOffer"
		| "loanBorrower"
		| "rentalBorrower"
	completedOrder:
		| StorefrontV2ListingAvailable
		| OpensearchStorefrontAvailableData
}

export const ShareListingComplete: React.FC<ShareListingCompleteProps> = ({
	isMainnet,
	listingType,
	completedOrder,
}) => {
	const href = createCompletedListingTwitterHref({
		completedOrder,
		isMainnet,
		listingType,
	})

	return <TwitterShareButton href={href} />
}
