import React, { useContext, useMemo } from "react"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { FilterPill } from "./FilterPill"
import { useSearchParams } from "react-router-dom"

/* eslint-disable import/no-unused-modules */
export const AccountTags: React.FC = () => {
	const [params] = useSearchParams()
	const { paymentTokens, setPaymentTokens } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const paymentTokensFromURL = JSON.parse(
		params.get("paymentTokens" ?? "") ?? "{}"
	)

	const account = useMemo(() => {
		if (paymentTokensFromURL?.dapper) {
			return "Dapper Wallet"
		}

		if (paymentTokensFromURL?.other) {
			return "Other Wallets"
		}

		return "All Accounts"
	}, [paymentTokensFromURL])

	if (account === "All Accounts") {
		return null
	}

	return (
		<FilterPill
			text={account}
			label='Wallet:'
			onClick={() => {
				const copyFilters = { ...paymentTokens }

				if (account === "Dapper Wallet") {
					delete copyFilters.dapper
				}
				if (account === "Other Wallets") {
					delete copyFilters.other
				}

				setPaymentTokens(copyFilters)
			}}
		/>
	)
}
