import { HelmetProvider, Helmet } from "react-helmet-async"

export const PageHeader: React.FC<{
	metaTitle: string
	metaDescription: string
	metaImage: string
}> = ({ metaTitle, metaDescription, metaImage }) => {
	return (
		<HelmetProvider>
			<Helmet>
				<meta name='description' content={metaDescription} />
				<link rel='manifest' href='/manifest.json' />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:site' content='@flowty' />
				<meta name='twitter:title' content={metaTitle} />
				<meta name='twitter:description' content={metaDescription} />
				<meta name='twitter:image' content={metaImage} />
				<meta name='description' content={metaDescription} />
				<meta property='og:title' content={metaTitle} />
				<meta property='og:description' content={metaDescription} />
				<meta property='og:image' content={metaImage} />
				<title>{metaTitle}</title>
			</Helmet>
		</HelmetProvider>
	)
}
