import { useState } from "react"
import { cn } from "../../../../util/cn"
import { SearchIcon } from "@heroicons/react/outline"
import { XIcon } from "@heroicons/react/solid"

interface FacetSearchBarProps {
	placeholder?: string
	value: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	clearSearch: () => void
}

export const FacetSearchBar: React.FunctionComponent<FacetSearchBarProps> = ({
	placeholder = "",
	value,
	onChange,
	clearSearch,
}) => {
	const [isFocus, setIsFocus] = useState(false)

	const reset = (): void => {
		setIsFocus(false)
		clearSearch()
	}

	const baseStyle =
		"block cursor-pointer w-full text-base height-[3rem] font-montserrat rounded-[0.325rem] bg-glassFillGray py-2 text-white placeholder:text-gray-400 hover:placeholder:text-gray-300 hover:bg-glassFillGrayHover outline-none focus:bg-[#2c3645] sm:leading-6"
	return (
		<div data-testid='facet-search-bar' className='relative flex items-center'>
			{!isFocus && (
				<div className='absolute flex items-center pl-4'>
					<SearchIcon height={17} width={17} color={"#9CA3AF"} />
				</div>
			)}

			{value && (
				<XIcon
					onClick={() => {
						reset()
					}}
					className='absolute top-1/2 right-0 transform -translate-y-1/2 -translate-x-1/2 w-4 h-4'
				/>
			)}

			<input
				type='text'
				onChange={onChange}
				value={value}
				className={cn(baseStyle, {
					"border-[1px] border-neutral-500 pl-10": !isFocus,
					"border-[1px] border-primary pl-4": isFocus,
				})}
				placeholder={isFocus ? "" : placeholder}
				onFocus={() => setIsFocus(true)}
			/>
		</div>
	)
}
