import { useEffect, useState } from "react"
import Countdown, { zeroPad } from "react-countdown"
import {
	getListingActionTime,
	isNftActive,
} from "../../../../../../../util/nftDataUtil"

interface ListingCooldownProps {
	blockTimestamp: Date
}

export const ListingCooldown: React.FunctionComponent<ListingCooldownProps> = ({
	blockTimestamp,
}) => {
	const [isActive, setIsActive] = useState(isNftActive(blockTimestamp))

	const countDownRenderer = ({
		minutes,
		seconds,
	}: {
		minutes: number
		seconds: number
	}): React.ReactNode => (
		<span className='text-white text-bold'>
			{zeroPad(minutes)}:{zeroPad(seconds)}
		</span>
	)

	useEffect(() => {
		setIsActive(isNftActive(blockTimestamp))
	}, [blockTimestamp])

	return !isActive ? (
		<div className='w-full h-full flex justify-center  bottom-2'>
			<div
				className={`bg-darkBg bg-opacity-70 h-10 flex justify-center items-center w-full`}
			>
				<p className='text-white font-bold me-2 pb-0.5'>Active in</p>
				<Countdown
					className='text-white font-bold'
					renderer={countDownRenderer}
					date={getListingActionTime(blockTimestamp)}
					onComplete={() => {
						setIsActive(true)
					}}
				/>
			</div>
		</div>
	) : null
}
