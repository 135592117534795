import { getMessageFromError } from "./typeUtilities"

export class FlowtyException extends Error {
	public error: Error

	public context: { [key: string]: any }

	public origin: string

	constructor(error: Error, context: { [key: string]: any }, origin: string) {
		const message = getMessageFromError(error)
		super(
			`${origin || "unknown origin"} ${
				message || "unknown error message"
			} ${JSON.stringify(context)} `
		)
		this.error = error
		this.context = context
		this.origin = origin
	}
}
