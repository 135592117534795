import { FlowtyButton, FlowtyFieldInput } from "ds-flowty"
import { FlowNFT } from "flowty-common"
import { AccountSummary } from "flowty-sdk"
import { Form, Formik } from "formik"
import React, { useMemo } from "react"
import * as Yup from "yup"
import { User } from "../../../../../../../models/user"
import { verifyAddress } from "../../../../../../../services/flow/AccountService"
import DapperTransferDisplay from "./DapperTransferDisplay"

interface TransferFormProps {
	onSubmit: (address: string) => Promise<void>
	accounts: AccountSummary[]
	nft: FlowNFT
	loggedUser: User
}

export const TransferForm: React.FC<TransferFormProps> = ({
	onSubmit,
	accounts,
	nft,
	loggedUser,
}) => {
	const nftBelongsToDapperAccount = useMemo(() => {
		const targetAccount = accounts.find(acc => {
			if (nft.owner === acc.address) {
				return acc
			}
		})

		if (!targetAccount) {
			return false
		}

		return targetAccount.isDapper
	}, [accounts])

	const schema = Yup.object().shape({
		address: Yup.string()
			.required("Required")
			.test("is-not-owner", "This user already owns this NFT", value => {
				return value !== loggedUser.addr
			})
			.test("is-valid-address", "Invalid address", value => {
				return verifyAddress(value || "")
			}),
	})

	if (nftBelongsToDapperAccount) {
		return (
			<div className='w-full h-full'>
				<DapperTransferDisplay flowNFT={nft} />
			</div>
		)
	}

	return (
		<Formik
			initialValues={{ address: "", isDapper: false }}
			validationSchema={schema}
			onSubmit={values => onSubmit(values.address)}
		>
			{({ values, errors }) => {
				return (
					<Form className='w-full h-full'>
						<div className='flex flex-col gap-4 w-full h-full font-montserrat'>
							<div className='flex flex-col'>
								<span className=' text-[14px] font-[700] mb-6'>TRANSFER</span>
								<span className=' text-base font-[600] mb-3'>Recipient</span>

								<div className='flex w-full'>
									<div className='relative w-full'>
										<FlowtyFieldInput
											name='address'
											inputError={Boolean(errors.address)}
											valueError={Boolean(errors.address)}
											placeholder='Address'
										/>

										{errors.address && (
											<span className='text-red-500 text-left h-6 font-bold font-montserrat-bold w-full mt-2'>
												{errors.address}
											</span>
										)}
									</div>
								</div>
							</div>

							<div className='flex flex-col w-full h-full justify-end'>
								<div className='h-full flex flex-col justify-end'>
									<p className='text-sm'>
										Note: Flowty is not responsible for any loss of assets or
										unexpected outcomes resulting from NFT transfers
									</p>
								</div>
							</div>
							<FlowtyButton
								onClick={() => onSubmit(values.address)}
								text={"Transfer"}
								variant={"secondary"}
								disabled={!values.address || Boolean(errors.address)}
								btnSize='small'
							/>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
