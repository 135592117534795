import { useState } from "react"
import { ReactComponent as Discord } from "../../../../assets/media/discordOutlined.svg"
import { ReactComponent as DiscordFilled } from "../../../../assets/media/discordFilled.svg"
import { ExternalURLWarning } from "ds-flowty"

interface DiscordIconProps {
	link: string
	onSocialLinkClick?: (link: string, openModal: () => void) => void
}

export const DiscordIcon: React.FunctionComponent<DiscordIconProps> = ({
	link,
	onSocialLinkClick,
}) => {
	const [onHover, setOnHover] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleClick = () => {
		if (onSocialLinkClick) {
			onSocialLinkClick(link, () => setIsModalOpen(true))
		} else {
			setIsModalOpen(true)
		}
	}
	return (
		<>
			{onHover ? (
				<DiscordFilled
					className='cursor-pointer h-5'
					onClick={handleClick}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Discord
					className='cursor-pointer h-5'
					onClick={handleClick}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
			<ExternalURLWarning
				isOpen={isModalOpen}
				linkURL={link}
				onCloseModal={() => setIsModalOpen(false)}
			/>
		</>
	)
}
