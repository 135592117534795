import { LoadingOrderDisplay } from "ds-flowty"
import {
	calcAPRInterest,
	ListingAvailableData,
	OpenSearchListingAvailableData,
} from "flowty-common"
import numeral from "numeral"
import React from "react"
import { getDurationPeriod } from "../../../../util/nftDataUtil"
import { TokenAmount } from "../../../Tokens/tokenDisplays"

interface LoanOrderDisplayProps {
	order: OpenSearchListingAvailableData | ListingAvailableData
	isLoading?: boolean
}

export const LoanOrderDisplay: React.FC<LoanOrderDisplayProps> = ({
	isLoading,
	order,
}) => {
	return (
		<div>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-1 items-center '>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								Loan Amount
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold text-base font-montserrat'>
								<TokenAmount
									amount={
										order?.derivations?.calculatedValues?.marketplaceAmount || 0
									}
									isSmall
									token={order?.paymentTokenName}
								/>
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center '>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								APR
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{numeral(
									calcAPRInterest(
										order
											? Number(
													order?.derivations?.calculatedValues?.periodicInterest
											  )
											: 0,
										order ? Number(order.term) : 0
									)
								).format("0,0.00")}
								%
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div className='text-[#AAAAAA] text-base font-montserrat'>
							Duration
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{order?.term && getDurationPeriod(Number(order.term))}
							</span>
						</div>
					</div>
					<div className='my-1 border-t border-[#474747]'></div>
					<div className='flex flex-row justify-between items-center'>
						<div className='text-[#AAAAAA] text-base font-montserrat'>
							Blended LTV
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{order?.valuations
									? `${numeral(
											order.valuations?.aggregate?.loanToValueRatio * 100
									  ).format("0,0.0")}%`
									: "-"}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
