import { cn } from "../../../util/cn"

interface ColProps {
	xs?: number
	sm?: number
	md?: number
	lg?: number
	xl?: number
	children: React.ReactNode | React.ReactNode[]
	className?: string
}

export const Col: React.FunctionComponent<ColProps> = ({
	xs,
	sm,
	md,
	lg,
	xl,
	children,
	className,
}) => {
	const defaultClass = !xs && !sm && !md && !lg && !xl ? "flex-grow" : ""

	const classes = [
		xs ? `w-${xs}/12` : "",
		sm ? `sm:w-${sm}/12` : "",
		md ? `md:w-${md}/12` : "",
		lg ? `lg:w-${lg}/12` : "",
		xl ? `xl:w-${xl}/12` : "",
		defaultClass,
	].join(" ")

	return <div className={cn(classes, className)}>{children}</div>
}
