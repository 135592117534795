import { inject, observer } from "mobx-react"
import { useContext } from "react"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { OpenSearchContext } from "../../../../../components/OpenSearch/OpenSearchConnector"
import { AuthStoreProp } from "../../../../../stores/AuthStore"
import { LoadingOffers } from "../LoadingOffers/LoadingOffers"
import { OfferNotFound } from "../OfferNotFound"
import { ProfileOffersTable } from "../ProfileOffersTable"
import { useMadeOffersTable } from "./hooks/useMadeOffersTable"

interface MadeOffersTableProps {
	address: string
	isPublic: boolean
}

const MadeOffersTableComponent: React.FC<
	MadeOffersTableProps & { addresses: Array<string> }
> = ({ address, isPublic, addresses }) => {
	const {
		dataTotal,
		hasMore,
		isFetching,
		isLoadingResults,
		nftsWithOffers,
		madeOfferFields,
		scroll,
		setSort,
		sort,
	} = useMadeOffersTable({
		address,
		addresses,
		isPublic,
	})
	return (
		<div className='flex flex-col justify-center items-center'>
			{isFetching ? (
				<LoadingOffers />
			) : dataTotal ? (
				<>
					<ProfileOffersTable
						fields={madeOfferFields}
						initialSortConfig={sort}
						items={nftsWithOffers}
						onSort={setSort}
						isFetchingResults={isLoadingResults}
					/>

					{hasMore && (
						<>
							<ScrollToLoadMsg />
							<div ref={scroll} />
						</>
					)}
				</>
			) : (
				<OfferNotFound />
			)}
		</div>
	)
}

type MadeOffersTableWithAddressesProps = MadeOffersTableProps & AuthStoreProp

const MadeOffersTableWithAddresses: React.FC<
	MadeOffersTableWithAddressesProps
> = props => {
	const { walletAddresses } = useContext(OpenSearchContext)

	return <MadeOffersTableComponent {...props} addresses={walletAddresses} />
}

export const MadeOffersTable = inject("authStore")(
	observer(MadeOffersTableWithAddresses)
)
