import { useMemo } from "react"
import { TimeSegmentsTypes } from "../../../hooks/useDropPage"

interface CountdownTimerProps {
	countdownCurrentValue: TimeSegmentsTypes
	isCustomColor?: boolean
}

const CountdownTimer: React.FunctionComponent<CountdownTimerProps> = ({
	countdownCurrentValue,
	isCustomColor,
}) => {
	const renderTimeSegments = useMemo(
		() =>
			Object.entries(countdownCurrentValue).map(([key, val], index) => {
				return (
					<div key={`${val}-${index}`}>
						<div className='flex flex-col justify-center'>
							<div className='flex justify-center items-center gap-6'>
								<div className='flex flex-col gap-2'>
									<div
										className={`w-[40px] h-[40px] flex items-center justify-center flex-col border rounded-[4px] ${
											isCustomColor ? "border-[#FF696985]" : "border-[#DEE2E6]"
										}`}
									>
										<div
											className={`${
												isCustomColor ? "text-[#FF6969]" : "text-white"
											} text-[14px] font-[700]`}
											data-testid={`${key}-value`}
										>
											{val}
										</div>
									</div>
									<div
										className={`${
											isCustomColor ? "text-[#FF6969]" : "text-neutral-400"
										} text-[12px] font-[400] capitalize text-center`}
									>
										{key}
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}),
		[countdownCurrentValue]
	)

	return (
		<div className='flex gap-[14px] items-center'>{renderTimeSegments}</div>
	)
}

export default CountdownTimer
