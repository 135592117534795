import { FlowtyCreatorHubFormValues } from "../types/FlowtyCreatorHubTypes"

export const creatorHubCacheFn = (
	values: FlowtyCreatorHubFormValues,
	isLoggedUser?: boolean
): void => {
	const valuesWithImages = {
		...values,
		dropThumbnailImage:
			values.dropThumbnailImage && isLoggedUser
				? {
						...values.dropThumbnailImage,
						fileBase64: undefined,
				  }
				: null,
		nftBannerImage:
			Boolean(values.nftBannerImage?.fileCid) ||
			(values.nftBannerImage && isLoggedUser)
				? {
						...values.nftBannerImage,
						fileBase64: undefined,
				  }
				: null,
		nftImage:
			values.nftImage && isLoggedUser
				? {
						...values.nftImage,
						fileBase64: undefined,
				  }
				: null,
		nftThumbnailImage:
			values.nftThumbnailImage && isLoggedUser
				? {
						...values.nftThumbnailImage,
						fileBase64: undefined,
				  }
				: null,
	}
	localStorage.setItem("creatorHubCache", JSON.stringify(valuesWithImages))
}

export const getCreatorHubCachedValues =
	(): FlowtyCreatorHubFormValues | null => {
		const cachedValues = localStorage.getItem("creatorHubCache")
		if (!cachedValues) {
			return null
		}

		return JSON.parse(cachedValues)
	}

export const cleanCreatorHubCache = async (): Promise<void> => {
	await localStorage.removeItem("creatorHubCache")
}
