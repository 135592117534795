import { LockClosedIcon } from "@heroicons/react/solid"
import React from "react"

interface ListingIndicatorProps {
	symbol: string
	count?: number
}
export const ListingIndicatorPill: React.FC<ListingIndicatorProps> = ({
	symbol,
	count,
}) => {
	return (
		<div
			style={{ lineHeight: 1 }}
			className={`${
				symbol === "LOAN"
					? "bg-[#2F2FA5] text-[#9D9DFE] border-[#9D9DFE]"
					: `${
							symbol === "RENT"
								? "bg-[#973C19] text-[#F3B59C] border-[#F3B59C]"
								: `${
										symbol === "LOCKED"
											? "bg-[#4C5B69] text-[#c2c2c2] border-[#7EE7C7]"
											: `${
													symbol === "FORBIDDEN"
														? "bg-[#C70039] text-[#F3B59C] border-[#F3B59C]"
														: "bg-[#1B8062] text-[#7EE7C7] border-[#7EE7C7]"
											  }`
								  }`
					  }`
			} rounded-md py-[0.25rem] px-[0.75rem] text-[0.75rem] mx-0.5 border-[1px] font-bold flex items-center justify-center shadow-xl h-fit m-auto w-fit`}
		>
			{symbol === "LOCKED" ? (
				<LockClosedIcon className='h-4 w-4 fill-white' />
			) : (
				<span>
					{symbol} {`${Number(count) > 1 ? `x${count}` : ""}`}
				</span>
			)}
		</div>
	)
}
