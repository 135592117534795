import {
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
	OrdersType,
} from "flowty-common"
import { Dispatch, SetStateAction } from "react"
import { FlowtyTabs } from "../../../../FlowtyTabs"
import { PurchaseOrderWrapper } from "../PurchaseOrderWrapper/PurchaseOrderWrapper"
import { AccountSummaries } from "flowty-sdk"

interface PurchaseMultiViewProps {
	activeTab: string
	setActiveTab: (tab: "1" | "2" | "3") => void
	isDapper: boolean
	accountSummaries: AccountSummaries
	nftOrders?: OrdersType
	setSelectedOrder: Dispatch<
		SetStateAction<{
			purchase?: Order | null
			fundLoan?: Order | null
			fundRental?: Order | null
		}>
	>
}

export const PurchaseMultiView: React.FunctionComponent<
	PurchaseMultiViewProps
> = ({
	activeTab,
	accountSummaries,
	setActiveTab,
	nftOrders,
	isDapper,
	setSelectedOrder,
}) => {
	return (
		<FlowtyTabs
			activeTab={activeTab}
			condensed
			tabs={[
				{
					badgeNotification:
						(nftOrders?.storefront?.length || 0) > 1
							? nftOrders?.storefront?.length?.toString()
							: undefined,
					content: Boolean((nftOrders?.storefront?.length || 0) === 0) ? (
						<p className='w-[400px] pt-[18px] text-base font-semibold text-white'>
							No Purchase Listings available
						</p>
					) : (
						<PurchaseOrderWrapper
							isDapper={isDapper}
							storefrontOrders={nftOrders?.storefront}
							accountSummaries={accountSummaries}
						/>
					),
					id: "1",
					label: "Sale",
				},
				{
					badgeNotification:
						(nftOrders?.loan?.length || 0) > 1
							? nftOrders?.loan?.length?.toString()
							: undefined,
					content: Boolean((nftOrders?.loan?.length || 0) === 0) ? (
						<p className='w-[400px] pt-[18px] text-base font-semibold text-white'>
							No Loan Listings available
						</p>
					) : (
						<PurchaseOrderWrapper
							isDapper={isDapper}
							fundLoanOrders={nftOrders?.loan}
							accountSummaries={accountSummaries}
						/>
					),
					id: "2",
					label: "Loan",
				},
				{
					badgeNotification:
						(nftOrders?.rental?.length || 0) > 1
							? nftOrders?.rental?.length?.toString()
							: undefined,
					content: Boolean((nftOrders?.rental?.length || 0) === 0) ? (
						<p className='w-[400px] pt-[18px] text-base font-semibold text-white'>
							No Rental Listings available
						</p>
					) : (
						<PurchaseOrderWrapper
							isDapper={isDapper}
							rentalOrders={nftOrders?.rental}
							accountSummaries={accountSummaries}
						/>
					),
					id: "3",
					label: "Rental",
				},
			]}
			onChange={tab => {
				setActiveTab(tab as "1" | "2" | "3")
				setSelectedOrder({
					fundLoan:
						tab === "2"
							? (nftOrders?.loan?.[0] as OpenSearchListingAvailableData)
							: null,
					fundRental:
						tab === "3"
							? (nftOrders?.rental?.[0] as OpensearchRentalAvailableData)
							: null,
					purchase:
						tab === "1"
							? (nftOrders
									?.storefront?.[0] as OpensearchStorefrontAvailableData)
							: null,
				})
			}}
			type='secondary'
		/>
	)
}
