import { FlowNFTContract, getSquareImage } from "flowty-common"
import React, { useMemo } from "react"
import { ReactComponent as IsNFTCatalog } from "../../assets/isNFTCatalog.svg"
import { ReactComponent as Recent } from "../../assets/recentSearch.svg"

interface SearchResultProps {
	result: FlowNFTContract
	isRecent?: boolean
}

export const SearchResult: React.FunctionComponent<SearchResultProps> = ({
	result,
	isRecent,
}) => {
	const collection = result

	const collectionName = useMemo(
		() =>
			collection.name === "TopShot"
				? "NBA Top Shot"
				: collection?.collectionDisplay?.name || collection?.name,
		[collection.name]
	)

	return (
		<div className='p-[12px] flex items-center gap-[14px] hover:bg-[#6C757D40] hover:bg-opacity-20 w-full'>
			{isRecent && <Recent width='24' height='24' />}
			<img
				src={getSquareImage(collection.name ?? "", collection)}
				className='w-[34px] h-[34px] flex-shrink-0 rounded-[6px]'
				alt="Collection's logo"
			/>
			<div className='flex items-center gap-[8px] overflow-hidden w-full'>
				<span className='text-[14px] text-[#F8F9FA] font-[600] truncate w-full'>
					{collectionName}
				</span>
				{collection.isNFTCatalog && <IsNFTCatalog />}
			</div>
		</div>
	)
}
