import { FlowtyButton } from "../FlowtyButton"

interface FlowtySignInScreenProps {
	onSignIn: () => void
}

export const FlowtySignInScreen = ({ onSignIn }: FlowtySignInScreenProps) => {
	return (
		<div className='flex flex-col items-center gap-[0.5rem] w-[248px]'>
			<svg
				width='248'
				height='77'
				viewBox='0 0 248 77'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className='max-h-[4.78863rem] flex-shrink-0'
			>
				<mask
					id='mask0_36923_544'
					style={{ maskType: "luminance" }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='249'
					height='77'
				>
					<path d='M248.527 0H0.526855V76.6175H248.527V0Z' fill='white' />
				</mask>
				<g mask='url(#mask0_36923_544)'>
					<path
						d='M19.1105 18.3768C20.768 12.6914 24.5086 9.44467 30.3325 8.63867H45.8015L43.3835 18.6868H32.6802C31.99 18.6868 31.3452 18.9286 30.7458 19.4122C30.1692 19.8958 29.8014 20.5055 29.6402 21.2433L27.776 28.0447H35.4062L33.0584 38.0928H25.3229L21.0759 55.2544H9.89111L19.1105 18.3768Z'
						fill='white'
					/>
					<path
						d='M52.9128 8.63867H64.3084L55.4693 43.307C55.4011 43.8836 55.5375 44.4003 55.8826 44.8611C56.2505 45.322 56.7465 45.5514 57.3685 45.5514H63.583L61.1319 55.2544H53.3633C50.2096 55.2544 47.7007 54.0578 45.8365 51.6625C43.9724 49.2693 43.34 46.4835 43.9373 43.3049L52.9128 8.63867Z'
						fill='white'
					/>
					<path
						d='M74.9084 52.4231C73.1827 51.1108 71.8807 49.3272 71.0065 47.0704C69.0494 42.1662 69.4648 36.6772 72.2486 30.5991C75.979 23.8308 82.0095 20.2988 90.3423 19.9992C92.3676 19.8834 94.4281 19.9082 96.5237 20.0674C99.9295 20.367 102.509 21.202 104.259 22.5701C106.008 23.9403 107.332 25.7528 108.229 28.0096C110.186 32.9365 109.771 38.4256 106.987 44.4809C103.282 51.272 97.1333 54.839 88.5484 55.1842C86.7979 55.2999 84.8181 55.2648 82.6088 55.0808C79.2009 54.62 76.6361 53.7334 74.9084 52.421M97.9745 38.4028C98.6875 35.7782 98.7102 33.7756 98.0427 32.395C97.3752 31.0145 95.7301 30.2085 93.1054 29.977C89.6975 29.7249 86.8909 30.3118 84.6796 31.7378C82.9539 33.0729 81.8131 34.72 81.2613 36.6751C79.6721 41.9017 81.2965 44.7103 86.1304 45.1009C90.0901 45.1918 92.8988 44.6049 94.5562 43.3401C96.2819 42.0278 97.4227 40.3827 97.9745 38.4028Z'
						fill='white'
					/>
					<path
						d='M113.65 43.3422L119.313 20.1377H130.638L124.976 43.3422C124.907 43.9188 125.044 44.4355 125.391 44.8964C125.736 45.3572 126.23 45.5866 126.875 45.5866H131.779C131.802 44.8261 131.905 44.08 132.089 43.3422L137.752 20.1377H149.077L143.414 43.3422C143.321 43.9188 143.46 44.4355 143.828 44.8964C144.173 45.3572 144.669 45.5866 145.314 45.5866H150.148L156.155 20.1377H167.687L159.193 55.2214H148.904V55.2566H141.168C136.747 55.2566 133.745 52.9894 132.155 48.4552L130.566 55.2235H126.699V55.2586H122.762C119.654 55.2586 117.191 54.062 115.372 51.6668C113.53 49.2963 112.954 46.5208 113.646 43.3443'
						fill='white'
					/>
					<path
						d='M167.896 43.307L176.425 8.63867H187.612C185.725 16.0291 184.746 19.8503 184.677 20.1025H192.93L190.443 30.2891H182.226C181.627 32.7525 181.133 34.7944 180.743 36.4188C180.35 38.0411 179.994 39.5147 179.672 40.8394C179.35 42.1641 179.153 42.9867 179.085 43.307C179.017 43.8836 179.153 44.4003 179.5 44.8611C179.846 45.322 180.329 45.5514 180.951 45.5514H186.752L184.405 55.2544H176.878C173.792 55.2544 171.364 54.0578 169.593 51.6625C167.797 49.2693 167.233 46.4835 167.9 43.3049'
						fill='white'
					/>
					<path
						d='M201.285 58.5362H207.225C207.847 58.5362 208.444 58.3068 209.02 57.8459C209.595 57.385 209.988 56.8663 210.194 56.2918L210.436 55.3246H202.322C199.214 55.3246 196.751 54.128 194.932 51.7327C193.113 49.3395 192.562 46.5536 193.275 43.3772L198.902 20.1375H210.263L204.635 43.3772C204.567 43.9538 204.693 44.4704 205.015 44.9313C205.36 45.3922 205.867 45.6216 206.534 45.6216H212.645L218.827 20.1375H218.895L218.93 20.0342H230.291L221.588 56.2918C220.714 59.4682 218.779 62.2541 215.787 64.6473C212.771 67.0405 209.709 68.2392 206.6 68.2392H198.832L201.283 58.5362H201.285Z'
						fill='white'
					/>
					<path
						d='M239.434 51.024C239.434 54.4505 236.656 57.2281 233.23 57.2281C229.803 57.2281 227.025 54.4505 227.025 51.024C227.025 47.5974 229.803 44.8198 233.23 44.8198C236.656 44.8198 239.434 47.5974 239.434 51.024Z'
						fill='#5BC096'
					/>
				</g>
			</svg>

			<div className='inline-flex flex-col items-center gap-[1.5rem] w-full'>
				<span className='font-montserrat w-ful w-[248px] text-[#ADB5BD] text-[0.75rem] font-normal text-center'>
					Sign in to view your NFTs and user profile
				</span>
				<FlowtyButton
					className='max-w-[248px] w-[90%]'
					onClick={onSignIn}
					variant='primary'
					btnSize='small'
					text='SIGN IN'
				/>
			</div>
		</div>
	)
}
