import { useLocation } from "react-router-dom"

export interface ActivityModeProp {
	name: ActivityModeNames
	route: string
}

export enum ActivityModeNames {
	Loans = "loans",
	Rentals = "rentals",
	Swaps = "swaps",
	Storefront = "sales",
	Offers = "offers",
}

enum ActivityModeRoutes {
	Loans = "loans",
	Rentals = "rentals",
	Swaps = "swaps",
	Storefront = "storefront",
	Offers = "offers",
}

interface ActivityTypeMetadata {
	path: boolean
	type: ActivityModeRoutes
}

interface FlowtyMode {
	activeRoute: ActivityModeRoutes
}

export const useFlowtyMode = (): FlowtyMode => {
	const { pathname } = useLocation()

	const ACTIVITY_TYPE_METADATA: ActivityTypeMetadata[] = [
		{
			path: pathname.includes("/storefront"),
			type: ActivityModeRoutes.Storefront,
		},
		{
			path: pathname.includes("/loans"),
			type: ActivityModeRoutes.Loans,
		},
		{
			path: pathname.includes("/rentals"),
			type: ActivityModeRoutes.Rentals,
		},
		{
			path: pathname.includes("/offers"),
			type: ActivityModeRoutes.Storefront,
		},
	]

	const activeRoute = ACTIVITY_TYPE_METADATA.reduce((selected, current) => {
		if (current.path) return current.type
		return selected
	}, ActivityModeRoutes.Storefront)

	return { activeRoute }
}
