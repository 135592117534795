import React, { createContext, useContext, useState } from "react"
import { useConfirmation } from "../hooks/useConfirmation"
import {
	FlowtyCreatorHubContextProps,
	FlowtyCreatorHubContextValues,
	FormTabs,
} from "../types/FlowtyCreatorHubContextTypes"
import { creatorHubCacheFn } from "../utils/creatorHubCacheFn"

const defaultContext: FlowtyCreatorHubContextValues = {
	activeConfirmationPreviewTab: "",
	changeSelectedFormTab: () => {},
	collectionUrlPathname: "",
	confirmationPageMobilePreviewTabs: [],
	confirmationPagePreviewTabs: [],
	errors: {},
	formTab: FormTabs.WelcomeCreatorHubTab,
	handleSubmit: () => {},
	hasViewedDropPage: false,
	mixPanelFn: (_: string, __: unknown) => {},
	moveToPreviousTab: () => {},
	onConfirmationPageTabClick: () => {},
	setFieldError: (_: string, __: string) => {},
	setHasViewedDropPage: () => {},
	touched: {},
}

const FlowtyCreatorHubContext =
	createContext<FlowtyCreatorHubContextValues>(defaultContext)

export const FlowtyCreatorHubContextProvider: React.FunctionComponent<
	FlowtyCreatorHubContextProps
> = ({
	children,
	mixPanelFn,
	loggedUser,
	flowtyMarketplaceFee,
	uploadIPFSImage,
	values,
	spotPrice,
	errors,
	formTab,
	changeSelectedFormTab,
	moveToPreviousTab,
	handleSubmit,
	setFieldError,
	touched,
	promotion,
}) => {
	const {
		activeConfirmationPreviewTab,
		confirmationPagePreviewTabs,
		confirmationPageMobilePreviewTabs,
		onConfirmationPageTabClick,
	} = useConfirmation()

	const [hasViewedDropPage, setHasViewedDropPage] = useState(false)

	const handleSubmitWrapper = (): void => {
		creatorHubCacheFn(values, Boolean(loggedUser))
		handleSubmit()
	}

	const moveToPreviousTabWrapper = (): void => {
		creatorHubCacheFn(values, Boolean(loggedUser))
		moveToPreviousTab()
	}

	const collectionUrlPathname = values.collectionName
		.split(" ")
		.map((word: string, index: number) =>
			index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
		)
		.join("")

	const uploadImage = async (file: File): Promise<{ cid: string } | null> => {
		try {
			const response = await uploadIPFSImage(file)
			return response
		} catch (error) {
			mixPanelFn("error uploading image", { error })
			//keeping console for now, will remove it after some time
			console.error("error uploading image", error)
			return null
		}
	}

	return (
		<FlowtyCreatorHubContext.Provider
			value={{
				activeConfirmationPreviewTab,
				changeSelectedFormTab,
				collectionUrlPathname: `${
					loggedUser ?? "your_address"
				}/${collectionUrlPathname}`,
				confirmationPageMobilePreviewTabs,
				confirmationPagePreviewTabs,
				errors,
				flowtyMarketplaceFee,
				formTab,
				handleSubmit: handleSubmitWrapper,
				hasViewedDropPage,
				loggedUser,
				mixPanelFn,
				moveToPreviousTab: moveToPreviousTabWrapper,
				onConfirmationPageTabClick,
				promotion,
				setFieldError,
				setHasViewedDropPage,
				spotPrice,
				touched,
				uploadImage,
			}}
		>
			{children}
		</FlowtyCreatorHubContext.Provider>
	)
}

export const useFlowtyCreatorHubContext = (): FlowtyCreatorHubContextValues =>
	useContext(FlowtyCreatorHubContext) as FlowtyCreatorHubContextValues
