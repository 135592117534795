import {
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
} from "flowty-common"
import React from "react"
import { AssetSeoHeader } from "../../components/Asset/AssetHeader"
import { NftDetailView } from "../../components/NftDetailView/NftDetailView"
import Layout from "../../layouts/Layout"
import NotFound from "../NotFound"
import { AssetScreenLoader } from "./components/AssetScreenLoader"
import { HistoryTab } from "./components/HistoryTab/HistoryTab"
import { ListingsTab } from "./components/ListingsTab/ListingsTab"
import { useAssetScreen } from "./hooks/useAssetScreen"

export interface OpensearchNftOrders {
	loans: OpenSearchListingAvailableData[]
	rentals: OpensearchRentalAvailableData[]
	storefront: OpensearchStorefrontAvailableData[]
}

export const AssetScreen: React.FC = () => {
	const { anyUndefined, openSearchNFT, orders, tabs, changeTab, currentTab } =
		useAssetScreen()

	return (
		<>
			{anyUndefined ? (
				<NotFound />
			) : (
				<div className='bg-cover bg-fixed bg-no-repeat md:bg-repeat-y'>
					<Layout>
						<div className='flex flex-col w-full container'>
							{openSearchNFT ? (
								<>
									<AssetSeoHeader asset={openSearchNFT} />
									<NftDetailView asset={openSearchNFT} orders={orders} />
									<nav className='flex justify-between pt-10' aria-label='Tabs'>
										<div className='-mb-px flex space-x-8'>
											{tabs.map(tab => {
												return (
													<button
														onClick={() =>
															changeTab(tab.key as "LISTINGS" | "HISTORY")
														}
														key={tab.name}
														className={`font-bold font-montserrat uppercase text-base text-neutral-900 px-6 py-1.5
							${tab.key === currentTab ? "border-neutral-900" : "border-transparent"} 
							"whitespace-nowrap px-2 border-b-[4px] text-sm"
						`}
													>
														{tab.name}
													</button>
												)
											})}
										</div>
									</nav>
									<div className='my-[1.5rem]'>
										{currentTab === "LISTINGS" && (
											<ListingsTab asset={openSearchNFT} orders={orders} />
										)}
										{currentTab === "HISTORY" && (
											<HistoryTab openSearchNFT={openSearchNFT} />
										)}
									</div>
								</>
							) : (
								<AssetScreenLoader />
							)}
						</div>
					</Layout>
				</div>
			)}
		</>
	)
}
