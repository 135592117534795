import { LoanRentalFilteredData, LostAndFoundNFTTicketData } from "flowty-sdk"
import { NftActionsCard } from "../../../../../components/Shared/NftCard/NftActionsCard"
import { NftListLoading } from "../../../../../components/NftListComponents/NftListLoading/NftListLoading"
import { EmptyNftView } from "../../../../../components/NftListComponents/NftListView/components/EmptyNftView"
import { NftCardLostAndFound } from "../../../../../components/Shared/NftCard/NftCardLostAndFound"
import { useFlowtyModalsContext } from "../../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"

interface UserActionsViewProps {
	allUserActionCenterItems: Array<
		LoanRentalFilteredData | LostAndFoundNFTTicketData
	>
	isLoading: boolean
	isFiltered: boolean
}

export const UserActionsView: React.FC<UserActionsViewProps> = ({
	allUserActionCenterItems,
	isLoading,
	isFiltered,
}) => {
	const { selectCard } = useFlowtyModalsContext()
	return isLoading ? (
		<NftListLoading />
	) : (
		<>
			<ul
				className={`w-full h-full grid pb-4 gap-4`}
				style={{
					gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
				}}
			>
				{allUserActionCenterItems?.map(
					(
						item: LoanRentalFilteredData | LostAndFoundNFTTicketData,
						i: number
					) => {
						const handleClick = (
							event:
								| React.MouseEvent<HTMLDivElement, MouseEvent>
								| React.KeyboardEvent<HTMLDivElement>
						): void => {
							event.preventDefault()
							if (
								(item as LoanRentalFilteredData)?.type === "loan" ||
								(item as LoanRentalFilteredData)?.type === "rental"
							) {
								selectCard({
									loanRentalActions: item as LoanRentalFilteredData,
									selected: {
										contractAddress: (item as LoanRentalFilteredData).nftData
											.collectionAddress,
										contractName: (item as LoanRentalFilteredData).nftData
											.collectionName,
										nftID: (item as LoanRentalFilteredData).nftID,
										nftType: (item as LoanRentalFilteredData).nftType,
									},
								})
							} else {
								selectCard({
									lostAndFoundModal: true,
									lostAndFoundTicketData: {
										catalogIdentifier: (item as LostAndFoundNFTTicketData)
											.catalogIdentifier,
										ticketID: (item as LostAndFoundNFTTicketData).ticketID,
									},
									selected: {
										contractAddress: (item as LostAndFoundNFTTicketData)
											.collectionAddress,
										contractName: (item as LostAndFoundNFTTicketData)
											.collectionName,
										nftID: (item as LostAndFoundNFTTicketData).nftID,
										nftType: (item as LostAndFoundNFTTicketData).typeIdentifier,
									},
								})
							}
						}

						if (
							(item as LoanRentalFilteredData)?.type === "loan" ||
							(item as LoanRentalFilteredData)?.type === "rental"
						) {
							return (
								<NftActionsCard
									key={`${item?.nftID}-${i}`}
									handleClick={handleClick}
									nft={item as LoanRentalFilteredData}
									isLoading={isLoading}
									isCatalog={true}
								/>
							)
						} else {
							return (
								<NftCardLostAndFound
									key={`${item?.nftID}-${i}`}
									handleClick={handleClick}
									nft={item as LostAndFoundNFTTicketData}
									isLoading={isLoading}
									isCatalog={true}
								/>
							)
						}
					}
				)}
			</ul>
			{allUserActionCenterItems.length === 0 && !isLoading && (
				<div className='pt-6'>
					<EmptyNftView
						isUnsupportedSelection={false}
						emptyActionsPage={!isFiltered}
					/>
				</div>
			)}
		</>
	)
}
