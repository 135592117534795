import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../../../types/FlowtyCreatorHubContextTypes"
import { CustomizeFirstStep } from "./components/CustomizeFirstStep/CustomizeFirstStep"
import { CustomizeSecondStep } from "./components/CustomizeSecondStep/CustomizeSecondStep"

export const CustomizeCollectionFormStep: React.FunctionComponent = () => {
	const { formTab } = useFlowtyCreatorHubContext()
	return formTab === FormTabs.CustomizeCollectionFirstTab ? (
		<CustomizeFirstStep />
	) : (
		<CustomizeSecondStep />
	)
}
