import { OfferCreated, Order, OrdersType } from "flowty-common"
import { AccountSummary, AccountSummaries, Flowty } from "flowty-sdk"
import { useFlowtyPurchaseModalContext } from "../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import TransactionFailure from "../common/TransactionFailure"
import { TransactionProcessing } from "../common/TransactionProcessing/TransactionProcessing"
import { TransactionSuccess } from "../common/TransactionSuccess/TransactionSuccess"
import { PurchaseMultiView } from "./PurchaseMultiView/PurchaseMultiView"
import { PurchaseSingleView } from "./PurchaseSingleView/PurchaseSingleView"
import OfferCancelSection from "./OfferCancelSection"
import OfferMakeSection from "./OfferMakeSection"
import { LoanRentalActionsSection } from "./LoanRentalActionsSection/LoanRentalActionsSection"
import { LoadingScreenMessages } from "../common/LoadingScreenMessages/LoadingScreenMessages"
import { LostAndFoundView } from "./LostAndFoundView/LostAndFoundView"

interface PurchaseModalViewProps {
	nftOrders?: OrdersType | null
	singleListing?: Order
	accountSummaries: AccountSummaries
	flowty: Flowty
	readonly strapiUrl: string
}

export const PurchaseModalView: React.FunctionComponent<
	PurchaseModalViewProps
> = ({ nftOrders, singleListing, accountSummaries, flowty, strapiUrl }) => {
	const {
		activeTab,
		setActiveTab,
		isMakeOffer,
		isLoading,
		error,
		sealed,
		isDapper,
		payWithSelectedAccount,
		updateSelectedAccount,
		sendToSelectedAccount,
		hasChildAccounts,
		offerDuration,
		offerValue,
		offerTokenType,
		listingType,
		isFormError,
		setSelectedOrder,
		transactionID,
		orderData,
		spotPrice,
		dapperOffer,
		nonCustodialOffer,
		isCancelOffer,
		isOrderListedByLoggedAccount,
		openSearchFlowNFT,
		isLoadingOrders,
		loanRentalActionsData,
		isMainnet,
		mixPanelFn,
		isLostAndFound,
	} = useFlowtyPurchaseModalContext()
	if (isLoadingOrders && !isMakeOffer) {
		return (
			<div className='h-full flex flex-col items-center justify-center'>
				<div className='w-full h-full md:w-[400px] flex flex-col justify-between'>
					<div className='w-full flex flex-col gap-[32px]'>
						<div className='w-[234px] h-[32px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						<div className='w-full h-[153px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
					</div>
					<div className='w-full h-[32px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
				</div>
			</div>
		)
	}
	if (error[listingType]) {
		return (
			<div className='h-full flex flex-col items-center justify-center'>
				<TransactionFailure
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
			</div>
		)
	}

	if (sealed[listingType]) {
		return (
			<div className='h-full flex flex-col items-center justify-center'>
				<TransactionSuccess
					isMainnet={isMainnet}
					type={listingType}
					transactionID={transactionID}
					orderData={orderData}
					nft={openSearchFlowNFT}
				/>
			</div>
		)
	}

	if (isLoading[listingType]) {
		return (
			<div className='h-full flex flex-col items-center justify-center mt-48 '>
				<TransactionProcessing
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
				<div className='flex-grow ' />
				<LoadingScreenMessages strapiUrl={strapiUrl} mixPanelFn={mixPanelFn} />
			</div>
		)
	}

	if (isLostAndFound) return <LostAndFoundView />

	return (
		<div className='w-full md:w-[400px] h-full flex flex-col gap-4 flex-shrink-0 relative'>
			{loanRentalActionsData ? (
				<LoanRentalActionsSection
					accountSummaries={accountSummaries}
					loanRentalActionsData={loanRentalActionsData}
				/>
			) : isMakeOffer ? (
				<>
					<OfferMakeSection
						isDapper={Boolean(isDapper)}
						selectedAccount={payWithSelectedAccount}
						offerValue={Number(offerValue)}
						offerDuration={offerDuration}
						offerToken={offerTokenType}
						accountSummaries={accountSummaries}
						hasChildAccounts={hasChildAccounts}
						payWithSelectedAccount={payWithSelectedAccount}
						setPayWithSelectedAccount={(account: AccountSummary) =>
							updateSelectedAccount(account, "payWith")
						}
						setSendToSelectedAccount={(account: AccountSummary) =>
							updateSelectedAccount(account, "sendTo")
						}
						sendToSelectedAccount={sendToSelectedAccount}
						isMakeOfferFormError={isFormError.makeOffer}
						spotPrice={spotPrice}
						dapperOffer={dapperOffer}
						nonCustodialOffer={nonCustodialOffer}
						storefrontOrders={nftOrders?.storefront}
						flowty={flowty}
					/>
				</>
			) : isCancelOffer ? (
				<OfferCancelSection
					spotPrice={spotPrice}
					offerData={orderData as OfferCreated}
					isOrderListedByLoggedAccount={isOrderListedByLoggedAccount}
					nftOwnerAddress={openSearchFlowNFT?.owner || ""}
				/>
			) : (
				<>
					{nftOrders && (
						<PurchaseMultiView
							setSelectedOrder={setSelectedOrder}
							activeTab={activeTab}
							accountSummaries={accountSummaries}
							setActiveTab={setActiveTab}
							nftOrders={nftOrders}
							isDapper={Boolean(isDapper)}
						/>
					)}
					{singleListing && (
						<PurchaseSingleView
							singleListing={singleListing}
							isDapper={Boolean(isDapper)}
							accountSummaries={accountSummaries}
						/>
					)}
				</>
			)}
		</div>
	)
}
