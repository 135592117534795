import {
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
} from "flowty-common"
import React, { useMemo } from "react"
import { FlowtyTabs } from "../../../FlowtyTabs"
import {
	AccountSummaries,
	AccountSummary,
	RentListingOrderType,
	StorefrontAcceptOfferOrderType,
} from "../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { FlowtyViewOnlyDisplay } from "../common/FlowtyViewOnlyDisplay/FlowtyViewOnlyDisplay"
import TransactionFailure from "../common/TransactionFailure"
import { TransactionProcessing } from "../common/TransactionProcessing/TransactionProcessing"
import { TransactionSuccess } from "../common/TransactionSuccess/TransactionSuccess"
import { AcceptOfferView } from "./AcceptOfferView/AcceptOfferView"
import { LoanDelisting } from "./LoanDelisting/LoanDelisting"
import { LoanListing } from "./LoanListing/LoanListing"
import { RentListing } from "./RentListing/RentListing"
import { RentalDelisting } from "./RentalDelisting/RentalDelisting"
import { SaleDelisting } from "./SaleDelisting/SaleDelisting"
import { SaleListing } from "./SaleListing/SaleListing"
import { Transfer } from "./Transfer/Transfer"
import { LockedDisplayView } from "./LockedDisplayView/LockedDisplayView"
import { Flowty } from "flowty-sdk"
import { LoadingScreenMessages } from "../common/LoadingScreenMessages/LoadingScreenMessages"

interface ListingModalView {
	readonly openSearchFlowNFT: OpensearchFlowNFT
	readonly accountSummaries: AccountSummaries
	readonly flowty: Flowty
	readonly strapiUrl: string
}

export const ListingModalView: React.FunctionComponent<ListingModalView> = ({
	openSearchFlowNFT,
	accountSummaries,
	flowty,
	strapiUrl,
}) => {
	const {
		activeTab,
		listingType,
		onTabChange,
		loanStep,
		rentStep,
		selectedAccount,
		setSelectedAccount,
		selectedOrder,
		hasChildAccounts,
		hasProvider,
		childAccounts,
		isCatalog,
		isDapper,
		isFormError,
		valuationData,
		isLoading,
		isUpdateListing,
		sealed,
		error,
		transactionID,
		existingPurchaseOrder,
		existingLoanOrder,
		existingRentalOrder,
		orderData,
		loanFormValues,
		transferReceiver,
		isAcceptOffer,
		selectedOffer,
		singleOrder,
		singleAction,
		isLocked,
		isDelist,
		isMainnet,
		collectionDisplayName,
		mixPanelFn,
	} = useFlowtyListingModalContext()

	let convertedListingType:
		| "sale"
		| "rent"
		| "loan"
		| "transfer"
		| "acceptOffer" = useMemo(() => {
		if (listingType === "storefront") {
			return "sale"
		}
		if (listingType === "rental") {
			return "rent"
		}

		return listingType
	}, [listingType])

	if (listingType === "rental") {
		convertedListingType = "rent"
	}

	if (isLocked && !isDelist.sale) return <LockedDisplayView />

	if (
		Boolean(selectedOffer) &&
		!error.acceptOffer &&
		!sealed.acceptOffer &&
		!isLoading.acceptOffer
	) {
		return (
			<div className='h-full md:w-[400px] flex flex-col items-center justify-center'>
				<AcceptOfferView
					accountSummaries={accountSummaries}
					offerListingError={Boolean(isFormError.acceptOffer)}
					flowty={flowty}
				/>
			</div>
		)
	}

	if (
		Boolean(singleOrder) &&
		!error.delist &&
		!sealed.delist &&
		!isLoading.delist
	) {
		if (singleAction === "delistSale") {
			return (
				<div className='h-full flex flex-col items-center justify-center'>
					<SaleDelisting
						storefrontOrder={
							[singleOrder] as OpensearchStorefrontAvailableData[]
						}
					/>
				</div>
			)
		}
		if (singleAction === "delistLoan") {
			return (
				<div className='h-full flex flex-col items-center justify-center'>
					<LoanDelisting
						selectedOrder={singleOrder as OpenSearchListingAvailableData}
					/>
				</div>
			)
		}
		if (singleAction === "delistRental") {
			return (
				<div className='h-full flex flex-col items-center justify-center'>
					<RentalDelisting
						selectedOrder={singleOrder as OpensearchRentalAvailableData}
					/>
				</div>
			)
		}
	}

	if (error[convertedListingType] || error.delist || error.acceptOffer) {
		return (
			<div className='h-full flex flex-col items-center justify-center'>
				<TransactionFailure
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
			</div>
		)
	}

	if (sealed[convertedListingType] || sealed.delist || sealed.acceptOffer) {
		return (
			<div className='h-full flex flex-col items-center justify-center'>
				<TransactionSuccess
					isMainnet={isMainnet}
					type={convertedListingType}
					transactionID={transactionID}
					orderData={orderData}
					nft={openSearchFlowNFT}
					isDelist={sealed.delist}
					collectionDisplayName={collectionDisplayName}
				/>
			</div>
		)
	}

	if (
		isLoading[convertedListingType] ||
		isLoading.delist ||
		isLoading.acceptOffer
	) {
		return (
			<div className='h-full flex flex-col items-center relative'>
				<div className='flex-grow flex flex-col justify-center items-center'>
					<TransactionProcessing
						transactionID={transactionID}
						isMainnet={isMainnet}
					/>
				</div>
				<div className='md:absolute bottom-0 w-full'>
					<LoadingScreenMessages
						strapiUrl={strapiUrl}
						mixPanelFn={mixPanelFn}
					/>
				</div>
			</div>
		)
	}

	if (!hasProvider) {
		return <FlowtyViewOnlyDisplay />
	}

	return (
		<div className='md:w-[400px] h-full flex flex-col gap-4'>
			<FlowtyTabs
				activeTab={activeTab}
				condensed
				tabs={[
					{
						content: isAcceptOffer ? (
							<AcceptOfferView
								accountSummaries={accountSummaries}
								offerListingError={Boolean(isFormError.acceptOffer)}
								flowty={flowty}
							/>
						) : existingPurchaseOrder && !isUpdateListing ? (
							<SaleDelisting
								storefrontOrder={
									existingPurchaseOrder as OpensearchStorefrontAvailableData[]
								}
								selectedOrder={
									selectedOrder.purchase as OpensearchStorefrontAvailableData
								}
							/>
						) : (
							<SaleListing
								accountSummaries={accountSummaries}
								isDapper={Boolean(isDapper)}
								valuationData={valuationData}
								saleListingError={isFormError.sale}
								orderData={orderData as StorefrontAcceptOfferOrderType}
								flowty={flowty}
							/>
						),
						id: "1",
						label: "Sale",
					},
					{
						content:
							existingLoanOrder &&
							existingLoanOrder.length > 0 &&
							!isUpdateListing ? (
								<LoanDelisting
									loanOrder={
										existingLoanOrder as OpenSearchListingAvailableData[]
									}
									selectedOrder={
										selectedOrder.fundLoan as OpenSearchListingAvailableData
									}
								/>
							) : (
								<LoanListing
									isCatalog={isCatalog}
									isDapper={Boolean(isDapper)}
									hasChildAccounts={hasChildAccounts}
									selectedAccount={selectedAccount as AccountSummary}
									setSelectedAccount={setSelectedAccount}
									accountSummaries={accountSummaries}
									loanStep={loanStep}
									loanFormValues={loanFormValues}
									loanFormError={isFormError.loan}
									openSearchFlowNFT={openSearchFlowNFT}
									flowty={flowty}
								/>
							),
						id: "2",
						label: "Loan",
					},
					{
						content:
							existingRentalOrder && !isUpdateListing ? (
								<RentalDelisting
									rentalOrder={
										existingRentalOrder as OpensearchRentalAvailableData[]
									}
									selectedOrder={
										selectedOrder.fundRental as OpensearchRentalAvailableData
									}
								/>
							) : (
								<RentListing
									isCatalog={isCatalog}
									isDapper={Boolean(isDapper)}
									hasChildAccounts={hasChildAccounts}
									selectedAccount={selectedAccount as AccountSummary}
									setSelectedAccount={setSelectedAccount}
									accountSummaries={accountSummaries}
									rentStep={rentStep}
									rentData={orderData as RentListingOrderType}
									rentFormError={isFormError.rent}
									openSearchFlowNFT={openSearchFlowNFT}
									flowty={flowty}
								/>
							),
						id: "3",
						label: "Rent",
					},
					{
						content: (
							<Transfer
								isDapper={isDapper}
								childAccounts={childAccounts}
								openSearchFlowNFT={openSearchFlowNFT}
								transferFormError={isFormError.transfer}
								transferReceiver={transferReceiver || ""}
							/>
						),
						id: "4",
						label: "Transfer",
					},
				]}
				onChange={tab => {
					onTabChange(tab as "1" | "2" | "3" | "4")
				}}
				type='secondary'
			/>
		</div>
	)
}
