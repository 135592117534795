import {
	NotificationType,
	RentalExpirationWarningNotification,
} from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"

export type RentalExpirationWarningNotificationItemProps =
	RentalExpirationWarningNotification & {
		loggedUserAddress?: string
		queueMessage?: ({
			type,
			id,
		}: {
			type: NotificationType.RentalExpirationWarning
			id: string
		}) => void
	}

export const RentalExpirationWarningNotificationItem: React.FunctionComponent<
	RentalExpirationWarningNotificationItemProps
> = ({
	rentalFee,
	transactionID,
	imageUrl,
	timestamp,
	title,
	token,
	nftId,
	nftType,
	renterAddress,
	type,
	timeRemaining,
	listingType,
	listingResourceID,
	loggedUserAddress,
}) => {
	const message = getNotificationMessage({
		amount: rentalFee,
		loggedUserAddress: loggedUserAddress,
		timeRemaining,
		title,
		token,
		type,
		wallet: renterAddress,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			token={token}
			nftId={nftId}
			nftType={nftType}
			listingResourceID={listingResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType={listingType}
			type={type}
		/>
	)
}
