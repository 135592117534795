import React, { useContext, useState } from "react"
import { formatSecondsAsDays } from "../../../../util/dateTimeUtils"
import { useFlowtyFieldInput } from "../../../Modals/Listings/shared/FieldInput/useFlowtyFieldInput"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"

export const LoanForm: React.FunctionComponent = () => {
	const { orderFilters, setOrderFilters } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const [loanTerms, setLoanTerms] = useState({
		max: formatSecondsAsDays(orderFilters?.loan?.["term"]?.max) ?? "",
		min: formatSecondsAsDays(orderFilters?.loan?.["term"]?.min) ?? "",
	})

	const isListingTypeSelected = !!orderFilters.loan

	const adjustValueDate = (value: number): number => value * 60 * 60 * 24
	const adjustValueLTV = (value: number): number => value / 100
	const displayValue = (value: number): number =>
		Number((value * 100).toFixed(0))

	const onChange = (
		value: number,
		fieldName: string,
		minOrMax: "min" | "max"
	): void => {
		const copyFilters = { ...orderFilters }
		let copyValue = value
		if (fieldName === "term") {
			copyValue = adjustValueDate(value)

			setLoanTerms({
				...loanTerms,
				[minOrMax]: value,
			})
		}

		if (copyValue) {
			if (copyFilters.loan) {
				copyFilters.loan[fieldName] = {
					...copyFilters.loan[fieldName],
					[minOrMax]: copyValue,
				}
			} else {
				copyFilters.loan = {
					[fieldName]: {
						[minOrMax]: copyValue,
					},
				}
			}
		} else {
			if (copyValue) {
				copyFilters?.loan?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters.loan[field.name].max
			} else {
				copyFilters?.loan?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters?.loan?.[fieldName]
			}
		}

		setOrderFilters(copyFilters)
	}

	useFlowtyFieldInput()

	return (
		<div className='flex flex-col gap-[1.5rem]'>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] font-[600] text-white'>
					Loan Amount
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='loan-amount-min'
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						value={
							orderFilters?.loan?.[
								"derivations.calculatedValues.marketplaceAmount"
							]?.min ?? ""
						}
						name='derivations.calculatedValues.marketplaceAmount'
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='loan-amount-max'
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						name='derivations.calculatedValues.marketplaceAmount'
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						value={
							orderFilters?.loan?.[
								"derivations.calculatedValues.marketplaceAmount"
							]?.max ?? ""
						}
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] text-left font-[600] text-white'>
					APR %
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='loan-apr-min'
						value={
							orderFilters?.loan?.["derivations.marketplaceAPR"]?.min ?? ""
						}
						name='derivations.marketplaceAPR'
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='loan-apr-max'
						value={
							orderFilters?.loan?.["derivations.marketplaceAPR"]?.max ?? ""
						}
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						name='derivations.marketplaceAPR'
						type='number'
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] text-left font-[600] text-white'>
					Duration (Days)
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='loan-duration-min'
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						value={loanTerms.min || ""}
						name='term'
						onChange={e => {
							onChange(Number(e.target.value), e.target.name || "", "min")
						}}
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='loan-duration-max'
						value={loanTerms.max || ""}
						onChange={e => {
							onChange(Number(e.target.value), e.target.name || "", "max")
						}}
						name='term'
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] text-left font-[600] text-white'>
					Loan to Value (LTV) %
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='loan-ltv-min'
						value={
							orderFilters?.loan?.["valuations.aggregate.loanToValueRatio"]?.min
								? displayValue(
										orderFilters?.loan?.[
											"valuations.aggregate.loanToValueRatio"
										]?.min
								  )
								: ""
						}
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						onChange={e =>
							onChange(
								adjustValueLTV(Number(e.target.value)),
								e.target.name || "",
								"min"
							)
						}
						name='valuations.aggregate.loanToValueRatio'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='loan-ltv-max'
						value={
							orderFilters?.loan?.["valuations.aggregate.loanToValueRatio"]?.max
								? displayValue(
										orderFilters?.loan?.[
											"valuations.aggregate.loanToValueRatio"
										]?.max
								  )
								: ""
						}
						onFocus={() => {
							if (!isListingTypeSelected) {
								setOrderFilters({ loan: {} })
							}
						}}
						onChange={e =>
							onChange(
								adjustValueLTV(Number(e.target.value)),
								e.target.name || "",
								"max"
							)
						}
						name='valuations.aggregate.loanToValueRatio'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
		</div>
	)
}
