import { ChildAccountDisplay, Display, getDefaultImage } from "flowty-common"
import {
	AccountSummaries,
	Config,
	Fees,
	FTBalance,
	LostAndFoundScriptResponse,
	WalletBalance,
} from "../types"
import { Err, executeScript } from "../utils/Utils"
import { getAccountCollections } from "./getAccountCollections"
import { getAddressesWithCollectionPublicScript } from "./getAddressesWithCollectionPublic"
import { getBalanceForAvailableProvidersScript } from "./getBalanceForAvailableProvidersScript"
import { getCatalogIdentifiersScript } from "./getCatalogIdentifiers"
import { getChildAccountsScript } from "./getChildAccounts"
import { getFlowtyStorefrontFeeScript } from "./getFlowtyStorefrontFee"
import { getFlowtyWrappedIpfsScript } from "./getFlowtyWrappedIpfs"
import { getHybridCustodyNFTProviderPathsForStorageScript } from "./getHybridCustodyNFTProviderPathsForStorage"
import { getNftResourceTypesScript } from "./getNftResourceTypes"
import { getRoyaltyRate } from "./getRoyaltyRate"
import { getWalletBalanceScript } from "./getWalletBalance"
import { hasExposedProviders } from "./hasExposedProviders"
import { getHybridCustodyDescribeFTAccountScript } from "./hybridCustodyDescribeFTAccountScript"
import { getHybridCustodyFTProvidersScript } from "./hybridCustodyFTProvidersScript"
import { getIsCollectionSetupScript } from "./isCollectionSetup"
import { getIsDapperCollectionScript } from "./isDapperCollectionScript"
import { getStorefrontFeesScript } from "./storefrontFees"
import { getValidateOwnershipScript } from "./validateOwnership"
import { getLostAndFoundTicketsScript } from "../lostAndFound/scripts/getLostAndFoundTickets"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class ScriptService {
	config: Config

	hasExposedProviders = async (addr: string): Promise<boolean> => {
		const args = [fcl.arg(addr, t.Address)]
		const res = await executeScript<boolean>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			hasExposedProviders(this.config),
			args,
			"hasExposedProviders"
		)
		return res
	}

	getRoyaltyRate = async (
		owner: string,
		id: string,
		storagePath: string
	): Promise<number> => {
		const args = [
			fcl.arg(owner, t.Address),
			fcl.arg(id, t.UInt64),
			fcl.arg(storagePath, t.String),
		]

		const res = await executeScript<number>(
			getRoyaltyRate(this.config),
			args,
			"getRoyaltyRate"
		)

		return res
	}

	getFlowtyStorefrontFee = async (identifier: string): Promise<number> => {
		const res = await executeScript<number>(
			getFlowtyStorefrontFeeScript(this.config),
			[fcl.arg(identifier, t.String)],
			"getFlowtyStorefrontFee"
		)

		return res
	}

	getStorefrontFees = async (
		owner: string,
		storagePath: string,
		nftID: string,
		tokenIdentifier: string,
		price: number
	): Promise<Fees> => {
		const script = getStorefrontFeesScript(this.config)
		const args = [
			fcl.arg(owner, t.Address),
			fcl.arg(storagePath, t.String),
			fcl.arg(nftID.toString(), t.UInt64),
			fcl.arg(tokenIdentifier, t.String),
			fcl.arg(price.toFixed(7), t.UFix64),
		]

		const res = await executeScript<Fees>(script, args, "getStorefrontFees")
		return res
	}

	getAccountWalletBalance = async (
		address: string | null | undefined
	): Promise<WalletBalance | null> => {
		if (!address) {
			return null
		}

		try {
			const balances = await executeScript<{ [key: string]: number }>(
				getWalletBalanceScript(this.config),
				[fcl.arg(address, t.Address)],
				"getBalanceScript"
			)

			return { address, balances } || {}
		} catch (error) {
			Err("Failed to get account wallet balance", error)
		}

		return null
	}

	validateUserOwnsNft = async (
		owner: string,
		nftID: string,
		nftContractName: string,
		nftContractAddress: string,
		resourceName: string,
		storagePath: string
	): Promise<boolean> => {
		const script = getValidateOwnershipScript(
			this.config,
			nftContractName,
			nftContractAddress,
			resourceName,
			storagePath
		)

		const typeIdentifier = `A.${nftContractAddress.slice(
			2
		)}.${nftContractName}.${resourceName}`

		return executeScript<boolean>(
			script,
			[
				fcl.arg(owner, t.Address),
				fcl.arg(nftID.toString(), t.UInt64),
				fcl.arg(typeIdentifier, t.String),
			],
			"validateUserOwnsNft"
		)
	}

	isCollectionSetup = async (
		address: string,
		contractAddress: string,
		contractName: string,
		isDapper: boolean,
		isNFTCatalog: boolean
	): Promise<boolean> => {
		const txArguments = [
			fcl.arg(address, t.Address),
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
		]

		if (isNFTCatalog && !this.config.crescendo) {
			txArguments.push(fcl.arg(isDapper, t.Bool))
		}

		return executeScript<boolean>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			getIsCollectionSetupScript(this.config, isNFTCatalog),
			txArguments,
			"isCollectionSetupScript",
			1
		)
	}

	// TODO: This method cannot be given a crescendo variant because private paths will no
	// longer be part of the cadence language. Instead, we will need to manage that
	// behavior upstream and make a second separate method for crescendo
	getHybridCustodyNFTProviderPathsForStorage = async (
		parent: string,
		child: string,
		storagePath: string
	): Promise<number[] | null> => {
		const script = getHybridCustodyNFTProviderPathsForStorageScript(this.config)

		console.log("getHybridCustodyNFTProviderPathsForStorage", {
			child,
			parent,
			script,
			storagePath,
		})

		const txArguments = [
			fcl.arg(parent, t.Address),
			fcl.arg(child, t.Address),
			fcl.arg(storagePath, t.String),
		]

		const capabilityIDs = await executeScript<number[]>(
			script,
			txArguments,
			"getNFTProviderPathsForStorageScript",
			0
		)

		if (!capabilityIDs) {
			return null
		}

		return capabilityIDs
	}

	getAddressesWithCollectionPublic = async (
		addresses: string[],
		contractAddress: string,
		contractName: string
	): Promise<string[]> => {
		const txArguments = [
			fcl.arg(addresses, t.Array(t.Address)),
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
		]
		return executeScript<string[]>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			getAddressesWithCollectionPublicScript(this.config),
			txArguments,
			"checkCollectionPublicOnAddressesScript",
			2
		)
	}

	getHybridCustodyAccountSummaries = async (
		address: string
	): Promise<AccountSummaries> => {
		if (!address?.startsWith("0x")) return {}

		const txArguments = [fcl.arg(address, t.Address)]
		const res = await executeScript<AccountSummaries>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			getHybridCustodyDescribeFTAccountScript(this.config),
			txArguments,
			"getHybridCustodyAccountSummaries",
			5
		)

		if (!res) return {}

		return Object.fromEntries(
			Object.entries(res).map(([key, value]) => {
				const display: Display = {
					description: value.display?.description || "",
					name: value.display?.name,
					thumbnail: value.display?.thumbnail || getDefaultImage(key),
				}

				return [key, { ...value, display }]
			})
		)
	}

	getHybridCustodyFTProvidersScript = async (
		address: string
	): Promise<{
		Address: {
			[tokenPath: string]: {
				domain: string
				identifier: string
			}
		}
	} | null> => {
		if (!address?.startsWith("0x")) {
			return null
		}

		const txArguments = [fcl.arg(address, t.Address)]

		// TODO: alter the return type here to be a string identifier instead of a Path
		return executeScript<{
			Address: {
				[tokenPath: string]: {
					domain: string
					identifier: string
				}
			}
		}>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			getHybridCustodyFTProvidersScript(this.config),
			txArguments,
			"getHybridCustodyFTProvidersScript",
			2
		)
	}

	getBalanceForAvailableProvidersScript = async (
		address: string,
		providers: string[]
	): Promise<FTBalance> => {
		const txArguments = [
			fcl.arg(address, t.Address),
			fcl.arg(providers, t.Array(t.String)),
		]

		return executeScript<FTBalance>( // eslint-disable-next-line @typescript-eslint/no-use-before-define
			getBalanceForAvailableProvidersScript(this.config),
			txArguments,
			"getBalanceForAvailableProvidersScript",
			2
		)
	}

	isDapperCollection = async (
		contractAddress: string,
		contractName: string
	): Promise<boolean> => {
		const txArguments = [
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
		]
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return executeScript<boolean>(
			getIsDapperCollectionScript(this.config),
			txArguments,
			"isDapperCollectionScript",
			3
		)
	}

	getIdentifierFromCatalog = async (
		nftType: string
	): Promise<{ [key: string]: boolean } | null> => {
		let type = nftType

		// temporary hack since type doesn't have the .NFT suffix sometimes
		// in the future, it's possible that we will be having to deal with the .NFT suffix
		// possibly not being a reliable marker for nfts as well.
		//
		// when that is the case, we'll have to rework this
		if (!type.endsWith(".NFT")) {
			type = `${type}.NFT`
		}

		return executeScript<{ [key: string]: boolean } | null>(
			getCatalogIdentifiersScript(this.config),
			[fcl.arg(type, t.String)],
			"getCatalogIdentifiersScript"
		)
	}

	getFlowtyWrappedIpfs = async (address: string): Promise<string> => {
		const txArguments = [fcl.arg(address, t.Address)]
		const result = await executeScript<string>(
			getFlowtyWrappedIpfsScript(this.config),
			txArguments,
			"getIpfsLinkScript",
			3
		)

		return result
	}

	getCatalogEntryForType = async (nftType: string): Promise<string | null> => {
		const catalogIdentifiers = await this.getIdentifierFromCatalog(nftType)
		if (!catalogIdentifiers) {
			// this means we don't know how to list the asset for sale since it is not part of the NFTCatalog!
			return null
		}

		let firstEntry = ""
		const keys = Object.keys(catalogIdentifiers)
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i]
			if (catalogIdentifiers[key]) {
				firstEntry = key
				break
			}
		}

		return firstEntry
	}

	getChildAccounts = async (
		address: string
	): Promise<{ [key: string]: ChildAccountDisplay } | null> => {
		if (!address) {
			return null
		}

		try {
			const res = await executeScript<{ [key: string]: ChildAccountDisplay }>(
				getChildAccountsScript(this.config),
				[fcl.arg(address, t.Address)],
				"getChildAccounts",
				3
			)

			return res || {}
		} catch (e) {
			console.error("failed to get child accounts", e)
		}
		return null
	}

	getNftResourceIdentifiers = async (
		contractAddress: string,
		contractName: string
	): Promise<string[]> => {
		if (!this.config.crescendo) {
			return [`A.${contractAddress.slice(2)}.${contractName}.NFT`]
		}

		const args = [
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
		]
		const content = getNftResourceTypesScript(this.config)
		return executeScript<string[]>(content, args, "getNftResourceTypes")
	}

	getAccountCollections = async (
		userAddress: string
	): Promise<{
		address: string
		contracts: unknown
	} | null> => {
		if (!this.config.crescendo) {
			return null
		}

		const args = [fcl.arg(userAddress, t.Address)]
		const content = getAccountCollections(this.config)
		return executeScript<{
			address: string
			contracts: unknown
		}>(content, args, "getNftResourceTypes")
	}

	getLostAndFoundTickets = async (
		address: string
	): Promise<LostAndFoundScriptResponse[]> => {
		if (!address?.startsWith("0x")) return []

		const txArguments = [fcl.arg(address, t.Address)]
		const res = await executeScript<LostAndFoundScriptResponse[]>(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			getLostAndFoundTicketsScript(this.config),
			txArguments,
			"getLostAndFoundTickets",
			5
		)

		if (!res) return []

		return res
	}

	constructor(config: Config) {
		this.config = config
	}
}
