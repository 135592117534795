import React from "react"
import { EventTypesWrapper } from "./EventTypesWrapper"

interface Props {
	storefrontForm: React.ReactNode
	rentalForm: React.ReactNode
	loanForm: React.ReactNode
	setEventType: (eventType: "storefront" | "loan" | "rent" | "all") => void
}

export const EventTypeFilter: React.FC<Props> = ({
	storefrontForm,
	rentalForm,
	loanForm,
	setEventType,
}) => {
	return (
		<div className='w-max h-fit flex flex-col gap-3 px-2 py-6 eventTypeCard min-w-[344px]'>
			<div className='flex items-center justify-between w-full px-[1rem]'>
				<div className='flex items-center gap-[0.5rem]'>
					<svg
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M18.432 9.39523L9.39409 18.433C8.91944 18.9078 8.14511 18.9078 7.67038 18.433L1.56698 12.3296C1.09226 11.855 1.09226 11.0807 1.56698 10.6059L10.6048 1.568C10.8485 1.32429 11.1478 1.20405 11.4925 1.21124L16.4403 1.31577C17.03 1.32816 17.5574 1.56023 17.9985 2.00148C18.4398 2.44273 18.6718 2.97003 18.6842 3.55972L18.7887 8.50753C18.796 8.85222 18.6757 9.15152 18.432 9.39523ZM15.1291 4.87097C14.5496 4.29148 13.61 4.29148 13.0305 4.87097C12.451 5.45046 12.451 6.38999 13.0305 6.96945C13.61 7.54894 14.5496 7.54894 15.1292 6.96945C15.7087 6.38999 15.7087 5.45046 15.1291 4.87097Z'
							fill='white'
							fillOpacity='0.8'
						/>
					</svg>
					<span className='text-[0.875rem] font-montserrat font-[600]'>
						Event Type
					</span>
				</div>
			</div>
			<div className='space-y-2 mt-3 overflow-y-auto styled-scroll px-[1rem]'>
				<EventTypesWrapper
					loanForm={loanForm}
					rentalForm={rentalForm}
					storefrontForm={storefrontForm}
					setEventType={setEventType}
				/>
			</div>
		</div>
	)
}
