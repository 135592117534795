import { ScriptErrorResponse } from "../../common/scriptError/ScriptErrorType"
import { Config } from "../../types"
import { Err, executeScript } from "../../utils/Utils"
import { getAllDropDetails } from "../scripts/getAllDropDetails"
import { getDropDetails } from "../scripts/getDropDetails"
import {
	AllDropsDetailsProps,
	DropDetailsProps,
	DropDetailsResult,
} from "./DropDetailsTypes"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class DropDetails {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	getDropDetails = async ({
		contractAddress,
		contractName,
		dropID,
		minter,
		quantity,
		paymentIdentifier,
	}: DropDetailsProps): Promise<DropDetailsResult | ScriptErrorResponse> => {
		const scriptArguments = [
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
			fcl.arg(dropID, t.UInt64),
			fcl.arg(minter || null, t.Optional(t.Address)),
			fcl.arg(quantity || null, t.Optional(t.Int)),
			fcl.arg(paymentIdentifier || null, t.Optional(t.String)),
		]

		const script = getDropDetails(this.config.contractAddresses.DropTypes)

		try {
			const res: DropDetailsResult | ScriptErrorResponse = await executeScript(
				script,
				scriptArguments
			)
			return res
		} catch (e) {
			console.log("Get drop details Script Error", e)
			Err("Error running drop details script", e)
			throw e
		}
	}

	getAllDropDetails = async ({
		nftResourceTypeIdentifier,
		minter,
		quantity,
		paymentIdentifier,
	}: AllDropsDetailsProps): Promise<
		DropDetailsResult[] | ScriptErrorResponse
	> => {
		const scriptArguments = [
			fcl.arg(nftResourceTypeIdentifier, t.String),
			fcl.arg(minter || null, t.Optional(t.Address)),
			fcl.arg(quantity || null, t.Optional(t.Int)),
			fcl.arg(paymentIdentifier || null, t.Optional(t.String)),
		]

		const script = getAllDropDetails(this.config.contractAddresses.DropTypes)

		try {
			const res: DropDetailsResult[] | ScriptErrorResponse =
				await executeScript(script, scriptArguments)
			return res
		} catch (e) {
			console.log("Get drop details Script Error", e)
			Err("Error running all drop details script", e)
			throw e
		}
	}
}
