import { Config } from "../types"

export const getStorefrontFeesScript = (config: Config): string => {
	return config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getFeesScriptCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getFeesScript(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getFeesScript = (config: Config): string => {
	return `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

pub struct Fees {
  pub let dapper: UFix64
  pub let flowty: UFix64
  pub let royalties: UFix64
  pub let seller: UFix64

  init(_ d :UFix64, _ f: UFix64, _ r: UFix64, _ s: UFix64) {
    self.dapper = d
    self.flowty = f
    self.royalties = r
    self.seller = s
  }
}

pub fun main(addr: Address, path: String, nftID: UInt64, paymentTokenIdentifier: String, price: UFix64): Fees {
  let tokenComposite = CompositeType(paymentTokenIdentifier)!
  let acct = getAuthAccount(addr)
  let storagePath = StoragePath(identifier: path)!
  let col = acct.borrow<&{NonFungibleToken.CollectionPublic}>(from: storagePath) ?? panic("collection not found")
  let nft = col.borrowNFT(id: nftID)

  let mpFee = NFTStorefrontV2.getFee(p: price, t: tokenComposite)

  // dapper only has a fee if we are using DUC
  var dapperFee = 0.0
  let flowtyFee = mpFee - dapperFee

  let cap = acct.getCapability<&{FungibleToken.Receiver}>(/public/foo)
  let cuts = NFTStorefrontV2.getPaymentCuts(r: cap, n: nft, p: price, tokenType: tokenComposite)

  var royalties = 0.0
  var seller = 0.0
  for i, c in cuts {
    if i == cuts.length - 1 {
      seller = c.amount
      continue
    }

    royalties = royalties + c.amount
  }

  return Fees(dapperFee, flowtyFee, royalties, seller)
}`
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getFeesScriptCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

access(all) struct Fees {
  access(all) let dapper: UFix64
  access(all) let flowty: UFix64
  access(all) let royalties: UFix64
  access(all) let seller: UFix64

  init(_ d :UFix64, _ f: UFix64, _ r: UFix64, _ s: UFix64) {
    self.dapper = d
    self.flowty = f
    self.royalties = r
    self.seller = s
  }
}

access(all) fun main(addr: Address, path: String, nftID: UInt64, paymentTokenIdentifier: String, price: UFix64): Fees {
  let tokenComposite = CompositeType(paymentTokenIdentifier)!
  let acct = getAuthAccount<auth(Storage, Capabilities) &Account>(addr)
  let storagePath = StoragePath(identifier: path)!
  let col = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: storagePath) ?? panic("collection not found")
  let nft = col.borrowNFT(nftID) ?? panic("nft not found")

  let mpFee = NFTStorefrontV2.getFee(p: price, t: tokenComposite)

  // dapper only has a fee if we are using DUC
  var dapperFee = 0.0
  let flowtyFee = mpFee - dapperFee

  let cap = acct.capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
  let cuts = NFTStorefrontV2.getPaymentCuts(r: cap, n: nft, p: price, tokenType: tokenComposite)

  var royalties = 0.0
  var seller = 0.0
  for i, c in cuts {
    if i == cuts.length - 1 {
      seller = c.amount
      continue
    }

    royalties = royalties + c.amount
  }

  return Fees(dapperFee, flowtyFee, royalties, seller)
}`
