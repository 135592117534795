/* eslint-disable @typescript-eslint/no-use-before-define,class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import { Card, ChildAccountDisplay, Display, Path } from "flowty-common"

export type ContractNames =
	| "TransactionTypes"
	| "Doodles"
	| "ViewResolver"
	| "MetadataViews"
	| "NonFungibleToken"
	| "FungibleToken"
	| "FlowtyAddressVerifiers"
	| "FlowtyDrops"
	| "FlowtyActiveCheckers"
	| "FlowtyPricers"
	| "NFTMetadata"
	| "DropTypes"
	| "FlowtyUtils"
	| "DapperUtilityCoin"
	| "FlowUtilityToken"
	| "NFTStorefrontV2"
	| "NFTCatalog"
	| "HybridCustody"
	| "FlowToken"
	| "USDCFlow"
	| "TokenForwarding"
	| "DapperWalletCollections"
	| "FlowtyWrapped"
	| "Flowty"
	| "TopShot"
	| "NFTStorefrontV2_Shared"
	| "StringUtils"
	| "OffersV2_Dapper"
	| "DapperOffersV2"
	| "Resolver"
	| "FlowtyOffersResolver"
	| "Offers"
	| "ContractFactory"
	| "ContractFactoryTemplate"
	| "OpenEditionTemplate"
	| "OpenEditionInitializer"
	| "ContractManager"
	| "BaseCollection"
	| "CapabilityCache"
	| "AddressUtils"
	| "FungibleTokenMetadataViews"
	| "FungibleTokenRouter"
	| "LostAndFound"
	| "LostAndFoundHelper"

export type Network = "mainnet" | "testnet" | "emulator"

export class Config {
	apiURL: string

	contractAddresses: Contracts

	network: "mainnet" | "testnet" | "emulator" | ""

	crescendo: boolean

	constructor(
		apiURL: string,
		contractAddresses: Contracts,
		network: Network,
		crescendo: boolean
	) {
		this.apiURL = apiURL
		this.contractAddresses = contractAddresses
		this.network = network
		this.crescendo = crescendo
	}

	getIdentifier(contractName: ContractNames, resourceName: string): string {
		return `A.${this.contractAddresses[contractName].substring(
			2
		)}.${contractName}.${resourceName}`
	}
}

export type Contracts = {
	[K in ContractNames]: string
}

export interface Fees {
	dapper: number
	flowty: number
	royalties: number
	seller: number
}

export interface WalletBalance {
	address: string
	balances?: { [key: string]: number }
}

export interface TokenSummary {
	balance: number | null
	receiverPath: Path | null
	providerPaths: Path[] | null
	type: string
}

export interface AccountSummary {
	address: string
	display: Display
	isDapper: boolean
	tokens: { [key: string]: TokenSummary }
	isMain: boolean
}

export type AccountSummaries = { [key: string]: AccountSummary }

export interface ChildAccountKV {
	[key: string]: ChildAccountDisplay
}

export interface NFTProviderStatus {
	[address: string]: {
		[data: string]: [Path]
	}
}

export interface FTProviderStatus {
	[address: string]: {
		[data: string]: Path
	}
}

export interface FTBalance {
	[data: string]: string
}

export interface FTBalances {
	[address: string]: [FTBalance]
}

export interface StorefrontListingRequest {
	nftProviderAddress: string
	nftProviderPathIdentifier: string
	nftTypeIdentifier: string
	nftID: string
	price: number
	customID: string | null
	expiry: number
	buyerAddress: string | null
	catalogCollection: boolean
	nftStoragePathIdentifier: string
}

export interface LoanRentalFilteredData {
	type: "loan" | "rental"
	listingResourceID?: string
	rentalResourceID?: string
	renterAddress?: string
	fundingResourceID?: string
	nftData: Card
	nftContractStoragePath: string
	nftType: string
	nftID: string
	paymentTokenName: string
	settleDeadline: number
	enabledAutoRepayment: boolean
	term: number
	loanAmount?: number
	repaymentDue?: number
	rentalFee?: number
	rentalRefundableDeposit?: number
	blockTimestamp?: number
}

export interface LostAndFoundScriptResponse {
	description: string
	memo: string
	name: string
	redeemed: boolean
	redeemer: string
	thumbnail: string
	nftID: string
	ticketID: string
	typeIdentifier: string
	catalogIdentifier: string
	type: {
		type: string
		kind: string
		typeID: string
	}
}

export interface LostAndFoundNFTTicketData {
	description: string
	memo: string
	name: string
	redeemed: boolean
	redeemer: string
	thumbnail: string
	nftID: string
	ticketID: string
	collectionAddress: string
	collectionName: string
	typeIdentifier: string
	catalogIdentifier: string
}

export interface DropPhaseProps {
	phaseStartDate: number
	phaseEndDate: number
	phasePrice: number
}

// This has to be of type any because any value can be a value of a cadence field.
// More details here: https://cadence-lang.org/docs/1.0/json-cadence-spec
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CadenceValue = any

// This has to be of type any because the contents of a type definition in cadence
// can vary depending on the complexity of the type.
// More details here: https://cadence-lang.org/docs/1.0/json-cadence-spec
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CadenceType = any

export interface CadenceEncoder {
	encode(config: Config): CadenceValue
	getType(config: Config, t: any): CadenceType
}

export interface MetadataViewsFile {
	uri(): string
}

export class HttpFile implements CadenceEncoder {
	url: string

	constructor(url: string) {
		this.url = url
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("MetadataViews", "HTTPFile"), [
			{ name: "url", value: t.String },
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "url",
						value: {
							type: "String",
							value: this.url,
						},
					},
				],
				id: config.getIdentifier("MetadataViews", "HTTPFile"),
			},
		}
	}
}

export class IpfsFile implements CadenceEncoder {
	cid: string

	path: string | null

	constructor(cid: string, path: string | null) {
		this.cid = cid
		this.path = path
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("MetadataViews", "IPFSFile"), [
			{ name: "cid", value: t.String },
			{ name: "path", value: t.Optional(t.String) },
		])
	}

	encode(config: Config): CadenceValue {
		const pathValue: CadenceValue =
			this.path !== null
				? {
						type: "String",
						value: this.path,
				  }
				: null

		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "cid",
						value: {
							type: "String",
							value: this.cid,
						},
					},
					{
						name: "path",
						value: pathValue,
					},
				],
				id: config.getIdentifier("MetadataViews", "IPFSFile"),
			},
		}
	}
}

export class ExternalUrl implements CadenceEncoder {
	url: string

	constructor(url: string) {
		this.url = url
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("MetadataViews", "ExternalURL"), [
			{
				name: "url",
				value: t.String,
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "url",
						value: {
							type: "String",
							value: this.url,
						},
					},
				],
				id: config.getIdentifier("MetadataViews", "ExternalURL"),
			},
		}
	}
}

// DropsNftMetadata is a class that represents the cadence definition found at
// NFTMetadata.Metadata and contains basic information about a drop.
//
//     access(all) struct Metadata {
//        // these are used to create the display metadata view so that we can concatenate
//        // the id onto it.
//        access(all) let name: String
//        access(all) let description: String
//        access(all) let thumbnail: {MetadataViews.File}
//
//        access(all) let traits: MetadataViews.Traits?
//        access(all) let editions: MetadataViews.Editions?
//        access(all) let externalURL: MetadataViews.ExternalURL?
//     		...
//		 }
export class DropsNftMetadata implements CadenceEncoder {
	name: string

	description: string

	thumbnail: HttpFile | IpfsFile

	// traits will be filled in the future
	traits: null

	// editions will be filled in the future
	editions: null

	externalUrl: ExternalUrl

	constructor(
		name: string,
		description: string,
		thumbnail: HttpFile | IpfsFile,
		externalUrl: ExternalUrl
	) {
		this.name = name
		this.description = description
		this.thumbnail = thumbnail
		this.externalUrl = externalUrl

		this.traits = null
		this.editions = null
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("NFTMetadata", "Metadata"), [
			{ name: "name", value: t.String },
			{ name: "description", value: t.String },
			{
				name: "thumbnail",
				value: this.thumbnail.getType(config, t),
			},
			{
				name: "traits",
				value: t.Optional(
					t.Struct(config.getIdentifier("MetadataViews", "Traits"), []) // TODO
				),
			},
			{
				name: "editions",
				value: t.Optional(
					t.Struct(config.getIdentifier("MetadataViews", "Editions"), []) // TODO
				),
			},
			{
				name: "externalURL",
				value: this.externalUrl.getType(config, t),
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "name",
						value: {
							type: "String",
							value: this.name,
						},
					},
					{
						name: "description",
						value: {
							type: "String",
							value: this.description,
						},
					},
					{
						name: "thumbnail",
						value: this.thumbnail.encode(config),
					},
					{
						name: "traits",
						value: {
							type: "Optional",
							value: null,
						},
					},
					{
						name: "editions",
						value: {
							type: "Optional",
							value: null,
						},
					},
					{
						name: "externalURL",
						value: this.externalUrl.encode(config),
					},
					{
						name: "data",
						value: {
							type: "Dictionary",
							value: [],
						},
					},
				],
				id: config.getIdentifier("NFTMetadata", "Metadata"),
			},
		}
	}
}

export class MetadataViewsDisplay implements CadenceEncoder {
	name: string

	description: string

	thumbnail: HttpFile | IpfsFile

	constructor(
		name: string,
		description: string,
		thumbnail: HttpFile | IpfsFile
	) {
		this.name = name
		this.description = description
		this.thumbnail = thumbnail
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("MetadataViews", "Display"), [
			{
				name: "name",
				value: t.String,
			},
			{
				name: "description",
				value: t.String,
			},
			{
				name: "thumbnail",
				value: this.thumbnail.getType(config, t),
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "name",
						value: {
							type: "String",
							value: this.name,
						},
					},
					{
						name: "description",
						value: {
							type: "String",
							value: this.description,
						},
					},
					{
						name: "thumbnail",
						value: this.thumbnail.encode(config),
					},
				],
				id: config.getIdentifier("MetadataViews", "Display"),
			},
		}
	}
}

// DropDetails represent the core details of a drop
//
//     access(all) struct DropDetails {
//         access(all) let display: MetadataViews.Display
//         access(all) let medias: MetadataViews.Medias?
//         access(all) var totalMinted: Int
//         access(all) var minters: {Address: Int}
//         access(all) let commissionRate: UFix64
//         access(all) let nftType: String
//     }
export class DropDetails implements CadenceEncoder {
	display: MetadataViewsDisplay

	// medias will be filled in the future
	medias: null

	totalMinted: number

	// minters is a map of address to the number of mints they've done.
	// When encoding, it is always empty
	minters: { [key: string]: number }

	commissionRate: number

	nftType: string

	constructor(
		display: MetadataViewsDisplay,
		totalMinted: number,
		minters: { [key: string]: number },
		commissionRate: number,
		nftType: string
	) {
		this.display = display
		this.totalMinted = totalMinted
		this.minters = minters
		this.commissionRate = commissionRate
		this.nftType = nftType

		this.medias = null
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("FlowtyDrops", "DropDetails"), [
			{
				name: "display",
				value: this.display.getType(config, t),
			},
			{
				name: "medias",
				value: t.Optional(t.AnyStruct), // TODO
			},
			{
				name: "totalMinted",
				value: t.Int,
			},
			{
				name: "minters",
				value: t.Dictionary(t.Address, t.Int),
			},
			{
				name: "commissionRate",
				value: t.UFix64,
			},
			{
				name: "nftType",
				value: t.String,
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "display",
						value: this.display.encode(config),
					},
					{
						name: "medias",
						value: {
							type: "Optional",
							value: null,
						},
					},
					{
						name: "totalMinted",
						value: {
							type: "Int",
							value: "0",
						},
					},
					{
						name: "minters",
						value: {
							type: "Dictionary",
							value: [],
						},
					},
					{
						name: "commissionRate",
						value: {
							type: "UFix64",
							value: this.commissionRate.toFixed(4),
						},
					},
					{
						name: "nftType",
						value: {
							type: "String",
							value: this.nftType,
						},
					},
				],
				id: config.getIdentifier("FlowtyDrops", "DropDetails"),
			},
		}
	}
}

export class TimestampSwitcher implements CadenceEncoder {
	start: number | null

	end: number | null

	constructor(start: number | null, end: number | null) {
		this.start = start
		this.end = end
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(
			config.getIdentifier("FlowtyActiveCheckers", "TimestampSwitch"),
			[
				{
					name: "start",
					value: t.Optional(t.UInt64),
				},
				{
					name: "end",
					value: t.Optional(t.UInt64),
				},
			]
		)
	}

	encode(config: Config): CadenceValue {
		const startValue =
			this.start !== null
				? {
						type: "UInt64",
						value: this.start.toString(),
				  }
				: null

		const endValue =
			this.end !== null
				? {
						type: "UInt64",
						value: this.end.toString(),
				  }
				: null

		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "start",
						value: {
							type: "Optional",
							value: startValue,
						},
					},
					{
						name: "end",
						value: {
							type: "Optional",
							value: endValue,
						},
					},
				],
				id: config.getIdentifier("FlowtyActiveCheckers", "TimestampSwitch"),
			},
		}
	}
}

export class FlatPricePricer implements CadenceEncoder {
	price: number

	paymentTokenType: string

	constructor(price: number, paymentTokenType: string) {
		this.price = price
		this.paymentTokenType = paymentTokenType
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("FlowtyPricers", "FlatPrice"), [
			{
				name: "price",
				value: t.UFix64,
			},
			{
				name: "paymentTokenType",
				value: t.String,
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "price",
						value: {
							type: "UFix64",
							value: this.price.toFixed(4),
						},
					},
					{
						name: "paymentTokenType",
						value: {
							type: "String",
							value: this.paymentTokenType,
						},
					},
				],
				id: config.getIdentifier("FlowtyPricers", "FlatPrice"),
			},
		}
	}
}

export class FreePricer implements CadenceEncoder {
	getType(config: Config, t: any): CadenceType {
		return t.Struct(config.getIdentifier("FlowtyPricers", "Free"), [])
	}

	// eslint-disable-next-line class-methods-use-this
	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [],
				id: config.getIdentifier("FlowtyPricers", "Free"),
			},
		}
	}
}

export class AllowAllAddressVerifier implements CadenceEncoder {
	maxPerMint: number

	constructor(maxPerMint: number) {
		this.maxPerMint = maxPerMint
	}

	getType(config: Config, t: any): CadenceType {
		return t.Struct(
			config.getIdentifier("FlowtyAddressVerifiers", "AllowAll"),
			[]
		)
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "maxPerMint",
						value: {
							type: "Int",
							value: this.maxPerMint.toString(),
						},
					},
				],
				id: config.getIdentifier("FlowtyAddressVerifiers", "AllowAll"),
			},
		}
	}
}

export class PhaseDetails implements CadenceEncoder {
	switcher: TimestampSwitcher

	display: MetadataViewsDisplay | null

	pricer: FlatPricePricer | FreePricer

	addressVerifier: AllowAllAddressVerifier

	constructor(
		switcher: TimestampSwitcher,
		display: MetadataViewsDisplay | null,
		pricer: FlatPricePricer | FreePricer,
		addressVerifier: AllowAllAddressVerifier
	) {
		this.switcher = switcher
		this.display = display
		this.pricer = pricer
		this.addressVerifier = addressVerifier
	}

	getType(config: Config, t: any): CadenceType {
		t.Struct(config.getIdentifier("FlowtyDrops", "PhaseDetails"), [
			{
				name: "switcher",
				value: this.switcher.getType(config, t),
			},
			{
				name: "display",
				value: t.Optional(t.AnyStruct), // TODO
			},
			{
				name: "pricer",
				value: t.AnyStruct, // TODO
			},
			{
				name: "addressVerifier",
				value: t.AnyStruct, // TODO
			},
			{
				name: "data",
				value: t.Dictionary(t.String, t.AnyStruct), // TODO
			},
		])
	}

	encode(config: Config): CadenceValue {
		const displayValue =
			this.display !== null ? this.display.encode(config) : null

		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "switcher",
						value: this.switcher.encode(config),
					},
					{
						name: "display",
						value: {
							type: "Optional",
							value: displayValue,
						},
					},
					{
						name: "pricer",
						value: this.pricer.encode(config),
					},
					{
						name: "addressVerifier",
						value: this.addressVerifier.encode(config),
					},
					{
						name: "data",
						value: {
							type: "Dictionary",
							value: [],
						},
					},
				],
				id: config.getIdentifier("FlowtyDrops", "PhaseDetails"),
			},
		}
	}
}

export class MetadataViewsMedia implements CadenceEncoder {
	file: HttpFile | IpfsFile

	mediaType: string

	constructor(file: HttpFile | IpfsFile, mediaType: string) {
		this.file = file
		this.mediaType = mediaType
	}

	getType(config: Config, t: any): CadenceType {
		t.Struct(config.getIdentifier("MetadataViews", "Media"), [
			{
				name: "file",
				value: this.file.getType(config, t),
			},
			{
				name: "mediaType",
				value: t.String,
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "file",
						value: this.file.encode(config),
					},
					{
						name: "mediaType",
						value: {
							type: "String",
							value: this.mediaType,
						},
					},
				],
				id: config.getIdentifier("MetadataViews", "Media"),
			},
		}
	}
}

export class MetadataViewsCollectionDisplay implements CadenceEncoder {
	name: string

	description: string

	externalURL: ExternalUrl

	squareImage: MetadataViewsMedia

	bannerImage: MetadataViewsMedia

	socials: { [key: string]: ExternalUrl }

	constructor(
		name: string,
		description: string,
		externalURL: ExternalUrl,
		squareImage: MetadataViewsMedia,
		bannerImage: MetadataViewsMedia,
		socials: { [key: string]: ExternalUrl }
	) {
		this.name = name
		this.description = description
		this.externalURL = externalURL
		this.squareImage = squareImage
		this.bannerImage = bannerImage
		this.socials = socials
	}

	getType(config: Config, t: any): CadenceType {
		t.Struct(config.getIdentifier("MetadataViews", "NFTCollectionDisplay"), [
			{
				name: "name",
				value: t.String,
			},
			{
				name: "description",
				value: t.String,
			},
			{
				name: "externalURL",
				value: ExternalUrl,
			},
			{
				name: "squareImage",
				value: MetadataViewsMedia,
			},
			{
				name: "bannerImage",
				value: MetadataViewsMedia,
			},
			{
				name: "socials",
				value: t.Dictionary(
					t.String,
					t.Struct(config.getIdentifier("MetadataViews", "ExternalURL"), [
						{
							name: "url",
							value: t.String,
						},
					])
				),
			},
		])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "name",
						value: {
							type: "String",
							value: this.name,
						},
					},
					{
						name: "description",
						value: {
							type: "String",
							value: this.description,
						},
					},
					{
						name: "externalURL",
						value: this.externalURL.encode(config),
					},
					{
						name: "squareImage",
						value: this.squareImage.encode(config),
					},
					{
						name: "bannerImage",
						value: this.bannerImage.encode(config),
					},
					{
						name: "socials",
						value: {
							type: "Dictionary",
							value: Object.entries(this.socials).map(([key, value]) => ({
								key: {
									type: "String",
									value: key,
								},
								value: value.encode(config),
							})),
						},
					},
				],
				id: config.getIdentifier("MetadataViews", "NFTCollectionDisplay"),
			},
		}
	}
}

export class FlowtyDropsCollectionInfo implements CadenceEncoder {
	collectionDisplay: MetadataViewsCollectionDisplay

	constructor(collectionDisplay: MetadataViewsCollectionDisplay) {
		this.collectionDisplay = collectionDisplay
	}

	getType(config: Config, t: any): CadenceType {
		t.Struct(config.getIdentifier("NFTMetadata", "CollectionInfo"), [])
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Struct",
			value: {
				fields: [
					{
						name: "collectionDisplay",
						value: this.collectionDisplay.encode(config),
					},
				],
				id: config.getIdentifier("NFTMetadata", "CollectionInfo"),
			},
		}
	}
}

export class AnyStructDictionary implements CadenceEncoder {
	data: { [key: string]: CadenceEncoder }

	constructor(data: { [key: string]: CadenceEncoder }) {
		this.data = data
	}

	getType(config: Config, t: any): CadenceType {
		throw new Error(
			"not implemented, there is no fcl t.AnyStruct to describe this type's value"
		)
	}

	encode(config: Config): CadenceValue {
		return {
			type: "Dictionary",
			value: Object.entries(this.data).map(([key, value]) => ({
				key: {
					type: "String",
					value: key,
				},
				value: value.encode(config),
			})),
		}
	}
}
