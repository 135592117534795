import { ALLDAY_ADDRESS, TOPSHOT_ADDRESS } from "../../../util/settings"

export const TRUSTABLE_COLLECTIONS = [
	{
		collectionAddress: TOPSHOT_ADDRESS,
		collectionName: "TopShot",
	},
	{
		collectionAddress: ALLDAY_ADDRESS,
		collectionName: "AllDay",
	},
]
