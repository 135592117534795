import AuthStore from "./AuthStore"
import RootStore from "./RootStore"
import { Log } from "../util/Log"

Log("stores/index")
const rootStore = new RootStore()
const authStore = new AuthStore(rootStore)

// eslint-disable-next-line import/no-default-export
export default {
	authStore,
	rootStore,
}
