interface RoyaltyWarningProps {
	royalty: number
}

export const RoyaltyWarning: React.FunctionComponent<RoyaltyWarningProps> = ({
	royalty,
}) => {
	return (
		<div className='dapper-warning bg-[#FFFFFF14] flex flex-col px-[0.75rem] py-[0.5rem] self-stretch rounded-[0.5rem]'>
			<div className='flex items-center gap-[0.5rem] self-stretch font-montserrat text-[0.75rem] font-[400] leading-[0.75rem]'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='18'
					height='18'
					viewBox='0 0 18 18'
					fill='none'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.1998 8.99999C16.1998 12.9764 12.9763 16.2 8.9998 16.2C5.02335 16.2 1.7998 12.9764 1.7998 8.99999C1.7998 5.02354 5.02335 1.79999 8.9998 1.79999C12.9763 1.79999 16.1998 5.02354 16.1998 8.99999ZM9.89981 5.39999C9.89981 5.89704 9.49686 6.29999 8.9998 6.29999C8.50275 6.29999 8.0998 5.89704 8.0998 5.39999C8.0998 4.90293 8.50275 4.49999 8.9998 4.49999C9.49686 4.49999 9.89981 4.90293 9.89981 5.39999ZM8.0998 8.09999C7.60275 8.09999 7.1998 8.50293 7.1998 8.99999C7.1998 9.49704 7.60275 9.89999 8.0998 9.89999V12.6C8.0998 13.097 8.50275 13.5 8.9998 13.5H9.89981C10.3969 13.5 10.7998 13.097 10.7998 12.6C10.7998 12.1029 10.3969 11.7 9.89981 11.7V8.99999C9.89981 8.50293 9.49686 8.09999 8.9998 8.09999H8.0998Z'
						fill='#F8F9FA'
					/>
				</svg>

				<span className='w-fit text-[#ADB5BD] text-[12px]'>
					If you sell this NFT, there will be a {royalty.toFixed(1)}% creator
					royalty assessed
				</span>
			</div>
		</div>
	)
}
