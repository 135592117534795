import { FlowNFT } from "./flow"
import { OpensearchFlowNFT } from "./search"

export const flowSeriesToTopShotSeries = (series?: number): string => {
	const num = Number(series)
	switch (num) {
		case 0:
			return "1"
		case 2:
			return "2"
		case 3:
			return "Summer 2021"
		case 4:
			return "3"
		case 5:
			return "4"
		case 6:
			return "2023-24"
		default:
			return series?.toString() ?? ""
	}
}

export const checkIsLocked = (
	nft: FlowNFT | OpensearchFlowNFT | null
): boolean => {
	if (!nft) return false
	const traits = nft?.nftView?.traits?.traits
	if (!traits) return false
	const locked = !!traits?.find(
		(trait: { name: string; value: boolean }) =>
			trait.name === "Locked" &&
			(typeof trait.value === "boolean" ? trait.value : trait.value === "true")
	)
	return locked
}
