import { NftPrice } from "ds-flowty"
import { getTokenFromType, SupportedTokens } from "flowty-common"

interface PriceDisplayProps {
	price: string | undefined
	paymentType: string | undefined
	toolTipCustomId?: string
}

export const PriceDisplay: React.FC<PriceDisplayProps> = ({
	price,
	paymentType,
	toolTipCustomId,
}) => {
	if (!price) {
		return <>N/A</>
	}
	const parsedPrice = parseFloat(price)

	if (parsedPrice === 0) {
		return <>FREE</>
	}
	const token = paymentType
		? getTokenFromType(paymentType)
		: SupportedTokens.FLOW

	return (
		<NftPrice
			price={parsedPrice}
			token={token}
			toolTipCustomId={toolTipCustomId}
		/>
	)
}
