import { FlowNFTContract } from "flowty-common"

const storeSearchResult = (result: FlowNFTContract): void => {
	const recentSearches = localStorage.getItem("recentSearches")

	const parsedRecentSearches = JSON.parse(recentSearches || "[]")

	parsedRecentSearches.push(result)

	localStorage.setItem("recentSearches", JSON.stringify(parsedRecentSearches))
}

const getRecentSearches = (): FlowNFTContract[] => {
	const recentSearches = localStorage.getItem("recentSearches")
	if (recentSearches === "null") {
		return []
	} else {
		const objects = JSON.parse(recentSearches ?? "[]")

		const jsonObject = objects.map((object: FlowNFTContract) =>
			JSON.stringify(object)
		)

		const uniqueSet = new Set(jsonObject)
		const uniqueArray: FlowNFTContract[] = Array.from(uniqueSet).map(
			(object: unknown) => JSON.parse(object as string)
		)

		return uniqueArray
	}
}

const storeRecentSearchResult = (
	result: FlowNFTContract,
	max: number = 5
): void => {
	const recentSearches = localStorage.getItem("recentSearches")

	const parsedRecentSearches = JSON.parse(recentSearches || "[]")

	parsedRecentSearches.unshift(result)

	if (parsedRecentSearches.length > max) {
		parsedRecentSearches.length = max
	}

	localStorage.setItem("recentSearches", JSON.stringify(parsedRecentSearches))
}

export { storeSearchResult, getRecentSearches, storeRecentSearchResult }
