import { FlowtyCreatorHub } from "ds-flowty"
import { SpotPrice, SupportedTokens } from "flowty-common"
import { useGetFlowtyStorefrontFee } from "flowty-sdk"
import { inject, observer } from "mobx-react"
import React, { useCallback } from "react"
import { PageHeader } from "../components/PageHeader"
import { flowty } from "../config/config"
import { useTokenSpotPrice } from "../hooks/data/collections/useTokenSpotPrice"
import Layout from "../layouts/Layout"
import { logOut } from "../services/AuthService"
import { uploadIPFSImage } from "../services/CreatorHubService"
import { AuthStoreProp } from "../stores/AuthStore"
import { actions as Mixpanel } from "../util/Mixpanel"
import { strapiUrl } from "../util/settings"

const CreatorHubScreenComponent: React.FunctionComponent<AuthStoreProp> = ({
	authStore,
}) => {
	const metaTitle =
		"Flowty NFT Activity: Loans, Rentals and Storefront. View the latest activity from our users"
	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
	const metaImage = "https://www.flowty.io/icons/icon192.png"

	const mixPanelFn = useCallback((action: string, properties: unknown) => {
		Mixpanel.track(action, properties)
	}, [])

	const spotPrice = useTokenSpotPrice(SupportedTokens.FLOW)
	const flowIdentifier = flowty.tokens.getTokenIdentifier(SupportedTokens.FLOW)
	const { flowtyStorefrontFee } = useGetFlowtyStorefrontFee({
		flowty,
		identifier: flowIdentifier,
	})

	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<FlowtyCreatorHub
				mixPanelFn={mixPanelFn}
				flowty={flowty}
				strapiUrl={strapiUrl}
				logOutFn={logOut}
				spotPrice={spotPrice as SpotPrice}
				loggedUser={authStore?.loggedUser?.addr ?? undefined}
				loggedIsDapper={authStore?.loggedUser?.isDapper || false}
				uploadIPFSImage={uploadIPFSImage}
				flowtyMarketplaceFee={flowtyStorefrontFee}
			/>
		</Layout>
	)
}

export const CreatorHubScreen = inject("authStore")(
	observer(CreatorHubScreenComponent)
)
