import { FlowtyButton } from "ds-flowty"
import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { Tooltip } from "react-tooltip"

interface AcceptHideProps {
	callBack: () => void
	onAccept: () => void
	onHide: () => Promise<void>
	hasProvider: boolean
}
export const AcceptHide: React.FC<AcceptHideProps> = ({
	callBack,
	onAccept,
	onHide,
	hasProvider,
}) => {
	const [isLoading, setIsLoading] = useState(false)
	return (
		<div className='flex items-center gap-12'>
			{hasProvider ? (
				<div className='w-36'>
					<FlowtyButton
						onClick={onAccept}
						text='Accept'
						variant={"secondary"}
						btnSize='small'
					/>
				</div>
			) : (
				<>
					<div className='w-36'>
						<Tooltip
							id={`view-only-tooltip`}
							style={{
								backgroundColor: "white",
								color: "#031021",
								fontSize: "0.75rem",
							}}
						/>
						<div
							data-tooltip-id={`view-only-tooltip`}
							data-tooltip-content='This profile has view-only access to this item'
							data-tooltip-place='bottom'
						>
							<FlowtyButton
								onClick={onAccept}
								text='Accept'
								variant={"secondary"}
								btnSize='small'
								disabled
							/>
						</div>
					</div>
				</>
			)}
			<div className='w-36'>
				<FlowtyButton
					text={
						isLoading ? (
							<div className='flex justify-around items-center'>
								<Oval height={12} width={12} />
							</div>
						) : (
							"Hide"
						)
					}
					onClick={() => {
						setIsLoading(true)
						onHide()
							.then(() => callBack())
							.finally(() => setIsLoading(false))
					}}
					variant='secondary'
					btnSize='small'
					bgColor='white'
				/>
			</div>
		</div>
	)
}
