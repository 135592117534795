import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import React, { Fragment } from "react"
import { FlowtyButton } from "../FlowtyButton"

interface Props {
	isOpen: boolean
	onCloseModal: () => void
	linkURL: string
}

export const ExternalURLWarning: React.FunctionComponent<Props> = ({
	isOpen,
	linkURL,
	onCloseModal,
}) => {
	const openExternalURL = (): void => {
		window.open(linkURL, "_blank")
		onCloseModal()
	}
	const formatLinkURL = (link: string): React.ReactNode => {
		const urlPattern = /^(https?:\/\/)([^\/]+)(.*)$/
		const match = link.match(urlPattern)
		if (!match) return link

		const [, protocol, domain, path] = match
		const domainParts = domain.split(".")
		const mainDomain = domainParts.slice(0, -1).join(".")
		const topLevelDomain = domainParts.slice(-1).join(".")

		return (
			<>
				{protocol}
				<strong>{mainDomain}</strong>.{topLevelDomain}
				{path}
			</>
		)
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1000]' onClose={onCloseModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div
					data-testid='flowty-listing-modal'
					className='fixed max-md:top-[40px] inset-0 overflow-y-auto'
				>
					<div className='flex min-h-full items-center justify-center text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full md:w-[511px] max-h-[500px] h-full flex flex-col transform rounded-lg bg-[#04070B] text-left align-middle shadow-xl transition-all border border-[#495057]'>
								<Dialog.Title
									as='div'
									className='border-b border-[#495057] px-[40px] py-[30px] md:py-8 w-full flex justify-between items-center'
								>
									<p className='text-white font-black text-[24px]'>
										Leaving Flowty
									</p>
									<XIcon
										onClick={onCloseModal}
										className='w-6 h-6 md:h-8 md:w-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div className={`w-full p-[32px] flex flex-col gap-4`}>
									<p className='text-[#ADB5BD] text-[12px] font-normal leading-normal'>
										This link is taking you to an external site. Flowty has not
										reviewed or verified the authenticity or security of the
										site. Please only proceed if you trust the link.
									</p>
									<div className='bg-[#FFFFFF0F] py-[8px] px-[12px] rounded-lg'>
										<p className='text-[#D9D9D9] text-[12px]'>
											{formatLinkURL(linkURL)}
										</p>
									</div>
								</div>
								<div className='h-full w-full flex justify-center items-center gap-4 border-t border-[#495057] px-[40px] py-[18px]'>
									<FlowtyButton
										text={"BACK"}
										onClick={onCloseModal}
										variant='secondary'
										btnSize='small'
										bgColor='white'
										className='h-[35px] flex items-center justify-center px-4'
									/>
									<FlowtyButton
										text={"VISIT EXTERNAL SITE"}
										onClick={openExternalURL}
										variant='secondary'
										btnSize='small'
										className='h-[35px] flex items-center justify-center px-4'
										bgColor='warning'
									/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
