import React from "react"
import { cn } from "../../../../util/cn"
import { CheckIcon } from "@heroicons/react/outline"

interface CurrencyCheckboxProps {
	onChange: (checkedState: boolean) => void
	isChecked: boolean
	label: string
	icon: React.ReactNode
}

export const CurrencyCheckbox: React.FC<CurrencyCheckboxProps> = ({
	onChange,
	isChecked,
	label,
	icon,
}) => {
	const baseStyles =
		"text-black flex justify-center rounded-[0.25rem] items-center focus:border-none cursor-pointer h-[1rem] w-[1rem]"

	return (
		<div
			className='flex items-center px-[1.75rem] pt-[0.5rem] w-full gap-[0.5rem]'
			onClick={() => onChange(!isChecked)}
		>
			<div
				data-testid='wallet-currency-checkbox'
				className={cn(baseStyles, {
					"bg-primary": isChecked,
					"bg-transparent border border-white": !isChecked,
					"border-none": isChecked,
				})}
			>
				{isChecked && <CheckIcon />}
			</div>
			{icon}
			<div className='text-[0.875rem] font-semibold'>{label}</div>
		</div>
	)
}
