export const LoadingCollectionBanner: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col gap-3 py-8'>
			<div className='flex gap-5'>
				<div className='animate-pulse bg-[rgba(116,118,117,0.8)] h-[92px] w-[92px] rounded-[12px] shadow-customCollectionPage border border-[#6C757D]' />
				<div className='flex max-md:flex-col max-md:items-start max-md:gap-3 items-end bg-cover w-auto rounded-[24px]'>
					<div className='w-full h-fit flex gap-4'>
						<div className='w-full flex flex-col justify-between'>
							<div className='mt-3'></div>
							<div className='w-full flex flex-col justify-between'>
								<div className='flex flex-row gap-3 items-center mb-3'>
									<div className='animate-pulse bg-[#747675cb] w-[170px] h-[34px] rounded-md'></div>
									<div className='animate-pulse bg-[#747675cb] pl-2 sm:pl-4 w-[28px] h-[28px] rounded-full'></div>
								</div>
								<div className='gap-3 hidden sm:flex'>
									<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
									<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
									<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
									<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
								</div>
							</div>
						</div>
					</div>
					<div className='gap-3 flex sm:hidden'>
						<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
						<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
						<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
						<div className='animate-pulse bg-[#747675cb] pl-4 w-[28px] h-[28px] rounded-full'></div>
					</div>
				</div>
			</div>
			<div className='w-full flex justify-between max-md:flex-col'>
				<div
					className={`w-full sm:w-3/5 xl:w-2/5 md:self-center flex flex-col gap-2`}
				>
					<div className='animate-pulse bg-[#747675cb] w-full h-[17px] rounded-md pb-2'></div>
					<div className='animate-pulse bg-[#747675cb] w-4/5 h-[17px] rounded-md'></div>
				</div>
				<div className='flex gap-3 pb-2'>
					<div className='flex gap-5 py-[16px]'>
						<div className='flex flex-col gap-1'>
							<div className='animate-pulse bg-[#747675cb] w-[41px] h-[17px] rounded-md'></div>
							<div className='animate-pulse bg-[#747675cb] w-[51px] h-[22px] rounded-md'></div>
						</div>
						<div className='flex flex-col gap-1'>
							<div className='animate-pulse bg-[#747675cb] w-[76px] h-[17px] rounded-md'></div>
							<div className='animate-pulse bg-[#747675cb] w-[46px] h-[22px] rounded-md'></div>
						</div>
						<div className='flex flex-col gap-1'>
							<div className='animate-pulse bg-[#747675cb] w-[86px] h-[17px] rounded-md'></div>
							<div className='animate-pulse bg-[#747675cb] w-[103px] h-[22px] rounded-md'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
