import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { FCLTransactionResult, TokenMetadata } from "flowty-common"
import { Config, LoanRentalFilteredData } from "../../types"
import { sendMutation } from "../../utils/Utils"
import { getReturnRentalTxn } from "./transactions"

export class ReturnRental {
	config: Config

	returnRental = async (
		rental: LoanRentalFilteredData,
		token: TokenMetadata,
		txAvailableCallback: (transactionId: string) => void,
		nftProviderAddress: string,
		nftProviderPathIdentifier: string
	): Promise<FCLTransactionResult> => {
		if (!rental.nftData) {
			throw new Error("Selected listing has no associated nft")
		}

		// shouldn't be possible but in case of bad data
		if (!rental.paymentTokenName) {
			throw new Error("Invalid payment token")
		}

		if (!rental.rentalResourceID) {
			throw new Error("Invalid rental resource ID")
		}

		const txContent = getReturnRentalTxn(this.config, token, {
			contractAddress: rental.nftData.collectionAddress,
			contractName: rental.nftData.collectionName,
			id: rental.nftID,
			nftOwner: "",
			type: rental.nftType,
		})

		const ftProviderArg = this.config.crescendo
			? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
			: fcl.arg(nftProviderPathIdentifier, t.String)

		const txArguments = [
			fcl.arg(rental.rentalResourceID.toString(), t.UInt64),
			fcl.arg(this.config.contractAddresses.Flowty, t.Address),
			fcl.arg(nftProviderAddress, t.Address),
			ftProviderArg,
		]

		return sendMutation(txContent, txArguments, txAvailableCallback)
	}

	constructor(config: Config) {
		this.config = config
	}
}
