import React, { useCallback } from "react"
import { PrimaryFlowtyTab } from "./PrimaryFlowtyTab"
import { SecondaryFlowtyTab } from "./SecondaryFlowtyTab"
import { cn } from "../utils/cn"

export type TabConfig = {
	id: string
	label: string
	content?: React.ReactNode
	disabled?: boolean
	icon?: React.ReactNode
	onClick?: (id: string) => void
	isActive?: boolean
	badgeNotification?: string
	condensed?: boolean
	fullWidth?: boolean
}

export interface FlowtyTabsProps {
	activeTab: string
	onChange: (activeTabId: string) => void
	tabs: TabConfig[]
	type: "primary" | "secondary"
	isCenter?: boolean
	condensed?: boolean
	fullWidth?: boolean
}

export const FlowtyTabs: React.FC<FlowtyTabsProps> = ({
	activeTab = "1",
	onChange,
	tabs,
	type = "primary",
	isCenter,
	condensed,
	fullWidth,
}) => {
	const handleTabChange = useCallback(
		(tabId: string) => {
			onChange(tabId)
		},
		[onChange]
	)

	const tabWrapperClassName = cn(`inline-flex w-full`, {
		"justify-center": isCenter,
		"w-fit": condensed,
		"w-full justify-between": fullWidth,
	})

	const primaryTabsWrapperClassName = cn(
		`items-start border-x-none border-t-none`
	)

	const secondaryTabsWrapperClassName = cn(
		`rounded-md bg-[#606e7d33] px-[0.75rem] py-[0.75rem] flex-wrap gap-x-1 md:gap-x-3`
	)
	return (
		<>
			<div
				role='tablist'
				className={cn(tabWrapperClassName, {
					[primaryTabsWrapperClassName]: type === "primary",
					[secondaryTabsWrapperClassName]: type === "secondary",
				})}
			>
				{tabs.map(tab => {
					const isActive = activeTab === tab.id

					return (
						<React.Fragment key={tab.id}>
							{type === "primary" ? (
								<>
									<PrimaryFlowtyTab
										key={tab.id}
										id={tab.id}
										label={tab.label}
										content={tab.content}
										disabled={tab.disabled}
										icon={tab.icon}
										onClick={handleTabChange}
										isActive={isActive}
										condensed={!!condensed}
									/>
									<div className='border-b-[1px] border-primary-tab-border-inactive-24 border-opacity-5 h-[32px] max-w-[12px] min-w-[12px]' />
								</>
							) : (
								<SecondaryFlowtyTab
									condensed={!!condensed}
									key={tab.id}
									id={tab.id}
									label={tab.label}
									content={tab.content}
									disabled={tab.disabled}
									icon={tab.icon}
									onClick={handleTabChange}
									isActive={isActive}
									badgeNotification={tab.badgeNotification}
								/>
							)}
						</React.Fragment>
					)
				})}
				{type === "primary" ? (
					<div className='border-b-[1px] h-[32px] w-full border-primary-tab-border-inactive-24 border-opacity-5' />
				) : null}
			</div>
			<div className='w-full h-full'>
				{tabs.map(tab => {
					const isActive = activeTab === tab.id
					return (
						<div
							className='h-full'
							role='tabpanel'
							key={tab.id}
							id={`tabpanel-${tab.id}`}
							aria-labelledby={`tab-${tab.id}`}
							hidden={!isActive}
						>
							{tab.content}
						</div>
					)
				})}
			</div>
		</>
	)
}
