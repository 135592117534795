/* eslint-disable @typescript-eslint/no-explicit-any */
import { firestore } from "../../firebase"
import { SortColumnOrNull } from "../../components/SortableTable"

interface ActivitySubscribeProps {
	limit?: number
	accountId?: string
	borrowerCb: (arg: Array<any>) => void // TODO add typing
	sort?: SortColumnOrNull
	filterActivityBy?: any
	eventCollection?: string
	nftType?: string
	nftTypes?: string[]
}

export const subscribeToAllCollectionActivity = ({
	limit = 25,
	accountId,
	borrowerCb,
	sort,
	filterActivityBy,
	eventCollection = "p2pEvents",
	nftType,
	nftTypes,
}: ActivitySubscribeProps): (() => void) => {
	let borrowerQuery = firestore
		.collection(eventCollection)
		.orderBy(sort?.column ?? "blockTimestamp", sort?.order ?? "desc")

	if (nftTypes && nftTypes.length > 0) {
		borrowerQuery = borrowerQuery.where("data.nftType", "in", nftTypes)
	} else if (nftType) {
		borrowerQuery = borrowerQuery.where("data.nftType", "==", nftType)
	}

	if (filterActivityBy?.min) {
		borrowerQuery = borrowerQuery.where("type", "==", filterActivityBy?.min)
	}

	if (accountId) {
		borrowerQuery = borrowerQuery.where("accountAddress", "==", accountId)
	}

	const unsubscribeFromBorrowerEvents = borrowerQuery
		.limit(limit)
		.onSnapshot(async ({ docs }) => {
			borrowerCb(
				docs.map(doc => ({
					...doc.data(),
				}))
			)
		})
	return () => {
		unsubscribeFromBorrowerEvents()
	}
}
