export const AssetScreenLoader: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col gap-3'>
			<div className='animate-pulse bg-[rgba(116,118,117,0.8)] h-[400px] w-full rounded-md shadow-customCollectionPage border border-[#6C757D] mt-[1rem] lg:mt-[3rem]' />
			<div className='w-full flex gap-2 max-w-[240px] pt-10'>
				<div className='animate-pulse bg-[#747675cb] w-4/5 h-[40px] rounded-md'></div>
				<div className='animate-pulse bg-[#747675cb] w-4/5 h-[40px] rounded-md'></div>
			</div>

			<div className='animate-pulse bg-[#747675cb] w-full h-[37px] rounded-md pb-2 my-[0.5rem]'></div>
			<div className='flex flex-col gap-5 md:flex-row'>
				<div className='animate-pulse bg-[#747675cb] md:w-[350px] w-full h-[270px] rounded-md pb-2'></div>
				<div className='animate-pulse bg-[#747675cb] w-full h-[270px] rounded-md pb-2'></div>
			</div>
		</div>
	)
}
