import { config } from "@onflow/fcl"
import axios from "axios"
import { FlowtyBuilder } from "flowty-sdk"
import { Log } from "../util/Log"
import { ACCESS_API, apiURL, IS_CRESCENDO, NETWORK } from "../util/settings"

interface AccountProofData {
	appIdentifier: string
	nonce: string
}

export const accountProofDataResolver =
	async (): Promise<AccountProofData | null> => {
		const res = await axios.get<AccountProofData>(`${apiURL}/auth/proof`, {
			validateStatus: _ => true,
		})

		Log("accountProofDataResolver", { ...res.data })

		return res.data
	}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseConfig: { [key: string]: any } = {
	"0xFlowty": process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT,
	"0xFlowtyRentals": process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT,
	"0xFungibleToken": process.env.REACT_APP_ADDRESS_FUNGIBLE_TOKEN_CONTRACT,
	"0xNonFungibleToken":
		process.env.REACT_APP_ADDRESS_NON_FUNGIBLE_TOKEN_CONTRACT,
	NFTCollection: process.env.REACT_APP_NFT_COLLECTION,
	"accessNode.api": ACCESS_API,
	"app.detail.icon":
		"https://storage.googleapis.com/flowty-images/flowty-logo.jpeg",
	"app.detail.title": "Flowty",
	"challenge.scope": "email",
	"discovery.authn.endpoint":
		process.env.REACT_APP_WALLET_DISCOVERY_ENDPOINT_V2,
	"discovery.authn.include": ACCESS_API.includes("mainnet")
		? ["0xead892083b3e2c6c"]
		: ["0x82ec283f88a62e65"],
	"discovery.wallet": process.env.REACT_APP_WALLET_DISCOVERY_V2,
	"fcl.accountProof.resolver": accountProofDataResolver,
	"fcl.appDomainTag": "flowty-V0.0-user",
	"walletconnect.projectId": process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
}

config(baseConfig)

export const resetConfig = (): void => {
	Log("resetting config...")
	config(baseConfig)
}

const builder = new FlowtyBuilder()
	.withNetwork(NETWORK)
	.withApiURL(apiURL)
	.withCrescendo(IS_CRESCENDO)
export const flowty = builder.build()

if (process.env.REACT_APP_FLOWTY_DEBUG === "true") {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.flowty = flowty
}
