import { ReactComponent as WarningIcon } from "../../../assets/warningIcon.svg"

interface WarningComponentProps {
	text: string
}
export const WarningComponent: React.FC<WarningComponentProps> = ({ text }) => {
	return (
		<div className='flex flex-row items-center bg-[#edf3f60a] p-[8px] 3xl:p-[12px] gap-[8px] text-[#FF6E25] font-normal rounded-lg'>
			<WarningIcon className='h-[1.125rem] w-[20px] fill-[#FF6E25]' />
			<div className='w-full'>
				<p className='text-xs 3xl:text-base leading-3'>{text}</p>
			</div>
		</div>
	)
}
