import { ReactComponent as TxFailIcon } from "../../../assets/media/loanInfo/txFailIcon.svg"
import { FlowtyButton } from "ds-flowty"

interface Props {
	errorMsg: string
	onTryAgainClick?: () => void
}

const TxNotSuccessful = ({ errorMsg, onTryAgainClick }: Props) => (
	<div className='flex flex-col justify-center items-center'>
		<TxFailIcon />

		<h5 className='mt-4 text-white'>Transaction was not successful</h5>
		<p className='text-lightText text-center px-4'>{errorMsg}</p>

		<div className=' flex justify-between mt-5'>
			<FlowtyButton
				variant='primary'
				onClick={onTryAgainClick}
				text={"Try again"}
			/>
		</div>
	</div>
)

export default TxNotSuccessful
