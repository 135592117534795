import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { DropPagePreview } from "../../common/DropPagePreview"
import { CustomizeCollectionPreviewStep } from "../CustomizeCollectionPreviewStep/CustomizeCollectionPreviewStep"

export const ConfirmationPreviewStep: React.FunctionComponent = () => {
	const { activeConfirmationPreviewTab } = useFlowtyCreatorHubContext()
	return (
		<div>
			{activeConfirmationPreviewTab === "Collection Page" && (
				<CustomizeCollectionPreviewStep />
			)}
			{activeConfirmationPreviewTab === "Drop Page" && <DropPagePreview />}
		</div>
	)
}
