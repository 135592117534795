/* eslint-disable @typescript-eslint/no-explicit-any */
import { Err } from "./Log"

interface ErrorMessagesTemplate {
	flowtyError: string
	uiError: string
}

// TODO: some kind of registry of errors that can be ignored, this will likely not be the only one
export const invariantFramesError = "Attempt at triggering multiple Frames"
export const rpcErrUnexpected = 'rpc error:"... is not valid JSON'

export enum FlowtyContractErrors {
	CommissionNotSetUp = "Given recipient has not authorised to receive the commission",
	MissingNFT = "Collateral is no longer in Borrower's account; This listing should be removed from Marketplace shortly.",
	InsufficientFunds = "Account has insufficient funds for selected action.",

	Fund1 = "The selected listing is in cooldown and will be eligible for funding within five minutes.",
	Fund2 = "The selected listing has already been funded.",
	Fund3 = "The selected listing does not exist or has already funded",

	Repay1 = "The loan has already reached maturity and is no longer eligible for repayment.",
	Repay2 = "The loan has already been repaid.",

	Profile = "Usernames can be a maximum of 15 characters.",

	RejectTx = "User rejected signature.",

	SessionExpired = "Session expired for the current user. Please log out and connect your account again.",

	DefaultError = "Undefined error; please retry. If error persists, please file a support ticket via Discord.",

	InsufficientFlowBalance = "Account has insufficient FLOW balance to process transaction. Please add FLOW to your account and retry. If you need further assistance, please file a support ticket in Discord.",

	UnableToFollowTransactionResult = "Uh oh! Something went wrong while trying to follow your transaction results",
}

export const uiErrorTemplates: ErrorMessagesTemplate[] = [
	{
		flowtyError: "missing NFT",
		uiError: FlowtyContractErrors.MissingNFT,
	},
	{
		flowtyError:
			"Amount withdrawn must be less than or equal than the balance of the Vault",
		uiError: FlowtyContractErrors.InsufficientFunds,
	},

	{
		flowtyError: "Funding is not enabled",
		uiError: FlowtyContractErrors.Fund1,
	},

	{
		flowtyError: "listing has already been funded",
		uiError: FlowtyContractErrors.Fund2,
	},

	{
		flowtyError: "the loan has expired",
		uiError: FlowtyContractErrors.Repay1,
	},
	{
		flowtyError: "funding has already been repaid",
		uiError: FlowtyContractErrors.Repay2,
	},

	{
		flowtyError: "User names must be under 15 characters long",
		uiError: FlowtyContractErrors.Profile,
	},

	{
		flowtyError: "Declined: User rejected signature",
		uiError: FlowtyContractErrors.RejectTx,
	},

	{
		flowtyError:
			"Declined: Session expired. Please log out and log back in again",
		uiError: FlowtyContractErrors.SessionExpired,
	},
	{
		flowtyError:
			"No Listing with that ID in FlowtyStorefront. Already funded or delisted.",
		uiError: FlowtyContractErrors.Fund3,
	},
	{
		flowtyError:
			"Capacity can be increased by adding FLOW tokens to the account",
		uiError: FlowtyContractErrors.InsufficientFlowBalance,
	},
	{
		flowtyError: "Given recipient has not authorised to receive the commission",
		uiError: FlowtyContractErrors.CommissionNotSetUp,
	},
]

const ERR_FLOW_PANIC = "panic:"
const ERR_FLOW_PRECONDITION = "pre-condition failed:"

export const getUserFriendlyErrorMessage = (error: any): string => {
	Err("getUserFriendlyErrorMessage ", error)

	const existingErrorTemplate = uiErrorTemplates.find(
		(uiErrorTemplate: ErrorMessagesTemplate) =>
			error.toString().includes(uiErrorTemplate.flowtyError)
	)

	if (existingErrorTemplate) {
		return existingErrorTemplate.uiError
	} else if (typeof error == "string") {
		if (error.includes(ERR_FLOW_PANIC)) {
			return error
				.slice(
					error.indexOf(ERR_FLOW_PANIC) + ERR_FLOW_PANIC.length,
					error.indexOf("-->")
				)
				.trim()
		} else if (error.includes(ERR_FLOW_PRECONDITION)) {
			return error
				.slice(
					error.indexOf(ERR_FLOW_PRECONDITION) + ERR_FLOW_PRECONDITION.length,
					error.indexOf("-->")
				)
				.trim()
		}
	}
	return FlowtyContractErrors.DefaultError
}
