export function calculateTimeAgo(timestamp: number): string {
	const now = Date.now()
	const diff = now - timestamp

	if (diff < 60 * 1000) {
		return "Just now"
	} else if (diff > 60 * 1000 && diff < 120 * 1000) {
		return "1 min ago"
	} else if (diff < 60 * 60 * 1000) {
		return `${Math.floor(diff / (60 * 1000))} mins ago`
	} else if (diff > 60 * 60 * 1000 && diff < 2 * 60 * 60 * 1000) {
		return "1 hour ago"
	} else if (diff < 24 * 60 * 60 * 1000) {
		return `${Math.floor(diff / (60 * 60 * 1000))} hours ago`
	} else if (diff > 24 * 60 * 60 * 1000 && diff < 2 * 24 * 60 * 60 * 1000) {
		return "1 day ago"
	} else if (diff < 7 * 24 * 60 * 60 * 1000) {
		return `${Math.floor(diff / (24 * 60 * 60 * 1000))} days ago`
	} else {
		const date = new Date(timestamp)
		return date.toLocaleDateString()
	}
}
