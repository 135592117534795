import React from "react"

export const OfferNotes: React.FunctionComponent = () => {
	return (
		<div className='mt-auto flex flex-col gap-[0.75rem] justify-end items-center self-stretch'>
			<div className='text-white text-[14px] font-montserrat font-[400] flex flex-col gap-3'>
				Notes:
				<ul className='list-disc list-inside'>
					<li className='leading-normal'>
						Cancel your offers anytime from your profile
					</li>
					<li className='leading-normal'>
						To ensure a successful transaction, please maintain a sufficient
						token balance in your account
					</li>
				</ul>
			</div>
		</div>
	)
}
