import { OfferCreated, SpotPrice, SupportedTokens } from "flowty-common"
import { ModalWarning } from "../../common/ModalWarning/ModalWarning"
import { NftPrice } from "../../common/NftPrice"

interface OfferCancelSectionProps {
	spotPrice?: SpotPrice
	offerData?: OfferCreated | null
	isOrderListedByLoggedAccount: boolean
	nftOwnerAddress: string
}

export const OfferCancelSection = ({
	spotPrice,
	offerData,
	isOrderListedByLoggedAccount,
	nftOwnerAddress,
}: OfferCancelSectionProps) => {
	return (
		<div className='w-full h-full flex flex-col justify-between gap-6'>
			<div className='flex justify-between'>
				<span className='text-white font-montserrat'>Offer Price</span>
				<NftPrice
					price={offerData?.amount || 0}
					token={offerData?.paymentTokenName as SupportedTokens}
					spotPriceValue={Number(spotPrice?.value) * (offerData?.amount || 0)}
				/>
			</div>
			<div className='flex justify-between'>
				<span className='text-white font-montserrat'>To</span>
				<a
					target='_blank'
					rel='noreferrer'
					href={`/user/${nftOwnerAddress}`}
					className='text-eletric-green-900 font-montserrat font-bold underline'
				>
					{nftOwnerAddress}
				</a>
			</div>
			<div className='mt-auto flex flex-col gap-6'>
				{!isOrderListedByLoggedAccount && (
					<ModalWarning
						text={`Offer was made while connected with
					${offerData?.flowtyStorefrontAddress} as Main. Please sign in
					with ${offerData?.flowtyStorefrontAddress} to cancel Offer.`}
					/>
				)}
			</div>
		</div>
	)
}
