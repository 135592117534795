import { SyntheticEvent, useEffect, useState } from "react"
import { getCollectionImage } from "../../../util/getCollectionImage"

interface FallbackImageProps {
	collectionAddress: string
	collectionName: string
	onError: (evt: SyntheticEvent<HTMLImageElement, Event>) => void
	className: string
}

export const FallbackImage = ({
	collectionAddress,
	collectionName,
	onError,
	className,
}: FallbackImageProps): JSX.Element => {
	const [collectionUrl, setCollectionUrl] = useState("")

	useEffect(() => {
		getCollectionImage({
			collectionAddress,
			collectionName,
		}).then(res => {
			setCollectionUrl(res)
		})
	}, [])

	return (
		<img
			src={collectionUrl}
			onError={onError}
			alt={`${collectionUrl} asset`}
			className={className}
		/>
	)
}
