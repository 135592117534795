import { TokenSummary } from "flowty-sdk"
import numeral from "numeral"
import { FlowtyTokenIcon } from "../../FlowtyTokenIcon"
import { cn } from "../../utils/cn"

interface LinkedAccountBalanceProps {
	flowTokenSummary: TokenSummary
	usdcTokenSummary: TokenSummary
	isEditing?: boolean
}

export const LinkedAccountBalance: React.FunctionComponent<
	LinkedAccountBalanceProps
> = ({ flowTokenSummary, usdcTokenSummary, isEditing }) => {
	return (
		<div
			className={cn(
				`flex flex-col items-start`,
				isEditing && "blur-[1px] opacity-30"
			)}
		>
			{flowTokenSummary?.balance && (
				<div className={` flex flex-row items-center`}>
					<span>
						<FlowtyTokenIcon token='FLOW' isLarge />
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						{numeral(flowTokenSummary.balance).format("0,0.00")}
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						FLOW
					</span>
				</div>
			)}
			{usdcTokenSummary?.balance && (
				<div
					className={`${
						isEditing ? "opacity-50" : ""
					}  flex flex-row items-center mt-3`}
				>
					<span>
						<FlowtyTokenIcon token='USDC' isLarge />
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						{numeral(usdcTokenSummary.balance).format("0,0.00")}
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						USDC
					</span>
				</div>
			)}
		</div>
	)
}
