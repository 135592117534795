import { ReactElement, useId } from "react"
import { Tooltip } from "react-tooltip"

interface Props {
	Comp: ReactElement
	tooltipText: string
	tooltipId: string
}

export const TooltipElement: React.FunctionComponent<Props> = ({
	Comp,
	tooltipText,
	tooltipId,
}) => {
	const id = useId()
	const uniqueTooltipId = `${tooltipId}-${id}`

	return (
		<div className='inline'>
			{tooltipText && tooltipText !== "" ? (
				<>
					<div className='absolute z-10'>
						<Tooltip id={uniqueTooltipId} />
					</div>
					<div
						data-tooltip-content={tooltipText}
						data-tooltip-id={uniqueTooltipId}
					>
						{Comp}
					</div>
				</>
			) : (
				<div>{Comp}</div>
			)}
		</div>
	)
}
