import { IEventType } from "flowty-common"
import {
	FilterFormValues,
	LoanFilters,
	RentFilters,
	StorefrontFilters,
} from "../components/HistoryTab/types/FilterFormValues"

export const checkValuesFilter = (
	values: FilterFormValues
): "ALL" | "STOREFRONT" | "LOAN" | "RENTAL" => {
	if (values.storefront && !values.loan && !values.rent) {
		return "STOREFRONT"
	}
	if (values.loan && !values.storefront && !values.rent) {
		return "LOAN"
	}
	if (values.rent && !values.storefront && !values.loan) {
		return "RENTAL"
	}
	return "ALL"
}

export const getCurrentStorefrontFilter = (
	values: StorefrontFilters
): IEventType[] => {
	const selectedValues: Set<IEventType> = new Set()
	selectedValues.add(IEventType.StorefrontListed)
	selectedValues.add(IEventType.StorefrontPurchased)
	selectedValues.add(IEventType.StorefrontOfferCreated)
	selectedValues.add(IEventType.StorefrontOfferCancelled)
	selectedValues.add(IEventType.StorefrontOfferAccepted)

	if (!values.purchased) {
		selectedValues.delete(IEventType.StorefrontPurchased)
	}
	if (!values.listed) {
		selectedValues.delete(IEventType.StorefrontListed)
	}
	if (!values.offerCreated) {
		selectedValues.delete(IEventType.StorefrontOfferCreated)
	}
	if (!values.offerCancelled) {
		selectedValues.delete(IEventType.StorefrontOfferCancelled)
	}
	if (!values.offerAccepted) {
		selectedValues.delete(IEventType.StorefrontOfferAccepted)
	}

	return Array.from(selectedValues)
}

export const getCurrentLoanFilter = (values: LoanFilters): IEventType[] => {
	const selectedValues: Set<IEventType> = new Set()
	selectedValues.add(IEventType.Listed)
	selectedValues.add(IEventType.Funded)
	selectedValues.add(IEventType.Repaid)
	selectedValues.add(IEventType.Settled)

	if (!values.listed) {
		selectedValues.delete(IEventType.Listed)
	}
	if (!values.funded) {
		selectedValues.delete(IEventType.Funded)
	}
	if (!values.repaid) {
		selectedValues.delete(IEventType.Repaid)
	}
	if (!values.defaulted) {
		selectedValues.delete(IEventType.Settled)
	}

	return Array.from(selectedValues)
}

export const getCurrentRentFilter = (values: RentFilters): IEventType[] => {
	const selectedValues: Set<IEventType> = new Set()
	selectedValues.add(IEventType.RentalListed)
	selectedValues.add(IEventType.RentalRented)
	selectedValues.add(IEventType.RentalReturned)
	selectedValues.add(IEventType.RentalSettled)

	if (!values.listed) {
		selectedValues.delete(IEventType.RentalListed)
	}
	if (!values.returned) {
		selectedValues.delete(IEventType.RentalReturned)
	}
	if (!values.unreturned) {
		selectedValues.delete(IEventType.RentalSettled)
	}
	if (!values.rented) {
		selectedValues.delete(IEventType.RentalRented)
	}

	return Array.from(selectedValues)
}
