export const TopShotThumbnail =
	"https://storage.googleapis.com/flowty-images/topshot.png"
export const TopShotSquare =
	"https://storage.googleapis.com/flowty-images/TopShot/512x512.png"
export const TopShotBanner =
	"https://storage.googleapis.com/flowty-images/TopShot/1400x350.png"

export const AllDayThumbnail =
	"https://storage.googleapis.com/flowty-images/AllDay/350x350.jpg"
export const AllDayBanner =
	"https://storage.googleapis.com/flowty-images/AllDay/1400x350.jpg"

export const StrikeThumbnail =
	"https://storage.googleapis.com/flowty-images/Strike/350x350.png"
export const StrikeBanner =
	"https://storage.googleapis.com/flowty-images/Strike/1400x350.png"

export const LaLigaBanner =
	"https://storage.googleapis.com/flowty-images/LaLiga/1400x350.png"
export const LaLigaThumbnail =
	"https://storage.googleapis.com/flowty-images/LaLiga/350x350.png"

export const DefaultBanner = "https://i.ibb.co/QQrdypK/fall-Back-Banner.png"
export const DefaultThumbnail =
	"https://i.ibb.co/N3Trjct/new-fallback-image.png"

export const getDefaultImage = (seed: string): string => {
	return `https://api-ufj4afzoca-uc.a.run.app/6.x/thumbs/png?seed=${seed}`
}
