import axios from "axios"
import { apiURL } from "../util/settings"
import { auth } from "../firebase"

export const uploadIPFSImage = async (
	image: File
): Promise<{ cid: string }> => {
	const authToken = (await auth?.currentUser?.getIdToken(true)) ?? ""

	const formData = new FormData()
	formData.append("file", image)
	const response = await axios.post<{ cid: string }>(
		`${apiURL}/launch-pad/upload-image`,
		formData,
		{
			headers: {
				Authorization: authToken,
				"Content-Type": "multipart/form-data",
			},
		}
	)
	return response.data
}
