import { ReactComponent as ToggleOn } from "../assets/State=On.svg"
import { ReactComponent as ToggleOff } from "../assets/State=Off.svg"
import { useField } from "formik"

interface FlowtyToggleProps {
	label?: string | React.ReactNode
	name: string
	loading?: boolean
}

export const FlowtyToggle: React.FunctionComponent<FlowtyToggleProps> = ({
	label,
	name,
	loading,
}) => {
	const [field, , helpers] = useField(name)

	const toggled = field.value

	const handleClick = () => {
		helpers.setValue(!field.value)
	}

	return (
		<div className='flex justify-between items-center'>
			{label && <span>{label}</span>}{" "}
			<button
				type='button'
				className='h-8 flex rounded-full outline-none'
				onClick={handleClick}
			>
				<div className='inset-0 flex justify-center items-center'>
					<div
						className={`flex justify-center items-center transition-opacity duration-1000 ease-in-out ${
							!loading ? "opacity-100" : "opacity-0"
						}`}
					>
						{toggled ? <ToggleOn /> : <ToggleOff />}
					</div>

					<div
						className={`absolute flex justify-center items-center transition-opacity duration-1000 ease-in-out ${
							loading ? "opacity-100" : "opacity-0"
						}`}
					>
						<div
							className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
							role='status'
							aria-label='loading'
						/>
					</div>
				</div>
			</button>
		</div>
	)
}
