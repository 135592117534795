import React, { useEffect, useState } from "react"
import { Filters, MinMaxFilter } from "../index"

interface FilterBy {
	path: string | null
	min: number
	max: number
}

interface BorrowerFilterProps {
	showFilter: boolean
	setShowFilter: (showFilter: boolean) => void
	filterBy: FilterBy
	setFilterBy: (filterBy: FilterBy) => void
}

export const BorrowerFilter: React.FunctionComponent<BorrowerFilterProps> = ({
	showFilter,
	setShowFilter,
	filterBy,
	setFilterBy,
}) => {
	const [status, setStatus] = useState<string>("")

	const [startDate, setStartDate] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [dueDate, setDueDate] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [amount, setAmount] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [rate, setRate] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [apr, setApr] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})
	const [repayment, setRepayment] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})

	const FilterFields = [
		{
			path: "state",
			selectField: true,
			setValue: setStatus,
			title: "Status",
			value: status,
		},
		{
			dateField: true,
			path: "blockTimestamp",
			setValue: setStartDate,
			title: "Start Date",
			value: startDate,
		},
		{
			dateField: true,
			path: "settleDeadline",
			setValue: setDueDate,
			title: "Due Date",
			value: dueDate,
		},
		{
			path: "amount",
			setValue: setAmount,
			title: "Amount",
			value: amount,
		},
		{
			path: "interestRate",
			setValue: setRate,
			title: "Rate %",
			value: rate,
		},
		{
			path: "derivations.apr",
			setValue: setApr,
			title: "APR %",
			value: apr,
		},
		{
			path: "derivations.calculatedValues.totalRepayment",
			setValue: setRepayment,
			title: "Repayment Amount",
			value: repayment,
		},
	]

	const setAllFieldsNull = () => {
		setStatus("")
		setStartDate({ max: "", min: "" })
		setDueDate({ max: "", min: "" })
		setAmount({ max: "", min: "" })
		setRate({ max: "", min: "" })
		setApr({ max: "", min: "" })
		setRepayment({ max: "", min: "" })
	}

	useEffect(() => {
		if (filterBy.path && !filterBy.min && !filterBy.max) {
			setFilterBy({ ...filterBy, path: "" })
		}
	}, [filterBy])

	const filterOptions = [
		{ label: "Listed", value: "LISTED" },
		{ label: "Delisted", value: "DELISTED" },
		{ label: "Funded", value: "FUNDED" },
		{ label: "Repaid", value: "REPAID" },
		{ label: "Settled", value: "SETTLED" },
	]

	const tableName = "Borrower Table"

	return (
		<Filters
			showFilter={showFilter}
			setShowFilter={setShowFilter}
			FilterFields={FilterFields}
			filterOptions={filterOptions}
			setAllFieldsNull={setAllFieldsNull}
			filterBy={filterBy}
			setFilterBy={setFilterBy}
			tableName={tableName}
		/>
	)
}
