import { cn } from "../../../util/cn"

interface RowProps {
	children: React.ReactNode | React.ReactNode[]
	className?: string
}

export const Row: React.FunctionComponent<RowProps> = ({
	children,
	className,
}) => {
	return <div className={cn("flex flex-wrap", className)}>{children}</div>
}
