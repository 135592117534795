import React from "react"
import { ReactComponent as FlameEmoji } from "../../assets/flame.svg"

interface OngoingPromotionButtonProps {
	onClick?: () => void
}

export const OngoingPromotionButton: React.FC<OngoingPromotionButtonProps> = ({
	onClick,
}) => {
	return (
		<button
			onClick={onClick}
			className='w-fit cursor-pointer flex items-center gap-[13.398px] rounded-[4.466px] bg-[#FFFFFF0A] border-[1.117px] border-[#FF9E3F] p-[6.699px_13.398px]'
		>
			<FlameEmoji className='w-[22.693px] h-[24px]' />{" "}
			<div className='font-montserrat text-[13.398px] font-semibold leading-[17.864px]'>
				<span
					style={{
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
						background: "linear-gradient(90deg, #FF9E3F 0%, #D24E65 100%)",
						backgroundClip: "text",
					}}
				>
					20K FLOW Giveaway
				</span>
			</div>
		</button>
	)
}
