import { Field } from "formik"
import { useEffect } from "react"
import { useFlowtyNumericInput } from "./useFlowtyNumericInput"

interface FlowtyNumericInputProps {
	name: string
	inputError?: boolean
	valueError?: boolean
	placeholder?: string
	textLeft?: boolean
	disabled?: boolean
	validateFn?: (value: string | number) => void
	errorMessage?: string | null
	decimalsAllowed?: boolean
	inputErrorRef?: React.RefObject<HTMLDivElement>
	dataTestId?: string
	className?: string
	value?: string | number | readonly string[] | undefined
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onFocus?: () => void
	min?: number
}

export const FlowtyNumericInput: React.FunctionComponent<
	FlowtyNumericInputProps
> = ({
	name,
	inputError = false,
	valueError = false,
	placeholder,
	textLeft,
	disabled,
	validateFn,
	errorMessage,
	decimalsAllowed = true,
	inputErrorRef,
	dataTestId,
	className,
	value,
	onChange,
	onFocus,
	min = 0,
}) => {
	useFlowtyNumericInput()

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === "ArrowUp" || e.key === "ArrowDown") {
			e.preventDefault()
		}
		if (!decimalsAllowed && (e.key === "." || e.key === ",")) {
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (valueError) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
			return
		}
	}, [valueError])

	const renderClassName =
		className ??
		`h-[37px] w-full text-[14px] bg-[#606E7D33] border font-semibold justify-end rounded-md px-3 hover:bg-[#606E7D4D] focus:border-[#FFFFFF7A] focus:outline-none ${
			inputError ? "border-red-500 focus:border-red-500" : "border-[#FFFFFF1F]"
		}
                ${valueError ? "text-[#FF6E25]" : "text-white"}
                ${textLeft ? "text-left" : "text-right"}
                ${
									disabled ? "cursor-not-allowed border-none" : "cursor-pointer"
								} 
                `

	return (
		<div className='w-full relative'>
			<Field
				data-testid={dataTestId ?? name}
				disabled={disabled}
				className={renderClassName}
				placeholder={placeholder}
				name={name}
				type='number'
				min={min}
				validate={validateFn}
				onKeyDown={handleKeyDown}
				onFocus={onFocus}
				{...(value && { value })}
				{...(onChange && { onChange })}
			/>
			<p className='w-full text-end text-[#FF6E25] text-xs leading-[15px] absolute whitespace-nowrap'>
				{errorMessage}
			</p>
		</div>
	)
}
