/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import SortableTable from "../../../SortableTable"

import { solveWithAmountAndInterest } from "flowty-common"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { composeLoanListingPath } from "../../../../routes"
import {
	subscribeToMyBorrows,
	subscribeToMyListedOrDelistBorrow,
} from "../../../../services/firestore/ActivityService"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { defaultNftPageSize } from "../../../../util/settings"
import { ScrollToLoadMsg } from "../../../Loaders/ScrollToLoadMsg"
import { BorrowerFilter } from "../../Filters/BorrowerFilter"
import { userBorrowerTableFields } from "./UserBorrowerTableFields"

const UserBorrowerTableComponent: React.FunctionComponent<AuthStoreProp> = ({
	authStore,
}) => {
	const [prevLength, setPrevLength] = useState(0)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [showFilter, setShowFilter] = useState(true)
	const [filterBy, setFilterBy] = useState<any>({
		max: "",
		min: "",
		path: "",
	})
	const [userBorrowerDocs, setUserBorrowerDocs] = useState<Array<any>>([])

	const [allDeListedActivityDocs, setAllDeListedActivityDocs] = useState<
		Array<any>
	>([])

	const [allListedActivityDocs, setAllListedActivityDocs] = useState<
		Array<any>
	>([])

	const navigate = useNavigate()

	const listingLimit = useMemo(() => page * defaultNftPageSize, [page])
	const userBorrowerDocsLength = useMemo(
		() => userBorrowerDocs.length,
		[userBorrowerDocs]
	)

	const allBorrowerItems = useMemo(
		() =>
			userBorrowerDocs
				.concat(allDeListedActivityDocs)
				.concat(allListedActivityDocs),
		[userBorrowerDocs, allListedActivityDocs]
	)

	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: loading,
			onInfiniteScroll() {
				if (!loading) {
					setPage(page + 1)
					setPrevLength(userBorrowerDocsLength)
				}
				Mixpanel.track("Inifinite Scroll Loans Borrower Table")
			},
		},
		[userBorrowerDocsLength]
	)
	useEffect((): any => {
		if (prevLength === userBorrowerDocsLength && userBorrowerDocsLength) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [prevLength, userBorrowerDocsLength])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Profile Borrower Loans Page Filtered`, {
				filter: filterBy,
				user: authStore?.loggedUser,
			})
		}
	}, [filterBy.path])

	useEffect(() => {
		if (authStore?.loggedUser?.addr) {
			subscribeToMyBorrows({
				accountId: authStore?.loggedUser?.addr,
				cb(docs) {
					// Set borrower data here ...

					const calculatedDocs = docs.map(doc => ({
						...doc,
						calculatedValues: {
							...solveWithAmountAndInterest(
								{
									amount: doc?.listingAvailable?.amount
										? Number(doc.listingAvailable?.amount)
										: 0,
									interest: doc?.listingAvailable?.interestRate
										? Number(doc.listingAvailable?.interestRate)
										: 0,
									term: doc?.listingAvailable?.term
										? Number(doc.listingAvailable?.term)
										: 0,
								},
								doc?.listingAvailable?.royaltyRate || 0
							),
						},
					}))

					setUserBorrowerDocs(calculatedDocs)
				},
				filterBy,
				limit: listingLimit,
			})

			subscribeToMyListedOrDelistBorrow({
				accountIDs: authStore?.getAccountSummaryAddresses() || [],
				accountId: authStore?.loggedUser?.addr,
				cb(docs) {
					setAllDeListedActivityDocs(docs)
				},
				filterBy,
				limit: listingLimit,
				stateType: "DELISTED",
			})

			subscribeToMyListedOrDelistBorrow({
				accountIDs: authStore?.getAccountSummaryAddresses() || [],
				accountId: authStore?.loggedUser?.addr,
				cb(docs) {
					setAllListedActivityDocs(docs)
				},
				filterBy,
				limit: listingLimit,
				stateType: "LISTED",
			})

			return
		}
		return () => {}
	}, [listingLimit, authStore?.loggedUser, filterBy])

	useEffect(() => {
		setPage(1)
	}, [filterBy])

	return (
		<div className=' w-full flex flex-col '>
			<BorrowerFilter
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={setFilterBy}
			/>
			<button
				className='flex justify-center w-20 relative self-end -top-24 float-right bg-blue-500 text-white active:bg-blue-600 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-4 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
				type='button'
				onClick={() => {
					setShowFilter(!showFilter)
				}}
			>
				<span>Filter</span>
			</button>
			<div className='relative -top-16 '>
				<SortableTable
					onClickRow={({ item }) =>
						item?.listingResourceID &&
						navigate(composeLoanListingPath(item.listingResourceID))
					}
					fields={userBorrowerTableFields(navigate)}
					items={allBorrowerItems}
					stickyHeaders
					composeRowHref={item =>
						composeLoanListingPath(item.listingResourceID)
					}
				/>
			</div>
			{loading ? (
				<div />
			) : (
				<>
					<ScrollToLoadMsg />
					<div ref={bottomOfPageRef} />
				</>
			)}
		</div>
	)
}

export const UserBorrowerTable = inject("authStore")(
	observer(UserBorrowerTableComponent)
)
