import { OpensearchFlowNFT } from "flowty-common"
import { NftLoadingCard } from "../../Shared/NftCard/NftLoadingCard"

export const NftListLoading: React.FunctionComponent = () => {
	return (
		<div
			className={`w-full h-full grid pb-4 gap-4`}
			style={{
				gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
			}}
		>
			{Array.from({ length: 16 }).map((_, index) => {
				return (
					<NftLoadingCard
						nft={{} as OpensearchFlowNFT}
						key={index}
						isLoading={true}
					/>
				)
			})}
		</div>
	)
}
