import { ChevronRightIcon } from "@heroicons/react/solid"
import {
	ListingRentedData,
	ListingStatus,
	RentalListingAvailableData,
	getImageURL,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { ReactComponent as ExternalLink } from "../assets/media/external-link.svg"
import noImage from "../assets/media/image_not_available.svg"
import { Alert } from "../components/Alert/Alert"
import { Col } from "../components/GridLayout/Col/Col"
import { Row } from "../components/GridLayout/Row/Row"
import { NftActiveCountdown } from "../components/LandingPage/NftActiveCountdown"
import { PrivateListing } from "../components/LandingPage/PrivateListing"
import RentalListingInfo from "../components/MarketPlace/Listing/RentalListing/RentalListingInfo"
import { Metadata } from "../components/Nfts/Metadata"
import { NftRefreshButton } from "../components/Nfts/NftRefreshButton"
import NftRepayExpiration from "../components/Nfts/NftRepayExpiration"
import NftStatePlaceholder from "../components/Nfts/NftStatePlaceholder"
import { RoutePaths } from "../routes"
import {
	fetchRentalListingById,
	getListingRentedDataByListingId,
} from "../services/firestore/MarketplaceService"
import { AuthStoreProp } from "../stores/AuthStore"
import { Log } from "../util/Log"
import { actions as Mixpanel } from "../util/Mixpanel"
import { guessAssetExtension } from "../util/guessAssetExtension"
import FallbackImage from "../components/Shared/FallbackImage"
import { addFallbackImage } from "../util/images"
import { isNftActive, isRepayedPeriodExpired } from "../util/nftDataUtil"
import Layout from "../layouts/Layout"
import LayoutLoader from "./loaders/LayoutLoader"
import { flowty } from "../config/config"

const RentalListingInfoScreenComponent: React.FC<AuthStoreProp> = ({
	authStore,
}) => {
	const { listingResourceId } = useParams()
	const accounts = authStore?.getAccountSummaryAddresses() || []
	const listingResourceIdNum = listingResourceId ?? ""
	const [backlink, setBacklink] = useState<string | null>(null)
	const [listing, setListing] = useState<RentalListingAvailableData | null>(
		null
	)
	const [funding, setFunding] = useState<ListingRentedData | null>(null)

	const [didImageLoadFail, setDidImageLoadFail] = useState(false)

	const isHistory =
		listing?.state === ListingStatus.Delisted ||
		listing?.state === ListingStatus.Repaid ||
		listing?.state === ListingStatus.Settled ||
		listing?.state === ListingStatus.Invalid ||
		listing?.state === ListingStatus.Rented ||
		listing?.state === ListingStatus.Returned ||
		listing?.state === ListingStatus.Expired

	// isListingActive - it's true if the first 5 minutes after the listing are expired
	const [isListingActive, setIsListingActive] = useState(
		listing ? isNftActive(listing?.blockTimestamp) : false
	)
	// isListingExpired - it's true if the listing is already settled
	const [isListingExpired, setIsListingExpired] = useState(
		funding?.settleDeadline
			? isRepayedPeriodExpired(funding?.settleDeadline)
			: false
	)

	const getFunding = async (): Promise<void> => {
		const item = await getListingRentedDataByListingId(listingResourceIdNum)
		setFunding(item)
	}
	const getListing = async (): Promise<void> => {
		const item = await fetchRentalListingById(listingResourceIdNum)
		setListing(item)
	}

	useEffect(() => {
		getFunding().then(() => {
			Log("getFunding", funding)
		})
		getListing().then(() => {
			Log("set listing to", listing)
		})
	}, [listingResourceId])

	useEffect(() => {
		const blockTimestamp = listing?.blockTimestamp
		if (blockTimestamp) {
			setIsListingActive(isNftActive(blockTimestamp))
		}
	}, [listing?.blockTimestamp])

	useEffect(() => {
		const blockTimestamp = funding?.settleDeadline

		if (blockTimestamp) {
			setIsListingExpired(isRepayedPeriodExpired(blockTimestamp))
		}
	}, [funding?.settleDeadline])

	useEffect(() => {
		if (!listing) {
			return
		}

		const ld = nftTypeAndIdToLocationData(listing?.nftType, listing?.nftID)
		flowty.api
			.getItem({
				contractAddress: ld.contract.address,
				contractName: ld.contract.name,
				nftID: ld.nftID,
				resourceName: ld.resourceName,
			})
			.then(nft => {
				const url = nft.nftView?.externalURL?.url ?? ""
				setBacklink(url)
				if (!url) {
					Log("No backlink URL")
				}
			})
	}, [listing])

	const renderActiveStatus = () => {
		if (!isListingActive && listing?.blockTimestamp) {
			return (
				<NftActiveCountdown
					blockTimestamp={listing?.blockTimestamp}
					onCountdownEnd={() => setIsListingActive(true)}
					className='w-3/4'
				/>
			)
		}

		if (isListingExpired) {
			return (
				<div className='nft-image-alert w-full'>
					<div className='pt-1 flex justify-center items-center'>
						<Alert
							variant='info'
							className='pt-2 pb-2 mt-5 absolute ps-3 pe-3 text-bold'
						>
							Expired
						</Alert>
					</div>
				</div>
			)
		}

		return null
	}

	useEffect(() => {
		if (listing?.detail) {
			const listingInfo = listing.detail
			Mixpanel.track("Asset Detail Page Visited", { listingInfo })
		}
	}, [listing?.detail])

	const isLoading = listing === null

	const [isMp4, setIsMp4] = useState<boolean | null>(null)

	const assetUrl = useMemo(() => {
		if (
			listing?.detail?.card?.images &&
			listing.detail.card.images.length > 0
		) {
			return getImageURL(listing.detail.card.images[0].url ?? noImage)
		}
		return getImageURL(noImage)
	}, [listing?.detail?.card?.images, noImage])

	useEffect(() => {
		if (assetUrl && didImageLoadFail) {
			guessAssetExtension(assetUrl, "mp4", { shouldUseContentType: true }).then(
				setIsMp4
			)
		}
	}, [assetUrl, didImageLoadFail])

	const renderImage = useMemo(() => {
		if (!isMp4 && didImageLoadFail) {
			return (
				<FallbackImage
					collectionAddress={listing?.detail?.card?.collectionAddress as string}
					collectionName={listing?.detail?.card?.collectionName as string}
					onError={e => {
						addFallbackImage(e)
					}}
					className={`object-cover aspect-square w-3/4 ${
						isHistory ? "opacity-50" : ""
					}`}
				/>
			)
		}

		if (!isMp4) {
			return (
				<img
					src={assetUrl}
					className={`nft-image max-w-xs md:max-w-lg mx-auto ${
						isHistory ? "opacity-50" : ""
					}`}
					loading='lazy'
					alt={`${listing?.detail?.card?.title} asset`}
					onError={() => {
						setDidImageLoadFail(true)
					}}
				/>
			)
		}

		if (isMp4) {
			return (
				// disable since there are no captions with these URLs
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<video
					muted
					loop
					playsInline
					preload='auto'
					autoPlay={true}
					className={`nft-image max-w-xs md:max-w-lg mx-auto ${
						isHistory ? "opacity-50" : ""
					}`}
				>
					<source type='video/mp4' src={assetUrl}></source>
				</video>
			)
		}
	}, [
		assetUrl,
		addFallbackImage,
		isMp4,
		didImageLoadFail,
		isHistory,
		setDidImageLoadFail,
	])

	return (
		<Layout>
			{isLoading ? (
				<LayoutLoader />
			) : (
				<div className='container'>
					<nav aria-label='Breadcrumb'>
						<ol className='flex items-center'>
							<li className='flex items-center'>
								<a
									href={RoutePaths.Marketplace}
									className='hover:underline cursor-pointer'
								>
									Marketplace
								</a>
								<ChevronRightIcon
									className='h-7 w-7 flex-shrink-0 text-primary'
									aria-hidden='true'
								/>
							</li>
							<li>
								<a
									href='/marketplace'
									className='hover:underline cursor-pointer'
								>
									{" "}
									{listing?.detail?.nft?.title || ""}
								</a>
							</li>
						</ol>
					</nav>
					<div className='relative pt-4'>
						<Row className='md:flex-nowrap flex-wrap'>
							<Col lg={4}>
								<div className='flex justify-center nft-image-card'>
									{renderImage}

									{!isListingActive ? (
										renderActiveStatus()
									) : accounts.includes(listing.flowtyStorefrontAddress) ? (
										<div className='z-50 flex justify-center nft-image-alert w-full'>
											<div className='w-3/4 flex justify-center items-center countdown-content rounded-t-xl'>
												<p className='text-white text-bold me-2'>My Listing</p>
											</div>
										</div>
									) : (
										listing.renter && (
											<PrivateListing listing={listing} className='w-3/4' />
										)
									)}
									{isHistory && listing?.state && (
										<NftStatePlaceholder nftState={listing.state} />
									)}
								</div>
								<div
									className={`ms-2 padding-20 w-full flex ${
										backlink ? "justify-between" : "justify-end"
									}`}
								>
									{backlink && (
										<a href={backlink} target='_blank' rel='noreferrer'>
											<p className='inline-block pt-1 mt-3 me-2 text-16'>
												View Asset
											</p>
											<ExternalLink className='inline-block pb-1' />
										</a>
									)}
									<NftRefreshButton
										nftType={listing.nftType}
										nftID={listing.nftID}
									/>
								</div>
								<div className='padding-10'>
									{listing?.detail?.nft?.metadata && listing?.nftType && (
										<Metadata
											metadata={listing.detail.nft.metadata}
											type={listing.nftType}
										/>
									)}
								</div>
							</Col>
							<Col className='mt-5 '>
								<RentalListingInfo
									listing={listing as RentalListingAvailableData}
									isListingActive={isListingActive}
									isListingExpired={isListingExpired}
									setListing={setListing}
								/>
							</Col>
						</Row>
						{funding?.settleDeadline &&
							(listing?.state === ListingStatus.Funded ||
								ListingStatus.Rented) && (
								<NftRepayExpiration
									settleDeadline={funding?.settleDeadline}
									className='mt-5'
									onCountdownEnd={() => setIsListingExpired(true)}
								/>
							)}
					</div>
				</div>
			)}
		</Layout>
	)
}

export const RentalListingInfoScreen = inject("authStore")(
	observer(RentalListingInfoScreenComponent)
)
