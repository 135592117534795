import { OfferCreated, getProviderImage } from "flowty-common"
import React from "react"
import { OFFERS_ENABLED } from "../../../../util/settings"
import { StorefrontFormValues } from "../../../Modals/Listings/Storefront/form/StorefrontListingForm"
import { AbbreviatedTokenAmount } from "../../../Tokens/tokenDisplays"

interface BestStorefrontOffersProps {
	dapperOffer?: OfferCreated | null | StorefrontFormValues
	nonCustodialOffer?: OfferCreated | null | StorefrontFormValues
	isWhite?: boolean
}

export const BestStorefrontOffers: React.FC<BestStorefrontOffersProps> = ({
	dapperOffer,
	nonCustodialOffer,
	isWhite,
}) => (
	<>
		<div className='flex flex-col'>
			<span
				className={`${
					isWhite ? "text-white" : "text-[#AAAAAA] "
				} text-base font-montserrat mb-2 text-right`}
			>
				Best Offer
			</span>
			{(nonCustodialOffer || dapperOffer) && OFFERS_ENABLED ? (
				<div className='flex flex-col'>
					{nonCustodialOffer ? (
						<div className='flex flex-row justify-between text-bold'>
							<div className='bg-[#FFFFFF2B] rounded-md flex items-center justify-center px-1 mx-1'>
								<img
									src={getProviderImage("blocto")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<AbbreviatedTokenAmount
									amount={
										(nonCustodialOffer as OfferCreated)?.amount ||
										(nonCustodialOffer as StorefrontFormValues)?.salePrice ||
										0
									}
									isSmall
									token={
										(nonCustodialOffer as OfferCreated)?.paymentTokenName ||
										(nonCustodialOffer as StorefrontFormValues)?.currencyToken
									}
								/>
							</div>
						</div>
					) : (
						<div className='flex flex-row justify-between text-bold'>
							<div className='bg-[#FFFFFF2B] rounded-md flex items-center justify-center px-1 mx-1'>
								<img
									src={getProviderImage("blocto")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<div className='nft-price flex items-center justify-end small '>
									<div className='text-bold text-base font-montserrat inline-block ms-1 '>
										-
									</div>
								</div>
							</div>
						</div>
					)}
					{dapperOffer ? (
						<div className='flex flex-row justify-between text-bold mt-1'>
							<div className='bg-[#FFFFFF2B] rounded-md flex items-center justify-center px-1 mx-1'>
								<img
									src={getProviderImage("dapper")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<AbbreviatedTokenAmount
									amount={
										(dapperOffer as OfferCreated)?.amount ||
										(dapperOffer as StorefrontFormValues)?.salePrice ||
										0
									}
									isSmall
									token={
										(dapperOffer as OfferCreated)?.paymentTokenName ||
										(dapperOffer as StorefrontFormValues)?.currencyToken
									}
								/>
							</div>
						</div>
					) : (
						<div className='flex flex-row justify-between text-bold mt-1'>
							<div className='bg-[#FFFFFF2B] rounded-md flex items-center justify-center px-1 mx-1'>
								<img
									src={getProviderImage("dapper")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<div className='nft-price flex items-center justify-end small '>
									<div className='text-bold text-base font-montserrat inline-block ms-1 '>
										-
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				<span className='text-right'>-</span>
			)}
		</div>
	</>
)
