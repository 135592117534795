import { FlowtyChildWalletCarousel } from "ds-flowty"
import { IdentifierToPaymentToken } from "flowty-sdk/lib/utils/TokensIdentifier"
import { inject, observer } from "mobx-react"
import { useContext, useMemo } from "react"
import Swal from "sweetalert2"
import { flowty } from "../../../config/config"
import { User } from "../../../models/user"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { OpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { onChangeHandler } from "../../Shared/fileUpload"

interface LinkedAccountsProps extends AuthStoreProp {
	user: User | null
}

const LinkedAccountsComponent: React.FunctionComponent<LinkedAccountsProps> = ({
	user,
	authStore,
}) => {
	const accountSummaries = user?.accountSummaries ?? {}
	const { walletAddresses: selectedAddresses, setWalletAddresses } =
		useContext(OpenSearchContext)

	const updateSelectedAddresses = (address: string): void => {
		let selected = [...selectedAddresses]
		if (selected.includes(address)) {
			selected = selected.filter(selectedAddress => selectedAddress !== address)
		} else {
			selected.push(address)
		}
		setWalletAddresses(selected)
	}

	const selectAll = (): void => {
		setWalletAddresses(Object.keys(accountSummaries))
	}

	const clearAll = (): void => {
		setWalletAddresses([])
	}

	const Toast = Swal.mixin({
		background: "#232D39E6", // Adding E6 at the end of the Hex value makes it 90% opaque
		customClass: {
			container: "py-1 mb-4",
			icon: "text-green-500",
		},
		iconColor: "#04F5A3",
		position: "bottom",
		showConfirmButton: false,
		timer: 3000,
		toast: true,
		width: "400px",
	})

	const saveNewImage = async (
		address: string,
		name: string,
		imageUrl: string
	): Promise<void> => {
		try {
			await flowty
				.editChildAccountDisplay({
					childAddress: address,
					desc: "",
					name,
					thumbnail: imageUrl,
				})
				.then(() => {
					Toast.fire({
						icon: "success",
						text: "Successfully Updated Account Details",
					})
					authStore?.setLinkedWalletImage(address, imageUrl)
					authStore?.setLinkedWalletDisplayName(address, name)
				})
		} catch (e) {
			console.log("errorrr", e)
		}
	}

	const mainAccount = useMemo(() => {
		return user?.accountSummaries[user?.addr ?? ""]
	}, [])

	return (
		<div>
			{user?.accountSummaries &&
				Object.keys(user?.accountSummaries).length > 1 && (
					<FlowtyChildWalletCarousel
						childAccounts={user?.accountSummaries}
						isLoggedUserAcct={user.addr === authStore?.loggedUser?.addr}
						mainAccount={mainAccount}
						tokenIdentifiers={IdentifierToPaymentToken}
						selectedWalletAddresses={selectedAddresses}
						selectAllAddresses={selectAll}
						clearAllAddresses={clearAll}
						updateSelectedAddresses={updateSelectedAddresses}
						onUploadNewImage={onChangeHandler}
						saveNewImage={saveNewImage}
					/>
				)}
		</div>
	)
}

export const LinkedAccounts = inject("authStore")(
	observer(LinkedAccountsComponent)
)
