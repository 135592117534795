import React, { useMemo, useState } from "react"
import { cn } from "../../../util/cn"
import { Accounts } from "./Accounts/Accounts"

export const AccountsFilter: React.FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(true)

	const chevron = useMemo(() => {
		return (
			<svg
				data-testid='chevron'
				onClick={e => {
					e.stopPropagation()
					setIsOpen(!isOpen)
				}}
				className={cn({
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
				})}
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isOpen])

	return (
		<div
			className={cn(
				"rounded-[0.375rem] cursor-pointer border-[1px] border-neutral-400 border-opacity-25  px-[1rem] py-[0.875rem]",
				{
					"hover:bg-[#ffffff29]": !isOpen,
				}
			)}
		>
			<div
				className='flex items-center justify-between w-full'
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className='flex items-center gap-[0.5rem]'>
					<svg
						width='18'
						height='14'
						viewBox='0 0 18 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M7.82609 7.91306V7.0435C7.82609 6.12101 8.19254 5.2363 8.84485 4.584C9.49715 3.9317 10.3819 3.56524 11.3043 3.56524H16.5217V1.82611C16.5217 1.59549 16.4301 1.37431 16.267 1.21123C16.104 1.04816 15.8828 0.956543 15.6522 0.956543H0.869565C0.638942 0.956543 0.417765 1.04816 0.25469 1.21123C0.0916147 1.37431 0 1.59549 0 1.82611L0 13.1305C0 13.3611 0.0916147 13.5823 0.25469 13.7453C0.417765 13.9084 0.638942 14 0.869565 14H15.6522C15.8828 14 16.104 13.9084 16.267 13.7453C16.4301 13.5823 16.5217 13.3611 16.5217 13.1305V11.3913H11.3043C10.3819 11.3913 9.49715 11.0249 8.84485 10.3726C8.19254 9.72027 7.82609 8.83556 7.82609 7.91306Z'
							fill='#CED4DA'
						/>
						<path
							d='M16.5217 5.30432H11.3043C10.8431 5.30432 10.4007 5.48755 10.0746 5.8137C9.74842 6.13985 9.56519 6.58221 9.56519 7.04345V7.91302C9.56519 8.37426 9.74842 8.81662 10.0746 9.14277C10.4007 9.46892 10.8431 9.65215 11.3043 9.65215H16.5217C16.7523 9.65215 16.9735 9.56053 17.1366 9.39746C17.2997 9.23438 17.3913 9.01321 17.3913 8.78258V6.17389C17.3913 5.94326 17.2997 5.72209 17.1366 5.55901C16.9735 5.39594 16.7523 5.30432 16.5217 5.30432ZM12.6087 8.3478C12.378 8.3478 12.1569 8.25618 11.9938 8.09311C11.8307 7.93003 11.7391 7.70886 11.7391 7.47823C11.7391 7.24761 11.8307 7.02643 11.9938 6.86336C12.1569 6.70028 12.378 6.60867 12.6087 6.60867C12.8393 6.60867 13.0605 6.70028 13.2235 6.86336C13.3866 7.02643 13.4782 7.24761 13.4782 7.47823C13.4782 7.70886 13.3866 7.93003 13.2235 8.09311C13.0605 8.25618 12.8393 8.3478 12.6087 8.3478Z'
							fill='#CED4DA'
						/>
					</svg>
					<span className='text-[0.875rem] font-montserrat font-[600]'>
						Wallets & Currencies
					</span>
				</div>
				{chevron}
			</div>
			{isOpen ? (
				<div className='space-y-2 mt-3 overflow-y-auto'>
					<Accounts />
				</div>
			) : null}
		</div>
	)
}
