import { Config } from "../types"

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getFlowtyStorefrontFeeScriptCrescendo = (config: Config): string => {
	return `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

access(all) fun main(identifier: String): UFix64 {
    let c = CompositeType(identifier)!
    return NFTStorefrontV2.getFee(p: UFix64(1), t: c)
}`
}

export const getFlowtyStorefrontFeeScript = (config: Config): string => {
	return _getFlowtyStorefrontFeeScriptCrescendo(config)
}
