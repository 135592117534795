import React from "react"
import {
	GenericActivityFilter,
	ActivityFilterProps,
} from "./GenericActivityFilter"

const loanFilterOptions = [
	{ label: "Settled", value: "SETTLED" },
	{ label: "Funded", value: "FUNDED" },
	{ label: "Repaid", value: "REPAID" },
	{ label: "Listed", value: "LISTED" },
	{ label: "Expired", value: "EXPIRED" },
]

export const LoanActivityFilter: React.FC<ActivityFilterProps> = props => (
	<GenericActivityFilter {...props} filterOptions={loanFilterOptions} />
)
