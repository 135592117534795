import { useCallback, useEffect, useMemo, useState } from "react"
import { RootStoreProp } from "../../stores/RootStore"
import AuthStore, { AuthStoreProp } from "../../stores/AuthStore"
import { useBreakpoint } from "ds-flowty"
import { useNavbarViewContext } from "../../contexts/NavbarViewContext"
import { IS_MAINNET } from "../../util/settings"
import {
	NavItemName,
	navItems,
} from "ds-flowty/src/FlowtyNavbar/types/NavbarTypes"
import { useSporkContext } from "../../contexts/SporkContext"
import { updateAccountData } from "../../services/firestore/AccountService"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { promotion } from "../../util/promotion"

interface LayoutPageInterface extends AuthStoreProp, RootStoreProp {
	landingPage?: boolean
	authStore: AuthStore | undefined
	pathname: string
}

export const useLayout = ({
	landingPage,
	authStore,
	pathname,
}: LayoutPageInterface) => {
	const breakpoint = useBreakpoint()
	const { isShowing } = useSporkContext()
	const [authLoading, setAuthLoading] = useState<boolean>(false)

	const { visibleInNav, setNavbarOffsetHeight, navbarOffsetHeight } =
		useNavbarViewContext()

	const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false)
	const [isPromotionVisible, setIsPromotionVisible] = useState(false)

	const [showLatestTermsModal, setShowLatestTermsModal] =
		useState<boolean>(false)

	const loggedUserHasNoEmail =
		authStore && authStore.loggedUser && authStore.loggedUser.email === ""

	const loggedUserHasNoMarketingEmail =
		authStore &&
		authStore.loggedUser &&
		(!authStore.loggedUser.marketingEmail ||
			authStore.loggedUser.marketingEmail === "")

	const loggedUserAcknowledged =
		authStore?.loggedUser?.hasAcknowledgedPromotions?.[
			promotion[0]?.promotionId
		]
	const localStorageKey = `featurePopupAcknowledged_${promotion[0]?.promotionId}`

	useEffect(() => {
		if (authStore) {
			authStore.loadActiveGlobalAlerts()
		}
		if (authStore?.loggedUser?.loggedIn) {
			setAuthLoading(false)
		}
	}, [authStore?.loggedUser?.loggedIn])

	useEffect(() => {
		if (
			((loggedUserHasNoEmail &&
				!authStore.loggedUser?.welcomePopupAcknowledged) ||
				(IS_MAINNET &&
					loggedUserHasNoMarketingEmail &&
					!authStore.loggedUser?.welcomePopupAcknowledged)) &&
			pathname !== "/tos" &&
			pathname !== "/privacy"
		) {
			setShowWelcomeMessage(true)
		} else {
			if (
				authStore?.loggedUser &&
				!authStore.loggedUser?.hasAcceptedTermsV2 &&
				pathname !== "/tos" &&
				pathname !== "/privacy"
			) {
				setShowLatestTermsModal(true)
			} else {
				setShowLatestTermsModal(false)
			}
		}
	}, [
		authStore?.loggedUser,
		loggedUserHasNoMarketingEmail,
		loggedUserHasNoEmail,
	])

	useEffect(() => {
		const localAcknowledged = localStorage.getItem(localStorageKey) === "true"
		if (
			pathname !== "/create" &&
			((authStore?.loggedUser?.addr && !loggedUserAcknowledged) ||
				(!authStore?.loggedUser?.addr && localAcknowledged === false))
		) {
			setIsPromotionVisible(true)
		}
	}, [authStore?.loggedUser, promotion[0]?.promotionId, localStorageKey])

	const selectedItem = useMemo<NavItemName | undefined>(() => {
		if (pathname.includes("/collection")) {
			return
		}

		const activeItem = Object.entries(navItems).find(([, value]) => {
			return pathname.includes(value)
		})

		if (activeItem) {
			return activeItem[0] as NavItemName
		}
	}, [pathname])

	const getPaddingBottom = useCallback(() => {
		if (["xs", "mobile", "tablet"].includes(breakpoint)) {
			return navbarOffsetHeight
		}
		return `${navbarOffsetHeight}px`
	}, [
		breakpoint,
		landingPage,
		navbarOffsetHeight,
		pathname,
		selectedItem,
		visibleInNav,
	])

	const handleClosePromotion = async () => {
		setIsPromotionVisible(false)
		if (authStore?.loggedUser?.addr) {
			await updateAccountData(authStore?.loggedUser?.addr, {
				hasAcknowledgedPromotions: {
					[promotion[0]?.promotionId]: true,
				},
			})

			Mixpanel.track("User acknowledged promotion", promotion[0]?.promotionId)
			authStore?.setPromotionAcknowledged(promotion[0]?.promotionId, true)
		}
		localStorage.setItem(localStorageKey, "true")
	}

	return {
		authLoading,
		getPaddingBottom,
		handleClosePromotion,
		isPromotionVisible,
		isShowing,
		navbarOffsetHeight,
		selectedItem,
		setAuthLoading,
		setNavbarOffsetHeight,
		showLatestTermsModal,
		showWelcomeMessage,
		visibleInNav,
	}
}

export default useLayout
