import { FCLTransactionResult } from "flowty-common"
import { Err, sendMutation } from "../../utils/Utils"
import { FundRentalListingProps } from "./FundRentalListingTypes"
import { Config } from "../../types"
import { getFillRentalTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class FundRentalListing {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	fundRentalListing = async ({
		listingData,
		token,
		nftData,
		enabledAutoReturn,
		nftReceiverAddress,
		ftPrivatePathIdentifier,
		ftProviderAddress,
		nftProviderPathIdentifier,
		txAvailableCallback,
	}: FundRentalListingProps): Promise<FCLTransactionResult> => {
		const { listingResourceID, flowtyStorefrontAddress } = listingData
		const ftProviderArg = this.config.crescendo
			? fcl.arg(ftPrivatePathIdentifier || "0", t.UInt64)
			: fcl.arg(ftPrivatePathIdentifier, t.String)
		const nftProviderArg = this.config.crescendo
			? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
			: fcl.arg(nftProviderPathIdentifier, t.String)

		const txArguments = [
			fcl.arg(listingResourceID.toString(), t.UInt64),
			fcl.arg(flowtyStorefrontAddress, t.Address),
			fcl.arg(enabledAutoReturn, t.Bool),
			fcl.arg(nftReceiverAddress, t.Address),
			fcl.arg(ftProviderAddress, t.Address),
			ftProviderArg,
			nftProviderArg,
		]

		const txContent = getFillRentalTxn(this.config, token, nftData)

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
