import { FlowNFT } from "flowty-common"
import { flowty } from "../../config/config"
import { Log } from "../../util/Log"
import { getNftStoragePath } from "./NftCollectionService"

export const getRoyaltyRateForNFT = async (nft: FlowNFT): Promise<number> => {
	if (!nft.owner) {
		throw new Error("nft owner is required to calculate royalty rate")
	}

	const storagePath = await getNftStoragePath(nft)
	if (!storagePath) {
		throw new Error(
			"unable to get storage path for nft to calculate royalty rate"
		)
	}

	const rate = await flowty.scripts.getRoyaltyRate(
		nft.owner || "",
		nft.id.toString(),
		storagePath
	)

	Log("getRoyaltyRateForNFT", { rate })
	return Number(rate)
}
