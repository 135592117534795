import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import { getDurationPeriod } from "../../../../../../util/nftDataUtil"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	flowtyStorefrontAddress: string
	amount: number
	deposit: number
	paymentTokenType: SupportedTokens
	term: number
}

export const RentListedRow: React.FunctionComponent<Props> = ({
	flowtyStorefrontAddress,
	amount,
	deposit,
	paymentTokenType,
	term,
}) => {
	return (
		<div className='flex gap-1 flex-wrap text-[14px]'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Listed</span> for a{" "}
				<span className='font-bold text-[14px]'>rental fee</span> of
			</p>
			<NftPrice price={amount} token={paymentTokenType} />
			<p className='text-start'>
				with a <span className='font-bold text-[14px]'>deposit</span> of{" "}
			</p>
			<NftPrice price={deposit} token={paymentTokenType} />
			<p className='text-start'>
				and duration of{" "}
				<span className='font-bold text-[14px]'>{getDurationPeriod(term)}</span>
			</p>
			<p className='text-start'>
				by <ProfileLink address={flowtyStorefrontAddress} />{" "}
			</p>
		</div>
	)
}
