import React, { useMemo, useState } from "react"
import { cn } from "../../utils/cn"

export interface FlowtyFacetButtonProps {
	children?: React.ReactNode | React.ReactNode[]
	isCollapsible?: boolean
	text?: string
	icon?: React.ReactNode
	onClick?: () => void
	className?: string
}

export const FlowtyFacetButton: React.FC<FlowtyFacetButtonProps> = ({
	children,
	icon,
	text,
	isCollapsible = false,
	className,
	onClick,
}) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const collapsibleChevron = useMemo(() => {
		if (isCollapsible) {
			return (
				<svg
					onClick={() => setIsExpanded(!isExpanded)}
					className={cn("text-white w-[1.25rem] h-[1.25rem] flex-shrink-0 ", {
						"rotate-0": !isExpanded,
						"rotate-180": isExpanded,
					})}
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L9.41421 10L12.7071 13.2929C13.0976 13.6834 13.0976 14.3166 12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L7.29289 10.7071C6.90237 10.3166 6.90237 9.68342 7.29289 9.29289L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289Z'
						fill='white'
					/>
				</svg>
			)
		}
		return null
	}, [isCollapsible, isExpanded])

	return (
		<button
			onClick={onClick && onClick}
			className={cn(
				"py-[2.5px] w-full font-montserrat text-[0.9375rem] font-[500] rounded-[0.375rem] text-white bg-glassFillGray hover:bg-glassFillGrayHover min-h-[2.5rem] flex items-center justify-start gap-[0.375rem] shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]",
				className
			)}
		>
			{collapsibleChevron}
			{icon}
			{text}
			{children}
		</button>
	)
}
