import React from "react"

import UserSocialNetworks from "./UserSocialNetworks"
import UserPreferences from "./UserPreferences"

export const Settings = () => {
	return (
		<section>
			<div className='text-[2rem] font-black pb-[3rem] pt-[1.5rem] leading-8 tracking-wider'>
				SETTINGS
			</div>
			<div className='flex flex-col xl:flex-row xl:items-start bg-blue gap-[1.5rem]'>
				<UserPreferences />
				<UserSocialNetworks />
			</div>
			<div className='w-full fill-[4C5B69] my-[3em] border-gradient js-show-on-scroll motion-safe:animate-fadeIn' />
		</section>
	)
}
