import { ListingAvailableData } from "flowty-common"
import { Helmet } from "react-helmet-async"

export const ListingHeader: React.FC<{ listing: ListingAvailableData }> = ({
	listing,
}) => {
	const metaTitle = `Flowty loan listing ${listing?.detail?.nft?.title ?? ""}`
	const metaDescription = `View this ${
		listing?.detail?.nft?.title ?? ""
	} loan listing and many others on flowty.io`

	// META IMAGE
	const metaImage =
		listing?.detail?.nft?.media && listing?.detail?.nft.media.length > 0
			? listing?.detail?.nft.media[0].uri
			: ""

	return (
		<Helmet>
			<meta
				name='description'
				content="Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
			/>
			<link rel='manifest' href='/manifest.json' />
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:site' content='@flowty' />

			<meta name='twitter:title' content={metaTitle} />
			<meta name='twitter:description' content={metaDescription} />
			<meta name='twitter:image' content={metaImage} />
			<meta name='description' content={metaDescription} />
			<meta property='og:title' content={metaTitle} />
			<meta property='og:description' content={metaDescription} />
			<meta property='og:image' content={metaImage} />
			<title>{metaTitle}</title>
		</Helmet>
	)
}
