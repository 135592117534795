import axios from "axios"
import { ListingKind, OpensearchFlowNFT, safeParseJSON } from "flowty-common"
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useHybridCustodyContext } from "../../contexts/HybridCustodyContext"
import {
	MappedFacet,
	fetchCollectionGlobalFacets,
} from "../../hooks/data/collections/flowNFTContract"
import { User } from "../../models/user"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { STOREFRONT_ENABLED, apiURL } from "../../util/settings"
import { Facet, Filter, FormattedFilter, SortColumn } from "./types"
import {
	FormattedOrderFilter,
	MappedOrderFilters,
	MappedRangeFilter,
	SelectedTokens,
} from "./types/orders"
import { formatToMappedFacets } from "./utils/formatFacets"

const includeAllListings = true

const getDefaultCollectionFilter = (
	collectionAddress: string,
	collectionName: string
): {
	collection: string
	maxSerial: string | undefined
	minSerial: string | undefined
	traits: []
} => {
	return {
		collection: `${collectionAddress}.${collectionName}`,
		maxSerial: undefined,
		minSerial: undefined,
		traits: [],
	}
}

interface OpenSearchConnectorProps {
	publicAccount?: User | null
	collectionPage?: Array<string | undefined>
	defaultShowOrders?: boolean
	endpoint: "collection" | "user" | "marketplace"
	isHomeScreen?: boolean
	children: (val: {
		initialLoading: boolean
		referenceFacets: MappedFacets | null
		mappedFacets: MappedFacets
		hasNoSpecifiedOrderTypeSelected: boolean
		isFilterHidden: boolean
	}) => React.ReactNode | React.ReactNode
}

interface MappedFacets {
	[key: string]: MappedFacet
}

// Explicit any as a fallback generic, ideally a type is defined at implementation
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface OpenSearchContextValues<T = any> {
	canScroll: boolean
	endpoint?: "collection" | "user" | "marketplace"
	facets: Facet[]
	filters: Filter
	fetchSearchResults: (refetch?: boolean) => void
	hits: T[]
	initialLoading: boolean
	isLoading: boolean
	isLoadingFacets: boolean
	matchedCount: number
	mappedFacets?: MappedFacets | null
	noFilterMappedFacets: MappedFacets | null
	orderFilters: MappedOrderFilters
	search: string
	setFilters: (val: Filter) => void
	setOrderFilters: (val: MappedOrderFilters) => void
	setSearch: (val: string) => void
	pageCount: number
	page: number
	paymentTokens: SelectedTokens | null
	setPaymentTokens: (val: SelectedTokens | null) => void
	serialFilter: MappedRangeFilter | null
	setSerialFilter: (val: MappedRangeFilter | null) => void
	setPage: (val: number) => void
	sort: SortColumn | null
	onlyUnlisted: boolean | null
	setOnlyUnlisted: (val: boolean) => void
	setSort: (val: SortColumn | null) => void
	setIsFilterHidden: (val: boolean) => void
	isFilterHidden: boolean
	publicAccount?: User | null
	walletAddresses: string[]
	setWalletAddresses: React.Dispatch<React.SetStateAction<string[]>>
}
const defaultOrderTypes = STOREFRONT_ENABLED
	? { loan: {}, rental: {}, storefront: {} }
	: { loan: {}, rental: {} }
export const OpenSearchContext = createContext<OpenSearchContextValues>({
	canScroll: true,
	facets: [],
	fetchSearchResults: () => {},
	filters: {},
	hits: [],
	initialLoading: true,
	isFilterHidden: false,
	isLoading: true,
	isLoadingFacets: true,
	matchedCount: 0,
	noFilterMappedFacets: {},
	onlyUnlisted: false,
	orderFilters: defaultOrderTypes,
	page: 1,
	pageCount: 0,
	paymentTokens: null,
	publicAccount: null,
	search: "",
	serialFilter: null,
	setFilters: () => {},
	setIsFilterHidden: () => {},
	setOnlyUnlisted: () => {},
	setOrderFilters: () => {},
	setPage: () => {},
	setPaymentTokens: () => {},
	setSearch: () => {},
	setSerialFilter: () => {},
	setSort: () => {},
	setWalletAddresses() {},
	sort: null,
	walletAddresses: [],
})

export const PAGE_LIMIT = 24

const DEFAULT_SORT = {
	direction: "desc",
	listingKind: "storefront",
	path: "blockTimestamp",
	prefix: undefined,
}

const USER_DEFAULT_SORT = {
	direction: "desc",
	listingKind: null,
	path: "latestBlock",
	prefix: "",
}

const DEFAULT_INITIAL_ORDER = {
	storefront: {},
}

const OpenSearchConnectorComponent: React.FC<OpenSearchConnectorProps> = ({
	publicAccount,
	collectionPage,
	children,
	defaultShowOrders = false,
	endpoint,
	isHomeScreen,
}) => {
	const location = useLocation()
	const navigate = useNavigate()
	const currentSearch = location.search

	const query = new URLSearchParams(currentSearch)
	const pathname = location.pathname

	const initialFilters = safeParseJSON(query.get("collectionFilters")) || {}

	const getInitialOrderFilters = () => {
		const parsedOrderFilters = safeParseJSON(query.get("orderFilters"))
		if (parsedOrderFilters) {
			return parsedOrderFilters
		}

		if (!isHomeScreen) {
			if (endpoint === "collection" || endpoint === "marketplace") {
				return DEFAULT_INITIAL_ORDER
			}
		}

		return {}
	}

	const initialOrderFilters = getInitialOrderFilters()
	const initialSortValue = safeParseJSON(query.get("sort")) || null
	const [isFilterHidden, setIsFilterHidden] = useState(false)

	const [onlyUnlisted, setOnlyUnlisted] = useState(false)

	const [walletAddresses, setWalletAddresses] = useState<string[]>(
		safeParseJSON(query.get("walletAddresses")) ||
			(endpoint === "user"
				? [
						publicAccount?.addr || "",
						...Object.keys(publicAccount?.childAccounts || {}),
				  ]
				: [])
	)

	const [hits, setHits] = useState<OpensearchFlowNFT[]>([])
	const [search, setSearch] = useState("")
	const [filters, setFilters] = useState<Filter>(initialFilters as Filter)
	const [facets, setFacets] = useState<Facet[]>([])
	const [noFilterFacets, setNoFilterFacets] = useState<Facet[]>([])
	const [matchedCount, setMatchedCount] = useState(0)
	const [page, setPage] = useState(1)
	const [initialLoading, setInitialLoading] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [isLoadingFacets, setisLoadingFacets] = useState(true)
	const [sort, setSort] = useState<SortColumn | null>(() => {
		const sortFromQuery = query.get("sort")

		if (sortFromQuery) {
			return safeParseJSON(sortFromQuery)
		}

		if (endpoint === "user") {
			return USER_DEFAULT_SORT
		} else {
			return (initialSortValue || DEFAULT_SORT) as SortColumn
		}
	})

	const [orderFilters, setOrderFilters] = useState<MappedOrderFilters>(
		initialOrderFilters as MappedOrderFilters
	)
	const [referenceFacets, setReferenceFacets] = useState<null | MappedFacets>(
		null
	)
	const [paymentTokens, setPaymentTokens] = useState<SelectedTokens | null>(
		safeParseJSON(query.get("paymentTokens"))
	)
	const [serialFilter, setSerialFilter] = useState<MappedRangeFilter | null>(
		safeParseJSON(query.get("serialFilter"))
	)
	const [foundCollectionTypes, setFoundCollectionTypes] = useState<string[]>([])
	const [stringfyCollections, setStringfyCollections] = useState<string>("")
	const { iterateAndRunScript } = useHybridCustodyContext()

	const canScrollRef = useRef(true)
	const previousPayloadRef = useRef("")
	const searchReqControllerRef = useRef<AbortController | null>(null)
	const facetReqControllerRef = useRef<AbortController | null>(null)
	const fetchReqControllerRef = useRef<AbortController | null>(null)
	const isFirstRender = useRef(true)

	const [collectionAddress, collectionName] = collectionPage || []

	const defaultFilters =
		collectionPage &&
		getDefaultCollectionFilter(collectionAddress || "", collectionName || "")

	const formattedOrderFilters: FormattedOrderFilter[] = useMemo(() => {
		const displayedFilters = defaultShowOrders
			? Object.keys(orderFilters).length > 0
				? orderFilters
				: defaultOrderTypes
			: orderFilters

		const listingTypes = Object.keys(displayedFilters) as ListingKind[]
		const results = listingTypes.map(type => {
			const fields = Object.keys(displayedFilters[type] || {})
			return {
				conditions: fields.map(field => {
					// @ts-ignore-next-line
					const values = displayedFilters?.[type]?.[field]
					return {
						gte: values?.min,
						lte: values?.max || null,
						path: field,
					}
				}),
				kind: type,
				paymentTokens:
					endpoint !== "user" && paymentTokens
						? paymentTokens?.dapper
							? Object.keys(paymentTokens.dapper)
							: Object.keys(paymentTokens?.other || {})
						: [],
			}
		})
		return results
	}, [orderFilters, paymentTokens])

	const collectionFilters = useMemo(() => {
		const copyFilters = { ...filters }

		if (defaultFilters?.collection && !copyFilters[defaultFilters.collection]) {
			copyFilters[defaultFilters.collection] = {}
		}

		const collections = Object.keys(copyFilters)

		const formattedFilters: FormattedFilter[] = collections.map(collection => {
			const traitsMap = copyFilters[collection]
			const traits = Object.keys(traitsMap)
			return {
				collection: collection,
				maxSerial: serialFilter?.max ? serialFilter?.max.toString() : undefined,
				minSerial: serialFilter?.min ? serialFilter?.min.toString() : undefined,
				traits: traits.map(trait => {
					const traitsFilter = {
						name: trait,
						values: Object.keys(copyFilters[collection][trait]),
					}

					return traitsFilter
				}),
			} as FormattedFilter
		})
		return formattedFilters
	}, [filters, defaultFilters, serialFilter])

	const url = useMemo(() => {
		if (endpoint === "collection") {
			return `${apiURL}/collection/${collectionAddress}/${collectionName}`
		}
		if (endpoint === "user") {
			return `${apiURL}/${endpoint}/${publicAccount?.addr}`
		}
		return `${apiURL}/${endpoint}`
	}, [collectionAddress, collectionName, endpoint, publicAccount?.addr])

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getPayload = (cleanFilter: boolean): { [key: string]: any } => {
		const filteredWalletAddresses = walletAddresses.filter(
			address => (address?.length || 0) > 2
		)
		if (cleanFilter) {
			return {
				address: publicAccount?.addr || null,
				addresses: filteredWalletAddresses,
				collectionFilters: [],
				from: 0,
				includeAllListings,
				limit: page * PAGE_LIMIT,
				onlyUnlisted,
				orderFilters: [],
				sort: sort,
			}
		} else if (formattedOrderFilters?.[0]?.kind === "all") {
			const formattedPayloadSort = {
				direction: "desc",
				listingKind: null,
				path: "blockTimestamp",
			} as SortColumn
			return {
				address: publicAccount?.addr || null,
				addresses: filteredWalletAddresses,
				collectionFilters: collectionFilters,
				from: 0,
				includeAllListings,
				kind: "storefront",
				limit: page * PAGE_LIMIT,
				onlyUnlisted,
				sort:
					JSON.stringify(sort) ===
					JSON.stringify({
						direction: "desc",
						listingKind: "all",
						path: "latestBlock",
					})
						? formattedPayloadSort
						: sort,
			}
		} else {
			return {
				address: publicAccount?.addr || null,
				addresses: filteredWalletAddresses,
				collectionFilters: collectionFilters,
				from: 0,
				includeAllListings,
				limit: page * PAGE_LIMIT,
				onlyUnlisted,
				orderFilters: formattedOrderFilters,
				sort: sort,
			}
		}
	}

	const mappedFacets: MappedFacets = useMemo(
		() => formatToMappedFacets(facets),
		[facets]
	)

	const noFilterMappedFacets: MappedFacets = useMemo(
		() => formatToMappedFacets(noFilterFacets),
		[noFilterFacets]
	)

	const getInitialFacets = useCallback(async () => {
		const payload = getPayload(true)

		if (endpoint === "collection") {
			const contractID = `${collectionAddress}.${collectionName}`
			const collectionTraits = await fetchCollectionGlobalFacets(contractID)
			if (!collectionTraits) {
				return
			}
			setNoFilterFacets([collectionTraits])
		} else {
			try {
				const facetRequest = await axios.post(url + "/facets", payload, {
					signal: fetchReqControllerRef?.current?.signal,
				})
				setNoFilterFacets(facetRequest.data.facets)
			} catch (error) {
				Mixpanel.track("ERROR_FETCHING_INITIAL_FACETS", { error })
			}
		}
	}, [])

	// Function that get facets from a collection, we use 2 different API calls
	// Firebase one (fetchCollectionGlobalFacets) Get all facets from a collection (No Filters)
	// Axios one (url + "/facets") Get facets from a collection and the filters applied
	const getFacets = async (isFirstCall: boolean): Promise<void> => {
		if (
			endpoint === "collection" &&
			collectionAddress &&
			collectionName &&
			isFirstCall
		) {
			const contractID = `${collectionAddress}.${collectionName}`
			const collectionTraits = await fetchCollectionGlobalFacets(contractID)
			if (!collectionTraits) {
				return
			}
			setFacets([collectionTraits])
			const mappedReferenceFacets = formatToMappedFacets([collectionTraits])
			setReferenceFacets(mappedReferenceFacets)

			setisLoadingFacets(false)
		} else {
			try {
				const payload = getPayload(false)
				const facetRequestResponse = await axios.post(
					url + "/facets",
					payload,
					{
						signal: fetchReqControllerRef?.current?.signal,
					}
				)

				facetRequestResponse.data.facets.length > 0 && endpoint === "collection"
					? setFacets([facetRequestResponse.data.facets?.[0]])
					: setFacets(facetRequestResponse.data.facets)
				const mappedReferenceFacets = formatToMappedFacets(
					facetRequestResponse.data.facets
				)
				setReferenceFacets(mappedReferenceFacets)
				const facetsArray = Object.keys(mappedReferenceFacets)
				setFoundCollectionTypes(Object.keys(mappedReferenceFacets))
				setStringfyCollections(facetsArray.join(","))
				setisLoadingFacets(false)
			} catch (err) {
				Mixpanel.track("FAILED_OSC_FACET_REQUEST", { err })
			}
		}
	}

	//Get NFTs from a address and the facets
	const fetchSearchResults = async (refetch?: boolean): Promise<void> => {
		const payload = getPayload(false)
		const stringifiedPayload = JSON.stringify(payload)

		// if the the useEffect is triggered but the payload is same don't make the request
		if (
			previousPayloadRef.current === stringifiedPayload &&
			!Boolean(refetch)
		) {
			return
		}
		setIsLoading(true)
		setisLoadingFacets(true)

		searchReqControllerRef.current?.abort()
		searchReqControllerRef.current = new AbortController()

		previousPayloadRef.current = stringifiedPayload

		try {
			const response = await axios.post(url, payload, {
				signal: searchReqControllerRef.current.signal,
			})
			setHits(prev => {
				if (
					response.data.nfts.length >= prev.length + PAGE_LIMIT ||
					prev.length === 0
				) {
					canScrollRef.current = true
				}
				if (response.data.total === response.data.nfts.length) {
					canScrollRef.current = false
				}
				return response.data.nfts
			})

			setMatchedCount(response.data.total)
			initialLoading && setInitialLoading(false)
			setIsLoading(false)
		} catch (err) {
			Mixpanel.track("FAILED_OSC_NFT_REQUEST", { err })
		}

		//leaving it here for now, until we are sure that this it's not important
		// canScrollRef.current = page > 1 ? false : true

		facetReqControllerRef.current?.abort()
		facetReqControllerRef.current = new AbortController()

		const isCollectionPage = location.pathname.includes("/collection")
		const clearPayload =
			isCollectionPage &&
			payload.collectionFilters?.[0]?.traits?.length === 0 &&
			payload?.orderFilters?.length === 0
		if (clearPayload) {
			await getFacets(true)
			return
		}
		getFacets(false)
	}

	//useEffect called when the collection found on facets change
	useEffect(() => {
		iterateAndRunScript(
			foundCollectionTypes,
			publicAccount?.childAccounts || {},
			publicAccount?.addr as string
		)
	}, [stringfyCollections])

	//useEffect called when the filter or sort change, reset the page to 1
	useEffect(() => {
		setPage(1)
		canScrollRef.current = true
	}, [filters, formattedOrderFilters, sort])

	//useEffect called when a search, filter, sort or page change
	useEffect(() => {
		fetchSearchResults()
	}, [
		search,
		collectionFilters,
		formattedOrderFilters,
		sort,
		page,
		serialFilter,
		walletAddresses,
		publicAccount,
		pathname,
	])

	//useEffect called on first render, getting total facets and counts available
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			getInitialFacets()
		}
	}, [])

	const defaultValues: { [key: string]: string } = {
		collectionFilters: JSON.stringify({}),
		orderFilters: location.pathname.includes("/user")
			? JSON.stringify({ all: {} })
			: JSON.stringify({ storefront: {} }),
		paymentTokens: JSON.stringify(null),
		serialFilter: JSON.stringify({ max: "", min: "" }),
		sort: location.pathname.includes("/user")
			? JSON.stringify({
					direction: "desc",
					listingKind: null,
					path: "latestBlock",
					prefix: "",
			  })
			: JSON.stringify({
					direction: "desc",
					listingKind: "storefront",
					path: "blockTimestamp",
			  }),
	}

	//UseEffect to update the URL PARAMS with the filters, sort, paymentTokens and serialFilter
	useEffect(() => {
		const currentFilters = {
			collectionFilters: JSON.stringify(filters),
			orderFilters: JSON.stringify(orderFilters),
			paymentTokens: JSON.stringify(paymentTokens),
			serialFilter: JSON.stringify(serialFilter),
			sort: JSON.stringify(sort),
		}

		const searchParams = new URLSearchParams(location.search)

		Object.entries(currentFilters).forEach(([key, value]) => {
			const defaultValue = defaultValues[key]

			if (
				value === defaultValue ||
				value === "null" ||
				value === "{}" ||
				value === "{{}}"
			) {
				searchParams.delete(key)
			} else {
				searchParams.set(key, value)
			}
		})

		const searchParamsString = searchParams.toString()

		if (
			searchParamsString &&
			currentSearch.replace(/\?/g, "") !== searchParamsString
		) {
			if (location.pathname === "/") {
				return
			}
			navigate(`?${searchParamsString}`, { replace: true })
		} else if (!searchParamsString && currentSearch.replace(/\?/g, "") !== "") {
			navigate(location.pathname, { replace: true })
		}
	}, [
		filters,
		orderFilters,
		sort,
		paymentTokens,
		serialFilter,
		location,
		currentSearch,
		navigate,
	])

	const openSearchValues = useMemo(() => {
		return {
			canScroll: canScrollRef.current,
			facets,
			fetchSearchResults,
			filters,
			hits,
			initialLoading,
			isFilterHidden,
			isLoading,
			isLoadingFacets,
			mappedFacets,
			matchedCount,
			noFilterMappedFacets,
			onlyUnlisted,
			orderFilters,
			page,
			pageCount: Math.ceil(matchedCount / PAGE_LIMIT),
			paymentTokens,
			publicAccount,
			search,
			serialFilter,
			setFilters,
			setIsFilterHidden,
			setOnlyUnlisted,
			setOrderFilters,
			setPage,
			setPaymentTokens,
			setSearch,
			setSerialFilter,
			setSort,
			setWalletAddresses,
			sort,
			walletAddresses,
		}
	}, [
		canScrollRef,
		facets,
		fetchSearchResults,
		filters,
		hits,
		initialLoading,
		isFilterHidden,
		isLoading,
		isLoadingFacets,
		mappedFacets,
		matchedCount,
		noFilterMappedFacets,
		onlyUnlisted,
		orderFilters,
		page,
		PAGE_LIMIT,
		paymentTokens,
		publicAccount,
		search,
		serialFilter,
		setFilters,
		setIsFilterHidden,
		setOnlyUnlisted,
		setOrderFilters,
		setPage,
		setPaymentTokens,
		setSearch,
		setSerialFilter,
		setSort,
		setWalletAddresses,
		sort,
		walletAddresses,
	])

	return (
		<OpenSearchContext.Provider value={openSearchValues}>
			{children({
				hasNoSpecifiedOrderTypeSelected: Object.keys(orderFilters).length !== 1,
				initialLoading,
				isFilterHidden,
				mappedFacets,
				referenceFacets,
			})}
		</OpenSearchContext.Provider>
	)
}

export const OpenSearchConnector = OpenSearchConnectorComponent

export const useOpenSearchContext = (): OpenSearchContextValues => {
	const context = useContext(OpenSearchContext)
	if (context === undefined) {
		throw new Error(
			"useMarketplaceAppContext must be used within a MarketplaceAppProvider"
		)
	}
	return context
}
