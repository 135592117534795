/* eslint-disable sort-keys-fix/sort-keys-fix */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { OpensearchFlowNFT } from "flowty-common"
import { Formik } from "formik"
import { useState } from "react"
import { HistoryFilters } from "./HistoryFilters"
import { HistoryForm } from "./HistoryForm/HistoryForm"
import { FilterFormValues } from "./types/FilterFormValues"
import { useMarketplaceAppContext } from "../../../../contexts/MarketplaceAppContext/MarketplaceAppContext"

interface Props {
	openSearchNFT: OpensearchFlowNFT
}

const initialValues: FilterFormValues = {
	all: true,
	storefront: false,
	loan: false,
	rent: false,
	loanFilters: {
		funded: true,
		repaid: true,
		defaulted: true,
		listed: true,
	},
	rentalFilters: {
		rented: true,
		returned: true,
		unreturned: true,
		listed: true,
	},
	storefrontFilters: {
		purchased: true,
		listed: true,
		offerAccepted: true,
		offerCancelled: true,
		offerCreated: true,
	},
}

export const HistoryTab: React.FC<Props> = ({ openSearchNFT }) => {
	const { renderMobile } = useMarketplaceAppContext()
	const [showFilters, setShowFilters] = useState(!renderMobile)
	const [page, setPage] = useState(1)

	const updatePage = (newPage: number): void => {
		setPage(newPage)
	}

	return (
		<div className='w-full flex flex-col gap-4'>
			<button
				onClick={() => setShowFilters(value => !value)}
				className='w-max max-md:w-full flex items-center justify-center py-1 collectionCard !rounded-md md:min-w-[344px]'
			>
				{showFilters ? (
					<ChevronLeftIcon height={20} width={20} />
				) : (
					<ChevronRightIcon height={20} width={20} />
				)}{" "}
				Filters{" "}
			</button>
			<div className='w-full flex gap-4'>
				<Formik
					initialValues={initialValues}
					onSubmit={() => {}}
					validate={() => {
						return {}
					}}
				>
					{({ values, setFieldValue }) => {
						const setEventType = (
							eventType: "storefront" | "loan" | "rent" | "all"
						): void => {
							setFieldValue("all", false)
							setFieldValue("storefront", false)
							setFieldValue("loan", false)
							setFieldValue("rent", false)
							setFieldValue(eventType, true)
							updatePage(1)

							renderMobile && setShowFilters(false)
						}

						return (
							<>
								<div className={showFilters ? "flex" : "hidden"}>
									<HistoryFilters values={values} setEventType={setEventType} />
								</div>

								{renderMobile && showFilters ? null : (
									<HistoryForm
										openSearchNFT={openSearchNFT}
										values={values}
										page={page}
										setPage={updatePage}
									/>
								)}
							</>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}
