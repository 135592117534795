import { fetchSpecificFlowNFT } from "../../../services/firestore/nftIInfo"
import { FlowNFT } from "flowty-common"
import { useEffect, useState } from "react"

interface UseFlowNFTProps {
	nftType: string
	nftId: string
	skip?: boolean
}

export const useFlowNFT = ({
	nftType,
	nftId,
	skip,
}: UseFlowNFTProps): { flowNFT: FlowNFT | null } => {
	const [flowNFT, setFlowNFT] = useState<FlowNFT | null>(null)

	useEffect(() => {
		if (skip) return
		fetchSpecificFlowNFT({
			nftId,
			nftType,
		}).then(res => setFlowNFT(res))
	}, [nftType, nftId])

	return { flowNFT }
}
