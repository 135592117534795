import React from "react"
import { FlowtyDiscountIndicator } from "../Shared/Molecules/FlowtyDiscountIndicator"
import {
	getFMVDisplayLabelFromSource,
	getFMVHrefFromSource,
} from "../utils/GlobalUtils"

interface FlowtyDiscountBannerProps {
	percent: number
	source: string
	isOffer?: boolean
}

export const FlowtyDiscountBanner: React.FC<FlowtyDiscountBannerProps> = ({
	percent,
	source,
	isOffer = false,
}) => {
	const fmvHref = getFMVHrefFromSource(source)
	const sourceName = getFMVDisplayLabelFromSource(source)

	if (percent < 5) return null
	return (
		<div className='w-full p-[1.12rem] bg-[#ffffff] text-white bg-opacity-10 rounded-md flex items-center'>
			<div className='pr-3 height-[2.625rem]'>
				<FlowtyDiscountIndicator percent={percent} />
			</div>
			<div className='flex flex-col py-1'>
				<div className='text-xs font-bold pb-1 pr-3'>
					Discount to Fair Market Value (FMV)
				</div>
				<div className='text-[0.6875rem] font-normal leading-normal'>
					{isOffer ? "Your Offer" : "Purchase Price"} is {percent}% lower than
					FMV per{" "}
					<a
						target='_blank'
						rel='noreferrer'
						href={fmvHref}
						className='text-primary'
					>
						{sourceName}
					</a>
					. FMV is an estimate and is not financial advice. Tag only appears if
					discount is 5%+.
				</div>
			</div>
		</div>
	)
}
