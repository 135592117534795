import { getSquareImage } from "flowty-common"
import { fetchSpecificFlowNFTContract } from "../services/firestore/nftIInfo"
import fallbackImage from "../assets/new_fallback_image.svg"

interface GetCollectionImageProps {
	collectionName: string
	collectionAddress: string
}

export const getCollectionImage = async ({
	collectionAddress,
	collectionName,
}: GetCollectionImageProps) => {
	try {
		const collection = await fetchSpecificFlowNFTContract({
			collectionAddress,
			collectionName,
		})

		return getSquareImage(
			collection?.collectionDisplay?.name || "",
			collection || null
		)
	} catch (error) {
		console.error("Error retrieving collection image")
		return fallbackImage
	}
}
