import { ReactComponent as TxFailIcon } from "../../../../assets/media/loanInfo/txFailIcon.svg"
import { FlowtyButton } from "ds-flowty"
// import { getUserFriendlyErrorMessage } from "../../../../util/errorUtil"

interface Props {
	error: string | null
	reset: () => void
}

const FailedTransaction = ({ error, reset }: Props) => {
	return (
		<div className='flex flex-col justify-center items-center'>
			<TxFailIcon />

			<h5 className='mt-4 text-white'>Transaction was not successful</h5>
			<p className='text-lightText text-center px-4'>
				{/* TODO Replace after handling error causing crash */}
				{/* {getUserFriendlyErrorMessage(error)} */}
			</p>

			<div className='flex justify-between mt-5'>
				<FlowtyButton variant='primary' onClick={reset} text={"Try again"} />
			</div>
		</div>
	)
}

export default FailedTransaction
