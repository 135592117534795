import React from "react"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../stores/AuthStore"
import { RentalListingAvailableData } from "flowty-common"

interface PrivateListingBannerProps extends AuthStoreProp {
	listing?: RentalListingAvailableData
	className?: string
}

const PrivateListingBannerComponent: React.FC<PrivateListingBannerProps> = ({
	listing,
	authStore,
	className,
}) =>
	listing?.renter && listing?.state === "LISTED" ? (
		<div className='absolute top-0 left-0 w-full flex justify-center'>
			<div
				className={`flex justify-center items-center py-1 rounded-t-xl ${className} ${
					listing?.renter && authStore?.loggedUser?.addr === listing.renter
						? "bg-primary"
						: "bg-secondary"
				}`}
			>
				<p className='text-white text-bold me-2'>Private Listing</p>
			</div>
		</div>
	) : null

export const PrivateListing = inject("authStore")(
	observer(PrivateListingBannerComponent)
)
