import numeral from "numeral"

export function truncateCurrencyAmount(_currency: number): string {
	return truncateNumber(_currency, { shouldUseDecimals: true })
}

export function truncateNumber(
	_numberValue?: number,
	options?: { shouldUseDecimals: boolean }
): string {
	const shouldUseDecimals = options?.shouldUseDecimals ?? false
	if (!_numberValue) {
		return ""
	}
	const currency = Math.round(_numberValue * 100) / 100

	switch (true) {
		case String(currency.toFixed(2)).replace(/\./g, "").length < 6 ||
			_numberValue < 1:
			return numeral(currency).format(shouldUseDecimals ? "0,0.00" : "0,0")
		case String(currency.toFixed(2)).replace(/\./g, "").length < 9: // thousands
			const currencyThousands = currency / 1e3
			return `${numeral(currencyThousands).format("0,0.00")}K`
		case String(currency.toFixed(2)).replace(/\./g, "").length >= 9: // millions
			const currencyMillions = currency / 1e6
			return `${numeral(currencyMillions).format("0,0.00")}M`
		default:
			return ""
	}
}
