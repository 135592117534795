import WalletGrouped from "../../assets/WalletGrouped.png"

interface SignInButtonProps {
	isLoading: boolean
	logUser: () => void
}

export const SignInButton = ({ isLoading, logUser }: SignInButtonProps) => {
	return (
		<button
			onClick={logUser}
			disabled={isLoading}
			className={`inline-flex py-[8px] px-[18px] items-center gap-2 rounded-lg border-2 border-[var(--Neutral-300,#495057)] shadow-[0_4px_18px_rgba(0,0,0,0.25)] sm:min-w-[220px] mx-auto ${
				!isLoading &&
				"hover:border-[var(--Neutral-300,#6C757D)] hover:bg-opacity-5 hover:bg-white"
			}`}
		>
			{isLoading ? (
				<div className='flex justify-center items-center h-full mx-auto py-1 min-w-[40px]'>
					<div className='spinner w-[14px] h-[14px] sm:w-[24px] sm:h-[24px] border-4 border-gray-200 rounded-full border-t-transparent animate-spin'></div>
				</div>
			) : (
				<>
					<span className='text-[var(--Neutral-700,#DEE2E6)] text-center font-montserrat text-sm font-black leading-[16px]'>
						SIGN IN <span className='hidden sm:inline-block'>WITH</span>
					</span>
					<div className='hidden sm:flex flex-row items-center h-[32px]'>
						<img
							src={WalletGrouped}
							alt='Dapper Wallet Icon'
							className='max-w-[80px] rounded-full'
						/>
					</div>
					<span className='hidden sm:block text-[var(--Neutral-700,#DEE2E6)] text-center font-montserrat text-sm font-semibold leading-[16px]'>
						+2
					</span>
				</>
			)}
		</button>
	)
}
