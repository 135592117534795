interface AccountTagProps {
	isMainWallet?: boolean
}

export const FlowtyAccountTag = ({ isMainWallet = false }: AccountTagProps) => {
	return (
		<div
			className={`rounded-lg px-2 py-1 ${
				isMainWallet ? "bg-primary" : "bg-[#ffffff]"
			}`}
		>
			<p className='leading-[14px] text-[#031021] font-montserrat-bold font-black text-[0.625rem] uppercase'>
				{isMainWallet ? "main" : "child"}
			</p>
		</div>
	)
}
