import {
	getFMVDisplayLabelFromSource,
	getFMVHrefFromSource,
} from "../../../../utils/GlobalUtils"
import { ReactComponent as Triangle } from "../../../../assets/Triangle.svg"

interface NftCardFMVTooltipProps {
	valuationSource: string
}

export const NftCardFMVTooltip: React.FunctionComponent<
	NftCardFMVTooltipProps
> = ({ valuationSource }) => {
	return (
		<div className='absolute hidden group-hover:block'>
			<div className='relative right-0 -top-[9rem] bg-[#FFFFFF] rounded-md min-w-[18rem] z-10 cursor-default flex flex-col'>
				<div className='p-3'>
					<p className='font-bold text-base mb-2 text-black'>
						Fair Market Value (FMV)
					</p>
					<p className='text-sm text-black'>
						An estimate of an NFT&apos;s value based on{" "}
						<a
							target='_blank'
							rel='noreferrer'
							href={getFMVHrefFromSource(valuationSource)}
							className='text-sm text-primary'
						>
							{getFMVDisplayLabelFromSource(valuationSource)}
						</a>
						. FMV is an estimate and is in no way meant to construe financial
						advice.
					</p>
				</div>
				<Triangle className='fill-[#FFFFFF]  absolute h-6 w-6 -bottom-[10px] left-[13%] rotate-180' />
			</div>
		</div>
	)
}
