import FlowtyLoadingGif from "../../../assets/flowty-loading2.gif"
import { TransactionFollowBox } from "../../../TransactionFollowBox"

interface Props {
	transactionID?: string | null
	isMainnet: boolean
}

export const DropPurchasing: React.FunctionComponent<Props> = ({
	transactionID,
	isMainnet,
}) => {
	return (
		<div className='w-full flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<img
				className='w-[124px] h-[124px]'
				src={FlowtyLoadingGif}
				alt='Processing Transaction GIF
			'
			/>
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Processing...
			</div>

			<TransactionFollowBox
				transactionID={transactionID}
				isMainnet={isMainnet}
			/>
		</div>
	)
}
