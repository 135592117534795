/* eslint-disable @typescript-eslint/no-use-before-define */
import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getTargetedOfferTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNFTCatalog: boolean
): string => {
	if (
		token.symbol === "DUC" &&
		nftData.contractAddress === config.contractAddresses.TopShot &&
		!config.crescendo
	) {
		return createDapperTopShotOffer(config)
	}

	if (token.symbol === "DUC") {
		return config.crescendo
			? createDapperOfferCrescendo(config)
			: createDapperOffer(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? createFlowtyCatalogOfferCrescendo(config)
			: createFlowtyCatalogOffer(config, token)
	}

	return config.crescendo
		? createFlowtyNonCatalogOfferCrescendo(config)
		: createFlowtyNonCatalogOffer(config, token)
}

// NOTE: String utils is imported from our own offers contract address here.
// This will be changed when we move to crescendo but can't be changed right now
// or the transaction won't match what dapper has approved
const createDapperTopShotOffer = (config: Config): string => ``

const createDapperOffer = (config: Config): string => ``

const createFlowtyCatalogOffer = (
	config: Config,
	token: TokenMetadata
): string => ``

const createFlowtyNonCatalogOffer = (
	config: Config,
	token: TokenMetadata
): string => ``

const createFlowtyCatalogOfferCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import Offers from ${config.contractAddresses.Offers}
import Filter from ${config.contractAddresses.Offers}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import AddressUtils from ${config.contractAddresses.AddressUtils}

transaction(
    collectionIdentifier: String,
    nftID: UInt64,
    numAcceptable: Int,
    offeredAmount: UFix64,
    paymentTokenIdentifier: String,
    expiry: UInt64,
    nftReceiverAddress: Address,
    ftProviderAddress: Address,
    ftProviderControllerID: UInt64
) {
    let storefront: auth(Offers.List) &Offers.Storefront
    let paymentTokenType: Type
    let nftType: Type
    let tokenProvider: Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: auth(Capabilities, Storage) &Account) {
        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier)
            ?? panic("Collection Identifier is not in the NFT Catalog.")

        if acct.storage.borrow<&Offers.Storefront>(from: Offers.OffersStoragePath) == nil {
            let s <- Offers.createStorefront()
            acct.storage.save(<-s, to: Offers.OffersStoragePath)

            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{Offers.StorefrontPublic}>(Offers.OffersStoragePath),
                at: Offers.OffersPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        self.storefront = acct.storage.borrow<auth(Offers.List) &Offers.Storefront>(from: Offers.OffersStoragePath) ?? panic("storefront not found")
        self.paymentTokenType = CompositeType(paymentTokenIdentifier)!

        let paymentTokenAddress = AddressUtils.parseAddress(self.paymentTokenType)!
        let paymentTokenContractName = self.paymentTokenType.identifier.split(separator: ".")[2]
        let paymentTokenContract = getAccount(paymentTokenAddress).contracts.borrow<&{ViewResolver}>(name: paymentTokenContractName)
            ?? panic("payment token does not implement ViewResolver")
        let ftVaultData = paymentTokenContract.resolveContractView(resourceType: self.paymentTokenType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData

        if ftProviderAddress == acct.address {
            let borrowType = Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>()
            if let provider = cache.getCapabilityByType(resourceType: self.paymentTokenType, capabilityType: CapabilityType(borrowType)!) {
                self.tokenProvider = provider as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
            } else {
                self.tokenProvider = acct.capabilities.storage.issueWithType(ftVaultData.storagePath, type: borrowType) as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
                cache.addCapability(resourceType: self.paymentTokenType, cap: self.tokenProvider)
            }
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftProviderAddress)
                ?? panic("fungible token provider address is not a child of this account")

            let ftProviderCap = child.getCapability(controllerID: ftProviderControllerID, type: Type<&{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>())
                ?? panic("no ft provider found in child account")
            self.tokenProvider = ftProviderCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
        }
        assert(self.tokenProvider.check(), message: "invalid token provider")

        if nftReceiverAddress == acct.address {
            if acct.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                let contractAcct = getAccount(value.contractAddress)
                let c = contractAcct.contracts.borrow<&{ViewResolver}>(name: value.contractName)
                    ?? panic("unable to borrow contract reference to setup collection")
                let cd = c.resolveContractView(resourceType: value.nftType, viewType: Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                acct.storage.save(<- cd.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                // we do not unlink first because this does not come from the NFT Catalog.
                acct.capabilities.unpublish(value.collectionData.publicPath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(value.collectionData.storagePath),
                    at: value.collectionData.publicPath
                )
                self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            }
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child of this account")
            let nftReceiver = getAccount(nftReceiverAddress)
            self.collectionCap = nftReceiver.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }
        assert(self.collectionCap.check(), message: "invalid nft collection receiver")

        self.nftType = value.nftType
    }

    execute {
        let filter = Filter.TypeAndIDFilter(self.nftType, nftID)
        let fg = Filter.FilterGroup([filter])

        self.storefront.createOffer(
            offeredAmount: offeredAmount,
            paymentTokenType: self.paymentTokenType,
            filterGroup: fg,
            expiry: expiry,
            numAcceptable: numAcceptable,
            taker: nil,
            paymentProvider: self.tokenProvider,
            nftReceiver: self.collectionCap
        )
    }
}`

const createDapperOfferCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import OffersV2 from ${config.contractAddresses.OffersV2_Dapper}
import DapperOffersV2 from ${config.contractAddresses.DapperOffersV2}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import Resolver from ${config.contractAddresses.Resolver}
import FlowtyOffersResolver from ${config.contractAddresses.FlowtyOffersResolver}
import StringUtils from ${config.contractAddresses.StringUtils}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

transaction(
    amount: UFix64,
    royalties: {Address: UFix64},
    offerParamsString: {String: String},
    nftId: UInt64,
    collectionAddress: Address,
    collectionName: String,
    publicPathIdentifier: String,
    storagePathIdentifier: String,
    proxyAddress: Address,
    expiry: UInt64,
    nftTypeIdentifier: String
) {
    var nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let dapperOffer: auth(DapperOffersV2.Manager) &DapperOffersV2.DapperOffer
    let ducVaultRef: Capability<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>
    let resolverCapability: Capability<&{Resolver.ResolverPublic}>
    let tokenAdminCollection: Capability<auth(DapperOffersV2.ProxyManager) &DapperOffersV2.DapperOffer>

    prepare(signer: auth(Storage, Capabilities) &Account, dapper: auth(Storage, Capabilities) &Account) {
        let contractAcct = getAccount(collectionAddress)
        let borrowedContract = contractAcct.contracts.borrow<&{NonFungibleToken}>(name: collectionName) ?? panic("collection not found")
        let nftType = CompositeType(nftTypeIdentifier) ?? panic("invalid nftTypeIdentifier")

        let storagePath = StoragePath(identifier: storagePathIdentifier)!
        let publicPath = PublicPath(identifier: publicPathIdentifier)!
        if signer.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: storagePath) == nil {
            let c <- borrowedContract.createEmptyCollection(nftType: nftType)
            signer.storage.save(<-c, to: storagePath)
            signer.capabilities.unpublish(publicPath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.Receiver, NonFungibleToken.CollectionPublic}>(storagePath),
                at: publicPath
            )
        }

        self.nftReceiver = signer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath)
        if !self.nftReceiver.check() {
            signer.capabilities.unpublish(publicPath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.Receiver, NonFungibleToken.CollectionPublic}>(storagePath),
                at: publicPath
            )
            self.nftReceiver = signer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath)
        }

        let dapperOfferType = Type<@DapperOffersV2.DapperOffer>()

        // Link the DapperOffer resource
        if signer.storage.borrow<&DapperOffersV2.DapperOffer>(from: DapperOffersV2.DapperOffersStoragePath) == nil {
            let dapperOffer <- DapperOffersV2.createDapperOffer()
            signer.storage.save(<-dapperOffer, to: DapperOffersV2.DapperOffersStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{DapperOffersV2.DapperOfferPublic}>(DapperOffersV2.DapperOffersStoragePath),
                at: DapperOffersV2.DapperOffersPublicPath
            )

            let managerStoragePath = /storage/flowtyDapperOfferManager
            let cap = signer.storage.copy<Capability<auth(DapperOffersV2.Manager) &{DapperOffersV2.DapperOfferManager}>>(from: managerStoragePath)
            if cap?.check() != true {
                let managerCap = signer.capabilities.storage.issue<auth(DapperOffersV2.Manager) &{DapperOffersV2.DapperOfferManager}>(DapperOffersV2.DapperOffersStoragePath)
                signer.storage.save(managerCap, to: managerStoragePath)
            }
        }

        // DapperOfferProxyManager Setup
        let proxyStoragePath = /storage/flowtyDapperOfferProxy
        let copiedProxy = signer.storage.copy<Capability<auth(DapperOffersV2.ProxyManager) &DapperOffersV2.DapperOffer>>(from: proxyStoragePath)
        if copiedProxy?.check() == true {
            self.tokenAdminCollection = copiedProxy!
        } else {
            self.tokenAdminCollection = signer.capabilities.storage.issue<auth(DapperOffersV2.ProxyManager) &DapperOffersV2.DapperOffer>(DapperOffersV2.DapperOffersStoragePath)
            signer.storage.load<AnyStruct>(from: proxyStoragePath)
            signer.storage.save(self.tokenAdminCollection, to: proxyStoragePath)
        }

        if dapper.storage.borrow<&DapperOffersV2.DapperOffer>(from: DapperOffersV2.DapperOffersStoragePath) == nil {
            let dapperOffer <- DapperOffersV2.createDapperOffer()
            dapper.storage.save(<-dapperOffer, to: DapperOffersV2.DapperOffersStoragePath)
            dapper.capabilities.publish(
                dapper.capabilities.storage.issue<&{DapperOffersV2.DapperOfferPublic}>(DapperOffersV2.DapperOffersStoragePath),
                at: DapperOffersV2.DapperOffersPublicPath
            )

            let proxyManagerStoragePath = /storage/dapperProxyManager
            let proxyCap = dapper.capabilities.storage.issue<auth(DapperOffersV2.ProxyManager) &{DapperOffersV2.DapperOfferManager, DapperOffersV2.DapperOfferProxyManager}>(DapperOffersV2.DapperOffersStoragePath)
            dapper.storage.save(proxyCap, to: proxyManagerStoragePath)
        }

        // Setup Proxy Cancel for Dapper
        let capabilityReceiver = dapper.capabilities.get<&{DapperOffersV2.DapperOfferPublic}>(/public/DapperOffersV2).borrow()
            ?? panic("Could not borrow capability receiver reference")
        capabilityReceiver.addProxyCapability(account: signer.address, cap: self.tokenAdminCollection)

        // Get the capability to the offer creators NFT collection
        self.nftReceiver = signer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath)
        assert(self.nftReceiver.check(), message: "Missing or mis-typed collection receiver")

        self.dapperOffer = signer.storage.borrow<auth(DapperOffersV2.Manager) &DapperOffersV2.DapperOffer>(from: DapperOffersV2.DapperOffersStoragePath)
            ?? panic("Missing or mis-typed DapperOffersV2.DapperOffer")
        // Get the capability to the DUC vault

        let ducCapStoragePath = /storage/flowtyDucProvider
        let copiedDucProvider = dapper.storage.copy<Capability<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>>(from: ducCapStoragePath)
        if copiedDucProvider?.check() == true {
            self.ducVaultRef = copiedDucProvider!
        } else {
            self.ducVaultRef = dapper.capabilities.storage.issue<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>(/storage/dapperUtilityCoinVault)
            dapper.storage.save(self.ducVaultRef, to: ducCapStoragePath)
        }

        assert(self.ducVaultRef.check() != nil, message: "Missing or mis-typed DapperUtilityCoin provider")
        self.resolverCapability = FlowtyOffersResolver.getResolverCap()
    }

    execute {
        var royaltysList: [OffersV2.Royalty] = []
        for k in royalties.keys {
            royaltysList.append(OffersV2.Royalty(
                receiver: getAccount(k).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver),
                amount: royalties[k]!
            ))
        }

        let str = collectionAddress.toString()
        let typeId = StringUtils.join(["A", str.slice(from: 2, upTo: str.length), collectionName, "NFT"], ".")

        offerParamsString.insert(key: "nftId", nftId.toString())
        offerParamsString.insert(key: "resolver", FlowtyOffersResolver.ResolverType.NFT.rawValue.toString())
        offerParamsString.insert(key: "_type", "NFT")
        offerParamsString.insert(key: "typeId", typeId)

        let offerParamsUInt64: {String: UInt64} = { "expiry": expiry }

        self.dapperOffer.createOffer(
            vaultRefCapability: self.ducVaultRef,
            nftReceiverCapability: self.nftReceiver,
            nftType: CompositeType(typeId)!,
            amount: amount,
            royalties: royaltysList,
            offerParamsString: offerParamsString,
            offerParamsUFix64: {},
            offerParamsUInt64: offerParamsUInt64,
            resolverCapability: self.resolverCapability
        )
    }
}`

// DONE
const createFlowtyNonCatalogOfferCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import StringUtils from ${config.contractAddresses.StringUtils}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import Offers from ${config.contractAddresses.Offers}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import Filter from ${config.contractAddresses.Offers}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}

transaction(
    nftTypeIdentifier: String,
    nftID: UInt64,
    numAcceptable: Int,
    offeredAmount: UFix64,
    paymentTokenIdentifier: String,
    expiry: UInt64,
    nftReceiverAddress: Address,
    ftProviderAddress: Address,
    ftProviderControllerID: UInt64
) {
    let storefront: auth(Offers.List) &Offers.Storefront
    let paymentTokenType: Type
    let nftType: Type
    let tokenProvider: Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: auth(Capabilities, Storage) &Account) {
        self.nftType = CompositeType(nftTypeIdentifier) ?? panic("invalid nft type identifier")
        let collectionAddress = AddressUtils.parseAddress(self.nftType) ?? panic("no address found in nftType")
        let collectionName = nftTypeIdentifier.split(separator: ".")[2]
    
        let contractAcct = getAccount(collectionAddress)
        let nftContract = contractAcct.contracts.borrow<&{ViewResolver}>(name: collectionName) ?? panic("collection not found")
        let collectionData = (
            nftContract.resolveContractView(resourceType: self.nftType, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("collection does not implement NFTCollectionData Metadata View")
        ) as! MetadataViews.NFTCollectionData

        if acct.storage.borrow<&Offers.Storefront>(from: Offers.OffersStoragePath) == nil {
            let s <- Offers.createStorefront()
            acct.storage.save(<-s, to: Offers.OffersStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{Offers.StorefrontPublic}>(Offers.OffersStoragePath),
                at: Offers.OffersPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        self.storefront = acct.storage.borrow<auth(Offers.List) &Offers.Storefront>(from: Offers.OffersStoragePath) ?? panic("storefront not found")
        self.paymentTokenType = CompositeType(paymentTokenIdentifier)!

        let paymentTokenAddress = AddressUtils.parseAddress(self.paymentTokenType)!
        let paymentTokenContractName = self.paymentTokenType.identifier.split(separator: ".")[2]
        let paymentTokenContract = getAccount(paymentTokenAddress).contracts.borrow<&{ViewResolver}>(name: paymentTokenContractName)
            ?? panic("payment token does not implement ViewResolver")
        let ftVaultData = paymentTokenContract.resolveContractView(resourceType: self.paymentTokenType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData

        if ftProviderAddress == acct.address {
            let borrowType = Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>()
            if let provider = cache.getCapabilityByType(resourceType: self.paymentTokenType, capabilityType: CapabilityType(borrowType)!) {
                self.tokenProvider = provider as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
            } else {
                self.tokenProvider = acct.capabilities.storage.issueWithType(ftVaultData.storagePath, type: borrowType) as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
                cache.addCapability(resourceType: self.paymentTokenType, cap: self.tokenProvider)
            }
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftProviderAddress) ?? panic("fungible token provider address is not a child of this account")
            let ftProviderCap = child.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>())
                ?? panic("no ft provider found in child account")
            self.tokenProvider = ftProviderCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
        }
        assert(self.tokenProvider.check(), message: "invalid token provider")

        if nftReceiverAddress == acct.address {
            if acct.storage.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
                acct.storage.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
            }

            self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            if !self.collectionCap.check() {
                // we do not unlink first because this does not come from the NFT Catalog.
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(collectionData.storagePath),
                    at: collectionData.publicPath
                )
                self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            }
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child of this account")
            let nftReceiver = getAccount(nftReceiverAddress)
            self.collectionCap = nftReceiver.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            // We can't change child account links in any way
        }
        assert(self.collectionCap.check(), message: "invalid nft collection receiver")
    }

    execute {
        let filter = Filter.TypeAndIDFilter(self.nftType, nftID)
        let fg = Filter.FilterGroup([filter])

        self.storefront.createOffer(
            offeredAmount: offeredAmount,
            paymentTokenType: self.paymentTokenType,
            filterGroup: fg,
            expiry: expiry,
            numAcceptable: numAcceptable,
            taker: nil,
            paymentProvider: self.tokenProvider,
            nftReceiver: self.collectionCap
        )
    }
}`
