import { ReactNode } from "react"
import { FlowtyFieldInput } from "../../../../FlowtyFieldInput"

interface CreatorHubFieldInputProps {
	title: string
	name: string
	className?: string
	isOptional?: boolean
	toolTip?: ReactNode
	inputError?: boolean
	errorMessage?: string | null
}
export const CreatorHubFieldInput: React.FC<CreatorHubFieldInputProps> = ({
	name,
	title,
	className,
	isOptional = false,
	toolTip,
	inputError = false,
	errorMessage,
}) => {
	return (
		<div className='flex flex-col gap-[8px] w-full'>
			<div className='flex items-center space-x-1'>
				<div className='flex items-center gap-x-2'>
					<div className='text-white text-xs 3xl:text-lg font-bold'>
						{title}
					</div>
					{toolTip}
				</div>
				{isOptional && (
					<div className='text-[#6C757D] text-xs 3xl:text-lg font-bold'>
						{"(Optional)"}
					</div>
				)}
			</div>
			<FlowtyFieldInput
				disabled={name === "creatorWalletAddress"}
				name={name}
				className={className}
				inputError={inputError}
				errorMessage={errorMessage}
			/>
		</div>
	)
}
