/* eslint-disable @typescript-eslint/no-use-before-define */

import { Config } from "../../types"

export const getCancelOfferTxn = (
	config: Config,
	isDapper: boolean
): string => {
	if (isDapper) {
		return config.crescendo
			? cancelDapperOfferCrescendo(config)
			: cancelDapperOffer(config)
	}

	return config.crescendo ? cancelOfferCrescendo(config) : cancelOffer(config)
}

const cancelOffer = (config: Config): string => ``

const cancelDapperOffer = (config: Config): string => ``

const cancelOfferCrescendo = (
	config: Config
): string => `import Offers from ${config.contractAddresses.Offers}

transaction(offerResourceID: UInt64) {
    prepare(acct: auth(BorrowValue) &Account) {
        let storefront = acct.storage.borrow<auth(Offers.Cancel) &Offers.Storefront>(from: Offers.OffersStoragePath)
            ?? panic("storefront not found")
        storefront.cancelOffer(offerResourceID: offerResourceID)
    }
}`

const cancelDapperOfferCrescendo = (
	config: Config
): string => `import DapperOffersV2 from ${config.contractAddresses.DapperOffersV2}

transaction(offerId: UInt64) {
    prepare(signer: auth(BorrowValue) &Account) {
        let offers = signer.storage.borrow<auth(DapperOffersV2.Manager) &DapperOffersV2.DapperOffer>(from: DapperOffersV2.DapperOffersStoragePath) ?? panic("offers resource not found")
        offers.removeOffer(offerId: offerId)
    }
}`
