import { ArrowRightIcon } from "@heroicons/react/solid"
import { ChildAccountDisplay } from "flowty-common"
import { Link, useLocation } from "react-router-dom"
import placeholderImage from "../../../assets/missing-collection-image-icon.svg"

type Props = {
	childAccounts?: ChildAccountDisplay[]
}

export const ChildWallets: React.FC<Props> = ({ childAccounts }) => {
	const loc = useLocation()

	const maxDisplayNameLength = 8

	return (
		<div>
			{childAccounts && childAccounts.length >= 1 && (
				<>
					<div className='flex flex-col md:flex-row md:items-center mt-[16px] md:mt-[24px] md:justify-between gap-3 md:gap-5 flex-wrap'>
						<div className='flex flex-row items-center'>
							<span className='mr-2 text-[1.125rem] text-white font-montserrat text-nowrap font-normal'>
								Linked Accounts ({childAccounts.length})
							</span>
							<div className='bg-[#ffffffba] rounded-md px-1.5 py-0.35'>
								<p className='leading-[14px] text-[#031021] font-montserrat-bold font-black text-[0.625rem]'>
									CHILD
								</p>
							</div>
						</div>
						<a className='flex flex-row hover-filter-text' href='/user/profile'>
							<span className='text-primary font-montserrat font-[1rem] mr-2 whitespace-nowrap'>
								View All
							</span>
							<ArrowRightIcon className='w-[1.125rem] h-[1.125rem] text-primary' />
						</a>
					</div>
					<div className='flex flex-row my-2 md:my-3 gap-3 mx-auto overflow-x-auto styled-scroll-wallet cursor-pointer'>
						{childAccounts.map(child => {
							let displayName = child?.display?.name

							if (child?.display) {
								const isTruncated =
									child.display?.name.length > maxDisplayNameLength

								displayName = isTruncated
									? child.display?.name.substring(0, maxDisplayNameLength) +
									  "..."
									: displayName
							}

							return (
								<Link
									key={child.address}
									reloadDocument={loc.pathname.includes("/profile")}
									className='flex-shrink-0 bg-[#ffffff1f] border border-secondary rounded-lg my-auto flex flex-col items-center justify-center border-[#ffffff36] mb-1 visited:bg-[#ffffff33] hover:bg-[#ffffff33] hover:border p-[1rem] overflow-hidden text-center whitespace-nowrap cursor-pointer group gap'
									to={`/user/profile?walletAddresses=%5B"${child.address}"%5D`}
								>
									<img
										className='w-[2.4rem] h-[2.4rem] rounded-[0.25rem]'
										src={child.display?.thumbnail}
										alt='thumbnail'
										onError={({ currentTarget }) => {
											// eslint-disable-next-line no-param-reassign
											currentTarget.onerror = null // prevents looping
											// eslint-disable-next-line no-param-reassign
											currentTarget.src = placeholderImage
										}}
									/>

									<p className='font-montserrat text-white text-[0.625rem] font-normal group-hover:text-white leading-3'>
										{displayName}
									</p>
								</Link>
							)
						})}
					</div>
				</>
			)}
		</div>
	)
}
