import React from "react"
import { FlowtyModalListingType } from "../../FlowtyModal/types/FlowtyModalTypes"

interface ListingTypeBannerProps {
	listingType?: FlowtyModalListingType
}

export const ListingTypeBanner: React.FC<ListingTypeBannerProps> = ({
	listingType,
}) => {
	const className =
		"uppercase font-bold text-white text-[8px] md:text-sm z-10 absolute bg-gradient-to-r top-2 md:top-4 w-4/5 h-[18px] md:h-10 -left-[19px] pl-4 flex items-center"

	return (
		<>
			{listingType === "loan" || listingType === "fundLoan" ? (
				<div className={`${className} loanListingBannerGradient`}>LOAN</div>
			) : listingType === "rental" || listingType === "fundRental" ? (
				<div className={`${className} rentalListingBannerGradient`}>RENT</div>
			) : listingType?.includes("storefront") ||
			  listingType === "purchase" ||
			  listingType === "makeOffer" ? (
				<div className={`${className} buyListingBannerGradient`}>BUY NOW</div>
			) : null}
		</>
	)
}
