import { NotificationType, RentalNotificationContent } from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"

export type RentalNotificationItemsProps = RentalNotificationContent & {
	loggedUserAddress?: string
	queueMessage?: ({
		type,
		id,
	}: {
		type:
			| NotificationType.RentalFunded
			| NotificationType.RentalReturned
			| NotificationType.PrivateRental
			| NotificationType.RentalSettled
		id: string
	}) => void
}

export const RentalNotificationItems: React.FunctionComponent<
	RentalNotificationItemsProps
> = ({
	rentalFee,
	transactionID,
	imageUrl,
	timestamp,
	title,
	token,
	nftId,
	nftType,
	renterAddress,
	type,
	listingType,
	listingResourceID,
	loggedUserAddress,
}) => {
	const message = getNotificationMessage({
		amount: rentalFee,
		loggedUserAddress: loggedUserAddress,
		title,
		token,
		type,
		wallet: renterAddress,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			token={token}
			nftId={nftId}
			nftType={nftType}
			listingResourceID={listingResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType={listingType}
			type={type}
		/>
	)
}
