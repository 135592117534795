import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import React from "react"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	offerAddress: string
	offeredAmount: number
	paymentTokenType: SupportedTokens
}

export const OfferCancelledRow: React.FC<Props> = ({
	offerAddress,
	offeredAmount,
	paymentTokenType,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Offer</span> for
			</p>
			<NftPrice price={offeredAmount} token={paymentTokenType} />
			<p className='text-start'>
				<span className='font-bold text-[14px]'>cancelled</span> by{" "}
				<ProfileLink address={offerAddress} />{" "}
			</p>
		</div>
	)
}
