import { useState } from "react"
import { ReactComponent as ShareDropPageButton } from "../../../assets/solid-link.svg"
import { Tooltip } from "react-tooltip"

type ShareDropPageProps = {
	href: string
}

export const ShareDropPage: React.FC<ShareDropPageProps> = ({ href }) => {
	const [copied, setCopied] = useState(false)

	const resetCopy = () => setCopied(false)

	const handleCopyClick = () => {
		setCopied(true)
		setTimeout(resetCopy, 5000)
	}
	const tooltipCustomId = "share-drop-page"
	const renderContent = "Copy Drop Page URL"
	return (
		<>
			<Tooltip
				id={`${tooltipCustomId}-tooltip`}
				className='flex items-center'
				style={{
					backgroundColor: "#F8F9FA",
					color: "#031021",
					fontSize: "14px",
					fontWeight: 500,
					height: "33.999px",
					lineHeight: "normal",
					padding: "12px",
					pointerEvents: "auto",
					textAlign: "center",
					zIndex: 40,
				}}
				opacity={100}
				clickable={false}
				render={() => <>{renderContent}</>}
			/>
			<div
				data-tooltip-id={`${tooltipCustomId}-tooltip`}
				data-tooltip-place='top'
			>
				<button
					className='border rounded p-[8px]'
					style={{ border: "1px solid var(--Neutral-300, #495057)" }}
					onClick={() => {
						handleCopyClick()
						;(navigator as Navigator).clipboard.writeText(href)
					}}
				>
					{copied ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-5 h-5 text-primary'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75'
							/>
						</svg>
					) : (
						<ShareDropPageButton className='w-5 h-5' />
					)}
				</button>
			</div>
		</>
	)
}
