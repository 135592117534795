import { OpensearchFlowNFT } from "flowty-common"
import { Flowty } from "flowty-sdk"
import React from "react"
import { AccountSummaries, AccountSummary } from "../../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { LoanFormValues } from "../../../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"
import { DapperDisplay } from "../../common/DapperDisplay"
import { NoCatalogNFTWrapper } from "../../common/NoCatalogNFTWrapper"
import { LoanListingStep1 } from "./LoanListingStep1"
import { LoanListingStep2 } from "./LoanListingStep2"

interface LoanListingProps {
	isCatalog: boolean
	isDapper: boolean
	accountSummaries: AccountSummaries
	selectedAccount: AccountSummary
	setSelectedAccount: (account: AccountSummary) => void
	hasChildAccounts: boolean
	loanStep: number
	loanFormValues: LoanFormValues
	loanFormError: boolean
	openSearchFlowNFT: OpensearchFlowNFT | null
	flowty: Flowty
}

export const LoanListing: React.FunctionComponent<LoanListingProps> = ({
	isCatalog,
	isDapper,
	accountSummaries,
	selectedAccount,
	setSelectedAccount,
	hasChildAccounts,
	loanStep,
	loanFormValues,
	loanFormError,
	openSearchFlowNFT,
	flowty,
}) => {
	const { spotPrice, isParentDapper } = useFlowtyListingModalContext()

	return (
		<>
			{!isCatalog ? (
				<NoCatalogNFTWrapper
					text={"Loan listings are not permitted for this NFT collection"}
				/>
			) : (!hasChildAccounts && isDapper) || isParentDapper ? (
				<DapperDisplay openSearchFlowNFT={openSearchFlowNFT} />
			) : (
				<div className='w-full h-full flex flex-col justify-between'>
					{loanStep === 1 ? (
						<LoanListingStep1
							isDapper={isDapper}
							hasChildAccounts={hasChildAccounts}
							selectedAccount={selectedAccount}
							setSelectedAccount={setSelectedAccount}
							accountSummaries={accountSummaries}
							loanFormValues={loanFormValues}
							loanFormError={loanFormError}
							spotPrice={spotPrice}
							flowty={flowty}
						/>
					) : (
						<LoanListingStep2
							loanFormError={loanFormError}
							spotPrice={spotPrice}
							loanStep={loanStep}
							listingDuration={Number(loanFormValues.listingDuration)}
						/>
					)}
				</div>
			)}
		</>
	)
}
