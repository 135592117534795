import React, { useContext } from "react"
import { useFlowtyFieldInput } from "../../../Modals/Listings/shared/FieldInput/useFlowtyFieldInput"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"

export const StorefrontForm: React.FunctionComponent = () => {
	const { orderFilters, setOrderFilters } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const onChange = (
		value: number,
		fieldName: string,
		minOrMax: "min" | "max"
	): void => {
		const copyFilters = { ...orderFilters }

		if (value) {
			if (copyFilters.storefront) {
				copyFilters.storefront[fieldName] = {
					...copyFilters.storefront[fieldName],
					[minOrMax]: value,
				}
			} else {
				copyFilters.storefront = {
					[fieldName]: {
						[minOrMax]: value,
					},
				}
			}
		} else {
			if (value) {
				copyFilters?.storefront?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters.storefront[field.name].max
			} else {
				copyFilters?.storefront?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters?.storefront?.[fieldName]
			}
		}

		setOrderFilters(copyFilters)
	}

	const adjustValue = (value: number): number => value / 100

	const displayValue = (value: number): number => value * 100

	useFlowtyFieldInput()

	return (
		<div className='flex flex-col gap-[1.5rem]'>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] font-[600] text-white'>
					Purchase Price
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='purchase-price-min'
						value={orderFilters?.storefront?.["salePrice"]?.min ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						name='salePrice'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='purchase-price-max'
						value={orderFilters?.storefront?.["salePrice"]?.max ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						name='salePrice'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] font-[600] text-white text-left'>
					3rd Party FMV
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='fmv-min'
						value={
							orderFilters?.storefront?.["valuations.blended.usdValue"]?.min ??
							""
						}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						name='valuations.blended.usdValue'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='fmv-max'
						value={
							orderFilters?.storefront?.["valuations.blended.usdValue"]?.max ??
							""
						}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						name='valuations.blended.usdValue'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] font-[600] text-white text-left'>
					Discount to FMV %
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='fmv-discount-min'
						value={
							orderFilters?.storefront?.["valuationRatio"]?.min
								? displayValue(orderFilters?.storefront?.["valuationRatio"].min)
								: ""
						}
						onChange={e =>
							onChange(
								adjustValue(Number(e.target.value)),
								e.target.name || "",
								"min"
							)
						}
						name='valuationRatio'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='fmv-discount-max'
						value={
							orderFilters?.storefront?.["valuationRatio"]?.max
								? displayValue(orderFilters?.storefront?.["valuationRatio"].max)
								: ""
						}
						onChange={e =>
							onChange(
								adjustValue(Number(e.target.value)),
								e.target.name || "",
								"max"
							)
						}
						name='valuationRatio'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
		</div>
	)
}
