import { ReactComponent as FlowtyLogoMobile } from "../../../src/assets/flowty-logo-6.svg"
import { ReactComponent as FlowtyLogo } from "../../assets/flowty_logo.svg"
import { MenuIcon } from "@heroicons/react/solid"
import { cn } from "../../utils/cn"
import React from "react"

type Props = {
	setSidebarNavigation: (value: number) => void
	isMobile?: boolean
}

export const NavBarLogoAndHamburgerMenu: React.FunctionComponent<Props> = ({
	setSidebarNavigation,
	isMobile,
}): JSX.Element => {
	return (
		<div className={cn("flex items-center gap-[12px] flex-shrink-0")}>
			<button onClick={() => setSidebarNavigation(300)} role='menu'>
				<MenuIcon className='w-[32px] h-[32px]' />
			</button>
			<a href='/' className='flex items-center'>
				{isMobile ? (
					<FlowtyLogoMobile />
				) : (
					<FlowtyLogo className='mr-[16px] h-full w-28' />
				)}
			</a>
		</div>
	)
}
