import { inject, observer } from "mobx-react"
import { values } from "mobx"
import { GlobalAlertProps } from "../../../config/globalAlertConfig"
import { AuthStoreProp } from "../../../stores/AuthStore"

const GlobalAlertComponent: React.FunctionComponent<AuthStoreProp> = ({
	authStore,
}) => (
	<div className='container z-[999] fixed top-0 left-0 right-0'>
		{values(authStore?.activeGlobalAlerts)?.map(
			(globalAlert: GlobalAlertProps) => {
				const AlertComponent = globalAlert.component
				return <AlertComponent key={globalAlert.id} />
			}
		)}
	</div>
)

export const GlobalAlert = inject("authStore")(observer(GlobalAlertComponent))
