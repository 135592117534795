import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid"
import { ListingKind } from "flowty-common"
import React, { useContext, useEffect, useMemo, useState } from "react"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { sortFields } from "./SortFields"
import useBrowserAgent from "../../../hooks/useBrowserAgent"
import { cn } from "../../../util/cn"

export const SortMenu: React.FC = () => {
	const { orderFilters, setSort, sort, endpoint } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const [isOpen, setIsOpen] = useState(false)
	const selectListingTypes = Object.keys(orderFilters)
	const listingType: ListingKind | null = useMemo(() => {
		if (selectListingTypes.length === 1) {
			return selectListingTypes[0] as ListingKind
		}
		return null
	}, [selectListingTypes])

	const fields = useMemo(() => {
		if (endpoint === "user") return sortFields.profile

		if (!listingType) return sortFields.all

		return sortFields[listingType] ? sortFields[listingType] : sortFields.all
	}, [listingType])

	const { isSafari } = useBrowserAgent()

	const closeMenu = (): void => setIsOpen(false)
	useEffect(() => {
		window.addEventListener("click", closeMenu)
		return () => window.removeEventListener("click", closeMenu)
	}, [])

	return (
		<div className='relative'>
			<button
				onClick={e => {
					e.stopPropagation()
					setIsOpen(!isOpen)
				}}
				className='flex h-[2.5rem] justify-center items-center py-2 text-base rounded-r-md border border-[#ffffff1f] lg:border-none lg:rounded-md bg-[#606e7d33] hover:bg-glassFillGrayHover font-normal max-sm:px-4 px-8 w-full text-nowrap'
			>
				Sort By:
				<span className={`text-base lg:text-sm 2xl:text-base mx-1 mr-2`}>
					{fields.find(field => field.value === sort?.path)?.label ||
						"Recently Viewed"}
				</span>{" "}
				{sort?.direction === "asc" ? (
					<ArrowUpIcon className='w-[14px] h-[14px] lg:h-4 lg:w-4 2xl:h-[14px] 2xl:w-[14px]' />
				) : (
					<ArrowDownIcon className='w-[14px] h-[14px] lg:h-4 lg:w-4 2xl:h-[14px] 2xl:w-[14px]' />
				)}
			</button>

			<div
				className={cn(
					`absolute top-[42px] rounded-md overflow-hidden transition-all duration-500 ease-in-out z-10 w-full`,
					{
						"bg-[#2f343a] bg-opacity-95": isSafari,
						"bg-glassFillGray flowty-dropdown": !isSafari,
						"max-h-0 p-0 opacity-0": !isOpen,
						"max-h-96 py-3 opacity-100": isOpen,
					}
				)}
			>
				{fields?.map(field => (
					<button
						className='flex justify-between items-center px-4 py-2 cursor-pointer w-full hover:bg-slate-500 hover:bg-opacity-20 whitespace-nowrap'
						key={field.label}
						onClick={e => {
							e.stopPropagation()

							const isAlreadySortingOnThisField = field?.value === sort?.path
							const currentFieldSortDirection = isAlreadySortingOnThisField
								? sort?.direction
								: null
							let initialSort = "asc"
							switch (field.value) {
								// put any other reverse cases here
								case "valuationRatio":
									initialSort = "desc"
									break
								default:
									initialSort = "asc"
									break
							}

							const newSortDirection =
								currentFieldSortDirection === "asc"
									? "desc"
									: currentFieldSortDirection === "desc"
									? "asc"
									: initialSort

							const prefix =
								field.value === "latestBlock" ||
								field.value === "nftView.serial"
									? ""
									: undefined

							const newSort = {
								direction: newSortDirection,
								listingKind:
									field.value === "nftView.serial"
										? null
										: (listingType as "loan" | "rental"),
								path: field?.value || "",
								prefix: prefix,
							}
							setSort(newSort)
						}}
					>
						<span
							className={`text-xs mr-4 font-medium text-[#ADB5BD] ${
								sort?.path === field.value && "text-primary"
							} `}
						>
							{field.label}
						</span>
						{sort?.path === field.value ? (
							sort.direction === "asc" ? (
								<ArrowUpIcon className='w-4 h-4 fill-primary' />
							) : (
								<ArrowDownIcon className='w-4 h-4 fill-primary' />
							)
						) : null}
					</button>
				))}
				{!listingType ? (
					<div className='flex justify-center w-full'>
						<span className='text-[#ADB5BD] px-4 py-2 text-xs font-normal'>
							Select a{" "}
							<span className='font-bold text-xs text-[#ADB5BD]'>
								Listing Type
							</span>{" "}
							to see more sort options
						</span>
					</div>
				) : null}
			</div>
		</div>
	)
}
