import { FlowtyBadge } from "ds-flowty"

interface AccountTagProps {
	isMainWallet?: boolean
}

export const AccountTag: React.FunctionComponent<AccountTagProps> = ({
	isMainWallet = false,
}) => {
	return (
		<FlowtyBadge
			text={isMainWallet ? "main" : "child"}
			type={isMainWallet ? "primary" : "secondary"}
		/>
	)
}
