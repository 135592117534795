import { useEffect, useState } from "react"
import { flowty } from "../../../config/config"
import { Log } from "../../../util/Log"
import { OpensearchFlowNFT } from "flowty-common"

interface UseFlowNFTProps {
	collectionAddress: string
	collectionName: string
	resourceName: string
	nftId: string
}

export const useOpensearchNFT = ({
	collectionAddress,
	collectionName,
	resourceName,
	nftId,
}: UseFlowNFTProps): { openSearchNFT: OpensearchFlowNFT | null } => {
	const [openSearchNFT, setOpenSearchNFT] = useState<OpensearchFlowNFT | null>(
		null
	)

	useEffect(() => {
		if (!collectionAddress || !collectionName || !nftId) return

		flowty.api
			.getItem({
				contractAddress: collectionAddress,
				contractName: collectionName,
				nftID: nftId,
				resourceName: resourceName,
			})
			.then((res: OpensearchFlowNFT) => {
				setOpenSearchNFT(res)
			})
			.catch((err: Error) => Log({ err }))
	}, [collectionAddress, collectionName, nftId, openSearchNFT?.type])

	return { openSearchNFT }
}
