import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import "firebase/compat/functions"

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}

firebase.initializeApp(firebaseConfig)

export const storage = firebase.storage()
export const db = firebase.firestore()
export const auth = firebase.auth()
export const functions = firebase.functions()
export const firestore = firebase.firestore()

if (process.env.REACT_APP_USE_EMULATOR === "true") {
	console.log("Using firebase emulator")
	db.useEmulator("localhost", 8081)
	auth.useEmulator("http://localhost:9099")
	functions.useEmulator("localhost", 5001)
	firestore.useEmulator("localhost", 8081)
}

export type FirestoreUnsubscrube = () => void
export type FirestoreTimestamp = firebase.firestore.Timestamp
