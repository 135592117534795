import React, { ComponentType } from "react"
import { CreatorHubRadioButton } from "./CreatorHubRadioButton"
import {
	CollectionEditionType,
	CollectionType,
} from "../../../types/FlowtyCreatorHubTypes"

interface CreatorHubSelectProps {
	image: ComponentType<React.SVGProps<SVGSVGElement>>
	name: string
	value: CollectionType | CollectionEditionType
	description: string
	labelMapping: Record<string, string>
	pillContent?: string | null
	disabled?: boolean
	isLarge: boolean
}

export const CreatorHubRadioSelect: React.FC<CreatorHubSelectProps> = ({
	name,
	value,
	image: Image,
	description,
	labelMapping,
	pillContent,
	disabled,
	isLarge,
}) => {
	return (
		<div className='relative w-full'>
			<div
				className={`${
					disabled
						? "cursor-not-allowed opacity-40 border-[#495057]"
						: "cursor-pointer"
				} flex flex-1 flex-col border rounded-lg border-[#ADB5BD] w-full h-full ${
					!isLarge && "gap-[8px]"
				}`}
				style={{
					backdropFilter: "blur(21.03209114074707px)",
					background: disabled
						? "linear-gradient(153deg, rgba(255, 255, 255, 0.12)0%, rgba(255, 255, 255, 0.06)100%)"
						: "linear-gradient(153deg, rgba(255, 255, 255, 0.16)0%, rgba(255, 255, 255, 0.08)100%)",
				}}
			>
				<div
					className={`flex w-full ${
						isLarge ? "p-[10.67px] 3xl:p-[16px]" : "px-[10px] pt-[10px]"
					} justify-between items-center`}
				>
					<div className='flex items-center gap-2 3xl:gap-3'>
						<div
							className={`bg-[#575656]  border-[#434242] ${
								isLarge
									? "p-[5.33px] 3xl-p[8px] border-[4px]"
									: "p-1 3xl:p-[5.333px] border-[2.667px]"
							} rounded-[2.667px] 3xl:rounded-md`}
						>
							<Image
								className='w-[17.333px] h-[17.333px] 3xl:max-w-[26px] 3xl:max-h-[26px]'
								aria-label={value}
							/>
						</div>
						<div
							className={`${
								isLarge
									? "text-[14px] 3xl:text-[18px]"
									: "text-[8px] 3xl:text-xs font-semibold"
							} text-white leading-[18.667px] 3xl:leading-7`}
						>
							{labelMapping[value]}
						</div>
					</div>
					<CreatorHubRadioButton
						name={name}
						value={value}
						disabled={disabled}
					/>
				</div>
				{isLarge && (
					<hr
						className={`w-full ${disabled ? "bg-[#495057]" : "bg-[#ADB5BD]"}`}
					/>
				)}
				<div
					className={`${
						isLarge
							? "p-[10px] 3xl:p-4 gap-[10px] 3xl:gap-4"
							: "px-[10px] pb-[18px] gap-2"
					} flex flex-col text-[#ADB5BD]`}
				>
					<p
						className={`${
							isLarge
								? "leading-[13px] 3xl:leading-5 text-[12px] 3xl:text-[14px]"
								: "text-xs 3xl:text-[14px] leading-5"
						}`}
					>
						{description}
					</p>
					{pillContent && <div className='w-[100px] h-[25px]'></div>}
				</div>
			</div>
			{pillContent && (
				<div
					className={`absolute ${
						isLarge
							? "bottom-[12px] left-[12px] 3xl:bottom-[15px] 3xl:left-[15px]"
							: "bottom-[10px] left-[10px] 3xl:bottom-[10px] 3xl:left-[10px]"
					} flex text-gray-100 justify-start bg-[#757575] w-fit border border-[#757575] text-[10px] 3xl:text-[12px] rounded-[10.667px] 3xl:rounded-[16px] ${
						isLarge
							? "py-[1.333px] 3xl:py-[2px] px-[6.667px] 3xl:px-[10px]"
							: "py-[2px] px-[10px]"
					} leading-[13.333px] 3xl:leading-[20px] opacity-100`}
				>
					{pillContent}
				</div>
			)}
		</div>
	)
}
