import { FCLTransactionResult } from "flowty-common"
import { Config } from "../../types"
import { RepayLoanRequest } from "./RepayLoanTypes"
import { getRepayLoanTxn } from "./transactions"
import { sendMutation } from "../../utils/Utils"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class RepayLoanService {
	config: Config

	repayLoan = async ({
		fundItemID,
		address,
		token,
		ftPrivatePathIdentifier,
		ftProviderAddress,
		txAvailableCallback,
	}: RepayLoanRequest): Promise<FCLTransactionResult> => {
		const txContent = getRepayLoanTxn(this.config, token)

		const ftProviderArg = this.config.crescendo
			? fcl.arg(ftPrivatePathIdentifier || "0", t.UInt64)
			: fcl.arg(ftPrivatePathIdentifier, t.String)

		const txArguments = [
			fcl.arg(fundItemID.toString(), t.UInt64),
			fcl.arg(address, t.Address),
			fcl.arg(ftProviderAddress, t.Address),
			ftProviderArg,
		]

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			throw e
		}
	}

	constructor(config: Config) {
		this.config = config
	}
}
