import { ProfileLink } from "../common/ProfileLink"

interface Props {
	listedBy: string
}

export const LoanDelistedRow: React.FunctionComponent<Props> = ({
	listedBy,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Delisted</span> by{" "}
				<ProfileLink address={listedBy} />
			</p>
		</div>
	)
}
