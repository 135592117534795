import { Trait } from "flowty-common"

interface TraitBox {
	trait: Trait
}

export const Traitbox: React.FC<TraitBox> = ({ trait }) => {
	return (
		<div className='rounded-[5px] py-3 px-4 bg-[#ffffff0d] font-montserrat text-white w-full 2xl:w-[165px] max-w-[300px]'>
			<div className='flex flex-col gap-2 shrink-0'>
				<div className='text-[0.875rem] truncate capitalize font-[700]'>
					{trait.name}
				</div>
				<div className='text-[0.875rem] truncate capitalize font-[500]'>
					{trait.value}
				</div>
			</div>
		</div>
	)
}
