type TwitterShareButtonProps = {
	href: string
}

export const TwitterShareButton: React.FC<TwitterShareButtonProps> = ({
	href,
}) => {
	return (
		<a
			className='bg-sky-400 rounded-md px-6 py-2 hover:bg-sky-500 group'
			target='_blank'
			rel='noreferrer'
			href={href}
		>
			<div className='flex justify-center items-center text-white group-hover:opacity-70 px-1'>
				<p className='font-bold text-base mx-1'>Share</p>
				<svg
					className='footer-social-logos filter-white h-7 w-7 '
					width='34'
					height='28'
					viewBox='0 0 34 28'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M32.6363 1.25C31.2797 2.20693 29.7776 2.93882 28.188 3.4175C27.3348 2.43648 26.2009 1.74115 24.9396 1.42556C23.6784 1.10998 22.3506 1.18936 21.1359 1.65298C19.9213 2.11659 18.8783 2.94207 18.148 4.01777C17.4178 5.09346 17.0356 6.36748 17.053 7.6675V9.08417C14.5634 9.14872 12.0965 8.59657 9.87191 7.47688C7.64737 6.3572 5.73427 4.70474 4.30298 2.66667C4.30298 2.66667 -1.36368 15.4167 11.3863 21.0833C8.46873 23.0638 4.99312 24.0568 1.46965 23.9167C14.2196 31 29.803 23.9167 29.803 7.625C29.8017 7.23039 29.7637 6.83676 29.6896 6.44917C31.1355 5.02328 32.1558 3.22301 32.6363 1.25Z'
						fill='white'
						stroke='white'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
		</a>
	)
}
