import { Order } from "flowty-common"
import { FlowtyModalCarousel } from "../../../../../FlowtyModalCarousel"
import React from "react"

interface MultiListingViewProps {
	listings: Order[]
	selectedListing?: Order | null
	updateSelectedOrder: (order: Order) => void
}

export const MultiListingView: React.FunctionComponent<
	MultiListingViewProps
> = ({ listings, selectedListing, updateSelectedOrder }) => {
	return (
		<FlowtyModalCarousel
			orders={listings}
			selectedOrder={selectedListing}
			updateSelectedOrder={updateSelectedOrder}
		/>
	)
}
