/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlowtyFilterMenu } from "ds-flowty"
import { IEventType, nftTypeAndIdToLocationData } from "flowty-common"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../assets/media/loadingArrow.svg"
import { StorefrontActivityFilter } from "../components/Activity/Filters/StorefrontActivityFilter"
import { ScrollToLoadMsg } from "../components/Loaders/ScrollToLoadMsg"
import { SortDirection } from "../components/MarketPlace/SortButton/shared"
import SortableTable, { SortColumnOrNull } from "../components/SortableTable"
import { platformStorefrontActivityFields } from "../components/SortableTable/PlatformActivity/platformStorefrontActivityFields"
import { StorefrontEventType } from "../components/UserProfile/Tables/Storefront/AllActivity/types"
import useinfiniteScroll from "../hooks/infiniteScroll"
import { subscribeToActivity } from "../services/firestore/ActivityService"
import { actions as Mixpanel } from "../util/Mixpanel"
import { defaultNftPageSize } from "../util/settings"
import { ActivityLayout } from "./ActivityLayout"

export const StorefrontActivityScreen: React.FunctionComponent = () => {
	const [nftPage, setNftPage] = useState(1)
	const navigate = useNavigate()

	const [allMakerDocs, setAllMakerDocs] = useState<Array<any>>([])
	const [filterBy, setFilterBy] = useState<any>({
		min: "",
		path: "",
	})

	const [isLoading, setIsLoading] = useState(false)

	const [showFilter, setShowFilter] = useState(true)
	const [sort, setSort] = useState<SortColumnOrNull>({
		column: "blockTimestamp",
		order: SortDirection.Descending,
	})

	useEffect(() => {
		setNftPage(1)
	}, [sort, filterBy])

	const setDocs = (docs: any[]): void => {
		setAllMakerDocs(docs)
	}

	useEffect(
		() =>
			subscribeToActivity({
				addressPath: "data.storefrontAddress",
				eventCollection: "storefrontEvents",
				filterActivityBy: filterBy,
				limit: defaultNftPageSize * nftPage,
				listingCollection: "storefrontEvents",
				makerCb: docs => setDocs(docs),
				sort,
			}),
		[nftPage, filterBy, sort]
	)

	const allDocs: Array<any> = useMemo(
		() =>
			allMakerDocs.map(doc => {
				return doc
			}),
		[allMakerDocs]
	)

	const belowTableRef = useinfiniteScroll(
		{
			isLoading: false,
			onInfiniteScroll() {
				setTimeout(() => {
					setIsLoading(false)
				}, 2000)
				if (nftPage * defaultNftPageSize === allDocs.length) {
					setNftPage(nftPage + 1)
					setIsLoading(true)
				}
				Mixpanel.track("Inifinite Scroll Global Rentals Page")
			},
		},
		[nftPage, allDocs]
	)

	useEffect(() => {
		Mixpanel.track("Global Storefront Page Visited")
	}, [])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Global Storefront Page Filtered`, {
				filter: filterBy,
			})
		}
	}, [filterBy.path])

	const storefrontFilterOptions = [
		{ label: "All", value: "" },
		{ label: "Purchased", value: IEventType.StorefrontPurchased.valueOf() },
		{ label: "Listed", value: IEventType.StorefrontListed.valueOf() },
		{ label: "Delisted", value: IEventType.StorefrontDelisted.valueOf() },
		{
			label: "Offer Created",
			value: IEventType.StorefrontOfferCreated.valueOf(),
		},
		{
			label: "Offer Accepted",
			value: IEventType.StorefrontOfferAccepted.valueOf(),
		},
		{
			label: "Offer Cancelled",
			value: IEventType.StorefrontOfferCancelled.valueOf(),
		},
	]

	const [selectedFilter, setSelectedFilter] = useState(
		storefrontFilterOptions[0].value
	)

	const updateSelectedFilter = (value: string): void => {
		setSelectedFilter(value)
		setFilterBy({
			max: "",
			min: value as StorefrontEventType,
			path: "state",
		})
	}

	return (
		<ActivityLayout>
			<div>
				<StorefrontActivityFilter
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>
				<div className='flex mb-[20px]'>
					<FlowtyFilterMenu
						fields={storefrontFilterOptions}
						selectedField={selectedFilter}
						updateSelectedField={updateSelectedFilter}
					/>
				</div>

				<div className='line-separator mb-3 md:mb-4 bg-gradient-to-r from-darkBg via-secondary to-darkBg' />

				<SortableTable
					onClickRow={({ e, item }) => {
						const event = e as any

						let buyer
						let seller

						if (item.type.toLowerCase().includes("offer")) {
							buyer = item.data?.storefrontAddress
							seller =
								typeof item?.data?.taker === "object" ? "-" : item?.data?.taker
						} else {
							buyer = item.data?.buyer
							seller = item.data?.storefrontAddress
						}

						if (event.target.classList[0] === "buyer") {
							if (buyer) {
								return navigate(`/user/${buyer}`)
							}
						} else if (event.target.classList[0] === "seller") {
							if (seller) {
								return navigate(`/user/${seller}`)
							}
						} else {
							const nftID =
								item.data?.nftID || item?.data?.typeAndIDOffer?.nftID || null

							if (!nftID) {
								return
							}

							if (
								!item?.additionalData?.card?.collectionAddress ||
								!item?.additionalData?.card?.collectionName
							) {
								return
							}

							const nftType =
								item.data?.nftType || item?.data?.typeAndIDOffer?.nftType || ""
							const locationData = nftTypeAndIdToLocationData(
								nftType,
								nftID.toString()
							)

							const url = nftID
								? `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
								: `/collection/${item?.additionalData?.card?.collectionAddress}/${item?.additionalData?.card?.collectionName}`
							return navigate(url)
						}
					}}
					fields={platformStorefrontActivityFields(navigate)}
					items={allDocs}
					onSort={setSort}
					initialSortConfig={sort}
					stickyHeaders
					composeRowHref={item => {
						const nftID =
							item.data?.nftID || item?.data?.typeAndIDOffer?.nftID || ""
						const nftType =
							item.data?.nftType || item?.data?.typeAndIDOffer?.nftType || ""
						const locationData =
							nftType && nftID
								? nftTypeAndIdToLocationData(nftType, nftID)
								: null

						return locationData
							? `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
							: `/collection/${item?.additionalData?.card?.collectionAddress}/${item?.additionalData?.card?.collectionName}`
					}}
				/>
				<div ref={belowTableRef}>
					{isLoading ? (
						<div className='h-10 w-full flex justify-center items-center'>
							<h2 className='justify-center items-center flex flex-row animate-pulse'>
								<RefreshArrow />
								Loading...
							</h2>
						</div>
					) : (
						nftPage * defaultNftPageSize === allDocs.length && (
							<ScrollToLoadMsg />
						)
					)}
				</div>
			</div>
		</ActivityLayout>
	)
}
