import React from "react"
import { cn } from "../../utils/cn"
import { ReactComponent as Bell } from "./assets/bell.svg"
import { ReactComponent as Pulse } from "./assets/pulse.svg"
export interface NotificationButtonProps {
	onClick: () => void
	isActive: boolean
	isPulsing?: boolean
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

const NotificationButton: React.FunctionComponent<NotificationButtonProps> = ({
	onClick,
	isActive,
	isPulsing,
	onMouseEnter,
	onMouseLeave,
}) => {
	return (
		<button
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			className={cn(
				"rounded-[6px] cursor-pointer border-2 border-neutral-300 flex p-[8px] justify-center items-center gap-[10px] flex-shrink-0 shadow-[0px_4px_18px_0px_rgba(0, 0, 0, 0.25)] hover:bg-dropStatusIndicatorBackground",
				{
					"bg-dropStatusIndicatorBackground": isActive,
				}
			)}
		>
			<div className='relative'>
				<Bell className='w-[24px] h-[24px] md:w-[28px] md:h-[28px]' />
				{isPulsing ? (
					<div className='absolute -top-1 -right-0' data-testid='pulse'>
						<Pulse className='animate-pulse' />
					</div>
				) : null}
			</div>
		</button>
	)
}

export default NotificationButton
