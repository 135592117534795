import { Config } from "../types"

export const getBalanceForAvailableProvidersScript = (config: Config): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _balanceForAvailableProvidersCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _balanceForAvailableProvidersScript(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _balanceForAvailableProvidersCrescendo = (
	config: Config
): string => `import FlowtyUtils from ${config.contractAddresses.NFTStorefrontV2}

access(all) fun main(address: Address, identifiers: [String]):  {String: UFix64}{
  let allowedTokens = FlowtyUtils.getSupportedTokens()
  let cache: {Type: Bool} = {}
  for a in allowedTokens {
    cache[a] = true
  }

  let foundTypes: {String: UFix64} = {}

  for id in identifiers {
    let composite: Type = CompositeType(id)!
    if cache[composite] == nil {
      continue
    }

    let balance = FlowtyUtils.getTokenBalance(address: address, vaultType: composite)
    foundTypes[id] = balance
  }

  return foundTypes
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _balanceForAvailableProvidersScript = (
	config: Config
): string => `import FlowtyUtils from ${config.contractAddresses.NFTStorefrontV2}

pub fun main(address: Address, identifiers: [String]):  {String: UFix64}{
  let allowedTokens = FlowtyUtils.getSupportedTokens()
  let cache: {Type: Bool} = {}
  for a in allowedTokens {
    cache[a] = true
  }

  let foundTypes: {String: UFix64} = {}
  
  for id in identifiers {
    let composite: Type = CompositeType(id)!
    if cache[composite] == nil {
      continue
    }

    let balance = FlowtyUtils.getTokenBalance(address: address, vaultType: composite)
    foundTypes[id] = balance
  }
  
  return foundTypes
}`
