import { RecentBigSale } from "./RecentBigSale/RecentBigSale"
import { ReactComponent as LongArrow } from "./SVGs/Arrow.svg"
import { ReactComponent as BasketballJersey } from "./SVGs/basketball-jersey.svg"
import { ReactComponent as RaresIcon } from "./SVGs/diamond-trait.svg"
import { TopSalesDesktop } from "./TopSales/TopSalesDesktop"
import { TopSalesMobile } from "./TopSales/TopSalesMobile"
import { RecentBigSaleResponse, TopAndRecentSalesResponse } from "./types"

export interface SaleItem {
	player: string
	serial: string
	series: string
	salePrice: string
	buyer: string
	purchasedOn: string
	image: string
	assetDetailUrl: string
	buyerUrl: string
}

export interface Attribute {
	imgUrl: string
	name: string
}

export interface BigSaleItem {
	videoUrl: string
	buyer: string
	buyerUrl: string
	collectionImage: string
	salePrice: string
	hashtag: string
	attributes: Attribute[]
}

interface TopSalesProps {
	topSalesData: TopAndRecentSalesResponse["data"] | null
	recentBigSaleData: RecentBigSaleResponse["data"] | null
	isLoading: boolean
}

export const LandingTopSales: React.FC<TopSalesProps> = ({
	topSalesData,
	isLoading,
	recentBigSaleData,
}) => {
	return (
		<div className='container pt-[60px] 2xl:max-w-[1920px] mx-auto'>
			<div className='grid 2xl:grid-cols-2 gap-[26px] 2xl:gap-[36px] my-auto mx-auto'>
				<div>
					<RecentBigSale saleItem={recentBigSaleData} isLoading={isLoading} />
					<div className='w-full overflow-x-scroll mt-[26px] lg:mt-[32px]'>
						<a
							href='https://www.flowty.io/collection/0x0b2a3299cc857e29/TopShot'
							className='flex flex-row items-center justify-start gap-x-[12px]'
						>
							<span className='text-white font-montserrat text-[21px] sm:text-[26px] font-bold leading-normal whitespace-nowrap'>
								Explore NBA Topshot
							</span>
							<LongArrow />
						</a>
						<div className='flex flex-col md:flex-row gap-[13px] mt-[12px] lg:mt-[32px] 2xl:justify-between items-stretch'>
							<a
								href='https://www.flowty.io/collection/0x0b2a3299cc857e29/TopShot?collectionFilters=%7B%220x0b2a3299cc857e29.TopShot%22%3A%7B%22%231+Serial%22%3A%7B%22True%22%3Atrue%7D%7D%7D'
								className='p-[7.2px] flex flex-row items-center justify-center gap-[10px] rounded-[5px] customCollectionStyle min-h-[81px] lg:min-h-[75px] w-full'
							>
								<div className='px-[23.4px] py-[25.2px] flex flex-col items-center justify-center bg-[#04070B] rounded-[5px]'>
									<span className='text-[#DEE2E6] text-[24px] font-montserrat font-black leading-[10px]'>
										#1
									</span>
								</div>
								<div className='flex flex-col w-full gap-[8px] md:gap-[10px] justify-center'>
									<span className='text-[#CED4DA] text-[14px] font-montserrat font-black leading-[10px]'>
										Serial
									</span>
									<span className='text-[#A4A4A4] text-[11px] font-montserrat font-normal leading-normal'>
										View all #1 Top Shot moments
									</span>
								</div>
							</a>
							<a
								href='https://www.flowty.io/collection/0x0b2a3299cc857e29/TopShot?collectionFilters=%7B%220x0b2a3299cc857e29.TopShot%22%3A%7B%22Jersey+Match%22%3A%7B%22True%22%3Atrue%7D%7D%7D'
								className='p-[7.2px] flex flex-row items-center justify-center gap-[10px] rounded-[5px] customCollectionStyle min-h-[81px] lg:min-h-[75px] w-full'
							>
								<div className='px-[20px] py-[13px] flex flex-col items-center justify-center bg-[#04070B] rounded-[5px]'>
									<BasketballJersey className='w-[34px] h-[34px]' />
								</div>
								<div className='flex flex-col w-full gap-[8px] md:gap-[10px] justify-center'>
									<span className='text-[#CED4DA] text-[14px] font-montserrat font-black leading-[10px]'>
										Jersey Match
									</span>
									<span className='text-[#A4A4A4] text-[11px] font-montserrat font-normal leading-normal'>
										View all jersey serial Top Shot moments
									</span>
								</div>
							</a>
							<a
								href='https://www.flowty.io/collection/0x0b2a3299cc857e29/TopShot?collectionFilters=%7B%220x0b2a3299cc857e29.TopShot%22%3A%7B%22Tier%22%3A%7B%22Rare%22%3Atrue%7D%7D%7D'
								className='p-[7.2px] flex flex-row items-center justify-center gap-[10px] rounded-[5px] customCollectionStyle min-h-[81px] lg:min-h-[75px] w-full'
							>
								<div className='px-[20px] py-[14px] flex flex-col items-center justify-center bg-[#04070B] rounded-[5px]'>
									<RaresIcon className='w-[32px] h-[32px]' />
								</div>
								<div className='flex flex-col w-full gap-[8px] md:gap-[10px] justify-center'>
									<span className='text-[#CED4DA] text-[14px] font-montserrat font-black leading-[10px]'>
										Rares
									</span>
									<span className='text-[#A4A4A4] text-[11px] font-montserrat font-normal leading-normal'>
										View all rare tier Top Shot moments
									</span>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div className='overflow-x-auto flex flex-col'>
					<h4 className='text-white font-montserrat text-[26px] font-bold leading-normal'>
						Recent Top Sales
					</h4>
					<div className='hidden 2xl:block mt-[12px] lg:mt-[26px] 2xl:mt-[4px]'>
						<TopSalesDesktop sales={topSalesData} isLoading={isLoading} />
					</div>
					<div className='block 2xl:hidden mt-[12px]'>
						<TopSalesMobile sales={topSalesData} isLoading={isLoading} />
					</div>
				</div>
			</div>
		</div>
	)
}
