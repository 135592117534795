import { useState } from "react"
import { PromotionalMessage } from "../../PromotionalMessage/PromotionalMessage"

import { OngoingPromotionButton } from "./OngoingPromotionButton"
import { Promotion } from "../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"

interface OngoingPromotionProps {
	promotion: Promotion
}

export const OngoingPromotions: React.FC<OngoingPromotionProps> = ({
	promotion,
}) => {
	const [isVisible, setIsVisible] = useState(false)

	const handleToggleVisibility = (): void => {
		setIsVisible(prevState => !prevState)
	}

	return (
		<>
			<OngoingPromotionButton onClick={handleToggleVisibility} />
			<PromotionalMessage
				promotionId={promotion?.promotionId}
				title={promotion?.title}
				message={promotion?.message}
				primaryButton={promotion?.primaryButton}
				secondaryButton={promotion?.secondaryButton}
				image={promotion?.image}
				handleClose={handleToggleVisibility}
				isVisible={isVisible}
			/>
		</>
	)
}
