import { DropPageComponent } from "./DropPageComponent"
import { PreviewBanner } from "./PreviewBanner/PreviewBanner"
import { PreviewPageTab } from "./PreviewPageTab/PreviewPageTab"

export const DropPagePreview: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col gap-3 2xl:gap-6'>
			<PreviewBanner />
			<PreviewPageTab type='DROP' />
			<div className='w-full'>
				<DropPageComponent />
			</div>
		</div>
	)
}
