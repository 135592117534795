/* eslint-disable jsx-a11y/anchor-is-valid */
import { FlowNFTContract } from "flowty-common"
import NoResults from "../../assets/NoResults"
import { storeRecentSearchResult } from "../../util/searchHistory"
import SearchResult from "../SearchResult"

interface PopularSearchesProps {
	searchResults: FlowNFTContract[]
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

export const PopularSearches: React.FunctionComponent<PopularSearchesProps> = ({
	searchResults,
	onMouseEnter,
	onMouseLeave,
}) => {
	return (
		<div
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className='flex rounded-[6px] searchResultsBackground flex-col p-[4px] gap-[14px] items-end'
		>
			<div className='flex flex-col items-center justify-center w-full'>
				<div className='pb-[8px] pt-[12px]'>
					<NoResults />
				</div>
				<span className='text-[12px] pt-2 font-montserrat font-[400] text-[#ADB5BD]'>
					You can only search for NFT collections
				</span>
			</div>
			<div className='gradient-bg-search w-full h-[1px] my-[4px]' />
			<div className='flex flex-col items-start justify-center w-full'>
				<div className='flex px-[12px] pb-[12px]'>
					<span className='text-[#ADB5BD] text-[14px]'>Popular Searches</span>
				</div>

				<div className='overflow-auto max-h-[300px] w-full'>
					{searchResults.map(result => (
						<a
							key={result.address}
							onClick={() => storeRecentSearchResult(result)}
							href={
								!process.env.IS_STORYBOOK
									? `/collection/${result.address}/${result?.name}`
									: "#"
							}
						>
							<SearchResult result={result} />
						</a>
					))}
				</div>
			</div>
		</div>
	)
}
