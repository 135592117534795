import format from "date-fns/format"
import {
	OpenSearchListingAvailableData,
	SupportedTokens,
	calcAPRInterest,
} from "flowty-common"
import numeral from "numeral"
import { getDurationPeriod } from "../../../../../utils/GlobalUtils"
import { NftPrice } from "../../../common/NftPrice"
import { useFlowtyPurchaseModalContext } from "../../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"

interface SingleFundListingViewProps {
	listing: OpenSearchListingAvailableData
}

export const SingleFundListingView = ({
	listing,
}: SingleFundListingViewProps) => {
	const { spotPrice } = useFlowtyPurchaseModalContext()
	return (
		<div className='w-full flex flex-col gap-3 justify-between mt-4'>
			<div className='w-full flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Loan Amount</p>
				<NftPrice
					token={listing.paymentTokenName as SupportedTokens}
					price={listing?.derivations?.calculatedValues?.marketplaceAmount || 0}
					spotPriceValue={
						spotPrice
							? spotPrice.value *
							  Number(
									listing?.derivations?.calculatedValues?.marketplaceAmount
							  )
							: undefined
					}
				/>
			</div>
			<div className='w-full flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Repayment Amount</p>
				<NftPrice
					token={listing.paymentTokenName as SupportedTokens}
					price={listing?.derivations?.calculatedValues?.totalRepayment || 0}
					spotPriceValue={
						spotPrice
							? spotPrice.value *
							  Number(listing?.derivations?.calculatedValues?.totalRepayment)
							: undefined
					}
				/>
			</div>
			<div className='w-full flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Loan Rate</p>
				<span className='text-base text-white font-bold'>
					{(
						(listing?.derivations?.calculatedValues?.periodicInterest ?? 0) *
						100
					).toFixed(2)}
					%
				</span>
			</div>
			<div className='w-full flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>APR Rate</p>
				<span className='text-base text-white font-bold'>
					{numeral(
						calcAPRInterest(
							listing
								? Number(
										listing?.derivations?.calculatedValues?.periodicInterest
								  )
								: 0,
							listing ? Number(listing.term) : 0
						)
					).format("0,0.00")}
					%
				</span>
			</div>
			<div className='w-full flex justify-between'>
				<p className='font-[400] text-[14px] text-white'>Duration</p>
				<span className='text-base text-white font-bold'>
					{getDurationPeriod(Number(listing.term))}
				</span>
			</div>
			<div className='w-full flex justify-between'>
				{listing?.valuations && (
					<>
						<p className='font-[400] text-[14px] text-white'>Blended LTV</p>
						<span className='text-base text-white font-bold'>
							{numeral(
								listing.valuations?.aggregate?.loanToValueRatio * 100
							).format("0,0.0")}
							%
						</span>
					</>
				)}
			</div>
		</div>
	)
}
