import {
	OfferCreated,
	OpensearchStorefrontAvailableData,
	StorefrontV2ListingAvailable,
} from "flowty-common"
import React from "react"
import { ValuationData } from "../../../../../Types/GlobalTypes"
import {
	getSourceFromSourceList,
	truncateNumber,
} from "../../../../../utils/GlobalUtils"
import { NftCardFMVTooltip } from "../../NftCardFMVTooltip/NftCardFMVTooltip"
import { NftPrice } from "../../NftPrice"
import { BestOfferDisplay } from "../BestOfferDisplay/BestOfferDisplay"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"

interface StorefrontOrderDisplayProps {
	dapperOffer?: OfferCreated | null
	nonCustodialOffer?: OfferCreated | null
	order: OpensearchStorefrontAvailableData | StorefrontV2ListingAvailable
	isLoading?: boolean
	valuationData: ValuationData
	isLoadingValuation?: boolean
}

export const StorefrontOrderDisplay: React.FC<StorefrontOrderDisplayProps> = ({
	dapperOffer,
	isLoading,
	nonCustodialOffer,
	order,
	valuationData,
	isLoadingValuation,
}) => {
	const valuationSource = getSourceFromSourceList(valuationData.source)

	return (
		<div className='h-full flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-center border-gradient mb-3'>
						<p className='text-white text-[14px] leading-6 mb-2 font-normal'>
							Purchase Price
						</p>
						<span className='text-neutral-600 leading-6 text-bold mb-2'>
							{order?.amount || order?.salePrice ? (
								<NftPrice
									price={order?.amount || order?.salePrice}
									token={order?.paymentTokenName}
									toolTipCustomId='purchase-price-tooltip'
								/>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						{isLoadingValuation ? (
							<div className='w-[87px] h-[56px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						) : (
							<>
								{valuationData?.usdValue && valuationData.usdValue > 0 ? (
									<div className='flex flex-col justify-start items-start h-24 relative group'>
										<div className='text-neutral-900 text-[12px] font-montserrat font-normal'>
											3rd Party FMV
										</div>
										<div className='self-center text-end py-1 w-full'>
											<span className='text-neutral-600 font-medium text-base font-montserrat w-full flex justify-start'>
												$
												{truncateNumber(valuationData.usdValue, {
													shouldUseDecimals: true,
												})}
											</span>
										</div>
										<NftCardFMVTooltip valuationSource={valuationSource} />
									</div>
								) : (
									<div />
								)}
							</>
						)}

						<BestOfferDisplay
							dapperOffer={dapperOffer}
							nonCustodialOffer={nonCustodialOffer}
						/>
					</div>
				</>
			)}
		</div>
	)
}
