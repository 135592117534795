import { FlowtyClaimableBadge } from "ds-flowty"
import { getImageURL, nftTypeAndIdToLocationData } from "flowty-common"
import { LostAndFoundNFTTicketData } from "flowty-sdk"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Tooltip } from "react-tooltip"
import noImage from "../../../assets/media/image_not_available.svg"
import { ReactComponent as VerifiedCheck } from "../../../assets/media/verifiedCheck.svg"
import { addFallbackImage } from "../../../util/images"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import FallbackImage from "../FallbackImage"

interface NftCardProps {
	nft: LostAndFoundNFTTicketData
	handleClick: (
		_:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.KeyboardEvent<HTMLDivElement>
	) => void
	isCatalog: boolean
	isLoading: boolean
}

export const NftCardLostAndFound: React.FunctionComponent<NftCardProps> = ({
	nft,
	handleClick,
	isLoading,
	isCatalog,
}) => {
	const [isMp4, setIsMp4] = useState<boolean | null>(null)
	const [onVideoError, setOnVideoError] = useState<boolean>(false)
	const assetUrl = getImageURL(nft?.thumbnail ?? noImage)
	const isCollectionRoute = window.location.pathname.includes("/collection")

	const { isFilterHidden } = useOpenSearchContext()

	const {
		contract: { address, name },
		resourceName,
		nftID,
	} = nftTypeAndIdToLocationData(nft.typeIdentifier ?? "", nft.nftID ?? "")

	const renderImage = useMemo(() => {
		if (!isMp4) {
			return (
				<img
					src={assetUrl}
					onError={() => {
						setIsMp4(true)
					}}
					alt={`${nft?.name} asset`}
					className='object-cover aspect-square w-full'
				/>
			)
		}

		if (onVideoError) {
			return (
				<FallbackImage
					collectionAddress={nft?.collectionAddress || ""}
					collectionName={nft?.collectionName || ""}
					onError={e => {
						addFallbackImage(e)
						setIsMp4(true)
					}}
					className='object-cover aspect-square w-[152px] sm:w-[304px]'
				/>
			)
		}

		if (isMp4) {
			return (
				<video
					muted
					loop
					playsInline
					preload='auto'
					autoPlay={false}
					className='object-cover aspect-square w-[152px] sm:w-[304px]'
					onMouseEnter={event => {
						// eslint-disable-next-line xss/no-mixed-html
						const videoElement = event.target as HTMLMediaElement
						videoElement.play()
					}}
					onMouseLeave={event => {
						// eslint-disable-next-line xss/no-mixed-html
						const videoElement = event.target as HTMLMediaElement
						videoElement.pause()
						videoElement.currentTime = 0
					}}
					onError={() => {
						setOnVideoError(true)
					}}
				>
					<source type='video/mp4' src={assetUrl}></source>
				</video>
			)
		}
	}, [
		onVideoError,
		isMp4,
		addFallbackImage,
		setIsMp4,
		setOnVideoError,
		assetUrl,
	])

	const titleRef = useRef<HTMLParagraphElement>(null)

	const [titleNumberLines, setTitleNumberLines] = useState(0)

	useEffect(() => {
		const checkNumberOfLines = (): void => {
			const node = titleRef.current
			if (node) {
				const computedStyle = window.getComputedStyle(node)
				const lineHeight = parseFloat(computedStyle.lineHeight)
				const numberOfLines = Math.ceil(node.scrollHeight / lineHeight)
				setTitleNumberLines(numberOfLines)
			}
		}
		checkNumberOfLines()
		window.addEventListener("resize", checkNumberOfLines)
		return () => window.removeEventListener("resize", checkNumberOfLines)
	}, [nft, isFilterHidden])

	return (
		<a href={`/asset/${address}/${name}/${resourceName}/${nftID}`}>
			<div
				onClick={event => handleClick(event)}
				onKeyDown={event => {
					if (event.key === "Enter") {
						handleClick(event)
					}
				}}
				data-testid='nft-card'
				className={`actionNftCard relative self-center justify-self-center flex flex-col p-[16px] rounded-lg nft-card-shadow w-auto max-sm:w-full
			`}
				style={{ maxWidth: "500px", minWidth: "240px" }}
			>
				<div className='sm:hover:text-white sm:peer-hover:text-white '>
					<div className='flex sm:flex-col-reverse mb-2 sm:mb-1'>
						<div className='grid grid-cols-3 sm:grid-cols-1 max-sm:w-full'>
							{isLoading ? (
								<>
									<div
										className={`hidden sm:flex cursor-wait my-[0.7rem] bg-gray-400 justify-center items-center animate-pulse object-cover aspect-square w-[152px] sm:w-full h-[304px]`}
									></div>
									<div className='sm:hidden cursor-wait rounded bg-gray-400 flex justify-center items-center animate-pulse'></div>
								</>
							) : (
								<div className='relative isolate flex justify-center'>
									{renderImage}
								</div>
							)}
							{isLoading ? (
								<div className='flex flex-col sm:mb-2 pl-3 sm:pl-0 col-span-2 sm:order-first'>
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait h-6' />
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
								</div>
							) : (
								<div className='flex flex-col pl-3 sm:pl-0 col-span-2 sm:order-first'>
									<div className='flex flex-row relative'>
										<div className={`max-w-[80%]`}>
											<p
												className={`font-montserrat font-extrabold uppercase text-[1.125rem] ${
													titleNumberLines > 3 && "h-[6rem] custom-truncate"
												}`}
												ref={titleRef}
											>
												{nft?.name}
											</p>
										</div>
										<div className='flex flex-col items-center justify-center space-y-2 flex-shrink-0 absolute top-1 right-0'>
											<div className='flex flex-row sm:flex-col justify-center items-center gap-1'>
												{isCatalog && !isCollectionRoute && (
													<>
														<Tooltip
															id={`${nft?.name}-verified-tooltip`}
															style={{
																backgroundColor: "white",
																color: "#031021",
																fontSize: "0.75rem",
															}}
														/>

														<VerifiedCheck
															data-tooltip-id={`${nft?.name}-verified-tooltip`}
															data-tooltip-content={`Verified Collection from the Flow NFT catalog`}
															data-tooltip-place='right'
															width={18}
															height={18}
														/>
													</>
												)}
											</div>
										</div>
									</div>
									<div className='flex flex-row items-center justify-start mt-2 h-6'></div>
									<div className='flex flex-row items-center justify-start mt-2 h-6'></div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='w-full h-[10rem] flex items-center justify-center'>
					<FlowtyClaimableBadge />
				</div>
			</div>
		</a>
	)
}
