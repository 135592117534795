import { Config } from "../types"

export const getCollectionPathsScript = (config: Config): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getCollectionPathsScriptCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getCollectionPathsScript(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getCollectionPathsScript = (
	config: Config
): string => `import NFTCatalog from ${config.contractAddresses.NFTCatalog}

pub struct Paths {
    pub let storagePath: String
    pub let publicPath: String

    init(storagePath: String, publicPath: String) {
        self.storagePath = storagePath
        self.publicPath = publicPath
    }
}

pub fun main(contractAddress: Address, contractName: String) : Paths? {
    let addrString = contractAddress.toString()
    let nftTypeIdentifier = "A.".concat(addrString.slice(from: 2, upTo: addrString.length)).concat(".").concat(contractName).concat(".NFT")
    let tmp = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier)
    if tmp == nil {
    return nil
    }

    let catalogIdentifiers = tmp!
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
    if catalogIdentifiers[k] == true {
        catalogIdentifier = k
        break
    }
    }

    if catalogIdentifier != "" {
        if let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
            return Paths(
                storagePath: catalogEntry.collectionData.storagePath.toString(),
                publicPath: catalogEntry.collectionData.publicPath.toString()
            )   
        }
    }

    return nil
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getCollectionPathsScriptCrescendo = (
	config: Config
): string => `import NFTCatalog from ${config.contractAddresses.NFTCatalog}

access(all) struct Paths {
    access(all) let storagePath: String
    access(all) let publicPath: String

    init(storagePath: String, publicPath: String) {
        self.storagePath = storagePath
        self.publicPath = publicPath
    }
}

access(all) fun main(contractAddress: Address, contractName: String) : Paths? {
    let addrString = contractAddress.toString()
    let nftTypeIdentifier = "A.".concat(addrString.slice(from: 2, upTo: addrString.length)).concat(".").concat(contractName).concat(".NFT")
    let tmp = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier)
    if tmp == nil {
    return nil
    }

    let catalogIdentifiers = tmp!
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
        if catalogIdentifiers[k] == true {
            catalogIdentifier = k
            break
        }
    }

    if catalogIdentifier != "" {
        if let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
            return Paths(
                storagePath: catalogEntry.collectionData.storagePath.toString(),
                publicPath: catalogEntry.collectionData.publicPath.toString()
            )   
        }
    }

    return nil
}`
