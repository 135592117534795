import { FlowNFT } from "flowty-common"
import { useEffect, useState } from "react"
import { Flowty } from "../flowty"

interface GetCollectionRoyaltyProps {
	royaltyRate: number | null
	isLoading: boolean
}

interface UseCollectionRoyaltyProps {
	nft: FlowNFT | null
	flowty: Flowty
	mixPanelFn: (event: string, data: unknown) => void
}

export const useGetCollectionRoyalty = ({
	nft,
	flowty,
	mixPanelFn,
}: UseCollectionRoyaltyProps): GetCollectionRoyaltyProps => {
	const [royaltyRate, setRoyaltyRate] = useState<number | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	const getRoyaltyRate = async (): Promise<void> => {
		if (nft?.owner) {
			const storagePath = nft?.nftView?.collectionData?.storagePath
			try {
				setIsLoading(true)
				const res = await flowty.scripts.getRoyaltyRate(
					nft.owner || "",
					nft.id.toString(),
					storagePath?.identifier ?? ""
				)
				setRoyaltyRate(res)
				setIsLoading(false)
			} catch (e) {
				setIsLoading(false)
				mixPanelFn("error getting royalty rate", e)
			}
		}
	}

	useEffect(() => {
		getRoyaltyRate()
	}, [nft])

	return { isLoading, royaltyRate }
}
