import { OpensearchStorefrontAvailableData } from "flowty-common"
import React from "react"
import { NftPrice } from "../../FlowtyModal/components/common/NftPrice"
import { useFlowtyPurchaseModalContext } from "../../FlowtyModal/contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { cn } from "../../utils/cn"

interface PurchaseCarouselCardProps {
	order: OpensearchStorefrontAvailableData
	selectedOrder: OpensearchStorefrontAvailableData | null
}

export const PurchaseCarouselCard: React.FunctionComponent<
	PurchaseCarouselCardProps
> = ({ order, selectedOrder }) => {
	const { spotPrice } = useFlowtyPurchaseModalContext()
	return (
		<div
			className={cn(
				"w-[288px] h-[130px] flex flex-col justify-between space-y-2 p-6 rounded-lg border border-[#495057] bg-[#ffffff14] font-montserrat cursor-pointer",
				{
					"border-primary":
						selectedOrder?.listingResourceID === order.listingResourceID,
				}
			)}
		>
			<div className='flex w-full justify-between items-start'>
				<span className='text-base text-white'>Purchase Price</span>
				<div className='text-white font-semibold'>
					<NftPrice
						price={order.amount}
						token={order.paymentTokenName}
						spotPriceValue={
							spotPrice ? spotPrice.value * Number(order?.amount) : undefined
						}
					/>
				</div>
			</div>
		</div>
	)
}
