import { FlowtyCheckbox } from "ds-flowty"
import { EventTypeFilter } from "./HistoryFilter/EventTypeFilter"
import { FilterFormValues } from "./types/FilterFormValues"

interface Props {
	values: FilterFormValues
	setEventType: (eventType: "storefront" | "loan" | "rent" | "all") => void
}

export const HistoryFilters: React.FC<Props> = ({ values, setEventType }) => {
	const wordTransformer = (word: string): string => {
		const withSpaces = word.replace(/([a-z])([A-Z])/g, "$1 $2")

		return withSpaces
			.split(" ")
			.map(w => w.charAt(0).toUpperCase() + w.slice(1))
			.join(" ")
	}

	return (
		<EventTypeFilter
			setEventType={setEventType}
			storefrontForm={
				<div className='pl-4 flex flex-col gap-4'>
					{Object.keys(values.storefrontFilters).map(filter => {
						return (
							<FlowtyCheckbox
								key={filter}
								name={`storefrontFilters.${filter}`}
								id={`storefrontFilters.${filter}`}
								label={wordTransformer(filter)}
								className='text-[14px] font-[700] capitalize'
							/>
						)
					})}
				</div>
			}
			rentalForm={
				<div className='pl-4 flex flex-col gap-4'>
					{Object.keys(values.rentalFilters).map(filter => {
						return (
							<FlowtyCheckbox
								key={filter}
								name={`rentalFilters.${filter}`}
								id={`rentalFilters.${filter}`}
								label={wordTransformer(filter)}
								className='text-[14px] font-[700] capitalize'
							/>
						)
					})}
				</div>
			}
			loanForm={
				<div className='pl-4 flex flex-col gap-4'>
					{Object.keys(values.loanFilters).map(filter => {
						return (
							<FlowtyCheckbox
								key={filter}
								name={`loanFilters.${filter}`}
								id={`loanFilters.${filter}`}
								label={wordTransformer(filter)}
								className='text-[14px] font-[700] capitalize'
							/>
						)
					})}
				</div>
			}
		/>
	)
}
