/* eslint-disable @typescript-eslint/no-use-before-define */
import { Config } from "../types"

export const getSetupCollectionTxn = (
	config: Config,
	isDapper: boolean,
	contractAddress: string,
	contractName: string,
	isNFTCatalog: boolean
): string => {
	if (isDapper) {
		return config.crescendo
			? setupCollectionDapperCrescendo(config)
			: setupCollectionDapper(config)
	}

	if (config.crescendo) return setupCollectionCrescendo(config)

	if (
		contractName === "TopShot" &&
		contractAddress === config.contractAddresses.TopShot
	) {
		return setupTopShotCollection(config)
	}

	if (isNFTCatalog) {
		return setupCollectionCatalog(config)
	}

	return setupWithoutCatalog(config)
}

const setupCollectionCatalog = (config: Config): string => ``
const setupWithoutCatalog = (config: Config): string => ``
const setupTopShotCollection = (config: Config): string => ``

const setupCollectionDapper = (config: Config): string => ``

const setupCollectionCrescendo = (
	config: Config
): string => `// flowty.io - Setup Collection with the NFT Catalog
import ViewResolver from ${config.contractAddresses.ViewResolver}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}

transaction(contractAddress: Address, contractName: String) {
    prepare(acct: auth(Capabilities, Storage) &Account) {
        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        let contractAcct = getAccount(contractAddress)

        let dc = contractAcct.contracts.get(name: contractName)
            ?? panic("contract not found")
        let vr = contractAcct.contracts.borrow<&{ViewResolver}>(name: contractName)
        let publicTypes = dc.publicTypes()
        let nftResourceType = Type<@{NonFungibleToken.NFT}>()
        for pt in publicTypes {
            if !pt.isSubtype(of: nftResourceType) {
                continue
            }

            var storagePath: StoragePath? = nil
            var publicPath: PublicPath? = nil

            var catalogIdentifier = ""
            if let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: pt.identifier) {
                for k in catalogIdentifiers.keys {
                    if catalogIdentifiers[k] == true {
                        catalogIdentifier = k
                        break
                    }
                }
            }

            if catalogIdentifier != "" {
                if let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
                    storagePath = catalogEntry.collectionData.storagePath
                    publicPath = catalogEntry.collectionData.publicPath
                }
            }

            let vr = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
            let md = vr.resolveContractView(resourceType: pt, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
            let collectionData = md as! MetadataViews.NFTCollectionData

            publicPath = publicPath ?? collectionData.publicPath
            storagePath = storagePath ?? collectionData.storagePath
            assert(storagePath != nil && publicPath != nil, message: "could not resolve publicPath or storagePath for collection")

            if acct.storage.borrow<&AnyResource>(from: storagePath!) == nil {
                acct.storage.save(<-collectionData.createEmptyCollection(), to: storagePath!)
            }

            let targetType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
            var hasProvider = false
            acct.capabilities.storage.forEachController(forPath: storagePath!, fun(c: &StorageCapabilityController): Bool {
								if c.borrowType.isRecovered {
										return true
								}

                if c.borrowType.isSubtype(of: targetType) {
                    hasProvider = true
                    return false
                }
                return true
            })

            if !hasProvider {
                let provider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(storagePath!)
                cache.addCapability(resourceType: pt, cap: provider)
            }

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath!).check() {
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storagePath!),
                    at: publicPath!
                )
            }
        }
    }
}`

const setupCollectionDapperCrescendo = (
	config: Config
): string => `// flowty.io - Setup Collection with the NFT Catalog

import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import DapperWalletCollections from ${config.contractAddresses.DapperWalletCollections}

transaction(contractAddress: Address, contractName: String) {
    prepare(acct: auth(Storage, Capabilities) &Account) {
    let contractAcct = getAccount(contractAddress)
    let c = contractAcct.contracts.borrow<&{NonFungibleToken}>(name: contractName) ?? panic("contract not found")

    let contractType = c.getType()
    assert(DapperWalletCollections.containsType(contractType), message: "not a valid NFT type")

    let nftTypeIdentifier = contractType.identifier.concat(".NFT")
    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
      if catalogIdentifiers[k] == true {
        catalogIdentifier = k
        break
      }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

    let publicPath = catalogEntry.collectionData.publicPath
    let storagePath = catalogEntry.collectionData.storagePath
    let privatePath = catalogEntry.collectionData.privatePath

    if acct.storage.borrow<&AnyResource>(from: storagePath) == nil {
      let collection <- c.createEmptyCollection(nftType: catalogEntry.nftType)
      acct.storage.save(<-collection, to: storagePath)
    }

    if !acct.capabilities.get<&{NonFungibleToken.Collection}>(publicPath).check() {
        acct.capabilities.unpublish(publicPath)
        acct.capabilities.publish(
            acct.capabilities.storage.issue<&{NonFungibleToken.Collection}>(storagePath),
            at: publicPath
        )
    }

    let providerStoragePath = FlowtyUtils.getCapabilityStoragePath(type: catalogEntry.nftType, suffix: "CollectionProviderForFlowty")
    let copiedCap = acct.storage.copy<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Collection}>>(from: providerStoragePath)
    if copiedCap?.check() != true {
      let provider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
      assert(provider.check(), message: "collection provider capability is not valid")
      acct.storage.save(provider, to: providerStoragePath)
    }
  }
}`
