import { XCircleIcon } from "@heroicons/react/solid"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { cn } from "../utils/cn"
import { ReactComponent as Focused } from "./assets/focusedMagnifyingGlass.svg"
import { ReactComponent as UnFocused } from "./assets/unFocusedMagnifyingGlass.svg"
import { FlowtySearchBarProps } from "./types"

const FlowtySearchBarInput = (props: FlowtySearchBarProps) => {
	const [localValue, setLocalValue] = useState(props.value)

	const [isFocused, setIsFocused] = useState(false)

	useEffect(() => {
		setLocalValue(props.value)
	}, [props.value])

	const debouncedOnChange = useDebouncedCallback((value: string) => {
		props.onChange(value)
	}, props.debounceTime)

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
		setLocalValue(newValue)
		debouncedOnChange(newValue)
	}

	const handleKeyDown = () => {
		debouncedOnChange(localValue)
	}

	const baseStyles = `flex border-[1px] border-[#6c757d40] truncate placeholder:truncate !h-[40px] w-full text-[0.875rem] h-[2.5rem] text-sm leading-6 font-montserrat font-normal rounded-[6px] bg-[#e1e1e114] bg-opacity-50 py-[0.375rem] pl-[3.75rem] text-white placeholder:text-gray-400 placeholder:text-[0.875rem] placeholder:font-[400] hover:bg-[#ffffff14] outline-none focus:bg-[#ffffff14] active:bg-[#ffffff14] sm:text-sm sm:leading-6`

	return (
		<>
			<div className='w-full'>
				<label
					htmlFor='search'
					className='sr-only block text-sm leading-6 text-[#6C757D]'
				>
					{props.labelText}
				</label>
				<div className='relative flex w-full items-center rounded-md shadow-sm'>
					<div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[24px]'>
						{isFocused ? <Focused /> : <UnFocused />}
					</div>
					{props.isMobile ? (
						<XCircleIcon
							onClick={props.onClear}
							className='absolute right-[24px] w-[1.5rem] h-[1.5rem] cursor-pointer'
						/>
					) : null}
					<input
						onBlur={() => {
							setIsFocused(false)
							if (props.onBlur) {
								props.onBlur()
							}
						}}
						onKeyDown={e => {
							if (e.key === "Enter") {
								handleKeyDown()
							}
						}}
						type='text'
						name='search'
						id='search'
						onFocus={() => {
							setIsFocused(true)
							if (props.onFocus) {
								props.onFocus()
							}
						}}
						className={cn(baseStyles, {
							"border-[1px] border-primary": isFocused,
						})}
						onChange={handleInputChange}
						disabled={props.disabled}
						maxLength={props.maxLength}
						autoFocus={props.autoFocus}
						value={localValue}
						placeholder={props.placeholder}
						autoComplete='off'
					/>
				</div>
			</div>
		</>
	)
}

export default FlowtySearchBarInput
