import { Config } from "../types"

export const getCatalogIdentifiersScript = (config: Config): string => `
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

// Get the NFTCatalog collection identifier for a supplied nftType identifier
access(all) fun main(nftTypeIdentifier: String):AnyStruct? {
  let collections = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier)
  return collections
}`
