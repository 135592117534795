import { useEffect, useState } from "react"
import { XIcon } from "@heroicons/react/solid"
import { FlowtyButton } from "ds-flowty"
import { useParams } from "react-router-dom"
import { flowty } from "../../../../config/config"

interface EnableAccountBoxProps {
	loggedUserAddr: string
	loggedIsDapper: boolean
	isNFTCatalog: boolean | undefined
	websiteLink: string | undefined
}

export const EnableAccountBox: React.FunctionComponent<
	EnableAccountBoxProps
> = ({ loggedUserAddr, loggedIsDapper, isNFTCatalog, websiteLink }) => {
	const { collectionAddress, collectionName } = useParams()
	const [settingUpCollection, setSettingUpCollection] = useState(false)
	const [isSetup, setIsSetup] = useState<boolean>(true)
	const [isDapperWalletCollection, setIsDapperWalletCollection] =
		useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(true)

	useEffect(() => {
		if (
			!loggedUserAddr ||
			!collectionAddress ||
			!collectionName ||
			isNFTCatalog === undefined
		) {
			return
		}

		// TODO: it isn't really enough to check if your collection is configured properly or not,
		// we also need to make sure that the wrong thing isn't stored as well, potentially.
		// We will need to tackle that another time.
		flowty.scripts
			.isCollectionSetup(
				loggedUserAddr,
				collectionAddress,
				collectionName,
				loggedIsDapper || false,
				isNFTCatalog || false
			)
			.then((res: boolean) => {
				setIsSetup(res)
			})

		if (loggedIsDapper) {
			flowty.scripts
				.isDapperCollection(collectionAddress, collectionName)
				.then(res => {
					setIsDapperWalletCollection(res)
				})
		}
	}, [settingUpCollection])

	const displayEnableAccountBox =
		!isSetup && (!loggedIsDapper || isDapperWalletCollection)

	return (
		<>
			{displayEnableAccountBox && isOpen && (
				<div className='flex flex-row gap-3 max-lg:flex-col bg-[#FFFFFF08] backdrop-blur-[9px] border rounded-md border-[#6c757d52] px-[18px] py-[14px] sm:px-[24px] sm:py-[16px] items-center mt-2 '>
					<div className='flex gap-2 max-w-96'>
						<h6 className='text-xs text-white'>
							Your wallet&apos;s ability to transfer{" "}
							<a href={websiteLink} target='_blank' rel='noreferrer'>
								{collectionName}
							</a>{" "}
							is limited until you enable the collection.{" "}
							<a
								href='https://docs.flowty.io/reference'
								target='_blank'
								rel='noreferrer'
								className='underline'
							>
								Learn more
							</a>{" "}
						</h6>
						<XIcon
							className='md:hidden h-[24px] w-[24px] min-h-[18px] min-w-[18px] hover:cursor-pointer'
							onClick={() => setIsOpen(false)}
						/>
					</div>
					<div className='max-lg:w-full flex flex-row space-x-4'>
						<div className='max-lg:flex md:mt-0 max-lg:grow'>
							<FlowtyButton
								onClick={async () => {
									setSettingUpCollection(true)

									await flowty.setupCollection(
										collectionAddress || "",
										collectionName || "",
										loggedIsDapper || false,
										isNFTCatalog || false
									)

									setSettingUpCollection(false)
								}}
								text={
									settingUpCollection ? (
										<>
											<div className='flex flex-row justify-center'>
												<svg
													className='animate-spin -ml-1 mr-3 h-5 w-5 text-primary'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
												>
													<circle
														className='opacity-25'
														cx='12'
														cy='12'
														r='10'
														stroke='currentColor'
														strokeWidth='4'
													></circle>
													<path
														className='opacity-75'
														fill='currentColor'
														d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
													></path>
												</svg>
												<span>Enabling...</span>
											</div>
										</>
									) : (
										<span>Enable Collection</span>
									)
								}
								variant={"secondary"}
								bgColor='white'
							></FlowtyButton>
						</div>
						<div className='max-lg:w-full flex max-lg:grow self-center justify-end'>
							<XIcon
								className='hidden md:block h-[24px] w-[24px] min-h-[18px] min-w-[18px] hover:cursor-pointer'
								onClick={() => setIsOpen(false)}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
