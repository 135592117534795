import { OpensearchFlowNFT, Path } from "flowty-common"

export const checkHasProviderFn = (
	nft: OpensearchFlowNFT | null,
	authStoreLoggedUserAddr: string,
	paths: { [data: string]: [Path] }
): boolean | null => {
	if (!nft) return null
	if (nft?.owner === authStoreLoggedUserAddr) {
		return true
	}

	if (paths === undefined) {
		return null
	}

	return (
		paths?.[nft?.contractAddress.concat(".").concat(nft?.contractName)]
			?.length > 0
	)
}
