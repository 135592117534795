export const LoadingOffers: React.FunctionComponent = () => {
	return (
		<div className='w-full flex flex-col gap-3 pt-3'>
			<div className='rounded flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait w-full h-[46px]' />
			{Array.from({ length: 5 }).map((_, index) => (
				<div
					key={index}
					className='rounded flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait w-full h-[72px]'
				/>
			))}
		</div>
	)
}
