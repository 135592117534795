import { makeAutoObservable, runInAction } from "mobx"

export interface RootStoreProp {
	rootStore?: RootStore
}

export interface FlowNetworkStatus {
	healthy: boolean
	errors: string[]
}

class RootStore {
	flowtyInitializedBlock: number = 0

	flowNetworkStatus: FlowNetworkStatus = {
		errors: [],
		healthy: true,
	}

	constructor() {
		makeAutoObservable(this)
	}

	setFlowtyInitializedBlock = (block: number): void => {
		runInAction(() => {
			this.flowtyInitializedBlock = block
		})
	}

	setFlowNetworkStatus = (newStatus: FlowNetworkStatus): void => {
		runInAction(() => {
			this.flowNetworkStatus = newStatus
		})
	}
}

// eslint-disable-next-line import/no-default-export
export default RootStore
