import { ReactComponent as HideFilterIcon } from "../../assets/hide-filter-icon.svg"

interface UserActionsHideFiltersBtnProps {
	isFilterHidden: boolean
	setIsFilterHidden: (value: boolean) => void
}
export const HideFiltersBtn: React.FunctionComponent<
	UserActionsHideFiltersBtnProps
> = ({ isFilterHidden, setIsFilterHidden }) => {
	return (
		<button
			onClick={() => setIsFilterHidden(!isFilterHidden)}
			className={`flex justify-center gap-2 items-center py-2 text-base rounded-md hover:bg-opacity-[15%] whitespace-nowrap w-full
				${
					isFilterHidden
						? "bg-[#FFFFFF20]"
						: "bg-glassFillGray hover:bg-glassFillGrayHover"
				}
				`}
		>
			<HideFilterIcon />
			<span className='font-medium text-sm leading-4'>
				{isFilterHidden ? "Show Filters" : "Hide Filters"}
			</span>
		</button>
	)
}
