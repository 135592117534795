/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlowtyFilterMenu } from "ds-flowty"
import { nftTypeAndIdToLocationData } from "flowty-common"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../assets/media/loadingArrow.svg"
import { RentalActivityFilter } from "../components/Activity/Filters"
import { ScrollToLoadMsg } from "../components/Loaders/ScrollToLoadMsg"
import { SortDirection } from "../components/MarketPlace/SortButton/shared"
import SortableTable, { SortColumnOrNull } from "../components/SortableTable"
import { platformRentalActivityFields } from "../components/SortableTable/PlatformActivity/platformRentalActivityFields"
import { RentalEventType } from "../components/UserProfile/Tables/Storefront/AllActivity/types"
import useinfiniteScroll from "../hooks/infiniteScroll"
import { subscribeToAllActivity } from "../services/firestore/ActivityService"
import { actions as Mixpanel } from "../util/Mixpanel"
import { defaultNftPageSize } from "../util/settings"
import { ActivityLayout } from "./ActivityLayout"

export const RentalsActivityScreen: React.FunctionComponent = () => {
	const [nftPage, setNftPage] = useState(1)
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)

	const [allBorrowerActivityDocs, setAllBorrowerActivityDocs] = useState<
		Array<any>
	>([])
	const [allLenderActivityDocs, setAllLenderActivityDocs] = useState<
		Array<any>
	>([])
	const [filterBy, setFilterBy] = useState<any>({
		min: "",
		path: "",
	})
	const [showFilter, setShowFilter] = useState(true)
	const [sort, setSort] = useState<SortColumnOrNull>({
		column: "blockTimestamp",
		order: SortDirection.Descending,
	})

	useEffect(() => {
		setNftPage(1)
	}, [sort, filterBy])

	useEffect(
		() =>
			subscribeToAllActivity({
				addressPath: "data.renterAddress",
				eventCollection: "rentalEvents",
				filterActivityBy: filterBy,
				limit: defaultNftPageSize * nftPage,
				listingCollection: "rentalAvailable",
				makerCb(docs) {
					setAllBorrowerActivityDocs(docs)
				},
				sort,
				takerCb(docs) {
					setAllLenderActivityDocs(docs)
				},
			}),
		[nftPage, filterBy, sort]
	)

	const allActivityDocs: Array<any> = useMemo(
		() =>
			allBorrowerActivityDocs.map(doc => ({
				...doc,
			})),
		[allLenderActivityDocs, allBorrowerActivityDocs]
	)

	const belowTableRef = useinfiniteScroll(
		{
			isLoading: false,
			onInfiniteScroll() {
				setTimeout(() => {
					setIsLoading(false)
				}, 2000)
				if (nftPage * defaultNftPageSize === allActivityDocs.length) {
					setNftPage(nftPage + 1)
					setIsLoading(true)
				}
				Mixpanel.track("Inifinite Scroll Global Rentals Page")
			},
		},
		[nftPage, allActivityDocs]
	)

	useEffect(() => {
		Mixpanel.track("Global Rentals Page Visited")
	}, [])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Global Rentals Page Filtered`, {
				filter: filterBy,
			})
		}
	}, [filterBy.path])

	const rentalFilterOptions = [
		{ label: "All", value: "" },
		{ label: "Listed", value: "RENTAL_LISTED" },
		{ label: "Delisted", value: "RENTAL_DESTROYED" },
		{ label: "Rented", value: "RENTAL_RENTED" },
		{ label: "Returned", value: "RENTAL_RETURNED" },
		{ label: "Settled", value: "RENTAL_SETTLED" },
		{ label: "Expired", value: "EXPIRED" },
	]

	const [selectedFilter, setSelectedFilter] = useState(
		rentalFilterOptions[0].value
	)

	const updateSelectedFilter = (value: string): void => {
		setSelectedFilter(value)
		setFilterBy({
			max: "",
			min: value as RentalEventType,
			path: "state",
		})
	}

	return (
		<ActivityLayout>
			<div>
				<RentalActivityFilter
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>

				<div className='flex mb-[20px]'>
					<FlowtyFilterMenu
						fields={rentalFilterOptions}
						selectedField={selectedFilter}
						updateSelectedField={updateSelectedFilter}
					/>
				</div>

				<div className='line-separator mb-3 md:mb-4 bg-gradient-to-r from-darkBg via-secondary to-darkBg' />

				<SortableTable
					onClickRow={({ item }) => {
						const nftType = item?.data?.nftType
						const nftId = item?.data?.nftID.toString()

						const itemIsFullyLoaded = !!(nftType && nftId)
						const locationData = nftTypeAndIdToLocationData(nftType, nftId)

						if (itemIsFullyLoaded) {
							navigate(
								`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
							)
						}
					}}
					fields={platformRentalActivityFields(navigate)}
					items={allActivityDocs}
					onSort={setSort}
					initialSortConfig={sort}
					stickyHeaders
					composeRowHref={item => {
						const nftType = item?.data?.nftType
						const nftId = item?.data?.nftID.toString()
						const locationData = nftTypeAndIdToLocationData(nftType, nftId)

						return `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
					}}
				/>
				<div ref={belowTableRef}>
					{isLoading ? (
						<div className='h-10 w-full flex justify-center items-center'>
							<h2 className='justify-center items-center flex flex-row animate-pulse'>
								<RefreshArrow />
								Loading...
							</h2>
						</div>
					) : (
						nftPage * defaultNftPageSize === allActivityDocs.length && (
							<ScrollToLoadMsg />
						)
					)}
				</div>
			</div>
		</ActivityLayout>
	)
}
