import { FlowNFT } from "flowty-common"
import { getDapperInventoryURL } from "../../../../../../../../util/dapper"
import { FlowtyButton } from "ds-flowty"

interface DapperTransferDisplayProps {
	flowNFT: FlowNFT
}

const DapperTransferDisplay: React.FC<DapperTransferDisplayProps> = ({
	flowNFT,
}) => {
	const dapperURL = getDapperInventoryURL(flowNFT.type, flowNFT.id)

	return (
		<div className='min-h-[16.65rem] flex flex-col flex-grow w-full justify-between '>
			<div></div>
			<span className='w-full px-2 h-full flex justify-center items-center flex-col gap-[0.75rem] my-[1.5rem]'>
				NFTs in Dapper Wallet can only be transferred via Dapper inventory
			</span>
			<div className='flex justify-items-end'>
				<FlowtyButton
					text='View in dapper inventory'
					variant='secondary'
					btnSize='small'
					onClick={() => {
						window.open(
							dapperURL || "https://accounts.meetdapper.com/inventory",
							"_blank"
						)
					}}
				/>
			</div>
		</div>
	)
}

export default DapperTransferDisplay
