import React from "react"
import { SimpleModal } from "../../../Modals/SimpleModal/SimpleModal"
import { FilterView } from "../hooks/useSaveView"

interface ConfirmDeleteCustomViewModalProps {
	selectedForDelete: FilterView | null
	setSelectedForDelete: (filterView: FilterView | null) => void
	deleteFilterView: (filterView: FilterView) => void
}

export const ConfirmDeleteCustomViewModal: React.FunctionComponent<
	ConfirmDeleteCustomViewModalProps
> = ({ selectedForDelete, setSelectedForDelete, deleteFilterView }) => {
	return (
		<SimpleModal
			className='z-[500]'
			actionType='negative'
			isShowing={!!selectedForDelete}
			onClose={() => setSelectedForDelete(null)}
			onAction={() => deleteFilterView(selectedForDelete as FilterView)}
			action='Delete'
			head={
				<div className='flex w-full'>
					<div className='text-[#FF2F2F] text-2xl font-bold px-1'>Delete</div>
					<div className='px-1 text-2xl font-bold text-white'>Custom View</div>
				</div>
			}
		>
			<div className='flex items-center justify-center w-full px-10 py-20 bg-darkBg'>
				<div className='flex flex-col items-center justify-center'>
					<div className='mb-4 font-bold'>
						Are you sure you want to delete the filter below?
					</div>
					<div className='text-3xl font-bold capitalize'>
						{selectedForDelete?.name}
					</div>
				</div>
			</div>
		</SimpleModal>
	)
}
