/* eslint-disable @typescript-eslint/no-use-before-define */

import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getCreateRentalListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => {
	return config.crescendo
		? rentalListingTxnCrescendo(config)
		: rentalListingTxn(config, token, nftData)
}

export const getDelistRentalListingTxn = (config: Config): string =>
	config.crescendo ? rentalDelistTxnCrescendo(config) : rentalDelistTxn(config)

const rentalListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const rentalListingTxnCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import FlowtyRentals from ${config.contractAddresses.Flowty}
import Flowty from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
    listItemID: UInt64,
    amount: UFix64,
    deposit: UFix64,
    term: UFix64,
    expiresAfter: UFix64,
    renter: Address?,
    nftProviderAddress: Address,
    nftProviderControllerID: UInt64,
    collectionIdentifier: String,
    ftReceiverAddress: Address,
    paymentTokenIdentifier: String
) {
    let receiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(FlowtyRentals.List) &FlowtyRentals.FlowtyRentalsStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let paymentTokenType: Type
    let nftType: Type

    prepare(acct: auth(Capabilities, Storage) &Account) {
        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier)
            ?? panic("Provided collection is not in the NFT Catalog.")
        self.paymentTokenType = CompositeType(paymentTokenIdentifier) ?? panic("invalid payment token type")
        self.nftType = catalogEntry.nftType

        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if(acct.storage.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
            let storefront <- FlowtyRentals.createStorefront()

            acct.storage.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontStoragePath),
                at: FlowtyRentals.FlowtyRentalsStorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        let ftAddress = AddressUtils.parseAddress(self.paymentTokenType)!
        let contractName = self.paymentTokenType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: self.paymentTokenType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        if ftReceiverAddress == acct.address {
            
            if acct.storage.borrow<&{FungibleToken.Vault}>(from: ftVaultData.storagePath) == nil {
                acct.storage.save(<-ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)

                acct.capabilities.unpublish(ftVaultData.receiverPath)
                acct.capabilities.unpublish(ftVaultData.metadataPath)

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath),
                    at: ftVaultData.receiverPath
                )
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Vault}>(ftVaultData.storagePath),
                    at: ftVaultData.metadataPath
                )
            }

            self.receiver = acct.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.receiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        }

        assert(self.receiver.check(), message: "Missing or mis-typed receiver")

        if nftProviderAddress == acct.address {
            let borrowType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
            if let provider = cache.getCapabilityByType(resourceType: catalogEntry.nftType, capabilityType: CapabilityType(borrowType)!) {
                self.nftProvider = provider as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            } else {
                self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
                cache.addCapability(resourceType: catalogEntry.nftType, cap: self.nftProvider)
            }

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        } else {           
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            let item = self.nftProvider.borrow() ?? panic("nft provider could not be borrowed")

            let receiverCap = child.getPublicCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.storage.borrow<auth(FlowtyRentals.List) &FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
            ?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.receiver,
            amount: amount
        )

        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            ownerFungibleTokenReceiver: self.receiver,
            nftType: self.nftType,
            nftID: listItemID,
            amount: amount,
            deposit: deposit,
            term: term,
            paymentVaultType: self.paymentTokenType,
            paymentCut: paymentCut,
            expiresAfter: expiresAfter,
            renter: renter
        )
    }
}`

const rentalDelistTxn = (config: Config): string => ``

const rentalDelistTxnCrescendo = (
	config: Config
): string => `import FlowtyRentals from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: auth(FlowtyRentals.Cancel) &{FlowtyRentals.FlowtyRentalsStorefrontManager}

	prepare(acct: auth(Storage) &Account) {
		self.storefront = acct.storage.borrow<auth(FlowtyRentals.Cancel) &{FlowtyRentals.FlowtyRentalsStorefrontManager}>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
			?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`
