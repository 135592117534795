import { useEffect, useState } from "react"

export const useHandleDapperSignInBar = (): {
	showDapperSignInBar: boolean
	setShowDapperSignInBar: (show: boolean) => void
} => {
	const [showDapperSignInBar, setShowDapperSignInBar] = useState(() => {
		const show = localStorage.getItem("showDapperSignInBar")
		if (show) {
			return JSON.parse(show)
		}
		return true
	})

	useEffect(() => {
		localStorage.setItem(
			"showDapperSignInBar",
			JSON.stringify(showDapperSignInBar)
		)
	}, [showDapperSignInBar])

	return { setShowDapperSignInBar, showDapperSignInBar }
}
