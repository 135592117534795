import { useState } from "react"
import { useSwiper } from "swiper/react"
import { ReactComponent as GrayDot } from "../../assets/grayDot.svg"
import { ReactComponent as LeftArrow } from "../../assets/navLeft.svg"
import { ReactComponent as RightArrow } from "../../assets/navRight.svg"
import { ReactComponent as WhiteDot } from "../../assets/whiteDot.svg"

interface CarouselNavigationProps {
	itemsLength: number
	setIsFirstIndex: (val: boolean) => void
	hiddeArrows?: boolean
}

export const CarouselNavigation: React.FunctionComponent<
	CarouselNavigationProps
> = ({ itemsLength, setIsFirstIndex, hiddeArrows }) => {
	const swiper = useSwiper()
	const [selectedSlideIdx, setSelectedSlideIdx] = useState<number>(
		swiper.activeIndex
	)

	const isFirst = selectedSlideIdx === 0
	const isLast = selectedSlideIdx === itemsLength - 1

	swiper.on("slideChange", () => {
		setSelectedSlideIdx(swiper.activeIndex)
		setIsFirstIndex(swiper.activeIndex === 0)
	})

	return (
		<div className='flex items-center justify-center space-x-2 pt-3'>
			<div className='w-[25px]'>
				{!isFirst && !hiddeArrows && (
					<div
						className='p-2 backdrop-blur-[6px] border-2 border-[#6c757d40] bg-[#ffffff14] cursor-pointer'
						onClick={() => swiper.slidePrev()}
					>
						<LeftArrow />
					</div>
				)}
			</div>
			{Array.from({ length: itemsLength }).map((_, idx) => (
				<div
					key={idx}
					className='flex space-x-3 cursor-pointer'
					onClick={() => swiper.slideTo(idx)}
				>
					{selectedSlideIdx === idx ? <WhiteDot /> : <GrayDot />}
				</div>
			))}
			<div className='w-[25px]'>
				{!isLast && !hiddeArrows && (
					<div
						className='p-2 backdrop-blur-[6px] border-2 border-[#6c757d40] bg-[#ffffff14] cursor-pointer'
						onClick={() => swiper.slideNext()}
					>
						<RightArrow />
					</div>
				)}
			</div>
		</div>
	)
}
