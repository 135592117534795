import { FlowNFTContract } from "flowty-common"
import { db } from "../../../firebase"
import { Facet, MappedFilterTraits } from "../../../components/OpenSearch/types"

export const fetchCollectionData = async (collectionName: string) => {
	const docRef = await db.collection("flowNFTContract").doc(collectionName)
	const doc = await docRef.get()
	if (doc) {
		return doc.data() as FlowNFTContract
	}
	return null
}

export interface MappedFacet {
	count: number
	traits?: MappedFilterTraits | undefined
}

export const fetchCollectionGlobalFacets = async (
	collectionName: string
): Promise<Facet | null> => {
	const docRef = await db.collection("flowNFTTraits").doc(collectionName).get()
	return (docRef.data() as Facet) || null
}
