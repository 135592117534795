import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline"
import { FlowtyButton } from "ds-flowty"
import React from "react"
import { Oval } from "react-loader-spinner"
import { StatusUncertain } from "../../../../../../../components/Modals/Listings/shared/StatusUncertain"

interface TransferTransactionStatusProps {
	transactionID: string | null
	flowscanBaseURL: string
	statusUncertain: boolean
	error: boolean
	sealed: boolean
	isLoading: boolean
	onSubmit: () => void
}

export const TransferTransactionStatus: React.FC<
	TransferTransactionStatusProps
> = ({
	transactionID,
	flowscanBaseURL,
	statusUncertain,
	error,
	sealed,
	isLoading,
	onSubmit,
}) => {
	if (error && !statusUncertain) {
		return (
			<div className='w-full flex flex-col justify-center items-center h-full'>
				{transactionID && (
					<p className='h-12'>
						View your transaction on&nbsp;
						<a
							className='modal-external-link'
							href={`${flowscanBaseURL}/${transactionID}`}
							target='_blank'
							rel='noreferrer'
						>
							Flowscan
						</a>
					</p>
				)}
				<div className='flex justify-center items-center my-3'>
					<XCircleIcon className='h-16 w-16 fill-red-500' />
					<span className='text-lg font-montserrat-bold font-bold'>
						Transaction Failed
					</span>
				</div>
				<div className='px-6 w-full flex justify-center items-center my-3'>
					<span className='text-base text-center'>
						Your transaction failed. Please try again. If you continue to have
						issues, please open a support ticket in{" "}
						<a
							href='https://discord.com/channels/657618722634203176/940412791507017729'
							className='underline text-primary'
						>
							Discord
						</a>
						.
					</span>
				</div>
				<FlowtyButton
					text={"Try Again"}
					onClick={onSubmit}
					variant={"primary"}
				/>
			</div>
		)
	}

	if (sealed && !error && transactionID) {
		return (
			<div className='w-full flex flex-col justify-center items-center h-full'>
				<div className='w-full flex flex-col items-center'>
					<p className='h-12'>
						Follow your transaction on&nbsp;
						<a
							className='modal-external-link'
							href={`${flowscanBaseURL}/${transactionID}`}
							target='_blank'
							rel='noreferrer'
						>
							Flowscan
						</a>
					</p>
				</div>

				<div className='px-6 w-full flex justify-center items-center my-3'>
					<span className='text-base text-center'>
						It may take a few minutes for your NFT to appear in the receiving
						account. You can close this window at any time.
					</span>
				</div>
				<div className='flex justify-center items-center my-3'>
					<CheckCircleIcon className='h-16 w-16 fill-primary' />
					<span className='text-lg font-montserrat-bold font-bold'>
						Transaction Successful!
					</span>
				</div>
			</div>
		)
	}

	if (statusUncertain) {
		return <StatusUncertain transactionID={transactionID} />
	}

	if (isLoading) {
		return (
			<div className='w-full flex flex-col justify-center items-center h-full'>
				{transactionID ? (
					<p className='h-12'>
						Follow your transaction on&nbsp;
						<a
							className='modal-external-link'
							href={`${flowscanBaseURL}/${transactionID}`}
							target='_blank'
							rel='noreferrer'
						>
							Flowscan
						</a>
					</p>
				) : (
					<div className='h-12' />
				)}
				<span className='text-lg font-montserrat-bold font-bold'>
					Processing...
				</span>
				<Oval height={150} width={150} />
			</div>
		)
	}

	return null
}
