/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import axios from "axios"
import { sendTx } from "./FlowUtil"

import { Log } from "../../util/Log"
import { apiURL } from "../../util/settings"

export interface WalletBalance {
	address: string
	balances?: { [key: string]: number }
}

export const ingestAccount = async (address: string): Promise<any> =>
	axios.get(`${apiURL}/ingestAccount`, {
		params: { address },
	})

export const verifyAddress = async (address: string): Promise<boolean> => {
	let isVerified = false
	try {
		const account = await fcl.account(address)
		if (account.address) isVerified = true
	} catch (error) {
		Log(`Couldn't find account [${address}] for private listing`)
	}
	return isVerified
}
