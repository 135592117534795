import React from "react"

interface BackArrowProps {
	onClick: () => void
}

export const BackArrow = ({ onClick }: BackArrowProps) => {
	return (
		<svg
			onClick={onClick}
			className='w-[2rem] h-[2rem] flex-shrink-0 cursor-pointer'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.5313 26.7314C14.9065 27.3563 13.8934 27.3563 13.2686 26.7314L3.66858 17.1314C3.04374 16.5066 3.04374 15.4935 3.66858 14.8687L13.2686 5.26868C13.8934 4.64384 14.9065 4.64384 15.5313 5.26868C16.1562 5.89352 16.1562 6.90658 15.5313 7.53142L8.66269 14.4H27.2C28.0836 14.4 28.8 15.1164 28.8 16C28.8 16.8837 28.0836 17.6 27.2 17.6L8.66269 17.6L15.5313 24.4687C16.1562 25.0935 16.1562 26.1066 15.5313 26.7314Z'
				fill='#F8F9FA'
			/>
		</svg>
	)
}
