/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import { getImageURL } from "flowty-common"
import { SortableTableField } from ".."
import noImage from "../../../assets/media/image_not_available.svg"
import { getDurationPeriod } from "../../../util/nftDataUtil"
import { isFlowRewards } from "../../../util/settings"
import { ActivityTableImage } from "../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { ActivityItem } from "../../Shared/ActivityTableFields"
import { TokenAmount } from "../../Tokens/tokenDisplays"

export const platformRentalActivityFields: (
	navigate: (path: string) => void
) => Array<SortableTableField<ActivityItem>> = navigate => [
	{
		customRender: ({ item }) => {
			const eventType = item?.type?.split("_").pop()?.toLowerCase() ?? ""

			return (
				<div className='flex flex-row justify-start items-center capitalize'>
					{eventType === "destroyed" ? "delisted" : eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Event",
	},
	{
		customRender: ({ item }) => (
			<div className='flex flex-row justify-start items-center'>
				{format(item?.blockTimestamp?.toDate(), "yyyy-MM-dd HH:mm")}
			</div>
		),
		name: "blockTimestamp",
		sortable: true,
		title: "Date",
	},
	{
		customRender: ({ item }) => {
			const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
				2
			)}.${item.additionalData?.card?.collectionName}.NFT`

			return (
				<div>
					{!!item.nftInfo?.nft && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.nftInfo?.card?.collectionAddress || ""
									}
									collectionName={item?.nftInfo?.card?.collectionName || ""}
									srcImg={getImageURL(
										item?.nftInfo?.card?.images?.[0]?.url ?? noImage,
										isFlowRewards(itemType)
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item?.nftInfo?.card?.additionalDetails ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item?.nftInfo?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item?.nftInfo?.card?.title}
										</span>
										{item?.nftInfo?.card?.num &&
											!item.nftInfo?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item?.nftInfo?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.nftInfo?.card?.additionalDetails ? (
									<>
										{item?.nftInfo?.card?.additionalDetails ? (
											item?.nftInfo?.card?.additionalDetails?.map(detail => (
												<span
													key={detail}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{detail}
												</span>
											))
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.nftInfo?.card?.headerTraits &&
											item?.nftInfo?.card?.headerTraits?.map(trait => (
												<span
													key={trait?.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait?.value}
												</span>
											))}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={Number(item?.amount)}
					isSmall
					token={item?.paymentTokenName}
				/>
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Rental Fee",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={Number(item?.deposit)}
					isSmall
					token={item?.paymentTokenName}
				/>
			</div>
		),
		name: "deposit",
		sortable: false,
		title: "Returnable Deposit",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{getDurationPeriod(item?.term)}
			</div>
		),
		name: "duration",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item?.valuations
					? `${item.valuations?.aggregate?.depositToValueRatio?.toFixed(2)}x`
					: "N/A"}
			</div>
		),
		name: "duration",
		sortable: false,
		title: "3rd Party DTV",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(
						`/user/${item?.data?.lender ?? item?.data?.flowtyStorefrontAddress}`
					)
				}}
				className='lender flex flex-row justify-start items-center hover:text-green-200'
			>
				<a
					href={`/user/${
						item?.data?.lender ?? item?.data?.flowtyStorefrontAddress
					}`}
				>
					{item?.data?.lender || item?.data?.flowtyStorefrontAddress || "--"}
				</a>
			</div>
		),
		name: "owner",
		sortable: false,
		title: "Owner",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`/user/${item?.data?.renter ?? item?.data?.renterAddress}`)
				}}
				className='renter flex flex-row justify-start items-center hover:text-green-200'
			>
				<a href={`/user/${item?.data?.renter ?? item?.data?.renterAddress}`}>
					{item?.data?.renter || item?.data?.renterAddress || "--"}
				</a>
			</div>
		),
		name: "renter",
		sortable: false,
		title: "Renter",
	},
]
