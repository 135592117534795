import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useState,
} from "react"

const defaultValues: NavbarViewContextValues = {
	navbarOffsetHeight: 0,
	setNavbarOffsetHeight() {},
	setVisibleInNav() {},
	visibleInNav: false,
}

interface NavbarViewContextValues {
	visibleInNav: boolean
	setVisibleInNav: (val: boolean) => void
	navbarOffsetHeight: number
	setNavbarOffsetHeight: (val: number) => void
}
const NavbarViewContext = createContext<NavbarViewContextValues>(defaultValues)

export const NavbarViewProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [visibleInNav, setVisibleInNav] = useState(false)
	const [navbarOffsetHeight, setNavbarOffsetHeight] = useState(0)
	const values = {
		navbarOffsetHeight,
		setNavbarOffsetHeight,
		setVisibleInNav,
		visibleInNav,
	}

	return (
		<NavbarViewContext.Provider value={values}>
			{children}
		</NavbarViewContext.Provider>
	)
}

export const useNavbarViewContext = () => useContext(NavbarViewContext)
