import { ReactComponent as ViewOnly } from "../../../../assets/ViewOnly.svg"

export const FlowtyViewOnlyDisplay = () => (
	<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-6 font-montserrat'>
		<div className='p-4 bg-[#FFFFFF] bg-opacity-10 w-full h-full rounded-md flex'>
			<div className='h-full px-2'>
				<ViewOnly className='h-[24px] w-[24px]' />
			</div>
			<div className='w-full px-3'>
				<div className='leading-4'>
					<span className='text-white text-xs font-normal'>
						Icon indicates that an NFT is{" "}
					</span>
					<span className='text-white text-xs font-bold'>view-only</span>
					<span className='text-white text-xs font-normal'>
						, meaning that a user cannot perform any Flowty actions on the NFT.
						An NFT may be view-only for several reasons, including:
						<br />
					</span>
				</div>
				<ul className='text-white text-xs font-normal list-disc pl-4'>
					<li>NFTs in Dapper Wallets have restrictions.</li>
					<li className=''>The NFT does not have a provider set up.</li>
					<li className=''>
						{" "}
						The NFT is stored in a different wallet to the one that a user is
						currently connected with.
					</li>
				</ul>
			</div>
		</div>
	</div>
)
