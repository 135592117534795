import React, { useEffect, useState } from "react"
import { Filters } from "../../UserProfile/Filters/index"
import { FilterBy } from "../../UserProfile/Tables/Storefront/AllActivity/types"

export interface ActivityFilterProps {
	showFilter: boolean
	setShowFilter: (showFilter: boolean) => void
	filterBy?: FilterBy
	setFilterBy: (filterBy: FilterBy) => void
}

interface FilterOptions {
	filterOptions: { value: string; label: string }[]
}

export const GenericActivityFilter: React.FC<
	ActivityFilterProps & FilterOptions
> = ({ showFilter, setShowFilter, filterBy, setFilterBy, filterOptions }) => {
	const [status, setStatus] = useState<string | null>(null)

	const filterFields = [
		{
			path: "state",
			selectField: true,
			setValue: setStatus,
			title: "Status",
			value: status,
		},
	]

	const setAllFieldsNull = (): void => {
		setStatus("")
	}

	useEffect(() => {
		if (filterBy?.path && !filterBy?.min && !filterBy?.max) {
			setFilterBy({ ...filterBy, path: null })
		}
	}, [filterBy])

	return (
		<Filters
			showFilter={showFilter}
			setShowFilter={setShowFilter}
			FilterFields={filterFields}
			filterOptions={filterOptions}
			setAllFieldsNull={setAllFieldsNull}
			filterBy={filterBy}
			setFilterBy={setFilterBy}
		/>
	)
}
