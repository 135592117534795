import {
	OpensearchFlowNFT,
	OpensearchStorefrontAvailableData,
	Order,
	SupportedTokens,
} from "flowty-common"
import { FlowtyButton } from "../../../../FlowtyButton"
import { FlowtyModalCarousel } from "../../../../FlowtyModalCarousel"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { NftPrice } from "../../common/NftPrice"
import { ShareActiveListing } from "../../../../TwitterShare/ShareCurrentListing"

interface SaleDelistingProps {
	storefrontOrder: OpensearchStorefrontAvailableData[]
	selectedOrder?: OpensearchStorefrontAvailableData | null
}

export const SaleDelisting: React.FunctionComponent<SaleDelistingProps> = ({
	storefrontOrder,
	selectedOrder,
}) => {
	const {
		isDelist,
		updateSelectedOrder,
		spotPrice,
		openSearchFlowNFT,
		hasOffer,
		acceptOffer,
		singleOrder,
		orderFromChildAccount,
		isMainnet,
		collectionDisplayName,
	} = useFlowtyListingModalContext()

	const orderAddress = storefrontOrder
		? storefrontOrder?.[0]?.flowtyStorefrontAddress
		: selectedOrder?.flowtyStorefrontAddress
	return (
		<div className='flex flex-col gap-3 h-full w-full md:w-[400px] justify-between'>
			<div className='flex flex-col gap-3'>
				{storefrontOrder?.length > 1 ? (
					<>
						{isDelist.sale && (
							<p className='text-sm font-normal text-white'>
								Sale listing will be removed from the marketplace.
							</p>
						)}
						<FlowtyModalCarousel
							updateSelectedOrder={updateSelectedOrder}
							orders={storefrontOrder}
							selectedOrder={selectedOrder as OpensearchStorefrontAvailableData}
						/>
					</>
				) : (
					<div className='w-full h-full flex flex-col justify-between mt-4'>
						{isDelist.sale && (
							<p className='text-sm font-normal mb-4 text-white'>
								Sale listing will be removed from the marketplace.
							</p>
						)}
						<div className='w-full flex justify-between'>
							<p className='font-[400] text-[14px] text-white'>
								Purchase Price
							</p>
							<NftPrice
								token={
									storefrontOrder?.[0]?.paymentTokenName || SupportedTokens.FLOW
								}
								price={storefrontOrder?.[0]?.amount || 0}
								spotPriceValue={
									spotPrice
										? spotPrice.value *
										  Number(storefrontOrder?.[0]?.amount || 0)
										: undefined
								}
							/>
						</div>
					</div>
				)}
				{isDelist.sale && orderFromChildAccount && (
					<div className='text-orange-500 text-sm font-normal py-2'>
						NFT was listed while connected with
						{` ${orderAddress}`} as Main. Please sign in with{" "}
						{` ${orderAddress}`} to delist.
					</div>
				)}
				{!isDelist.sale && (
					<ShareActiveListing
						isMainnet={isMainnet}
						nft={openSearchFlowNFT as OpensearchFlowNFT}
						collectionDisplayName={collectionDisplayName}
						order={selectedOrder as Order}
						listingType='storefront'
					/>
				)}
			</div>
			{!isDelist.sale && hasOffer && !Boolean(singleOrder) ? (
				<FlowtyButton
					onClick={acceptOffer}
					text='ACCEPT BEST OFFER'
					variant='secondary'
					bgColor='white'
					btnSize='small'
				/>
			) : null}
		</div>
	)
}
