import { FlowtyTabs, TabConfig } from "ds-flowty"
import { inject, observer } from "mobx-react"
import React from "react"
import { AuthStoreProp } from "../../stores/AuthStore"
import { CopyWalletAddressButton } from "./CopyWalletAddressButton"
import { RentalHistory } from "./RentalHistory"

const UserRenterProfileDataComponent: React.FC<AuthStoreProp> = ({
	authStore,
}) => {
	const userActivityTab: TabConfig[] = [
		{ content: <RentalHistory />, id: "tag-1", label: "User Activity" },
	]

	return (
		<div className='pt-4 flex flex-col justify-center'>
			<div className='self-center'>
				<div className='flex justify-center items-center'>
					<CopyWalletAddressButton address={`${authStore?.loggedUser?.addr}`} />
				</div>
			</div>

			<div className='mt-3'>
				<FlowtyTabs
					activeTab={"tag-1"}
					tabs={userActivityTab}
					onChange={() => {}}
					type='primary'
					isCenter
				/>
			</div>
		</div>
	)
}

export const UserRenterProfileData = inject("authStore")(
	observer(UserRenterProfileDataComponent)
)
