import {
	FlowNFT,
	OfferCreated,
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
	OrdersType,
	SpotPrice,
	Valuation,
	InitiatedTransactionNotification,
	SupportedTokens,
} from "flowty-common"
import { AccountSummaries, Flowty } from "flowty-sdk"
import { Formik } from "formik"
import React, { useMemo } from "react"
import { ListingModalView } from "./components/ListingModalView/ListingModalView"
import { ModalWrapperListing } from "./components/common/ModalWrapper.Listing"
import { FlowtyListingModalContextProvider } from "./contexts/FlowtyListingModalContext/FlowtyListingModalContext"

export interface FlowtyListingFormValues {
	tokenType: string
	saleListingValue: string | number
	storefrontListingDuration: string | number
	amountToBorrow: string | number
	amountToRepay: string | number
	loanDuration: string | number
	listingDuration: string | number
	rentalListingDuration: string | number
	rentalFee: string | number
	rentalDuration: string | number
	refundableDeposit: string | number
	privateListingAddr: string
	transferReceiver: string
	loanEnableAutoRepayment: boolean
	isLoadingValuation: boolean
}

export interface FlowtyListingModalProps {
	isOpen: boolean
	collectionImage?: string | null
	initialListingType?: "rent" | "loan" | "transfer"
	onClose: () => void
	accountSummaries: AccountSummaries
	openSearchFlowNFT: OpensearchFlowNFT
	flowNFT?: FlowNFT
	selectedOffer?: OfferCreated
	createTransactionNotification?: (
		data: InitiatedTransactionNotification
	) => void
	collectionDisplayName?: string | null
	singleAction?: "delistLoan" | "delistRental" | "delistSale"
	singleOrder?: Order
	nftProviderPathIdentifier: string
	valuation: Valuation | null
	isLoadingValuation: boolean
	hasProvider: boolean
	spotPrice: SpotPrice
	strapiUrl: string
	addressesWithCollectionPublic?: string[]
	mixPanelFn: (event: string, data: unknown) => void
	flowty: Flowty
}

export const FlowtyListingModal: React.FunctionComponent<
	FlowtyListingModalProps
> = ({
	isOpen,
	initialListingType,
	onClose,
	accountSummaries,
	openSearchFlowNFT,
	flowNFT,
	selectedOffer,
	singleAction,
	singleOrder,
	createTransactionNotification,
	collectionDisplayName,
	nftProviderPathIdentifier,
	valuation,
	isLoadingValuation,
	hasProvider,
	collectionImage,
	spotPrice,
	strapiUrl,
	mixPanelFn,
	addressesWithCollectionPublic,
	flowty,
}) => {
	const accountSummariesValues = Object.values(accountSummaries || {})
	const mainAccount = accountSummariesValues?.find(acct => acct.isMain)
	const nftOrders: OrdersType | undefined = useMemo(() => {
		if (openSearchFlowNFT) {
			return {
				loan: openSearchFlowNFT.orders?.filter(
					order => order.listingKind === "loan"
				) as OpenSearchListingAvailableData[],
				rental: openSearchFlowNFT.orders?.filter(
					order => order.listingKind === "rental"
				) as OpensearchRentalAvailableData[],
				storefront: openSearchFlowNFT.orders?.filter(
					order => order.listingKind === "storefront"
				) as OpensearchStorefrontAvailableData[],
			}
		}
		return undefined
	}, [openSearchFlowNFT])

	return (
		<Formik
			initialValues={
				{
					amountToBorrow: "",
					amountToRepay: "",
					isLoadingValuation: false,
					listingDuration: 30,
					loanDuration: "",
					loanEnableAutoRepayment: false,
					privateListingAddr: "",
					refundableDeposit: "",
					rentalDuration: "",
					rentalFee: "",
					rentalListingDuration: 30,
					saleListingValue: "",
					storefrontListingDuration: 30,
					tokenType: mainAccount?.isDapper
						? SupportedTokens.DUC
						: SupportedTokens.USDC,
					transferReceiver: "",
				} as FlowtyListingFormValues
			}
			onSubmit={() => {}}
			validate={() => {
				return {}
			}}
		>
			{({ values, resetForm }) => {
				return (
					<FlowtyListingModalContextProvider
						flowNft={flowNFT}
						createTransactionNotification={createTransactionNotification}
						collectionDisplayName={collectionDisplayName}
						nftProviderPathIdentifier={nftProviderPathIdentifier}
						accountSummaries={accountSummaries}
						addressesWithCollectionPublic={addressesWithCollectionPublic}
						mainAccount={mainAccount}
						openSearchFlowNFT={openSearchFlowNFT}
						nftOrders={nftOrders}
						selectedOffer={selectedOffer}
						onClose={onClose}
						resetForm={resetForm}
						collectionImage={collectionImage ?? ""}
						spotPrice={spotPrice}
						values={values}
						valuation={valuation}
						isLoadingValuation={isLoadingValuation}
						hasProvider={hasProvider}
						initialListingType={initialListingType}
						singleAction={singleAction}
						singleOrder={singleOrder}
						mixPanelFn={mixPanelFn}
						flowty={flowty}
					>
						<ModalWrapperListing
							isOpen={isOpen}
							openSearchFlowNFT={openSearchFlowNFT}
							hasProvider={hasProvider}
							orderContent={
								<ListingModalView
									openSearchFlowNFT={openSearchFlowNFT}
									accountSummaries={accountSummaries}
									flowty={flowty}
									strapiUrl={strapiUrl}
								/>
							}
							mixPanelFn={mixPanelFn}
						/>
					</FlowtyListingModalContextProvider>
				)
			}}
		</Formik>
	)
}
