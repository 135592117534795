import React, { PropsWithChildren } from "react"
import { FlowtyModeSelector } from "../Shared/FlowtyModeSelector"

export const ProfileLayout: React.FC<
	PropsWithChildren<Record<string, never>>
> = ({ children }) => {
	return (
		<div className=''>
			<FlowtyModeSelector path='user-profile' />
			{children}
		</div>
	)
}
