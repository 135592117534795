/* eslint-disable @typescript-eslint/explicit-function-return-type */
import mixpanel from "mixpanel-browser"
import { mixpanelToken } from "./settings"

mixpanel.init(mixpanelToken)

export const actions = {
	alias: id => {
		mixpanel.alias(id)
	},
	identify: id => {
		mixpanel.identify(id)
	},
	people: {
		set: props => {
			mixpanel.people.set(props)
		},
	},
	track: (name, props) => {
		mixpanel.track(name, props)
	},
	track_links: (name, props) => {
		mixpanel.track_links(name, props)
	},
}
