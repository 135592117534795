import { db } from "../../firebase"
import { SpotPrice } from "flowty-common"
import { Log } from "../../util/Log"

export const getTokenSpotPrice = async (token: string): Promise<SpotPrice> => {
	Log("getTokenSpotPrice", token)
	const res = await db
		.collection("spotPrice")
		.where("symbol", "==", token)
		.orderBy("time", "desc")
		.limit(1)
		.get()
	if (res.empty || res.docs.length !== 1 || !res.docs[0].exists) {
		Log("no spot price found")
		return {
			currency: "USD",
			symbol: token,
			time: new Date(Date.now()),
			value: 1.0,
		}
	}

	const { docs } = res
	return docs[0].data() as SpotPrice
}
