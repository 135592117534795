import React from "react"
import { cn } from "../../util/cn"

const ALERT_STYLES = {
	// Need to replace these later with design system colors
	danger: "bg-[#dc3545] text-white",
	dark: "bg-[#212529] text-white",
	info: "bg-[#0dcaf0]  text-white",
	light: "bg-[#f8f9fa] text-black",
	primary: "bg-[#0d6efd] text-white",
	secondary: "bg-[#6c757d] text-white",
	success: "bg-[#198754] text-white",
	warning: "bg-[#ffc107] text-black",
}

interface AlertProps {
	variant: keyof typeof ALERT_STYLES
	children: React.ReactNode | React.ReactNode[]
	onClose?: () => void
	className?: string
}

export const Alert: React.FC<AlertProps> = ({
	variant = "primary",
	children,
	onClose,
	className,
}) => {
	return (
		<div className={cn(`p-4 rounded-lg ${ALERT_STYLES[variant]}`, className)}>
			{children}
			{onClose && (
				<button
					onClick={onClose}
					className='absolute top-2 right-2 text-xl leading-none hover:text-black'
				>
					&times;
				</button>
			)}
		</div>
	)
}
