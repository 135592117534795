import React from "react"
import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../../../types/FlowtyCreatorHubContextTypes"
import { ConfirmationPreviewTabSelector } from "../ConfirmationPreviewStep/components/ConfirmationPreviewTabSelector"

export const HeaderPreviewTab: React.FunctionComponent = () => {
	const {
		formTab,
		activeConfirmationPreviewTab,
		onConfirmationPageTabClick,
		confirmationPagePreviewTabs,
	} = useFlowtyCreatorHubContext()

	const getPreviewTitle = (): string | React.ReactNode => {
		switch (formTab) {
			case FormTabs.CustomizeCollectionFirstTab:
			case FormTabs.CustomizeCollectionSecondTab:
				return "Preview • Collection Page"
			case FormTabs.CustomizeDropTab:
				return "Preview • Drop Page"
			case FormTabs.ConfirmationTab:
				return (
					<div className='flex flex-row items-center space-x-1'>
						<div className='text-white text-[18px] font-bold hidden md:inline-block'>
							Preview •
						</div>
						<ConfirmationPreviewTabSelector
							tabs={confirmationPagePreviewTabs}
							activeTab={activeConfirmationPreviewTab}
							onTabClick={onConfirmationPageTabClick}
						/>
					</div>
				)
			default:
				return null
		}
	}

	return (
		<div className='text-white text-[18px] font-bold'>{getPreviewTitle()}</div>
	)
}
