import {
	OpensearchFlowNFT,
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import React from "react"
import { FlowtyClaimableBadge } from "../../../../FlowtyClaimableBadge"
import {
	AcceptOfferOrder,
	LoanRentalActionsOrder,
	MakeOfferOrder,
	ValuationData,
} from "../../../../Types/GlobalTypes"
import { PuchaseModalOfferType } from "../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { AcceptOfferOrderDisplay } from "./AcceptOfferOrderDisplay/AcceptOfferOrderDisplay"
import { CancelOfferOrderDisplay } from "./CancelOfferOrderDisplay/CancelOfferOrderDisplay"
import { FundedLoanRentalOrderDisplay } from "./FundedLoanRentalOrderDisplay/FundedLoanRentalOrderDisplay"
import { LoanOrderDisplay } from "./LoanOrderDisplay/LoanOrderDisplay"
import { MakeOfferOrderDisplay } from "./MakeOfferOrderDisplay/MakeOfferOrderDisplay"
import { RentalOrderDisplay } from "./RentalOrderDisplay/RentalOrderDisplay"
import { StorefrontOrderDisplay } from "./StorefrontOrderDisplay/StorefrontOrderDisplay"

interface CardOrderDisplayProps {
	order?:
		| Order
		| MakeOfferOrder
		| PuchaseModalOfferType
		| AcceptOfferOrder
		| LoanRentalActionsOrder
		| null
	isLoading?: boolean
	nft: OpensearchFlowNFT
	valuationData: ValuationData
	blendedLTV?: number | null
	isDapper?: boolean
	isTransfer?: boolean
	isLoadingValuation?: boolean
	isLoadingOrders?: boolean
	isLostAndFound?: boolean
}

export const NftCardOrderDisplay: React.FC<CardOrderDisplayProps> = ({
	isLoading,
	isLoadingValuation,
	isDapper,
	nft,
	order,
	valuationData,
	blendedLTV,
	isTransfer,
	isLoadingOrders,
	isLostAndFound,
}) => {
	const listingType = order?.listingKind

	if (isTransfer) {
		return null
	}

	if (isLostAndFound) {
		return (
			<div className='w-full h-[10rem] flex items-center justify-center'>
				<FlowtyClaimableBadge />
			</div>
		)
	}

	switch (listingType) {
		case "storefront":
			return (
				<StorefrontOrderDisplay
					isLoading={isLoading}
					isLoadingValuation={isLoadingValuation}
					order={order as OpensearchStorefrontAvailableData}
					nonCustodialOffer={nft?.bestNonCustodialOffer}
					dapperOffer={nft?.bestDapperOffer}
					valuationData={valuationData}
				/>
			)
		case "acceptOffer":
			return (
				<AcceptOfferOrderDisplay
					isLoading={isLoading}
					isLoadingValuation={isLoadingValuation}
					purchaseOrder={(order as AcceptOfferOrder).purchaseOrder}
					nonCustodialOffer={nft?.bestNonCustodialOffer}
					dapperOffer={nft?.bestDapperOffer}
					valuationData={valuationData}
				/>
			)
		case "makeOffer":
			return (
				<MakeOfferOrderDisplay
					isDapper={isDapper}
					isLoading={isLoading}
					isLoadingValuation={isLoadingValuation}
					order={order as MakeOfferOrder}
					nonCustodialOffer={nft?.bestNonCustodialOffer}
					dapperOffer={nft?.bestDapperOffer}
					valuationData={valuationData}
				/>
			)
		case "cancelOffer":
			return (
				<CancelOfferOrderDisplay
					isLoadingValuation={isLoadingValuation}
					nonCustodialOffer={nft?.bestNonCustodialOffer}
					dapperOffer={nft?.bestDapperOffer}
					isLoading={isLoading}
					offer={order as PuchaseModalOfferType}
					valuationData={valuationData}
				/>
			)
		case "loan":
			return (
				<LoanOrderDisplay
					order={order as OpenSearchListingAvailableData}
					isLoading={isLoading}
					blendedLTV={blendedLTV}
				/>
			)
		case "rental":
			return (
				<RentalOrderDisplay
					order={order as OpensearchRentalAvailableData}
					isLoading={isLoading}
				/>
			)
		case "loanBorrower":
			return (
				<FundedLoanRentalOrderDisplay
					order={order as LoanRentalActionsOrder}
					isLoading={isLoading}
				/>
			)
		case "rentalBorrower":
			return (
				<FundedLoanRentalOrderDisplay
					order={order as LoanRentalActionsOrder}
					isLoading={isLoading}
				/>
			)
		default:
			return isLoadingOrders ? (
				<div className='h-[10rem] flex justify-center items-center'>
					<div className='w-full h-full mt-4 animate-pulse bg-[#edf3f60a] rounded-lg'></div>
				</div>
			) : (
				<div className='h-[10rem] flex justify-center items-center'>
					<p className='text-sm font-normal text-[#ADB5BD]'>
						No Listing Available
					</p>
				</div>
			)
	}
}
