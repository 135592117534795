import { TransactionFollowBox } from "../../../TransactionFollowBox"
import { ShareNewPostMint } from "../../../TwitterShare/LaunchPad/ShareNewPostMint"

interface Props {
	transactionID: string | null
	dropPageLink: string | null
	collectionDisplayName: string
	isMainnet: boolean
}

export const DropSuccess: React.FunctionComponent<Props> = ({
	transactionID,
	dropPageLink,
	collectionDisplayName,
	isMainnet,
}) => {
	return (
		<div className='w-full flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<svg
				className='w-[124px] h-[124px]'
				width='124'
				height='124'
				viewBox='0 0 124 124'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M61.9999 111.6C89.3932 111.6 111.6 89.3933 111.6 62C111.6 34.6067 89.3932 12.4 61.9999 12.4C34.6066 12.4 12.3999 34.6067 12.3999 62C12.3999 89.3933 34.6066 111.6 61.9999 111.6ZM84.984 53.9841C87.4052 51.5628 87.4052 47.6372 84.984 45.216C82.5627 42.7947 78.6371 42.7947 76.2158 45.216L55.7999 65.6319L47.784 57.616C45.3627 55.1947 41.4371 55.1947 39.0158 57.616C36.5946 60.0372 36.5946 63.9628 39.0158 66.3841L51.4158 78.7841C53.8371 81.2053 57.7627 81.2053 60.184 78.7841L84.984 53.9841Z'
					fill='#04E5A3'
				/>
			</svg>
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Successful!
			</div>

			<TransactionFollowBox
				transactionID={transactionID}
				isMainnet={isMainnet}
			/>
			<ShareNewPostMint
				dropPageLink={dropPageLink ?? ""}
				collectionDisplayName={collectionDisplayName}
			/>
		</div>
	)
}
