import { Config } from "../types"

export const getAccountCollections = (
	config: Config
): string => `import ContractManager from ${config.contractAddresses.ContractManager}
import BaseCollection from ${config.contractAddresses.BaseCollection}
import NFTMetadata from ${config.contractAddresses.NFTMetadata}
import MetadataViews from ${config.contractAddresses.MetadataViews}

access(all) struct AccountContract {
    access(all) let address: Address
    access(all) let contracts: [CollectionContract]

    init(address: Address, contracts: [CollectionContract]) {
        self.address = address
        self.contracts = contracts
    }
}

access(all) struct CollectionContract {
    access(all) var name: String
    access(all) var collectionDisplay: MetadataViews.NFTCollectionDisplay

    init(_ name: String, _ collectionDisplay: MetadataViews.NFTCollectionDisplay) {
        self.name = name
        self.collectionDisplay = collectionDisplay
    }
}

access(all) fun main(userAddress: Address): AccountContract {
    let contractsData: [CollectionContract] = []

    let userAccount = getAccount(userAddress)
    let contractManagerCap = userAccount.capabilities.borrow<&ContractManager.Manager>(ContractManager.PublicPath)

    if (contractManagerCap == nil) {
        return AccountContract(address: userAddress, contracts: [])
    }

    let managerAccount: &Account = contractManagerCap!.getAccount()
    let managerAddress = contractManagerCap!.getAccount().address
    let contracts: &[String] = managerAccount.contracts.names


    if contracts.length == 0 {
        return AccountContract(address: userAddress, contracts: [])
    }

    for c in contracts {
        let baseContract = managerAccount.contracts.borrow<&{BaseCollection}>(name: c) ?? panic("collection not found")
        let identifier = baseContract.getType().identifier.concat(".NFT")
        let metadataContainer = baseContract.MetadataCap.borrow()!

        let collectionDisplay = baseContract.resolveContractView(resourceType: CompositeType(identifier)!, viewType: Type<MetadataViews.NFTCollectionDisplay>())! as! MetadataViews.NFTCollectionDisplay

        contractsData.append(CollectionContract(c, collectionDisplay))
    }
    return AccountContract(address: managerAddress, contracts: contractsData)
}`
