import { ExclamationCircleIcon } from "@heroicons/react/outline"
import React from "react"

interface NoCatalogNFTWrapperProps {
	title?: string
	text: string
}

export const NoCatalogNFTWrapper: React.FunctionComponent<
	NoCatalogNFTWrapperProps
> = ({ title, text }) => {
	return (
		<div className='w-full flex gap-3 justify-start items-center p-5 border border-warning bg-[#ffffff1a] rounded'>
			<ExclamationCircleIcon className='w-[40px] h-[40px] text-warning' />
			<div className='flex flex-col gap-2'>
				<p className='text-xs font-bold text-white'>{title}</p>
				<p className='text-xs font-normal text-white'>{text}</p>
			</div>
		</div>
	)
}
