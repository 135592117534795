import { useEffect, useState } from "react"
import { getAllNFTActivity } from "../../../util/getAllNFTStorefrontActivity"
import { OpensearchFlowNFT } from "flowty-common"
import { StorefrontEvent } from "../types/StorefrontEventTypes"
import { RentEvent } from "../types/RentEventTypes"
import { LoanEvent } from "../types/LoanEventTypes"
import { FilterFormValues } from "../types/FilterFormValues"
import {
	checkValuesFilter,
	getCurrentLoanFilter,
	getCurrentRentFilter,
	getCurrentStorefrontFilter,
} from "../../../util/historyUtils"
import { getNFTStorefrontActivity } from "../../../util/getNFTStorefrontActivity"
import { getNFTRentActivity } from "../../../util/getNFTRentActivity"
import { getNFTLoanActivity } from "../../../util/getNFTLoanActivity"
import useInfiniteScroll from "../../../../../hooks/infiniteScroll"

interface HistoryHookValues {
	bottomOfPageRef: (node: HTMLElement | null) => void
	historyData: Array<StorefrontEvent | RentEvent | LoanEvent>
	initialLoading: boolean
	loading: boolean
	finalLength: boolean
}

interface HistoryHookProps {
	openSearchNFT: OpensearchFlowNFT
	values: FilterFormValues
	page: number
	setPage: (page: number) => void
}

const eventQueryLimit = 20

export const useHistory = ({
	openSearchNFT,
	values,
	page,
	setPage,
}: HistoryHookProps): HistoryHookValues => {
	const [historyData, setHistoryData] = useState<
		Array<StorefrontEvent | RentEvent | LoanEvent>
	>([])
	const [initialLoading, setInitialLoading] = useState<boolean>(true)
	const [loading, setLoading] = useState<boolean>(false)
	const [dataCount, setDataCount] = useState(0)

	const listingLimit = page * eventQueryLimit

	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: loading,
			onInfiniteScroll() {
				if (!loading && !initialLoading) {
					setPage(page + 1)
				}
			},
		},
		[dataCount]
	)
	const type = checkValuesFilter(values)

	useEffect(() => {
		if (page === 1) {
			setInitialLoading(true)
		}
		if (page > 1) {
			setLoading(true)
		}

		if (type === "ALL") {
			const unsubscribe = getAllNFTActivity({
				cb: ({
					data,
					totalCount,
				}: {
					data: Array<StorefrontEvent | RentEvent | LoanEvent>
					totalCount: number
				}) => {
					setDataCount(totalCount)
					setHistoryData(data)
					if (page === 1) {
						setInitialLoading(false)
					} else {
						setLoading(false)
					}
				},
				limit: listingLimit,
				nftId: openSearchNFT?.id ?? "",
				nftType: openSearchNFT?.type ?? "",
			})

			return () => unsubscribe()
		} else if (type === "STOREFRONT") {
			const currentFilter = getCurrentStorefrontFilter(values.storefrontFilters)
			const unsubscribe = getNFTStorefrontActivity({
				cb: ({
					data,
					totalCount,
				}: {
					data: Array<StorefrontEvent | RentEvent | LoanEvent>
					totalCount: number
				}) => {
					setDataCount(totalCount)
					setHistoryData(data)
					if (page === 1) {
						setInitialLoading(false)
					} else {
						setLoading(false)
					}
				},
				filterBy: currentFilter,
				limit: listingLimit,
				nftId: openSearchNFT?.id ?? "",
				nftType: openSearchNFT?.type ?? "",
			})

			return () => unsubscribe()
		} else if (type === "RENTAL") {
			const currentFilter = getCurrentRentFilter(values.rentalFilters)
			const unsubscribe = getNFTRentActivity({
				cb: ({
					data,
					totalCount,
				}: {
					data: Array<StorefrontEvent | RentEvent | LoanEvent>
					totalCount: number
				}) => {
					setDataCount(totalCount)
					setHistoryData(data)
					if (page === 1) {
						setInitialLoading(false)
					} else {
						setLoading(false)
					}
				},
				filterBy: currentFilter,
				limit: listingLimit,
				nftId: openSearchNFT?.id ?? "",
				nftType: openSearchNFT?.type ?? "",
			})

			return () => unsubscribe()
		} else if (type === "LOAN") {
			const currentFilter = getCurrentLoanFilter(values.loanFilters)
			const unsubscribe = getNFTLoanActivity({
				cb: ({
					data,
					totalCount,
				}: {
					data: Array<StorefrontEvent | RentEvent | LoanEvent>
					totalCount: number
				}) => {
					setDataCount(totalCount)
					setHistoryData(data)
					if (page === 1) {
						setInitialLoading(false)
					} else {
						setLoading(false)
					}
				},
				filterBy: currentFilter,
				limit: listingLimit,
				nftId: openSearchNFT?.id ?? "",
				nftType: openSearchNFT?.type ?? "",
			})

			return () => unsubscribe()
		} else {
			setHistoryData([])
			setInitialLoading(false)
			setLoading(false)
		}
	}, [page, values])

	return {
		bottomOfPageRef,
		finalLength: dataCount <= historyData.length,
		historyData,
		initialLoading,
		loading,
	}
}
