import { apiURL } from "../../util/settings"
import axios, { AxiosError } from "axios"
import { FlowtyException } from "flowty-common"
import { actions as Mixpanel } from "../../util/Mixpanel"

export interface FlowtyCollections {
	catalog: Set<string>
	denylist: Set<string>
}

const extraDeniedTypes: string[] = [
	"A.fdae91e14e960079.GaiaPackNFT.NFT",
	"A.e81193c424cfd3fb.Doodles.NFT",
]

export const getFlowtyCollections = async (
	retries: number = 3,
	backoff: number = 1000
): Promise<FlowtyCollections> => {
	const url = `${apiURL}/catalog`
	try {
		const abortController = new AbortController()

		const res = await axios.get<FlowtyCollections>(url, {
			signal: abortController.signal,
		})
		if (res.status !== 200) {
			throw new Error(`Failed to fetch ${url}: ${res.statusText}`)
		}

		const denylist = new Set(res.data.denylist)
		extraDeniedTypes.forEach(type => denylist.add(type))

		return {
			catalog: new Set(res.data.catalog),
			denylist: new Set(extraDeniedTypes),
		}
	} catch (e) {
		Mixpanel.track("FAILED_TO_FETCH_CATALOG", { e })
		if (axios.isCancel(e)) {
			// No further action, error is handled
		} else if (
			e &&
			(e as AxiosError).isAxiosError &&
			(e as AxiosError).response === undefined // response will be undefined for network errors
		) {
			// Network Error: In this context, network errors are expected and can be safely ignored.
		}
		if (retries - 1 === 0) {
			// After 3 retries throw error to be caught by sentry
			throw new FlowtyException(
				e as AxiosError,
				{ params: (e as AxiosError).response },
				"FAILED_TO_FETCH_CATALOG"
			)
		}
		return getFlowtyCollections(retries - 1, backoff)
	}
}
