import { useField } from "formik"
import React, { useEffect, useRef } from "react"
import { FlowtyAccordion } from "../../../../FlowtyAccordion"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { NftPrice } from "../../common/NftPrice"
import { RentalFee } from "./RentListing"
import { getListingExpirationTime } from "../../../utils/FlowtyModalUtils"
interface RentListingStep2Props {
	rentalFees: RentalFee
	rentFormError: boolean
	rentalListingDuration: number
}

export const RentListingStep2: React.FunctionComponent<
	RentListingStep2Props
> = ({ rentalFees, rentFormError, rentalListingDuration }) => {
	const serviceTermRef = useRef<HTMLDivElement>(null)
	const modalTop = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (rentFormError) {
			if (serviceTermRef?.current?.scrollIntoView) {
				serviceTermRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
		} else {
			if (modalTop?.current?.scrollIntoView) {
				modalTop?.current?.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [rentFormError, modalTop])

	const [tokenType] = useField({ name: `tokenType` })
	const [privateListingAddr] = useField({ name: `privateListingAddr` })
	const { spotPrice } = useFlowtyListingModalContext()

	return (
		<div className='w-full h-full flex flex-col justify-between' ref={modalTop}>
			<div className='flex flex-col gap-[40px]'>
				<div className='flex flex-col gap-[18px]'>
					<p className='text-base font-semibold text-white'>
						Payout if Your NFT is Rented
					</p>
					<div className='flex flex-col gap-2'>
						<div className='w-full flex justify-between'>
							<p className='text-sm text-white font-normal ml-1'>Rental Fee</p>
							<NftPrice
								price={Number(rentalFees.rentalFee)}
								token={tokenType.value}
								spotPriceValue={
									Number(rentalFees.rentalFee) * (spotPrice?.value || 0)
								}
								toolTipCustomId='rentalFeeTooltip'
							/>
						</div>
						<FlowtyAccordion
							items={[
								{
									addOn: (
										<div className='flex items-start font-montserrat space-x-2'>
											<span className='font-bold'>-</span>
											<NftPrice
												price={Number(rentalFees.rentalFeeRoyalty)}
												token={tokenType.value}
												spotPriceValue={
													Number(rentalFees.rentalFeeRoyalty) *
													(spotPrice?.value || 0)
												}
												toolTipCustomId='rentalListingCreatorRoyalty'
											/>
										</div>
									),
									content: (
										<div className='text-xs text-neutral-500 font-montserrat'>
											<p className='text-gray text-sm font-normal ml-2'>
												Transferred directly to the creator of an NFT
												collection. This is not a Flowty fee.{" "}
												<a
													href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
													target='_blank'
													className='text-[#04E5A3] font-montserrat underline'
													rel='noreferrer'
												>
													Learn More
												</a>
											</p>
										</div>
									),
									id: "item-1",
									title: `Creator Royalty (${Number(
										rentalFees.rentalFeeRoyalty
									).toFixed(2)}%)`,
								},
								{
									addOn: (
										<div className='flex items-start space-x-2'>
											<span className='font-bold font-montserrat'>-</span>
											<NftPrice
												price={Number(rentalFees.flowtyFee)}
												token={tokenType.value}
												spotPriceValue={
													Number(rentalFees.flowtyFee) * (spotPrice?.value || 0)
												}
												toolTipCustomId='rentalListingFlowtyFee'
											/>
										</div>
									),
									content: (
										<div className='text-xs text-neutral-500 font-montserrat'>
											<p className='text-gray text-sm font-normal ml-2'>
												A fee charged by Flowty for providing the services on
												the platform.{" "}
												<a
													href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
													target='_blank'
													className='text-[#04E5A3] font-montserrat underline'
													rel='noreferrer'
												>
													Learn More
												</a>
											</p>
										</div>
									),
									id: "item-2",
									title: `Flowty Fee (${Number(rentalFees.flowtyFee).toFixed(
										2
									)}%)`,
								},
							]}
						/>

						<hr className='border-[#495057]'></hr>
						<div className='flex justify-between items-start'>
							<p className='text-sm font-semibold text-white'>
								You&apos;ll Receive
							</p>
							<NftPrice
								price={rentalFees.rentalFeeReceived}
								token={tokenType.value}
								spotPriceValue={
									rentalFees.rentalFeeReceived * (spotPrice?.value || 0)
								}
							/>
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-[18px]'>
					<p className='text-base font-semibold text-white'>
						Payout if Your NFT is Rented but not Returned
					</p>
					<div className='flex flex-col gap-2'>
						<div className='flex flex-col gap-3'>
							<div className='w-full flex items-start'>
								<div className='w-1/2'>
									<p className='text-sm text-white font-normal ml-1'>
										Refundable Deposit
									</p>
								</div>
								<div className='w-1/2'>
									<NftPrice
										price={Number(rentalFees.refundableDeposit)}
										token={tokenType.value}
										spotPriceValue={
											Number(rentalFees.refundableDeposit) *
											(spotPrice?.value || 0)
										}
										toolTipCustomId='refundableDeposit'
									/>
								</div>
							</div>
							<FlowtyAccordion
								items={[
									{
										addOn: (
											<div className='flex items-start font-montserrat space-x-2'>
												<span className='font-bold'>-</span>
												<NftPrice
													price={Number(rentalFees.rentalDepositRoyalty)}
													token={tokenType.value}
													spotPriceValue={
														Number(rentalFees?.rentalDepositRoyalty) *
														(spotPrice?.value || 0)
													}
													toolTipCustomId='rentalDepositRoyalty'
												/>
											</div>
										),
										badge: "Refundable",
										content: (
											<div className='text-xs text-neutral-500 font-montserrat'>
												<p className='text-gray text-sm font-normal ml-2'>
													Royalty on Refundable Deposit ONLY applies if a rented
													NFT is not returned.
												</p>
												<p className='text-gray text-sm font-normal ml-2'>
													Transferred directly to the creator of an NFT
													collection. This is not a Flowty fee.{" "}
													<a
														href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
														target='_blank'
														className='text-[#04E5A3] font-montserrat underline'
														rel='noreferrer'
													>
														Learn More
													</a>
												</p>
											</div>
										),
										id: "item-1",
										title: `Creator Royalty (${Number(
											rentalFees.rentalFeeRoyalty
										).toFixed(2)}%)`,
									},
								]}
							/>
						</div>
						<hr className='border-[#495057]'></hr>
						<div className='flex justify-between items-start'>
							<p className='text-sm font-semibold text-white '>
								You&apos;ll Receive
							</p>
							<NftPrice
								price={rentalFees.rentalDepositReceived}
								token={tokenType.value}
								spotPriceValue={
									rentalFees.rentalDepositReceived * (spotPrice?.value || 0)
								}
							/>
						</div>
					</div>
				</div>
				<div className='w-full flex justify-between'>
					<p className='text-sm text-white font-normal ml-1'>
						Your Listing Will Expire On
					</p>
					<p className='text-sm font-bold text-white'>
						{getListingExpirationTime(rentalListingDuration)}
					</p>
				</div>
			</div>

			<div
				className='flex flex-col gap-4 text-white font-normal'
				ref={serviceTermRef}
			>
				{privateListingAddr.value ? (
					<div className='py-2'>
						<div className='flex w-full justify-between text-sm md:text-lg bg-[#FFFFFF14] rounded-lg p-3'>
							<span className='text-sm md:text-base'>
								Private Listing Address:
							</span>
							<span className='text-sm md:text-base'>
								{privateListingAddr.value}
							</span>
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}
