import { firestore } from "../../firebase"

export const getItem = async <T>(
	id: string,
	collection: string
): Promise<T | null> => {
	const doc = await firestore.collection(collection).doc(id).get()
	if (!doc.exists) {
		return null
	}

	return doc.data() as T
}
