// this utility is helpful for control flow analysis as an exhaustiveness check
// e.g. a switch statement that evaluates a variable that is expected to be a
// prop of an enum or union or similar - by the `default` case, every possible
// enum/union value should have been evaluated, and code reaching this case
// means that there is a value outside the enum/union being passed in.
// alternatively, if there is a new property added to the enum/union, it will
// fail TS compilation since the value being passed in cannot be recognized as
// `never`, forcing the dev to define a case for the new property
export const assertNever = (x: never, message: string = ""): never => {
	throw new Error(
		`assertNever failed: got ${x} with type ${typeof x} ${message}`
	)
}

export const getMessageFromError = (e: unknown): string => {
	if (e instanceof Error) return e.message

	if (e instanceof String) return e as string

	if (typeof e === "string") return e as string

	return "Unknown Error"
}

export type ArrayType<A> = A extends Array<infer T> ? T : never

export function validateObject<OutputType extends Record<any, any>>(
	source: Record<any, any>,
	shape: Record<keyof OutputType, undefined> // ensures we have every key
): OutputType {
	const result: Partial<OutputType> = {}
	Object.keys(shape).forEach(key => {
		if (source[key] === undefined) {
			console.error("validateObject failed", key, source)
			throw new Error(`Missing key: ${key}`)
		}
		result[key as keyof OutputType] = source[key]
	})
	return result as OutputType
}
