import { OpensearchRentalAvailableData, Order } from "flowty-common"
import { FlowtyModalCarousel } from "../../../../FlowtyModalCarousel"
import { SingleRentalItemView } from "./SingleRentalItemView/SingleRentalItemView"
import { ExclamationCircleIcon } from "@heroicons/react/outline"

interface FundRentalViewProps {
	rentalOrders?: OpensearchRentalAvailableData[]
	singleRentalOrder?: OpensearchRentalAvailableData
	selectedOrder?: Order | null
	updateSelectedOrder: (order: Order) => void
	isDapper?: boolean
	isPrivateListing?: boolean
}

export const FundRentalView: React.FunctionComponent<FundRentalViewProps> = ({
	rentalOrders,
	singleRentalOrder,
	selectedOrder,
	updateSelectedOrder,
	isDapper,
	isPrivateListing,
}) => {
	return (
		<div className='flex flex-col justify-between h-full gap-2'>
			{rentalOrders && rentalOrders.length > 1 && (
				<FlowtyModalCarousel
					orders={rentalOrders}
					selectedOrder={selectedOrder as Order}
					updateSelectedOrder={updateSelectedOrder}
				/>
			)}
			{(singleRentalOrder || (rentalOrders && rentalOrders.length === 1)) && (
				<SingleRentalItemView
					order={
						rentalOrders
							? rentalOrders[0]
							: (singleRentalOrder as OpensearchRentalAvailableData)
					}
				/>
			)}
			{isPrivateListing && (
				<div className='w-full flex gap-3 justify-between items-center p-5 border border-warning bg-[#ffffff1a] rounded'>
					<ExclamationCircleIcon className='w-[40px] h-[40px] text-warning' />
					<div className='flex flex-col gap-2'>
						<p className='text-xs font-bold text-white'>Private Listing</p>
						<p className='text-xs font-normal text-white'>
							This is a private listing and can only be filled by the address{" "}
							{(selectedOrder as OpensearchRentalAvailableData).renter}
						</p>
					</div>
				</div>
			)}
			{!isDapper && (
				<div className='mt-auto flex flex-col gap-[0.75rem] justify-end items-center self-stretch'>
					<div className='text-white text-[14px] font-montserrat font-[400] flex flex-col gap-1'>
						<span>Notes:</span>
						<ul className='list-disc list-inside px-3'>
							<li className='leading-normal list-none'>
								<span className='mr-3'>•</span>Failure to return the NFT(s)
								before the end of the rental period will lead to forfeiture of
								your deposit.
							</li>
							<li className='leading-normal list-none'>
								<span className='mr-3'>•</span>Flowty cannot assist if there are
								Flowty or third-party outages or technical problems that prevent
								or delay transfer of the rented NFT(s) back to your account or
								back to the Owner.
							</li>
						</ul>
					</div>
				</div>
			)}
		</div>
	)
}
