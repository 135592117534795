import { useEffect, useState } from "react"

import classNames from "classnames"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { ProfileLayout } from "./ProfileLayout"
import { UserRentalActivityTable } from "./Tables/Rentals/UserRentalActivityTable"
import UserRentalTable from "./Tables/Rentals/UserRentalTable"

const rentalTabs = [
	{
		content: <UserRentalActivityTable />,
		name: "Activity",
	},
	{
		content: <UserRentalTable />,
		name: "Renter",
	},
	{
		content: <UserRentalTable asOwner />,
		name: "Owner",
	},
]

export const RentalHistory: React.FunctionComponent = () => {
	const [currentTab, setCurrentTab] = useState(rentalTabs[0].name)

	useEffect(() => {
		Mixpanel.track("Profile Activity Rentals Visited", { currentTab })
	}, [currentTab])

	return (
		<>
			<ProfileLayout />
			<div className='mt-4 transition duration-700'>
				<div>
					<nav className='-mb-px flex space-x-8' aria-label='loanTabs'>
						{rentalTabs.map(tab => (
							<div
								key={tab.name}
								onClick={() => {
									setCurrentTab(tab.name)
									Mixpanel.track(
										`Profile Activity Rentals Toggled: ${tab.name}`,
										{
											tab: tab.name,
										}
									)
								}}
								className={classNames(
									tab.name === currentTab
										? "border-indigo-500 text-white"
										: "border-transparent text-gray-500 hover:text-white hover:border-indigo-500",
									"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
								)}
							>
								{tab.name}
							</div>
						))}
					</nav>
					<div className='line-separator bg-gradient-to-r from-darkBg via-secondary to-darkBg' />
				</div>
				<div className='mt-8 mb-3'>
					{rentalTabs.map(tab => {
						if (tab.name === currentTab) {
							return <div key={tab.name}>{tab.content}</div>
						}
						return null
					})}
				</div>
			</div>
		</>
	)
}
