import { useLocation, useNavigate } from "react-router-dom"
import React, { useEffect } from "react"
import { getItem } from "../services/firestore/DocService"
import {
	assertNever,
	ListingAvailableBase,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import { delay } from "../util/delay"
import { Oval as OvalLoader } from "react-loader-spinner"
import { Log } from "../util/Log"

type SupportedPaths = "listing" | "rentals" | "storefront"

const pathToListingCollection = (path: SupportedPaths): string => {
	switch (path) {
		case "listing":
			return "listingAvailable"
		case "rentals":
			return "rentalAvailable"
		case "storefront":
			return "storefrontListingAvailable"
		default:
			assertNever(path)
	}
}

export const ListingRedirect: React.FC = () => {
	const navigate = useNavigate()

	const { pathname } = useLocation()

	const assignListingNFT = async (
		collection: string,
		listingID: string
	): Promise<void> => {
		let listing: ListingAvailableBase | null = null
		while (!listing) {
			listing = await getItem<ListingAvailableBase>(listingID, collection)

			if (!listing) {
				await delay(5000)
			}
		}

		const locationData = nftTypeAndIdToLocationData(
			listing?.nftType || "",
			listing?.nftID || ""
		)

		// generate the path to the nft being used in this listing
		const path = `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
		navigate(path)
	}

	useEffect(() => {
		// get the listing in question first by extracting the path and looking for the type and its id
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		// const collection = pathToListingCollection(listingType as SupportedPaths)
		// assignListingNFT(collection, resourceID)

		const [_, listingType, resourceID] = pathname.split("/")
		const collection = pathToListingCollection(listingType as SupportedPaths)
		Log("ListingRedirect", { collection, listingType, resourceID })
		assignListingNFT(collection, resourceID)
	}, [])

	return (
		<div className='upload-btn-wrapper flex items-center justify-center bg-greyDark'>
			<OvalLoader color='#04E5A3' height={210} width={210} />
		</div>
	)
}
