export interface GetNFTsRequest {
	owner: string
	limit: number
	offset: number
}

export interface NFTIDs {
	[key: string]: number[]
}

export interface GetNFTIDsRequest {
	owner: string
	ids: NFTIDs
}

export interface GetNFTsResponse {
	ownerAddress: string
	nfts: AlchemyNFT[]
	chain: string
	network: string
	nftCount: number
	offset: number
	error?: any
}

export interface AlchemyNFT {
	contract: Contract
	id: AlchemyID
	tokenUri: TokenURI
	media: Media[]
	metadata: Metadata
	title: string
	externalDomainViewUrl: string
	description: string
}

export interface Contract {
	name: string
	address: string
	externalDomain: string
	contractMetadata: ContractMetadata
}

interface ContractMetadata {
	storagePath: string
	publicPath: string
	publicCollectionName: string
}

export interface AlchemyID {
	tokenId: string
	tokenMetadata: TokenMetadata
}

interface TokenMetadata {
	uuid: string
}

interface TokenURI {
	raw: string
}

export interface Media {
	uri: string
	mimeType: string
}

export interface Metadata {
	metadata: MetadataEntry[]
}

export interface MetadataEntry {
	name: string
	value: any
}

export interface NFTMetadata {
	metadata: { [key: string]: any }
}

export interface NFT {
	contract: Contract
	id: AlchemyID
	tokenUri: TokenURI
	media: Media[]
	metadata: NFTMetadata
	title: string
	externalDomainViewUrl: string
	description: string
}

export interface InventoryResponse {
	inventory?: {
		nfts: NFT[]
		limit: number
		offset: number
	}
	error?: {
		message: string
	}
}

// alchemy has some weird support with FCL that we need to work around
// FCL v1 ONLY works w/ alchemy on HTTP at the /v2 path prefix w/ api key in url
// either FCL v0 or alchemy url only supports gRPC so url api auth fails
export const composeFlowAccessURL = (
	accessAPIBase: string,
	alchemyAPIKey: string = ""
): string => {
	const lowercasedEndpoint = accessAPIBase?.toLowerCase()

	return lowercasedEndpoint.includes("alchemy.com") &&
		lowercasedEndpoint.endsWith("v2")
		? `${lowercasedEndpoint}/${alchemyAPIKey}`
		: lowercasedEndpoint
}
