import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { getFlowtyCollections } from "../services/flow/SupportedCollections"
import { Log } from "../util/Log"
import { actions as Mixpanel } from "../util/Mixpanel"

interface CatalogContext {
	catalogCache: Set<string>
	denylistCache: Set<string>
}

const Context: React.Context<CatalogContext> = createContext<CatalogContext>({
	catalogCache: new Set(),
	denylistCache: new Set(),
})

const CatalogContext = ({ children }: { children: React.ReactNode }) => {
	const [denylistCache, setDenylistCache] = useState<Set<string>>(new Set())
	const [catalogCache, setCatalogCache] = useState<Set<string>>(new Set())

	useEffect(() => {
		const fetchFlowtyCollections = async () => {
			try {
				const response = await getFlowtyCollections()

				Log("getFlowtyCollections", { response })
				setCatalogCache(response.catalog)
				setDenylistCache(response.denylist)
			} catch (error) {
				Mixpanel.track("ERROR_FETCHING_FLOWTY_COLLECTIONS", { error })
			}
		}

		fetchFlowtyCollections()
	}, [])

	const providerValue = useMemo(
		() => ({ catalogCache, denylistCache }),
		[catalogCache, denylistCache]
	)
	return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

function useCatalogContext(): CatalogContext {
	return useContext(Context)
}

export { CatalogContext, useCatalogContext }
