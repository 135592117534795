import * as DOMPurify from "dompurify"
import * as parseDataURL from "data-urls"

const essenceSVG = "image/svg+xml"

const notBase64 = /[^A-Z0-9+/=]/i
const isBase64 = (str: string): boolean => {
	const len = str.length
	if (!len || len % 4 !== 0 || notBase64.test(str)) {
		return false
	}
	const firstPaddingChar = str.indexOf("=")
	return (
		firstPaddingChar === -1 ||
		firstPaddingChar === len - 1 ||
		(firstPaddingChar === len - 2 && str[len - 1] === "=")
	)
}

const handleDataUrl = (data: string): string => {
	// If this is a browser that sanitizing doesn't support, do not allow any data uris
	if (!DOMPurify.isSupported) {
		return ""
	}

	const parsed = parseDataURL(data)
	if (!parsed) {
		return ""
	}

	let parsedBody = new TextDecoder().decode(parsed.body)
	let sanitized = ""
	let encodingType = ""

	switch (parsed.mimeType.essence) {
		case essenceSVG:
			if (isBase64(parsedBody)) {
				// if this is a base64 string, decode is, so we can properly clean it
				parsedBody = atob(parsedBody)
				encodingType = "base64"
			} else {
				// default encoding is utf8, we will not accept anything else
				encodingType = "utf8"
			}

			sanitized = DOMPurify.sanitize(decodeURI(parsedBody))
			break
		default:
			return ""
	}

	if (encodingType === "base64") {
		sanitized = atob(sanitized)
	}

	return `data:${parsed.mimeType.essence};${encodingType},${encodeURIComponent(
		sanitized
	)}`
}

export const tryHandleDataUrl = (data: string): string => {
	try {
		return handleDataUrl(data)
	} catch (e) {
		return ""
	}
}
