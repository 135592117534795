import { actions as Mixpanel } from "./Mixpanel"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const playVideo = (
	event: React.MouseEvent<HTMLVideoElement, MouseEvent>
) => {
	// eslint-disable-next-line xss/no-mixed-html
	const videoElement = event.target as HTMLVideoElement
	const playPromise = videoElement.play()

	if (playPromise !== undefined) {
		playPromise
			.then(_ => {})
			.catch(error => Mixpanel.track("ERROR_PLAYING_NFT_VIDEO", { error }))
	}

	return
}
