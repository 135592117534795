import numeral from "numeral"
import { TopAndRecentSalesResponse } from "../types"

interface TopSalesMobileProps {
	sales: TopAndRecentSalesResponse["data"] | null
	isLoading: boolean
}

export const TopSalesMobile: React.FC<TopSalesMobileProps> = ({
	sales,
	isLoading,
}) => {
	return (
		<div className='flex flex-row gap-[8px] overflow-x-auto'>
			{isLoading
				? sales?.map((_, idx) => (
						<div
							key={idx}
							className={`flex-shrink-0 flex flex-col items-start justify-between p-[12px] h-[190px] w-[224px] rounded-[12px] gap-[8px] whitespace-nowrap cursor-wait bg-gray-400 animate-pulse`}
						></div>
				  ))
				: sales?.map((sale, index) => (
						<div
							key={index}
							className='flex-shrink-0 flex flex-col items-start justify-between p-[12px] rounded-[12px] gap-[8px] whitespace-nowrap'
							style={{
								backdropFilter: "blur(21.03209114074707px)",
								background:
									"linear-gradient(153deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.03) 100%)",
								boxShadow: "0px 4.11px 18.494px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<a
								href={sale.attributes.assetDetailUrl}
								className='flex flex-row gap-x-[10px]'
							>
								<img
									src={sale.attributes.image.data[0].attributes.url}
									alt={sale.attributes.assetTitle}
									className='w-[54px] h-[54px] rounded-[4px]'
								/>
								<div className='flex flex-col gap-y-[4px] justify-around'>
									<p className='text-[#F8F9FA] font-black uppercase text-[14px] tracking-[1.12px] leading-normal'>
										{sale.attributes.assetTitle}
									</p>
									<p className='text-[#ADB5BD] text-[11px] font-normal leading-normal'>
										{sale.attributes.serial}
									</p>
									<p className='text-[#ADB5BD] text-[11px] font-normal leading-normal'>
										{sale.attributes.series}
									</p>
								</div>
							</a>
							<div
								className='flex flex-col justify-around gap-[8px] px-[12px] py-[8px] rounded-[12px] w-full min-w-[200px]'
								style={{
									background:
										"linear-gradient(153deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.03) 100%)",
								}}
							>
								<div className='flex flex-row items-center justify-between'>
									<p className='text-[#ADB5BD] font-semibold'>Price</p>
									<p className='text-white font-bold text-[16px] leading-normal'>
										${numeral(sale.attributes.salePrice).format("0,0")}
									</p>
								</div>
								<a
									href={sale.attributes.buyerUrl}
									className='flex flex-row items-center justify-between'
								>
									<p className='text-[#ADB5BD] text-[12px] font-normal leading-normal'>
										Buyer
									</p>
									<p className='text-[#CED4DA] text-[12px] font-medium leading-normal'>
										{sale.attributes.buyerName}
									</p>
								</a>
								<div className='flex flex-row items-center justify-between'>
									<p className='text-[#ADB5BD] text-[12px] font-normal leading-normal'>
										Purchased On
									</p>
									<p className='text-[#CED4DA] text-[12px] font-medium leading-normal'>
										{sale.attributes.purchasedOn}
									</p>
								</div>
							</div>
						</div>
				  ))}
		</div>
	)
}
