import { FlowNFT } from "flowty-common"
import { Log } from "./Log"
import { IS_FLOW_EMULATOR, IS_MAINNET } from "./settings"

const contractTypeToDapperID: Record<string, string> = {
	"A.0b2a3299cc857e29.TopShot": "ad3260ba-a87c-4359-a8b0-def2cc36310b",
	"A.329feb3ab062d289.UFC_NFT": "24ed5274-68f4-42bc-8600-4a6f5fa17359",
	"A.427ceada271aa0b1.SturdyItems": "fb3cebea-cb28-4231-9d56-ea4ecd2f8ff7",
	"A.427ceada271aa0b1.SturdyTokens": "35c53829-12f1-4f0d-ac7f-9a9d2b7bc172",
	"A.87ca73a41bb50ad5.Golazos": "44924327-dea6-4915-a18d-85a827c5eb51",
	"A.8b148183c28ff88f.Gaia": "75dc054b-e405-4fbd-91c3-6dbfc0e691ca",
	"A.e4cf4bdc1751c65d.AllDay": "567226a0-f1fe-4c9a-ba85-423d020d646a",
}

export const getDapperInventoryURL = (
	nftType: string | undefined,
	nftId: string | undefined
): string | null => {
	Log("getDapperInventoryURL", { nftType })

	if (!nftType || !nftId) {
		return null
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, addr, name] = nftType.split(".")
	const contractID = `A.${addr}.${name}`

	const appID = contractTypeToDapperID[contractID] ?? null
	if (!appID) {
		return null
	}

	const url = `https://accounts.meetdapper.com/inventory?page=1&sort=DESC&token=${nftId}&contract=${contractID}&dapp=${appID}`
	return url
}

export const TOKEN_FORWARDING_ADDRESS = IS_MAINNET
	? "0xe544175ee0461c4b" // mainnet
	: IS_FLOW_EMULATOR
	? "0xf8d6e0586b0a20c7" // emulator
	: "0x51ea0e37c27a1f1a" // testnet
