import { ProfileLink } from "../common/ProfileLink"

interface Props {
	renterAddress: string
	owner: string
}

export const RentRentedRow: React.FunctionComponent<Props> = ({
	renterAddress,
	owner,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<ProfileLink address={renterAddress} /> rented NFT from{" "}
				<ProfileLink address={owner} />
			</p>
		</div>
	)
}
