import { Config } from "../../types"

export const getLostAndFoundTicketsScript = (config: Config): string => `
import LostAndFound from ${config.contractAddresses.LostAndFound}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
        
access(all) struct Ticket {
    // An optional message to attach to this item.
    access(all) let memo: String?
    // The address that it allowed to withdraw the item from this ticket
    access(all) let redeemer: Address
    //The type of the resource (non-optional) so that bins can represent the true type of an item
    access(all) let type: Type
    access(all) let typeIdentifier: String
    // State maintained by LostAndFound
    access(all) let redeemed: Bool
    access(all) let nftID: UInt64?
    access(all) let name : String?
    access(all) let description : String?
    access(all) let thumbnail : String?
    access(all) let ticketID : UInt64?
    access(all) let catalogIdentifier: String?
    init(_ ticket: &LostAndFound.Ticket, catalogIdentifier: String?) {
        self.memo = ticket.memo 
        self.redeemer = ticket.redeemer 
        self.type = ticket.type 
        self.typeIdentifier = ticket.type.identifier
        self.redeemed = ticket.redeemed 
        self.nftID = ticket.getNonFungibleTokenID()
        self.name = ticket.display?.name
        self.description = ticket.display?.description
        self.thumbnail = ticket.display?.thumbnail?.uri()
        self.ticketID = ticket.uuid
        self.catalogIdentifier = catalogIdentifier
    }
}

access(all) fun main(addr: Address): [Ticket] {
    let tickets: [Ticket] = []
    for ticket in LostAndFound.borrowAllTickets(addr: addr) {
        log(ticket.type.identifier)
        let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: ticket.type.identifier)
        var catalogIdentifier = ""
        if (catalogIdentifiers == nil) {
            continue
        } else {
            for k in catalogIdentifiers!.keys {
                if catalogIdentifiers![k] == true {
                    catalogIdentifier = k
                    break
                }
            }
        }
        tickets.append(Ticket(ticket, catalogIdentifier: catalogIdentifier))
    }
    
    return tickets
}`
