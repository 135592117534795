import { ReactNode } from "react"
import { FlowtyNumericInput } from "../../../../../../../FlowtyNumericInput"
import { CustomizeNumberDropdown } from "../CustomizeNumberDropdown/CustomizeNumberDropdown"
import { FlowIconWithTooltip } from "../CustomizeNumberDropdown/components/FlowIconWithTooltip"

interface CustomizeNumberInputProps {
	inputName: string
	title: string
	type: "flow" | "percentage"
	dropDownText: string
	inputError?: boolean
	errorMessage?: string | null
	placeHolder?: string
	toolTip?: ReactNode
	flowtyMarketplaceFee?: number | null
}

export const CustomizeNumberInput: React.FC<CustomizeNumberInputProps> = ({
	dropDownText,
	inputName,
	title,
	type,
	inputError = false,
	errorMessage,
	placeHolder,
	toolTip,
	flowtyMarketplaceFee,
}) => {
	return (
		<div className='w-full p-3 3xl:p-[18px] flex flex-col bg-[#ffffff0a] border border-[#343A40] gap-3 rounded-[5.333px] 3xl:rounded-lg'>
			<div className='w-full flex flex-col gap-1'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-x-2'>
						<div className='text-white text-[12px] 3xl:text-[18px] font-bold'>
							{title}
						</div>
						{toolTip}
					</div>
					<div className='flex flex-col gap-[4px]'>
						<div className='text-white flex items-center justify-end gap-[12px]'>
							<div className='text-sm 3xl:text-lg font-bold'>
								{type === "flow" ? (
									<div className='flex gap-1 items-center'>
										<FlowIconWithTooltip name={inputName} />
										<FlowtyNumericInput
											name={inputName}
											inputError={inputError}
											className={`h-[26.667px] 3xl:h-[40px] max-w-[52px] 3xl:max-w-[102px] text-[9.333px] 3xl:text-sm px-2 3xl:px-3 flex justify-center items-end rounded-lg border  bg-[#ffffff0a] text-right ${
												inputError ? "border-[#FF6E25]" : "border-[#343A40]"
											}`}
											placeholder={placeHolder}
										/>
									</div>
								) : (
									<div className='flex gap-1 items-center'>
										<FlowtyNumericInput
											name={inputName}
											inputError={inputError}
											className={`h-[26.667px] 3xl:h-[40px] max-w-[52px] 3xl:max-w-[102px] text-[9.333px] 3xl:text-sm px-2 3xl:px-3 flex justify-center items-end rounded-lg border  bg-[#ffffff0a] text-right ${
												inputError ? "border-[#FF6E25]" : "border-[#343A40]"
											}`}
											placeholder={placeHolder}
										/>
										<div className='3xl:ml-2'>%</div>
									</div>
								)}
							</div>
						</div>
						{errorMessage && (
							<p className='w-full text-end text-[#FF6E25] text-xs whitespace-nowrap'>
								{errorMessage}
							</p>
						)}
					</div>
				</div>
			</div>
			<div className='flex items-center justify-between w-full'>
				<div className='text-[#ADB5BD] font-semibold text-xs 3xl:text-lg'>
					{dropDownText}
				</div>
			</div>
			<CustomizeNumberDropdown
				inputName={inputName}
				flowtyMarketplaceFee={flowtyMarketplaceFee}
			/>
		</div>
	)
}
