import { useEffect, useState } from "react"
import { getAllUserStorefrontActivity } from "../util/getAllUserStorefrontActivity"
import { FilterBy, StorefrontData } from "../types"

interface UserAllStorefrontActivityProps {
	filterBy?: FilterBy
	limit: number
	loggedUserAddress: string
	role: "buyer" | "seller" | "buyerAndSeller"
}

export function useAllUserStorefrontActivity({
	filterBy,
	role,
	loggedUserAddress,
	limit,
}: UserAllStorefrontActivityProps): {
	storefrontData: StorefrontData[]
	loading: boolean
	dataCount: number
} {
	const [storefrontData, setStorefrontData] = useState<StorefrontData[]>([])

	const [loading, setLoading] = useState<boolean>(true)

	const [dataCount, setDataCount] = useState(0)

	useEffect(() => {
		setLoading(true)
		const unsubscribe = getAllUserStorefrontActivity({
			cb: ({
				data,
				totalCount,
			}: {
				data: StorefrontData[]
				totalCount: number
			}) => {
				setStorefrontData(data)
				setDataCount(totalCount)
				setLoading(false)
			},
			filterBy,
			limit,
			loggedUserAddress,
			role,
		})

		return () => unsubscribe()
	}, [
		loggedUserAddress,
		limit,
		filterBy?.path,
		filterBy?.max,
		filterBy?.min,
		role,
	])

	return { dataCount, loading, storefrontData }
}
