import { Order } from "flowty-common"
import { PurchaseOrderWrapper } from "../PurchaseOrderWrapper/PurchaseOrderWrapper"
import { AccountSummaries } from "flowty-sdk"

interface PurchaseSingleViewProps {
	singleListing: Order
	isDapper: boolean
	accountSummaries: AccountSummaries
}

export const PurchaseSingleView: React.FunctionComponent<
	PurchaseSingleViewProps
> = ({ singleListing, isDapper, accountSummaries }) => {
	return (
		<>
			{singleListing.listingKind === "rental" ? (
				<PurchaseOrderWrapper
					isDapper={isDapper}
					accountSummaries={accountSummaries}
					singleRentalOrder={singleListing}
				/>
			) : singleListing.listingKind === "storefront" ? (
				<PurchaseOrderWrapper
					isDapper={isDapper}
					accountSummaries={accountSummaries}
					singleStorefrontOrder={singleListing}
				/>
			) : (
				<PurchaseOrderWrapper
					isDapper={isDapper}
					accountSummaries={accountSummaries}
					singleLoanOrder={singleListing}
				/>
			)}
		</>
	)
}
