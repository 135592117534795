import { NotificationType, OfferNotificationContent } from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"

export type OfferNotificationItemsProps = OfferNotificationContent & {
	queueMessage?: ({
		type,
		id,
	}: {
		type:
			| NotificationType.OfferAccepted
			| NotificationType.OfferReceived
			| NotificationType.OfferExpired
		id: string
	}) => void
}

export const OfferNotificationItems: React.FunctionComponent<
	OfferNotificationItemsProps
> = ({
	amount,
	transactionID,
	imageUrl,
	timestamp,
	title,
	token,
	nftId,
	nftType,
	payerAddress,
	type,
	listingType,
	offerResourceID,
}) => {
	const message = getNotificationMessage({
		amount,
		title,
		token,
		type,
		wallet: payerAddress,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			token={token}
			nftId={nftId}
			nftType={nftType}
			flowtyStorefrontAddress={payerAddress}
			listingResourceID={offerResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType={listingType}
			type={type}
		/>
	)
}
