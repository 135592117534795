import Swal from "sweetalert2"
import { DiscordIcon } from "./DiscordIcon"
import { ShareIcon } from "./ShareIcon"
import { TwitterIcon } from "./TwitterIcon"
import { WebsiteIcon } from "./WebsiteIcon"
import { InstagramIcon } from "./InstagramIcon"

interface SocialLinksProps {
	websiteLink: string | undefined
	twitter: string | undefined
	discord: string | undefined
	instagram: string | undefined
	isTrustableCollection?: boolean
}

export const SocialLinks: React.FunctionComponent<SocialLinksProps> = ({
	websiteLink,
	twitter,
	discord,
	instagram,
	isTrustableCollection,
}) => {
	const handleCopyClick = (): void => {
		;(navigator as Navigator).clipboard.writeText(window.location.href)
		Swal.fire({
			icon: "success",
			showConfirmButton: false,
			timer: 1500,
			title: "Link Copied",
		})
	}

	const onSocialLinkClick = (link: string, openModal: () => void): void => {
		if (isTrustableCollection) {
			window.open(link, "_blank")
		} else {
			openModal()
		}
	}

	return (
		<div className={`flex gap-2 justify-end`}>
			{websiteLink && (
				<WebsiteIcon link={websiteLink} onSocialLinkClick={onSocialLinkClick} />
			)}
			{twitter && (
				<TwitterIcon link={twitter} onSocialLinkClick={onSocialLinkClick} />
			)}
			{discord && (
				<DiscordIcon link={discord} onSocialLinkClick={onSocialLinkClick} />
			)}
			{instagram && (
				<InstagramIcon link={instagram} onSocialLinkClick={onSocialLinkClick} />
			)}
			<ShareIcon
				shareFn={() => {
					handleCopyClick()
				}}
			/>
		</div>
	)
}
