/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChildAccountDisplay, Path } from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { createContext, useContext, useState } from "react"
import { fetchSpecificFlowNFTContract } from "../services/firestore/nftIInfo"
import { AuthStoreProp } from "../stores/AuthStore"
import { Log } from "../util/Log"
import { flowty } from "../config/config"
import { actions as Mixpanel } from "../util/Mixpanel"

interface ChildAccountKV {
	[key: string]: ChildAccountDisplay
}

interface NFTProviderStatus {
	[address: string]: {
		[data: string]: [Path]
	}
}

export interface FTProviderStatus {
	[address: string]: {
		[data: string]: Path
	}
}

interface FTBalance {
	[data: string]: string
}

export interface FTBalances {
	[address: string]: [FTBalance]
}

interface HybridCustodyContextValue {
	hybridCustodyNFTStatus: NFTProviderStatus
	publicAccountNftProviders: NFTProviderStatus
	iterateAndRunScript: (
		foundCollectionTypes: string[],
		childAccounts: ChildAccountKV,
		parent: string
	) => void
}

interface HybridCustodyProviderProps extends AuthStoreProp {
	children: React.ReactNode
}

const HybridCustodyContext = createContext<HybridCustodyContextValue>({
	hybridCustodyNFTStatus: {},
	iterateAndRunScript: () => {},
	publicAccountNftProviders: {},
})

const HybridCustodyProvider: React.FC<HybridCustodyProviderProps> = ({
	children,
	authStore,
}) => {
	const [hybridCustodyNFTStatus, sethybridCustodyNFTStatus] =
		useState<NFTProviderStatus>({})

	const [publicAccountNftProviders, setPublicAccountNftProviders] =
		useState<NFTProviderStatus>({})

	const iterateAndRunScript = async (
		availableFacets: { [key: string]: any },
		accounts: { [key: string]: ChildAccountDisplay },
		parent: string
	): Promise<void> => {
		if (!accounts) return
		Object.entries(accounts).forEach(async ([addr]) => {
			Object.entries(availableFacets).forEach(async ([_, value]) => {
				const split = value.split(".")
				const collectionAddress = split[0]
				const collectionName = split[1]
				if (collectionAddress && collectionName) {
					try {
						const res = await fetchSpecificFlowNFTContract({
							collectionAddress,
							collectionName,
						})
						// TODO remove this and handle exception...adding this so I can test
						if (
							!flowty.config.crescendo &&
							!res?.collectionData?.providerPath?.identifier
						) {
							Log(
								"No provider path for the following collection: ",
								collectionName,
								{ res }
							)
							return
						}

						const scriptResult =
							await flowty.scripts.getHybridCustodyNFTProviderPathsForStorage(
								parent,
								addr,
								res?.collectionData?.storagePath?.identifier ?? ""
							)
						if (authStore?.loggedUser?.addr === parent) {
							sethybridCustodyNFTStatus(oldProviderStatus => {
								return {
									...oldProviderStatus,
									[addr]: {
										...oldProviderStatus[addr],
										[value]: scriptResult,
									},
								}
							})
						} else {
							setPublicAccountNftProviders(oldProviderStatus => {
								return {
									...oldProviderStatus,
									[addr]: {
										...oldProviderStatus[addr],
										[value]: scriptResult,
									},
								}
							})
						}
					} catch (error) {
						Mixpanel.track("ERROR_GETTING_HYBRID_CUSTODY_NFT_PROVIDER_PATHS", {
							error,
						})
					}
				}
			})
		})
	}

	return (
		<HybridCustodyContext.Provider
			value={{
				hybridCustodyNFTStatus,
				iterateAndRunScript,
				publicAccountNftProviders,
			}}
		>
			{children}
		</HybridCustodyContext.Provider>
	)
}

const HybridCustodyProviderAuthStore = inject("authStore")(
	observer(HybridCustodyProvider)
)

const useHybridCustodyContext = (): HybridCustodyContextValue => {
	const context = useContext(HybridCustodyContext)
	if (context === undefined) {
		throw new Error(
			"useHybridCustodyContext must be used within a HybridCustodyProvider"
		)
	}
	return context
}

export {
	HybridCustodyProviderAuthStore as HybridCustodyProvider,
	useHybridCustodyContext,
}
