import { OfferCreated, SupportedTokens, getProviderImage } from "flowty-common"
import React from "react"
import OfferNftPrice from "../../OfferNftPrice"

export interface MakeOfferType {
	amount: number
	paymentTokenName: SupportedTokens
}

interface BestOfferDisplayProps {
	dapperOffer?: OfferCreated | MakeOfferType | null
	nonCustodialOffer?: OfferCreated | MakeOfferType | null
}

export const BestOfferDisplay: React.FC<BestOfferDisplayProps> = ({
	dapperOffer,
	nonCustodialOffer,
}) => {
	return (
		<>
			<div className='flex flex-col items-end gap-2'>
				<span
					className={`text-neutral-600 text-base font-montserrat text-right`}
				>
					Best Offer
				</span>
				<div className='flex flex-col text-neutral-600'>
					{nonCustodialOffer && nonCustodialOffer.amount ? (
						<div className='flex flex-row gap-1 items-center justify-between text-bold'>
							<div className='bg-[#ffffff2e] rounded flex items-center justify-center p-1 mx-1'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='14'
									height='14'
									viewBox='0 0 9 11'
									fill='none'
								>
									<g clipPath='url(#clip0_2479_1716)'>
										<path
											d='M4.3134 3.06307C3.37349 3.06307 2.45605 3.42549 1.7702 4.06839C1.01794 4.77374 0.54188 5.8154 0.268994 6.79528C0.090263 7.4362 0.000732422 8.10123 0.000732422 8.76693C0.000732422 8.96813 0.0089917 9.16767 0.0248495 9.3649C0.0443414 9.60475 0.277914 9.77093 0.510495 9.70816C0.713343 9.65365 0.926762 9.62424 1.14679 9.62424C1.59874 9.62424 2.02227 9.74714 2.38535 9.96188C2.39427 9.96717 2.40286 9.97245 2.41178 9.97741C3.00182 10.3293 3.69791 10.5225 4.44026 10.4977C6.37656 10.4337 7.95838 8.85811 8.02974 6.92214C8.10771 4.80413 6.41422 3.06274 4.31373 3.06274L4.3134 3.06307ZM4.3134 8.48083C3.37481 8.48083 2.61397 7.71998 2.61397 6.78173C2.61397 5.84348 3.37481 5.0823 4.3134 5.0823C5.25198 5.0823 6.01282 5.84315 6.01282 6.78173C6.01282 7.72031 5.25198 8.48083 4.3134 8.48083Z'
											fill='#14AAFF'
										/>
										<path
											d='M2.78437 1.89218C2.78437 2.38212 2.52767 2.83671 2.10612 3.08647C1.84149 3.2434 1.59338 3.42874 1.36873 3.63985C0.870197 4.10699 0.498199 4.68018 0.22267 5.24842C0.168159 5.36009 0 5.32044 0 5.19622V1.89218C0 1.12341 0.62341 0.5 1.39218 0.5C2.16096 0.5 2.78437 1.12341 2.78437 1.89218Z'
											fill='#0077FF'
										/>
									</g>
									<defs>
										<clipPath id='clip0_2479_1716'>
											<rect
												width='8.03198'
												height='10'
												fill='white'
												transform='translate(0 0.5)'
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							<div className='flex-1'>
								<OfferNftPrice
									price={(nonCustodialOffer as OfferCreated)?.amount || 0}
									token={
										(nonCustodialOffer as OfferCreated)
											?.paymentTokenName as SupportedTokens
									}
									toolTipCustomId='make-offer-nonCustodial'
								/>
							</div>
						</div>
					) : (
						<div className='flex flex-row gap-1 items-center justify-between text-bold'>
							<div className='bg-[#ffffff2e] rounded flex items-center justify-center p-1 mx-1'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='14'
									height='14'
									viewBox='0 0 9 11'
									fill='none'
								>
									<g clipPath='url(#clip0_2479_1716)'>
										<path
											d='M4.3134 3.06307C3.37349 3.06307 2.45605 3.42549 1.7702 4.06839C1.01794 4.77374 0.54188 5.8154 0.268994 6.79528C0.090263 7.4362 0.000732422 8.10123 0.000732422 8.76693C0.000732422 8.96813 0.0089917 9.16767 0.0248495 9.3649C0.0443414 9.60475 0.277914 9.77093 0.510495 9.70816C0.713343 9.65365 0.926762 9.62424 1.14679 9.62424C1.59874 9.62424 2.02227 9.74714 2.38535 9.96188C2.39427 9.96717 2.40286 9.97245 2.41178 9.97741C3.00182 10.3293 3.69791 10.5225 4.44026 10.4977C6.37656 10.4337 7.95838 8.85811 8.02974 6.92214C8.10771 4.80413 6.41422 3.06274 4.31373 3.06274L4.3134 3.06307ZM4.3134 8.48083C3.37481 8.48083 2.61397 7.71998 2.61397 6.78173C2.61397 5.84348 3.37481 5.0823 4.3134 5.0823C5.25198 5.0823 6.01282 5.84315 6.01282 6.78173C6.01282 7.72031 5.25198 8.48083 4.3134 8.48083Z'
											fill='#14AAFF'
										/>
										<path
											d='M2.78437 1.89218C2.78437 2.38212 2.52767 2.83671 2.10612 3.08647C1.84149 3.2434 1.59338 3.42874 1.36873 3.63985C0.870197 4.10699 0.498199 4.68018 0.22267 5.24842C0.168159 5.36009 0 5.32044 0 5.19622V1.89218C0 1.12341 0.62341 0.5 1.39218 0.5C2.16096 0.5 2.78437 1.12341 2.78437 1.89218Z'
											fill='#0077FF'
										/>
									</g>
									<defs>
										<clipPath id='clip0_2479_1716'>
											<rect
												width='8.03198'
												height='10'
												fill='white'
												transform='translate(0 0.5)'
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							<div className='flex-1'>
								<div className='nft-price d-flex align-items-center justify-end small '>
									<div className='text-neutral-600 text-bold text-base font-montserrat d-inline-block ms-1 text-end'>
										-
									</div>
								</div>
							</div>
						</div>
					)}
					{dapperOffer && dapperOffer.amount ? (
						<div className='flex flex-row gap-1 items-center justify-between text-bold mt-1'>
							<div className='bg-[#ffffff2e] rounded flex items-center justify-center p-1 mx-1'>
								<img
									src={getProviderImage("dapper")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<OfferNftPrice
									price={(dapperOffer as OfferCreated)?.amount || 0}
									token={
										(dapperOffer as OfferCreated)
											?.paymentTokenName as SupportedTokens
									}
									toolTipCustomId='make-offer-dapper'
								/>
							</div>
						</div>
					) : (
						<div className='flex flex-row gap-1 items-center justify-between text-bold mt-1'>
							<div className='bg-[#ffffff2e] rounded flex items-center justify-center p-1 mx-1'>
								<img
									src={getProviderImage("dapper")}
									alt='account icon'
									className='h-4 w-4 rounded'
								/>
							</div>
							<div className='flex-1'>
								<div className='nft-price d-flex align-items-center justify-end small '>
									<div className='text-neutral-600 text-bold text-base font-montserrat d-inline-block ms-1 text-end'>
										-
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
