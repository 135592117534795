/* eslint-disable jsx-a11y/anchor-is-valid */
import { FlowNFTContract } from "flowty-common"
import SearchResult from "../SearchResult"
import { storeRecentSearchResult } from "../../util/searchHistory"
import React from "react"

interface ResultsProps {
	searchResults: FlowNFTContract[]
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

export const Results: React.FunctionComponent<ResultsProps> = ({
	searchResults,
	onMouseEnter,
	onMouseLeave,
}) => {
	return (
		<div
			className='flex rounded-[6px] searchResultsBackground flex-col gap-[14px] items-end'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className='flex flex-col items-start justify-center w-full'>
				<div className='flex p-[12px] w-full'>
					<span className='text-[#ADB5BD] text-[14px]'>Results</span>
				</div>
				<div className='overflow-auto max-h-[300px] w-full'>
					{searchResults
						.filter(result => !!result?.name)
						.map(result => (
							<a
								key={result.address}
								onClick={() => storeRecentSearchResult(result)}
								href={
									!process.env.IS_STORYBOOK
										? `/collection/${result.address}/${result?.name}`
										: "#"
								}
							>
								<SearchResult result={result} />
							</a>
						))}
				</div>
			</div>
		</div>
	)
}
