import { NotificationType, LoanNotificationContent } from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"

export type LoanNotificationItemsProps = LoanNotificationContent & {
	loggedUserAddress?: string
	queueMessage?: ({
		type,
		id,
	}: {
		type:
			| NotificationType.LoanFunded
			| NotificationType.LoanRepaid
			| NotificationType.LoanSettled
			| NotificationType.ListingInvalid
		id: string
	}) => void
}

export const LoanNotificationItems: React.FunctionComponent<
	LoanNotificationItemsProps
> = ({
	amount,
	transactionID,
	imageUrl,
	timestamp,
	title,
	token,
	marketplaceAmount,
	nftId,
	nftType,
	lenderAddress,
	type,
	listingType,
	listingResourceID,
	loggedUserAddress,
}) => {
	const isLender = loggedUserAddress === lenderAddress
	const message = getNotificationMessage({
		amount:
			NotificationType.LoanRepaid || (NotificationType.LoanSettled && isLender)
				? marketplaceAmount
				: amount,
		loggedUserAddress,
		title,
		token,
		type,
		wallet: lenderAddress,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			token={token}
			nftId={nftId}
			nftType={nftType}
			listingResourceID={listingResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType={listingType}
			type={type}
		/>
	)
}
