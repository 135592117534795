import { ReactComponent as TwitterIcon } from "../../../assets/twitterSolid.svg"
import { ReactComponent as DiscordIcon } from "../../../assets/discordSolid.svg"
import { ReactComponent as InstagramIcon } from "../../../assets/instagramSolid.svg"
import { ReactComponent as WebsiteIcon } from "../../../assets/WebsiteIcon.svg"

interface CreatorHubDisplayFieldProps {
	twitterAccount?: string
	discordAccount?: string
	instagramAccount?: string
	websiteUrl?: string
}
export const CreatorHubSocialsField: React.FC<CreatorHubDisplayFieldProps> = ({
	twitterAccount,
	discordAccount,
	instagramAccount,
	websiteUrl,
}) => {
	const linkFormatter = (
		url: string,
		type?: "DISCORD" | "INSTAGRAM" | "TWITTER"
	): string => {
		if (url.includes("http://") || url.includes("https://")) {
			return url
		}
		switch (type) {
			case "DISCORD":
				return `https://discord.gg/${url}`
			case "INSTAGRAM":
				return `https://www.instagram.com/${url}`
			case "TWITTER":
				return `https://www.twitter.com/${url}`
			default:
				return `https://${url}`
		}
	}

	return (
		<div className='w-full'>
			<div className='text-white text-[18px] font-black pb-2'>
				External Links
			</div>
			<div className='flex flex-col gap-[20px] text-white'>
				<div className='flex items-center gap-3'>
					<WebsiteIcon />
					{websiteUrl ? (
						<a
							className='text-[14px]'
							href={linkFormatter(websiteUrl)}
							target='_blank'
							rel='noreferrer'
						>
							{linkFormatter(websiteUrl)}
						</a>
					) : (
						<p>-</p>
					)}
				</div>

				<div className='flex items-center gap-3'>
					<TwitterIcon />
					{twitterAccount ? (
						<a
							className='text-[14px]'
							href={linkFormatter(twitterAccount, "TWITTER")}
							target='_blank'
							rel='noreferrer'
						>
							{linkFormatter(twitterAccount, "TWITTER")}
						</a>
					) : (
						<p>-</p>
					)}
				</div>

				<div className='flex items-center gap-3'>
					<DiscordIcon />
					{discordAccount ? (
						<a
							href={`${linkFormatter(discordAccount, "DISCORD")}`}
							className='text-[14px]'
							target='_blank'
							rel='noreferrer'
						>{`${linkFormatter(discordAccount, "DISCORD")}`}</a>
					) : (
						<p>-</p>
					)}
				</div>

				<div className='flex items-center gap-3'>
					<InstagramIcon />
					{instagramAccount ? (
						<a
							className='text-[14px]'
							href={linkFormatter(instagramAccount, "INSTAGRAM")}
							target='_blank'
							rel='noreferrer'
						>
							{linkFormatter(instagramAccount, "INSTAGRAM")}
						</a>
					) : (
						<p>-</p>
					)}
				</div>
			</div>
		</div>
	)
}
