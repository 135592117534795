import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { FlowtyAPI, GlobalSearchResponse } from "../services/FlowtyAPI"

export const useSearchCollections = (
	initialQuery = ""
): {
	collections: GlobalSearchResponse | null
	isLoading: boolean
	search: string
	setSearch: Dispatch<SetStateAction<string>>
} => {
	const [search, setSearch] = useState(initialQuery)
	const [isLoading, setIsLoading] = useState(false)
	const [collections, setCollections] = useState<GlobalSearchResponse | null>(
		null
	)

	const requestAborter = useRef<AbortController>()

	const executeSearch = async (query: string): Promise<void> => {
		setIsLoading(true)
		requestAborter.current?.abort()
		const aborter = new AbortController()
		requestAborter.current = aborter

		const normalizedQuery = query.replaceAll(/\s/g, "")

		try {
			const searchRes = await FlowtyAPI.globalSearch(
				{ query: normalizedQuery },
				{ signal: aborter.signal }
			)

			setCollections(searchRes)
		} catch (err) {
			if ((err as Error).message !== "canceled") {
				console.error("Search Request Failed", err)
			}
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (search) {
			void executeSearch(search)
		} else {
			setCollections(null)
		}
	}, [search])

	return { collections, isLoading, search, setSearch }
}
