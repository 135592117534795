import { FCLTransactionResult } from "flowty-common"
import { Err, sendMutation } from "../../utils/Utils"
import { CancelOfferProps } from "./CancelOfferTypes"
import { Config } from "../../types"
import { getCancelOfferTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class CancelOffer {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	cancelOffer = async ({
		txAvailableCallback,
		token,
		offerResourceID,
	}: CancelOfferProps): Promise<FCLTransactionResult> => {
		const txArguments = [fcl.arg(offerResourceID.toString(), t.UInt64)]
		const isDapperOffer = token === "DUC"
		const txContent = getCancelOfferTxn(this.config, isDapperOffer)

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
