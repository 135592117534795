import { cn } from "../utils/cn"
import { TabConfig } from "./FlowtyTabs"

export const PrimaryFlowtyTab: React.FC<TabConfig> = props => {
	const baseStyles = cn(
		`flex flex-col items-center gap-[18px] text-neutral-700 h-[32px] border-b-[1px] border-primary-tab-border-inactive-24 border-opacity-5`
	)

	const activeStyles = cn(
		`border-b-2 border-primary border-opacity-100 text-primary-500 text-primary`
	)

	const baseStylesDisabled = cn(`text-neutral-700 cursor-not-allowed`)

	const spanStyles = cn(
		`flex items-center w-max gap-[0.25rem] font-[700] text-[14px] font-montserrat text-center px-[18px] pb-[18px]`
	)

	return (
		<button
			role='tab'
			key={props.id}
			id={`tab-${props.id}`}
			aria-controls={`tabpanel-${props.id}`}
			aria-selected={props.isActive}
			disabled={props.disabled}
			onClick={() => props.onClick && props.onClick(props.id)}
			className={cn(baseStyles, {
				[activeStyles]: props.isActive,
				[baseStylesDisabled]: props.disabled,
			})}
		>
			<span className={spanStyles}>
				{props.icon && (
					<div className='h-[1.25rem] w-[1.125rem]'>{props.icon}</div>
				)}
				{props.label.toUpperCase()}
			</span>
		</button>
	)
}
