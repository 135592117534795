import { Dialog, Transition } from "@headlessui/react"
import { FlowtyButton, FlowtyCheckbox, SettingsToggle } from "ds-flowty"
import { Field, Form, Formik } from "formik"
import { inject, observer } from "mobx-react"
import { Fragment, useState } from "react"
import { Oval } from "react-loader-spinner"
import * as Yup from "yup"
import { subscribeEmailToNotification } from "../../../services/EmailService"
import AuthStore from "../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { SnackbarAlert } from "../../Shared/Snackbar/SnackbarAlert"
import { ProfileSettingsValues } from "../../UserProfile/Settings/UserPreferences"
import {
	getInitialValues,
	onUserProfileFormSubmit,
} from "../../UserProfile/Util/FormSubmit"

interface Props {
	authStore: AuthStore | undefined
}

const WelcomeMessageModal: React.FunctionComponent<Props> = ({ authStore }) => {
	const [isOpen, setIsOpen] = useState(true)
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState<boolean>(false)

	const EmailSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email")
			.when("emailOptIn", {
				is: true,
				then: Yup.string()
					.required("Email is required when opting in")
					.email("Invalid email"),
				// eslint-disable-next-line sort-keys-fix/sort-keys-fix
				otherwise: Yup.string().optional(),
			}),
		welcomeCheckboxTerms: Yup.boolean().required(
			"Please read and agree to the Flowty terms before proceeding."
		),
	})

	const onClose = (): void => {
		setIsOpen(false)
	}

	const onFormSubmitted = (): void => {
		onClose()
		setShowSuccessSnackbar(true)
	}

	const getWalletConnectedInitialValues = (): ProfileSettingsValues => {
		const initialValues = getInitialValues(authStore)
		if (!initialValues.email || initialValues.email.length === 0) {
			return {
				...initialValues,
				email: !authStore?.loggedUser?.emailOptIn
					? ""
					: authStore?.loggedUser?.blockToEmail || "",
				emailOptIn: true,
				preferredCardSize: authStore?.loggedUser?.preferredCardSize ?? "large",
				subscribe: true,
			}
		}
		return initialValues
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const addMarketingEmail = async (email: string): Promise<any> => {
		try {
			const response = await subscribeEmailToNotification(email)
			Mixpanel.track("ADD_MARKETING_EMAIL_SUCCESS", {
				email,
			})
			return response
		} catch (error) {
			Mixpanel.track("ADD_MARKETING_EMAIL_FAILURE", {
				email,
				error,
			})
		}
	}

	return (
		<div>
			<SnackbarAlert
				setShow={setShowSuccessSnackbar}
				show={showSuccessSnackbar}
				seconds={5}
				message={"Successfully Saved Preferences"}
				type='SUCCESS'
			/>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-40' static onClose={() => null}>
					<Transition.Child
						enter='transition duration-000 ease-out'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-75 ease-out'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'
						as={Fragment}
					>
						<div className='fixed inset-0 bg-black bg-opacity-75' />
					</Transition.Child>
					<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-1000'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='relative z-40 shadow-xl'>
								<div className='bg-darkBg rounded-[10px] border border-[#343A40]'>
									<Formik
										initialValues={getWalletConnectedInitialValues()}
										validationSchema={EmailSchema}
										enableReinitialize
										onSubmit={values => {
											authStore?.setEmail(values?.email)
											if (values?.emailOptIn) {
												authStore?.setOptIn(values?.emailOptIn)
											}
											Mixpanel.track("WELCOME_MESSAGE_SUBMIT", {
												values,
											})
											authStore?.setWelcomeMessagePopupAcknowledged(true)
											authStore?.setNewTermsAcknowledged(true)
											if (values?.preferredCardSize) {
												authStore?.setPreferredCardSize(
													values?.preferredCardSize
												)
											}
											if (values.subscribe === true) {
												authStore?.setMarketingEmail(values?.email)
												addMarketingEmail(values?.email)
											}

											onUserProfileFormSubmit(
												authStore?.loggedUser?.addr,
												values,
												onFormSubmitted,
												"welcome",
												() => {}
											)
												.then(() => {
													Mixpanel.track(
														"WELCOME_MESSAGE_MODAL_FORM_SUBMIT_SUCCESS",
														{
															values,
														}
													)
												})
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												.catch((err: any) => {
													Mixpanel.track(
														"WELCOME_MESSAGE_MODAL_FORM_SUBMIT_FAILURE",
														{
															error: err,
															values,
														}
													)
												})
										}}
									>
										{({
											isSubmitting,
											setFieldValue,
											values,
											setTouched,
											handleChange,
											validateField,
										}) => (
											<Form className='flex flex-col select-none'>
												<div className='p-[40px]'>
													<span className='text-white text-shadow text-[24px] font-montserrat font-black leading-normal font-montserrat'>
														Welcome to Flowty!
													</span>
												</div>
												<div className='mx-auto p-[40px] bg-darkBg border-y border-[#343A40]'>
													{isSubmitting ? (
														<div className='mx-auto text-center'>
															<Oval height={200} width={200} />
														</div>
													) : (
														<>
															<h2 className='text-xl font-bold mb-[20px]'>
																Email Notification Preferences
															</h2>
															<div className='flex flex-col space-y-[24px]'>
																<SettingsToggle
																	key={0}
																	name='emailOptIn'
																	toggled={values.emailOptIn}
																	onToggle={toggleState => {
																		setFieldValue("emailOptIn", toggleState)
																		if (toggleState == false) {
																			setFieldValue("email", "")
																			setFieldValue("subscribe", false)
																		}
																	}}
																	label={
																		<p className='text-[12px] text-[#DBDBDB] font-normal w-[21.43rem] md:w-[320px] leading-none'>
																			Receive emails for new purchases,
																			listings, loans, rentals and other
																			user-initiated actions
																		</p>
																	}
																/>
																<SettingsToggle
																	key={1}
																	name='subscribe'
																	toggled={values.subscribe}
																	onToggle={toggleState => {
																		setFieldValue("subscribe", toggleState)
																	}}
																	disabled={!values.emailOptIn}
																	label={
																		<p className='text-[12px] text-[#DBDBDB] font-normal w-[21.43rem] md:w-[320px] leading-none'>
																			Receive emails for offers received on your
																			NFTs and Flowty marketing
																		</p>
																	}
																/>
															</div>
															<div className='mt-[32px]'>
																<label
																	htmlFor='email'
																	className='text-base font-bold'
																>
																	Email Address
																</label>
																<Field
																	type='email'
																	name='email'
																	id='email'
																	disabled={!values.emailOptIn}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>
																	) => {
																		handleChange(e)
																		setTouched({ email: true })
																	}}
																	onKeyDown={(
																		e: React.KeyboardEvent<HTMLInputElement>
																	) => {
																		if (
																			e.key === "Backspace" ||
																			e.key === "Delete"
																		) {
																			setTimeout(() => {
																				if (
																					(e.target as HTMLInputElement)
																						.value === ""
																				) {
																					validateField("email")
																				}
																			}, 0)
																		}
																		setTouched({ email: true })
																	}}
																	className={`border rounded-[.3125rem] leading-tight pr-[0.75rem] py-[.5rem] pl-[1.06rem] min-w-[22.687rem] text-white font-bold bg-transparent w-full mt-4 ${
																		!values.emailOptIn
																			? "opacity-50 cursor-not-allowed"
																			: "opacity-100"
																	}`}
																/>
															</div>
															<div className='mt-[32px]'>
																<FlowtyCheckbox
																	label={
																		<span className='text-[#ADB5BD] text-12px font-normal'>
																			I have read and agreed to Flowty&apos;s{" "}
																			<a
																				className='text-primary underline'
																				href='https://www.flowty.io/tos'
																				target='_blank'
																				rel='noopener noreferrer'
																			>
																				Terms of Service
																			</a>{" "}
																			and{" "}
																			<a
																				className='text-primary underline'
																				href='https://www.flowty.io/privacy'
																				target='_blank'
																				rel='noopener noreferrer'
																			>
																				Privacy Policy.
																			</a>
																		</span>
																	}
																	name='welcomeCheckboxTerms'
																	id='welcomeCheckboxTerms'
																	errorMessage='Please review and agree to our terms of service'
																/>
															</div>
														</>
													)}
												</div>
												<div className='bg-darkBg  rounded-b-[.625rem] py-[20px] flex flex-col items-center justify-center'>
													<div className='w-96 mb-2'>
														<FlowtyButton
															type='submit'
															text={"SAVE PREFERENCES"}
															variant={"secondary"}
															disabled={
																isSubmitting ||
																!values.welcomeCheckboxTerms ||
																(values.emailOptIn && !values.email)
															}
															btnSize={"large"}
														/>
													</div>
													<div className='text-[12px] text-[#7D7D7D] font-normal leading-normal'>
														Update preferences in Settings Page
													</div>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}

export default inject("authStore")(observer(WelcomeMessageModal))
