import { OpensearchFlowNFT } from "flowty-common"
import axios from "axios"
import { FlowNFTIdentifier, SuccessResponse } from "./types"

export class FlowtyAPI {
	baseURL: string

	getItem = async (request: FlowNFTIdentifier): Promise<OpensearchFlowNFT> => {
		const url = `${this.baseURL}/item/${request.contractAddress}/${request.contractName}/${request.resourceName}/${request.nftID}`
		const response = await axios.get<OpensearchFlowNFT>(url)
		return response.data
	}

	refreshMetadata = async (identifier: string): Promise<SuccessResponse> => {
		return axios
			.get<SuccessResponse>(`${this.baseURL}/nft/refresh?id=${identifier}`)
			.then(res => res.data)
	}

	constructor(baseURL: string) {
		this.baseURL = baseURL
	}
}
