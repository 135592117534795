import {
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { useFlowtyModalsContext } from "../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { useOpensearchNFT } from "../../../hooks/data/nfts/useOpensearchNFT"

interface OpensearchNftOrders {
	loans: OpenSearchListingAvailableData[]
	rentals: OpensearchRentalAvailableData[]
	storefront: OpensearchStorefrontAvailableData[]
}

export const useAssetScreen = () => {
	const { collectionAddress, collectionName, nftId, resource } = useParams()
	const location = useLocation()
	const currentSearch = location.search
	const [searchParams, setSearchParams] = useSearchParams()
	const query = new URLSearchParams(currentSearch)
	const ref = useRef(true)
	const [currentTab, setCurrentTab] = useState<"LISTINGS" | "HISTORY">(
		searchParams.get("tab") === "history" ? "HISTORY" : "LISTINGS"
	)

	const tabs = [
		{
			key: "LISTINGS",
			name: "Listings",
		},
		{
			key: "HISTORY",
			name: "History",
		},
	]

	const changeTab = (tab: "LISTINGS" | "HISTORY"): void => {
		if (tab === "HISTORY") {
			searchParams.set("tab", "history")
			setSearchParams(searchParams)
		} else {
			searchParams.delete("tab")
			setSearchParams(searchParams)
		}
		setCurrentTab(tab)
	}

	const resourceName = resource || "NFT"

	const { selectCard } = useFlowtyModalsContext()
	const [anyUndefined, setAnyUndefined] = useState(false)

	useEffect(() => {
		const undefinedValuesExist = [
			collectionAddress,
			collectionName,
			nftId,
		].includes("undefined")
		setAnyUndefined(undefinedValuesExist)
	}, [collectionAddress, collectionName, nftId])

	const { openSearchNFT } = useOpensearchNFT({
		collectionAddress: `${collectionAddress}`,
		collectionName: `${collectionName}`,
		nftId: `${nftId}`,
		resourceName,
	})

	const orders: OpensearchNftOrders = useMemo(() => {
		return {
			loans: openSearchNFT?.orders?.filter(
				order => order?.listingKind === "loan"
			) as OpenSearchListingAvailableData[],
			rentals: openSearchNFT?.orders?.filter(
				order => order?.listingKind === "rental"
			) as OpensearchRentalAvailableData[],
			storefront: openSearchNFT?.orders?.filter(
				order => order?.listingKind === "storefront"
			) as OpensearchStorefrontAvailableData[],
		}
	}, [openSearchNFT])

	const listingResourceID = query.get("listingResourceID")
	useEffect(() => {
		if (openSearchNFT && listingResourceID) {
			const listedOrder = openSearchNFT?.orders?.find((order: Order) => {
				return order.listingResourceID === listingResourceID
			})

			if (listedOrder && ref.current) {
				ref.current = false
				selectCard({
					selected: {
						contractAddress: openSearchNFT.contractAddress,
						contractName: openSearchNFT.contractName,
						nftID: openSearchNFT.id,
						nftType: openSearchNFT.type,
					},
					selectedSinglePurchase: listedOrder,
				})
			}
		}
	}, [orders, query])

	return {
		anyUndefined,
		changeTab,
		currentTab,
		openSearchNFT,
		orders,
		tabs,
	}
}
