import React from "react"
import { ReactComponent as Pentagon } from "../../../assets/Pentagon.svg"
import { getFMVDisplayLabelFromSource } from "../../../utils/GlobalUtils"

interface FlowtyDiscountIndicatorProps {
	percent: number
	tooltip?: boolean
	source?: string
}

export const FlowtyDiscountIndicator: React.FC<
	FlowtyDiscountIndicatorProps
> = ({ percent, source, tooltip = false }) => {
	if (percent < 5) {
		return null
	}

	return (
		<div className='relative group'>
			<Pentagon className=' fill-[#5308B2] drop-shadow-[0_0_2px_#5308B2]' />
			<div className='absolute top-[46%] left-[54%] transform -translate-x-1/2 -translate-y-1/2 text-white font-montserrat-bold font-bold'>
				{percent}%
			</div>
			{tooltip && (
				<div
					onClick={e => {
						e.stopPropagation()
					}}
					className='absolute hidden group-hover:block'
				>
					<div className='relative -left-3/4 top-3 bg-[#F8F9FA] rounded-md min-w-[18rem] z-10 cursor-default before:content-[""] before:absolute before:border-[6px] before:border-transparent before:border-b-[#F8F9FA] before:bottom-full before:right-[10%]'>
						<div className='p-[12px]'>
							<p className='text-sm font-normal text-black'>
								Listed Purchase Price is {percent}% lower than Fair Market Value
								(FMV) per {getFMVDisplayLabelFromSource(`${source}`)}. FMV is an
								estimate and is not financial advice.
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
