import numeral from "numeral"
import { FlowtyTokenIcon } from "../../../../FlowtyTokenIcon"

type Props = {
	userFlowBalance?: number
	userUsdcBalance?: number
}

export const TokenBalance: React.FC<Props> = ({
	userFlowBalance,
	userUsdcBalance,
}) => {
	return (
		<div className='flex flex-col items-start gap-y-[19px]'>
			<div className='flex flex-row items-center'>
				<span>
					<FlowtyTokenIcon isLarge token='FLOW' />
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					{numeral(userFlowBalance).format("0,0.00")}
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					FLOW
				</span>
			</div>
			<div className='flex flex-row items-center'>
				<span>
					<FlowtyTokenIcon isLarge token='USDC' />
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					{numeral(userUsdcBalance).format("0,0.00")}
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					USDC
				</span>
			</div>
		</div>
	)
}
