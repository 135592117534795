import { Config } from "../types"

export const getHybridCustodyDescribeFTAccountScript = (
	config: Config
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hybridCustodyDescribeFTAccountCrescendoScript(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hybridCustodyDescribeFTAccountScript(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hybridCustodyDescribeFTAccountCrescendoScript = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.NonFungibleToken}
import FlowToken from ${config.contractAddresses.FlowToken}
import USDCFlow from ${config.contractAddresses.USDCFlow}

import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}

import HybridCustody from ${config.contractAddresses.HybridCustody}
import FlowtyUtils from ${config.contractAddresses.NFTStorefrontV2}

access(all) fun main(parent: Address): {Address: AccountSummary} {
  let summaries: {Address: AccountSummary} = {}
  
  let flowTokenType = Type<@FlowToken.Vault>()
  let usdcTokenType = Type<@USDCFlow.Vault>()

  // get parent summary. We don't have to do as much work on this one because it is the main account
  // we can build all provider/receiver paths we need so those will always be available
  var flowBalance = FlowtyUtils.getTokenBalance(address: parent, vaultType: flowTokenType)
  var usdcBalance = FlowtyUtils.getTokenBalance(address: parent, vaultType: usdcTokenType)

  summaries[parent] = AccountSummary(
    parent, false,
    {
      flowTokenType.identifier: TokenSummary(flowBalance, nil, [], flowTokenType.identifier),
      usdcTokenType.identifier: TokenSummary(usdcBalance, nil, [], usdcTokenType.identifier)
    },
    nil,
    true
  )

  let account = getAuthAccount<auth(Capabilities, Storage) &Account>(parent)
  let m = account.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
  if m == nil {  
  	return summaries 
  }
  let manager = m!

  let isParentDapper = isDapper(account)

  for childAddress in manager.getChildAddresses() {
  	if childAddress == parent {
      continue
    }

    let child = manager.borrowAccount(addr: childAddress)
      ?? panic("child account not found")
    summaries[childAddress] = getSummaryForChild(isParentDapper: isParentDapper, child: child, flowTokenType: flowTokenType, usdcTokenType: usdcTokenType)
  }

  
  return summaries
}

access(all) fun getSummaryForChild(isParentDapper: Bool, child: auth(HybridCustody.Child) &{HybridCustody.AccountPrivate, HybridCustody.AccountPublic, ViewResolver.Resolver}, flowTokenType: Type, usdcTokenType: Type): AccountSummary {
  let childAuthAcct = getAuthAccount<auth(Capabilities, Storage) &Account>(child.owner!.address)

  let flowBalance = isParentDapper ? nil : getBalance(child: child, path: /public/flowTokenBalance)
  let usdcBalance = isParentDapper ? nil : getBalance(child: child, path: /public/usdcFlowMetadata)

  var hasFlowReceiver: PublicPath? = nil
  var hasUsdcReceiver: PublicPath? = nil
  if !isParentDapper {
    hasFlowReceiver = hasReceiver(child: child, path: /public/flowTokenReceiver) ? /public/flowTokenReceiver : nil
    hasUsdcReceiver = hasReceiver(child: child, path: /public/usdcFlowReceiver) ? /public/usdcFlowReceiver : nil
  }

  let providers = getFTProviders(child, childAuthAcct)

  let f = TokenSummary(flowBalance, hasFlowReceiver, providers[flowTokenType]?.providerPaths ?? [], flowTokenType.identifier)
  let usdc = TokenSummary(usdcBalance, hasUsdcReceiver, providers[usdcTokenType]?.providerPaths ?? [], usdcTokenType.identifier)
  let d = isDapper(childAuthAcct)

  var dis: Display? = nil
  if let display = child.resolveView(Type<MetadataViews.Display>()) {
    dis = Display(display as! MetadataViews.Display)
  } else {
  	let name = childAuthAcct.address.toString()
		dis = Display(
			MetadataViews.Display(
				name: name,
				description: "",
				thumbnail: MetadataViews.HTTPFile(
					url: "https://api-ufj4afzoca-uc.a.run.app/6.x/thumbs/png?seed=".concat(name)
				)
			)
		)
  }

  return AccountSummary(childAuthAcct.address, d, { flowTokenType.identifier: f }, dis, false)
}

access(all) fun isDapper(_ acct: auth(Capabilities, Storage) &Account): Bool {
  let path = /public/dapperUtilityCoinReceiver
  acct.capabilities.unpublish(path)
  acct.capabilities.publish(
    acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(/storage/dapperUtilityCoinReceiver),
    at: path
  )

  let tmp = acct.capabilities.get<&{FungibleToken.Receiver}>(path)
  if tmp == nil {
    return false
  }

  let cap = tmp!
  if !cap.check() {
    return false
  }

  let receiver = cap.borrow()
  if receiver == nil {
      return false
  }

  if receiver!.getType() != Type<@TokenForwarding.Forwarder>() {
      return false
  }

  let forwarder = receiver! as! &TokenForwarding.Forwarder
  let nextReceiver = forwarder.safeBorrow()
  if nextReceiver == nil {
      return false
  }

  if nextReceiver!.getType() != Type<@DapperUtilityCoin.Vault>() {
    // if it points to the duc vault, return the current address
    return false
  }

  return true
}

access(all) fun getBalance(child: auth(HybridCustody.Child) &{HybridCustody.AccountPrivate, HybridCustody.AccountPublic}, path: PublicPath): UFix64? {
  if let tmp = child.getPublicCapability(path: path, type: Type<&{FungibleToken.Balance}>()) {
    let cap = tmp! as! Capability<&{FungibleToken.Balance}>
    if cap.check() {
      return cap.borrow()!.balance
    }
  }

  return nil
}

access(all) fun hasReceiver(child: auth(HybridCustody.Child) &{HybridCustody.AccountPrivate, HybridCustody.AccountPublic}, path: PublicPath): Bool {
  if let tmp = child.getPublicCapability(path: path, type: Type<&{FungibleToken.Receiver}>()) {
    let cap = tmp! as! Capability<&{FungibleToken.Receiver}>
    return cap.check()
  }

  return false
}

access(all) fun getFTProviders(_ child: auth(HybridCustody.Child) &{HybridCustody.AccountPrivate, HybridCustody.AccountPublic}, _ childAuthAcct: auth(Capabilities, Storage) &Account): {Type: Access} {
  let accessible: {Type: Access} = {}

  let storagePaths: [StoragePath] = [
    /storage/flowTokenVault,
    /storage/usdcFlowVault,
    /storage/dapperUtilityCoinVault,
    /storage/flowUtilityTokenVault
  ]

  let targetType = Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>()

  for s in storagePaths {
    childAuthAcct.capabilities.storage.forEachController(forPath: s, fun(con: &StorageCapabilityController): Bool {
        if con.borrowType.isRecovered {
            return true
        }

        if !con.borrowType.isSubtype(of: targetType) {
            return true
        }

        let cap = child.getCapability(controllerID: con.capabilityID, type: targetType)
        if cap == nil {
            return true
        }

        let provider = cap! as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
        if !provider.check() {
            return true
        }

        if let storedType = childAuthAcct.storage.type(at: s) {
            if accessible[storedType] == nil {
                accessible[storedType] = Access(storedType)
            }

            accessible[storedType]?.addControllerID(con.capabilityID)
        } else {
            return true
        }

        return true
    })
  }

  return accessible
}

access(all) struct AccountSummary {
  access(all) let address: Address
  access(all) let isDapper: Bool
  access(all) let tokens: {String: TokenSummary}
  access(all) let display: Display?
  access(all) let isMain: Bool

  init(_ a: Address, _ d: Bool, _ tokens: {String: TokenSummary}, _ dis: Display?, _ isMain: Bool) {
    self.address = a
    self.isDapper = d
    self.tokens = tokens
    self.display = dis

    self.isMain = isMain
  }
}

access(all) struct TokenSummary {
  access(all) let balance: UFix64?
  access(all) let receiverPath: PublicPath?
  access(all) let providerPaths: [PrivatePath]
  access(all) let type: String

  init(_ b: UFix64?, _ r: PublicPath?, _ p: [PrivatePath], _ t: String) {
    self.balance = b
    self.receiverPath = r
    self.providerPaths = p
    self.type = t
  }
}

access(all) struct Display {
  access(all) let name: String
  access(all) let description: String
  access(all) let thumbnail: String

  init(_ d: MetadataViews.Display) {
      self.name = d.name
      self.description = d.description
      self.thumbnail = d.thumbnail.uri()
  }
}

access(all) struct Access {
  access(all) let type: Type
  access(all) let providerPaths: [PrivatePath]
  access(all) let controllerIDs: [UInt64]

  access(all) fun addPrivPath(_ p: PrivatePath) {
    self.providerPaths.append(p)
  }

  access(all) fun addControllerID(_ num: UInt64) {
    self.controllerIDs.append(num)
  }

  init(_ t: Type) {
    self.type = t
    self.providerPaths = []
    self.controllerIDs = []
  }
}

access(all) struct TokenConfig {
  access(all) let tokenType: String
  access(all) let balance: UFix64?
  access(all) let receiverPaths: [PublicPath]
  access(all) let providerPaths: [PrivatePath]

  init(_ tt: String, _ b: UFix64?, _ rs: [PublicPath], _ ps: [PrivatePath]) {
    self.tokenType = tt
    self.balance = b
    self.receiverPaths = rs
    self.providerPaths = ps
  }
}

access(all) struct AccountConfig {
  access(all) let address: Address
  access(all) let tokens: [TokenConfig]

  init(_ a: Address, _ ts: [TokenConfig]) {
    self.address = a
    self.tokens = ts
  }
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hybridCustodyDescribeFTAccountScript = (config: Config): string => ``
