import { Popover } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import {
	ListingKind,
	SUPPORTED_NON_DAPPER_TOKENS,
	SUPPORTED_TOKEN_LIST,
	SupportedTokens,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useEffect, useMemo } from "react"
import { AccountSummary } from "flowty-sdk"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { addFallbackImage } from "../../../../util/images"
import { AccountTag } from "../../../HybridCustody/AccountTag"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { flowty } from "../../../../config/config"

interface WalletSelectorProps<T> extends AuthStoreProp {
	label: string
	selected?: AccountSummary | null
	selectorFilter?: SelectorFilter<T>
	setSelected?: (account: AccountSummary) => void
	disabledMessage?: string
	description?: string
	tableView?: boolean
}

interface SelectorFilter<T> {
	apply: (account: AccountSummary) => boolean
	params: T
}

class PassThroughFilter implements SelectorFilter<unknown> {
	params: unknown

	constructor() {
		this.params = {}
	}

	apply(_: AccountSummary): boolean {
		return true
	}
}

export class TokenReceiverFilter implements SelectorFilter<TokenNameParams> {
	params: TokenNameParams

	constructor(params: TokenNameParams) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		const tokenType = flowty.tokens.getTokenIdentifier(
			(this.params?.tokenName as SupportedTokens) ?? SupportedTokens.FLOW
		)

		return !!account?.tokens?.[tokenType]?.receiverPath || account.isMain
	}
}

export class ValidTokenReceiverFilter
	implements SelectorFilter<{ listingType: ListingKind }>
{
	params: { listingType: ListingKind }

	constructor(params: { listingType: ListingKind }) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		const availableTokens =
			this?.params?.listingType === "storefront"
				? SUPPORTED_TOKEN_LIST
				: SUPPORTED_NON_DAPPER_TOKENS

		for (let i = 0; i < availableTokens.length - 1; i++) {
			const token = availableTokens[i]
			const tokenType = flowty.tokens.getTokenIdentifier(
				(token as SupportedTokens) ?? SupportedTokens.FLOW
			)
			if (!!account?.tokens?.[tokenType]?.receiverPath || account.isMain)
				return true
		}
		return false
	}
}

export class TokenProviderFilter implements SelectorFilter<TokenNameParams> {
	params: TokenNameParams

	constructor(params: TokenNameParams) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		const tokenType = flowty.tokens.getTokenIdentifier(
			(this.params?.tokenName as SupportedTokens) ?? SupportedTokens.FLOW
		)
		return (account?.tokens?.[tokenType]?.providerPaths?.length || 0) > 0
	}
}

interface TokenNameParams {
	tokenName: string
	listingType?: ListingKind
}

const WalletSelectorComponent: React.FC<WalletSelectorProps<unknown>> = ({
	authStore,
	selected,
	setSelected,
	selectorFilter = new PassThroughFilter(),
	label,
	disabledMessage,
	description,
	tableView,
}) => {
	const accounts = useMemo(() => {
		const summaries = authStore?.loggedUser?.accountSummaries

		return Object.values(summaries || {}).reduce(
			(result, account) => {
				const isAccountInvalid =
					!selectorFilter?.apply(account) && !account.isMain

				return isAccountInvalid
					? { invalid: [account, ...result.invalid], valid: result.valid }
					: { invalid: result.invalid, valid: [account, ...result.valid] }
			},
			{
				invalid: [] as AccountSummary[],
				valid: [] as AccountSummary[],
			}
		)
	}, [authStore?.loggedUser?.accountSummaries, selectorFilter])

	useEffect(() => {
		const isSelectedValid = accounts.valid.reduce(
			(isValid, account) => selected?.address === account.address || isValid,
			false
		)
		if (!isSelectedValid) {
			setSelected?.(accounts.valid[0])
		}
	}, [selectorFilter.params, accounts])

	if (
		!selected ||
		!setSelected ||
		Object.values(authStore?.loggedUser?.accountSummaries || {}).length <= 1
	)
		return null
	return (
		<div className='w-full flex flex-col justify-between'>
			<div
				className={`${
					tableView ? "text-lg font-bold" : "text-base font-semibold py-2"
				}`}
			>
				{label}
			</div>
			<Popover className={`${!tableView && "relative"} w-full`}>
				<Popover.Button className='w-full'>
					{({ open }) => (
						<div
							className={`flex font-bold font-montserrat-bold border border-[#B1B1B1] hover:border-white rounded-md items-center justify-around md:justify-between group py-1 px-1 md:px-1.5 ${
								open && "border-primary"
							}
							${tableView && "h-[37px]"}`}
						>
							<div className={`flex justify-between w-full items-center`}>
								<div className='flex items-center'>
									<img
										onError={addFallbackImage}
										src={
											(selected.address === authStore?.loggedUser?.addr
												? authStore?.loggedUser?.avatar
												: selected?.display?.thumbnail) || ""
										}
										alt='Wallet Icon'
										className='h-7 w-7 md:h-8 md:w-8 content-fit rounded-md'
									/>
									<span className='p-1 md:p-2 font-bold font-montserrat-bold text-nowrap'>
										{selected?.display?.name || selected.address || ""}
									</span>
								</div>
								<AccountTag isMainWallet={selected.isMain} />
							</div>
							<ChevronDownIcon
								className={`h-7 w-7 md:h-8 md:w-8 transition-transform duration-300  ${
									open && "rotate-180 transform fill-primary"
								}`}
							/>
						</div>
					)}
				</Popover.Button>
				{/* Dropdown items*/}
				<Popover.Panel
					className={`absolute z-10 bg-[#232D39] rounded-md mt-2 border overflow-hidden w-full ${
						tableView ? "w-auto border-[#B1B1B1]" : "border-white"
					}`}
				>
					{accounts.valid.map(account => {
						return (
							<Popover.Button
								as='div'
								key={account.address}
								onClick={() => {
									setSelected(account)
									Mixpanel.track("WALLET_ACCOUNT_TOGGLED", {
										account,
									})
								}}
								className={`cursor-pointer w-full px-3 py-1 hover:bg-white hover:bg-opacity-10  flex justify-between items-center transition-all ease-in-out duration-300`}
							>
								<div className='flex items-center'>
									<img
										onError={addFallbackImage}
										src={account?.display?.thumbnail}
										alt='Wallet Icon'
										className='h-7 w-7 md:h-8 md:w-8 content-fit rounded-md'
									/>
									<span className='p-1 md:p-2 font-bold font-montserrat-bold text-nowrap'>
										{account?.display?.name || account?.address || ""}
									</span>
								</div>
								<AccountTag isMainWallet={account.isMain} />
							</Popover.Button>
						)
					})}

					{accounts.invalid.map((account, index) => {
						return (
							<div key={account.address}>
								<Popover.Button
									aria-disabled
									as='div'
									onClick={() => {
										setSelected(account)
										Mixpanel.track("WALLET_ACCOUNT_TOGGLED", {
											account,
										})
									}}
									className={`cursor-not-allowed w-full px-3 py-1 flex items-center justify-between opacity-50`}
								>
									<div className='flex items-center'>
										<img
											onError={addFallbackImage}
											src={account?.display?.thumbnail}
											alt='Wallet Icon'
											className='h-4 w-4 md:h-4 md:w-4 content-fit rounded-md'
										/>
										<span className='p-1 md:p-2 font-bold font-montserrat-bold text-nowrap'>
											{account?.display?.name || account?.address || ""}
										</span>
									</div>
									<AccountTag isMainWallet={account.isMain} />
								</Popover.Button>
								{index === accounts.invalid.length - 1 && disabledMessage ? (
									<div className='w-full border-t border-gray-500 h-8 text-danger text-xs grid place-items-center'>
										{disabledMessage}
									</div>
								) : null}
							</div>
						)
					})}
				</Popover.Panel>
			</Popover>
			{description && <div className='text-sm py-1'>{description}</div>}
		</div>
	)
}

export const WalletSelector = inject("authStore")(
	observer(WalletSelectorComponent)
)

export const walletDisabledMessage = {
	payTo: "Disabled wallets not setup to receive token type.",
	payWith: "Disabled wallets may not have token provider setup.",
	sendTo: "Disabled wallets may not be setup to receive NFT",
}
