import { OpensearchFlowNFT } from "flowty-common"
import React, { useEffect, useState } from "react"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { ListingIndicatorPill } from "../../../Shared/ListingIndicatorPill/ListingIndicatorPill"
import { useCatalogContext } from "../../../../contexts/CatalogContext"
import { OpensearchNftOrders } from "../../../../screens/AssetScreen/AssetScreen"

interface AvailableListingsPillsListProps extends AuthStoreProp {
	nftId: string
	nft: OpensearchFlowNFT
	orders: OpensearchNftOrders
}

const forbiddenEscapes = [
	"A.e4cf4bdc1751c65d.PackNFT.NFT",
	"A.2d4c3caffbeab845.FLOAT.NFT",
	"A.fdae91e14e960079.GaiaPackNFT.NFT",
	"A.e81193c424cfd3fb.Doodles.NFT",
]

export const AvailableListingsPillsList: React.FC<
	AvailableListingsPillsListProps
> = ({ nft, orders }) => {
	const { denylistCache } = useCatalogContext()
	const [nftType, setNftType] = useState<string>(nft.type)

	useEffect(() => {
		if (!nft.type.endsWith(".NFT")) {
			setNftType(`${nft.type}.NFT`)
		}
	}, [nft])

	return (
		<>
			{nft.offers?.length === 0 &&
			!orders.loans &&
			!orders.rentals &&
			!orders.storefront ? (
				<span className='text-[1rem] md:text-[1.125rem] font-bold font-montserrat'>
					None available
				</span>
			) : (
				<div className='flex flex-row gap-[0.375rem]'>
					{nft.offers?.length ? <ListingIndicatorPill symbol='OFFER' /> : null}
					{orders?.storefront?.length ? (
						<ListingIndicatorPill
							symbol='SALE'
							count={orders.storefront.length}
						/>
					) : null}
					{orders?.loans?.length ? (
						<ListingIndicatorPill symbol='LOAN' count={orders.loans.length} />
					) : null}
					{orders?.rentals?.length ? (
						<ListingIndicatorPill symbol='RENT' count={orders.rentals.length} />
					) : null}
					{denylistCache.has(nftType) && !forbiddenEscapes.includes(nftType) ? (
						<ListingIndicatorPill symbol='FORBIDDEN' />
					) : null}
				</div>
			)}
		</>
	)
}
