import { useEffect, useState } from "react"
import { getCollectionImage } from "../util/getCollectionImage"

interface UseFallbackImageProps {
	collectionAddress: string
	collectionName: string
}

export const useFallbackImage = ({
	collectionAddress,
	collectionName,
}: UseFallbackImageProps) => {
	const CACHE_VALIDITY = 14 * 24 * 60 * 60 * 1000

	const [collectionImages, setCollectionImages] = useState<
		Record<string, string>
	>({})

	useEffect(() => {
		// Retrieve the entire map from localStorage
		const savedImagesJSON = localStorage.getItem("collectionImages")
		const savedImages = savedImagesJSON ? JSON.parse(savedImagesJSON) : {}

		const currentTime = new Date().getTime()
		const imageData = savedImages[collectionName!]

		if (imageData && imageData.expiry > currentTime) {
			// If data is still valid, use it
			setCollectionImages(prev => ({
				...prev,
				[collectionName!]: imageData.url,
			}))
		} else if (collectionAddress && collectionName) {
			// Data is expired or not present, fetch new data
			getCollectionImage({ collectionAddress, collectionName }).then(res => {
				const newImageData = {
					expiry: currentTime + CACHE_VALIDITY,
					url: res,
				}

				// Update local state
				setCollectionImages(prev => ({ ...prev, [collectionName]: res }))

				if (!res.includes("new_fallback_image")) {
					localStorage.setItem(
						"collectionImages",
						JSON.stringify({
							...savedImages,
							[collectionName]: newImageData,
						})
					)
				}

				// Update localStorage
			})
		}
	}, [collectionAddress, collectionName])

	return collectionImages
}
