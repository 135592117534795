export const sortFields = {
	all: [
		{
			label: "Recently Viewed",
			value: "latestBlock",
		},
		{
			label: "Recently Listed",
			value: "blockTimestamp",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
	],
	loan: [
		{
			label: "Recently Listed",
			value: "blockTimestamp",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
		{
			label: "Loan Rate",
			value: "derivations.calculatedValues.periodicInterest",
		},
		{
			label: "APR",
			value: "derivations.apr",
		},
		{
			label: "Loan Amount",
			value: "derivations.calculatedValues.marketplaceAmount",
		},
		{
			label: "Duration",
			value: "term",
		},
		{
			label: "3rd Party LTV",
			value: "valuations.aggregate.loanToValueRatio",
		},
	],
	profile: [
		{
			label: "Recently Viewed",
			value: "latestBlock",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
		{
			label: "Recently Listed",
			value: "blockTimestamp",
		},
	],
	rental: [
		{
			label: "Recently Listed",
			value: "blockTimestamp",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
		{
			label: "Rental Fee",
			value: "amount",
		},
		{
			label: "Deposit",
			value: "deposit",
		},
		{
			label: "Duration",
			value: "term",
		},
		{
			label: "3rd Party DTV",
			value: "valuations.aggregate.depositToValueRatio",
		},
	],
	storefront: [
		{
			label: "Recently Listed",
			value: "blockTimestamp",
		},
		{
			label: "Serial Number",
			value: "nftView.serial",
		},
		{
			label: "Price",
			value: "usdValue",
		},
		{
			label: "FMV",
			value: "valuations.blended.usdValue",
		},
		{
			label: "Discount to FMV",
			value: "valuationRatio",
		},
	],
}
