import React from "react"
import { Snackbar, SnackbarProps } from "./Snackbar"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid"

export type SnackbarSeverity = "SUCCESS" | "WARNING" | "ERROR"

export interface SnackbarMessage {
	message: string
	type: SnackbarSeverity
}

interface GeneralMsgProps extends SnackbarProps, SnackbarMessage {}

export const SnackbarAlert: React.FC<GeneralMsgProps> = ({
	setShow,
	show,
	seconds,
	message,
	type,
}) => {
	return (
		<Snackbar setShow={setShow} show={show} seconds={seconds}>
			<div
				className={`rounded-md bg-[#283B46] py-2 px-3 flex border-[1px] h-full  items-center ${
					type === "SUCCESS"
						? "border-primary"
						: type === "ERROR"
						? "border-danger"
						: "border-[#FF6E25]"
				}`}
			>
				<div className='flex-shrink-0'>
					{type === "SUCCESS" ? (
						<CheckCircleIcon className='h-8 w-8 fill-primary' />
					) : (
						<ExclamationCircleIcon
							className={`h-8 w-8 ${
								type === "ERROR" ? "fill-danger" : "fill-[#FF6E25]"
							}`}
						/>
					)}
				</div>
				<div
					className={`ml-3 h-full flex items-center capitalize ${
						type === "SUCCESS"
							? "text-primary"
							: type === "ERROR"
							? "text-danger"
							: "text-[#FF6E25]"
					}`}
				>
					{message}
				</div>
			</div>
		</Snackbar>
	)
}
