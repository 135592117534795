import { useField } from "formik"
import { setFutureEndDateTime } from "./DateTimePicker"
import { cn } from "../../../../../../utils/cn"
import { useEffect, useRef } from "react"
import { DateTimeType } from "../../../../../types/FlowtyCreatorHubTypes"

const generateTimeOptions = (): string[] => {
	const times = []
	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 60; j += 30) {
			const hour = i % 12 === 0 ? 12 : i % 12
			const minute = j.toString().padStart(2, "0")
			const period = i < 12 ? "AM" : "PM"
			times.push(`${hour}:${minute} ${period}`)
		}
	}
	return times
}

interface TimePickerProps {
	fieldName: string
	toggleTimePicker: () => void
	selectedTime: string | null
	setSelectedTime: React.Dispatch<React.SetStateAction<string | null>>
}
export const TimePicker: React.FC<TimePickerProps> = ({
	fieldName,
	toggleTimePicker,
	setSelectedTime,
	selectedTime,
}) => {
	const timeOptions = generateTimeOptions()
	const [dropStartDate] = useField<DateTimeType>("dropStartDateTime")
	const [, dropEndDate, endDateHelpers] =
		useField<DateTimeType>("dropEndDateTime")

	const [field, , helpers] = useField<DateTimeType>(fieldName)

	const handleTimeChange = (time: string): void => {
		setSelectedTime(time)
		if (time === "NOW") {
			const newDate = new Date(Date.now())

			helpers.setValue({ dateTime: newDate.getTime(), isNow: true })

			toggleTimePicker()
			return
		}

		if (time) {
			const [hourPart, minutePart] = time.split(":")
			const minutes = minutePart.slice(0, 2)
			const period = minutePart.slice(3)
			let hours = parseInt(hourPart)

			if (period === "PM" && hours !== 12) {
				hours += 12
			} else if (period === "AM" && hours === 12) {
				hours = 0
			}

			const newDate = new Date(
				field.value.dateTime || dropStartDate.value.dateTime || Date.now()
			)
			newDate.setHours(hours)
			newDate.setMinutes(parseInt(minutes))
			const formattedDate = newDate.getTime()
			helpers.setValue({ dateTime: formattedDate })
			toggleTimePicker()

			if (
				fieldName === "dropStartDateTime" &&
				formattedDate >= dropEndDate.value.dateTime
			) {
				setFutureEndDateTime(newDate, endDateHelpers)
			}
		}
	}

	const newDate = new Date(dropStartDate.value.dateTime)
	const isToday = new Date().getDate() === newDate.getDate()

	return (
		<div className='w-full rounded-lg bg-[#04070B] shadow-md'>
			<div
				id='timePicker'
				className='cursor-pointer absolute left-0 mt-2  block w-full h-[240px] py-4 3xl:py-5 whitespace-nowrap text-[9.333px] 3xl:text-base font-medium border border-[#6C757D] bg-[#04070B] focus:outline-none sm:text-sm rounded-md overflow-y-scroll'
			>
				{fieldName === "dropStartDateTime" && isToday && (
					<div
						className={cn(
							"text-white py-[8px] select-none rounded px-5 3xl:px-6 hover:bg-[#575757]",
							{
								"bg-[#575757]/30": selectedTime === "NOW",
							}
						)}
						onClick={() => handleTimeChange("NOW")}
						onKeyDown={() => handleTimeChange("NOW")}
					>
						NOW
					</div>
				)}
				{timeOptions.map((time, index) => (
					<div
						key={index}
						onClick={() => handleTimeChange(time)}
						onKeyDown={() => handleTimeChange(time)}
						className={cn(
							"text-white py-[8px] select-none rounded px-5 3xl:px-6 hover:bg-[#575757]",
							{
								"bg-[#575757]/30": selectedTime === time,
							}
						)}
					>
						{time}
					</div>
				))}
			</div>
		</div>
	)
}
