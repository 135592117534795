import React from "react"
import FlowIcon from "../../../../../../../assets/flow-token-icon.png"
import { Tooltip } from "react-tooltip"

interface FlowIconWithTooltipProps {
	name: string
}

export const FlowIconWithTooltip: React.FunctionComponent<
	FlowIconWithTooltipProps
> = ({ name }) => {
	return (
		<>
			<Tooltip
				id={`${name}-FLOW-tooltip`}
				style={{
					backgroundColor: "white",
					color: "#031021",
					fontSize: "0.875rem",
					fontWeight: 500,
					zIndex: 10,
				}}
			/>
			<img
				data-tooltip-id={`${name}-FLOW-tooltip`}
				data-tooltip-content={"FLOW"}
				data-tooltip-place='left'
				src={FlowIcon}
				alt='FLOW'
				className='-ml-3 h-[12px] w-[12px] 3xl:h-4 3xl:w-4'
			/>
		</>
	)
}
