/* eslint-disable import/no-unused-modules */
import { useField } from "formik"
import React, { useEffect, useRef } from "react"
import { FlowtyAccordion } from "../../../../FlowtyAccordion"

import { FLOWTY_INTEREST_FEE, SpotPrice } from "flowty-common"
import { Flowty } from "flowty-sdk"
import { FlowtyListBoxTokenSelector } from "../../../../FlowtyListboxTokenSelector/FlowtyListboxTokenSelection"
import {
	FlowtyWalletSelector,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { AccountSummaries, AccountSummary } from "../../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { LoanFormValues } from "../../../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"
import { NftPrice } from "../../common/NftPrice"
import {
	IS_MAINNET,
	MAX_AMOUNT_TO_BORROW,
	MAX_AMOUNT_TO_REPAY,
	MIN_AMOUNT_TO_BORROW,
	MIN_AMOUNT_TO_REPAY,
	MAX_LOAN_DURATION,
	getMinLoanDuration,
} from "../../../contexts/FlowtyListingModalContext/utils/constants"
import numeral from "numeral"
import {
	allowDecimalInDuration,
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../../utils/constants"
import { FlowtyNumericInput } from "../../../../FlowtyNumericInput"
import { ChildWalletListing } from "../../common/ChildWalletListing"

interface LoanListingStep1Props {
	isDapper: boolean
	accountSummaries: AccountSummaries
	selectedAccount: AccountSummary
	setSelectedAccount: (account: AccountSummary) => void
	hasChildAccounts: boolean
	loanFormValues: LoanFormValues
	loanFormError: boolean
	spotPrice?: SpotPrice
	flowty: Flowty
}

export const LoanListingStep1: React.FunctionComponent<
	LoanListingStep1Props
> = ({
	isDapper,
	accountSummaries,
	selectedAccount,
	setSelectedAccount,
	hasChildAccounts,
	loanFormValues,
	loanFormError,
	spotPrice,
	flowty,
}) => {
	const inputErrorRef = useRef<HTMLDivElement>(null)

	const isMainnet = flowty.config.network === "mainnet"
	const MIN_LOAN_DURATION = getMinLoanDuration(isMainnet)
	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	useEffect(() => {
		if (loanFormError) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
				return
			}
		}
	}, [loanFormError])

	const { isChildAccountAsset, royaltyRate, listingType } =
		useFlowtyListingModalContext()
	const [tokenType] = useField({ name: `tokenType` })
	const [amountToBorrow] = useField({ name: `amountToBorrow` })
	const [amountToRepay] = useField({ name: `amountToRepay` })

	const feeCalcValue = amountToRepay.value - amountToBorrow.value

	const flowtyFee = feeCalcValue > 0 ? FLOWTY_INTEREST_FEE * feeCalcValue : 0
	const royaltyFee = (royaltyRate || 0) * amountToBorrow.value

	const lenderFunds = amountToBorrow.value + (royaltyFee + flowtyFee)

	const isInvalidBorrowAmount =
		loanFormValues?.amountToBorrow < MIN_AMOUNT_TO_BORROW ||
		loanFormValues?.amountToBorrow > MAX_AMOUNT_TO_BORROW
	const isInvalidRepayAmount =
		loanFormValues?.amountToRepay < MIN_AMOUNT_TO_REPAY ||
		loanFormValues?.amountToRepay > MAX_AMOUNT_TO_REPAY
	const isInvalidDuration =
		Number(loanFormValues?.loanDuration) < MIN_LOAN_DURATION ||
		loanFormValues?.loanDuration === "" ||
		Number(loanFormValues?.loanDuration) > MAX_LOAN_DURATION

	const isInvalidListingDuration =
		Number(loanFormValues?.listingDuration) < MIN_LISTING_DURATION ||
		loanFormValues?.listingDuration === "" ||
		Number(loanFormValues?.listingDuration) > MAX_LISTING_DURATION

	return (
		<div className='h-full' ref={inputErrorRef}>
			<div className='h-full flex flex-col gap-3'>
				<div className='flex flex-col gap-5'>
					{hasChildAccounts && listingType === "loan" && (
						<FlowtyWalletSelector
							selected={selectedAccount}
							setSelected={setSelectedAccount}
							label='Pay To'
							summaries={accountSummaries}
							listingType={"loan"}
							disabledMessage={walletDisabledMessage.payTo}
						/>
					)}
					{hasChildAccounts && isChildAccountAsset && <ChildWalletListing />}
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Token Received
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyListBoxTokenSelector
								name='tokenType'
								orderType={"loan"}
								isDapper={isDapper}
								selectedAccount={selectedAccount}
								flowty={flowty}
							/>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Amount to Borrow
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyNumericInput
								name={`amountToBorrow`}
								valueError={loanFormError && isInvalidBorrowAmount}
								inputError={loanFormError && isInvalidBorrowAmount}
								errorMessage={
									loanFormError && isInvalidBorrowAmount
										? `Between ${numeral(MIN_AMOUNT_TO_BORROW).format(
												"$0,0.0"
										  )} and ${numeral(MAX_AMOUNT_TO_BORROW).format("$0,0.00")}`
										: null
								}
							/>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Amount to Repay
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyNumericInput
								name={`amountToRepay`}
								valueError={
									loanFormError &&
									(loanFormValues?.amountToRepay <=
										loanFormValues?.amountToBorrow ||
										isInvalidRepayAmount)
								}
								inputError={
									loanFormError &&
									(loanFormValues?.amountToRepay <=
										loanFormValues?.amountToBorrow ||
										isInvalidRepayAmount)
								}
								errorMessage={
									loanFormError && isInvalidRepayAmount
										? `Between ${numeral(MIN_AMOUNT_TO_REPAY).format(
												"$0,0.0"
										  )} and ${numeral(MAX_AMOUNT_TO_REPAY).format("$0,0.00")}`
										: loanFormError &&
										  loanFormValues?.amountToRepay <=
												loanFormValues?.amountToBorrow
										? "Must be greater than borrowed amount"
										: null
								}
							/>
						</div>
					</div>
					<div className='w-full flex items-start'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Loan Duration
							</p>
						</div>
						<div className='w-1/2 flex gap-3 items-center'>
							<FlowtyNumericInput
								name={`loanDuration`}
								valueError={loanFormError && isInvalidDuration}
								inputError={loanFormError && isInvalidDuration}
								errorMessage={
									loanFormError && isInvalidDuration
										? `Between ${MIN_LOAN_DURATION} and ${MAX_LOAN_DURATION} days`
										: null
								}
							/>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Days
							</p>
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-3'>
					<FlowtyAccordion
						items={[
							{
								addOn: (
									<div className='flex items-start space-x-2'>
										<NftPrice
											price={Number(lenderFunds)}
											token={tokenType.value}
											spotPriceValue={
												Number(lenderFunds) * (spotPrice?.value || 0)
											}
											toolTipCustomId='lender-funds'
										/>
									</div>
								),
								content: (
									<div className='text-xs text-neutral-500 font-montserrat'>
										The amount that a lender transfers to fund your loan.{" "}
										<a
											href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
											target='_blank'
											className='text-[#04E5A3] font-montserrat underline'
											rel='noreferrer'
										>
											Learn More
										</a>
									</div>
								),
								id: "item-1",
								title: `Lender Funds`,
							},
							{
								addOn: (
									<div className='flex items-start font-montserrat space-x-2'>
										<span className='font-bold'>-</span>
										<NftPrice
											price={royaltyFee}
											token={tokenType.value}
											spotPriceValue={royaltyFee * (spotPrice?.value || 0)}
											toolTipCustomId='royaltyFee'
										/>
									</div>
								),
								badge: "Refundable",
								content: (
									<div className='text-xs text-neutral-500 font-montserrat'>
										If loan is repaid, royalty is returned to the lender. If the
										loan defaults, the royalty is transferred to the NFT
										creator.{" "}
										<a
											href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
											target='_blank'
											className='text-[#04E5A3] font-montserrat underline'
											rel='noreferrer'
										>
											Learn More
										</a>
									</div>
								),
								id: "item-2",
								title: `Creator Royalty (${((royaltyRate || 0) * 100).toFixed(
									2
								)}%)`,
							},
							{
								addOn: (
									<div className='flex items-start space-x-2'>
										<span className='font-bold font-montserrat'>-</span>
										<NftPrice
											price={flowtyFee}
											token={tokenType.value}
											spotPriceValue={flowtyFee * (spotPrice?.value || 0)}
											toolTipCustomId='flowtyFee'
										/>
									</div>
								),
								content: (
									<div className='text-xs text-neutral-500 font-montserrat'>
										A fee charged by Flowty for providing the services on the
										platform.{" "}
										<a
											href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
											target='_blank'
											className='text-[#04E5A3] font-montserrat underline'
											rel='noreferrer'
										>
											Learn More
										</a>
									</div>
								),
								id: "item-3",
								title: `Flowty Fee`,
							},
						]}
					/>
					<hr className='border-[#495057]'></hr>
					<div className='flex justify-between text-white pt-3'>
						<p>If Funded, You&apos;ll Receive</p>
						<div className='flex flex-col items-end'>
							<NftPrice
								price={Number(amountToBorrow.value)}
								token={tokenType.value}
								spotPriceValue={
									Number(amountToBorrow.value) * (spotPrice?.value || 0)
								}
								toolTipCustomId='amountToBorrow'
							/>
						</div>
					</div>
					<div className='w-full flex items-center mt-2 justify-between'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Listing Duration
							</p>
						</div>
						<div className='flex gap-3 items-center'>
							<div className='w-[9.125rem]'>
								<FlowtyNumericInput
									name={`listingDuration`}
									valueError={loanFormError && isInvalidListingDuration}
									inputError={loanFormError && isInvalidListingDuration}
									decimalsAllowed={allowDecimalInDuration(IS_MAINNET)}
									errorMessage={
										loanFormError && isInvalidListingDuration
											? `Between ${MIN_LISTING_DURATION} and ${MAX_LISTING_DURATION} days`
											: null
									}
								/>
							</div>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Days
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
