import { useState } from "react"
import { CheckIcon, LinkIcon } from "@heroicons/react/outline"
import { cn } from "../../util/cn"

type Props = {
	address: string
}

export const CopyPublicProfileLinkButton: React.FunctionComponent<Props> = ({
	address,
}) => {
	const [copied, setCopied] = useState(false)

	const resetCopy = (): void => setCopied(false)

	const handleCopyClick = (): void => {
		setCopied(true)
		setTimeout(resetCopy, 5000)
	}

	const domainUrl = window.location.origin

	return (
		<>
			<button
				disabled={copied}
				data-tooltip-id={`copy-wallet-url-tooltip`}
				data-tooltip-content={`Share Profile URL`}
				data-tooltip-place='right'
				className={cn(
					"bg-[#606E7D33] hover:bg-[#606E7D52] border border-[#FFFFFF1F] rounded-md px-[12px] py-[6px] cursor-pointer flex items-center justify-between w-[126px] md:w-[146px]",
					copied && "text-white bg-[#606E7D52]"
				)}
				onClick={() => {
					handleCopyClick()
					;(navigator as Navigator).clipboard.writeText(
						`${domainUrl}/user/${address}`
					)
				}}
			>
				{copied ? (
					<>
						<span className='font-normal text-[0.875rem] md:text-[14px] mr-[0.25rem] text-[#DEDEDE] leading-[normal]'>
							Profile Copied
						</span>
						<CheckIcon className='w-4 h-4 stroke-primary' />
					</>
				) : (
					<>
						<span className='font-normal text-[0.875rem] md:text-[14px] mr-[0.25rem] text-[#DEDEDE] leading-[normal]'>
							Share Profile
						</span>
						<LinkIcon className='w-4 h-4 stroke-[#DEDEDE]' />
					</>
				)}
			</button>
		</>
	)
}
