import { useState, useEffect, useRef } from "react"
import { FlowtyMessageResponse, FlowtyMessage } from "./FlowtyMessages"
import { fetchJSON } from "../../../../utils/GlobalUtils"

export const useFlowtyMessages = (
	strapiUrl: string,
	mixPanelFn: (event: string, data: unknown) => void
) => {
	const [selectedMessage, setSelectedMessage] = useState<FlowtyMessage>()
	const isFetching = useRef(false)

	useEffect(() => {
		if (isFetching.current) {
			return
		}
		isFetching.current = true
		const fetchMessages = async () => {
			const response = await fetchJSON<{ data: FlowtyMessageResponse[] }>(
				`${strapiUrl}/api/loading-messages`,
				mixPanelFn
			)

			if (!response || !response.data) {
				isFetching.current = false
				return
			}
			const messages: FlowtyMessage[] = response.data.map(item => ({
				Message: item.attributes.Message,
				category: item.attributes.category,
				id: item.id,
			}))
			const randomIndex = Math.floor(Math.random() * messages.length)
			setSelectedMessage(messages[randomIndex])
			isFetching.current = false
		}

		fetchMessages()
	}, [])

	const renderTitle = () => {
		if (
			selectedMessage?.category === "Tip" ||
			selectedMessage?.category === "Fact"
		) {
			return `Flowty ${selectedMessage.category}:`
		} else {
			return `Fun ${selectedMessage?.category} Fact`
		}
	}

	return {
		renderTitle,
		selectedMessage,
	}
}
