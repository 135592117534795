import React, { useEffect, useRef, useState } from "react"
import { FlowtyNavbarProps, navItems } from "../types/NavbarTypes"
import { NavBarLogoAndHamburgerMenu } from "./NavBarLogoAndHamburgerMenu"
import { NavBarSignInAndNotifications } from "./NavBarSignInAndNotifications"
import { SidebarNavigation } from "./SidebarNavigation"

export const NavbarTablet: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	registerHeight,
	authLoading,
	notificationData,
	logUser,
	selectedItem,
	loggedUserAddress,
	disconnectWallet,
	childAccounts,
	isDapper,
	user,
}) => {
	const [sidebarNavigation, setSidebarNavigation] = useState<number>(0)
	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = (): void => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = (): void => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	useEffect(() => {}, [sidebarNavigation])

	const baseStyles = `${bgColor} py-[18px] flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-50 font-black text-white`

	return (
		<div>
			<div
				ref={navbarRef}
				className={baseStyles}
				data-testid='navbar-component'
			>
				<nav className={`container flex w-full justify-between h-[40px]`}>
					<NavBarLogoAndHamburgerMenu
						setSidebarNavigation={() => setSidebarNavigation(300)}
					/>
					<NavBarSignInAndNotifications
						fetchMoreNotifications={notificationData.fetchMoreNotifications}
						loadingNotifications={notificationData.loadingNotifications}
						notifications={notificationData.notifications}
						hasMore={notificationData.hasMore}
						queueNotification={notificationData.queueNotification}
						isLoading={authLoading}
						isLogged={isLogged}
						logUser={logUser}
						loggedUserAddress={loggedUserAddress || ""}
						disconnectWallet={disconnectWallet}
						childAccounts={childAccounts}
						isDapper={isDapper}
						user={user}
					/>
				</nav>
				{sidebarNavigation ? (
					<SidebarNavigation
						isLogged={isLogged}
						width={sidebarNavigation}
						onClose={() => setSidebarNavigation(0)}
						navItems={navItems}
						selectedItem={selectedItem}
					/>
				) : null}
			</div>
		</div>
	)
}
