import FlowtyLoadingGif from "../../../../assets/flowty-loading2.gif"
import { TransactionFollowBox } from "../../../../TransactionFollowBox"

interface TransactionProcessingProps {
	transactionID?: string | null
	isMainnet: boolean
}

export const TransactionProcessing: React.FunctionComponent<
	TransactionProcessingProps
> = ({ transactionID, isMainnet }) => {
	return (
		<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<img
				className='w-[7.75rem] md:h-[7.75rem]'
				src={FlowtyLoadingGif}
				alt='Transaction Loading GIF'
			/>
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Processing...
			</div>
			<div className='h-[88px]'>
				<TransactionFollowBox
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
			</div>
		</div>
	)
}
