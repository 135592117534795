import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import React, { Fragment, PropsWithChildren, ReactNode } from "react"
import { cn } from "../../../util/cn"

interface SimpleModalProps extends PropsWithChildren {
	action: string
	actionType: "positive" | "negative"
	head: ReactNode
	isShowing: boolean
	onClose: () => void
	className?: string
	onAction: () => void
}

export const SimpleModal: React.FC<SimpleModalProps> = ({
	action,
	actionType,
	children,
	head,
	className,
	isShowing,
	onAction,
	onClose,
}) => {
	return (
		<Transition appear show={isShowing} as={Fragment}>
			<Dialog
				as='div'
				className={cn("relative z-40", className)}
				onClose={onClose}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className=' transform rounded-xl bg-[#212b36]   text-left align-middle shadow-xl transition-all'>
								<Dialog.Title
									as='div'
									className='border-b border-modal px-4 py-4 w-full flex justify-between items-center'
								>
									{head}

									<XIcon
										onClick={onClose}
										className='h-8 w-8 cursor-pointer hover:fill-gray-400'
									/>
								</Dialog.Title>
								<div className='flex flex-col lg:flex-row w-full'>
									{children}
								</div>
								<div className='flex w-full justify-center px-4 pt-3 pb-6 border-t border-modal'>
									<button
										onClick={onClose}
										className='w-full h-10 mr-4 flex justify-center items-center border border-white rounded-md font-extrabold text-base shadow-slate-100 hover-button-white'
									>
										Cancel
									</button>
									<button
										onClick={onAction}
										className={`w-full h-10 flex justify-center items-center uppercase  border-transparent rounded-md border-2 text-base font-extrabold text-black ${
											actionType === "positive"
												? "bg-primary hover-button"
												: "bg-[#FF2F2F] hover-button-red"
										}`}
									>
										{action}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
