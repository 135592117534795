import { ProfileLink } from "../common/ProfileLink"

interface Props {
	lender: string
}

export const LoanFundedRow: React.FunctionComponent<Props> = ({ lender }) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Loan funded</span> by{" "}
				<ProfileLink address={lender} />{" "}
			</p>
		</div>
	)
}
