// eslint-disable-next-line import/no-unresolved
import { Tweet } from "react-tweet"
import { cn } from "../../utils/cn"
import Marquee from "react-fast-marquee"

export interface EmbeddedTweetResponse {
	data: {
		id: number
		attributes: {
			tweetId: string
			createdAt: string
			updatedAt: string
			publishedAt: string
		}
	}[]
	meta: {
		pagination: {
			page: number
			pageSize: number
			pageCount: number
			total: number
		}
	}
}

export const TwitterFeed = ({
	tweetIds,
	isLoading,
	className,
}: {
	tweetIds: string[]
	isLoading: boolean
	className?: string
}) => {
	return (
		<div
			className='py-[40px] md:py-[80px] lg:py-[116px]'
			style={{
				background: "rgba(255, 255, 255, 0.04)",
			}}
		>
			<h4 className='container mx-auto md:px-[113px] lg:px-[178px] text-[18px] lg:text-[32px] md:text-[24px] font-montserrat text-white font-extrabold leading-normal'>
				Trusted and Loved by Many
			</h4>
			<Marquee
				className={cn(
					"relative overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
					className
				)}
			>
				<ul
					className={cn(
						"flex flex-row shrink-0 lg:gap-x-[28px] flex-nowrap gap-x-[24px] mt-[20px] md:mt-[40px] items-center md:items-start"
					)}
				>
					{tweetIds.map(id => (
						<div
							data-theme='dark'
							key={id}
							className='px-[15px] md:py-[10px] rounded-[7px]'
							style={{
								border: "0.861px solid #343A40)",
							}}
						>
							<Tweet id={id} />
						</div>
					))}
				</ul>
			</Marquee>
		</div>
	)
}

export default TwitterFeed
