import { StorefrontData } from "../types"

interface MapStorefrontEventToBuyerOrSellerProps {
	item: StorefrontData
	loggedUserAddress: string
}

export const mapStorefrontEventToBuyerOrSeller = ({
	item,
	loggedUserAddress,
}: MapStorefrontEventToBuyerOrSellerProps): "Buyer" | "Seller" | undefined => {
	if (item.type === "STOREFRONT_OFFER_ACCEPTED") {
		return "Buyer"
	}
	if (item.type === "STOREFRONT_LISTED") {
		return "Seller"
	}
	if (item.type === "STOREFRONT_DELISTED") {
		return "Seller"
	}
	if (item.type === "STOREFRONT_OFFER_CREATED") {
		return "Buyer"
	}
	if (item.type === "STOREFRONT_OFFER_CANCELLED") {
		return "Buyer"
	}
	if (item.type === "STOREFRONT_PURCHASED") {
		if (item?.data?.buyer === loggedUserAddress) {
			return "Buyer"
		}
		if (item?.accountAddress === loggedUserAddress) {
			return "Seller"
		}
	}
}
