import { Link } from "react-router-dom"
import { ReactComponent as FlowtyLogo } from "../../assets/flowty_logo.svg"
import { cn } from "../../utils/cn"
import { navItems } from "../types/NavbarTypes"
import { HelpPopover } from "./HelpPopover"

type Props = {
	selectedItem?: string
}

export const NavBarLogoAndLinks: React.FunctionComponent<Props> = ({
	selectedItem,
}) => {
	return (
		<div className={cn("flex items-center flex-shrink-0")}>
			<a href='/' className='flex items-center'>
				<FlowtyLogo className='h-full w-28 mr-[16px] ml-[-6px]' />
			</a>
			<ul className={cn("flex list-none")}>
				{Object.entries(navItems).map(([name, href]) => {
					return (
						<li
							key={name}
							className={`py-[12px] ${
								selectedItem === name ? "pl-[12px] pr-[24px]" : "px-[24px]"
							}`}
						>
							<Link
								to={href}
								className={`flex items-center hover-nav-item transform text-size-[.875rem] rounded-md tracking-wider whitespace-nowrap font-black uppercase text-white duration-300 ease-in-out gap-[8px]`}
								aria-current={selectedItem === name ? "page" : undefined}
							>
								{selectedItem === name && (
									<div className='rounded bg-[#04E5A3] h-[14px] w-1'></div>
								)}
								{name}
							</Link>
						</li>
					)
				})}
			</ul>
			<HelpPopover className='py-[12px] px-[24px]' />
		</div>
	)
}
