import { cn } from "../../../../util/cn"

interface Props {
	days: number
}

export const ActionCardChip: React.FunctionComponent<Props> = ({ days }) => {
	const getStrokeColor = (): string => {
		if (days <= 1) {
			return "#FF6969CC"
		} else if (days <= 3) {
			return "#FF8A4FCC"
		} else {
			return "#9A9A9ACC"
		}
	}
	return (
		<div className='bg-[#000000ab] absolute bottom-[-10px] m-auto flex items-center gap-1'>
			<div
				className={cn(
					"flex items-center gap-1 border px-[6px] py-[4px] rounded",
					days <= 1 && "bg-[#FF69693D] border-[#FF696985]",
					days > 1 && days <= 3 && "bg-[#FF8A4F3D] border-[#FF8A4F85]",
					days > 3 && "bg-[#9A9A9A3D] border-[#9A9A9A85]"
				)}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='12'
					height='12'
					viewBox='0 0 12 12'
					fill='none'
				>
					<path
						d='M6 4V6L7.5 7.5M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6Z'
						stroke={getStrokeColor()}
						strokeOpacity='0.8'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
				<p
					className={cn(
						"text-[10px] font-[500] leading-none",
						days <= 1 && "text-[#FF6969CC] hover:text-[#FF6969CC]",
						days > 1 && days <= 3 && "text-[#FF8A4FCC]",
						days > 3 && "text-[#9A9A9ACC]"
					)}
				>
					{days} {days > 1 ? "Days" : "Day"}
				</p>
			</div>
		</div>
	)
}
