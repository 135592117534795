import { flowty } from "../../../../config/sdk"

export const IS_MAINNET = flowty.config.network === "mainnet"

const MIN_AMOUNT_TO_BORROW = 0.1
const MAX_AMOUNT_TO_BORROW = 248500
const MIN_AMOUNT_TO_REPAY = 0.1
const MAX_AMOUNT_TO_REPAY = 250000
const getMinLoanDuration = (isMainnet: boolean) => {
	return isMainnet ? 1 : 0
}
const MAX_LOAN_DURATION = 365

const getMinRentDuration = (isMainnet: boolean) => {
	return isMainnet ? 1 : 0
}
const MAX_RENT_DURATION = 375

export {
	MIN_AMOUNT_TO_BORROW,
	MAX_AMOUNT_TO_BORROW,
	MIN_AMOUNT_TO_REPAY,
	MAX_AMOUNT_TO_REPAY,
	getMinLoanDuration,
	MAX_LOAN_DURATION,
	getMinRentDuration,
	MAX_RENT_DURATION,
}
