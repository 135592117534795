import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"
import "./assets/style/App.scss"
import "./config/config"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { sentryAPI } from "./util/settings"

if (sentryAPI) {
	Sentry.init({
		dsn: sentryAPI,
		integrations: [
			new BrowserTracing({
				tracePropagationTargets: [],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	})

	window.addEventListener("error", function (event) {
		Sentry.withScope(_ => {
			Sentry.captureException(event.error)
		})
	})
}

const appContainer = document.getElementById("root")
if (appContainer === null) {
	throw new Error("Unable to find app container root")
}

const root = createRoot(appContainer)

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
