import React from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as BulkCursorClick } from "../../assets/media/icons/BulkCursorClick.svg"
import { useMarketplaceAppContext } from "../../contexts/MarketplaceAppContext/MarketplaceAppContext"
import { actions as Mixpanel } from "../../util/Mixpanel"

export const BulkSelectButton: React.FunctionComponent = () => {
	const { toggleBulkList, bulkListActive } = useMarketplaceAppContext()
	const location = useLocation()

	const isProfilePage = location.pathname.includes("profile")

	if (!isProfilePage) return null
	return (
		<>
			{location.pathname.includes("profile") && (
				<button
					data-testid='bulk-select-btn'
					onClick={() => {
						toggleBulkList()
						if (!bulkListActive) {
							Mixpanel.track("BULK_SELECT_OPENED")
						} else {
							Mixpanel.track("BULK_SELECT_CLOSED")
						}
					}}
					className={`w-auto h-fit lg:!w-max py-2 px-4 flex justify-center items-center gap-1.5 rounded-md group cursor-pointer border bg-[#FFFFFF] backdrop-blur-sm
                    						${
																	bulkListActive
																		? "bg-opacity-[8%] border-emerald-400"
																		: "border-transparent hover:bg-opacity-[15%] bg-opacity-[8%]"
																}
										`}
				>
					<p
						className={`text-sm font-medium ${
							bulkListActive ? "text-emerald-400 " : "text-white"
						}
						`}
					>
						Bulk Select
					</p>

					<BulkCursorClick
						className={`h-6 w-6 ${
							bulkListActive ? "stroke-emerald-400" : "stroke-white"
						}
						`}
					/>
				</button>
			)}
		</>
	)
}
