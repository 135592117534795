import { ContractsWithValuations } from "../firestore"

export type OTMContracts = Extract<
	ContractsWithValuations,
	"AllDay" | "TopShot"
>

type OTMValueID<Contract extends OTMContracts> = {
	[C in Contract as `${C}TrueValue.id`]: string | null
}
type OTMValuePred<Contract extends OTMContracts> = {
	[C in Contract as `${C}TrueValue.pred`]: string | null // string float - not sure if always this formats
}
type OTMValueAmount<Contract extends OTMContracts> = {
	[C in Contract as `${C}TrueValue.trueValue`]: number | null
}

type OTMValue<Contract extends OTMContracts> = OTMValueID<Contract> &
	OTMValuePred<Contract> &
	OTMValueAmount<Contract>

interface OwnTheMomentValuationBase {
	/* eslint-disable @typescript-eslint/naming-convention */
	momentId: string
	serial: number
	playId: string
	packListingID: string
	acquiredAt: string // timestamptz - Date parsable
	serialBucket: number
	flowID: number
	owner_id: string // probably potentially null
	listingpk_id: string
	/* eslint-enable @typescript-eslint/naming-convention */
}

export interface OwnTheMomentTopShotValuation
	extends OwnTheMomentValuationBase,
		OTMValue<"TopShot"> {
	/* eslint-disable @typescript-eslint/naming-convention */
	locked: boolean | null
	circulationId: string | null
	time_stamp: string // timestamptz - Date parsable
	set_id_id: string
	moment_set_play_id: string
	true_value_id: string // probably potentially null
	["TopShotTrueValue.trueValue"]: number | null
	/* eslint-enable @typescript-eslint/naming-convention */
}

export interface OwnTheMomentAllDayValuation
	extends OwnTheMomentValuationBase,
		OTMValue<"AllDay"> {
	/* eslint-disable @typescript-eslint/naming-convention */
	id: number
	cursor: string
	updatedAt: string // timestamptz format Date
	setFlowID_id: number
	editionFlowID_id: number
	trueValue_id: string
	/* eslint-enable @typescript-eslint/naming-convention */
}

export type OwnTheMomentValuation =
	| OwnTheMomentAllDayValuation
	| OwnTheMomentTopShotValuation

export function isTopShotOTMValuation(
	v: OwnTheMomentValuation
): v is OwnTheMomentTopShotValuation {
	return (
		(v as OwnTheMomentTopShotValuation)["TopShotTrueValue.trueValue"] !==
		undefined
	)
}

interface NormalizedOTMValues {
	setId: string
	value: number | null
	contractName: OTMContracts
}

export const getNormalizedOTMValues = (
	valuation: OwnTheMomentValuation
): NormalizedOTMValues => {
	if (isTopShotOTMValuation(valuation)) {
		const v = valuation as OwnTheMomentTopShotValuation
		return {
			contractName: "TopShot",
			setId: v.set_id_id || "",
			value: v["TopShotTrueValue.trueValue"],
		}
	}

	const v = valuation as OwnTheMomentAllDayValuation
	return {
		contractName: "AllDay",
		setId: v.setFlowID_id?.toString() || "",
		value: v["AllDayTrueValue.trueValue"],
	}
}
