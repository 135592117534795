import { useEffect, useRef, useState } from "react"
import { cn } from "../utils/cn"

interface FlowtyFilterMenuProps {
	fields: { label: string; value: string }[]
	selectedField: string
	updateSelectedField: (field: string) => void
}

export const FlowtyFilterMenu: React.FunctionComponent<
	FlowtyFilterMenuProps
> = ({ fields, selectedField, updateSelectedField }) => {
	const [isOpen, setIsOpen] = useState(false)
	const popupRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("click", handleClickOutside)
		} else {
			document.removeEventListener("click", handleClickOutside)
		}

		return () => {
			document.removeEventListener("click", handleClickOutside)
		}
	}, [isOpen])

	return (
		<div className='relative w-full md:w-[340px]' ref={popupRef}>
			<button
				onClick={e => {
					e.stopPropagation()
					setIsOpen(!isOpen)
				}}
				className='flex h-[2.5rem] justify-center items-center py-2 text-base border border-[#FFFFFF1F] rounded-md bg-[#606e7d33] hover:bg-glassFillGrayHover font-normal max-sm:px-4 px-8 w-full'
			>
				Filter By:
				<span className={`text-base lg:text-sm 2xl:text-base mx-1 mr-2`}>
					{fields.find(field => field.value === selectedField)?.label ||
						"Recently Viewed"}
				</span>{" "}
			</button>

			<div
				className={cn(
					`absolute top-[42px] rounded-md overflow-hidden transition-all duration-500 ease-in-out z-10 w-full bg-[#04070B] border border-[#FFFFFF1F]`,
					{
						"max-h-0 p-0 opacity-0": !isOpen,
						"max-h-96 opacity-100": isOpen,
					}
				)}
			>
				<div className='bg-[#606E7D33] py-3'>
					{fields?.map(field => (
						<button
							className='flex justify-between items-center px-4 py-2 cursor-pointer w-full hover:bg-slate-500 hover:bg-opacity-20 whitespace-nowrap'
							key={field.label}
							onClick={e => {
								e.stopPropagation()
								updateSelectedField(field.value)
								setIsOpen(false)
							}}
						>
							<span
								className={`text-[14px] mr-4 font-medium text-[#F8F9FA] ${
									selectedField === field.value && "text-primary"
								} `}
							>
								{field.label}
							</span>
						</button>
					))}
				</div>
			</div>
		</div>
	)
}
