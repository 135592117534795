import { ReactComponent as BackArrowMobile } from "../../../../assets/backArrowMobile.svg"
import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../../../types/FlowtyCreatorHubContextTypes"

export const HeaderFormTab: React.FunctionComponent = () => {
	const { formTab, moveToPreviousTab } = useFlowtyCreatorHubContext()

	return (
		<>
			<div
				className={`w-full flex flex-col gap-[16px] 3xl:gap-[24px] max-w-[710px] z-10`}
				data-testid='navbar-step-selector'
			>
				<div
					className={`flex gap-2 items-center`}
					data-testid='navbar-step-selector-title'
				>
					{formTab !== FormTabs.WelcomeCreatorHubTab && (
						<button
							onClick={moveToPreviousTab}
							className='cursor-pointer fill-white'
						>
							<BackArrowMobile />
						</button>
					)}
					<p
						className={`text-[22px] md:text-[32px] 3xl:text-[44px] whitespace-nowrap creator-hub-title-gradient font-bold leading-none`}
					>
						Create Collection
					</p>
				</div>
				<div className='flex gap-[9.333px] 3xl:gap-[14px]'>
					<div className={`flex flex-col gap-2 max-md:w-full w-[106px]`}>
						<div
							className={`rounded-xl h-[8px] ${
								formTab >= 1 ? "bg-white" : "bg-[#495057]"
							}`}
						/>
						<p
							className={
								formTab >= 1
									? "text-[#F8F9FA] text-[12px] font-semibold whitespace-nowrap leading-none"
									: "text-[#495057] text-[12px] font-semibold whitespace-nowrap leading-none"
							}
						>
							<span className='hidden md:flex text-[12px]'>
								Customize Collection
							</span>
							<span className='flex md:hidden'>Collection</span>
						</p>
					</div>
					<div className={`flex flex-col gap-2 max-md:w-full w-[106px]`}>
						<div
							className={`rounded-xl h-[8px]  ${
								formTab >= 2 ? "bg-white" : "bg-[#495057]"
							}`}
						/>
					</div>
					<div className={`flex flex-col gap-2 max-md:w-full w-[106px]`}>
						<div
							className={`rounded-xl h-[8px]  ${
								formTab >= 3 ? "bg-white" : "bg-[#495057]"
							}`}
						/>
						<p
							className={
								formTab >= 3
									? "text-[#F8F9FA] text-[12px] font-semibold whitespace-nowrap leading-none"
									: "text-[#495057] text-[12px] font-semibold whitespace-nowrap leading-none"
							}
						>
							<span className='hidden md:flex text-[12px]'>
								Customize Drop Page
							</span>
							<span className='flex md:hidden'>Drop Page </span>
						</p>
					</div>
					<div className={`flex flex-col gap-2 max-md:w-full w-[106px]`}>
						<div
							className={`rounded-xl h-[8px]  ${
								formTab >= 4 ? "bg-white" : "bg-[#495057]"
							}`}
						/>
						<p
							className={
								formTab >= 4
									? "text-[12px] text-[#F8F9FA] font-semibold text-end whitespace-nowrap leading-[15px]"
									: "text-[12px] text-[#495057] font-semibold text-end whitespace-nowrap leading-[15px]"
							}
						>
							End
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
