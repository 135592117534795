import { addDays } from "date-fns"
import {
	CollectionEditionType,
	CollectionType,
	FileType,
	FlowtyCreatorHubFormValues,
} from "../types/FlowtyCreatorHubTypes"

export const creatorHubInitialValues = (
	walletAddress: string
): FlowtyCreatorHubFormValues => {
	return {
		collectionDescription: "",
		collectionEditionType: CollectionEditionType.OpenEdition,
		collectionName: "",
		collectionType: CollectionType.SingleImage,
		collectionWebsiteURL: "",
		creatorWalletAddress: walletAddress,
		discordHandle: null,
		dropEndDateTime: { dateTime: addDays(Date.now(), 7).getTime() },
		dropStartDateTime: { dateTime: new Date().getTime(), isNow: true },
		dropThumbnailImage: null,
		exampleSalePrice: 100,
		instagramHandle: null,
		mintPrice: null,
		nftBannerImage: {
			file: new File([], "FLOWTY_DEFAULT_IMAGE"),
			fileBase64: undefined,
			fileCid:
				"QmWz1ticnmu3PNWb68uMPFGffn5pXhpiUin7qfcSaotrGX?pinataGatewayToken=xFpPpu5fb5PZMLV6RF8ueOm08-BkzG5BLYJf1jVw1NL8RUORAi5yj3BwsexCJWIz",
			fileName: "defaultBannerImage.png",
		} as FileType,
		nftImage: null,
		nftThumbnailImage: null,
		royalty: null,
		twitterHandle: null,
	} as FlowtyCreatorHubFormValues
}
