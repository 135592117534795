import { Config } from "../types"

export const getAddressesWithCollectionPublicScript = (
	config: Config
): string =>
	!config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getAddressesWithCollectionPublicScript(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getAddressesWithCollectionPublicScriptCrescendo(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getAddressesWithCollectionPublicScript = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

import NFTCatalog from ${config.contractAddresses.NFTCatalog}

pub fun main(addressesToCheck: [Address], contractAddress: Address, contractName: String): [Address] {
  let pathOpt = getReceiverPath(contractAddress, contractName)
  if pathOpt == nil {
    return []
  }

  let pathToCheck = pathOpt!

  let matches: [Address] = []
  for a in addressesToCheck {
    let acct = getAccount(a)
    if acct.getCapability<&{NonFungibleToken.CollectionPublic}>(pathToCheck).check() {
      matches.append(a)
    }
  }
  return matches
}

pub fun getReceiverPath(_ address: Address, _ name: String): PublicPath? {
  // build the nft type identifier
  let contractAddressStr = address.toString()
  let identifier = "A.".concat(contractAddressStr.slice(from: 2, upTo: contractAddressStr.length)).concat(".").concat(name).concat(".NFT")

  if let catalogEntries = NFTCatalog.getCollectionsForType(nftTypeIdentifier: identifier) {
    var catalogIdentifier = ""
    for k in catalogEntries.keys {
      if catalogEntries[k] == true {
        catalogIdentifier = k
        break
      }
    }

    if let entry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
      return entry.collectionData.publicPath
    }
  }

  let c = getAccount(address).contracts.borrow<&ViewResolver>(name: name)

  if c == nil {
    return nil
  }

  let md = c!.resolveView(Type<MetadataViews.NFTCollectionData>())
  if md == nil {
    return nil
  }

  let collectionData = md! as! MetadataViews.NFTCollectionData
  return collectionData.publicPath
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getAddressesWithCollectionPublicScriptCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

import NFTCatalog from ${config.contractAddresses.NFTCatalog}

access(all) fun main(addressesToCheck: [Address], contractAddress: Address, contractName: String): [Address] {
  let pathOpt = getReceiverPath(contractAddress, contractName)
  if pathOpt == nil {
    return []
  }

  let pathToCheck = pathOpt!

  let matches: [Address] = []
  for a in addressesToCheck {
    let acct = getAccount(a)
    if acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(pathToCheck).check() {
      matches.append(a)
    }
  }
  return matches
}

access(all) fun getReceiverPath(_ address: Address, _ name: String): PublicPath? {
  // build the nft type identifier
  let contractAddressStr = address.toString()
  let identifier = "A.".concat(contractAddressStr.slice(from: 2, upTo: contractAddressStr.length)).concat(".").concat(name).concat(".NFT")
  let nftType = CompositeType(identifier) ?? panic("invalid identifier: ".concat(identifier))

  if let catalogEntries = NFTCatalog.getCollectionsForType(nftTypeIdentifier: identifier) {
    var catalogIdentifier = ""
    for k in catalogEntries.keys {
      if catalogEntries[k] == true {
        catalogIdentifier = k
        break
      }
    }

    if let entry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
      return entry.collectionData.publicPath
    }
  }

  let c = getAccount(address).contracts.borrow<&{ViewResolver}>(name: name)

  if c == nil {
    return nil
  }

  let md = c!.resolveContractView(resourceType: nftType, viewType: Type<MetadataViews.NFTCollectionData>())
  if md == nil {
    return nil
  }

  let collectionData = md! as! MetadataViews.NFTCollectionData
  return collectionData.publicPath
}`
