import { format } from "date-fns"
import { SupportedTokens } from "flowty-common"
import { AccountSummary, Flowty } from "flowty-sdk"

interface Props {
	salePrice: number
	spotPrice: number
	token: SupportedTokens
	valuation: number
}

export function calcDiscountWithAmountAndValuation({
	salePrice,
	spotPrice,
	token,
	valuation,
}: Props): number | null {
	if (!valuation || !salePrice) return 0

	let percentAsDecimal: number

	if (token === SupportedTokens.FLOW) {
		if (spotPrice === 0) {
			return null
		}
		const dollarAmount = salePrice * spotPrice
		percentAsDecimal = 1 - dollarAmount / valuation
	} else {
		percentAsDecimal = 1 - salePrice / valuation
	}
	const percentFixed = Number(percentAsDecimal.toFixed(2))
	return Math.floor(percentFixed * 100)
}

export function checkAccountHasBalanceForOffer(
	offerAmount: number,
	offerToken: SupportedTokens,
	wallet: AccountSummary,
	flowty: Flowty
): boolean {
	if ([SupportedTokens.DUC].includes(offerToken || "")) {
		return true
	}

	const tokenIdentifier = flowty.tokens.getTokenIdentifier(offerToken)
	if (!tokenIdentifier) {
		return false
	}

	const tokenBalance = wallet.tokens[tokenIdentifier]?.balance || 0
	return tokenBalance >= offerAmount
}

export function checkAccountHasBalanceForStorefront(
	wallet: AccountSummary,
	orderSalePrice: number,
	orderToken: SupportedTokens,
	flowty: Flowty
): boolean {
	if ([SupportedTokens.DUC].includes(orderToken || "")) {
		return true
	}

	const tokenIdentifier = flowty.tokens.getTokenIdentifier(orderToken)
	if (!tokenIdentifier) {
		return false
	}

	const tokenBalance = wallet?.tokens?.[tokenIdentifier]?.balance || 0
	return tokenBalance >= orderSalePrice
}

export function checkAccountHasBalanceForRental(
	wallet: AccountSummary,
	orderDeposit: number,
	orderAmount: number,
	orderToken: SupportedTokens,
	flowty: Flowty
): boolean {
	if ([SupportedTokens.DUC].includes(orderToken || "")) {
		return true
	}

	const tokenIdentifier = flowty.tokens.getTokenIdentifier(orderToken)
	if (!tokenIdentifier) {
		return false
	}

	const tokenBalance = wallet?.tokens?.[tokenIdentifier]?.balance || 0
	return tokenBalance >= orderAmount + orderDeposit
}

export function checkAccountHasBalanceForLoan(
	wallet: AccountSummary,
	orderToken: string,
	marketplaceAmount?: number
): boolean {
	if (
		orderToken?.includes("DapperUtilityCoin") ||
		orderToken?.includes("FlowUtilityToken")
	) {
		return true
	}

	if (!marketplaceAmount) {
		return false
	}

	const tokenBalance = wallet?.tokens?.[orderToken]?.balance || 0

	return tokenBalance >= marketplaceAmount
}

export const getListingExpirationTime = (listingDuration: number): string => {
	if (isNaN(listingDuration)) return "-"
	const now = new Date()
	now.setDate(now.getDate() + listingDuration)
	return format(now, "yyyy-MM-dd HH:mm")
}
