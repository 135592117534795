import { useContext, useEffect, useMemo, useState } from "react"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"
import { LoanForm } from "../LoanForm/LoanForm"
import { ListingType } from "../ListingType/ListingType"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { SortColumn } from "../../../OpenSearch/types"
import { StorefrontForm } from "../StorefrontForm/StorefrontForm"
import { RentalForm } from "../RentalForm/RentalForm"
import { useLocation } from "react-router-dom"

const defaultSortsPaths = {
	all: "latestBlock",
	loan: "blockTimestamp",
	rental: "blockTimestamp",
	storefront: "blockTimestamp",
}

export const ListingTypesWrapper: React.FunctionComponent = () => {
	const {
		orderFilters,
		setOrderFilters,
		setSort,
		onlyUnlisted,
		setOnlyUnlisted,
	} = useContext<OpenSearchContextValues>(OpenSearchContext)

	const [selectedListingType, setSelectedListingType] = useState<
		"loan" | "rental" | "storefront" | "all" | "unlisted"
	>("storefront")
	const [listingIsOpen, setListingIsOpen] = useState({
		loan: false,
		rental: false,
		storefront: false,
	})

	const location = useLocation()
	const currentRoute = location.pathname

	const isUserRoute = useMemo(
		() => currentRoute.includes("/user"),
		[currentRoute]
	)

	useEffect(() => {
		if (orderFilters?.loan) {
			return setSelectedListingType("loan")
		}
		if (orderFilters?.all) {
			return setSelectedListingType("all")
		}
		if (orderFilters?.storefront) {
			return setSelectedListingType("storefront")
		}
		if (orderFilters?.rental) {
			return setSelectedListingType("rental")
		}
		if (onlyUnlisted) {
			return setSelectedListingType("unlisted")
		}
		setSelectedListingType("all")
	}, [orderFilters])

	const multipleTypesSelected = Object.keys(orderFilters).length > 1

	const changeListingType = (
		listingType: "loan" | "rental" | "storefront" | "all" | "unlisted" | null
	): void => {
		Mixpanel.track(`${listingType} listing toggled`, {
			listingType: listingType,
		})
		setListingIsOpen({
			loan: false,
			rental: false,
			storefront: false,
			[listingType as "loan" | "rental" | "storefront"]: true,
		})

		setSelectedListingType(
			listingType as "loan" | "rental" | "storefront" | "all" | "unlisted"
		)

		if (listingType === "unlisted") {
			setOnlyUnlisted(true)
		} else {
			setOnlyUnlisted(false)
		}
		if (listingType === "all") {
			setOrderFilters({ all: {} })
			setSort(
				isUserRoute
					? {
							direction: "desc",
							listingKind: null,
							path: "latestBlock",
							prefix: "",
					  }
					: ({
							direction: "desc",
							listingKind: null,
							path: "blockTimestamp",
					  } as SortColumn)
			)
			return
		}

		if (listingType === "unlisted" || listingType === null) {
			return setOrderFilters({})
		}

		if (
			orderFilters[listingType as "loan" | "rental" | "storefront"] &&
			!multipleTypesSelected
		) {
			setOrderFilters({})
			setSort({
				direction: "desc",
				listingKind: null,
				path: "blockTimestamp",
				prefix: undefined,
			})
		} else {
			const defaultSort = {
				direction: "desc",
				listingKind: listingType,
				path: defaultSortsPaths[
					listingType as "loan" | "rental" | "storefront"
				],
				prefix: undefined,
			}
			setOrderFilters({ [listingType as "loan" | "rental" | "storefront"]: {} })
			if (isUserRoute) {
				const sort: SortColumn = {
					direction: "desc",
					listingKind: listingType,
					path: "blockTimestamp",
				}
				setSort(sort)
			} else {
				setSort(defaultSort as SortColumn)
			}
		}
	}

	return (
		<div className='space-y-2'>
			<ListingType
				name='listing-type'
				value='all'
				label='Show All'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "all"}
				iconPosition={"right"}
				collapsible={false}
			/>
			<ListingType
				name='listing-type'
				value='storefront'
				label='Buy Now'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "storefront"}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.storefront}
				setIsOpen={(isOpen: boolean) => {
					setListingIsOpen({ ...listingIsOpen, storefront: isOpen })
				}}
			>
				<div className='w-full pb-1 mt-2.5'>
					<StorefrontForm />
				</div>
			</ListingType>
			<ListingType
				name='listing-type'
				value='rental'
				label='Rentals'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "rental"}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.rental}
				setIsOpen={(isOpen: boolean) =>
					setListingIsOpen({ ...listingIsOpen, rental: isOpen })
				}
			>
				<div className='w-full pb-1 mt-2.5'>
					<RentalForm />
				</div>
			</ListingType>
			<ListingType
				name='listing-type'
				value='loan'
				label='Loans'
				disabled={false}
				onChange={changeListingType}
				isChecked={selectedListingType === "loan"}
				iconPosition={"right"}
				collapsible
				isOpen={listingIsOpen.loan}
				setIsOpen={(isOpen: boolean) =>
					setListingIsOpen({ ...listingIsOpen, loan: isOpen })
				}
			>
				<div className='w-full pb-1 mt-2.5'>
					<LoanForm />
				</div>
			</ListingType>
			{isUserRoute && (
				<ListingType
					name='listing-type'
					value='unlisted'
					label='Unlisted'
					disabled={false}
					onChange={changeListingType}
					isChecked={selectedListingType === "unlisted"}
					iconPosition={"right"}
					collapsible={false}
				/>
			)}
		</div>
	)
}
