import React from "react"

export const StorefrontDeListedRow: React.FC = () => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Delisted</span>
			</p>
		</div>
	)
}
