/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlowtyFilterMenu } from "ds-flowty"
import {
	nftTypeAndIdToLocationData,
	solveWithAmountAndInterest,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../assets/media/loadingArrow.svg"
import { LoanActivityFilter } from "../components/Activity/Filters"
import { ScrollToLoadMsg } from "../components/Loaders/ScrollToLoadMsg"
import { SortDirection } from "../components/MarketPlace/SortButton/shared"
import { activityTableFields } from "../components/Shared/ActivityTableFields"
import SortableTable, {
	SortColumnOrNull,
} from "../components/SortableTable/index"
import { LoanEventType } from "../components/UserProfile/Tables/Storefront/AllActivity/types"
import useinfiniteScroll from "../hooks/infiniteScroll"
import { subscribeToAllActivity } from "../services/firestore/ActivityService"
import { actions as Mixpanel } from "../util/Mixpanel"
import { defaultNftPageSize } from "../util/settings"
import { ActivityLayout } from "./ActivityLayout"

const ActivityScreen = () => {
	const navigate = useNavigate()

	const [nftPage, setNftPage] = useState(1)

	const [isLoading, setIsLoading] = useState(false)

	const [allBorrowerActivityDocs, setAllBorrowerActivityDocs] = useState<
		Array<any>
	>([])
	const [allLenderActivityDocs, setAllLenderActivityDocs] = useState<
		Array<any>
	>([])
	const [filterBy, setFilterBy] = useState<any>({
		min: "",
		path: "",
	})
	const [showFilter, setShowFilter] = useState(true)
	const [sort, setSort] = useState<SortColumnOrNull>({
		column: "blockTimestamp",
		order: SortDirection.Descending,
	})

	useEffect(() => {
		setNftPage(1)
	}, [sort, filterBy])

	useEffect(
		() =>
			subscribeToAllActivity({
				filterActivityBy: filterBy,
				limit: defaultNftPageSize * nftPage,
				makerCb(docs) {
					setAllBorrowerActivityDocs(docs)
				},
				sort,
				takerCb(docs) {
					setAllLenderActivityDocs(docs)
				},
			}),
		[nftPage, filterBy, sort]
	)

	const allActivityDocs: Array<any> = useMemo(
		() =>
			allBorrowerActivityDocs.map(doc => {
				const amount = Number(doc?.amount || doc.additionalData.amount || 0)
				const interest = Number(
					doc?.rate || doc.additionalData.periodicInterest || 0
				)
				const term = Number(doc?.term || doc.additionalData.term || 0)

				return {
					...doc,
					calculatedValues: {
						...solveWithAmountAndInterest(
							{
								amount,
								interest,
								term,
							},
							doc?.royaltyRate || 0
						),
					},
				}
			}),
		[allLenderActivityDocs, allBorrowerActivityDocs]
	)

	const belowTableRef = useinfiniteScroll(
		{
			isLoading: false,
			onInfiniteScroll() {
				setTimeout(() => {
					setIsLoading(false)
				}, 2000)
				if (nftPage * defaultNftPageSize === allActivityDocs.length) {
					setNftPage(nftPage + 1)
					setIsLoading(true)
				}
				Mixpanel.track("Infinite Scroll Global Activity Page")
			},
		},
		[nftPage, allActivityDocs]
	)

	const loanFilterOptions = [
		{ label: "All", value: "" },
		{ label: "Settled", value: "SETTLED" },
		{ label: "Funded", value: "FUNDED" },
		{ label: "Repaid", value: "REPAID" },
		{ label: "Listed", value: "LISTED" },
		{ label: "Expired", value: "EXPIRED" },
	]

	const [selectedFilter, setSelectedFilter] = useState(
		loanFilterOptions[0].value
	)

	const updateSelectedFilter = (value: string): void => {
		setSelectedFilter(value)
		setFilterBy({
			max: "",
			min: value as LoanEventType,
			path: "state",
		})
	}

	return (
		<ActivityLayout>
			<div>
				<LoanActivityFilter
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>
				<div className='flex mb-[20px]'>
					<FlowtyFilterMenu
						fields={loanFilterOptions}
						selectedField={selectedFilter}
						updateSelectedField={updateSelectedFilter}
					/>
				</div>

				<div className='line-separator mb-3 md:mb-4 bg-gradient-to-r from-darkBg via-secondary to-darkBg' />

				<SortableTable
					onClickRow={({ item }) => {
						const nftType = item?.data?.nftType
						const nftId = item?.data?.nftID.toString()

						const itemIsFullyLoaded = !!(nftType && nftId)
						const locationData = nftTypeAndIdToLocationData(nftType, nftId)

						if (itemIsFullyLoaded) {
							navigate(
								`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
							)
						}
					}}
					fields={activityTableFields(navigate)}
					items={allActivityDocs}
					onSort={setSort}
					initialSortConfig={sort}
					stickyHeaders
					composeRowHref={item => {
						const nftType = item?.data?.nftType
						const nftId = item?.data?.nftID.toString()

						const locationData = nftTypeAndIdToLocationData(nftType, nftId)
						return `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
					}}
				/>
				<div ref={belowTableRef}>
					{isLoading ? (
						<div className='h-10 w-full flex justify-center items-center'>
							<h2 className='justify-center items-center flex flex-row animate-pulse'>
								<RefreshArrow />
								Loading...
							</h2>
						</div>
					) : (
						nftPage * defaultNftPageSize === allActivityDocs.length && (
							<ScrollToLoadMsg />
						)
					)}
				</div>
			</div>
		</ActivityLayout>
	)
}

export default inject("authStore")(observer(ActivityScreen))
