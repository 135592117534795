import { useState } from "react"
import { ReactComponent as Website } from "../../../../assets/media/websiteOutlined.svg"
import { ReactComponent as WebsiteFilled } from "../../../../assets/media/websiteFilled.svg"
import { ExternalURLWarning } from "ds-flowty"

interface WebsiteIconProps {
	link: string
	onSocialLinkClick: (link: string, openModal: () => void) => void
}

export const WebsiteIcon: React.FunctionComponent<WebsiteIconProps> = ({
	link,
	onSocialLinkClick,
}) => {
	const [onHover, setOnHover] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<>
			{onHover ? (
				<WebsiteFilled
					className='cursor-pointer h-5'
					onClick={() => onSocialLinkClick(link, () => setIsModalOpen(true))}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Website
					className='cursor-pointer h-5'
					onClick={() => onSocialLinkClick(link, () => setIsModalOpen(true))}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
			<ExternalURLWarning
				isOpen={isModalOpen}
				linkURL={link}
				onCloseModal={() => setIsModalOpen(false)}
			/>
		</>
	)
}
