import React from "react"
import { useFlowtyCreatorHubContext } from "../../../../../contexts/FlowtyCreatorHubContext"

interface ConfirmationPreviewTabSelectorProps {
	tabs: string[]
	activeTab: string
	onTabClick: (tab: string) => void
}

export const ConfirmationPreviewTabSelector: React.FC<
	ConfirmationPreviewTabSelectorProps
> = ({ tabs = [], activeTab, onTabClick }) => {
	const { hasViewedDropPage, setHasViewedDropPage } =
		useFlowtyCreatorHubContext()

	const handleTabClick = (tab: string): void => {
		if (tab === "Drop Page") {
			setHasViewedDropPage(true)
		}
		onTabClick(tab)
	}

	return (
		<div className='w-fit h-[38px] flex bg-[#FFFFFF14] rounded p-2'>
			{tabs.map(tab => (
				<button
					key={tab}
					className={`relative flex items-center justify-center text-[12px] 3xl:text-[14px] py-[6px] md:py-2 px-[8px] md:px-4 cursor-pointer leading-none ${
						activeTab === tab
							? "text-white font-bold bg-[#FFFFFF3D] p-[6px] rounded"
							: "text-white font-bold"
					}`}
					onClick={() => handleTabClick(tab)}
				>
					{tab}
					{tab === "Drop Page" && !hasViewedDropPage && (
						<div className='absolute top-0 right-0 w-[10px] h-[10px] bg-primary rounded-full drop-shadow-sm'></div>
					)}
				</button>
			))}
		</div>
	)
}
