import numeral from "numeral"
import Swal from "sweetalert2"
import { storage } from "../../firebase"
import { Log } from "../../util/Log"

const maxSize = 200000 // 200KB

const resizeFile = (
	file: File,
	newWidth: number,
	newHeight: number
): Promise<File> => {
	return new Promise<File>((resolve, reject) => {
		if (!file) {
			return
		}
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			const img: HTMLImageElement = document.createElement("img")
			img.src = reader.result as string
			img.onload = () => {
				const canvas = document.createElement("canvas")
				canvas.width = newWidth
				canvas.height = newHeight

				const ctx = canvas.getContext("2d")
				ctx?.drawImage(img, 0, 0, newWidth, newHeight)

				canvas.toBlob(
					blob => {
						const resizedFile = new File([blob ? blob : ""], file.name, {
							lastModified: file.lastModified,
							type: file.type,
						})
						resolve(resizedFile)
					},
					file.type,
					0.8
				)
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			img.onerror = (err: any) => {
				reject(err)
			}
		}
		reader.onerror = err => {
			reject(err)
		}
	})
}

const validateImage = async (file: File): Promise<boolean | undefined> => {
	if (!file) {
		return
	}
	const allowedFileTypes = ["image/jpeg", "image/png"]

	if (file.size > maxSize) {
		Swal.fire({
			confirmButtonColor: "#3085d6",
			text: `${
				file.name
			} is too large, please pick an image with max size ${numeral(
				maxSize
			).format("0,0 b")} KB`,
		})
		return false
	} else if (!allowedFileTypes.includes(file.type)) {
		Swal.fire({
			confirmButtonColor: "#3085d6",
			text: `${file.type} is an invalid file type, please upload from the following file types: ${allowedFileTypes}`,
		})
		return false
	}
	return true
}

export const onChangeHandler = async (
	event: React.ChangeEvent<HTMLInputElement>,
	setImage: (image: string) => void,
	setIsLoading: (isLoading: boolean) => void,
	fileName: string,
	formikSetValue?: (
		field: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any,
		shouldValidate?: boolean | undefined
	) => void
): Promise<void | undefined> => {
	const file: File | null = event?.target?.files && event?.target?.files[0]
	if (!file) {
		return
	}
	Log("Max image size", numeral(maxSize).format("0.0 b"))
	Log("Image size", numeral(file.size).format("0.0 b"))
	let handledFile
	if (file.size > maxSize) {
		handledFile = await resizeFile(file, 300, 300)
		Log("Image Resized: ", numeral(handledFile.size).format("0.0 b"))
	} else {
		handledFile = file
	}

	if (handledFile && (await validateImage(handledFile))) {
		setIsLoading(true)
		const fileExtension = handledFile.name.split(".").pop()
		const storageRef = storage.ref(`/images/${fileName}.${fileExtension ?? ""}`)

		storageRef.put(handledFile).then(() => {
			storageRef.getDownloadURL().then((imagePath: string) => {
				setImage(imagePath)
				formikSetValue?.("avatar", imagePath)
				setIsLoading(false)
			})
		})
	}
}
