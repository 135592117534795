import {
	ListingAvailableData,
	ListingRentedData,
	RentalListingAvailableData,
} from "flowty-common"

import { db } from "../../firebase"
import { Log } from "../../util/Log"
import { FundingAvailableData } from "../flow/FundingService"

export const fetchListingById = async (
	listingID: string
): Promise<ListingAvailableData | null> => {
	Log("fetchListingById", listingID)
	const query = db
		.collection("listingAvailable")
		.where("listingResourceID", "in", [listingID.toString(), Number(listingID)])
		.limit(1)

	const data = await query.get()
	Log("fetchListingById", data.docs)

	if (data.docs.length > 0) {
		const docData = data.docs[0].data()
		Log("fetchListingById", docData)
		const listing = { ...docData } as ListingAvailableData
		listing.blockTimestamp = docData?.blockTimestamp?.toDate()
		return listing
	}
	return null
}

export const fetchRentalListingById = async (
	listingID: string
): Promise<RentalListingAvailableData | null> => {
	Log("fetchRentalListingById", listingID)
	const query = db
		.collection("rentalAvailable")
		.where("listingResourceID", "in", [listingID.toString(), Number(listingID)])
		.limit(1)

	const data = await query.get()
	Log("fetchRentalListingById", data.docs)

	if (data.docs.length > 0) {
		const docData = data.docs[0].data()
		Log("fetchRentalListingById", docData)
		const listing = { ...docData } as RentalListingAvailableData
		listing.blockTimestamp = docData?.blockTimestamp?.toDate()
		return listing
	}
	return null
}

export const getFundingAvailableDataByListingId = async (
	listingId: string
): Promise<FundingAvailableData | null> => {
	if (!listingId) {
		return null
	}

	const query = db
		.collection("fundingAvailable")
		.where("listingResourceID", "in", [listingId.toString(), Number(listingId)])
		.limit(1)

	const data = await query.get()

	if (data.docs.length > 0) {
		const docData = data.docs[0].data()

		const funding = { ...docData } as FundingAvailableData
		if (funding) {
			funding.settleDeadline = docData?.settleDeadline?.toDate()
		}

		return funding
	}
	return null
}

export const getListingRentedDataByListingId = async (
	listingId: string
): Promise<ListingRentedData | null> => {
	const query = db
		.collection("listingRented")
		.where("listingResourceID", "in", [Number(listingId), listingId.toString()])
		.limit(1)

	const data = await query.get()

	if (data.docs.length > 0) {
		const docData = data.docs[0].data()

		const rental = { ...docData } as ListingRentedData

		if (rental) {
			rental.settleDeadline = docData?.settleDeadline?.toDate()
		}

		return rental
	}

	return null
}
