import { ProfileLink } from "../common/ProfileLink"

interface Props {
	renterAddress: string
}

export const RentReturnedRow: React.FunctionComponent<Props> = ({
	renterAddress,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<ProfileLink address={renterAddress} />{" "}
				<span className='font-bold text-[14px]'>returned NFT</span>
			</p>
		</div>
	)
}
