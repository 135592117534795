import {
	ONE_DAY_IN_SECONDS,
	OpensearchRentalAvailableData,
	RentalListingAvailableData,
} from "flowty-common"
import React from "react"
import { getDurationPeriod } from "../../../../../utils/GlobalUtils"
import { NftPrice } from "../../NftPrice"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"

interface RentalOrderDisplayProps {
	order: OpensearchRentalAvailableData | RentalListingAvailableData
	isLoading?: boolean
}

export const RentalOrderDisplay: React.FC<RentalOrderDisplayProps> = ({
	isLoading,
	order,
}) => {
	return (
		<div className='h-[10rem] flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-center'>
						<div>
							<span className='text-white leading-6 text-base font-montserrat font-normal'>
								Rental Fee
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 leading-6 text-bold mb-2'>
								{order?.amount ? (
									<NftPrice
										price={Number(order?.amount)}
										token={order?.paymentTokenName}
										toolTipCustomId='rental-fee-tooltip'
									/>
								) : (
									"-"
								)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-white leading-6 text-base font-montserrat font-normal'>
								Refundable Deposit
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 text-bold text-base font-montserrat'>
								{order?.deposit ? (
									<NftPrice
										price={Number(order?.deposit)}
										token={order?.paymentTokenName}
										toolTipCustomId='refundable-deposit-fee-tooltip'
									/>
								) : (
									"-"
								)}
							</span>
						</div>
					</div>
					<div className='my-1 border-gradient' />
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-white leading-6 text-base font-montserrat font-normal'>
								Total Upfront
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-neutral-600 text-bold text-base font-montserrat'>
								{order?.amount && order?.deposit ? (
									<NftPrice
										price={Number(order.deposit) + Number(order.amount)}
										token={order?.paymentTokenName}
										toolTipCustomId='total-upfront-tooltip'
									/>
								) : (
									"-"
								)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<span className='text-neutral-500 leading-6 text-base font-montserrat font-normal'>
							Duration
						</span>
						<div className='self-center text-end'>
							<span className='text-neutral-600 leading-6 font-medium'>
								{order?.term
									? getDurationPeriod(
											order.state === "LISTED"
												? Number(order.term)
												: Number(order.term) * ONE_DAY_IN_SECONDS
									  )
									: "-"}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
