import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import { useMemo, useState } from "react"
import { ProfileOffersTableField } from "../ProfileOffersTable"
import { OfferCreated, OpensearchFlowNFT } from "flowty-common"

interface OfferRowProps {
	nft: OpensearchFlowNFT
	fields: ProfileOffersTableField[]
}

interface ProfileMoreOffersRowProps {
	moreOffer: OfferCreated
	fields: ProfileOffersTableField[]
	isLast: boolean
	nft: OpensearchFlowNFT
	onHide: () => void
}

const ProfileMoreOffersRow = ({
	nft,
	moreOffer,
	fields,
	isLast,
	onHide,
}: ProfileMoreOffersRowProps) => {
	const [isHidden, setIsHidden] = useState(false)
	return (
		<tr
			className={`text-lightText ${isHidden && "hidden"} ${
				isLast && "border-b-[0.5px] border-[#444444]"
			} `}
		>
			<td />
			{fields.slice(1).map((field, index) =>
				field.customRender({
					callback: () => {
						onHide()
						setIsHidden(true)
					},
					key: `${index}`,
					nft,
					offer: moreOffer,
				})
			)}
		</tr>
	)
}

export const ProfileOfferRow: React.FC<OfferRowProps> = ({ nft, fields }) => {
	const [isOpen, setIsOpen] = useState(true)
	const [isHidden, setIsHidden] = useState(false)
	const offers = nft.offers

	const [currentIdx, setCurrentIdx] = useState(0)
	const [lastVisibleIndex, setLastVisibleIndex] = useState(offers.length - 1)
	const [hiddenIndexes, setHiddenIndexes] = useState<{
		[key: number]: boolean
	}>({})

	const hasMoreOffers = useMemo(
		() => offers.length - Object.keys(hiddenIndexes).length > 1,
		[currentIdx, hiddenIndexes]
	)
	const onHide = () => {
		setHiddenIndexes({ ...hiddenIndexes, [currentIdx]: true })
		let incrementIdex = 1
		if (!hasMoreOffers) {
			return setIsHidden(true)
		}

		while (hiddenIndexes[currentIdx + incrementIdex]) {
			incrementIdex += 1
		}
		setCurrentIdx(currentIdx + incrementIdex)
	}

	return (
		<>
			<tr
				className={`${
					(!hasMoreOffers || (hasMoreOffers && !isOpen)) &&
					"border-b-[0.5px] border-[#444444]"
				} text-lightText ${isHidden && "hidden"}`}
			>
				{offers[currentIdx] &&
					fields.map((field, index) =>
						field.customRender({
							callback: onHide,
							key: `${field.name}-${field.title}-${index}`,
							nft,
							offer: offers[currentIdx],
						})
					)}
				{hasMoreOffers && (
					<td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-[#B5B5B5] text-lg font-medium sm:pr-0'>
						<div
							className='inline-flex cursor-pointer'
							onClick={() => setIsOpen(!isOpen)}
							onKeyDown={event => {
								if (event.key === "Enter") {
									setIsOpen(!isOpen)
								}
							}}
						>
							{`${
								offers.length - (Object.keys(hiddenIndexes).length + 1)
							} more offers`}
							{isOpen ? (
								<ChevronUpIcon className='h-7 w-7 ml-1 cursor-pointer text-primary' />
							) : (
								<ChevronDownIcon className='h-7 w-7 ml-1 cursor-pointer text-primary' />
							)}
						</div>
					</td>
				)}
			</tr>
			{hasMoreOffers &&
				isOpen &&
				offers.slice(currentIdx + 1).map((moreOffer, index) => {
					const realIndex = index + 1 + currentIdx
					const isLast = realIndex === lastVisibleIndex
					return moreOffer ? (
						<ProfileMoreOffersRow
							onHide={() => {
								if (isLast) {
									let newLast = lastVisibleIndex - 1

									while (newLast > 0 && hiddenIndexes[newLast]) {
										newLast -= 1
									}
									setLastVisibleIndex(newLast)
								}
								setHiddenIndexes({
									...hiddenIndexes,
									[realIndex]: true,
								})
							}}
							nft={nft}
							fields={fields}
							moreOffer={moreOffer}
							key={moreOffer.offerResourceID}
							isLast={isLast}
						/>
					) : null
				})}
		</>
	)
}
