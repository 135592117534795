import { useEffect, useMemo, useRef, useState } from "react"
import FlowtySearchBarInput from "./FlowtySearchBarInput"
import { PopularSearches } from "./components/PopularSearches"
import { RecentSearches } from "./components/RecentSearches"
import { Results } from "./components/Results/Results"
import { FlowNFTContract } from "flowty-common"
import { getRecentSearches } from "./util/searchHistory"

export interface GlobalSearchHit<T> {
	score: number // float
	data: T
}

export interface FlowtySearchProps {
	hits: GlobalSearchHit<FlowNFTContract>[]
	popularSearches: FlowNFTContract[]
	onChange: (search: string) => void
	onBlur?: () => void
	value: string
	placeholder: string
	debounceTime: number
	labelText: string
	autoFocus?: boolean
	disabled?: boolean
}

export const FlowtySearch = ({
	value,
	placeholder,
	debounceTime,
	hits,
	disabled,
	onChange,
	onBlur,
	autoFocus,
	popularSearches,
	labelText,
}: FlowtySearchProps) => {
	const searchResultsRef = useRef<HTMLDivElement | null>(null)
	const [searchResultsWidth, setSearchResultsWidth] = useState(0)

	const [isBrowsing, setIsBrowsing] = useState(false)
	const [isSearchFocused, setIsSearchFocused] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handleOnChange = (newValue: string) => {
		setIsLoading(true)
		onChange(newValue)
	}

	const clearSearch = () => {
		onChange("")
	}

	const searchResults = useMemo(() => {
		return hits.map(hit => {
			return hit.data
		})
	}, [hits, value])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsLoading(false)
		}, debounceTime)

		return () => clearTimeout(timeoutId)
	}, [value, debounceTime])

	const recentSearches = useMemo(() => getRecentSearches(), [])

	const searchUIBasedOnResults = useMemo(() => {
		if (isLoading) {
			return (
				<div className='flex rounded-[6px] searchResultsBackground flex-col gap-[14px] items-end'>
					<div className='flex flex-col items-start justify-center w-full'>
						<div className='flex p-[12px] w-full'>
							<span className='text-[#ADB5BD] text-[14px]'>Searching...</span>
						</div>
						<div className='overflow-auto max-h-[300px] w-full'>
							<div className='p-[12px] flex items-center gap-[14px] hover:bg-[#6C757D40] hover:bg-opacity-20 w-full'>
								<div className='w-[34px] h-[34px] flex-shrink-0 rounded-[6px] bg-[#606e7d33] animate-pulse backdrop-blur-3xl'></div>
								<div className='flex items-center gap-[8px] overflow-hidden w-full'>
									<div className='flex items-center gap-[8px] overflow-hidden w-full'>
										<span className='text-[14px] text-[#F8F9FA] font-[600] truncate w-full bg-[#606e7d33] h-6 animate-pulse backdrop-blur-3xl'></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		if (!value && isSearchFocused && recentSearches.length > 0) {
			return (
				<RecentSearches
					onMouseEnter={() => setIsBrowsing(true)}
					onMouseLeave={() => setIsBrowsing(false)}
				/>
			)
		}

		if (
			value &&
			value.length >= 3 &&
			searchResults &&
			searchResults.length <= 0
		) {
			return (
				<PopularSearches
					onMouseEnter={() => setIsBrowsing(true)}
					onMouseLeave={() => setIsBrowsing(false)}
					searchResults={popularSearches}
				/>
			)
		} else if (value && searchResults && searchResults?.length > 0) {
			return (
				<Results
					onMouseEnter={() => setIsBrowsing(true)}
					onMouseLeave={() => setIsBrowsing(false)}
					searchResults={searchResults}
				/>
			)
		}

		if (isSearchFocused && recentSearches.length >= 0) {
			return (
				<PopularSearches
					onMouseEnter={() => setIsBrowsing(true)}
					onMouseLeave={() => setIsBrowsing(false)}
					searchResults={popularSearches}
				/>
			)
		}
	}, [searchResults, value, isSearchFocused, popularSearches, isLoading])

	useEffect(() => {
		if (searchResultsRef.current) {
			setSearchResultsWidth(searchResultsRef.current.clientWidth)
		}
	}, [searchResultsRef.current])

	return (
		<div
			className='w-full lg:w-[340px] flex flex-col gap-[12px] relative'
			ref={searchResultsRef}
		>
			<FlowtySearchBarInput
				autoFocus={autoFocus}
				onFocus={() => {
					setIsSearchFocused(true)
				}}
				onBlur={() => {
					if (isBrowsing) {
						return
					}
					setIsSearchFocused(false)
					onBlur && onBlur()
				}}
				disabled={disabled}
				value={value}
				onChange={handleOnChange}
				onClear={clearSearch}
				placeholder={placeholder}
				debounceTime={debounceTime}
				labelText={labelText}
			/>
			<div
				className={`absolute top-[54px] w-[${searchResultsWidth}px] left-0 z-10`}
			>
				{searchUIBasedOnResults}
			</div>
		</div>
	)
}
