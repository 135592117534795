import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getFillRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  fillRentalTxnCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  fillRentalTxn(config, token, nftData)

const fillRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const fillRentalTxnCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import FlowtyRentals from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
    listingResourceID: UInt64,
    flowtyStorefrontAddress: Address,
    autoReturn: Bool,
    nftReceiverAddress: Address,
    ftProviderAddress: Address,
    ftProviderControllerID: UInt64,
    nftProviderControllerID: UInt64,
) {
    let paymentVault: @{FungibleToken.Vault}
    let storefront: &{FlowtyRentals.FlowtyRentalsStorefrontPublic}
    let listing: &{FlowtyRentals.ListingPublic}

    let tokenReceiver: Capability<&{FungibleToken.Receiver}>
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let provider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>?
    let ftProvider: auth(FungibleToken.Withdraw) &{FungibleToken.Provider}

    prepare(acct: auth(Storage, Capabilities) &Account) {
        if(acct.storage.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
            // Create a new empty .Storefront
            let storefront <- FlowtyRentals.createStorefront()

            // save it to the account
            acct.storage.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)

            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontStoragePath),
                at: FlowtyRentals.FlowtyRentalsStorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        self.storefront = getAccount(flowtyStorefrontAddress).capabilities.get<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontPublicPath)!.borrow()
            ?? panic("Could not borrow FlowtyRentalsStorefront from provided address")

        assert(self.storefront.getType() == Type<@FlowtyRentals.FlowtyRentalsStorefront>(), message: "unexpected storefront type")

        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Listing with that ID in FlowtyRentalsStorefront. Already rented or delisted.")
        let listingDetails = self.listing.getDetails()
        let price = listingDetails.amount

        let nft = self.listing.borrowNFT() ?? panic("nft not found")
        let nftType = nft.getType()

        let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
        var catalogIdentifier = ""
        for k in catalogIdentifiers.keys {
            if catalogIdentifiers[k] == true {
                catalogIdentifier = k
                break
            }
        }
        assert(catalogIdentifier != "", message: "no valid catalog identifier found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")
        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        let ftAddress = AddressUtils.parseAddress(listingDetails.paymentVaultType)!
        let contractName = listingDetails.paymentVaultType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")
        let ftVaultData = ftContract.resolveContractView(resourceType: listingDetails.paymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        if ftProviderAddress == acct.address {
            self.ftProvider = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
                ?? panic("failed to borrow payment token vault from storage")
            self.tokenReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        } else {
            // signer is the parent account and ftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

            let cap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<&{FungibleToken.Provider}>()) ?? panic("no cap found")

            let providerCap = cap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
            self.ftProvider = providerCap.borrow() ?? panic("failed to borrow fungible token provider from child acount")
            self.tokenReceiver = getAccount(ftProviderAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
        }

        assert(self.tokenReceiver.check(), message: "Missing or mis-typed token receiver")

        let paymentAmount = self.listing.getDetails().getTotalPayment()
        self.paymentVault <- self.ftProvider.withdraw(amount: paymentAmount)

        if nftReceiverAddress == acct.address {
            if acct.storage.borrow<&{NonFungibleToken.Collection}>(from: storageCollectionPath) == nil {
                let collection <- nft.createEmptyCollection()
                acct.storage.save(<-collection, to: storageCollectionPath)
            }

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!

            if autoReturn {
                let borrowType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
                if let provider = cache.getCapabilityByType(resourceType: catalogEntry.nftType, capabilityType: CapabilityType(borrowType)!) {
                    self.provider = provider as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
                } else {
                    self.provider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
                    cache.addCapability(resourceType: catalogEntry.nftType, cap: self.provider!)
                }
            } else {
                self.provider = nil
            }
        } else {
            self.nftReceiver = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!

            if autoReturn {
                // Get child nft provider for auto return, if path doesn't exist we can't create it
                let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
                let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nftReceiver account not found")
                let providerCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")

                self.provider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            } else {
                self.provider = nil
            }
        }
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Collection")
        assert(self.provider == nil || self.provider!.check(), message: "Missing or mis-typed NFT provider")
  }

    execute {
        self.listing.rent(
            payment: <-self.paymentVault,
            renterFungibleTokenReceiver: self.tokenReceiver,
            renterNFTCollection: self.nftReceiver,
            renterNFTProvider: self.provider
        )
    }
}`
