import { useCallback, useEffect, useState } from "react"
import { FacetTrait } from "../components/OpenSearch/types"
import { fetchCollectionGlobalFacets } from "./data/collections/flowNFTContract"
import { actions as Mixpanel } from "../util/Mixpanel"

interface UseFetchTraitDataProps {
	contractID: string
}

export const useFetchTraitData = ({
	contractID,
}: UseFetchTraitDataProps): {
	collectionTraits: FacetTrait[] | undefined
	traitDataLoading: boolean
} => {
	const [isLoading, setIsLoading] = useState(true)

	const [collectionTraits, setCollectionTraits] = useState<
		FacetTrait[] | undefined
	>([])

	const fetchData = useCallback(async (contractIdentifier: string) => {
		try {
			const data = await fetchCollectionGlobalFacets(contractIdentifier)
			setCollectionTraits(data?.traits)
			setIsLoading(false)
		} catch (error) {
			Mixpanel.track("Error fetching data:", error)
		}
	}, [])

	useEffect(() => {
		fetchData(contractID)
	}, [contractID])

	return { collectionTraits, traitDataLoading: isLoading }
}
