import { History as UserProfileHistory } from "./History"
import { CopyWalletAddressButton } from "./CopyWalletAddressButton"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../stores/AuthStore"
import { FlowtyTabs, TabConfig } from "ds-flowty"

export enum UserProfileTabButtons {
	Listed = 0,
	Funded = 1,
	History = 2,
}

interface UserProfileDataProps extends AuthStoreProp {
	activeTabBtn: UserProfileTabButtons
}

const UserProfileData: React.FC<UserProfileDataProps> = ({
	activeTabBtn = UserProfileTabButtons.Listed,
	authStore,
}) => {
	const userActivityTab: TabConfig[] = [
		{ content: <UserProfileHistory />, id: "tag-1", label: "User Activity" },
	]

	return (
		<div className='pt-4 flex flex-col justify-center'>
			<div className='self-center'>
				<div className='flex justify-center items-center'>
					<CopyWalletAddressButton address={`${authStore?.loggedUser?.addr}`} />
				</div>
			</div>

			<div className='mt-3'>
				<FlowtyTabs
					activeTab={"tag-1"}
					tabs={userActivityTab}
					onChange={() => {}}
					type='primary'
					isCenter
				/>
			</div>
		</div>
	)
}

export default inject("authStore")(observer(UserProfileData))
