import { inject, observer } from "mobx-react"
import React, { useContext } from "react"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { OpenSearchContext } from "../../../../../components/OpenSearch/OpenSearchConnector"
import { AuthStoreProp } from "../../../../../stores/AuthStore"
import { LoadingOffers } from "../LoadingOffers/LoadingOffers"
import { OfferNotFound } from "../OfferNotFound"
import { ProfileOffersTable } from "../ProfileOffersTable"
import { useReceivedOffersTable } from "./hooks/useReceivedOffersTable"

interface ReceivedOffersTableProps extends AuthStoreProp {
	address: string
	isPublic: boolean
}

const ReceivedOffersTableComponent: React.FC<
	ReceivedOffersTableProps & { addresses: string[] }
> = ({ address, isPublic, authStore, addresses }) => {
	const {
		dataTotal,
		isFetching,
		isLoadingResults,
		receivedOffersFields,
		scroll,
		sort,
		nftsWithOffers,
		setSort,
		hasMore,
	} = useReceivedOffersTable({ address, addresses, authStore, isPublic })

	return (
		<div className='flex flex-col justify-center items-center'>
			{isFetching ? (
				<LoadingOffers />
			) : (
				<>
					{Boolean(dataTotal) ? (
						<>
							<ProfileOffersTable
								fields={receivedOffersFields}
								initialSortConfig={sort}
								items={nftsWithOffers}
								onSort={setSort}
								isFetchingResults={isLoadingResults}
							/>
							{hasMore && (
								<>
									<ScrollToLoadMsg />
									<div ref={scroll} />
								</>
							)}
						</>
					) : (
						<OfferNotFound isReceived={true} />
					)}
				</>
			)}
		</div>
	)
}

const MadeOffersTableWithAddresses: React.FC<
	ReceivedOffersTableProps & AuthStoreProp
> = props => {
	const { walletAddresses } = useContext(OpenSearchContext)

	return <ReceivedOffersTableComponent {...props} addresses={walletAddresses} />
}

export const ReceivedOffersTable = inject("authStore")(
	observer(MadeOffersTableWithAddresses)
)
