import Countdown, { zeroPad } from "react-countdown"
import { getListingActionTime, isNftActive } from "../../util/nftDataUtil"

interface NftActiveCountdownProps {
	blockTimestamp: Date
	onCountdownEnd?: () => void
	text?: string
	className?: string
}

export const NftActiveCountdown: React.FunctionComponent<
	NftActiveCountdownProps
> = ({ blockTimestamp, onCountdownEnd, text, className }) => {
	const countDownRenderer = text
		? undefined
		: ({
				minutes,
				seconds,
		  }: {
				minutes: string | number
				seconds: string | number
		  }) => (
				<span className='text-white text-bold'>
					{zeroPad(minutes)}:{zeroPad(seconds)}
				</span>
		  )

	if (isNftActive(blockTimestamp)) {
		return null
	}

	return (
		<div className='absolute top-0 left-0 flex justify-center w-full'>
			<div
				className={`flex justify-center text-center items-center py-1 bg-blue-500 bg-opacity-90 rounded-t-xl ${className}`}
			>
				<p className='text-white text-bold me-2'>{text || "Active in"}</p>
				<Countdown
					className='text-white text-bold'
					renderer={countDownRenderer}
					date={getListingActionTime(blockTimestamp)}
					onComplete={onCountdownEnd}
				/>
			</div>
		</div>
	)
}
