import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import React from "react"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	storefrontAddress: string
	salePrice: number
	tokenIdentifier: SupportedTokens
}

export const StorefrontListedRow: React.FC<Props> = ({
	storefrontAddress,
	salePrice,
	tokenIdentifier,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Listed</span> by{" "}
				<ProfileLink address={storefrontAddress} /> for{" "}
			</p>
			<NftPrice price={salePrice} token={tokenIdentifier} />
		</div>
	)
}
