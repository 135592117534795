import axios from "axios"
import { Valuation } from "flowty-common"
import { useState } from "react"
import { Err } from "../../../util/Log"
import { apiURL } from "../../../util/settings"
import { NftDetails } from "../types/FlowtyModalsContextTypes"

interface GetValuationValues {
	cleanValuation: () => void
	getValuation: (selectedNftDetails: NftDetails) => Promise<void>
	isLoadingValuation: boolean
	valuation: Valuation | null
}

export const useModalGetValuation = (): GetValuationValues => {
	const [valuation, setValuation] = useState<Valuation | null>(null)
	const [isLoadingValuation, setIsLoadingValuation] = useState<boolean>(false)

	const getValuation = async (
		selectedNftDetails: NftDetails
	): Promise<void> => {
		setIsLoadingValuation(true)

		try {
			const res = await axios.post(
				`${apiURL}/nft/valuation`,
				selectedNftDetails
			)

			setValuation(res.data)
		} catch (err) {
			Err("Failed to fetch asset orders.", err)
		} finally {
			setIsLoadingValuation(false)
		}
	}

	const cleanValuation = (): void => {
		setValuation(null)
	}

	return {
		cleanValuation,
		getValuation,
		isLoadingValuation,
		valuation,
	}
}
