/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react"
import { inject, observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import SortableTable from "../../../SortableTable"
import { userActivityTableFields } from "./UserActivityTableFields"
import {
	subscribeToAllActivity,
	subscribeToMyDelistLoansActivity,
} from "../../../../services/firestore/ActivityService"
import { GenericActivityFilter } from "../../../Activity/Filters/GenericActivityFilter"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { defaultNftPageSize } from "../../../../util/settings"
import { composeLoanListingPath } from "../../../../routes"
import { ScrollToLoadMsg } from "../../../Loaders/ScrollToLoadMsg"

const UserActivityTableComponent: React.FC<AuthStoreProp> = ({ authStore }) => {
	const [prevLength, setPrevLength] = useState(0)
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [showFilter, setShowFilter] = useState(true)
	const [filterBy, setFilterBy] = useState<any>({
		max: "",
		min: "",
		path: "",
	})
	const [allBorrowerActivityDocs, setAllBorrowerActivityDocs] = useState<
		Array<any>
	>([])
	const [allLenderActivityDocs, setAllLenderActivityDocs] = useState<
		Array<any>
	>([])

	const [allDelistActivityDocs, setAllDelistActivityDocs] = useState<
		Array<any>
	>([])

	const navigate = useNavigate()

	const listingLimit = useMemo(() => page * defaultNftPageSize, [page])
	const allUserActivity = useMemo(
		() =>
			allLenderActivityDocs
				.map((doc: any) => ({
					...doc,
					activityType: "Lender",
				}))
				.concat(
					allBorrowerActivityDocs.map((doc: any) => ({
						...doc,
						activityType: "Borrower",
					}))
				)
				.concat(
					allDelistActivityDocs.map((doc: any) => ({
						...doc,
						activityType: "Borrower",
					}))
				),
		[allLenderActivityDocs, allBorrowerActivityDocs, allDelistActivityDocs]
	)
	const loanFilterOptions = [
		{ label: "Settled", value: "SETTLED" },
		{ label: "Funded", value: "FUNDED" },
		{ label: "Repaid", value: "REPAID" },
		{ label: "Listed", value: "LISTED" },
		{ label: "Delisted", value: "DELISTED" },
	]
	const userActivityLength = useMemo(
		() => allUserActivity.length,
		[allUserActivity]
	)
	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: loading,
			onInfiniteScroll() {
				if (!loading) {
					setPage(page + 1)
					setPrevLength(userActivityLength)
				}
				Mixpanel.track("Inifinite Scroll Loans Activity Table")
			},
		},
		[userActivityLength, loading]
	)
	useEffect((): any => {
		if (prevLength === userActivityLength && userActivityLength) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [prevLength, userActivityLength])

	useEffect(() => {
		if (authStore?.loggedUser?.addr) {
			subscribeToAllActivity({
				accountIDs: authStore?.getAccountSummaryAddresses() || [],
				accountId: authStore?.loggedUser?.addr,
				filterActivityBy: filterBy,
				limit: listingLimit,
				makerCb(docs) {
					setAllBorrowerActivityDocs(docs)
				},
				takerCb(docs) {
					setAllLenderActivityDocs(docs)
				},
			})
			subscribeToMyDelistLoansActivity({
				accountIDs: authStore?.getAccountSummaryAddresses() || [],
				accountId: authStore?.loggedUser?.addr,
				filterActivityBy: filterBy,
				limit: listingLimit,
				makerCb(docs) {
					setAllDelistActivityDocs(docs)
				},
			})
			return
		}
		return () => {}
	}, [listingLimit, authStore?.loggedUser, filterBy])

	useEffect(() => {
		setPage(1)
	}, [filterBy])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Profile Activity Loans Page Filtered`, {
				filter: filterBy,
				user: authStore?.loggedUser,
			})
		}
	}, [filterBy.path])

	return (
		<div className=' w-full flex flex-col '>
			<GenericActivityFilter
				filterOptions={loanFilterOptions}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={setFilterBy}
			/>
			<button
				className='flex justify-center w-20 relative self-end -top-24 float-right bg-blue-500 text-white active:bg-blue-600 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-4 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
				type='button'
				onClick={() => {
					setShowFilter(!showFilter)
				}}
			>
				<span>Filter</span>
			</button>
			<div className='relative -top-16 '>
				<SortableTable
					onClickRow={({ item }) =>
						item?.data?.listingResourceID &&
						navigate(composeLoanListingPath(item.data.listingResourceID))
					}
					fields={userActivityTableFields}
					items={allUserActivity}
					stickyHeaders
					composeRowHref={item =>
						composeLoanListingPath(item.data.listingResourceID)
					}
				/>
			</div>
			{loading ? (
				<div />
			) : (
				<>
					<ScrollToLoadMsg />
					<div ref={bottomOfPageRef} />
				</>
			)}
		</div>
	)
}

export const UserActivityTable = inject("authStore")(
	observer(UserActivityTableComponent)
)
