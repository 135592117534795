import { FlowNFT, OpensearchFlowNFT, getImageURL } from "flowty-common"
import { Helmet } from "react-helmet-async"

export const AssetSeoHeader: React.FC<{
	asset: FlowNFT | OpensearchFlowNFT
}> = ({ asset }) => {
	const getSerial = (nft: FlowNFT | OpensearchFlowNFT): string | string[] => {
		if (nft?.card?.additionalDetails) {
			const additionalDetails = [
				`${
					nft?.card?.additionalDetails
						? nft?.card?.additionalDetails.map(trait => {
								return " " + trait
						  })
						: ""
				}`,
			]
			return additionalDetails
		}
		const serial = `#${nft.id}`
		const serialNumber = `${
			nft.id && !nft.card?.title?.includes("#") ? serial : ""
		}`
		return serialNumber
	}

	const metaTitle = `View Asset: ${asset.card?.title ?? ""} ${getSerial(asset)}`
	const metaDescription = `View ${
		asset?.card?.title ?? ""
	} and much more on flowty.io`

	// META IMAGE
	const metaImage = getImageURL(
		asset?.card?.images && asset?.card?.images.length > 0
			? asset?.card?.images[0].url
			: ""
	)

	return (
		<Helmet>
			<meta
				name='description'
				content="Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
			/>
			<link rel='manifest' href='/manifest.json' />
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:site' content='@flowty' />
			<meta name='twitter:title' content={metaTitle} />
			<meta name='twitter:description' content={metaDescription} />
			<meta name='twitter:image' content={metaImage} />
			<meta name='description' content={metaDescription} />
			<meta property='og:title' content={metaTitle} />
			<meta property='og:description' content={metaDescription} />
			<meta property='og:image' content={metaImage} />
			<title>{metaTitle}</title>
		</Helmet>
	)
}
