import { useState } from "react"

interface CreatorHubDisplayImageProps {
	imageType: string
	imageSrc: string
	className?: string
}
export const DisplayImage: React.FC<CreatorHubDisplayImageProps> = ({
	imageSrc,
	imageType,
	className,
}) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = (): void => {
		setImageLoaded(true)
	}

	return (
		<div className='flex flex-col gap-[8px] 3xl:gap-3'>
			<div className='text-white font-montserrat text-[12px] 3xl:text-[18px] font-bold leading-normal'>
				{imageType}
			</div>
			<img
				src={imageSrc}
				alt={`${imageType}`}
				className={`${className} max-h-[8.875rem] rounded-lg ${
					!imageLoaded ? "hidden" : ""
				}`}
				onLoad={handleImageLoad}
			/>

			<div
				className={`${className} max-h-[8.875rem] rounded-lg animate-pulse bg-[#edf3f60a] ${
					imageLoaded ? "hidden" : ""
				}`}
			/>
		</div>
	)
}
