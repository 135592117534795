export interface StorefrontListingRequest {
	nftProviderAddress: string
	nftProviderPathIdentifier: string
	nftTypeIdentifier: string
	nftID: string
	price: number
	customID: string | null
	expiry: number
	buyerAddress: string | null
	catalogCollection: boolean
	nftStoragePathIdentifier: string
}

export const getStorefrontListingRequestStruct = (
	t: any,
	storefrontListingRequestIdentifier: string,
	crescendo: boolean
): any => {
	const providerArg = crescendo
		? { name: "nftProviderControllerID", value: t.UInt64 }
		: { name: "nftProviderPath", value: t.Path }

	return t.Struct(storefrontListingRequestIdentifier, [
		{ name: "nftProviderAddress", value: t.Address },
		providerArg,
		{ name: "nftStoragePath", value: t.Path },
		{ name: "nftTypeIdentifier", value: t.String },
		{ name: "nftID", value: t.UInt64 },
		{ name: "price", value: t.UFix64 },
		{ name: "customID", value: t.Optional(t.String) },
		{ name: "expiry", value: t.UInt64 },
		{ name: "buyerAddress", value: t.Optional(t.Address) },
		{ name: "catalogCollection", value: t.Bool },
	])
}

export const encodeStorefrontListingRequestFields = (
	r: StorefrontListingRequest,
	crescendo: boolean
): any => {
	const nftProviderField = crescendo
		? {
				name: "nftProviderControllerID",
				value: r.nftProviderPathIdentifier || "0",
		  }
		: {
				name: "nftProviderPath",
				value: {
					domain: "private",
					identifier: r.nftProviderPathIdentifier,
				},
		  }

	return {
		fields: [
			{ name: "nftProviderAddress", value: r.nftProviderAddress },
			nftProviderField,
			{
				name: "nftStoragePath",
				value: {
					domain: "storage",
					identifier: r.nftStoragePathIdentifier,
				},
			},
			{ name: "nftTypeIdentifier", value: r.nftTypeIdentifier },
			{ name: "nftID", value: r.nftID.toString() },
			{ name: "price", value: r.price.toFixed(5) },
			{ name: "customID", value: r.customID },
			{ name: "expiry", value: r.expiry.toString() },
			{ name: "buyerAddress", value: r.buyerAddress },
			{ name: "catalogCollection", value: r.catalogCollection },
		],
	}
}

export const encodeStorefrontListingRequests = (
	requests: StorefrontListingRequest[],
	storefrontListingRequestIdentifier: string,
	crescendo: boolean,
	arg: any,
	t: any
): any => {
	const arr: StorefrontListingRequest[] = []
	for (const r of requests) {
		const encoded = encodeStorefrontListingRequestFields(r, crescendo)
		arr.push(encoded)
	}

	return arg(
		arr,
		t.Array(
			getStorefrontListingRequestStruct(
				t,
				storefrontListingRequestIdentifier,
				crescendo
			)
		)
	)
}
