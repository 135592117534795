import { cn } from "ds-flowty/src/utils/cn"
import { useMemo } from "react"

interface ListingTypeProps {
	name: string
	value: string
	disabled: boolean
	onChange: (value: "storefront" | "rental" | "loan" | "all") => void
	isChecked: boolean
	icon?: React.ReactNode
	iconPosition: "right" | "left"
	collapsible: boolean
	label: string
	children?: React.ReactNode | React.ReactNode[]
	isOpen?: boolean
	setIsOpen?: (isOpen: boolean) => void
}

export const ListingType: React.FC<ListingTypeProps> = ({
	name,
	value,
	disabled,
	onChange,
	isChecked,
	icon,
	iconPosition,
	collapsible,
	label,
	children,
	isOpen,
	setIsOpen,
}) => {
	const baseStyles = `hidden h-5 w-5 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0 cursor-pointer`

	const collapsibleChevron = useMemo(() => {
		return (
			<svg
				data-testid='single-listing-type-chevron'
				className={cn("w-[0.875rem] h-[0.5rem]", {
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
				})}
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isOpen])

	return (
		<button className='py-[0.75rem] w-full font-montserrat border-[1px] border-neutral-400 border-opacity-25 text-[0.875rem] font-[600] px-[1rem] rounded-[0.375rem] text-white bg-[#ffffff0a] hover:bg-[#ffffff1f] flex flex-col items-center justify-between gap-[0.375rem] shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]'>
			<div
				className='flex w-full items-center'
				onClick={e => {
					e.stopPropagation()
					setIsOpen && setIsOpen(!isOpen)
				}}
			>
				<div
					onClick={() =>
						document.getElementById(`${value}-listing-type-radio`)?.click()
					}
					onKeyDown={() =>
						document.getElementById(`${value}-listing-type-radio`)?.click()
					}
					className='flex items-center w-[70%] justify-between cursor-pointer'
				>
					<div className='flex items-center w-full'>
						<input
							id={`${value}-listing-type-radio`}
							data-testid={`${value}-listing-type-radio`}
							type='radio'
							name={name}
							value={value}
							disabled={disabled}
							checked={isChecked}
							onChange={e => {
								onChange(
									e.target.value as "rental" | "loan" | "storefront" | "all"
								)
							}}
							className={baseStyles}
						/>
						<div className='flex items-center gap-[0.5rem]'>
							<div
								className={`w-5 h-5 rounded-full border-2 border-white relative ${
									isChecked
										? "bg-primary border-none flex items-center justify-center"
										: "bg-transparent!"
								}`}
							>
								<div
									className={`rounded-full p-[0.27rem] ${
										isChecked ? "bg-[#031021]" : "bg-transparent"
									}`}
								></div>
							</div>
							{iconPosition === "left" && icon}
							<span className='text-[0.875rem] font-montserrat'>{label}</span>
							{iconPosition === "right" && icon}
						</div>
					</div>
				</div>
				<div className='ml-auto'>{collapsible && collapsibleChevron}</div>
			</div>
			{isOpen && children}
		</button>
	)
}
