import {
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	Order,
} from "flowty-common"
import { FlowtyModalCarousel } from "../../../../FlowtyModalCarousel"
import { ShareActiveListing } from "../../../../TwitterShare/ShareCurrentListing"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { SingleFundListingView } from "../../PurchaseModalView/FundLoanView/SingleFundListingView/SingleFundListingView"

interface LoanDelistingProps {
	loanOrder?: OpenSearchListingAvailableData[]
	selectedOrder: OpenSearchListingAvailableData | null
}

export const LoanDelisting: React.FunctionComponent<LoanDelistingProps> = ({
	loanOrder,
	selectedOrder,
}) => {
	const {
		updateSelectedOrder,
		isDelist,
		openSearchFlowNFT,
		orderFromChildAccount,
		isMainnet,
		collectionDisplayName,
	} = useFlowtyListingModalContext()

	const orderAddress = loanOrder
		? loanOrder?.[0]?.flowtyStorefrontAddress
		: selectedOrder?.flowtyStorefrontAddress
	return (
		<div className='flex flex-col gap-3 h-full w-full md:w-[400px]'>
			{loanOrder && loanOrder.length > 1 ? (
				<>
					{isDelist.loan && (
						<p className='text-sm font-normal text-white'>
							Loan listing will be removed from the marketplace.
						</p>
					)}
					<FlowtyModalCarousel
						updateSelectedOrder={updateSelectedOrder}
						orders={loanOrder}
						selectedOrder={selectedOrder as OpenSearchListingAvailableData}
					/>
				</>
			) : (
				<>
					{isDelist.loan && (
						<p className='text-sm font-normal text-white'>
							Loan listing will be removed from the marketplace.
						</p>
					)}
					<SingleFundListingView
						listing={selectedOrder as OpenSearchListingAvailableData}
					/>
				</>
			)}
			{isDelist.loan && orderFromChildAccount && (
				<div className='text-orange-500 text-sm font-normal py-2'>
					NFT was listed while connected with
					{` ${orderAddress}`} as Main. Please sign in with {` ${orderAddress}`}{" "}
					to delist.
				</div>
			)}
			{!isDelist.loan && (
				<ShareActiveListing
					nft={openSearchFlowNFT as OpensearchFlowNFT}
					order={selectedOrder as Order}
					listingType='storefront'
					isMainnet={isMainnet}
					collectionDisplayName={collectionDisplayName}
				/>
			)}
		</div>
	)
}
