import React from "react"
import {
	GenericActivityFilter,
	ActivityFilterProps,
} from "./GenericActivityFilter"
import { IEventType } from "flowty-common"
import { showOfferEvents } from "../../../util/settings"

const storefrontFilterOptions = [
	{ label: "Purchased", value: IEventType.StorefrontPurchased.valueOf() },
	{ label: "Listed", value: IEventType.StorefrontListed.valueOf() },
	{ label: "Delisted", value: IEventType.StorefrontDelisted.valueOf() },
]

if (showOfferEvents) {
	storefrontFilterOptions.push(
		...[
			{
				label: "Offer Created",
				value: IEventType.StorefrontOfferCreated.valueOf(),
			},
			{
				label: "Offer Accepted",
				value: IEventType.StorefrontOfferAccepted.valueOf(),
			},
			{
				label: "Offer Cancelled",
				value: IEventType.StorefrontOfferCancelled.valueOf(),
			},
		]
	)
}

export const StorefrontActivityFilter: React.FC<
	ActivityFilterProps
> = props => (
	<GenericActivityFilter {...props} filterOptions={storefrontFilterOptions} />
)
