import Sidebar from "../../../../Sidebar"

interface DesktopControllersProps {
	isFilterHidden: boolean
}

export const DesktopControllers: React.FunctionComponent<
	DesktopControllersProps
> = ({ isFilterHidden }) => {
	return (
		<div
			className={`transition-width duration-200 ease-out ${
				isFilterHidden ? "w-0" : "w-full"
			}`}
		>
			<div
				style={{
					boxSizing: "border-box",
					height: "calc(100vh - 204px)",
				}}
				className={`flex-col transition-opacity duration-[300ms] ease-out lg:flex overflow-y-scroll scroll-pr-0 ${
					isFilterHidden ? "opacity-0" : "opacity-100"
				}`}
			>
				<Sidebar />
			</div>
		</div>
	)
}
