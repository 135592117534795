import { useEffect } from "react"

const useScrollbarWidth = () => {
	useEffect(() => {
		const calculateAndApplyScrollbarWidth = () => {
			// Create a temporary div element
			const div = document.createElement("div")
			div.style.overflowY = "scroll"
			div.style.width = "100vw"
			div.style.visibility = "hidden" // Make sure the div is not visible
			document.body.appendChild(div)

			// Calculate the scrollbar width
			const scrollbarWidth = div.offsetWidth - div.clientWidth

			// Remove the temporary div from the body
			document.body.removeChild(div)

			// Set a CSS variable on the root element with the scrollbar width
			document.documentElement.style.setProperty(
				"--scrollbar-width",
				`${scrollbarWidth}px`
			)
		}

		// Call the function on mount
		calculateAndApplyScrollbarWidth()

		// Optional: If you expect the scrollbar width to change (e.g., due to dynamic content), you can recalculate on resize
		window.addEventListener("resize", calculateAndApplyScrollbarWidth)

		// Cleanup
		return () =>
			window.removeEventListener("resize", calculateAndApplyScrollbarWidth)
	}, []) // Empty dependency array means this effect runs once on mount
}

export default useScrollbarWidth
