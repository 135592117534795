import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import { getDurationPeriod } from "../../../../../../util/nftDataUtil"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	borrower: string
	amount: number
	paymentTokenType: SupportedTokens
	interestRate: number
	term: number
}

export const LoanListedRow: React.FunctionComponent<Props> = ({
	borrower,
	amount,
	paymentTokenType,
	interestRate,
	term,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>Listing for</p>
			<NftPrice price={amount + interestRate} token={paymentTokenType} />
			<p className='text-start'>
				with a duration of{" "}
				<span className='font-bold text-[14px]'>{getDurationPeriod(term)}</span>{" "}
				by <ProfileLink address={borrower} />
			</p>
		</div>
	)
}
