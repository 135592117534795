import { ReactComponent as limitedEdition } from "../../../../../assets/limitedEdition.svg"
import { ReactComponent as openEdition } from "../../../../../assets/openEdition.svg"
import { CollectionEditionType } from "../../../../../types/FlowtyCreatorHubTypes"

const collectionTypeNames: Record<CollectionEditionType, string> = {
	[CollectionEditionType.OpenEdition]: "Open Edition",
	[CollectionEditionType.LimitedEdition]: "Limited Edition",
}

export const options = [
	{
		description: "No cap on NFTs that can be minted while minting is live",
		disabled: false,
		image: openEdition,
		labelMapping: collectionTypeNames,
		name: "collectionEditionType",
		pillContent: null,
		value: CollectionEditionType.OpenEdition,
	},
	{
		description: "Create a fixed number of NFTs",
		disabled: true,
		image: limitedEdition,
		labelMapping: collectionTypeNames,
		name: "collectionEditionType",
		pillContent: "Coming Soon",
		value: CollectionEditionType.LimitedEdition,
	},
]
