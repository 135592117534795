import { Dialog, Transition } from "@headlessui/react"
import { FlowtyButton } from "ds-flowty"
import { inject, observer } from "mobx-react"
import { Fragment, useState } from "react"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import AuthStore from "../../../stores/AuthStore"
import { updateAccountData } from "../../../services/firestore/AccountService"
import { Oval } from "react-loader-spinner"

interface Props {
	authStore: AuthStore | undefined
}

const TermsHaveBeenUpdatedModal: React.FunctionComponent<Props> = ({
	authStore,
}) => {
	const [isOpen, setIsOpen] = useState(true)

	const [loading, setLoading] = useState(false)

	const onClose = () => {
		setIsOpen(false)
	}

	const onFormSubmitted = async () => {
		setLoading(true)
		try {
			if (authStore?.loggedUser?.addr) {
				await updateAccountData(authStore.loggedUser.addr, {
					hasAcceptedTermsV2: true,
				})
				authStore?.setNewTermsAcknowledged(true)
				Mixpanel.track("AGREED_TO_LATEST_TERMS")
			} else {
				Mixpanel.track("AGREED_TO_LATEST_TERMS_ERROR", {
					error: "No User",
				})
			}
		} catch (error) {
			Mixpanel.track("AGREED_TO_LATEST_TERMS_ERROR", {
				error,
			})
			onClose()
		} finally {
			setLoading(false)
			onClose()
		}
	}

	return (
		<div>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-40' static onClose={() => null}>
					<Transition.Child
						enter='transition duration-000 ease-out'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-75 ease-out'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'
						as={Fragment}
					>
						<div className='fixed inset-0 bg-black bg-opacity-75' />
					</Transition.Child>
					<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-1000'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='relative z-40 shadow-xl bg-darkBg rounded-[10px] border border-[#343A40]'>
								<div className='flex flex-col select-none w-[24rem] md:w-[436px]'>
									<div className='p-[40px]'>
										<span className='text-white text-shadow text-[24px] font-montserrat font-black leading-normal font-montserrat'>
											Updated Terms Of Service
										</span>
									</div>
									<div className='mx-auto p-[40px] border-y border-[#343A40]'>
										{loading ? (
											<div className='mx-auto text-center'>
												<Oval height={200} width={200} />
											</div>
										) : (
											<>
												<p className='text-neutral-900 text-sm font-montserrat font-medium leading-normal'>
													We updated our{" "}
													<a
														className='text-primary underline'
														href='https://www.flowty.io/tos'
														target='_blank'
														rel='noopener noreferrer'
													>
														Terms of Service
													</a>{" "}
													and{" "}
													<a
														className='text-primary underline'
														href='https://www.flowty.io/privacy'
														target='_blank'
														rel='noopener noreferrer'
													>
														Privacy Policy
													</a>
													. To continue using Flowty, please review and agree to
													these changes.
												</p>
												<p className='text-neutral-900 text-sm font-montserrat font-medium leading-normal mt-[10px]'>
													Simply click &quot;ACCEPT AND CONTINUE&quot; to
													confirm that you have read and agree to the updated
													policies.
												</p>
											</>
										)}
									</div>
									<div className='rounded-b-[.625rem] py-[20px] flex flex-col items-center justify-center'>
										<div className='w-96 mb-2'>
											<FlowtyButton
												type='button'
												onClick={onFormSubmitted}
												text={"ACCEPT AND CONTINUE"}
												variant={"secondary"}
												btnSize={"large"}
												disabled={loading}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}

export default inject("authStore")(observer(TermsHaveBeenUpdatedModal))
