import {
	collection,
	limit as firebaseLimit,
	onSnapshot,
	query,
	where,
	orderBy,
	or,
	and,
	getDocs,
} from "firebase/firestore"
import { db } from "../../../../../../firebase"
import { Unsubscribe } from "firebase/auth"
import { FilterBy } from "../types"

interface SubscribeProps {
	limit: number
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cb: (data: any) => void
	loggedUserAddress: string | number
	filterBy?: FilterBy
	min?: number | null
	max?: number | null
	role: "buyer" | "seller" | "buyerAndSeller"
}

export const getAllUserStorefrontActivity = ({
	limit = 25,
	cb,
	loggedUserAddress,
	filterBy,
	role,
}: SubscribeProps): Unsubscribe => {
	let filtersApplied = false

	const baseQuery = collection(db, "storefrontEvents")

	let countQuery = query(baseQuery)

	let storefrontQuery = query(baseQuery)

	if (role === "buyer") {
		storefrontQuery = query(
			storefrontQuery,
			or(
				where("data.buyer", "==", loggedUserAddress),
				and(
					where("type", "==", "STOREFRONT_OFFER_CREATED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("type", "==", "STOREFRONT_OFFER_CANCELLED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("type", "==", "STOREFRONT_OFFER_ACCEPTED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("data.buyer", "==", loggedUserAddress),
					where("type", "==", "STOREFRONT_PURCHASED")
				)
			)
		)
		countQuery = query(
			countQuery,
			or(
				where("data.buyer", "==", loggedUserAddress),
				and(
					where("type", "==", "STOREFRONT_OFFER_CREATED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("type", "==", "STOREFRONT_OFFER_CANCELLED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("type", "==", "STOREFRONT_OFFER_ACCEPTED"),
					where("accountAddress", "==", loggedUserAddress)
				),
				and(
					where("data.buyer", "==", loggedUserAddress),
					where("type", "==", "STOREFRONT_PURCHASED")
				)
			)
		)
	}

	if (role === "seller") {
		storefrontQuery = query(
			storefrontQuery,
			and(
				where("accountAddress", "==", loggedUserAddress),
				or(
					and(where("type", "==", "STOREFRONT_LISTED")),
					and(
						where("type", "==", "STOREFRONT_DELISTED"),
						where("accountAddress", "==", loggedUserAddress)
					),
					and(
						where("accountAddress", "==", loggedUserAddress),
						where("type", "==", "STOREFRONT_PURCHASED")
					)
				)
			)
		)
		countQuery = query(
			countQuery,
			and(
				where("accountAddress", "==", loggedUserAddress),
				or(
					and(where("type", "==", "STOREFRONT_LISTED")),
					and(
						where("type", "==", "STOREFRONT_DELISTED"),
						where("accountAddress", "==", loggedUserAddress)
					),
					and(
						where("accountAddress", "==", loggedUserAddress),
						where("type", "==", "STOREFRONT_PURCHASED")
					)
				)
			)
		)
	}

	if (role === "buyerAndSeller") {
		storefrontQuery = query(
			storefrontQuery,
			or(
				where("data.buyer", "==", loggedUserAddress),
				where("accountAddress", "in", [loggedUserAddress])
			)
		)
		countQuery = query(
			countQuery,
			or(
				where("data.buyer", "==", loggedUserAddress),
				where("accountAddress", "in", [loggedUserAddress])
			)
		)
	}

	if (filterBy?.path === "salePrice") {
		if (filterBy?.min && !filterBy?.max) {
			storefrontQuery = query(
				storefrontQuery,
				where("data.salePrice", ">=", Number(filterBy.min)),
				orderBy("data.salePrice")
			)
			countQuery = query(
				countQuery,
				where("data.salePrice", ">=", Number(filterBy.min))
			)
		}

		if (filterBy?.max && !filterBy?.min) {
			storefrontQuery = query(
				storefrontQuery,
				where("data.salePrice", "<=", Number(filterBy.max)),
				orderBy("data.salePrice")
			)
			countQuery = query(
				countQuery,
				where("data.salePrice", "<=", Number(filterBy.max))
			)
		}
		if (filterBy?.min && filterBy?.max) {
			storefrontQuery = query(
				storefrontQuery,
				where("data.salePrice", ">=", Number(filterBy.min)),
				where("data.salePrice", "<=", Number(filterBy.max)),
				orderBy("data.salePrice")
			)
			countQuery = query(
				countQuery,
				where("data.salePrice", ">=", Number(filterBy.min)),
				where("data.salePrice", "<=", Number(filterBy.max))
			)
		}
		filtersApplied = true
	}

	if (filterBy?.path === "state") {
		storefrontQuery = query(storefrontQuery, where("type", "==", filterBy.min))
		countQuery = query(countQuery, where("type", "==", filterBy.min))
	}

	if (filtersApplied) {
		storefrontQuery = query(storefrontQuery, orderBy("blockTimestamp", "desc"))
	} else {
		storefrontQuery = query(storefrontQuery, orderBy("blockTimestamp", "desc"))
	}

	storefrontQuery = query(storefrontQuery, firebaseLimit(limit))

	const fetchCount = getDocs(countQuery).then(
		countSnapshot => countSnapshot.size
	)

	const fetchPageData = onSnapshot(storefrontQuery, snapshot => {
		const storefrontData = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		}))

		if (storefrontData) {
			fetchCount.then(totalCount => {
				cb({ data: storefrontData, totalCount })
			})
		}
	})

	return fetchPageData
}
