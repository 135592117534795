import { Flowty, verifyAddress } from "flowty-sdk"
import { useField } from "formik"
import React, { useCallback, useEffect, useRef } from "react"
import { FlowtyFieldInput } from "../../../../FlowtyFieldInput"
import { FlowtyListBoxTokenSelector } from "../../../../FlowtyListboxTokenSelector/FlowtyListboxTokenSelection"
import { FlowtyNumericInput } from "../../../../FlowtyNumericInput"
import {
	FlowtyWalletSelector,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { AccountSummaries, AccountSummary } from "../../../../Types/GlobalTypes"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import {
	getMinRentDuration,
	IS_MAINNET,
	MAX_RENT_DURATION,
} from "../../../contexts/FlowtyListingModalContext/utils/constants"
import {
	allowDecimalInDuration,
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../../utils/constants"
import { ChildWalletListing } from "../../common/ChildWalletListing"

interface RentListingStep1Props {
	isDapper: boolean
	accountSummaries: AccountSummaries
	selectedAccount: AccountSummary
	setSelectedAccount: (account: AccountSummary) => void
	hasChildAccounts: boolean
	rentData: {
		listingKind: string
		paymentTokenName: string
		amount: number | string
		deposit: number | string
		term: number | string
		rentalListingDuration: number | string
	}
	rentFormError: boolean
	flowty: Flowty
}

export const RentListingStep1: React.FunctionComponent<
	RentListingStep1Props
> = ({
	isDapper,
	accountSummaries,
	selectedAccount,
	setSelectedAccount,
	hasChildAccounts,
	rentData,
	rentFormError,
	flowty,
}) => {
	const inputErrorRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (rentFormError) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
			return
		}
	}, [rentFormError])

	const [privateListingAddr] = useField({ name: `privateListingAddr` })
	const {
		isChildAccountAsset,
		privateRentInvalidAddress,
		setPrivateRentInvalidAddress,
		mainAccount,
		listingType,
		isMainnet,
	} = useFlowtyListingModalContext()

	const MIN_RENT_DURATION = getMinRentDuration(isMainnet)
	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	const checkIsInvalid = useCallback(async () => {
		if (privateListingAddr.value != "") {
			{
				const is = await verifyAddress(privateListingAddr.value)
				setPrivateRentInvalidAddress(!is)
			}
		}
	}, [privateListingAddr.value])

	useEffect(() => {
		if (!privateListingAddr.value) {
			setPrivateRentInvalidAddress(false)
			return
		}

		if (
			Boolean(privateListingAddr.value) &&
			privateListingAddr.value !== mainAccount?.address
		) {
			checkIsInvalid()
		} else {
			setPrivateRentInvalidAddress(false)
		}
	}, [checkIsInvalid, privateListingAddr.value])

	const isInvalidDuration =
		Number(rentData?.term) < MIN_RENT_DURATION ||
		rentData?.term === "" ||
		Number(rentData?.term) > MAX_RENT_DURATION

	const isInvalidListingDuration =
		Number(rentData?.rentalListingDuration) < MIN_LISTING_DURATION ||
		rentData?.rentalListingDuration === undefined ||
		Number(rentData?.rentalListingDuration) > MAX_LISTING_DURATION

	return (
		<div className='h-full' ref={inputErrorRef}>
			<div className='h-full flex flex-col justify-between gap-3'>
				<div className='flex flex-col gap-[14px]'>
					{hasChildAccounts && listingType === "rental" && (
						<FlowtyWalletSelector
							selected={selectedAccount}
							setSelected={setSelectedAccount}
							label='Pay To'
							summaries={accountSummaries}
							listingType={"rental"}
							disabledMessage={walletDisabledMessage.payTo}
						/>
					)}
					{hasChildAccounts && isChildAccountAsset && <ChildWalletListing />}
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Token Received
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyListBoxTokenSelector
								name='tokenType'
								orderType={"rental"}
								isDapper={isDapper}
								selectedAccount={selectedAccount}
								flowty={flowty}
							/>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Rental Fee
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyNumericInput
								name={`rentalFee`}
								valueError={
									rentFormError &&
									(Number(rentData.amount) < 0.01 ||
										rentData.amount === "" ||
										Number(rentData.amount) > 50000)
								}
								inputError={
									rentFormError &&
									(Number(rentData.amount) < 0.01 ||
										rentData.amount === "" ||
										Number(rentData.amount) > 50000)
								}
								errorMessage={
									rentFormError &&
									(Number(rentData.amount) < 0.01 ||
										rentData.amount === "" ||
										Number(rentData.amount) > 50000)
										? "Between $0.01 - $50000"
										: null
								}
							/>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Refundable Deposit
							</p>
						</div>
						<div className='w-1/2 flex gap-3 items-center'>
							<FlowtyNumericInput
								name={`refundableDeposit`}
								valueError={
									rentFormError &&
									(Number(rentData.deposit) < 0.01 ||
										Number(rentData.deposit) > 1000000 ||
										rentData.deposit === "")
								}
								inputError={
									rentFormError &&
									(Number(rentData.deposit) < 0.01 ||
										Number(rentData.deposit) > 1000000 ||
										rentData.deposit === "")
								}
								errorMessage={
									rentFormError &&
									(Number(rentData.deposit) < 0.01 ||
										Number(rentData.deposit) > 1000000 ||
										rentData.deposit === "")
										? "Between $0.01 - $1000000"
										: null
								}
							/>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Rental Duration
							</p>
						</div>
						<div className='w-1/2 flex gap-3 items-center'>
							<FlowtyNumericInput
								name={`rentalDuration`}
								valueError={rentFormError && isInvalidDuration}
								inputError={rentFormError && isInvalidDuration}
								errorMessage={
									rentFormError && isInvalidDuration
										? `Between ${MIN_RENT_DURATION} and ${MAX_RENT_DURATION} days`
										: null
								}
							/>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Days
							</p>
						</div>
					</div>
				</div>

				<div className='flex flex-col gap-[14px]'>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Listing Duration
							</p>
						</div>
						<div className='w-1/2 flex gap-3 items-center'>
							<FlowtyNumericInput
								name={`rentalListingDuration`}
								valueError={rentFormError && isInvalidListingDuration}
								inputError={rentFormError && isInvalidListingDuration}
								decimalsAllowed={allowDecimalInDuration(IS_MAINNET)}
								errorMessage={
									rentFormError && isInvalidListingDuration
										? `Between ${MIN_LISTING_DURATION} and ${MAX_LISTING_DURATION} days`
										: null
								}
							/>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Days
							</p>
						</div>
					</div>
					<div className='w-full flex items-center'>
						<div className='w-1/2'>
							<p className='text-base font-semibold text-white whitespace-nowrap'>
								Private Listing
							</p>
						</div>
						<div className='w-1/2'>
							<FlowtyFieldInput
								name={`privateListingAddr`}
								valueError={privateRentInvalidAddress}
								inputError={privateRentInvalidAddress}
								placeholder='Wallet Address'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
