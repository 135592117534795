import React from "react"
import {
	GenericActivityFilter,
	ActivityFilterProps,
} from "./GenericActivityFilter"

const rentalFilterOptions = [
	{ label: "Listed", value: "RENTAL_LISTED" },
	{ label: "Delisted", value: "RENTAL_DESTROYED" },
	{ label: "Rented", value: "RENTAL_RENTED" },
	{ label: "Returned", value: "RENTAL_RETURNED" },
	{ label: "Settled", value: "RENTAL_SETTLED" },
	{ label: "Expired", value: "EXPIRED" },
]

export const RentalActivityFilter: React.FC<ActivityFilterProps> = props => (
	<GenericActivityFilter {...props} filterOptions={rentalFilterOptions} />
)
