import { useField } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { TimePicker } from "./TimePicker"
import { ReactComponent as CalendarIcon } from "../../../../../assets/calendarIcon.svg"
import { ReactComponent as Chevron } from "../../../../../assets/chevron.svg"
import { ReactComponent as Clock } from "../../../../../assets/clock.svg"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays, format, startOfDay } from "date-fns"
import { DateTimeType } from "../../../../../types/FlowtyCreatorHubTypes"

export const setFutureEndDateTime = (
	date: Date | number,
	endDateHelpers: { setValue: (value: DateTimeType) => void }
): void => {
	const adjustedEndTime = addDays(new Date(date), 7)
	endDateHelpers.setValue({ dateTime: adjustedEndTime.getTime() })
}
interface DateTimePickerProps {
	fieldName: string
	selectsStart?: boolean
	selectsEnd?: boolean
	inputError?: boolean
	errorMessage?: string | null
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
	fieldName,
	selectsStart = false,
	selectsEnd = false,
	inputError,
	errorMessage,
}) => {
	const [field, , helpers] = useField<DateTimeType>(fieldName)
	const [, dropEndDate, endDateHelpers] =
		useField<DateTimeType>("dropEndDateTime")
	const [dropStartDate] = useField<DateTimeType>("dropStartDateTime")

	const startDate = new Date(dropStartDate.value.dateTime)
	const endDate = new Date(dropEndDate.value.dateTime)

	const formattedTime = field.value.dateTime
		? format(new Date(field.value.dateTime), "h:mm a")
		: format(new Date(), "h:mm a")

	const currentIsToday =
		new Date().getDate() === new Date(field.value.dateTime).getDate()

	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
	const [isTimePickerOpen, setIsTimePickerOpen] = useState(false)

	const selectedTimeValue =
		(fieldName === "dropStartDateTime" &&
			currentIsToday &&
			!Boolean(field.value.dateTime)) ||
		field.value.isNow
			? "NOW"
			: formattedTime

	const [selectedTime, setSelectedTime] = useState<string | null>(
		selectedTimeValue
	)

	const dropdownRef = useRef<HTMLDivElement>(null)

	const handleTimeClickOutside = (event: MouseEvent): void => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsTimePickerOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleTimeClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleTimeClickOutside)
		}
	}, [])

	const toggleTimePicker = (): void => {
		setIsTimePickerOpen(!isTimePickerOpen)
		setIsDatePickerOpen(false)
	}

	const handleCalendarClick = (e: React.MouseEvent): void => {
		e.stopPropagation()
	}

	const roundToNearestHour = (date: Date): Date => {
		const roundedDate = new Date(date)
		roundedDate.setMinutes(60, 0, 0)
		return roundedDate
	}

	const handleDateChange = (date: Date | null): void => {
		const isToday = new Date().getDate() === date?.getDate()

		if (isToday && fieldName === "dropStartDateTime") {
			setSelectedTime("NOW")
			helpers.setValue({
				dateTime: new Date().getTime(),
				isNow: true,
			})
			setIsDatePickerOpen(false)
		} else {
			if (date) {
				if (selectedTime === "NOW") {
					const roundedDate = roundToNearestHour(date)
					const formattedDate = format(roundedDate, "h:mm a")
					setSelectedTime(formattedDate)
					helpers.setValue({
						dateTime: roundedDate.getTime(),
						isNow: false,
					})

					if (
						fieldName === "dropStartDateTime" &&
						roundedDate.getTime() >= dropEndDate.value.dateTime
					) {
						setFutureEndDateTime(roundedDate, endDateHelpers)
					}

					setIsDatePickerOpen(false)
					return
				}

				if (
					fieldName === "dropStartDateTime" &&
					date.getTime() >= dropEndDate.value.dateTime
				) {
					setFutureEndDateTime(date, endDateHelpers)
				}

				const formattedDate = date.getTime()
				helpers.setValue({
					dateTime: formattedDate,
					isNow: false,
				})
				setIsDatePickerOpen(false)
			}
		}
	}

	const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
		e.stopPropagation()
		setIsDatePickerOpen(prevValue => !prevValue)
	}

	const handleClickOutside = (): void => {
		setIsDatePickerOpen(false)
	}

	const customDayClassName = (date: Date, dateStart: Date | null): string => {
		const now = new Date()

		const normalizedDate = startOfDay(date)
		const normalizedNow = startOfDay(now)
		const normalizedStartDate = dateStart ? startOfDay(dateStart) : null
		if (!normalizedStartDate) {
			return ""
		}
		if (
			normalizedDate >= normalizedNow &&
			normalizedDate < normalizedStartDate
		) {
			return "react-datepicker__day--disabled custom-disabled-day"
		}
		return ""
	}

	const getDateValue = (value: number | null | undefined): Date => {
		return value ? new Date(value) : new Date()
	}

	const getStartDate = (): Date => {
		return fieldName === "dropEndDateTime"
			? startDate || new Date()
			: new Date()
	}

	return (
		<div className='relative'>
			<div className='flex flex-row gap-[.56rem] 3xl:h-[2.5rem] items-stretch bg-[#04070B] text-white rounded-lg'>
				<div className='basis-2/3 relative inline-block'>
					<button
						className={`flex flex-row cursor-pointer gap-2 select-none w-full text-base md:justify-between px-2 py-[1px] 3xl:px-3 3xl:py-2 rounded-md h-[26.667px] 3xl:h-full border ${
							inputError ? "border-[#FF6E25]" : "border-[#495057]"
						} ${isDatePickerOpen && "react-datepicker-ignore-onclickoutside"}`}
						onClick={handleIconClick}
					>
						<div
							className='flex flex-row text-white bg-[#04070B] gap-2 w-full items-center '
							id='drop-form'
							data-testid={`${fieldName}-drop-form`}
						>
							<CalendarIcon className='cursor-pointer text-gray-500 h-4 3xl:h-5' />
							<DatePicker
								data-testid='date-picker'
								selected={
									field.value.dateTime
										? new Date(field.value.dateTime)
										: new Date()
								}
								onChange={handleDateChange}
								minDate={getStartDate()}
								open={isDatePickerOpen}
								selectsStart={selectsStart}
								selectsEnd={selectsEnd}
								className='react-datepicker inline-block cursor-pointer relative text-[#E9ECEF] text-[9.333px] 3xl:text-base font-normal leading-6'
								startDate={startDate ?? Date.now()}
								endDate={endDate ?? Date.now()}
								dateFormat='MMMM d, yyyy'
								dayClassName={date =>
									customDayClassName(
										date,
										getDateValue(dropStartDate.value.dateTime)
									)
								}
								calendarContainer={props => (
									<div
										{...props}
										onClick={handleCalendarClick}
										onMouseDown={handleCalendarClick}
									/>
								)}
								onClickOutside={handleClickOutside}
							/>
						</div>
						<Chevron />
					</button>
				</div>
				<div className='basis-1/3 h-[26.667px] 3xl:h-full' ref={dropdownRef}>
					<button
						type='button'
						onClick={toggleTimePicker}
						className={`px-2 3xl:px-3 h-full select-none flex flex-row items-center justify-between w-full py-1 3xl:py-2 rounded-md border ${
							inputError ? "border-[#FF6E25]" : "border-[#495057]"
						}`}
					>
						<div className='flex gap-2 items-center w-full'>
							<div className='w-4 3xl:w-5'>
								<Clock className='w-full' />
							</div>
							<div className='text-neutral-800 whitespace-nowrap text-[9.333px] 3xl:text-base font-normal leading-normal 3xl:leading-6'>
								{selectedTimeValue}
							</div>
						</div>
						<Chevron />
					</button>
					{isTimePickerOpen && (
						<div className='flex w-full justify-center items-center relative z-20 '>
							<TimePicker
								fieldName={fieldName}
								toggleTimePicker={toggleTimePicker}
								setSelectedTime={setSelectedTime}
								selectedTime={selectedTimeValue}
							/>
						</div>
					)}
				</div>
			</div>
			{errorMessage && (
				<p className='absolute bottom-[-15px] left-0 flex w-full justify-end text-end text-[#FF6E25] font-[400] text-[8px] leading-normal 3xl:text-xs whitespace-nowrap'>
					{errorMessage}
				</p>
			)}
		</div>
	)
}
