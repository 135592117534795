import { Tab } from "@headlessui/react"

interface CurrentStepButtonProps {
	step: string
	text: string
	subText: string
	isCurrentStep?: boolean
	onClick?: () => void
}

const StepCircle = ({ text }: { text: string }) => {
	return (
		<div className='flex flex-shrink-0 items-center justify-center min-w-[47px] min-h-[47px] 2xl:min-w-[64px] 2xl:min-h-[64px] border-[1px] border-[#FAFAFA] rounded-full'>
			<span className='text-[17px] lg:text-[24px] text-neutral-900 custom-shadow-steps font-[700]'>
				{text}
			</span>
		</div>
	)
}

const CurrentStepButton = ({
	step,
	text,
	subText,
	isCurrentStep,
	onClick,
}: CurrentStepButtonProps) => {
	return (
		<Tab
			className={`ui-not-focus-visible:outline-none focus:outline-none focus-visible:outline-none flex flex-1 border-[1px] border-transparent items-center gap-[24px] p-[12px] 2xl:py-[18px] 2xl:px-[24px] z-30 ${
				isCurrentStep
					? "bg-carouselButtonBackgroundGradient border-[1px] border-[#343A40] backdrop-blur-[21px]"
					: "cursor-pointer customStepsBg"
			} rounded-[12px]`}
		>
			<StepCircle text={step} />
			<div className='flex flex-col items-start'>
				<span className='leading-normal text-[#F8F9FA] text-[17.6px] 2xl:text-[24px] font-[700] text-left'>
					{text}
				</span>
				<span className='leading-normal text-[#A4A4A4] text-[10.3px] 2xl:text-[14px] font-[400] text-left'>
					{subText}
				</span>
			</div>
		</Tab>
	)
}

export default CurrentStepButton
