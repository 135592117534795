import { FlowtyTabs, TabConfig } from "ds-flowty"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import {
	ActivityModeProp,
	ActivityModeNames,
	useFlowtyMode,
} from "../../../hooks/useFlowtyMode"

interface FlowtyModeSelectorProps {
	path: string
	isCollectionPage?: boolean
	collectionAddress?: string
	collectionName?: string
}

const SUPPORTED_ACTIVITY_MODES: ActivityModeProp[] = [
	{
		name: ActivityModeNames.Storefront,
		route: "storefront",
	},
	{
		name: ActivityModeNames.Loans,
		route: "loans",
	},
	{
		name: ActivityModeNames.Rentals,
		route: "rentals",
	},
]

export const FlowtyModeSelector: React.FC<FlowtyModeSelectorProps> = ({
	path,
	isCollectionPage,
	collectionAddress,
	collectionName,
}) => {
	const { activeRoute } = useFlowtyMode()
	const navigate = useNavigate()

	const tabs = useMemo<TabConfig[]>(() => {
		return SUPPORTED_ACTIVITY_MODES.map(supportedMode => {
			return {
				id: supportedMode.route,
				label: supportedMode.name,
			}
		})
	}, [SUPPORTED_ACTIVITY_MODES])

	return (
		<div className='py-3 md:pb-[20px] md:sticky md:top-[74px] z-20 bg-darkBg'>
			<FlowtyTabs
				activeTab={activeRoute}
				tabs={tabs}
				onChange={tabId => {
					if (isCollectionPage) {
						navigate(
							`/collection/${collectionAddress}/${collectionName}/activity/${tabId}`,
							{ replace: true }
						)
					} else {
						navigate(`/${path}/${tabId}`, { replace: true })
					}
				}}
				type={"primary"}
			/>
		</div>
	)
}
