import { Path } from "flowty-common"

export const getBestCollectionPrivatePath = (
	paths: Path[] | string[],
	nftContractAddress?: string,
	nftContractName?: string,
	collectionProviderPath?: string
): string => {
	if (!paths || !paths.length) return ""

	if (typeof paths[0] === "string") {
		return paths[0]
	}

	const p = paths as Path[]

	if (nftContractAddress && nftContractName) {
		const flowtyPrivatePath = `${nftContractName}${nftContractAddress}CollectionProviderForFlowty`
		const flowtyPrivatePathExists = p.find(path =>
			path.identifier.includes(flowtyPrivatePath)
		)
		if (flowtyPrivatePathExists) return flowtyPrivatePathExists.identifier
	}

	const defaultPath = p.find(path => path.identifier.includes("ForFlowty"))
	if (defaultPath) return defaultPath.identifier

	if (collectionProviderPath) {
		const collectionProviderPathExists = p.find(path =>
			path.identifier.includes(collectionProviderPath)
		)
		if (collectionProviderPathExists)
			return collectionProviderPathExists.identifier
	}

	return p?.[0]?.identifier || ""
}

export const getBestFtPrivatePath = (
	paths: Path[],
	ftContractAddress?: string,
	ftContractName?: string
): string => {
	if (!paths || !paths.length) return ""

	if (ftContractAddress && ftContractName) {
		const flowtyPrivatePath = `${ftContractName}${ftContractAddress}FtProviderForFlowty`

		const privatePath = paths.find(path =>
			path.identifier.includes(flowtyPrivatePath)
		)
		if (privatePath) return privatePath.identifier
	}

	const defaultPath = paths.find(path => path.identifier.includes("ForFlowty"))
	if (defaultPath) return defaultPath.identifier

	return paths?.[0]?.identifier || ""
}
