import format from "date-fns/format"
import { OpenSearchListingAvailableData, calcAPRInterest } from "flowty-common"
import numeral from "numeral"
import React from "react"
import { NftPrice } from "../../FlowtyModal/components/common/NftPrice"
import { useFlowtyPurchaseModalContext } from "../../FlowtyModal/contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { getDurationPeriod } from "../../utils/GlobalUtils"
import { cn } from "../../utils/cn"

interface LoanCarouselCardProps {
	order: OpenSearchListingAvailableData
	selectedOrder: OpenSearchListingAvailableData | null
}

export const LoanCarouselCard: React.FunctionComponent<
	LoanCarouselCardProps
> = ({ order, selectedOrder }) => {
	const { spotPrice } = useFlowtyPurchaseModalContext()
	return (
		<div
			className={cn(
				"w-[288px] h-[270px] flex flex-col justify-between space-y-2 p-6 rounded-lg border border-[#495057] bg-[#ffffff14] font-montserrat cursor-pointer",
				{
					"border-primary":
						selectedOrder?.listingResourceID === order.listingResourceID,
				}
			)}
		>
			<div className='w-full flex flex-col gap-3'>
				<div className='flex w-full justify-between items-start'>
					<span className='text-base text-white'>Loan Amount</span>
					<div className='text-white font-semibold'>
						<NftPrice
							price={
								order?.derivations?.calculatedValues?.marketplaceAmount || 0
							}
							token={order?.paymentTokenName}
							spotPriceValue={
								spotPrice
									? spotPrice.value *
									  Number(
											order?.derivations?.calculatedValues?.marketplaceAmount
									  )
									: undefined
							}
						/>
					</div>
				</div>
				<div className='flex w-full justify-between items-start'>
					<span className='text-base text-white'>Repayment Amount</span>
					<div className='text-white font-semibold'>
						<NftPrice
							price={order.derivations?.calculatedValues?.totalRepayment || 0}
							token={order.paymentTokenName}
							spotPriceValue={
								spotPrice
									? spotPrice.value *
									  Number(order?.derivations?.calculatedValues?.totalRepayment)
									: undefined
							}
						/>
					</div>
				</div>
				<div className='flex w-full justify-between items-center'>
					<span className='text-base text-white'>Loan Rate</span>
					<span className='text-base text-white font-bold'>
						{(
							(order?.derivations?.calculatedValues?.periodicInterest ?? 0) *
							100
						).toFixed(2)}
						%
					</span>
				</div>
				<div className='flex w-full justify-between items-center'>
					<span className='text-base text-white'>APR</span>
					<span className='text-base text-white font-bold'>
						{numeral(
							calcAPRInterest(
								order
									? Number(
											order?.derivations?.calculatedValues?.periodicInterest
									  )
									: 0,
								order ? Number(order.term) : 0
							)
						).format("0,0.00")}
						%
					</span>
				</div>
				<div className='flex w-full justify-between items-center'>
					<span className='text-base text-white'>Duration</span>
					<span className='text-base text-white font-bold'>
						{getDurationPeriod(Number(order?.term))}
					</span>
				</div>
				<div className='flex w-full justify-between items-center'>
					{order?.valuations && (
						<>
							<span className='text-base text-white'>Blended LTV</span>
							<span className='text-base text-white font-bold'>
								{numeral(
									order.valuations?.aggregate?.loanToValueRatio * 100
								).format("0,0.0")}
								%
							</span>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
