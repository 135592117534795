import { NftPrice } from "ds-flowty"
import { SupportedTokens } from "flowty-common"
import { ProfileLink } from "../common/ProfileLink"

interface Props {
	borrower: string
	repaymentAmount: number
	tokenIdentifier: SupportedTokens
}

export const LoanDefaultedRow: React.FunctionComponent<Props> = ({
	borrower,
	repaymentAmount,
	tokenIdentifier,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Loan</span> of{""}
			</p>
			<NftPrice price={repaymentAmount} token={tokenIdentifier} />
			<p className='text-start'>
				was not repaid on time by <ProfileLink address={borrower} />
			</p>
		</div>
	)
}
