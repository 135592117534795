import { NftDetails } from "../../../contexts/FlowtyModalsContext/types/FlowtyModalsContextTypes"
import { CollectionTabsType } from "../../../screens/CollectionPage/hooks/useCollectionPage"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { NftListControllers } from "../NftListControllers/NftListControllers"
import { NftListLoading } from "../NftListLoading/NftListLoading"
import { DropStatus } from "../NftListView/components/EmptyNftView"
import { NftListView } from "../NftListView/NftListView"

interface NftListTabWrapperProps {
	handleSelectCard?: (selected: NftDetails) => void
	address?: string
	showVisitDrop?: DropStatus
	onSelectedTabChange?: (tab: CollectionTabsType) => void
}

export const NftListTabWrapper: React.FunctionComponent<
	NftListTabWrapperProps
> = ({ handleSelectCard, address, showVisitDrop, onSelectedTabChange }) => {
	const { facets } = useOpenSearchContext()

	return (
		<div className='flex flex-col w-full'>
			<NftListControllers
				address={address}
				nftList={
					<>
						{!facets ? (
							<NftListLoading />
						) : (
							<div className={`w-full`}>
								<NftListView
									onSelectCard={handleSelectCard}
									showVisitDrop={showVisitDrop}
									onSelectedTabChange={onSelectedTabChange}
								/>
							</div>
						)}
					</>
				}
			/>
		</div>
	)
}
