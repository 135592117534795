import { cn } from "../../../utils/cn"
import React, { useEffect, useState } from "react"
import BallerzLogo from "../SVGs/Ballerz_logo.png"
import BloctoLogo from "../SVGs/Blocto_logo.png"
import DapperlabsLogo from "../SVGs/Dapperlabs_logo.png"
import DMXLogo from "../SVGs/DMX_logo.png"
import DoodlesLogo from "../SVGs/Doodles_logo.png"
import FlowLogo from "../SVGs/Flow_logo.png"
import FlunksLogo from "../SVGs/Flunks_logo.png"
import LiveTokenLogo from "../SVGs/LiveToken_logo.png"
import NBATSLogo from "../SVGs/NBATS_logo.png"
import NFLADLogo from "../SVGs/NFLAD_logo.svg"
import UFCStrikeLogo from "../SVGs/UFCStrike_logo.png"

export const InfiniteLogos = ({
	direction = "left",
	speed = "fast",
	pauseOnHover = true,
	className,
}: {
	direction?: "left" | "right"
	speed?: "fast" | "normal" | "slow"
	pauseOnHover?: boolean
	className?: string
}) => {
	const containerRef = React.useRef<HTMLDivElement>(null)
	const scrollerRef = React.useRef<HTMLUListElement>(null)

	type Image = {
		src: string
		alt: string
		url: string
	}

	const images: Image[] = [
		{
			alt: "Ballerz",
			src: BallerzLogo,
			url: "/collection/0x8b148183c28ff88f/Gaia",
		},
		{ alt: "Blocto", src: BloctoLogo, url: "https://blocto.io/" },
		{
			alt: "Dapperlabs",
			src: DapperlabsLogo,
			url: "https://www.dapperlabs.com/",
		},
		{
			alt: "DMX",
			src: DMXLogo,
			url: "/collection/0xe3ad6030cbaff1c2/DimensionX",
		},
		{
			alt: "Doodles",
			src: DoodlesLogo,
			url: "/collection/0xe81193c424cfd3fb/Doodles",
		},
		{ alt: "Flow", src: FlowLogo, url: "https://flow.com/" },
		{
			alt: "Flunks",
			src: FlunksLogo,
			url: "/collection/0x807c3d470888cc48/Flunks",
		},
		{
			alt: "LiveToken",
			src: LiveTokenLogo,
			url: "https://livetoken.co/",
		},
		{
			alt: "NBATS",
			src: NBATSLogo,
			url: '/collection/0x0b2a3299cc857e29/TopShot?collectionFilters=%7B"0x0b2a3299cc857e29.TopShot"%3A%7B"%231+Serial"%3A%7B"True"%3Atrue%7D%7D%7D',
		},
		{
			alt: "NFLAD",
			src: NFLADLogo,
			url: '/collection/0xe4cf4bdc1751c65d/AllDay?collectionFilters=%7B"0xe4cf4bdc1751c65d.AllDay"%3A%7B"%231+Serial"%3A%7B"True"%3Atrue%7D%7D%7D',
		},
		{
			alt: "UFCStrike",
			src: UFCStrikeLogo,
			url: "/collection/0x329feb3ab062d289/UFC_NFT",
		},
	]

	useEffect(() => {
		addAnimation()
	}, [])

	const [start, setStart] = useState(false)

	function addAnimation() {
		if (containerRef.current && scrollerRef.current) {
			const scrollerContent = Array.from(scrollerRef.current.children)

			scrollerContent.forEach(item => {
				const duplicatedItem = item.cloneNode(true)
				if (scrollerRef.current) {
					scrollerRef.current.appendChild(duplicatedItem)
				}
			})

			getDirection()
			getSpeed()
			setStart(true)
		}
	}

	const getDirection = () => {
		if (containerRef.current) {
			if (direction === "left") {
				containerRef.current.style.setProperty(
					"--animation-direction",
					"forwards"
				)
			} else {
				containerRef.current.style.setProperty(
					"--animation-direction",
					"reverse"
				)
			}
		}
	}

	const getSpeed = () => {
		if (containerRef.current) {
			if (speed === "fast") {
				containerRef.current.style.setProperty("--animation-duration", "20s")
			} else if (speed === "normal") {
				containerRef.current.style.setProperty("--animation-duration", "40s")
			} else {
				containerRef.current.style.setProperty("--animation-duration", "80s")
			}
		}
	}

	const handleImageClick = (url: string) => {
		// eslint-disable-next-line xss/no-location-href-assign
		window.location.href = url
	}

	return (
		<div
			ref={containerRef}
			className={cn(
				"scroller relative overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)] opacity-80",
				className
			)}
		>
			<ul
				ref={scrollerRef}
				className={cn(
					"flex flex-row items-center shrink-0 gap-[52px] flex-nowrap md:px-[120px] lg:px-[240px]",
					start && "animate-scroll ",
					pauseOnHover && "hover:[animation-play-state:paused]"
				)}
			>
				{[...Array(2)].map((_, index) => (
					<React.Fragment key={index}>
						{images.map((item, i) => (
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
							<img
								src={item.src}
								alt={item.alt}
								key={`${item.alt}-${i}-${index}`}
								className='grayscale shrink-0 max-h-[32px] cursor-pointer'
								onClick={() => handleImageClick(item.url)}
							/>
						))}
					</React.Fragment>
				))}
			</ul>
		</div>
	)
}
