import { NFTMetadata } from "flowty-common"
import { getMetadataFields } from "../../util/getMetadataFields"
import React, { useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"

interface Props {
	metadata: NFTMetadata
	type: string
}

interface GridProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: { [key: string]: any }
}

interface BoxProps {
	field: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any
}

const MetadataBox = ({ field, value }: BoxProps) => (
	<tr>
		<td className='w-[25%]'>
			<p className='text-13 pr-3 py-2 capitalize'>{field}</p>
		</td>
		<td className='w-[75%]'>
			<p className='text-13'>
				<strong>{value}</strong>
			</p>
		</td>
	</tr>
)

const MetadataGrid = ({ data }: GridProps) => {
	const [isOpen, setIsOpen] = useState(true)
	return (
		<div>
			<div className='metadata-grid justify-start'>
				<div className='w-full'>
					<div
						className='flex justify-between cursor-pointer'
						onClick={() => setIsOpen(!isOpen)}
					>
						<div>Properties</div>
						{isOpen ? (
							<ChevronUpIcon height={24} width={24} />
						) : (
							<ChevronDownIcon height={24} width={24} />
						)}
					</div>
					{isOpen && (
						<div className='flex justify-center'>
							<table className='min-w-full overflow-auto flex'>
								<tbody className='bg-darkBG px-4'>
									{Object.entries(data).map(([key, value]) => (
										<MetadataBox
											key={`field-${key}`}
											field={key}
											value={value}
										/>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export const Metadata: React.FunctionComponent<Props> = ({
	metadata,
	type,
}) => {
	const metadataFields = getMetadataFields(metadata, type)
	return <MetadataGrid data={metadataFields} />
}
