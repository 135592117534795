import { ErrorMessage, Field } from "formik"
import { useRef, useState } from "react"
import AuthStore from "../../../stores/AuthStore"
import { LoadingSpinner } from "../../Shared/LoadingSpinner"
import { onChangeHandler } from "../../Shared/fileUpload"
import { ProfileAvatarComponent } from "./ProfileAvatar"

interface UserInfoProps {
	authStore?: AuthStore | undefined
	setFieldValue: (
		field: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any,
		shouldValidate?: boolean | undefined
	) => void
	image: string
	setImage: (image: string) => void
}

export const UserInfo = ({
	authStore,
	setFieldValue,
	image,
	setImage,
}: UserInfoProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const fileInput = useRef<HTMLInputElement>(null)
	const onUploadBtnClick = (): void => {
		if (fileInput && fileInput.current) {
			fileInput.current?.click()
		}
	}
	return (
		<div className='flex flex-col md:flex-row md:gap-[3.5] gap-[1rem]'>
			<div className='flex flex-col justify-between w-auto'>
				<label
					htmlFor='profilePicture'
					className='block text-base font-bold mb-[1.12rem] min-w-[8.5rem]'
				>
					Profile Picture
				</label>

				<input
					id='avatar'
					name='avatar'
					type='file'
					ref={fileInput}
					style={{ display: "none" }}
					onChange={event => {
						onChangeHandler(
							event,
							setImage,
							setIsLoading,
							authStore?.loggedUser?.addr ?? "",
							setFieldValue
						)
					}}
				/>
				<button
					type='button'
					className={`flex flex-col cursor-pointer items-center justify-center ${
						authStore?.loggedUser?.avatar
							? " focus:border-none focus:ring-0 rounded-[0.25rem] w-[7.375rem] h-[7.375rem] bg-cover bg-center text-white"
							: ""
					}`}
					onClick={e => {
						e.stopPropagation()
						onUploadBtnClick()
					}}
				>
					<div
						className={`bg-[#031021]/60 rounded-[0.25rem] w-[7.375rem] h-[7.375rem] flex items-center ${
							isLoading ? "justify-center" : ""
						}`}
					>
						{isLoading ? (
							<LoadingSpinner size={15} borderThickness={3} />
						) : (
							<ProfileAvatarComponent
								avatar={
									image
										? image
										: authStore?.loggedUser?.avatar
										? authStore?.loggedUser?.avatar
										: ""
								}
							/>
						)}
					</div>
				</button>
			</div>
			<div className='flex flex-col w-full'>
				<div className='mb-4'>
					<label
						htmlFor='userName'
						className='block text-base font-bold mb-[.5rem]'
					>
						Username
					</label>
					<Field
						type='text'
						id='userName'
						name='userName'
						className='text-[1rem] bg-[#FFFFFF14] font-normal cursor-pointer border-[1px] focus:border-primary focus:outline-none focus:bg-transparent focus:ring-0 border-[#6C757D] rounded-md w-full py-2 px-3 leading-tight hover:bg-[#ffffff29] input::-webkit-autofill input::-webkit-autofill:focus
        input::-webkit-autofill:active input:-webkit-autofill:hover'
					/>
				</div>
				<div>
					<label htmlFor='email' className='block text-base font-bold mb-2'>
						Email
					</label>
					<Field
						type='email'
						id='email'
						name='email'
						className='text-[1rem] bg-[#FFFFFF14] font-normal cursor-pointer border-[1px] focus:border-primary focus:outline-none focus:bg-transparent focus:ring-0 border-[#6C757D] rounded-md w-full py-2 px-3 leading-tight hover:bg-[#ffffff29] input::-webkit-autofill input::-webkit-autofill:focus
        input::-webkit-autofill:active input:-webkit-autofill:hover'
					/>
					<ErrorMessage name='email' component='div' className='text-red-500' />
				</div>
			</div>
		</div>
	)
}
