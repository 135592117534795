import { TokenMetadata } from "flowty-common"
import { FlowNFTData } from "../../common/CommonTypes"
import { Config } from "../../types"

export const getReturnRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  returnRentalTxnCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  returnRentalTxn(config, token, nftData)

const returnRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const returnRentalTxnCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import FlowtyRentals from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
    rentalResourceID: UInt64,
    marketplaceAddr: Address,
    nftProviderAddress: Address,
    nftProviderControllerID: UInt64
) {
    let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
    let marketplace: &{FlowtyRentals.FlowtyRentalsMarketplacePublic}
    let rental: &{FlowtyRentals.RentalPublic}

    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.marketplace = getAccount(marketplaceAddr).capabilities.get<&{FlowtyRentals.FlowtyRentalsMarketplacePublic}>(FlowtyRentals.FlowtyRentalsMarketplacePublicPath)
            .borrow() ?? panic("Could not find FlowtyRentals marketplace")

        self.rental = self.marketplace.borrowRental(rentalResourceID: rentalResourceID) ?? panic("failed to find rental resource")

        let rentalDetails = self.rental.getDetails()
        let nftType = rentalDetails.nftType

        let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
        var catalogIdentifier = ""
        for k in catalogIdentifiers.keys {
            if catalogIdentifiers[k] == true {
                catalogIdentifier = k
                break
            }
        }

        assert(catalogIdentifier != "", message: "no valid catalog identifier found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if nftProviderAddress == acct.address {
            self.nftProvider = acct.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: storageCollectionPath)
            ?? panic("failed to borrow provider from storage")
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")

            let cap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>()) ?? panic("no cap found")
            let provider = cap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>
            assert(provider.check(), message: "invalid provider capability")

            self.nftProvider = provider.borrow() ?? panic("unable to borrow provider")
        }
    }

  execute {
    let nft <- self.nftProvider.withdraw(withdrawID: self.rental.getDetails().nftID)
    self.rental.returnNFT(nft: <-nft)
  }
}`
