import {
	FlowNFT,
	FlowNFTContract,
	getNftId,
	nftTypeToContractID,
} from "flowty-common"
import { db } from "../../firebase"
import { flowty } from "../../config/config"
import { actions as Mixpanel } from "../../util/Mixpanel"

export const getNftStoragePath = async (nft: FlowNFT): Promise<string> => {
	let identifier = nft?.nftView?.collectionData?.storagePath?.identifier
	if (identifier) {
		return identifier
	}

	await flowty.api.refreshMetadata(getNftId(nft))
	if (!identifier) {
		const contractID = nftTypeToContractID(nft.type)
		let doc
		try {
			doc = await db.collection("flowNFTContract").doc(contractID).get()
		} catch (e) {
			Mixpanel.track("ERROR_FIREBASE_GET_ACCOUNT_GLOBAL_ALERT", {
				e,
			})
		}

		if (doc && !doc.exists) {
			Mixpanel.track("missing contract to supplement missing metadata on nft")
		}

		const contract = doc?.data() as FlowNFTContract
		identifier = contract.collectionData?.storagePath?.identifier
	}

	return identifier ?? ""
}
