import { ReactComponent as Bell } from "./assets/bell.svg"
import { ReactComponent as Empty } from "./assets/empty.svg"

interface EmptyNotificationMenuProps {
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

export const EmptyNotificationMenu = ({
	onMouseEnter,
	onMouseLeave,
}: EmptyNotificationMenuProps) => {
	return (
		<div
			data-testid='empty-notification-menu'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className='flex px-[28px] py-[24px] flex-col justify-center items-start gap-[10px] rounded-[6px] border-[1px] border-neutral-400 bg-notifications backdrop-blur-[50px] min-w-[339px] max-w-[339px]'
		>
			<div className='flex flex-col items-start gap-[32px]'>
				<div className='inline-flex gap-[8px] items-center w-[168px]'>
					<Bell />
					<span className='text-neutral-700 text-[16px] font-[900] leading-[16px] text-center'>
						NOTIFICATIONS
					</span>
				</div>
				<div className='mx-auto flex flex-col items-center gap-[14px]'>
					<Empty />
					<div className='flex flex-col items-center gap-[8px]'>
						<span className='text-[14px] text-neutral-500 font-[700]'>
							No Notifications
						</span>
						<span className='text-[12px] text-neutral-500 font-[400] text-center'>
							You will find notifications and transaction status here
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
