import { FlowNFTContract } from "flowty-common"
import { LegacyRef, useEffect, useState } from "react"
import axios from "axios"
import { apiURL } from "../../../util/settings"
import useInfiniteScroll from "../../../hooks/infiniteScroll"
import { actions as Mixpanel } from "../../../util/Mixpanel"

interface UseCollectionListPageValues {
	collections: FlowNFTContract[]
	endOfData: boolean
	isLoading: boolean
	onScrollRef: LegacyRef<HTMLDivElement> | undefined
}

interface UseCollectionListPageProps {
	count?: number
}

export const useCollectionListPage = ({
	count = 8,
}: UseCollectionListPageProps): UseCollectionListPageValues => {
	const url = `${apiURL}/collections`
	const [collections, setCollections] = useState<FlowNFTContract[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [endOfData, setEndOfData] = useState(false)
	const [page, setPage] = useState(0)

	const addPage = (): void => {
		setPage(page + 1)
	}

	useEffect(() => {
		setIsLoading(true)
		axios.post(url, { offset: count * page, size: count }).then(res => {
			setCollections([...collections, ...(res.data?.collections ?? [])])
			setEndOfData(
				collections.length + (res.data?.collections ?? []).length >=
					res.data?.total
			)
			setIsLoading(false)
		})
	}, [page])

	const onScrollRef = useInfiniteScroll(
		{
			isLoading: isLoading,
			onInfiniteScroll() {
				addPage()
			},
		},
		[collections]
	)

	useEffect(() => {
		Mixpanel.track(`All collections page visited`)
	}, [])

	return {
		collections,
		endOfData,
		isLoading,
		onScrollRef,
	}
}
