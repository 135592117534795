import axios, { AxiosRequestConfig } from "axios"
import { FlowNFTContract } from "flowty-common"
import { apiURL } from "../util/settings"

interface GlobalSearchRequest {
	query: string
}

// this shape should support types for every index it searches across
interface GlobalSearchHit<T> {
	score: number // float
	data: T
}

export interface GlobalSearchResponse {
	contracts: GlobalSearchHit<FlowNFTContract>[]
}

export class FlowtyAPI {
	static globalSearch(
		req: GlobalSearchRequest,
		config?: AxiosRequestConfig<GlobalSearchRequest>
	): Promise<GlobalSearchResponse> {
		return axios
			.post<GlobalSearchResponse>(apiURL.concat("/search/global"), req, config)
			.then(resp => resp.data)
	}
}
