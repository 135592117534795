import { Config } from "../types"

export const hasExposedProviders = (config: Config): string => {
	return !config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hasExposedProviders(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hasExposedProvidersCrescendo(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hasExposedProvidersCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}

access(all) fun main(addr: Address):Bool {
  let acct = getAuthAccount<auth(Capabilities, Storage) &Account>(addr)

  let impactedPaths: [PublicPath] = []
  let nftProviderType = Type<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>>()
	let ftProviderType = Type<Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>>()

  acct.storage.forEachPublic(fun (path: PublicPath, type: Type): Bool {
    if type.isSubtype(of: nftProviderType) || type.isSubtype(of: ftProviderType) {
      impactedPaths.append(path)
    }
    
    return true
  })

  return impactedPaths.length > 0
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hasExposedProviders = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}

pub fun main(addr: Address):Bool {
  let acct = getAuthAccount(addr)

  let impactedPaths: [PublicPath] = []
  let nftProviderType = Type<Capability<&{NonFungibleToken.Provider}>>()
	let ftProviderType = Type<Capability<&{FungibleToken.Provider}>>()

  acct.forEachPublic(fun (path: PublicPath, type: Type): Bool {
    if type.isSubtype(of: nftProviderType) || type.isSubtype(of: ftProviderType) {
      impactedPaths.append(path)
    }
    
    return true
  })

  return impactedPaths.length > 0
}`
