import { useEffect, useMemo, useState } from "react"
import { Filters, MinMaxFilter } from "../index"
import { IEventType } from "flowty-common"
import { FilterBy } from "../../Tables/Storefront/AllActivity/types"

interface UserStorefrontTableFiltersProps {
	showFilter: boolean
	setShowFilter: (showFilter: boolean) => void
	filterBy: FilterBy
	setFilterBy: (filterBy: FilterBy) => void
	asBuyer: boolean
}

export const UserStorefrontTableFilters: React.FunctionComponent<
	UserStorefrontTableFiltersProps
> = ({ showFilter, setShowFilter, filterBy, setFilterBy, asBuyer }) => {
	const [status, setStatus] = useState<string | null>(null)

	const [salePrice, setPrice] = useState<MinMaxFilter>({
		max: null,
		min: null,
	})

	const filterOptionsByRole = useMemo(() => {
		if (asBuyer) {
			return [
				{ label: "Purchased", value: "STOREFRONT_PURCHASED" },
				{
					label: "Offer Created",
					value: "STOREFRONT_OFFER_CREATED",
				},
				{
					label: "Offer Accepted",
					value: IEventType.StorefrontOfferAccepted.valueOf(),
				},
				{
					label: "Offer Cancelled",
					value: IEventType.StorefrontOfferCancelled.valueOf(),
				},
			]
		} else {
			return [
				{
					label: "Offer Accepted",
					value: IEventType.StorefrontOfferAccepted.valueOf(),
				},
				{ label: "Purchased", value: "STOREFRONT_PURCHASED" },
				{ label: "Listed", value: "STOREFRONT_LISTED" },
				{ label: "Delisted", value: "STOREFRONT_DELISTED" },
			]
		}
	}, [asBuyer])

	const FilterFields = [
		{
			path: "state",
			selectField: true,
			setValue: setStatus,
			title: "Status",
			value: status,
		},
		{
			path: "salePrice",
			setValue: setPrice,
			title: "Price",
			value: salePrice,
		},
	]

	const setAllFieldsNull = () => {
		setStatus(null)
		setPrice({ max: "", min: "" })
	}

	useEffect(() => {
		if (filterBy.path && !filterBy.min && !filterBy.max) {
			setFilterBy({ ...filterBy, path: null })
		}
		if (!filterBy.path) {
			setAllFieldsNull()
		}
	}, [filterBy])

	return (
		<Filters
			showFilter={showFilter}
			setShowFilter={setShowFilter}
			FilterFields={FilterFields}
			filterOptions={filterOptionsByRole}
			setAllFieldsNull={setAllFieldsNull}
			filterBy={filterBy}
			setFilterBy={setFilterBy}
		/>
	)
}
