import { useState } from "react"
import { getCollectionImage } from "../../../util/getCollectionImage"
import { addFallbackImage } from "../../../util/images"

interface ActivityTableImageProps {
	collectionAddress: string
	collectionName: string
	srcImg: string
}

export const ActivityTableImage = ({
	collectionAddress,
	collectionName,
	srcImg,
}: ActivityTableImageProps) => {
	const [collectionUrl, setCollectionUrl] = useState("")

	return (
		<>
			{!collectionUrl ? (
				<img
					src={srcImg}
					onError={() => {
						getCollectionImage({
							collectionAddress,
							collectionName,
						}).then(res => {
							setCollectionUrl(res)
						})
					}}
					alt={`${srcImg} asset`}
					className='object-cover aspect-square rounded-[4px]'
				/>
			) : (
				<img
					src={collectionUrl}
					onError={e => {
						addFallbackImage(e)
					}}
					alt={`${collectionUrl} asset`}
					className='object-cover aspect-square rounded-[4px]'
				/>
			)}
		</>
	)
}
