import { FlowtyButton } from "ds-flowty"
import { ViewSetting } from "flowty-common"
import { Form, Formik } from "formik"
import { inject, observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import {
	EmailSchema,
	getEmailSettings,
	handleSubscribe,
} from "../../../services/EmailService"
import { AuthStoreProp } from "../../../stores/AuthStore"
import {
	SnackbarAlert,
	SnackbarSeverity,
} from "../../Shared/Snackbar/SnackbarAlert"
import { getInitialValues, onUserProfileFormSubmit } from "../Util/FormSubmit"
import { UserInfo } from "./UserInfo"
import { FlowtyToggle } from "ds-flowty/src/FlowtyToggle"

export interface ProfileSettingsValues {
	emailOptIn?: boolean
	userName: string
	email: string
	marketingEmail?: string
	avatar: string
	subscribe?: boolean
	subscribeOffers?: boolean
	subscribeNewsletter?: boolean
	preferredCardSize?: ViewSetting
	welcomeCheckboxTerms?: boolean
}

const UserPreferences: React.FC<AuthStoreProp> = ({ authStore }) => {
	if (!authStore?.loggedUser?.loggedIn) {
		window.location.replace("/")
	}

	const [loading, setLoading] = useState<boolean>(true)
	const [touched, setTouched] = useState<boolean>(false)
	const [offersToggleState, setOffersToggleState] = useState<boolean>(false)
	const [newsletterToggleState, setNewsletterToggleState] = useState<boolean>()
	const [submitError, setSubmitError] = useState<string | null>(null)
	const [image, setImage] = useState(authStore?.loggedUser?.avatar ?? "")

	const [snackbar, setSnackbar] = useState<{
		show: boolean
		message: string
		type: SnackbarSeverity
	}>({ message: "", show: false, type: "SUCCESS" })

	const getWalletConnectedInitialValues = () => {
		const initialValues = getInitialValues(authStore)
		if (!initialValues.email || initialValues.email.length === 0) {
			return {
				...initialValues,
				email: authStore?.loggedUser?.blockToEmail || "",
			}
		}
		return {
			...initialValues,
			subscribeNewsletter: newsletterToggleState ?? false,
			subscribeOffers: offersToggleState ?? false,
		}
	}

	const fetchEmailSettings = async () => {
		try {
			const emailSettings = await getEmailSettings()
			if (emailSettings) {
				setNewsletterToggleState(!emailSettings.settings[1]?.suppressed)
				setOffersToggleState(!emailSettings.settings[0]?.suppressed)
				setLoading(false)
				Mixpanel.track("EMAIL_SETTINGS_FETCH_SUCCESS", {
					email: authStore?.loggedUser?.marketingEmail,
				})
			}
		} catch (error) {
			Mixpanel.track("EMAIL_SETTINGS_FETCH_FAILURE", {
				email: authStore?.loggedUser?.marketingEmail,
				error,
			})
		}
	}

	useEffect(() => {
		if (authStore?.loggedUser?.marketingEmail) {
			fetchEmailSettings()
		} else {
			setOffersToggleState(false)
			setNewsletterToggleState(false)
			setLoading(false)
		}
	}, [authStore?.loggedUser])

	const onFormSubmitted = (): void => {
		setSnackbar({
			message: "Successfully Saved Preferences",
			show: true,
			type: "SUCCESS",
		})
		setSubmitError(null)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onFormSubmitError = (err: any, resetForm: () => void): void => {
		resetForm()
		setSnackbar({
			message: "Something went wrong. Please try again later",
			show: true,
			type: "ERROR",
		})
		setSubmitError(err)
	}

	const handleFormSubmit = async (
		values: ProfileSettingsValues,
		setSubmitting: { (isSubmitting: boolean): void; (arg0: boolean): void }
	): Promise<void> => {
		const { userName, email, subscribeOffers, subscribeNewsletter } = values

		authStore?.setProfileImage(image)
		authStore?.setUsername(userName)
		authStore?.setEmail(email)

		handleSubscribe(authStore, email, subscribeOffers, subscribeNewsletter)

		await onUserProfileFormSubmit(
			authStore?.loggedUser?.addr ?? "",
			values,
			onFormSubmitted,
			"settings"
		)
			.then(() => {
				setSubmitting(false)
				Mixpanel.track("USER_PREFERENCES_SUBMITTED", {
					values,
				})
			})
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.catch((err: any) => {
				setSubmitting(false)
				onFormSubmitError(err, () => {})
				Mixpanel.track("USER_PREFERENCES_SUBMIT_FAILED", {
					error: err,
					values,
				})
			})
	}
	return (
		<div className='flex-1 px-[2.5rem] py-[2rem] w-full lg:min-w-[44.25rem] text-[#DEE2E6] bg-neutral-400/10 rounded-lg'>
			<SnackbarAlert
				setShow={show => setSnackbar({ ...snackbar, show })}
				show={snackbar.show}
				seconds={5}
				message={snackbar.message}
				type={snackbar.type}
			/>{" "}
			<div className='font-semibold text-lg pb-[2rem]'>User Preferences</div>
			<Formik
				initialValues={getWalletConnectedInitialValues()}
				enableReinitialize={true}
				validationSchema={EmailSchema}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					try {
						await handleFormSubmit(values, setSubmitting)
					} catch (err) {
						onFormSubmitError(err, resetForm)
					}
				}}
			>
				{({ dirty, isSubmitting, isValid, setFieldValue, resetForm }) => (
					<Form>
						<div className='section md:[mb-6] mb-4'>
							<UserInfo
								authStore={authStore}
								image={image}
								setImage={setImage}
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className='max-w-[39.25rem] fill-[4C5B69] my-[3em] border-gradient js-show-on-scroll motion-safe:animate-fadeIn' />
						<div className='flex md:[mb-6] mb-4 w-full'>
							<div className='w-full'>
								<h3 className='text-lg font-bold mb-[2rem]'>Notifications</h3>
								<p className='text-base font-bold leading-4'>Received Offers</p>
								<FlowtyToggle
									key={0}
									loading={loading}
									name='subscribeOffers'
									label={
										<>
											<p className='text-xs font-normal mt-[0.5rem] mb-[0.5rem] text-[#DEE2E6]'>
												Subscribe to email notifications for Received Offers
											</p>
										</>
									}
								/>
								<p className='text-base font-bold leading-4 mt-8'>
									Promotional Emails
								</p>
								<FlowtyToggle
									key={1}
									loading={loading}
									name='subscribeNewsletter'
									label={
										<>
											<p className='text-xs font-normal mt-[0.5rem] mb-[0.5rem] text-[#DEE2E6]'>
												Subscribe to email notifications for The Flowty
												Newsletter and other informational emails
											</p>
											{(newsletterToggleState == false ||
												offersToggleState == false) && (
												<p className='text-xs font-normal mb-[0.5rem] text-[#FF6E25]'>
													Note: Opting out of the above will not prevent
													transaction confirmation emails
												</p>
											)}
										</>
									}
								/>
							</div>
						</div>

						<div className='max-w-[39.25rem] fill-[4C5B69] my-[3em] border-gradient js-show-on-scroll motion-safe:animate-fadeIn' />
						<div className='flex gap-[0.5rem] justify-end w-[200px] mr-0 ml-auto'>
							<FlowtyButton
								onClick={resetForm}
								type='reset'
								disabled={!dirty && !touched}
								text={"CANCEL"}
								variant={"secondary"}
								bgColor='white'
							/>

							<FlowtyButton
								text={
									isSubmitting ? (
										<div
											className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
											role='status'
											aria-label='loading'
										/>
									) : (
										"SAVE"
									)
								}
								type='submit'
								disabled={!isValid || isSubmitting || (!dirty && !touched)}
								variant={"secondary"}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}
export default inject("authStore")(observer(UserPreferences))
