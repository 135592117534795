import {
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronUpIcon,
} from "@heroicons/react/solid"
import { OfferCreated, OpensearchFlowNFT } from "flowty-common"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Oval as OvalLoader } from "react-loader-spinner"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import { ProfileOfferRow } from "../ProfileOfferRow"
import { SortColumnOrNull } from "../../../../../components/SortableTable"

export interface ProfileOffersTableField {
	name: string
	title: string
	customRender: (val: {
		callback: () => void
		offer: OfferCreated
		nft?: OpensearchFlowNFT
		key: string
	}) => React.ReactNode
	onSort?: () => void
	sortable?: boolean
}

interface ProfileOffersTableProps {
	items: OpensearchFlowNFT[]
	fields: Array<ProfileOffersTableField>
	onSort?: (args: SortColumnOrNull) => void
	initialSortConfig?: SortColumnOrNull
	isFetchingResults?: boolean
}

export const ProfileOffersTable: React.FC<ProfileOffersTableProps> = ({
	items,
	fields,
	onSort = () => {},
	initialSortConfig = null,
	isFetchingResults,
}) => {
	const [sortMode, setSortMode] = useState(initialSortConfig)
	const [isScrolledFullLeft, setIsScrolledFullLeft] = useState(true)
	const [isScrolledFullRight, setIsScrolledFullRight] = useState(true)

	const scrollableElement = useRef<HTMLDivElement>(null)
	const onSortClicked = useCallback(
		(name: string) => () => {
			if (sortMode?.column === name) {
				if (sortMode.order === "desc") {
					setSortMode({
						column: name,
						order: "asc",
					})
					return
				}
				setSortMode(initialSortConfig)
				return
			}
			setSortMode({
				column: name,
				order: "desc",
			})
		},
		[sortMode]
	)

	useEffect(() => {
		onSort(sortMode)
	}, [sortMode])

	useEffect(() => {
		if (sortMode?.column === "blockTimestamp" && sortMode?.order === "desc") {
			Mixpanel.track(`Offers table sorted`, {
				sortedBy: sortMode,
			})
		}
	}, [sortMode?.column])
	useEffect(() => {
		if (!scrollableElement.current) return

		const scrollWidth = scrollableElement.current.scrollWidth
		const clientWidth = scrollableElement.current.clientWidth

		const isScrollable = scrollWidth > clientWidth

		if (!isScrollable) return

		const scrolledFromLeft = scrollableElement.current.scrollLeft

		setIsScrolledFullRight(scrolledFromLeft + clientWidth + 100 > scrollWidth)
		setIsScrolledFullLeft(scrolledFromLeft < 100)
	}, [scrollableElement?.current])
	const tableDistanceFromTop = useMemo(
		() =>
			(scrollableElement.current?.getBoundingClientRect?.()?.top || 0) + 100,
		[scrollableElement.current]
	)
	return (
		<div
			className={` relative flow-root w-full text-lightText pr-6 pl-4 sm:pl-0 lg:pr-0 ${
				isFetchingResults && "animate-pulse"
			}`}
		>
			<div
				onScroll={(e: React.UIEvent<HTMLDivElement>) => {
					// eslint-disable-next-line
					const ele = e.target as HTMLDivElement

					setIsScrolledFullRight(
						ele?.clientWidth + ele?.scrollLeft + 100 > ele?.scrollWidth
					)
					setIsScrolledFullLeft(ele?.scrollLeft < 100)
				}}
				ref={scrollableElement}
				className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'
			>
				{!isScrolledFullRight && (
					<ChevronDoubleRightIcon
						style={{ top: `${tableDistanceFromTop}px` }}
						className='lg:hidden fixed right-2  animate-pulse h-12 w-12 text-white drop-shadow-[0_6px_6px_rgba(4,229,163,0.5)] z-50'
					/>
				)}
				{!isScrolledFullLeft && (
					<ChevronDoubleLeftIcon
						style={{ top: `${tableDistanceFromTop}px` }}
						className='lg:hidden fixed left-2  animate-pulse h-12 w-12 text-white drop-shadow-[0_6px_6px_rgba(4,229,163,0.5)] z-50'
					/>
				)}
				<div className='inline-block py-2 align-middle sm:px-6 lg:px-8 w-full'>
					<table className='w-full divide-y divide-gray-300 bg-transparent'>
						<thead className='border-b-[0.5px] border-[#444444]'>
							<tr className='uppercase text-base font-semibold text-[#DDDDDD]'>
								{fields.map(field => (
									<th className='px-3 py-3.5' key={`${field.title} header`}>
										<button
											type='button'
											className={`bg-transparent ${
												field.sortable ? "" : "cursor-default"
											} p-0 m-0 flex flex-row items-center`}
											disabled={!field.sortable}
											onClick={
												field.sortable ? onSortClicked(field?.name) : () => {}
											}
										>
											<span className='uppercase whitespace-nowrap inline-flex items-center text-left text-base'>
												{field.title}
											</span>
											{field.sortable && (
												<ChevronUpIcon
													color='white'
													height={20}
													className={` ml-1 ${
														sortMode?.column === field.name
															? "opacity-100"
															: "opacity-20 hover:opacity-30"
													} ${
														sortMode?.order === "desc"
															? "transform rotate-180"
															: ""
													} `}
												/>
											)}
										</button>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{isFetchingResults && (
								<div className='flex justify-center col-span-4 py-3'>
									<OvalLoader height={100} width={100} />
								</div>
							)}
							{items.map(item =>
								item ? (
									<ProfileOfferRow
										nft={item}
										fields={fields}
										key={`${item?.contractAddress} ${item?.contractName} ${item?.id}`}
									/>
								) : null
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
