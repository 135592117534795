import React from "react"
import { ReactComponent as TxSuccess } from "../../../assets/media/loanInfo/txSuccess.svg"

interface TxSuccessfulProps {
	msg: string
}

export const TxSuccessful: React.FC<TxSuccessfulProps> = ({ msg }) => (
	<div className='flex flex-col justify-center items-center'>
		<TxSuccess />
		<h5 className='text-white'>All set!</h5>
		<p className='text-lightText text-center px-4'>{msg}</p>
	</div>
)
