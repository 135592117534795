/* eslint-disable import/no-unused-modules */
import React, { useState } from "react"
import { ReactComponent as ProfileAvatar } from "./../../../assets/icons/profileAvatar.svg"
import { ReactComponent as ProfileAvatarHover } from "./../../../assets/icons/profileAvatarHover.svg"
import { ReactComponent as UploadAvatar } from "./../../../assets/icons/uploadAvatar.svg"

interface ProfileAvatarProps {
	avatar?: string
}
export const ProfileAvatarComponent = ({ avatar }: ProfileAvatarProps) => {
	const [isHovered, setIsHovered] = useState(false)

	if (avatar) {
		return (
			<div
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				className='group relative w-[7.375rem] h-[7.375rem]'
			>
				{isHovered ? (
					<>
						<img
							src={avatar}
							alt='Your Description'
							className='w-full h-full rounded-lg'
						/>
						<div className='absolute inset-0 bg-[#031021]/25 flex items-center justify-center rounded-lg'>
							<UploadAvatar />
						</div>{" "}
					</>
				) : (
					<img
						src={avatar}
						alt='Your Description'
						className='w-full h-full rounded-lg'
					/>
				)}
			</div>
		)
	}

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className='cursor-pointer'
		>
			{isHovered ? <ProfileAvatarHover /> : <ProfileAvatar />}
		</div>
	)
}
