import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthStoreProp } from "../../../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import { defaultNftPageSize } from "../../../../../util/settings"
import { StorefrontActivityFilter } from "../../../../Activity/Filters/StorefrontActivityFilter"
import { ScrollToLoadMsg } from "../../../../Loaders/ScrollToLoadMsg"
import { SortDirection } from "../../../../MarketPlace/SortButton/shared"
import SortableTable, { SortColumnOrNull } from "../../../../SortableTable"
import { UserStorefrontTableFilters } from "../../../Filters/StorefrontTables/UserStorefrontTableFilters"
import { userStorefrontActivityTableFields } from "../../ActivityTable/UserStorefrontActivityFields"
import { useAllUserStorefrontActivity } from "./hooks/useAllActivity"
import { FilterBy } from "./types"
import { mapStorefrontEventToBuyerOrSeller } from "./util/mapStorefrontEventToBuyerOrSeller"
import useInfiniteScroll from "../../../../../hooks/infiniteScroll"
import { nftTypeAndIdToLocationData } from "flowty-common"

interface UserStorefrontActivityTableProps extends AuthStoreProp {
	userRole: "buyer" | "seller" | "buyerAndSeller"
}

const UserStorefrontActivityTable = ({
	authStore,
	userRole,
}: UserStorefrontActivityTableProps): JSX.Element => {
	const [prevLength, setPrevLength] = useState(0)
	const [page, setPage] = useState(1)
	const [showFilter, setShowFilter] = useState(true)
	const [filterBy, setFilterBy] = useState<FilterBy>({
		max: null,
		min: null,
		path: null,
	})

	const limit = useMemo(() => page * defaultNftPageSize, [page])

	const loggedUserAddress = authStore?.loggedUser?.addr ?? ""

	const { storefrontData, loading, dataCount } = useAllUserStorefrontActivity({
		filterBy,
		limit,
		loggedUserAddress,
		role: userRole,
	})

	const tableItems = useMemo(() => {
		return storefrontData.map(item => {
			return {
				...item,
				activityType: mapStorefrontEventToBuyerOrSeller({
					item,
					loggedUserAddress,
				}),
			}
		})
	}, [storefrontData, loggedUserAddress, mapStorefrontEventToBuyerOrSeller])

	const sort = useMemo<SortColumnOrNull>(
		() => ({
			column: "blockTimestamp",
			order: SortDirection.Descending,
		}),
		[]
	)

	const navigate = useNavigate()

	const userActivityLength = useMemo(
		() => tableItems.length,
		[tableItems.length]
	)

	const hasMoreData = useMemo(
		() => userActivityLength < dataCount,
		[userActivityLength, dataCount]
	)

	const isInfiniteScrollLoading = useMemo(
		() => prevLength === userActivityLength && hasMoreData,
		[prevLength, userActivityLength, hasMoreData]
	)

	const bottomOfPageRef = useInfiniteScroll(
		{
			isLoading: isInfiniteScrollLoading,
			onInfiniteScroll() {
				if (!loading && hasMoreData) {
					setPage(prev => prev + 1)
					setPrevLength(userActivityLength)
				}
				Mixpanel.track(`Infinite Scroll Profile Storefront Table`)
			},
		},
		[userActivityLength, isInfiniteScrollLoading, loading]
	)

	useEffect(() => {
		setPage(1)
	}, [sort, filterBy])

	useEffect(() => {
		Mixpanel.track("Profile Storefront Page Visited")
	}, [])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`Profile Storefront Page Filtered`, {
				filter: filterBy,
				user: authStore?.loggedUser,
			})
		}
	}, [filterBy.path])

	const filterComponent = useMemo(() => {
		if (userRole === "buyer" && !showFilter) {
			return (
				<UserStorefrontTableFilters
					asBuyer
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>
			)
		}
		if (userRole === "seller" && !showFilter) {
			return (
				<UserStorefrontTableFilters
					asBuyer={false}
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>
			)
		}
		if (userRole === "buyerAndSeller" && !showFilter) {
			return (
				<StorefrontActivityFilter
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					filterBy={filterBy}
					setFilterBy={setFilterBy}
				/>
			)
		}
	}, [userRole, showFilter, setShowFilter, filterBy, setFilterBy])

	return (
		<div className=' w-full flex flex-col min-h-screen'>
			{filterComponent}
			<button
				className='flex justify-center w-20 relative self-end -top-24 float-right bg-blue-500 text-white active:bg-blue-600 hover:bg-blue-600 font-bold uppercase text-sm px-6 py-4 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
				type='button'
				onClick={() => {
					setShowFilter(!showFilter)
				}}
			>
				<span>Filter</span>
			</button>
			<div className='relative -top-16 '>
				<SortableTable
					onClickRow={({ item }) => {
						const nftType =
							item.data?.nftType || item.data?.typeAndIDOffer?.nftType || ""
						const nftID = (
							item.data?.nftID ||
							item.data?.typeAndIDOffer?.nftID ||
							""
						).toString()
						const locationData =
							nftType && nftID
								? nftTypeAndIdToLocationData(nftType, nftID)
								: null

						return (
							locationData &&
							navigate(
								`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
							)
						)
					}}
					fields={userStorefrontActivityTableFields}
					items={tableItems}
					stickyHeaders
					composeRowHref={item => {
						const nftType =
							item.data?.nftType || item.data?.typeAndIDOffer?.nftType || ""
						const nftID = (
							item.data?.nftID ||
							item.data?.typeAndIDOffer?.nftID ||
							""
						).toString()
						const locationData =
							nftType && nftID
								? nftTypeAndIdToLocationData(nftType, nftID)
								: null

						return `/asset/${locationData?.contract.address}/${locationData?.contract.name}/${locationData?.resourceName}/${locationData?.nftID}`
					}}
				/>
			</div>
			{loading ? <ScrollToLoadMsg /> : null}
			{!loading && userActivityLength <= 0 ? (
				<div className='mx-auto'>No Activity</div>
			) : null}

			{!isInfiniteScrollLoading ?? (
				<>
					<ScrollToLoadMsg />
				</>
			)}

			<div className='mt-auto' ref={bottomOfPageRef} />
		</div>
	)
}

export default inject("authStore")(observer(UserStorefrontActivityTable))
