import { format } from "date-fns"
import React from "react"

interface ListingTimestampProps {
	blockTimestamp: number
	listingType: string | undefined
}

export const ListingTimestamp: React.FunctionComponent<
	ListingTimestampProps
> = ({ blockTimestamp, listingType }) => {
	if (!blockTimestamp) {
		return null
	}
	return (
		<>
			{blockTimestamp && (
				<div className='flex p-1 text-[#6C757D] text-sm h-[25px]'>
					{`Listing created on ${format(
						listingType === "fundRental" || listingType === "rental"
							? blockTimestamp * 1000
							: blockTimestamp,
						"dd MMM yyyy"
					)}`}
				</div>
			)}{" "}
		</>
	)
}
