import { inject, observer } from "mobx-react"
import React, { useEffect, useMemo, useState } from "react"
import { Oval as OvalLoader } from "react-loader-spinner"
import { useNavigate, useParams } from "react-router-dom"
import { LinkedAccounts } from "../../components/FlowUI/LinkedAccounts/LinkedAccounts"
import { UserHeader } from "../../components/FlowUI/UserHeader/UserHeader"
import { OpenSearchConnector } from "../../components/OpenSearch/OpenSearchConnector"
import { PageHeader } from "../../components/PageHeader"
import Layout from "../../layouts/Layout"
import { User } from "../../models/user"
import { getPublicAccount } from "../../services/AuthService"
import { AuthStoreProp } from "../../stores/AuthStore"
import { Err } from "../../util/Log"
import { ProfileOffers } from "./components/ProfileOffers/ProfileOffers"
import { UserActionsCenter } from "./components/UserActions/UserActionsCenter"
import { UserCollectionTab } from "./components/UserCollectionTab/UserCollectionTab"
import { TabWindow } from "./components/UserPagesTab/TabWindow"

const UserFlowTab: React.FC<AuthStoreProp> = ({ authStore }) => {
	const { walletAddress } = useParams()
	const navigate = useNavigate()
	const [profile, setProfile] = useState<User | null>(null)

	useEffect(() => {
		getPublicAccount(walletAddress || "")
			.then(userProfile =>
				setProfile({
					...userProfile,
					accountSummaries: Object.keys(
						userProfile?.childAccounts || {}
					).reduce((res, cur) => {
						return {
							...res,
							[cur]: userProfile?.childAccounts?.[cur],
						}
					}, {}),
				} as User)
			)
			.catch(err => {
				Err(err)
				navigate("/wallet-not-found")
			})
	}, [walletAddress])

	const isPublic = useMemo(() => {
		return authStore?.loggedUser?.addr !== walletAddress
	}, [authStore?.loggedUser, walletAddress])

	const tabs = useMemo(() => {
		const pageTabs = [
			{
				component: <UserCollectionTab isPublic={isPublic} profile={profile} />,
				name: "Collection",
			},
			{
				component: (
					<ProfileOffers address={`${walletAddress}`} isPublic={isPublic} />
				),
				name: "Offers",
			},
		]
		if (!isPublic) {
			pageTabs.push({
				component: <UserActionsCenter />,
				name: "Action Center",
			})
		}
		return pageTabs
	}, [isPublic])

	const metaTitle = `Flowty: ${walletAddress} NFT Account`
	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
	const metaImage = "https://www.flowty.io/icons/icon192.png"

	return (
		<>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			{profile ? (
				<OpenSearchConnector
					publicAccount={profile || null}
					endpoint='user'
					key={walletAddress}
				>
					{() => (
						<Layout>
							<div className='container'>
								{profile ? (
									<>
										<UserHeader profile={profile} />
										<div className='mt-4'>
											<LinkedAccounts user={profile} />
										</div>
									</>
								) : (
									<div className='w-full flex justify-center items-center'>
										<OvalLoader color='#04E5A3' height={100} width={100} />
									</div>
								)}

								<TabWindow tabs={tabs} address={profile?.walletAddress || ""} />
							</div>
						</Layout>
					)}
				</OpenSearchConnector>
			) : (
				<div className='w-full h-screen flex justify-center items-center'>
					<OvalLoader color='#04E5A3' height={100} width={100} />
				</div>
			)}
		</>
	)
}

export const UserFlowScreen = inject("authStore")(observer(UserFlowTab))
