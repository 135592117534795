import { useEffect, useState } from "react"

import classNames from "classnames"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { ProfileLayout } from "./ProfileLayout"
import { UserActivityTable } from "./Tables/ActivityTable"
import { UserBorrowerTable } from "./Tables/BorrowerTable"
import UserLenderTable from "./Tables/LenderTable"

const loanTabs = [
	{
		content: <UserActivityTable />,
		name: "Activity",
	},
	{
		content: <UserBorrowerTable />,
		name: "Borrower",
	},
	{
		content: <UserLenderTable />,
		name: "Lender",
	},
]

export const History = () => {
	const [currentTab, setCurrentTab] = useState(loanTabs[0].name)

	useEffect(() => {
		Mixpanel.track("Profile Activity Loans Visited", { currentTab })
	}, [currentTab])

	return (
		<>
			<ProfileLayout />
			<div className='mt-4 transition duration-700'>
				<div>
					<nav className='-mb-px flex space-x-8' aria-label='loanTabs'>
						{loanTabs.map(tab => (
							<div
								key={tab.name}
								onClick={() => {
									setCurrentTab(tab.name)
									Mixpanel.track(
										`Profile Activity Loans Toggled: ${tab.name}`,
										{
											tab: tab.name,
										}
									)
								}}
								className={classNames(
									tab.name === currentTab
										? "border-indigo-500 text-white"
										: "border-transparent text-gray-500 hover:text-white hover:border-indigo-500",
									"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
								)}
							>
								{tab.name}
							</div>
						))}
					</nav>
					<div className='line-separator bg-gradient-to-r from-darkBg via-secondary to-darkBg' />
				</div>
				<div className='mt-8 mb-3'>
					{loanTabs.map(tab => {
						if (tab.name === currentTab) {
							return <div key={tab.name}>{tab.content}</div>
						}
						return null
					})}
				</div>
			</div>
		</>
	)
}
