import { Formik } from "formik"
import { useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { cn } from "../../../util/cn"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../OpenSearch/OpenSearchConnector"
import { SerialInputsContainer } from "./SerialInputsContainer/SerialInputsContainer"

export interface SerialRangeType {
	serialNumberMax: number
	serialNumberMin: number
}

export const SerialRangeFilter: React.FC = () => {
	const { serialFilter, setSerialFilter, filters } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const location = useLocation()
	const isCollectionPage = useMemo(() => {
		return location.pathname.includes("/collection/")
	}, [location.pathname])

	const [isOpen, setIsOpen] = useState(true)
	const [disabled, setDisabled] = useState<boolean>(false)

	useEffect(() => {
		if (!isCollectionPage && !Object.keys(filters).length) {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	}, [filters])

	const chevron = useMemo(() => {
		return (
			<svg
				data-testid='serial-range-chevron'
				onClick={e => {
					e.stopPropagation()
					setIsOpen(!isOpen)
				}}
				className={cn({
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
				})}
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isOpen])

	return (
		<div
			className={cn(
				"rounded-[0.375rem] cursor-pointer border-[1px] border-neutral-400 border-opacity-25  px-[1rem] py-[0.875rem]",
				{
					"hover:bg-[#ffffff29]": !isOpen,
				}
			)}
		>
			<div
				className='flex items-center justify-between w-full'
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className='flex items-center gap-[0.5rem]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.24254 3.02985C9.77833 3.1638 10.1041 3.70673 9.97014 4.24253L9.53078 5.99999H12.4692L13.0299 3.75746C13.1638 3.22166 13.7067 2.8959 14.2425 3.02985C14.7783 3.1638 15.1041 3.70673 14.9701 4.24253L14.5308 5.99999H17C17.5523 5.99999 18 6.44771 18 6.99999C18 7.55228 17.5523 7.99999 17 7.99999H14.0308L13.0308 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H12.5308L11.9701 16.2425C11.8362 16.7783 11.2933 17.1041 10.7575 16.9701C10.2217 16.8362 9.89591 16.2933 10.0299 15.7575L10.4692 14H7.53078L6.97014 16.2425C6.83619 16.7783 6.29326 17.1041 5.75746 16.9701C5.22167 16.8362 4.89591 16.2933 5.02986 15.7575L5.46922 14H3C2.44772 14 2 13.5523 2 13C2 12.4477 2.44772 12 3 12H5.96922L6.96922 7.99999H5C4.44772 7.99999 4 7.55228 4 6.99999C4 6.44771 4.44772 5.99999 5 5.99999H7.46922L8.02986 3.75746C8.16381 3.22166 8.70674 2.8959 9.24254 3.02985ZM9.03078 7.99999L8.03078 12H10.9692L11.9692 7.99999H9.03078Z'
							fill='#CED4DA'
						/>
					</svg>
					<span className='text-[0.875rem] font-montserrat font-[600]'>
						Serial Number Range
					</span>
				</div>
				{chevron}
			</div>
			{isOpen ? (
				<>
					<div className='flex gap-2 pt-2'>
						<Formik
							initialValues={
								{
									serialNumberMax: serialFilter ? serialFilter.max : "",
									serialNumberMin: serialFilter ? serialFilter.min : "",
								} as SerialRangeType
							}
							onSubmit={() => {}}
							validate={() => {
								return {}
							}}
						>
							{({ values, setFieldValue }) => {
								return (
									<SerialInputsContainer
										values={values}
										setFieldValue={setFieldValue}
										serialFilter={serialFilter}
										setSerialFilter={setSerialFilter}
										disabled={disabled}
									/>
								)
							}}
						</Formik>
					</div>
					<div className='text-xs p-1 min-h-[21px]'>
						{" "}
						{disabled &&
							"Select at least one collection, then input range"}{" "}
					</div>
				</>
			) : null}
		</div>
	)
}
