import { Config } from "../types"

export const getDWRoyaltyScript = (config: Config) =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getDWRoyaltyScriptCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getDWRoyaltyScript(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getDWRoyaltyScript = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import RoyaltiesOverride from ${config.contractAddresses.NFTStorefrontV2}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

pub fun main(addr: Address, nftID: UInt64, identifier: String, price: UFix64): {Address: UFix64} {
	let acct = getAuthAccount(addr)
	let c = acct.borrow<&{NonFungibleToken.CollectionPublic}>(from: StoragePath(identifier: identifier)!)
			?? panic("collection not found")
	let nft = c.borrowNFT(id: nftID)
	let ti = FlowtyUtils.getTokenInfo(Type<@DapperUtilityCoin.Vault>()) ?? panic("unsupported token type")   

	let flowtyAcct = NFTStorefrontV2.getAddress()
	var paymentCuts: {Address: UFix64} = {}


	let flowtyMerchantAcct = getMerchantAddress(NFTStorefrontV2.getAddress(), ti.receiverPath, /storage/dapperUtilityCoinReceiver) 
	let mpFee = NFTStorefrontV2.getFee(p: price, t: Type<@DapperUtilityCoin.Vault>())
	if flowtyMerchantAcct != nil {
			paymentCuts.insert(key: flowtyMerchantAcct!, mpFee)
	} 
	

	if RoyaltiesOverride.get(nft.getType()) {
			return paymentCuts
	}

	let royaltiesMd = nft.resolveView(Type<MetadataViews.Royalties>())! as! MetadataViews.Royalties
	let royalties = royaltiesMd.getRoyalties()

	// for r in royalties {
	//     if let addr = getMerchantAddress(r.receiver.address, ti.receiverPath, /storage/dapperUtilityCoinReceiver) {
	//         if addr == flowtyMerchantAcct {
	//             paymentCuts[flowtyMerchantAcct!] =  mpFee + r.cut * price
	//         } else {
	//             paymentCuts[addr] = r.cut * price
	//         }
	//     }
	// }
	return paymentCuts
}


pub fun getMerchantAddress(_ addr: Address, _ publicPath: PublicPath, _ storagePath: StoragePath): Address? {
		// is there a token forwarder at this account?
		let acct = getAuthAccount(addr)

		// relink as an auth'd link for downcasting
		acct.unlink(publicPath)
		acct.link<auth &{FungibleToken.Receiver}>(publicPath, target: storagePath)

		let cap = acct.getCapability<auth &{FungibleToken.Receiver}>(publicPath)
		if !cap.check() {
				return nil
		}

		let receiver = cap.borrow()
		if receiver == nil {
				return nil
		}

		if receiver!.getType() != Type<@TokenForwarding.Forwarder>() {
				return nil
		}

		// is this a forwarder type? If it isn't a forwarder, return nil
		let forwarder = receiver! as! &TokenForwarding.Forwarder
		let nextReceiver = forwarder.safeBorrow()
		if nextReceiver == nil {
				return nil
		}

		// does it point to another token forwarder? Or does it point to the DUC vault?
		if nextReceiver!.getType() == Type<@DapperUtilityCoin.Vault>() {
				// if it points to the duc vault, return the current address
				return addr
		} else {
				// if it points to another forwarder, call getMerchantAddress of the tokenforwarder's receiver address
				return getMerchantAddress(nextReceiver!.owner!.address, publicPath, storagePath)
		}
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getDWRoyaltyScriptCrescendo = (
	config: Config
) => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import RoyaltiesOverride from ${config.contractAddresses.NFTStorefrontV2}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

access(all) fun main(addr: Address, nftID: UInt64, identifier: String, price: UFix64): {Address: UFix64} {
	let acct = getAuthAccount<auth(Storage, Capabilities) &Account>(addr)
	let c = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: StoragePath(identifier: identifier)!)
			?? panic("collection not found")
	let nft = c.borrowNFT(nftID) ?? panic("NFT not found in collection")
	let ti = FlowtyUtils.getTokenInfo(Type<@DapperUtilityCoin.Vault>()) ?? panic("unsupported token type")   

	let flowtyAcct = NFTStorefrontV2.getAddress()
	var paymentCuts: {Address: UFix64} = {}


	let flowtyMerchantAcct = getMerchantAddress(NFTStorefrontV2.getAddress(), ti.receiverPath, /storage/dapperUtilityCoinReceiver) 
	let mpFee = NFTStorefrontV2.getFee(p: price, t: Type<@DapperUtilityCoin.Vault>())
	if flowtyMerchantAcct != nil {
			paymentCuts.insert(key: flowtyMerchantAcct!, mpFee)
	} 
	

	if RoyaltiesOverride.get(nft.getType()) {
        return paymentCuts
	}

	let royaltiesMd = nft.resolveView(Type<MetadataViews.Royalties>())! as! MetadataViews.Royalties
	let royalties = royaltiesMd.getRoyalties()

	// for r in royalties {
	//     if let addr = getMerchantAddress(r.receiver.address, ti.receiverPath, /storage/dapperUtilityCoinReceiver) {
	//         if addr == flowtyMerchantAcct {
	//             paymentCuts[flowtyMerchantAcct!] =  mpFee + r.cut * price
	//         } else {
	//             paymentCuts[addr] = r.cut * price
	//         }
	//     }
	// }
	return paymentCuts
}

access(all) fun getMerchantAddress(_ addr: Address, _ publicPath: PublicPath, _ storagePath: StoragePath): Address? {
    // is there a token forwarder at this account?
    let acct = getAuthAccount<auth(Storage, Capabilities) &Account>(addr)

    let cap = acct.capabilities.get<&{FungibleToken.Receiver}>(publicPath)
    if !cap.check() {
            return nil
    }

    let receiver = cap.borrow()
    if receiver == nil {
        return nil
    }

    if receiver!.getType() != Type<@TokenForwarding.Forwarder>() {
        return nil
    }

    // is this a forwarder type? If it isn't a forwarder, return nil
    let forwarder = receiver! as! &TokenForwarding.Forwarder
    let nextReceiver = forwarder.safeBorrow()
    if nextReceiver == nil {
        return nil
    }

    // does it point to another token forwarder? Or does it point to the DUC vault?
    if nextReceiver!.getType() == Type<@DapperUtilityCoin.Vault>() {
        // if it points to the duc vault, return the current address
        return addr
    } else {
        // if it points to another forwarder, call getMerchantAddress of the tokenforwarder's receiver address
        return getMerchantAddress(nextReceiver!.owner!.address, publicPath, storagePath)
    }
}`
