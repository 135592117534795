export function getFMVHrefFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "https://www.otmnft.com/nbatopshot/articles/introducing-otm-true-value-account-valuation"
		default:
			return "404"
	}
}

export function getSourceFromSourceList(sources: string): string {
	const sourceList = sources.split(",")

	if (sourceList.length > 1) return "blended"

	return sourceList[0]
}

export function getFMVDisplayLabelFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "OTM's True Value model"
		default:
			return source
	}
}
