import React, { useMemo, useState } from "react"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { cn } from "../../../util/cn"
import { CollectionFilterCheckbox } from "./CollectionFilterCheckbox/CollectionFilterCheckbox"
import { CollectionsTrait } from "./CollectionsTrait/CollectionsTrait"

import { truncateNumber } from "../../../util/truncateValueUtil"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { useLocation } from "react-router-dom"
import { isFlowRewards } from "../../../util/settings"
import { FacetSearchBar } from "./FacetSearchbar/FacetSearchbar"

export const CollectionsFilter: React.FC = () => {
	const {
		filters,
		setFilters,
		isLoadingFacets,
		orderFilters,
		mappedFacets,
		noFilterMappedFacets,
	} = useOpenSearchContext()

	const [isOpen, setIsOpen] = useState(true)
	const [query, setQuery] = useState("")

	const location = useLocation()
	const isOnCollectionsPage = location.pathname.includes("/collection")
	const isCleanFilter =
		Object.keys(filters || {})?.length === 0 &&
		Object.keys(orderFilters || {})?.length === 0

	const isFlowRewardCollection = useMemo(() => {
		if (isOnCollectionsPage) {
			const paths = location.pathname.split("/")
			const collectionAddressParam = paths[2]
			const collectionNameParam = paths[3]
			const itemType = `A.${collectionAddressParam?.substring(
				2
			)}.${collectionNameParam}.NFT`
			return isFlowRewards(itemType)
		}
		return false
	}, [isOnCollectionsPage])

	const chevron = useMemo(() => {
		return (
			<svg
				data-testid='collection-chevron'
				className={cn({
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
				})}
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isOpen])

	const renderCollections = Object.keys(noFilterMappedFacets ?? {})
		.filter(collection => {
			if (query) {
				return collection
					.toLowerCase()
					.replace(/\s+/g, "")
					.includes(query.toLowerCase().replace(/\s+/g, ""))
			}
			return collection
		})
		.map(collection => {
			const initialResult = noFilterMappedFacets?.[collection]
			const result = mappedFacets?.[collection]

			const isChecked = !!filters[collection]

			const collectionAddress = collection.split(".")[0]
			const collectionName = collection.split(".")[1]

			const itemType = `A.${collectionAddress?.substring(
				2
			)}.${collectionName}.NFT`

			const collectionTraits =
				initialResult?.traits &&
				Object.keys(initialResult.traits)?.length &&
				Object.keys(initialResult.traits)?.map((trait: string) => {
					return (
						<CollectionsTrait
							collectionName={collectionName}
							resultMappedTrait={result?.traits?.[trait]}
							initialMappedTrait={initialResult?.traits?.[trait]}
							key={trait}
							collection={collection}
							isCollapsible
							label={trait}
							count={truncateNumber(result?.traits?.[trait]?.count)}
						/>
					)
				})

			return (
				<CollectionFilterCheckbox
					isCheckboxHidden={isOnCollectionsPage}
					name={collectionName}
					isCollapsible={!!result?.traits && !isFlowRewards(itemType)}
					count={truncateNumber(result?.count ?? 0)}
					isVerified={true}
					collection={collection}
					label={collectionName}
					key={collection}
					onChange={(newCheckedState: boolean) => {
						Mixpanel.track("Collection toggled", {
							collectionType: { [collection]: [newCheckedState] },
						})

						const copyFilters = filters ? { ...filters } : {}
						!newCheckedState
							? delete copyFilters[collection]
							: (copyFilters[collection] = {})
						setFilters(copyFilters)
					}}
					isChecked={isChecked}
				>
					<div className='space-y-1'>{collectionTraits}</div>
				</CollectionFilterCheckbox>
			)
		})

	return (
		<div
			className={cn(
				"rounded-[0.375rem] cursor-pointer border-[1px] border-neutral-400 border-opacity-25 py-[0.875rem]",
				{
					"hover:bg-[#ffffff29]": !isOpen,
				}
			)}
		>
			<div
				className='flex items-center justify-between w-full cursor-pointer px-[1rem]'
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className='flex items-center gap-[0.5rem]'>
					{isOnCollectionsPage ? (
						<>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'
							>
								<path
									d='M9.50386 1.13176C9.81129 0.956081 10.1887 0.956081 10.4961 1.13176L12.2461 2.13176C12.7257 2.40577 12.8923 3.01662 12.6182 3.49614C12.3442 3.97566 11.7334 4.14225 11.2539 3.86824L10 3.15175L8.74614 3.86824C8.26662 4.14225 7.65577 3.97566 7.38176 3.49614C7.10775 3.01662 7.27434 2.40577 7.75386 2.13176L9.50386 1.13176Z'
									fill='#CED4DA'
								/>
								<path
									d='M5.61824 4.50386C5.89225 4.98338 5.72566 5.59423 5.24614 5.86824L5.01556 6L5.24614 6.13176C5.72566 6.40577 5.89225 7.01662 5.61824 7.49614C5.34423 7.97566 4.73338 8.14225 4.25386 7.86824L4 7.72318V8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8V6C2 5.75001 2.09173 5.52145 2.24336 5.34614C2.27802 5.30603 2.31598 5.26854 2.35699 5.23411C2.40754 5.19163 2.46236 5.15405 2.52071 5.12213L4.25386 4.13176C4.73338 3.85775 5.34423 4.02434 5.61824 4.50386Z'
									fill='#CED4DA'
								/>
								<path
									d='M14.3818 4.50386C14.6558 4.02434 15.2666 3.85775 15.7461 4.13176L17.4793 5.12212C17.5376 5.15405 17.5925 5.19162 17.643 5.23411C17.8613 5.41755 18 5.69258 18 6V8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8V7.72318L15.7461 7.86824C15.2666 8.14225 14.6558 7.97566 14.3818 7.49614C14.1077 7.01662 14.2743 6.40577 14.7539 6.13176L14.9844 6L14.7539 5.86824C14.2743 5.59423 14.1077 4.98338 14.3818 4.50386Z'
									fill='#CED4DA'
								/>
								<path
									d='M7.38176 8.50386C7.65577 8.02434 8.26662 7.85775 8.74614 8.13176L10 8.84825L11.2539 8.13176C11.7334 7.85775 12.3442 8.02434 12.6182 8.50386C12.8923 8.98338 12.7257 9.59423 12.2461 9.86824L11 10.5803V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V10.5803L7.75386 9.86824C7.27434 9.59423 7.10775 8.98338 7.38176 8.50386Z'
									fill='#CED4DA'
								/>
								<path
									d='M3 11C3.55228 11 4 11.4477 4 12V13.4197L5.24614 14.1318C5.72566 14.4058 5.89225 15.0166 5.61824 15.4961C5.34423 15.9757 4.73338 16.1423 4.25386 15.8682L2.50386 14.8682C2.19229 14.6902 2 14.3589 2 14V12C2 11.4477 2.44772 11 3 11Z'
									fill='#CED4DA'
								/>
								<path
									d='M17 11C17.5523 11 18 11.4477 18 12V14C18 14.3589 17.8077 14.6902 17.4961 14.8682L15.7461 15.8682C15.2666 16.1423 14.6558 15.9757 14.3818 15.4961C14.1077 15.0166 14.2743 14.4058 14.7539 14.1318L16 13.4197V12C16 11.4477 16.4477 11 17 11Z'
									fill='#CED4DA'
								/>
								<path
									d='M7.38176 16.5039C7.65577 16.0243 8.26662 15.8577 8.74614 16.1318L9 16.2768V16C9 15.4477 9.44772 15 10 15C10.5523 15 11 15.4477 11 16V16.2768L11.2539 16.1318C11.7334 15.8577 12.3442 16.0243 12.6182 16.5039C12.8923 16.9834 12.7257 17.5942 12.2461 17.8682L10.5113 18.8596C10.3617 18.9488 10.1868 19 10 19C9.81316 19 9.63828 18.9488 9.48866 18.8596L7.75386 17.8682C7.27434 17.5942 7.10775 16.9834 7.38176 16.5039Z'
									fill='#CED4DA'
								/>
							</svg>
							<span className='text-[0.875rem] font-montserrat font-[600]'>
								Traits
							</span>
						</>
					) : (
						<>
							<svg
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5H13C13.5523 5 14 4.55228 14 4C14 3.44772 13.5523 3 13 3H7Z'
									fill='#CED4DA'
								/>
								<path
									d='M4 7C4 6.44772 4.44772 6 5 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H5C4.44772 8 4 7.55228 4 7Z'
									fill='#CED4DA'
								/>
								<path
									d='M2 11C2 9.89543 2.89543 9 4 9H16C17.1046 9 18 9.89543 18 11V15C18 16.1046 17.1046 17 16 17H4C2.89543 17 2 16.1046 2 15V11Z'
									fill='#CED4DA'
								/>
							</svg>
							<span className='text-[0.875rem] font-montserrat font-[600]'>
								Collections
							</span>
						</>
					)}
				</div>
				{chevron}
			</div>
			{isOpen ? (
				<div className='space-y-2 mt-3 max-h-[360px] overflow-y-auto px-[1rem]'>
					{isLoadingFacets && Object.keys(mappedFacets || {})?.length === 0 ? (
						<div className='flex flex-col gap-1'>
							<div className='w-full h-[44px] animate-pulse border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 bg-[#747675cb]'></div>
							<div className='w-full h-[44px] animate-pulse border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 bg-[#747675cb]'></div>
							<div className='w-full h-[44px] animate-pulse border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 bg-[#747675cb]'></div>
							<div className='w-full h-[44px] animate-pulse border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 bg-[#747675cb]'></div>
							<div className='w-full h-[44px] animate-pulse border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 bg-[#747675cb]'></div>
						</div>
					) : Object.keys(mappedFacets || {})?.length === 0 &&
					  !isFlowRewardCollection ? (
						<div className='w-full h-[44px] border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 cursor-default'>
							<p className='text-center'>
								{isOnCollectionsPage || !isCleanFilter
									? "No Filters Result"
									: "No Owned NFTs"}
							</p>
						</div>
					) : (Object.keys(mappedFacets || {})?.length > 0 &&
							Object.values(mappedFacets || {})?.[0]?.traits === undefined &&
							isOnCollectionsPage) ||
					  isFlowRewardCollection ? (
						<div className='w-full h-[44px] border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 cursor-default'>
							<p className='text-center'>No Traits Found</p>
						</div>
					) : (
						<>
							{!isOnCollectionsPage && (
								<FacetSearchBar
									value={query}
									onChange={e => setQuery(e.target.value)}
									clearSearch={() => setQuery("")}
									placeholder='Search Collections'
								/>
							)}
							<>
								{renderCollections.length
									? renderCollections
									: query && <div>No results found.</div>}
							</>
						</>
					)}
				</div>
			) : null}
		</div>
	)
}
