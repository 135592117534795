import {
	FundingAvailableData,
	nftTypeAndIdToLocationData,
	OpensearchFlowNFT,
} from "flowty-common"
import React from "react"

import { TwitterShareButton } from "./TwitterShareButton"
import { formatAmount, getDetails, ListingType } from "./utility"

function createCompletedListingTwitterHref({
	listingType,
	isMainnet,
	fundedOrder,
	nft,
}: {
	listingType: ListingType | "fundRental" | "fundLoan"
	isMainnet: boolean
	fundedOrder?: FundingAvailableData
	nft: OpensearchFlowNFT
}): string {
	const locationData = nftTypeAndIdToLocationData(
		fundedOrder?.nftType || "",
		fundedOrder?.nftID ?? ""
	)

	const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"

	const card = nft?.card
	const assetPageLink = `${listingBaseURL}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID} `

	const title = card?.title?.includes("#")
		? card?.title.replace("#", "%23")
		: card?.title

	const postDetails = `${title}${getDetails(card)}`

	switch (listingType) {
		case "fundLoan": {
			const amount = fundedOrder?.derivations.calculatedValues.marketplaceAmount

			const { paymentTokenName } = fundedOrder as FundingAvailableData

			const detail = `https://twitter.com/intent/tweet?text=I%20just%20funded%20a%20${formatAmount(
				Number(amount),
				paymentTokenName
			).trim()}%20loan%20backed%20by%20${postDetails}%20on%20@flowty_io!%0A%0A%20https://${assetPageLink}`
			return detail
		}
		default:
			return ""
	}
}

type ShareListingFundedProps = {
	isMainnet: boolean
	listingType: ListingType | "fundLoan"
	fundedOrder: FundingAvailableData
	nft?: OpensearchFlowNFT
}

export const ShareFundedListing: React.FC<ShareListingFundedProps> = ({
	isMainnet,
	listingType,
	fundedOrder,
	nft,
}) => {
	if (!nft) {
		return null
	}
	const href = createCompletedListingTwitterHref({
		fundedOrder,
		isMainnet,
		listingType,
		nft,
	})

	return <TwitterShareButton href={href} />
}
