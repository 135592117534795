import React, { useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReceivedOffersTable } from "./ReceivedOffersTable/ReceivedOffersTable"
import { MadeOffersTable } from "./MadeOffersTable/MadeOffersTable"

interface ProfileOffersProps {
	address: string
	isPublic?: boolean
}

export const ProfileOffers: React.FC<ProfileOffersProps> = ({
	address,
	isPublic = false,
}) => {
	const navigate = useNavigate()
	const { search } = useLocation()
	const query = new URLSearchParams(search)
	const defaultTab = query.get("offersTab") || 0
	const [selectedTab, setSelectedTab] = useState(Number(defaultTab))

	const tabs = useMemo(
		() => [
			{
				component: (
					<ReceivedOffersTable address={address} isPublic={isPublic} />
				),
				name: "Received",
			},
			{
				component: <MadeOffersTable address={address} isPublic={isPublic} />,
				name: "Made",
			},
		],
		[address]
	)
	const onSelectTab = (index: number): void => {
		query.set("offersTab", `${index}`)
		navigate({ search: query.toString() })
		setSelectedTab(index)
	}
	return (
		<div className='pt-4'>
			<div className='py-[11px] px-3 bg-[#606e7d] bg-opacity-20 flex items-center justify-center w-[16rem] rounded-[5px] space-x-4 mb-9'>
				{tabs.map((tab, idx) => (
					<button
						className={`py-1.5 px-3 rounded-[5px] font-bold text-base uppercase ${
							selectedTab === idx ? "bg-primary text-black" : "text-lightText"
						}`}
						key={tab.name}
						onClick={() => onSelectTab(idx)}
					>
						{tab.name}
					</button>
				))}
			</div>
			{tabs[selectedTab].component}
		</div>
	)
}
