import { Formik, FormikHelpers } from "formik"
import React, { useCallback, useState } from "react"
import { CreatorHubModal } from "./components/CreatorHubModal/CreatorHubModal"
import { CreatorHubWrapper } from "./components/CreatorHubWrapper"
import { removeSpecialChars } from "./components/FormView/components/ConfirmationFormStep/components/CollectionConfirmationFormStep"
import { FlowtyCreatorHubContextProvider } from "./contexts/FlowtyCreatorHubContext"
import { useFormTab } from "./hooks/useFormTab"
import { FormTabs } from "./types/FlowtyCreatorHubContextTypes"
import {
	AccountContracts,
	creatorHubValidationSchemas,
	FlowtyCreatorHubFormValues,
	FlowtyCreatorHubProps,
} from "./types/FlowtyCreatorHubTypes"
import { getCreatorHubCachedValues } from "./utils/creatorHubCacheFn"
import { creatorHubInitialValues } from "./utils/formInitialValues"

export const FlowtyCreatorHub: React.FunctionComponent<
	FlowtyCreatorHubProps
> = ({
	flowty,
	flowtyMarketplaceFee,
	mixPanelFn,
	strapiUrl,
	spotPrice,
	loggedUser,
	logOutFn,
	loggedIsDapper,
	creatorHubId,
	uploadIPFSImage,
	promotion,
}) => {
	const { formTab, changeSelectedFormTab, moveToNextTab, moveToPreviousTab } =
		useFormTab()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const cachedValues = getCreatorHubCachedValues()

	const openModal = (): void => {
		setIsModalOpen(true)
	}

	const handleFormikSubmit = useCallback(
		async (
			values: FlowtyCreatorHubFormValues,
			formikProps: FormikHelpers<FlowtyCreatorHubFormValues>
		) => {
			if (
				loggedUser &&
				values.creatorWalletAddress === "Sign in to view wallet address"
			) {
				formikProps.setFieldValue("creatorWalletAddress", loggedUser)
			}

			if (values.dropStartDateTime?.isNow) {
				const timeNow = new Date(Date.now())
				formikProps.setFieldValue("dropStartDateTime", {
					dateTime: timeNow.getTime(),
					isNow: true,
				})
			}

			if (formTab === FormTabs.CustomizeCollectionFirstTab) {
				if (!values.nftThumbnailImage?.file) {
					formikProps.setFieldValue("nftThumbnailImage", values.nftImage)
				}
				if (!values.dropThumbnailImage?.file) {
					formikProps.setFieldValue("dropThumbnailImage", values.nftImage)
				}
			}

			if (formTab !== FormTabs.ConfirmationTab) {
				if (
					loggedUser &&
					formTab === FormTabs.CustomizeCollectionFirstTab &&
					!process.env.IS_STORYBOOK
				) {
					const collections = (await flowty.scripts.getAccountCollections(
						loggedUser ?? ""
					)) as AccountContracts

					const normalizeName = (name: string): string => {
						if (!name) return ""
						return removeSpecialChars(name.toLowerCase())
					}

					const isDuplicateName = collections?.contracts.some(contract => {
						const contractName = contract?.name
						const normalizedInputName = normalizeName(values.collectionName)

						return contractName === normalizedInputName
					})

					if (isDuplicateName) {
						formikProps.setFieldError(
							"collectionName",
							"You have already created a collection with this name"
						)
						return
					}
				}

				if (
					formTab === FormTabs.CustomizeCollectionSecondTab &&
					!Boolean(values.dropStartDateTime?.dateTime)
				) {
					const timeNow = new Date(Date.now())

					formikProps.setFieldValue("dropStartDateTime", {
						dateTime: timeNow.getTime(),
						isNow: true,
					})
				}

				moveToNextTab()
				formikProps.setTouched({})
				return
			}

			openModal()
		},
		[formTab]
	)

	return (
		<Formik
			initialValues={
				cachedValues ??
				creatorHubInitialValues(loggedUser ?? "Sign in to view wallet address")
			}
			onSubmit={handleFormikSubmit}
			validationSchema={creatorHubValidationSchemas[formTab]}
			validateOnBlur={false}
			validateOnMount={false}
		>
			{({
				values,
				resetForm,
				errors,
				handleSubmit,
				touched,
				setFieldError,
			}) => {
				return (
					<FlowtyCreatorHubContextProvider
						resetForm={resetForm}
						spotPrice={spotPrice}
						values={values}
						strapiUrl={strapiUrl}
						mixPanelFn={mixPanelFn}
						loggedUser={loggedUser}
						errors={errors}
						formTab={formTab}
						flowtyMarketplaceFee={flowtyMarketplaceFee}
						changeSelectedFormTab={changeSelectedFormTab}
						moveToNextTab={moveToNextTab}
						moveToPreviousTab={moveToPreviousTab}
						promotion={promotion}
						handleSubmit={handleSubmit}
						uploadIPFSImage={uploadIPFSImage}
						setFieldError={setFieldError}
						touched={touched}
					>
						<CreatorHubWrapper />
						{isModalOpen && (
							<CreatorHubModal
								isOpen={isModalOpen}
								onCloseModal={() => setIsModalOpen(false)}
								flowty={flowty}
								isLoggedUser={Boolean(loggedUser)}
								creatorHubId={creatorHubId}
								loggedIsDapper={loggedIsDapper}
								logOutFn={logOutFn}
								mixPanelFn={mixPanelFn}
								changeSelectedFormTab={changeSelectedFormTab}
								resetForm={resetForm}
								uploadImageFn={uploadIPFSImage}
								values={values}
							/>
						)}
					</FlowtyCreatorHubContextProvider>
				)
			}}
		</Formik>
	)
}
