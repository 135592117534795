const DAPPER_LOGO_URL =
	"https://storage.googleapis.com/flowty-images/tokens/dapper_logo.png"
const BLOCTO_LOGO_URL =
	"https://storage.googleapis.com/flowty-images/tokens/blocto_logo.png"
const FLOW_REF_WALLET_LOGO_URL =
	"https://storage.googleapis.com/flowty-images/tokens/flow-reference-wallet.png"

export function getProviderImage(walletServiceUid: string): string {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return DAPPER_LOGO_URL
	if (uid.includes("blocto")) return BLOCTO_LOGO_URL
	if (uid.includes("fcw")) return FLOW_REF_WALLET_LOGO_URL
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return FLOW_REF_WALLET_LOGO_URL

	return ""
}

export const getProviderName = (walletServiceUid: string): string => {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return "Dapper"
	if (uid.includes("blocto")) return "Blocto"
	if (uid.includes("fcw")) return "Flow Reference Wallet"
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return "Flow Reference Wallet"

	return ""
}
