interface Props {
	type: "LISTINGS" | "DROP"
}

export const PreviewPageTab: React.FunctionComponent<Props> = ({ type }) => {
	return (
		<div data-testid='preview-page-tab'>
			<div className='w-full border-b-2 border-b-[#212529] text-white'>
				<div className='flex items-center h-[32px]'>
					<div
						className={`w-[80px] 3xl:w-[100px] text-center font-bold 
                        ${
													type === "LISTINGS"
														? "3xl:pb-1 border-b-4 border-b-white"
														: "pb-1 3xl:pb-2"
												}
                        text-[10px] 3xl:text-[14px]`}
					>
						LISTINGS
					</div>
					<div className='w-[80px] 3xl:w-[100px] text-center font-bold pb-1 3xl:pb-2 text-[10px] 3xl:text-[14px]'>
						ACTIVITY
					</div>
					<div
						className={`w-[80px] 3xl:w-[100px] text-center font-bold ${
							type === "DROP"
								? "3xl:pb-1 border-b-4 border-b-white"
								: "pb-1 3xl:pb-2"
						} text-[10px] 3xl:text-[14px]`}
					>
						DROP
					</div>
				</div>
			</div>
		</div>
	)
}
