import { FCLTransactionResult } from "flowty-common"
import {
	Err,
	expirationDaysToSeconds,
	getCatalogEntryForType,
	sendMutation,
	termNumberToSeconds,
} from "../../utils/Utils"
import { CreateRentListingProps, RemoveRentListing } from "./RentListingTypes"
import { Config } from "../../types"
import {
	getCreateRentalListingTxn,
	getDelistRentalListingTxn,
} from "./transactions"
import { PaymentTokenToIdentifier } from "../../utils/TokensIdentifier"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class RentListing {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	createRentListing = async ({
		nftData,
		rentalFee,
		rentalDeposit,
		rentalTerm,
		token,
		renterAddress,
		rentalExpiresAfterDays,
		nftProviderPathIdentifier,
		ftReceiverAddress,
		txAvailableCallback,
	}: CreateRentListingProps): Promise<FCLTransactionResult> => {
		if (!nftData) throw new Error("Flow NFT it's null")
		const { id, type, nftOwner } = nftData

		const txContent = getCreateRentalListingTxn(this.config, token, nftData)
		const termNumber = Number(rentalTerm)
		const termInSeconds = termNumberToSeconds(termNumber)

		const expiresAfter: number = expirationDaysToSeconds(rentalExpiresAfterDays)
		const catalogIdentifier = await getCatalogEntryForType(type, this.config)

		const nftProviderArg = this.config.crescendo
			? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
			: fcl.arg(nftProviderPathIdentifier, t.String)

		const txArguments = [
			fcl.arg(id.toString(), t.UInt64),
			fcl.arg(rentalFee.toFixed(2), t.UFix64),
			fcl.arg(rentalDeposit.toFixed(2), t.UFix64),
			fcl.arg(termInSeconds.toFixed(1), t.UFix64),
			fcl.arg(expiresAfter.toFixed(2), t.UFix64),
			fcl.arg(
				renterAddress === "" ? null : renterAddress,
				t.Optional(t.Address)
			),
			fcl.arg(nftOwner, t.Address),
			nftProviderArg,
			fcl.arg(catalogIdentifier, t.String),
			fcl.arg(ftReceiverAddress, t.Address),
		]

		if (this.config.crescendo) {
			txArguments.push(
				fcl.arg(PaymentTokenToIdentifier[token.symbol], t.String)
			)
		}

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}

	removeRentListing = async ({
		listingResourceID,
		txAvailableCallback,
	}: RemoveRentListing): Promise<FCLTransactionResult> => {
		const txArguments = [fcl.arg(listingResourceID.toString(), t.UInt64)]
		const txContent = getDelistRentalListingTxn(this.config)

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)

			return res
		} catch (e) {
			console.log("TX ERROR", e)
			throw e
		}
	}
}
