import { actions } from "./Mixpanel"

declare global {
	interface Navigator {
		standalone?: boolean
	}
}

export const trackPWALaunch = (): void => {
	const navigator = window.navigator
	if (
		navigator.standalone ||
		window.matchMedia("(display-mode: standalone)").matches
	) {
		const isiOS = /iphone|ipad|ipod/.test(
			window.navigator.userAgent.toLowerCase()
		)
		const isAndroid = /android/.test(window.navigator.userAgent.toLowerCase())
		const device = isiOS ? "iOS" : isAndroid ? "android" : "desktop"
		actions.track("PWA Launched", { device })
	}
}
