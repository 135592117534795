import { Trait } from "flowty-common"
import { useMemo } from "react"
import { FacetTrait } from "../components/OpenSearch/types"

interface UseSortTraitsProps {
	traits: Trait[]
	isLoading: boolean
	collectionTraits: FacetTrait[] | undefined
}

export const useSortTraits = ({
	traits,
	isLoading,
	collectionTraits,
}: UseSortTraitsProps): Trait[] => {
	const sortedTraits: Trait[] = useMemo(() => {
		if (!isLoading) {
			const matchingTraits = traits.filter(trait =>
				collectionTraits?.some(
					(collectionTrait: { name: string }) =>
						collectionTrait.name === trait.name
				)
			)

			const remainingTraits = traits.filter(
				trait =>
					!collectionTraits?.some(
						(collectionTrait: { name: string }) =>
							collectionTrait.name === trait.name
					)
			)

			matchingTraits.sort((a, b) => a.name.localeCompare(b.name))
			remainingTraits.sort((a, b) => a.name.localeCompare(b.name))

			return [...matchingTraits, ...remainingTraits]
		}

		return []
	}, [traits, collectionTraits, isLoading])

	return sortedTraits
}
