import { useEffect, useState } from "react"
import { db } from "../../../../firebase"
import { Tooltip } from "react-tooltip"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { DiscordIcon } from "../../../../screens/CollectionPage/components/SocialLinks/DiscordIcon"

interface DiscordProviderDocument {
	username: string
	id: string
}

export const DiscordIntegration: React.FC<{ walletAddress: string }> = ({
	walletAddress,
}) => {
	const [discordHandle, setDiscordHandle] = useState<string | null>(null)

	useEffect(() => {
		if (walletAddress) {
			db.doc(`/accounts/${walletAddress}/providers/discord`)
				.get()
				.then(discordDoc => {
					if (discordDoc.exists) {
						setDiscordHandle(
							(discordDoc.data() as DiscordProviderDocument).username
						)
					}
				})
				.catch(e => {
					Mixpanel.track("ERROR_FIREBASE_FETCH_DISCORD", {
						e,
					})
				})
		}
	}, [walletAddress])

	if (discordHandle) {
		return (
			<div>
				<Tooltip
					id={`view-discord-profile-tooltip`}
					style={{
						backgroundColor: "white",
						color: "#031021",
						fontSize: "0.75rem",
					}}
				/>
				<div
					data-tooltip-id={`view-discord-profile-tooltip`}
					data-tooltip-content={`View Discord Profile`}
					data-tooltip-place='right'
				>
					<DiscordIcon link={`https://discordapp.com/users/${discordHandle}`} />
				</div>
			</div>
		)
	}

	return <></>
}
