import { ReactComponent as WarningIcon } from "../../../../assets/warning.svg"

export const ConfirmCollection = () => {
	return (
		<div className='flex flex-col space-y-10'>
			<div className='text-[#DEE2E6]'>
				This will finalize the creation of your NFT collection on the Flow
				blockchain. Please ensure that all details are correct before
				proceeding. Once submitted, you will not be able to edit the collection
				or minting details.
			</div>
			<div className='text-[#FF6E25] px-3 py-2 flex space-x-3 items-center bg-[#ffffff14] rounded-lg'>
				<div>
					<WarningIcon />
				</div>
				<div className='text-sm'>
					Once you submit your collection, you will no longer be able to make
					any edits or changes
				</div>
			</div>
		</div>
	)
}
