import { Config } from "../types"

export const getFlowtyWrappedIpfsScript = (config: Config): string => {
	if (config.crescendo) {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return getIpfsLinkScriptCrescendo(config)
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	return getIpfsLinkScript(config)
}

const getIpfsLinkScript = (config: Config): string => `
import FlowtyWrapped from ${config.contractAddresses.FlowtyWrapped}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

pub fun main(addr: Address): AnyStruct {
		if(!getAccount(addr).getCapability<&{FlowtyWrapped.FlowtyWrappedCollectionPublic}>(FlowtyWrapped.CollectionPublicPath).check()) {
				return false
		}

		let cap = getAccount(addr).getCapability<&{FlowtyWrapped.FlowtyWrappedCollectionPublic}>(FlowtyWrapped.CollectionPublicPath).borrow() ?? panic("Could not borrow capability from public collection")
		if(cap.getIDs().length == 0) {
				return false
		}

		let id = cap.getIDs()[0]
		let wrappedRef = cap.borrowFlowtyWrapped(id: id)!
		let medias = wrappedRef.resolveView(Type<MetadataViews.Medias>())! as! MetadataViews.Medias

		let ipfsMedia = medias.items[0]
		return ipfsMedia.file.uri()
}`

const getIpfsLinkScriptCrescendo = (config: Config): string => `
import FlowtyWrapped from ${config.contractAddresses.FlowtyWrapped}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

access(all) fun main(addr: Address): AnyStruct {
    if(!getAccount(addr).capabilities.get<&{FlowtyWrapped.FlowtyWrappedCollectionPublic}>(FlowtyWrapped.CollectionPublicPath).check()) {
        return false
    }

    let cap = getAccount(addr).capabilities.get<&{FlowtyWrapped.FlowtyWrappedCollectionPublic}>(FlowtyWrapped.CollectionPublicPath).borrow() ?? panic("Could not borrow capability from public collection")
    if(cap.getIDs().length == 0) {
        return false
    }
    let id = cap.getIDs()[0]
    let wrappedRef = cap.borrowFlowtyWrapped(id: id)!
    let medias = wrappedRef.resolveView(Type<MetadataViews.Medias>())! as! MetadataViews.Medias

    let ipfsMedia = medias.items[0]
    return ipfsMedia.file.uri()
}`
