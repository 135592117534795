import React from "react"

interface MobileSidebarDrawerProps {
	children?: React.ReactNode
	sidebarNavigation: string
}

// eslint-disable-next-line import/no-unused-modules
export const MobileSidebarDrawer: React.FC<MobileSidebarDrawerProps> = ({
	sidebarNavigation,
	children,
}) => {
	return (
		<div
			className='overlay flex min-h-full flex-col overflow-y-auto bg-[#04070B] backdrop-blur-[100px] backdrop-opacity-80 shadow-xl'
			style={{ width: sidebarNavigation }}
		>
			<div className='relative flex-1 px-4'>
				<div className='absolute inset-0 flex flex-col'>{children}</div>
			</div>
		</div>
	)
}
