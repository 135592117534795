import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import {
	nftTypeAndIdToLocationData,
	OpensearchFlowNFT,
	Order,
} from "flowty-common"
import React, { Fragment, useState } from "react"
import { Tooltip } from "react-tooltip"
import { FlowtyNftCard } from "../../../FlowtyNftCard"
import { ValuationData } from "../../../Types/GlobalTypes"
import { ListingOrderDataType } from "../../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"
import { PurchaseOrderDataType } from "../../contexts/FlowtyPurchaseModalContext/types/PurchaseModalContextTypes"
import {
	FlowtyModalListingType,
	FlowtyModalNavBtnProps,
} from "../../types/FlowtyModalTypes"
import BackArrow from "./BackArrow"
import { ListingTimestamp } from "./ListingTimestamp"
import { ListingTitle } from "./ListingTitle"
import { ModalNav } from "./ModalNav"
import { NftCardOrderDisplay } from "./NftCardOrderDisplay/NftCardOrderDisplay"

interface ModalWrapperProps {
	isOpen: boolean
	isDapper?: boolean
	isLostAndFound?: boolean
	blendedLTV?: number | null
	openSearchFlowNFT: OpensearchFlowNFT
	hasProvider: boolean
	orderContent: React.ReactNode
	collectionImage: string | null
	isPurchase?: boolean
	valuationData: ValuationData
	isLoadingValuation?: boolean
	onCloseModal: () => void
	listingType?: FlowtyModalListingType
	resetModal?: () => void
	closeBtnProps: FlowtyModalNavBtnProps
	confirmBtnProps: FlowtyModalNavBtnProps
	orderData?: ListingOrderDataType | PurchaseOrderDataType
	isLoadingMakeOffer?: boolean
	isLoadingOrders?: boolean
	sealedMakeOffer?: boolean
	isSingleOffer?: boolean
	isDelist?: boolean
	isMainnet?: boolean
	mixPanelFn: (event: string, data: unknown) => void
}

export const ModalWrapper: React.FunctionComponent<ModalWrapperProps> = ({
	isOpen,
	isDapper,
	isLostAndFound,
	collectionImage,
	blendedLTV,
	openSearchFlowNFT,
	hasProvider,
	orderContent,
	isPurchase = false,
	valuationData,
	onCloseModal,
	listingType,
	resetModal,
	closeBtnProps,
	confirmBtnProps,
	orderData,
	isLoadingMakeOffer,
	isLoadingOrders,
	sealedMakeOffer,
	isSingleOffer,
	isDelist,
	isLoadingValuation,
	isMainnet,
	mixPanelFn,
}) => {
	const bodyClass = isPurchase
		? "flex-col lg:flex-row-reverse lg:justify-start"
		: "flex-col lg:flex-row"

	const middleBorder = isPurchase ? "lg:border-l" : "lg:border-r"

	const [copied, setCopied] = useState(false)

	const resetCopy = (): void => setCopied(false)

	const locationData = nftTypeAndIdToLocationData(
		openSearchFlowNFT.type,
		openSearchFlowNFT.id
	)

	const handleCopyClick = (): void => {
		;(navigator as Navigator).clipboard.writeText(
			`${window.location.origin}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
		)
		setCopied(true)
		setTimeout(resetCopy, 3000)
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1000]' onClose={onCloseModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div
					data-testid='flowty-listing-modal'
					className='fixed max-md:h-full max-md:overflow-hidden inset-0 overflow-y-auto'
				>
					<div className='flex min-h-full items-center justify-center text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='modal-height transform md:rounded-xl bg-[#04070B] text-left align-middle shadow-xl transition-all w-full md:w-[33rem] lg:w-[65rem] max-md:min-h-[100svh] max-md:h-[100svh] md:border border-[#495057] max-md:flex max-md:flex-col'>
								<Dialog.Title
									as='div'
									className='border-b border-[#495057] px-[40px] py-[21px] md:py-8 w-full flex justify-between items-center'
								>
									<div className='flex items-center gap-[24px]'>
										{listingType === "makeOffer" &&
											!isSingleOffer &&
											!isLoadingMakeOffer &&
											!sealedMakeOffer && (
												<BackArrow onClick={() => resetModal && resetModal()} />
											)}
										<ListingTitle
											listingType={listingType}
											nftName={openSearchFlowNFT?.card?.title || ""}
											isPurchase={isPurchase}
											hasProvider={hasProvider}
											isDelist={isDelist}
											isLostAndFound={isLostAndFound}
										/>
									</div>

									<XIcon
										data-testid='close-modal-icon'
										onClick={onCloseModal}
										className='w-6 h-6 md:h-8 md:w-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div
									className={`flex ${bodyClass} w-full max-md:flex-grow max-md:overflow-y-scroll max-md:overflow-x-hidden`}
								>
									<div
										className={`w-full md:h-auto ${middleBorder} border-[#495057] flex-col flex px-[40px] py-[24px] items-center`}
									>
										<div className='flex w-full h-full flex-col'>
											{orderContent}
										</div>
									</div>
									<div
										className={`w-full bg-[#04070B] ${
											(orderData as Order)?.blockTimestamp ||
											(isLoadingOrders && listingType !== "makeOffer")
												? "px-[40px] md:px-[24px] pt-[24px] pb-0 md:pt-[21px] md:pb-0 min-w-[0px]"
												: "px-[40px] md:px-[24px] py-[24px] md:pt-[21px] md:pb-[25px]"
										} md:px-[40px] flex justify-center items-center flex-col`}
									>
										<div className='flex justify-between items-center w-full pb-4'>
											<span className='text-[16px] font-montserrat font-semibold text-neutral-500'>
												{listingType === "transfer" ||
												listingType === "lostAndFound"
													? "NFT Preview"
													: isPurchase
													? "Listing"
													: "Listing Preview"}
											</span>
											<div className='flex gap-2'>
												<Tooltip
													id={`copyUrl`}
													style={{
														backgroundColor: "#F8F9FA",
														color: "#04070B",
														zIndex: 50,
													}}
													className='text-sm font-medium'
												/>
												<div
													data-tooltip-id={`copyUrl`}
													data-tooltip-content={"Copy URL"}
													data-tooltip-place='bottom'
													onClick={handleCopyClick}
													className={`p-2 ${"border-[#7C7C7C]"} border-[0.3px] border-opacity-80 rounded bg-[#FFFFFF14] hover:bg-[#ffffff33] cursor-pointer`}
												>
													{copied ? (
														<svg
															xmlns='http://www.w3.org/2000/svg'
															fill='none'
															viewBox='0 0 24 24'
															strokeWidth={1.5}
															stroke='currentColor'
															className='w-[16px] h-[16px] text-primary'
														>
															<path
																strokeLinecap='round'
																strokeLinejoin='round'
																d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75'
															/>
														</svg>
													) : (
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 16 16'
															fill='none'
														>
															<path
																d='M10.0687 3.6686C10.6936 3.04377 11.7066 3.04377 12.3315 3.6686C12.9563 4.29344 12.9563 5.30651 12.3315 5.93135L9.93145 8.33135C9.30661 8.95619 8.29355 8.95619 7.66871 8.33135C7.35629 8.01893 6.84976 8.01893 6.53734 8.33135C6.22492 8.64377 6.22492 9.1503 6.53734 9.46272C7.78702 10.7124 9.81315 10.7124 11.0628 9.46272L13.4628 7.06272C14.7125 5.81304 14.7125 3.78691 13.4628 2.53723C12.2131 1.28756 10.187 1.28756 8.93734 2.53723L7.73734 3.73723C7.42492 4.04965 7.42492 4.55619 7.73734 4.8686C8.04976 5.18102 8.55629 5.18102 8.86871 4.8686L10.0687 3.6686Z'
																fill='#F8F9FA'
															/>
															<path
																d='M6.06873 7.6686C6.69356 7.04377 7.70663 7.04377 8.33147 7.6686C8.64389 7.98102 9.15042 7.98102 9.46284 7.6686C9.77526 7.35618 9.77526 6.84965 9.46284 6.53723C8.21316 5.28756 6.18703 5.28756 4.93736 6.53723L2.53736 8.93723C1.28768 10.1869 1.28768 12.213 2.53736 13.4627C3.78703 14.7124 5.81316 14.7124 7.06284 13.4627L8.26284 12.2627C8.57526 11.9503 8.57526 11.4438 8.26284 11.1313C7.95042 10.8189 7.44389 10.8189 7.13147 11.1313L5.93147 12.3313C5.30663 12.9562 4.29357 12.9562 3.66873 12.3313C3.04389 11.7065 3.04389 10.6934 3.66873 10.0686L6.06873 7.6686Z'
																fill='#F8F9FA'
															/>
														</svg>
													)}
												</div>
												<a
													target='_blank'
													rel='noreferrer'
													href={`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`}
												>
													<Tooltip
														id={`moreInfo`}
														style={{
															backgroundColor: "#F8F9FA",
															color: "#04070B",
															zIndex: 50,
														}}
														className='text-sm font-medium'
													/>
													<div
														data-tooltip-id={`moreInfo`}
														data-tooltip-content={"More Info"}
														data-tooltip-place='bottom'
														className='p-2 border-[0.3px] border-[#7C7C7C] border-opacity-80 rounded bg-[#FFFFFF14] hover:bg-[#ffffff33] cursor-pointer'
													>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 16 16'
															fill='none'
														>
															<path
																d='M8.0001 1.59998C7.55827 1.59998 7.2001 1.95815 7.2001 2.39998V3.19998C7.2001 3.6418 7.55827 3.99998 8.0001 3.99998C8.44192 3.99998 8.8001 3.6418 8.8001 3.19998V2.39998C8.8001 1.95815 8.44192 1.59998 8.0001 1.59998Z'
																fill='#F8F9FA'
															/>
															<path
																fillRule='evenodd'
																clipRule='evenodd'
																d='M3.2001 3.19998H5.6001C5.6001 4.52546 6.67461 5.59998 8.0001 5.59998C9.32558 5.59998 10.4001 4.52546 10.4001 3.19998H12.8001C13.6838 3.19998 14.4001 3.91632 14.4001 4.79998V12C14.4001 12.8836 13.6838 13.6 12.8001 13.6H3.2001C2.31644 13.6 1.6001 12.8836 1.6001 12V4.79998C1.6001 3.91632 2.31644 3.19998 3.2001 3.19998ZM5.2001 8.79998C5.86284 8.79998 6.4001 8.26272 6.4001 7.59998C6.4001 6.93723 5.86284 6.39998 5.2001 6.39998C4.53736 6.39998 4.0001 6.93723 4.0001 7.59998C4.0001 8.26272 4.53736 8.79998 5.2001 8.79998ZM7.16048 12C7.18672 11.8707 7.20049 11.737 7.20049 11.6C7.20049 10.4954 6.30506 9.59998 5.20049 9.59998C4.09592 9.59998 3.20049 10.4954 3.20049 11.6C3.20049 11.737 3.21426 11.8707 3.2405 12H7.16048ZM9.6001 7.19998C9.15827 7.19998 8.8001 7.55815 8.8001 7.99998C8.8001 8.4418 9.15827 8.79998 9.6001 8.79998H12.0001C12.4419 8.79998 12.8001 8.4418 12.8001 7.99998C12.8001 7.55815 12.4419 7.19998 12.0001 7.19998H9.6001ZM8.8001 10.4C8.8001 9.95815 9.15827 9.59998 9.6001 9.59998H11.2001C11.6419 9.59998 12.0001 9.95815 12.0001 10.4C12.0001 10.8418 11.6419 11.2 11.2001 11.2H9.6001C9.15827 11.2 8.8001 10.8418 8.8001 10.4Z'
																fill='#F8F9FA'
															/>
														</svg>
													</div>
												</a>
											</div>
										</div>

										<FlowtyNftCard
											collectionImage={collectionImage}
											listingType={listingType}
											nft={openSearchFlowNFT}
											valuationData={valuationData}
											hasProvider={hasProvider}
											isMainnet={isMainnet}
											mixPanelFn={mixPanelFn}
										>
											<NftCardOrderDisplay
												isLostAndFound={isLostAndFound}
												isTransfer={listingType === "transfer"}
												isDapper={isDapper}
												order={orderData as Order}
												isLoading={false}
												nft={openSearchFlowNFT}
												valuationData={valuationData}
												blendedLTV={blendedLTV}
												isLoadingValuation={isLoadingValuation}
												isLoadingOrders={isLoadingOrders}
											/>
										</FlowtyNftCard>
										{isLoadingOrders && listingType !== "makeOffer" ? (
											<div className='flex p-1 text-[#6C757D] text-sm h-[25px]'>
												<div className='w-[200px] h-[18px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
											</div>
										) : (
											<>
												{(orderData as Order)?.blockTimestamp &&
													!isLoadingOrders && (
														<ListingTimestamp
															blockTimestamp={Number(
																(orderData as Order)?.blockTimestamp
															)}
															listingType={listingType}
														/>
													)}
											</>
										)}
									</div>
								</div>
								<div className='flex flex-col lg:flex-row w-full items-center justify-between gap-[24px] md:gap-6 px-[40px] md:px-0 py-8 border-t border-[#495057]'>
									<div
										className={`w-auto hidden lg:flex gap-2 items-center lg:pl-[40px]`}
									>
										<p className='text-[#ADB5BD] font-medium text-[16px]'>
											Need Help?
										</p>
										<a
											href='https://discord.gg/Fpv5sDnHWS'
											target='_blank'
											rel='noreferrer'
											className='text-white text-[16px] font-semibold underline cursor-pointer'
										>
											Contact Us
										</a>
									</div>
									<div
										className={`w-full ${
											isPurchase ? "md:w-[480px]" : "md:w-[429px]"
										} `}
									>
										<ModalNav
											closeBtnProps={closeBtnProps}
											confirmBtnProps={confirmBtnProps}
										/>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
