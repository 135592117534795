import * as fcl from "@onflow/fcl"

export const connectWallet = async (
	loadingCallbackFn?: (value: boolean) => void
): Promise<void> => {
	if (loadingCallbackFn !== undefined || loadingCallbackFn === null) {
		loadingCallbackFn(true)
	}

	const keys = await fcl.authenticate()

	const { addr, cid, loggedIn, expiresAt, services } = keys

	if (
		loadingCallbackFn !== undefined &&
		addr === null &&
		cid === null &&
		!loggedIn &&
		expiresAt === null &&
		services.length === 0
	) {
		loadingCallbackFn(false)
	}
}
