export const ScrollToLoadMsg = ({ show = true }: { show?: boolean }) => (
	<div className='h-10 w-full flex justify-center items-center'>
		{show && (
			<h2 className='justify-center items-center flex flex-row animate-pulse'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					className='w-6 h-6 mr-2'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3'
					/>
				</svg>
				Scroll down to load more
			</h2>
		)}
	</div>
)
