import {
	FundingAvailableData,
	ListingAvailableData,
	ListingRentedData,
} from "flowty-common"
import { firestore } from "../../../firebase"

interface LoanBorrowerSubscribeProps {
	userAddress: string
	callbackFn: (data: ListingAvailableData[]) => void
}

interface RentalBorrowerSubscribeProps {
	userAddress?: string
	userAddresses?: string[]
	callbackFn: (data: ListingRentedData[]) => void
}

export const subscribeToLoanBorrower = async ({
	userAddress,
	callbackFn,
}: LoanBorrowerSubscribeProps): Promise<void> => {
	const loanBorrowerQuery = firestore
		.collection("listingAvailable")
		.where("flowtyStorefrontAddress", "==", userAddress)
		.where("state", "==", "FUNDED")

	await loanBorrowerQuery.onSnapshot(async ({ docs }) => {
		const resultDocs = docs.map(doc => doc.data() as ListingAvailableData)
		callbackFn(resultDocs)
	})
}

export const getLoanFundingId = async (
	listingResourceID: string
): Promise<string> => {
	const loanBorrowerQuery = firestore
		.collection("fundingAvailable")
		.where("listingResourceID", "==", listingResourceID)

	const result = await loanBorrowerQuery.get()
	const docs = result.docs.map(doc => doc.data() as FundingAvailableData)
	return docs[0].fundingResourceID
}

export const subscribeToRentalBorrower = async ({
	userAddresses,
	callbackFn,
}: RentalBorrowerSubscribeProps): Promise<void> => {
	const rentalBorrowerQuery = firestore
		.collection("listingRented")
		.where("renterAddress", "in", userAddresses)
		.where("returned", "==", false)
		.where("settled", "==", false)

	await rentalBorrowerQuery.onSnapshot(async ({ docs }) => {
		const resultDocs = docs.map(doc => doc.data() as ListingRentedData)
		callbackFn(resultDocs)
	})
}
