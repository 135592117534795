import { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { actions as Mixpanel } from "../util/Mixpanel"
import Layout from "../layouts/Layout"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// @ts-ignore-next-line
import termsPDF from "../assets/flowty-service-terms.pdf"
import { PageHeader } from "../components/PageHeader"

export const TermsScreen = () => {
	useEffect(() => {
		Mixpanel.track("Terms Page Visited")
	}, [])

	const [numberPages, setNumPages] = useState<number | null>(null)

	const metaTitle = "Flowty: Terms of Service"
	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
	const metaImage = "https://www.flowty.io/icons/icon192.png"

	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<section className='container'>
				<div className='mt-5 mb-4 pt-4'>
					<h1 className='pt-3 text-3xl text-center'>Terms of Service</h1>
					<br />
					<object
						data='https://storage.googleapis.com/flowty-docs/flowty-service-terms.pdf'
						type='application/pdf'
						width='100%'
						className='h-[500px] md:h-[800px] hidden md:block'
					>
						<p>
							Your web browser does not have a PDF plugin. Instead you can
							<a href='https://storage.googleapis.com/flowty-docs/flowty-service-terms.pdf'>
								click here to view the PDF file
							</a>
						</p>
					</object>
					<div className='md:hidden'>
						<Document
							file={termsPDF}
							onLoadSuccess={({ numPages }) => {
								setNumPages(numPages)
							}}
							renderMode='canvas'
						>
							{Array.from(Array(numberPages), (_, i) => (
								<div
									className='mb-2 mx-auto text-center justify-center flex'
									key={i}
								>
									<div>
										<Page pageNumber={i + 1} width={window.innerWidth} />
									</div>
								</div>
							))}
						</Document>
					</div>
				</div>
			</section>
		</Layout>
	)
}
