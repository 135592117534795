import React, { useMemo, useState } from "react"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { cn } from "../../../../util/cn"

import { TOPSHOT_ADDRESS } from "../../../../util/settings"
import { truncateNumber } from "../../../../util/truncateValueUtil"
import { useOpenSearchContext } from "../../../OpenSearch/OpenSearchConnector"
import { MappedFilterTrait } from "../../../OpenSearch/types"
import { TraitFilterCheckbox } from "../../TraitFilterCheckbox/TraitFilterCheckbox"
import { FacetSearchBar } from "../FacetSearchbar/FacetSearchbar"

interface CollectionsTraitProps {
	count: number | string
	isCollapsible: boolean
	collection: string
	collectionName: string
	addon?: React.ReactNode
	label: string
	resultMappedTrait: MappedFilterTrait | undefined
	initialMappedTrait: MappedFilterTrait | undefined
}

export const CollectionsTrait: React.FC<CollectionsTraitProps> = ({
	isCollapsible,
	addon,
	count,
	resultMappedTrait,
	initialMappedTrait,
	label,
	collection,
}) => {
	const { filters, setFilters, isLoadingFacets } = useOpenSearchContext()

	const [query, setQuery] = useState("")
	const [isExpanded, setIsExpanded] = useState(false)

	const isLocked =
		label === "Locked" && collection === `${TOPSHOT_ADDRESS}.TopShot`

	const collapsibleChevron = useMemo(() => {
		if (isCollapsible) {
			return (
				<svg
					data-testid='collection-trait-chevron'
					className={cn({
						"rotate-0": !isExpanded,
						"rotate-180": isExpanded,
					})}
					width='14'
					height='8'
					viewBox='0 0 14 8'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0L7 8L14 0L0 0Z'
						fill={!isExpanded ? "#F8F9FA" : "#04e5a3"}
					/>
				</svg>
			)
		}
		return null
	}, [isCollapsible, isExpanded])

	const showSearch =
		initialMappedTrait?.values &&
		Object.keys(initialMappedTrait.values).length > 9

	const renderSubFilters =
		initialMappedTrait?.values &&
		Object.keys(initialMappedTrait.values)
			.filter(traitValue => {
				if (query) {
					return traitValue.toLowerCase().includes(query.toLowerCase())
				}
				return traitValue
			})
			.map((traitValue: string) => {
				const traitCount = resultMappedTrait?.values?.[traitValue] || 0

				const isChecked = !!filters?.[collection]?.[label]?.[traitValue]

				const handleFilter = (): void => {
					const copyFilters = { ...filters }

					Mixpanel.track("Trait toggled", {
						traitType: {
							[label]: [copyFilters?.[collection]?.[label]?.[traitValue]],
						},
					})

					if (!copyFilters?.[collection]) {
						copyFilters[collection] = {}
					}

					if (isChecked) {
						if (traitValue) {
							delete copyFilters?.[collection]?.[label]?.[traitValue]

							if (
								Object.keys(copyFilters?.[collection]?.[label] ?? {}).length ===
								0
							) {
								delete copyFilters?.[collection]?.[label]
							}
						} else {
							delete copyFilters?.[collection]?.[label]
						}
					} else {
						if (traitValue) {
							!copyFilters?.[collection]?.[label]
								? (copyFilters[collection][label] = {})
								: (copyFilters[collection][label] =
										copyFilters[collection][label])
							copyFilters[collection][label][traitValue.toString()] = true
						} else {
							copyFilters[collection][label] = {}
						}
					}

					setFilters(copyFilters)
				}

				return (
					<TraitFilterCheckbox
						key={traitValue}
						isLocked={isLocked}
						count={truncateNumber(traitCount)}
						label={traitValue}
						isChecked={isChecked}
						onChange={() => handleFilter()}
					/>
				)
			})

	const formatText = (text: string): string => {
		return text.split(/(?=[A-Z][a-z])/).join(" ")
	}

	return (
		<div
			className={cn(
				"border-[1px] rounded-[4px] py-[0.5rem] border-neutral-400 border-opacity-25 w-full bg-[#ffffff0a]",
				{
					"hover:bg-[#ffffff1f]": !isExpanded,
				}
			)}
		>
			<div
				className='flex justify-between px-[1rem]'
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<div className='flex w-[70%]' onClick={() => setIsExpanded(true)}>
					<div className='text-[0.875rem] font-semibold capitalize'>
						{isLocked ? "Lock Status" : formatText(label)}
					</div>
					<div className='font-[500]'>{addon}</div>
				</div>
				<div className='flex items-center gap-[0.5rem] w-[30%] justify-end'>
					<div className='font-[500] text-[0.75rem]'>
						{isLoadingFacets ? (
							<div className='w-[20px] h-[15px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						) : (
							count
						)}
					</div>
					<div className='hover:cursor-pointer'>{collapsibleChevron}</div>
				</div>
			</div>
			{isExpanded ? (
				<div>
					<div className='px-[1rem]'>
						{showSearch && (
							<FacetSearchBar
								value={query}
								onChange={e => setQuery(e.target.value)}
								clearSearch={() => setQuery("")}
								placeholder='Search'
							/>
						)}
					</div>
					<div className='max-h-[300px] overflow-y-auto px-[1rem]'>
						{renderSubFilters}
					</div>
				</div>
			) : null}
		</div>
	)
}
