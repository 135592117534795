import {
	ListingKind,
	SUPPORTED_DAPPER_TOKENS,
	SUPPORTED_NON_DAPPER_TOKENS,
	SUPPORTED_TOKEN_LIST,
	SupportedTokens,
} from "flowty-common"
import { AccountSummary, Flowty } from "flowty-sdk"
import { FieldInputProps, useField } from "formik"
import { useEffect, useMemo } from "react"

const allTokens = SUPPORTED_TOKEN_LIST
const loanAndRentalTokens = SUPPORTED_NON_DAPPER_TOKENS

const nonDapperStorefrontTokens = SUPPORTED_NON_DAPPER_TOKENS

const dapperTokens = SUPPORTED_DAPPER_TOKENS

const dapperOfferTokens = [SupportedTokens.DUC]

const getTokensFromOrderType = (
	orderType: string,
	isDapper: boolean
): SupportedTokens[] | string[] => {
	switch (orderType) {
		case "loan":
			return loanAndRentalTokens
		case "rental":
			return loanAndRentalTokens
		case "storefront":
			return isDapper ? dapperTokens : nonDapperStorefrontTokens
		case "offer":
			return isDapper ? dapperOfferTokens : nonDapperStorefrontTokens
		default:
			return []
	}
}

interface TokenNameParams {
	tokenIdentifier: string
	listingType?: ListingKind
}

interface SelectorFilter<T> {
	apply: (account: AccountSummary) => boolean
	params: T
}

class TokenReceiverFilter implements SelectorFilter<TokenNameParams> {
	params: TokenNameParams

	constructor(params: TokenNameParams) {
		this.params = params
	}

	apply(account: AccountSummary): boolean {
		return (
			!!account?.tokens?.[this.params.tokenIdentifier]?.receiverPath ||
			account.isMain
		)
	}
}

interface FlowtyListboxTokenSelectorValues {
	allTokens: SupportedTokens[]
	defaultToken: SupportedTokens
	field: FieldInputProps<string>
	supportedTokensMap: Record<string, boolean>
}

interface Props {
	name: string
	selectedAccount: AccountSummary
	isDapper: boolean
	orderType: string
	flowty: Flowty
}

export const useFlowtyListboxTokenSelector = ({
	name,
	selectedAccount,
	isDapper,
	orderType,
	flowty,
}: Props): FlowtyListboxTokenSelectorValues => {
	const defaultToken = isDapper ? SupportedTokens.DUC : SupportedTokens.USDC
	const [field] = useField(name)

	const supportedTokensMap: Record<string, boolean> = useMemo(() => {
		if (!selectedAccount) {
			return {}
		}

		return allTokens.reduce((result: Record<string, boolean>, token) => {
			return {
				...result,
				[token]:
					new TokenReceiverFilter({
						tokenIdentifier: flowty.tokens.getTokenIdentifier(token),
					}).apply(selectedAccount) &&
					getTokensFromOrderType(orderType, selectedAccount.isDapper).includes(
						token
					),
			}
		}, {})
	}, [selectedAccount, orderType, isDapper])

	useEffect(() => {
		const supportedTokens = allTokens.filter(token => supportedTokensMap[token])

		const selectedTokenIsValid = supportedTokens.includes(
			field.value as SupportedTokens
		)

		if (selectedTokenIsValid) return

		const token = supportedTokens[0]
		field.onChange({ target: { name, value: token } })
	}, [selectedAccount, isDapper])
	useEffect(() => {
		const supportedTokens = allTokens.filter(token => supportedTokensMap[token])

		const selectedTokenIsValid = supportedTokens.includes(
			field.value as SupportedTokens
		)

		if (selectedTokenIsValid) return

		if (isDapper) {
			const token = SupportedTokens.DUC
			field.onChange({ target: { name, value: token } })
		} else {
			const token = SupportedTokens.USDC
			field.onChange({ target: { name, value: token } })
		}
	}, [isDapper])

	return {
		allTokens,
		defaultToken,
		field,
		supportedTokensMap,
	}
}
