import { OpenSearchListingAvailableData, Order } from "flowty-common"
import MultiListingView from "./MutliListingView"
import { SingleFundListingView } from "./SingleFundListingView/SingleFundListingView"

interface FundLoanViewProps {
	fundLoanOrders?: OpenSearchListingAvailableData[]
	singleLoanOrder?: OpenSearchListingAvailableData
	selectedOrder?: Order | null
	updateSelectedOrder: (order: Order) => void
}

export const FundLoanView = ({
	fundLoanOrders,
	selectedOrder,
	singleLoanOrder,
	updateSelectedOrder,
}: FundLoanViewProps) => {
	return (
		<>
			{(singleLoanOrder || (fundLoanOrders && fundLoanOrders.length === 1)) && (
				<div className='flex flex-col gap-2'>
					<SingleFundListingView
						listing={
							fundLoanOrders
								? fundLoanOrders[0]
								: (singleLoanOrder as OpenSearchListingAvailableData)
						}
					/>
				</div>
			)}
			{fundLoanOrders && fundLoanOrders.length > 1 && (
				<MultiListingView
					listings={fundLoanOrders}
					selectedListing={selectedOrder}
					updateSelectedOrder={updateSelectedOrder}
				/>
			)}
		</>
	)
}
