import { getTokenFromType, SupportedTokens } from "flowty-common"
import { inject, observer } from "mobx-react"
import { AccountSummary } from "flowty-sdk"
import { FundingAvailableData } from "../../../services/flow/FundingService"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { Alert } from "../../Alert/Alert"
import { ConnectedWalletIndicator } from "../../FlowUI/wizards/shared/ConnectedWalletIndicator"
import { LoadingSpinner } from "../../Shared/LoadingSpinner"
import { TokenAmount } from "../../Tokens/tokenDisplays"
import { Row } from "../../GridLayout/Row/Row"
import { Col } from "../../GridLayout/Col/Col"
import React from "react"

interface Props extends AuthStoreProp {
	onFundClick: () => void
	funding: FundingAvailableData
	userSelectedWallet: AccountSummary
	loading: boolean
}

const RepayReadyToBePlaced: React.FunctionComponent<Props> = ({
	onFundClick,
	funding,
	userSelectedWallet,
	loading,
}) => {
	const token =
		"paymentTokenType" in funding.listingAvailable
			? getTokenFromType(funding.listingAvailable.paymentTokenType)
			: SupportedTokens.FLOW

	const checkAccountHasBalanceForRental = (
		fundingAvailable: FundingAvailableData,
		wallet: AccountSummary
	): boolean => {
		const tokenBalance =
			wallet?.tokens?.[fundingAvailable.listingAvailable.paymentTokenType]
				?.balance || 0
		return tokenBalance >= Number(fundingAvailable?.repaymentAmount)
	}

	const hasBalance = funding.repaymentAmount
		? checkAccountHasBalanceForRental(funding, userSelectedWallet)
		: true

	return (
		<>
			<div className='flex flex-col justify-center items-center'>
				<Row className='w-full'>
					<Col>
						<table className='table-borderless mt-6 px-[1.25rem]'>
							<tbody>
								<tr>
									<td className='text-base text-start'>
										<p>Repayment Amount:</p>
									</td>
									<td className='text-end'>
										<div className='flex flex-col-reverse md:flex-row-reverse'>
											<TokenAmount
												amount={Number(funding.repaymentAmount || 0)}
												isSmall
												largeText
												token={token}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>
			</div>
			{!hasBalance && (
				<div className='px-3'>
					<Alert variant='danger' className='mt-3 mb-1'>
						<div className='flex justify-between items-center'>
							<p className='text-red'>
								Insufficient {token} to repay this loan
							</p>
						</div>
					</Alert>
				</div>
			)}
			<div className='flex flex-col items-center justify-center w-full px-4 py-3 lg:flex-row border-modal'>
				<ConnectedWalletIndicator />
				<div className='flex flex-col items-center'>
					<div className='flex items-center justify-center w-full lg:justify-end'>
						<button
							onClick={onFundClick}
							disabled={!hasBalance || loading}
							className={`w-[10em] md:w-[12em] flex justify-center items-center border border-white rounded-md font-extrabold text-base shadow-slate-100 hover-button-white px-[1.25rem] py-2 ${
								(!hasBalance || loading) && "bg-gray-600 text-gray-400"
							}`}
						>
							{loading ? <LoadingSpinner /> : "Repay"}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default inject("authStore")(observer(RepayReadyToBePlaced))
