/* eslint-disable @typescript-eslint/no-use-before-define */
import { Config, ContractNames } from "./types"
import { Flowty } from "./flowty"

export class FlowtyBuilder {
	config: Config

	withApiURL(apiURL: string): FlowtyBuilder {
		this.config.apiURL = apiURL
		return this
	}

	withContractAddress(
		contractName: ContractNames,
		address: string
	): FlowtyBuilder {
		this.config.contractAddresses[contractName] = address
		return this
	}

	withNetwork(network: "mainnet" | "testnet" | "emulator"): FlowtyBuilder {
		switch (network) {
			case "mainnet":
				return this.withMainnet()
			case "testnet":
				return this.withTestnet()
			case "emulator":
				return this.withEmulator()
			default:
				throw new Error("Unrecognized network")
		}
	}

	withCrescendo(value: boolean): FlowtyBuilder {
		this.config.crescendo = value

		if (this.config.crescendo) {
			switch (this.config.network) {
				case "mainnet":
					this.config.contractAddresses.TransactionTypes = "0x26836b2113af9115"
					break
				case "testnet":
					this.config.contractAddresses.TransactionTypes = "0x0364649c96f0dcec"
					break
				default:
					break
			}
		}

		return this
	}

	build(): Flowty {
		return new Flowty(this.config)
	}

	constructor() {
		this.config = new Config(
			"",
			{
				AddressUtils: "",
				BaseCollection: "",
				CapabilityCache: "",
				ContractFactory: "",
				ContractFactoryTemplate: "",
				ContractManager: "",
				DapperOffersV2: "",
				DapperUtilityCoin: "",
				DapperWalletCollections: "",
				Doodles: "",
				DropTypes: "",
				FlowToken: "",
				FlowUtilityToken: "",
				Flowty: "",
				FlowtyActiveCheckers: "",
				FlowtyAddressVerifiers: "",
				FlowtyDrops: "",
				FlowtyOffersResolver: "",
				FlowtyPricers: "",
				FlowtyUtils: "",
				FlowtyWrapped: "",
				FungibleToken: "",
				FungibleTokenMetadataViews: "",
				FungibleTokenRouter: "",
				HybridCustody: "",
				LostAndFound: "",
				LostAndFoundHelper: "",
				MetadataViews: "",
				NFTCatalog: "",
				NFTMetadata: "",
				NFTStorefrontV2: "",
				NFTStorefrontV2_Shared: "",
				NonFungibleToken: "",
				Offers: "",
				OffersV2_Dapper: "",
				OpenEditionInitializer: "",
				OpenEditionTemplate: "",
				Resolver: "",
				StringUtils: "",
				TokenForwarding: "",
				TopShot: "",
				TransactionTypes: "",
				USDCFlow: "",
				ViewResolver: "",
			},
			"emulator",
			false
		)
	}

	private withMainnet(): FlowtyBuilder {
		this.config = new Config(
			"https://api2.flowty.io",
			{
				AddressUtils: "0xa340dc0a4ec828ab",
				BaseCollection: "0xbefbaccb5032a457",
				CapabilityCache: "0xacc5081c003e24cf",
				ContractFactory: "0xbefbaccb5032a457",
				ContractFactoryTemplate: "0xbefbaccb5032a457",
				ContractManager: "0xbefbaccb5032a457",
				DapperOffersV2: "0xb8ea91944fd51c43",
				DapperUtilityCoin: "0xead892083b3e2c6c",
				DapperWalletCollections: "0x42a54b4f70e7dc81",
				Doodles: "0xe81193c424cfd3fb",
				DropTypes: "0x54233ec36b0c883b",
				FlowToken: "0x1654653399040a61",
				FlowUtilityToken: "0xead892083b3e2c6c",
				Flowty: "0x5c57f79c6694797f",
				FlowtyActiveCheckers: "0xbefbaccb5032a457",
				FlowtyAddressVerifiers: "0xbefbaccb5032a457",
				FlowtyDrops: "0xbefbaccb5032a457",
				FlowtyOffersResolver: "0x322d96c958eb8c46",
				FlowtyPricers: "0xbefbaccb5032a457",
				FlowtyUtils: "0x3cdbb3d569211ff3",
				FlowtyWrapped: "0x592eb32b47d8b85f",
				FungibleToken: "0xf233dcee88fe0abe",
				FungibleTokenMetadataViews: "0xf233dcee88fe0abe",
				FungibleTokenRouter: "0x707c0b39a8d689cb",
				HybridCustody: "0xd8a7e05a7ac670c0",
				LostAndFound: "0x473d6a2c37eab5be",
				LostAndFoundHelper: "0x473d6a2c37eab5be",
				MetadataViews: "0x1d7e57aa55817448",
				NFTCatalog: "0x49a7cda3a1eecc29",
				NFTMetadata: "0xbefbaccb5032a457",
				NFTStorefrontV2: "0x3cdbb3d569211ff3",
				NFTStorefrontV2_Shared: "0x4eb8a10cb9f87357",
				NonFungibleToken: "0x1d7e57aa55817448",
				Offers: "0x3c1c4b041ad18279",
				OffersV2_Dapper: "0xb8ea91944fd51c43",
				OpenEditionInitializer: "0xbefbaccb5032a457",
				OpenEditionTemplate: "0xbefbaccb5032a457",
				Resolver: "0xb8ea91944fd51c43",
				StringUtils: "0xa340dc0a4ec828ab",
				TokenForwarding: "0xe544175ee0461c4b",
				TopShot: "0x0b2a3299cc857e29",
				TransactionTypes: "0xccd763254ec95a9e",
				USDCFlow: "0xf1ab99c82dee3526",
				ViewResolver: "0x1d7e57aa55817448",
			},
			"mainnet",
			false
		)
		return this
	}

	private withTestnet(): FlowtyBuilder {
		this.config = new Config(
			"https://api2.testnet.flowty.io",
			{
				AddressUtils: "0x31ad40c07a2a9788",
				BaseCollection: "0xea3e1b74e99dc035",
				CapabilityCache: "0x83d75469f66d2ee6",
				ContractFactory: "0xea3e1b74e99dc035",
				ContractFactoryTemplate: "0xea3e1b74e99dc035",
				ContractManager: "0xea3e1b74e99dc035",
				DapperOffersV2: "0x8a5f647e58dde1ee",
				DapperUtilityCoin: "0x82ec283f88a62e65",
				DapperWalletCollections: "0xb67c471ade6fdbea",
				Doodles: "0x1c5033ad60821c97",
				DropTypes: "0xfc6a7e477099ca54",
				FlowToken: "0x7e60df042a9c0868",
				FlowUtilityToken: "0x82ec283f88a62e65",
				Flowty: "0xe1d43e0cfc237807",
				FlowtyActiveCheckers: "0xea3e1b74e99dc035",
				FlowtyAddressVerifiers: "0xea3e1b74e99dc035",
				FlowtyDrops: "0xea3e1b74e99dc035",
				FlowtyOffersResolver: "0xc96178f4d1e4c1fd",
				FlowtyPricers: "0xea3e1b74e99dc035",
				FlowtyUtils: "0xb051bdaddb672a33",
				FlowtyWrapped: "0xd1712bc9e162f9e3",
				FungibleToken: "0x9a0766d93b6608b7",
				FungibleTokenMetadataViews: "0x9a0766d93b6608b7",
				FungibleTokenRouter: "0x83231f90a288bc35",
				HybridCustody: "0x294e44e1ec6993c6",
				LostAndFound: "0xbe4635353f55bbd4",
				LostAndFoundHelper: "0xbe4635353f55bbd4",
				MetadataViews: "0x631e88ae7f1d7c20",
				NFTCatalog: "0x324c34e1c517e4db",
				NFTMetadata: "0xea3e1b74e99dc035",
				NFTStorefrontV2: "0xb051bdaddb672a33",
				NFTStorefrontV2_Shared: "0x6225830c8c0957ba",
				NonFungibleToken: "0x631e88ae7f1d7c20",
				Offers: "0x0d3dc5ad70be03d1",
				OffersV2_Dapper: "0x8a5f647e58dde1ee",
				OpenEditionInitializer: "0xea3e1b74e99dc035",
				OpenEditionTemplate: "0xea3e1b74e99dc035",
				Resolver: "0x8a5f647e58dde1ee",
				StringUtils: "0x31ad40c07a2a9788",
				TokenForwarding: "0x51ea0e37c27a1f1a",
				TopShot: "0x877931736ee77cff",
				TransactionTypes: "0x86d1c2159a5d9eca",
				USDCFlow: "0x64adf39cbc354fcb",
				ViewResolver: "0x631e88ae7f1d7c20",
			},
			"testnet",
			false
		)
		return this
	}

	private withEmulator(): FlowtyBuilder {
		this.config = new Config(
			"http://localhost:9000",
			{
				AddressUtils: "0xf8d6e0586b0a20c7",
				BaseCollection: "0xf8d6e0586b0a20c7",
				CapabilityCache: "0xf8d6e0586b0a20c7",
				ContractFactory: "0xf8d6e0586b0a20c7",
				ContractFactoryTemplate: "0xf8d6e0586b0a20c7",
				ContractManager: "0xf8d6e0586b0a20c7",
				DapperOffersV2: "0xf8d6e0586b0a20c7",
				DapperUtilityCoin: "0xf8d6e0586b0a20c7",
				DapperWalletCollections: "0xf8d6e0586b0a20c7",
				Doodles: "0xf8d6e0586b0a20c7",
				DropTypes: "0xf8d6e0586b0a20c7",
				FlowToken: "0x0ae53cb6e3f42a79",
				FlowUtilityToken: "0xf8d6e0586b0a20c7",
				Flowty: "0xf8d6e0586b0a20c7",
				FlowtyActiveCheckers: "0xf8d6e0586b0a20c7",
				FlowtyAddressVerifiers: "0xf8d6e0586b0a20c7",
				FlowtyDrops: "0xf8d6e0586b0a20c7",
				FlowtyOffersResolver: "0xf8d6e0586b0a20c7",
				FlowtyPricers: "0xf8d6e0586b0a20c7",
				FlowtyUtils: "0xf8d6e0586b0a20c7",
				FlowtyWrapped: "0xf8d6e0586b0a20c7",
				FungibleToken: "0xee82856bf20e2aa6",
				FungibleTokenMetadataViews: "0xf8d6e0586b0a20c7",
				FungibleTokenRouter: "0xf8d6e0586b0a20c7",
				HybridCustody: "0xf8d6e0586b0a20c7",
				LostAndFound: "0xf8d6e0586b0a20c7",
				LostAndFoundHelper: "0xf8d6e0586b0a20c7",
				MetadataViews: "0xf8d6e0586b0a20c7",
				NFTCatalog: "0xf8d6e0586b0a20c7",
				NFTMetadata: "0xf8d6e0586b0a20c7",
				NFTStorefrontV2: "0xf8d6e0586b0a20c7",
				NFTStorefrontV2_Shared: "0x1",
				NonFungibleToken: "0xf8d6e0586b0a20c7",
				Offers: "0xf8d6e0586b0a20c7",
				OffersV2_Dapper: "0xf8d6e0586b0a20c7",
				OpenEditionInitializer: "0xf8d6e0586b0a20c7",
				OpenEditionTemplate: "0xf8d6e0586b0a20c7",
				Resolver: "0xf8d6e0586b0a20c7",
				StringUtils: "0xf8d6e0586b0a20c7",
				TokenForwarding: "0xf8d6e0586b0a20c7",
				TopShot: "0xf8d6e0586b0a20c7",
				TransactionTypes: "0xf8d6e0586b0a20c7",
				USDCFlow: "0xf8d6e0586b0a20c7",
				ViewResolver: "0xf8d6e0586b0a20c7",
			},
			"emulator",
			false
		)
		return this
	}
}
