import * as React from "react"
import { cn } from "../utils/cn"

interface FlowtyButtonProps {
	text: string | React.ReactNode
	variant: "primary" | "secondary" | "tertiary"
	loading?: boolean
	disabled?: boolean
	btnSize?: "small" | "large"
	bgColor?: "primary" | "danger" | "white" | "warning"
	onClick?: () => void
	type?: "button" | "submit" | "reset" | undefined
	className?: string
}

export const FlowtyButton: React.FunctionComponent<FlowtyButtonProps> = ({
	text,
	variant,
	loading = false,
	disabled = false,
	btnSize = "large",
	onClick,
	bgColor = "primary",
	type,
	className,
}) => {
	const baseStyles =
		"px-6 text-black rounded-lg shadow-lg uppercase font-black whitespace-nowrap bg-primary"

	return (
		<button
			type={type}
			onClick={!disabled ? onClick : undefined}
			disabled={disabled}
			className={cn(
				baseStyles,
				{
					"bg-[#FF6E25]": bgColor === "warning",
					"bg-red-500": bgColor === "danger",
					"bg-white": bgColor === "white",
					"capitalize p-0 font-medium text-base bg-transparent text-primary shadow-none hover:underline hover:shadow-none":
						variant === "tertiary",
					"hover-button":
						bgColor === "primary" && !disabled && variant !== "tertiary",
					"hover-danger-button": bgColor === "danger" && !disabled,
					"hover-warning-button": bgColor === "warning" && !disabled,
					"hover-white-button": bgColor === "white" && !disabled,
					"opacity-50 cursor-not-allowed no-shadow": disabled,
					"py-2": btnSize === "small",
					"py-3": btnSize === "large",
					"text-base bg-primary shadow-flowty-btn-primary":
						variant === "primary",
					"text-opacity-50": loading,
					"text-xs": btnSize === "small" && variant === "primary",
					"w-full text-sm font-extrabold shadow-flowty-btn-secondary":
						variant === "secondary",
				},
				className
			)}
		>
			{text}
		</button>
	)
}
