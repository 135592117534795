import {
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	checkIsLocked,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import React, { useMemo, useState } from "react"
import { Oval } from "react-loader-spinner"
import { useNavigate } from "react-router-dom"
import { NftDetails } from "../../../contexts/FlowtyModalsContext/types/FlowtyModalsContextTypes"
import { useMarketplaceAppContext } from "../../../contexts/MarketplaceAppContext/MarketplaceAppContext"
import { CollectionTabsType } from "../../../screens/CollectionPage/hooks/useCollectionPage"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { TOPSHOT_TYPE } from "../../../util/settings"
import { ScrollToLoadMsg } from "../../Loaders/ScrollToLoadMsg"
import { PAGE_LIMIT } from "../../OpenSearch/OpenSearchConnector"
import { NftCard } from "../../Shared/NftCard/NftCard"
import { NftLoadingCard } from "../../Shared/NftCard/NftLoadingCard"
import {
	SnackbarAlert,
	SnackbarSeverity,
} from "../../Shared/Snackbar/SnackbarAlert"
import { NftListLoading } from "../NftListLoading/NftListLoading"
import { DropStatus, EmptyNftView } from "./components/EmptyNftView"
import { useNftListView } from "./hooks/useNftListView"

interface NftListViewProps {
	onSelectCard?: (selected: NftDetails) => void
	profilePage?: boolean
	showVisitDrop?: DropStatus
	onSelectedTabChange?: (tab: CollectionTabsType) => void
}

export const NftListView: React.FC<NftListViewProps> = ({
	onSelectCard = () => {},
	profilePage = false,
	showVisitDrop = { showDropTab: false },
	onSelectedTabChange = () => {},
}) => {
	const {
		accountSummaries,
		bulkListActive,
		bulkPurchaseSelecteds,
		canScroll,
		catalogCache,
		denylistCache,
		hasProviderFn,
		hasProvinerPublicFn,
		hits,
		initialLoading,
		isLoading,
		isLoggedUserProfile,
		isProfile,
		isUnsupportedSelection,
		lastRef,
		onBulkPurchaseSelected,
		verifyHasOwner,
		publicAccountView,
	} = useNftListView()
	const { loggedUserAddress, bulkLimit } = useMarketplaceAppContext()

	const navigate = useNavigate()

	const [warningBar, setWarningBar] = useState<{
		show: boolean
		message: string
		type: SnackbarSeverity
	}>({ message: "", show: false, type: "WARNING" })

	const loadingCards = useMemo(() => {
		return window.innerWidth >= 1680
			? Array.from({ length: 10 })
			: window.innerWidth >= 1280
			? Array.from({ length: 6 })
			: window.innerWidth >= 768
			? [0, 1, 2]
			: [0, 1]
	}, [window.innerWidth])

	const addresses = Object.keys(accountSummaries ?? {})

	const loggedInUserAccountWasLastOwner = (saveOwner: string): boolean => {
		const isOwnerInAccountSummaries = Object.values(addresses ?? {}).some(
			account => account === saveOwner
		)
		return isOwnerInAccountSummaries
	}

	return (
		<div className='min-h-screen relative flex flex-col justify-start w-full'>
			{!initialLoading ? (
				<>
					<ul
						className={`w-full h-full grid pb-4 gap-4`}
						style={{
							gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
						}}
					>
						{hits?.map((nft, i) => {
							const nftType = nft?.type?.endsWith(".NFT")
								? nft?.type
								: `${nft?.type}.NFT`

							const isCatalog = catalogCache.has(nftType)

							let isLocked = false
							if (nftType === TOPSHOT_TYPE) {
								isLocked = checkIsLocked(nft)
							}
							const hasProvider =
								isProfile || isLoggedUserProfile
									? hasProviderFn(nft)
									: hasProvinerPublicFn(nft)

							const nftCustomId = `${nft?.contractAddress}.${nft?.contractName}.${nft?.id}`
							const bulkSelected = bulkPurchaseSelecteds.has(nftCustomId)

							const handleClick = (
								event:
									| React.MouseEvent<HTMLDivElement, MouseEvent>
									| React.KeyboardEvent<HTMLDivElement>
							): void => {
								const locationData = nftTypeAndIdToLocationData(
									nft?.type || "",
									nft?.id || ""
								)

								event.preventDefault()
								const assetHref = `/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
								if (event.ctrlKey || event.metaKey) {
									window.open(assetHref, "_blank")
								} else {
									!denylistCache.has(nftType)
										? handleSelect()
										: navigate(assetHref)
								}
							}

							const order = nft
								?.orders?.[0] as OpensearchStorefrontAvailableData

							const decimal =
								order?.valuationDifference &&
								order?.valuations?.blended?.usdValue &&
								order?.valuationDifference / order.valuations?.blended?.usdValue
							const percent = decimal ? decimal * 100 : 0
							const discountPercent =
								nft?.orders[0]?.listingKind !== "storefront"
									? 0
									: Math.floor(percent)

							const orders: {
								loans: OpenSearchListingAvailableData[]
								rentals: OpensearchRentalAvailableData[]
								storefront: OpensearchStorefrontAvailableData[]
							} = {
								loans: nft?.orders?.filter(
									nftOrder => nftOrder?.listingKind === "loan"
								) as OpenSearchListingAvailableData[],
								rentals: nft?.orders?.filter(
									nftOrder => nftOrder?.listingKind === "rental"
								) as OpensearchRentalAvailableData[],
								storefront: nft?.orders?.filter(
									nftOrder => nftOrder?.listingKind === "storefront"
								) as OpensearchStorefrontAvailableData[],
							}
							const hasListingOnChild =
								orders?.storefront?.length > 0 &&
								orders?.storefront?.[0]?.flowtyStorefrontAddress !==
									loggedUserAddress

							let isOwned

							const handleSelect = async (): Promise<void> => {
								const saveOwner = nft.owner
								isOwned = await verifyHasOwner(nft)

								if (
									!!isOwned === false &&
									loggedInUserAccountWasLastOwner(saveOwner)
								) {
									setWarningBar({
										message:
											"Our system does not identify you as the owner of this nft, your account is being refreshed",
										show: true,
										type: "WARNING",
									})

									Mixpanel.track("OWNERSHIP_MISMATCH", {
										selectedNft: nft,
									})
								} else if (bulkListActive) {
									if (
										!hasListingOnChild &&
										hasProvider &&
										!isLocked &&
										isOwned
									) {
										Mixpanel.track("BULK_ASSET_TOGGLED", {
											selectedNft: nft,
										})
										onBulkPurchaseSelected(nft)
									} else {
										setWarningBar({
											message: "Unable to bulk list this nft",
											show: true,
											type: "WARNING",
										})
										Mixpanel.track("BULK_ASSET_SELECT_ERROR", {
											selectedNft: nft,
										})
									}
								} else {
									onSelectCard({
										contractAddress: nft.contractAddress,
										contractName: nft.contractName,
										nftID: nft.id,
										nftType: nft.type,
									})
									Mixpanel.track("PROFILE_ASSET_SELECTED", {
										selectedNft: nft,
									})
								}
							}
							return (
								<div key={`${nft?.card?.title}-${i}`}>
									<SnackbarAlert
										setShow={show => setWarningBar({ ...warningBar, show })}
										show={warningBar.show}
										seconds={2}
										message={warningBar.message}
										type={warningBar.type}
									/>
									<NftCard
										key={`${nft?.card?.title}-${i}`}
										handleClick={handleClick}
										nft={nft}
										hasProvider={hasProvider}
										bulkSelected={bulkSelected && bulkListActive}
										bulkDisabled={
											//TODO set disabled after ownership mismatch
											hasListingOnChild ||
											isLocked ||
											!hasProvider ||
											(bulkPurchaseSelecteds.size === bulkLimit &&
												!bulkSelected)
										}
										bulkListActive={bulkListActive}
										isLoading={false}
										isCatalog={isCatalog}
										isLocked={isLocked}
										discountPercent={discountPercent}
										didImageLoadFail={false}
										accountSummaries={accountSummaries}
										publicAccountView={publicAccountView}
										profilePage={profilePage}
									/>
								</div>
							)
						})}
						{isLoading && (
							<>
								{loadingCards.map((_, index) => {
									return (
										<NftLoadingCard
											nft={{} as OpensearchFlowNFT}
											key={index}
											isLoading={true}
										/>
									)
								})}
							</>
						)}
					</ul>
					<ScrollToLoadMsg show={hits?.length >= PAGE_LIMIT && canScroll} />
					<div className='self-end justify-self-end'>
						{hits?.length >= PAGE_LIMIT && canScroll ? (
							<div className='w-full h-[20px] mt-44' ref={lastRef} />
						) : null}
					</div>
					<div>
						{hits?.length <= 0 && (
							<>
								{isLoading ? (
									<div className='w-full flex justify-center'>
										<Oval height={150} width={150} />
									</div>
								) : (
									<EmptyNftView
										isUnsupportedSelection={isUnsupportedSelection}
										showVisitDrop={showVisitDrop}
										onSelectedTabChange={onSelectedTabChange}
									/>
								)}
							</>
						)}
					</div>
				</>
			) : (
				<NftListLoading />
			)}
		</div>
	)
}
