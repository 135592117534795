import { getTokenSpotPrice } from "../../../services/firestore/SpotPriceService"
import { SpotPrice, SupportedTokens } from "flowty-common"
import { useCallback, useEffect, useState } from "react"

export const useTokenSpotPrice = (
	token: SupportedTokens | null
): SpotPrice | null => {
	const [spotPrice, setSpotPrice] = useState<SpotPrice | null>(null)

	const updateSpotPrice = useCallback(async () => {
		if (token === SupportedTokens.FLOW) {
			const flowSpotPrice = await getTokenSpotPrice(SupportedTokens.FLOW)
			setSpotPrice(flowSpotPrice)
		} else {
			setSpotPrice(null)
		}
	}, [token])

	useEffect(() => {
		updateSpotPrice()
	}, [token])

	return spotPrice
}
