export const LostAndFoundView: React.FunctionComponent = () => (
	<div className='flex flex-col gap-6 w-full md:w-[400px] justify-between bg-[#FFFFFF14] p-3 rounded-lg'>
		<div className='flex flex-col gap-2'>
			<p className='text-white text-[15px] font-bold'>Why Is This NFT here? </p>
			<span className='text-[#ADB5BD] text-[12px] font-normal'>
				On Flow, a wallet needs to approve a collection before it can receive
				NFTs from that collection. This NFT is from a collection that you have
				not approved. To claim the NFT and approve the collection, click the
				Claim button.
			</span>
		</div>
		<a
			className='underline text-white text-[16px] font-semibold'
			href='https://x.com/austin_flowty/status/1559184554090323968'
			target='_blank'
			rel='noreferrer'
		>
			Learn More
		</a>
	</div>
)
