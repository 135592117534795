import { OpensearchFlowNFT } from "flowty-common"
import React from "react"
import { NftCardOrderDisplay } from "./components/NftCardOrderDisplay"

interface NftCardProps {
	nft: OpensearchFlowNFT
	isLoading: boolean
}

export const NftLoadingCard: React.FunctionComponent<NftCardProps> = ({
	nft,
	isLoading,
}) => {
	return (
		<div
			className={`nftCard relative self-center justify-self-center flex flex-col p-[16px] rounded-lg nft-card-shadow cursor-pointer w-full h-full
			${"border-opacity-60 border-[0.8px] border-[#7c7c7c]"}`}
		>
			<div className='sm:hover:text-white sm:peer-hover:text-white w-full h-full'>
				<div className='flex sm:flex-col-reverse mb-2 sm:mb-1'>
					<div className='grid grid-cols-3 sm:grid-cols-1 w-full h-full'>
						{isLoading ? (
							<>
								<div
									className={`max-w-full w-full object-fill aspect-square hidden sm:flex cursor-wait my-[0.7rem] bg-gray-400 animate-pulse`}
								></div>
								<div className='sm:hidden cursor-wait rounded bg-gray-400 flex justify-center items-center animate-pulse'></div>
							</>
						) : null}
						{isLoading ? (
							<div className='flex flex-col sm:mb-2 pl-3 sm:pl-0 col-span-2 sm:order-first'>
								<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait h-6' />
								<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
								<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
							</div>
						) : null}
					</div>
				</div>
			</div>
			<NftCardOrderDisplay order={null} isLoading={isLoading} nft={nft} />
		</div>
	)
}
