import { ReactComponent as NumberOne } from "./assets/numberone.svg"
import { ReactComponent as JerseyMatch } from "./assets/jerseymatch.svg"
import { ReactComponent as PerfectSerial } from "./assets/perfectserial.svg"
import { Tooltip } from "react-tooltip"
import { TraitName } from "./util/returnTsSpecialTraits"

const traitIcons: Record<TraitName, React.FC<React.SVGProps<SVGSVGElement>>> = {
	"#1 Serial": NumberOne,
	"Jersey Match": JerseyMatch,
	"Perfect Serial": PerfectSerial,
}

interface TsSpecialIconsProps {
	specialTsTraits: Record<TraitName, boolean>
}

export const TsSpecialIcons = ({ specialTsTraits }: TsSpecialIconsProps) => {
	return (
		<>
			{Object.entries(specialTsTraits).map(([trait, isTrue]) => {
				const Icon = traitIcons[trait as keyof typeof traitIcons]
				return (
					isTrue &&
					Icon && (
						<div
							key={trait}
							className='rounded-[100px] backdrop-blur-[6px] flex w-[20px] h-[20px] md:w-[24px] md:h-[24px] px-[3px] py-[2px] md:px-[4px] md:py-[3px] gap-[10px] flex-shrink-0 justify-center items-center flex-col mr-[-4px] md:mr-[-6px]'
							style={{
								background: "rgba(255, 255, 255, 0.14)",
							}}
						>
							<Tooltip
								id={`${trait}-tooltip`}
								style={{
									backgroundColor: "#F8F9FA",
									boxShadow: "none",
									color: "#031021",
									fontSize: "14px",
									fontWeight: 500,
									zIndex: 20,
								}}
								opacity={1}
								border='none'
							/>
							<Icon
								className='h-[12px] w-[12px] md:h-[16px] md:w-[16px] flex-shrink-0'
								data-tooltip-id={`${trait}-tooltip`}
								data-tooltip-place='top'
								data-tooltip-content={trait}
							/>
						</div>
					)
				)
			})}
		</>
	)
}
