import { ProfileLink } from "../common/ProfileLink"

interface Props {
	flowtyStorefrontAddress: string
}

export const RentDelistedRow: React.FunctionComponent<Props> = ({
	flowtyStorefrontAddress,
}) => {
	return (
		<div className='flex gap-1 text-[14px] flex-wrap'>
			<p className='text-start'>
				<span className='font-bold text-[14px]'>Delisted</span> by{" "}
				<ProfileLink address={flowtyStorefrontAddress} />
			</p>
		</div>
	)
}
