/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { SupportedTokens } from "flowty-common"
import { AccountSummaries, AccountSummary } from "flowty-sdk"
import { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { CarouselNavigation } from "../../FlowtyModalCarousel/CarouselNavigation/CarouselNavigation"
import { LinkedAccountBox } from "./LinkedAccountBox"
import { Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

type MobileWalletCarouselProps = {
	mainAccount?: AccountSummary
	childAccounts?: AccountSummaries
	isLoggedUserAcct: boolean
	selectedWalletAddresses: string[]
	updateSelectedAddresses: (address: string) => void
	onUploadNewImage: (
		event: React.ChangeEvent<HTMLInputElement>,
		setImage: (image: string) => void,
		setIsLoading: (isLoading: boolean) => void,
		fileName: string
	) => Promise<void | undefined>
	saveNewImage: (address: string, name: string, image: string) => void
	tokenIdentifiers: Record<string, SupportedTokens>
}

export const MobileWalletCarousel: React.FunctionComponent<
	MobileWalletCarouselProps
> = ({
	mainAccount,
	childAccounts,
	isLoggedUserAcct,
	selectedWalletAddresses,
	updateSelectedAddresses,
	onUploadNewImage,
	saveNewImage,
	tokenIdentifiers,
}) => {
	const [isFirstIndex, setIsFirstIndex] = useState(true)

	return (
		<div className='w-full md:hidden pl-6'>
			<Swiper
				slidesPerView={"auto"}
				spaceBetween={10}
				effect={"slide"}
				speed={800}
				centeredSlides={!isFirstIndex}
				modules={[Pagination]}
			>
				{childAccounts && Object.keys(childAccounts).length > 1 && (
					<span slot='container-end' className='h-32 w-32'>
						<CarouselNavigation
							itemsLength={Object.keys(childAccounts).length}
							setIsFirstIndex={setIsFirstIndex}
							hiddeArrows
						/>
					</span>
				)}
				{mainAccount && (
					<SwiperSlide key={mainAccount.address} className='!w-auto !h-auto'>
						<LinkedAccountBox
							saveNewImage={saveNewImage}
							onUploadNewImage={onUploadNewImage}
							parentWalletAddress={mainAccount.address}
							acct={mainAccount as AccountSummary}
							isSelected={selectedWalletAddresses.includes(
								`${mainAccount.address}`
							)}
							onSelect={updateSelectedAddresses}
							tokenIdentifiers={tokenIdentifiers}
						/>
					</SwiperSlide>
				)}
				{childAccounts &&
					Object.values(childAccounts).map(childAcct => {
						if (childAcct.address === mainAccount?.address) return null
						return (
							<SwiperSlide key={childAcct.address} className='!w-auto !h-auto'>
								<LinkedAccountBox
									saveNewImage={saveNewImage}
									onUploadNewImage={onUploadNewImage}
									parentWalletAddress={mainAccount?.address ?? ""}
									canEdit={isLoggedUserAcct}
									key={childAcct.address}
									acct={childAcct}
									isSelected={selectedWalletAddresses.includes(
										childAcct.address
									)}
									onSelect={updateSelectedAddresses}
									tokenIdentifiers={tokenIdentifiers}
								/>
							</SwiperSlide>
						)
					})}
			</Swiper>
		</div>
	)
}
