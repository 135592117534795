/* eslint-disable @typescript-eslint/no-use-before-define */

import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getLoanListingTransactionContent = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => {
	return config.crescendo
		? getLoanListingTransactionCrescendo(config)
		: getLoanListingTransaction(config, token, nftData)
}

export const getDelistLoanListingTransactionContent = (
	config: Config
): string =>
	config.crescendo
		? delistLoanListingTransactionCrescendo(config)
		: delistLoanListingTransaction(config)

const getLoanListingTransaction = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => ``

const getLoanListingTransactionCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import Flowty from ${config.contractAddresses.Flowty}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import CapabilityCache from ${config.contractAddresses.CapabilityCache}
import AddressUtils from ${config.contractAddresses.AddressUtils}

transaction(
    listItemID: UInt64,
    amount: UFix64,
    interestRate: UFix64,
    term: UFix64,
    autoRepaymentEnabled: Bool,
    loanExpiresAfter: UFix64,
    ftProviderControllerID: UInt64,
    nftProviderControllerID: UInt64,
    collectionIdentifier: String,
    nftProviderAddress: Address,
    ftReceiverAddress: Address,
    paymentTokenIdentifier: String
) {
    let tokenReceiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(Flowty.List) &Flowty.FlowtyStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let paymentVault: @{FungibleToken.Vault}
    let tokenProvider: Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>?
    let paymentTokenType: Type
    let nftType: Type

    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.paymentTokenType = CompositeType(paymentTokenIdentifier) ?? panic("invalid payment token type identifier")

        if(acct.storage.borrow<&Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath) == nil) {
            // Create a new empty .Storefront
            let storefront <- Flowty.createStorefront()

            // save it to the account
            acct.storage.save(<-storefront, to: Flowty.FlowtyStorefrontStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{Flowty.FlowtyStorefrontPublic}>(Flowty.FlowtyStorefrontStoragePath),
                at: Flowty.FlowtyStorefrontPublicPath
            )
        }

        let namespace = "flowty"
        let cacheStoragePath = CapabilityCache.getPathForCache(namespace)
        if acct.storage.borrow<&CapabilityCache.Cache>(from: cacheStoragePath) == nil {
            let c <- CapabilityCache.createCache(namespace: namespace)
            acct.storage.save(<-c, to: cacheStoragePath)
        }
        let cache = acct.storage.borrow<auth(CapabilityCache.Add, CapabilityCache.Get) &CapabilityCache.Cache>(from: cacheStoragePath)
            ?? panic("capability cache not found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")
        self.nftType = catalogEntry.nftType

        // We need a provider capability, but one is not provided by default so we create one if needed.
        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        let ftAddress = AddressUtils.parseAddress(self.paymentTokenType)!
        let contractName = self.paymentTokenType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: self.paymentTokenType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        // Do we need to setup this vault for the signing account?
        if ftReceiverAddress == acct.address && acct.storage.type(at: ftVaultData.storagePath) == nil {
            acct.storage.save(<- ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)
            acct.capabilities.unpublish(ftVaultData.receiverPath)
            acct.capabilities.unpublish(ftVaultData.metadataPath)

            acct.capabilities.publish(acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath), at: ftVaultData.receiverPath)
            acct.capabilities.publish(acct.capabilities.storage.issue<&{FungibleToken.Vault}>(ftVaultData.storagePath), at: ftVaultData.metadataPath)
        }

        if autoRepaymentEnabled {
            if ftReceiverAddress == acct.address {
                let borrowType = Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>()
                if let provider = cache.getCapabilityByType(resourceType: self.paymentTokenType, capabilityType: CapabilityType(borrowType)!) {
                    self.tokenProvider = provider as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
                } else {
                    self.tokenProvider = acct.capabilities.storage.issueWithType(ftVaultData.storagePath, type: borrowType) as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>
                    cache.addCapability(resourceType: self.paymentTokenType, cap: self.tokenProvider!)
                }
            } else {
                let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

                let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
                let ftProviderCap = child.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("no ft provider found")
                self.tokenProvider = ftProviderCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>?
            }

            assert(self.tokenProvider!.check(), message: "Missing or mis-typed token provider")
        } else {
            self.tokenProvider = nil
        }

        if ftReceiverAddress == acct.address {
            if acct.storage.borrow<&{FungibleToken.Vault}>(from: ftVaultData.storagePath) == nil {
                acct.storage.save(<- ftVaultData.createEmptyVault(), to: ftVaultData.storagePath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(ftVaultData.storagePath),
                    at: ftVaultData.receiverPath
                )

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Balance}>(ftVaultData.storagePath),
                    at: ftVaultData.metadataPath
                )
            }

            self.tokenReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.tokenReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)!
        }

        assert(self.tokenReceiver.check(), message: "Missing or mis-typed token receiver")

        if nftProviderAddress == acct.address {
            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            let borrowType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
            if let provider = cache.getCapabilityByType(resourceType: self.nftType, capabilityType: CapabilityType(borrowType)!) {
                self.nftProvider = provider as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            } else {
                self.nftProvider = acct.capabilities.storage.issueWithType(catalogEntry.collectionData.storagePath, type: borrowType) as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
                cache.addCapability(resourceType: self.nftType, cap: self.nftProvider!)
            }

            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")

            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>

            let receiverCap = child.getPublicCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.storage.borrow<auth(Flowty.List) &Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath)
            ?? panic("Missing or mis-typed Flowty FlowtyStorefront")

        let tokenVault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: ftVaultData.storagePath)
            ?? panic("Cannot borrow token vault from acct storage")

        self.paymentVault <- tokenVault.withdraw(amount: Flowty.ListingFee)
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.tokenReceiver,
            amount: amount
        )
        self.storefront.createListing(
            payment: <-self.paymentVault,
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            fusdProviderCapability: self.tokenProvider,
            nftType: self.nftType,
            nftID: listItemID,
            amount: amount,
            interestRate: interestRate,
            term: term,
            paymentVaultType: self.paymentTokenType,
            paymentCuts: [paymentCut],
            expiresAfter: loanExpiresAfter
        )
    }
}`

const delistLoanListingTransaction = (config: Config): string => ``

const delistLoanListingTransactionCrescendo = (
	config: Config
): string => `import Flowty from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: auth(Flowty.Cancel) &Flowty.FlowtyStorefront

	prepare(acct: auth(Storage) &Account) {
		self.storefront = acct.storage.borrow<auth(Flowty.Cancel) &Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath)
			?? panic("Missing or mis-typed Flowty.FlowtyStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`
