import { OpensearchFlowNFT } from "flowty-common"
import { FlowtyButton } from "../../../FlowtyButton"
import { getDapperInventoryURL } from "../ListingModalView/Transfer/Transfer"
import FlowtyLink from "./FlowtyLink"

interface DapperDisplayProps {
	openSearchFlowNFT: OpensearchFlowNFT | null
}
export const DapperDisplay: React.FunctionComponent<DapperDisplayProps> = ({
	openSearchFlowNFT,
}) => {
	const dapperURL = getDapperInventoryURL(openSearchFlowNFT)

	return (
		<div className='w-full flex flex-col py-4 px-3 justify-center text-white'>
			<span className='py-2 text-med font-bold'>
				Feature can only be accessed via a non-Dapper Wallet or using Account
				Linking
			</span>
			<span className='py-2 font-semibold text-sm md:text-nowrap'>
				Account Linking:
			</span>
			<FlowtyLink
				size='small'
				href='https://flowty.substack.com/p/account-linking-on-flowty'
				text='How to sign up for Account Linking'
			/>
			<span className='py-2 font-semibold text-sm md:text-nowrap'>
				Transfer to Non-Dapper Wallet:
			</span>
			<span className='py-2 text-xs md:text-nowrap'>
				Use button to transfer an NFT to non-Dapper Wallet via Dapper inventory
			</span>
			<div className='w-full flex justify-center pt-3'>
				<FlowtyButton
					text='VIEW IN DAPPER INVENTORY'
					variant='secondary'
					btnSize='small'
					onClick={() => {
						window.open(
							dapperURL || "https://accounts.meetdapper.com/inventory",
							"_blank"
						)
					}}
				/>
			</div>
		</div>
	)
}
