import React, { PropsWithChildren } from "react"
import { TooltipElement } from "../InfoText"
import { ReactComponent as FUSDIcon } from "../../assets/media/tokens/fusd.svg"
import { ReactComponent as FLOWIcon } from "../../assets/media/tokens/flow.svg"
import { ReactComponent as USDTIcon } from "../../assets/media/tokens/usdt.svg"
import { ReactComponent as USDCIcon } from "../../assets/media/tokens/usdc.svg"
import { ReactComponent as DapperDUCIcon } from "../../assets/media/tokens/dapperDUC.svg"
import { ReactComponent as DapperFUTIcon } from "../../assets/media/tokens/dapperFUT.svg"
import { ReactComponent as SloppyIcon } from "../../assets/media/tokens/sloppy.svg"
import { DEFAULT_TOKEN_TYPE } from "../../util/settings"
import numeral from "numeral"
import isNil from "lodash/isNil"
import { truncateCurrencyAmount } from "../../util/truncateValueUtil"

type TokenCompProps = PropsWithChildren<{
	isSmall?: boolean
	isMedium?: boolean
	isLarge?: boolean
	innerText?: string
	token?: string
	justifyStart?: boolean
}>

const TokenComp: React.FC<TokenCompProps> = ({
	isSmall,
	isMedium,
	isLarge,
	innerText,
	children,
	token = "fusd",
	justifyStart = false,
}) => {
	let icon
	let tooltip
	switch (token.toLowerCase()) {
		case "fusd":
			icon = (
				<FUSDIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "FUSD"
			break
		case "flow":
			icon = (
				<FLOWIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "FLOW"
			break
		case "fut":
			icon = (
				<DapperFUTIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "Dapper FLOW"
			break
		case "usdc":
			icon = (
				<USDCIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "USDC"
			break
		case "duc":
			icon = (
				<DapperDUCIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "Dapper Balance"
			break
		case "sloppy":
		case "sloppystakes":
			icon = (
				<SloppyIcon
					style={{ backgroundSize: "20px 20px" }}
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "Sloppy Stakes"
			break
		case "usdt":
			icon = (
				<USDTIcon
					height={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 30}
					width={isSmall ? 13 : isMedium ? 18 : isLarge ? 24 : 27}
				/>
			)
			tooltip = "USDT"
			break
		default:
			return null
	}

	return (
		<div
			className={`nft-price flex items-center ${
				justifyStart ? "justify-start gap-x-2" : "justify-between"
			} ${isSmall ? "small" : isMedium ? "medium" : ""}`}
		>
			<TooltipElement
				Comp={icon}
				tooltipText={tooltip}
				tooltipId='display-token-tooltip'
			/>
			{children}

			{innerText && <p className='text-white text-sm ms-1'>{innerText}</p>}
		</div>
	)
}

export const TokenIcon: React.FC<TokenCompProps> = ({
	isSmall,
	isMedium,
	isLarge,
	innerText,
	token,
	children,
}) => (
	<TokenComp
		isSmall={isSmall}
		isMedium={isMedium}
		isLarge={isLarge}
		innerText={innerText}
		token={token}
	>
		{children}
	</TokenComp>
)

interface TokenAmountProps {
	amount?: number
	isSmall?: boolean
	isMedium?: boolean
	largeText?: boolean
	token?: string
	fontSize?: string
	justifyStart?: boolean
	testId?: string
}

export const TokenAmount: React.FC<TokenAmountProps> = ({
	amount,
	fontSize,
	isMedium = false,
	isSmall,
	largeText,
	justifyStart,
	token = DEFAULT_TOKEN_TYPE.toLowerCase(),
	testId,
}) => (
	<TokenComp
		isSmall={isSmall}
		token={token}
		isMedium={isMedium}
		justifyStart={justifyStart}
	>
		<div
			className={`text-bold ${
				fontSize ? fontSize : "text-base"
			} font-montserrat inline-block ms-1 ${largeText && "text-sm"}`}
			data-testid={testId}
		>
			{!isNil(amount) && numeral(amount).format("0,0.00")}
		</div>
	</TokenComp>
)

export const AbbreviatedTokenAmount: React.FC<TokenAmountProps> = ({
	amount,
	isSmall,
	largeText,
	token = DEFAULT_TOKEN_TYPE.toLowerCase(),
}) => (
	<TokenComp isSmall={isSmall} token={token}>
		<div
			className={`text-bold text-base inline-block font-montserrat ms-1 ${
				largeText && "text-sm"
			}`}
		>
			{!isNil(amount) && truncateCurrencyAmount(amount)}
		</div>
	</TokenComp>
)
