import { FlowtyButton } from "ds-flowty"
import {
	calcAPRInterest,
	getContractNameFromType,
	getTokenFromType,
	isContractWithValuation,
	ListingAvailableData,
	ListingStatus,
	nftTypeAndIdToLocationData,
	Order,
	solveWithAmountAndInterest,
	SupportedTokens,
	truncateString,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import numeral from "numeral"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ReactComponent as KeepInMindIcon } from "../../assets/media/loanInfo/keepInMindIcon.svg"
import { ReactComponent as NftCardTimer } from "../../assets/media/nftCard/nft_card_timer_icon.svg"
import { useFlowtyModalsContext } from "../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { useOpensearchNFT } from "../../hooks/data/nfts/useOpensearchNFT"
import { composeUserProfilePath } from "../../routes"
import { getTokenSpotPrice } from "../../services/firestore/SpotPriceService"
import { FundingAvailableData } from "../../services/flow/FundingService"
import { AuthStoreProp } from "../../stores/AuthStore"
import { Log } from "../../util/Log"
import { getDurationPeriod } from "../../util/nftDataUtil"
import { MAX_NAME_CHARACTERS } from "../../util/settings"
import { Alert } from "../Alert/Alert"
import { Col } from "../GridLayout/Col/Col"
import { Row } from "../GridLayout/Row/Row"
import { ConditionalTooltip } from "../Shared/ConditionalTooltip"
import { ValuationLabel } from "../Shared/OverlayValuation"
import { TokenAmount } from "../Tokens/tokenDisplays"
import { DelistLoan } from "../TxButtons/DelistLoan"
import { RepayLoan } from "../TxButtons/RepayLoan"
interface LoanListingInfoProps extends AuthStoreProp {
	listing: ListingAvailableData
	funding: FundingAvailableData | null
	isListingActive: boolean
	isListingExpired: boolean
}

const LoanListingInfo: React.FC<LoanListingInfoProps> = ({
	authStore,
	listing,
	funding,
	isListingActive,
	isListingExpired,
}) => {
	const locationData = nftTypeAndIdToLocationData(
		listing.nftType,
		listing.nftID
	)

	const { selectCard } = useFlowtyModalsContext()
	const { openSearchNFT } = useOpensearchNFT({
		collectionAddress: locationData.contract.address,
		collectionName: locationData.contract.name,
		nftId: locationData.nftID,
		resourceName: locationData.resourceName,
	})
	const { listingResourceId } = useParams()
	const listedOrder = openSearchNFT?.orders?.find((order: Order) => {
		return order.listingResourceID === listingResourceId
	})

	const token =
		"paymentTokenType" in listing
			? getTokenFromType(listing.paymentTokenType)
			: SupportedTokens.FLOW

	const currentUser = authStore?.loggedUser?.addr

	const [tokenPrice, setTokenPrice] = useState<number | null>(null)
	const contractName = getContractNameFromType(listing.nftType)
	const hasValuationProvider = isContractWithValuation(contractName)

	const ltvLabel = hasValuationProvider ? (
		<ValuationLabel
			contractName={contractName}
			nftID={listing.nftID.toString()}
			labelType={"LTV"}
		/>
	) : undefined

	useEffect(() => {
		if (token === SupportedTokens.FLOW) {
			getTokenSpotPrice(token).then(sp => {
				Log("setTokenPrice", sp.value)
				setTokenPrice(sp.value)
			})
		} else {
			setTokenPrice(1)
		}
	}, [token])

	const renderFundingOrDelist = (
		isListedFromLoggedUser: boolean,
		isOwner: boolean
	) => {
		if (listing.state === ListingStatus.Funded && funding) {
			if (isListedFromLoggedUser) {
				return (
					<RepayLoan
						className='flex m-auto justify-end w-fit'
						funding={funding}
						authStore={authStore}
					/>
				)
			}
		} else if (listing.state !== ListingStatus.Expired && isOwner) {
			return (
				<>
					<DelistLoan
						className='flex m-auto justify-end w-fit'
						listing={listing}
						isOrderListedByLoggedAccount={isListedFromLoggedUser}
						isOwner={isOwner}
					/>
					{!isListedFromLoggedUser && isOwner && listing.state === "LISTED" && (
						<div className='py-2 text-sm font-normal text-center text-orange-500'>
							NFT was listed while connected with
							{` ${listing?.flowtyStorefrontAddress}`} as Main. Please sign in
							with {` ${listing?.flowtyStorefrontAddress}`} to delist.
						</div>
					)}
				</>
			)
		} else {
			return (
				<div className='flex m-auto justify-end w-fit'>
					<FlowtyButton
						text={"FUND"}
						onClick={() =>
							selectCard({
								selected: {
									contractAddress: openSearchNFT?.contractAddress ?? "",
									contractName: openSearchNFT?.contractName ?? "",
									nftID: openSearchNFT?.id ?? "",
									nftType: openSearchNFT?.type ?? "",
								},
								selectedSinglePurchase: listedOrder,
							})
						}
						variant={"secondary"}
						btnSize='small'
					/>
				</div>
			)
		}
		return
	}

	const renderActionBtn = () => {
		const userAddresses = authStore?.getAccountSummaryAddresses() ?? []
		const isOwner = userAddresses.includes(listing.flowtyStorefrontAddress)

		const isOrderListedByLoggedAccount =
			listing.flowtyStorefrontAddress === currentUser

		if (isListingActive) {
			return renderFundingOrDelist(isOrderListedByLoggedAccount, isOwner)
		} else if (isOrderListedByLoggedAccount) {
			return (
				<DelistLoan
					className='mt-3 text-center'
					listing={listing}
					isOrderListedByLoggedAccount={isOrderListedByLoggedAccount}
					isOwner={isOwner}
				/>
			)
		}
		return null
	}

	const calculatedValues = solveWithAmountAndInterest(
		{
			amount: Number(listing.amount || 0),
			interest: Number(listing.interestRate || 0),
			term: Number(listing.term || 0),
		},
		listing.royaltyRate || 0
	)

	const apr = calcAPRInterest(
		calculatedValues ? calculatedValues.periodicInterest : 0,
		calculatedValues ? calculatedValues.term : 0
	)

	const listingName = listing?.detail?.nft?.title ?? ""
	const truncatedName = truncateString(listingName, MAX_NAME_CHARACTERS)

	return (
		<>
			<div className='nft-info-card bg-greyDark w-full'>
				<Row className='nft-info-row flex'>
					<Col>
						<ConditionalTooltip
							showTooltip={listingName !== truncatedName}
							tooltipText={listingName}
						>
							<h1 className='inline'>{truncatedName}</h1>
						</ConditionalTooltip>
					</Col>
					<Col className='pt-1'>
						<div className='flex-row-reverse flex items-center'>
							{token === SupportedTokens.FLOW && tokenPrice && (
								<p>
									(USD $
									{calculatedValues &&
										numeral(
											(calculatedValues?.marketplaceAmount || 0) * tokenPrice
										).format("0,0.00")}
									)
								</p>
							)}
							<TokenAmount
								amount={calculatedValues?.marketplaceAmount || 0}
								token={token}
							/>
						</div>
					</Col>
				</Row>
				<div className='nft-info-separator bg-secondary' />
				<table className='table-borderless'>
					<tbody>
						<tr>
							<td>Repayment</td>
							<td className='text-end'>
								<div className='flex-row-reverse flex items-center'>
									{token === SupportedTokens.FLOW && tokenPrice && (
										<p className='text-sm'>
											(USD $
											{numeral(
												(calculatedValues?.totalRepayment || 0) * tokenPrice
											).format("0,0.00")}
											)
										</p>
									)}
									<TokenAmount
										amount={calculatedValues?.totalRepayment || 0}
										isSmall
										token={token}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<td>Loan Rate</td>
							<td className='flex justify-end'>
								{calculatedValues &&
									(calculatedValues.periodicInterest * 100).toFixed(2)}
								%
							</td>
						</tr>
						<tr>
							<td>APR</td>
							<td className='flex justify-end'>
								{numeral(apr).format("0,0.00")}%
							</td>
						</tr>
						<tr>
							<td>
								<NftCardTimer className='inline' />
								<p className='pt-1 inline ms-1'>Duration</p>
							</td>
							<td className='flex justify-end'>
								{calculatedValues && getDurationPeriod(calculatedValues.term)}
							</td>
						</tr>
						{listing.valuations && (
							<tr>
								<td>{ltvLabel}</td>
								<td className='flex justify-end'>
									{Math.round(
										// This is valid for Flovatar as well since we only have one provider
										// TODO: Should be derived same way as the label
										listing.valuations.aggregate.loanToValueRatio * 100
									)}
									%
								</td>
							</tr>
						)}
						<tr>
							<td>Auto Repayment</td>
							<td className='flex justify-end'>
								{listing.enabledAutoRepayment ? (
									<input
										disabled
										checked={listing.enabledAutoRepayment}
										type='checkbox'
										className='w-4 h-4 border-gray-300 rounded cursor-not-allowed focus:ring-primary text-primary accent-pink-500'
									/>
								) : (
									<span className='px-1 font-extrabold text-red-600 bg-gray-400 rounded-sm'>
										X
									</span>
								)}
							</td>
						</tr>
						<tr>
							<td>Listed By</td>
							<td className='flex justify-end'>
								<Link
									to={composeUserProfilePath(listing.flowtyStorefrontAddress)}
								>
									{listing.flowtyStorefrontAddress === currentUser
										? "You"
										: listing.flowtyStorefrontAddress}
								</Link>
							</td>
						</tr>
						{funding?.lender && (
							<tr>
								<td>Lender</td>
								<td className='flex justify-end'>
									<Link to={composeUserProfilePath(funding.lender)}>
										{funding.lender === currentUser ? "You" : funding.lender}
									</Link>
								</td>
							</tr>
						)}
						{listing.flowtyStorefrontAddress === currentUser &&
							listing.state !== ListingStatus.Expired && (
								<tr>
									<div className='flex flex-col gap-4'>
										<td>
											<table>
												<tr>
													<td>
														<u>Borrower Information:</u>
													</td>
												</tr>
												<tr>
													<td>Borrowed Amount:</td>
													<td>
														<TokenAmount
															amount={calculatedValues?.amount || 0}
															isSmall
															token={token}
														/>
													</td>
												</tr>
												<tr>
													<td>Repayment Amount:</td>
													<td>
														<TokenAmount
															amount={calculatedValues?.repaymentDue || 0}
															isSmall
															token={token}
														/>
													</td>
												</tr>
											</table>
										</td>
									</div>
								</tr>
							)}
					</tbody>
				</table>
				<div className='justify-end'>
					{!isListingExpired &&
						listing?.state !== ListingStatus.Delisted &&
						listing?.state !== ListingStatus.Repaid &&
						renderActionBtn()}
				</div>
			</div>
			{!isListingExpired && listing?.state === ListingStatus.Listed && (
				<Alert variant='dark' className='mt-2'>
					<div className='flex items-center'>
						<KeepInMindIcon className='mt-1 text-xl me-3' />
						<div className='w-full'>
							<h5 className='text-xl text-white'>Please Note</h5>
							<p className='mt-2 text-lightText'>
								There is no guarantee that you will receive the Repayment
								amount. If the Borrower does not repay (defaults), you will
								receive the collateral in lieu of a repayment.
							</p>
						</div>
					</div>
				</Alert>
			)}
		</>
	)
}

export default inject("authStore")(observer(LoanListingInfo))
