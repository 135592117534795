import { Trait } from "flowty-common"
import { useEffect, useState } from "react"

export type TraitWithUrl = Trait & { url: string }

type CustomRenderFunction = (item: TraitWithUrl) => JSX.Element

interface TraitGridProps {
	traits: TraitWithUrl[]
	customRender: CustomRenderFunction
}
export const TraitGrid: React.FC<TraitGridProps> = ({
	traits,
	customRender,
}) => {
	const [displayValue, setDisplayValue] = useState(14)
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		const updateDisplayValue = () => {
			if (window.innerWidth < 768) {
				setDisplayValue(5)
			} else {
				setDisplayValue(14)
			}
		}

		updateDisplayValue()

		window.addEventListener("resize", updateDisplayValue)

		return () => {
			window.removeEventListener("resize", updateDisplayValue)
		}
	}, [])

	if (!traits.length)
		return (
			<span className='text-[1rem] md:text-[1.125rem] font-bold font-montserrat text-white'>
				No Properties
			</span>
		)

	return (
		<div className='flex flex-wrap gap-4'>
			{traits
				.slice(0, isOpen ? traits.length : displayValue)
				.map(trait => customRender(trait))}
			{traits.length > displayValue && (
				<div
					className={`flex flex-col items-center justify-center cursor-pointer rounded-[5px] py-4 px-6 shrink-0 gap-[8px] shadow-[0_4px_18px_0_rgba(0,0,0,0.25)] bg-[#ffffff0d] hover:bg-[#ffffff47] font-montserrat text-white w-full 2xl:w-[165px] border  ${
						isOpen ? "border-primary" : "border-[#ffffff47]"
					}`}
					onClick={() => setIsOpen(!isOpen)}
				>
					<div
						className={`flex flex-row items-center gap-[0.5rem] ${
							isOpen ? "text-primary" : "text-white"
						}`}
					>
						<span className='font-montserrat text-[0.875rem] font-[800] uppercase'>
							{isOpen ? "View Less" : "View More"}
						</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='19'
							height='18'
							viewBox='0 0 19 18'
							fill='none'
							className={`w-[1.125rem] h-[1.125rem]`}
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M3.77273 3.27273C3.98972 3.05573 4.28403 2.93382 4.59091 2.93382L9.5 2.93383C10.139 2.93383 10.6571 3.45187 10.6571 4.09091C10.6571 4.72995 10.139 5.24799 9.5 5.24799L5.74799 5.24799L5.74799 9C5.74799 9.63904 5.22995 10.1571 4.59091 10.1571C3.95187 10.1571 3.43383 9.63904 3.43383 9L3.43383 4.09091C3.43383 3.78403 3.55573 3.48972 3.77273 3.27273ZM8.34292 13.9091C8.34292 13.2701 8.86096 12.752 9.5 12.752L13.252 12.752V9C13.252 8.36096 13.7701 7.84292 14.4091 7.84292C15.0481 7.84292 15.5662 8.36096 15.5662 9L15.5662 13.9091C15.5662 14.5481 15.0481 15.0662 14.4091 15.0662L9.5 15.0662C8.86096 15.0662 8.34292 14.5481 8.34292 13.9091Z'
								fill={`${isOpen ? "#04E5A3" : "white"}`}
							/>
						</svg>
					</div>
				</div>
			)}
		</div>
	)
}
