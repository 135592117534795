import { nftTypeAndIdToLocationData, OpensearchFlowNFT } from "flowty-common"
import { AccountSummaries } from "flowty-sdk"
import { useCallback, useContext } from "react"
import { flowty } from "../../../../config/config"
import { useCatalogContext } from "../../../../contexts/CatalogContext"
import { useHybridCustodyContext } from "../../../../contexts/HybridCustodyContext"
import { useMarketplaceAppContext } from "../../../../contexts/MarketplaceAppContext/MarketplaceAppContext"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { Log } from "../../../../util/Log"
import { BulkNftFormType } from "../../../BulkActions/BulkActionsPopUp/hooks/useBulkActionsPopUp"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"

interface NftListViewHookPropsResponse {
	accountSummaries: AccountSummaries | undefined
	bulkListActive: boolean
	bulkPurchaseSelecteds: Map<string, BulkNftFormType>
	bulkSelectedLength: number
	canScroll: boolean
	catalogCache: Set<string>
	denylistCache: Set<string>
	hasProviderFn: (nft: OpensearchFlowNFT) => boolean | null
	hasProvinerPublicFn: (nft: OpensearchFlowNFT) => boolean | null
	verifyHasOwner: (nft: OpensearchFlowNFT) => Promise<boolean>
	hits: OpensearchFlowNFT[]
	initialLoading: boolean
	isFilterHidden: boolean
	isLoading: boolean
	isLoggedUserProfile: boolean
	isUnsupportedSelection: boolean
	isProfile: boolean
	lastRef: (node: HTMLElement | null) => void
	onBulkPurchaseSelected: (nft: OpensearchFlowNFT) => void
	publicAccountView: {
		addr: string | undefined | null
		avatar: string | undefined | null
		userName: string | undefined | null
	}
}

export const useNftListView = (): NftListViewHookPropsResponse => {
	const {
		canScroll,
		hits,
		orderFilters,
		page,
		pageCount,
		paymentTokens,
		publicAccount,
		setPage,
		isFilterHidden,
		isLoading,
		initialLoading,
	} = useContext<OpenSearchContextValues<OpensearchFlowNFT>>(OpenSearchContext)

	const isProfile = location.pathname.includes("/profile")
	const { hybridCustodyNFTStatus, publicAccountNftProviders } =
		useHybridCustodyContext()
	const { catalogCache, denylistCache } = useCatalogContext()
	const {
		bulkSelectedLength,
		bulkPurchaseSelecteds,
		onBulkPurchaseSelected,
		bulkListActive,
		loggedUserAddress,
		accountSummaries: loggedAccountSummaries,
	} = useMarketplaceAppContext()

	const isLoggedUserProfile = loggedUserAddress === publicAccount?.addr

	const isDapperWalletSelected = paymentTokens?.dapper
	const isUnsupportedSelection =
		!!(orderFilters.loan || orderFilters.rental) && !!isDapperWalletSelected

	const lastRef = useInfiniteScroll(
		{
			isLoading: isLoading,
			onInfiniteScroll() {
				Log("Infinite Scroll Collection Cards", {
					isLoading,
					page,
					pageCount,
				})

				if (!isLoading) {
					setPage(page + 1)
				}
			},
		},
		[hits?.length, page, pageCount, isLoading]
	)

	const verifyHasOwner = useCallback(async (nft: OpensearchFlowNFT) => {
		const locationData = nftTypeAndIdToLocationData(nft.type, nft.id)

		try {
			const item = await flowty.api.getItem({
				contractAddress: nft.contractAddress,
				contractName: nft.contractName,
				nftID: nft.id,
				resourceName: locationData.resourceName,
			})

			return !!item.owner
		} catch (err) {
			Log({ err })
			return true
		}
	}, [])

	const hasProviderFn = useCallback(
		(nft: OpensearchFlowNFT): boolean | null => {
			if (!isProfile || nft?.owner === publicAccount?.addr) {
				return true
			}

			const paths = hybridCustodyNFTStatus[nft?.owner]
			if (paths === undefined) {
				return null
			}

			return (
				paths?.[nft?.contractAddress.concat(".").concat(nft?.contractName)]
					?.length > 0
			)
		},
		[hybridCustodyNFTStatus]
	)

	const hasProvinerPublicFn = useCallback(
		(nft: OpensearchFlowNFT): boolean | null => {
			if (nft?.owner === publicAccount?.addr) {
				return true
			}

			const paths = publicAccountNftProviders[nft?.owner]
			if (paths === undefined) {
				return null
			}

			return (
				paths?.[nft?.contractAddress.concat(".").concat(nft?.contractName)]
					?.length > 0
			)
		},
		[publicAccountNftProviders]
	)

	const accountSummaries: AccountSummaries | undefined =
		location.pathname.includes("/profile")
			? loggedAccountSummaries
			: publicAccount?.accountSummaries

	const publicAccountView = {
		addr: publicAccount?.addr,
		avatar: publicAccount?.avatar,
		userName: publicAccount?.userName,
	}

	return {
		accountSummaries,
		bulkListActive,
		bulkPurchaseSelecteds,
		bulkSelectedLength,
		canScroll,
		catalogCache,
		denylistCache,
		hasProviderFn,
		hasProvinerPublicFn,
		hits,
		initialLoading,
		isFilterHidden,
		isLoading,
		isLoggedUserProfile,
		isProfile,
		isUnsupportedSelection,
		lastRef,
		onBulkPurchaseSelected,
		publicAccountView,
		verifyHasOwner,
	}
}
