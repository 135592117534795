import React, { useContext, useState } from "react"
import { formatSecondsAsDays } from "../../../../util/dateTimeUtils"
import { useFlowtyFieldInput } from "../../../Modals/Listings/shared/FieldInput/useFlowtyFieldInput"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"

export const RentalForm: React.FunctionComponent = () => {
	const { orderFilters, setOrderFilters } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const [rentalTerms, setRentalTerms] = useState({
		max: formatSecondsAsDays(orderFilters?.rental?.["term"]?.max) ?? "",
		min: formatSecondsAsDays(orderFilters?.rental?.["term"]?.min) ?? "",
	})

	const adjustValueDate = (value: number): number => value * 60 * 60 * 24

	const onChange = (
		value: number,
		fieldName: string,
		minOrMax: "min" | "max"
	): void => {
		const copyFilters = { ...orderFilters }
		let copyValue = value
		if (fieldName === "term") {
			copyValue = adjustValueDate(value)

			setRentalTerms({
				...rentalTerms,
				[minOrMax]: value,
			})
		}

		if (copyValue) {
			if (copyFilters.rental) {
				copyFilters.rental[fieldName] = {
					...copyFilters.rental[fieldName],
					[minOrMax]: copyValue,
				}
			} else {
				copyFilters.rental = {
					[fieldName]: {
						[minOrMax]: copyValue,
					},
				}
			}
		} else {
			if (copyValue) {
				copyFilters?.rental?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters.rental[field.name].max
			} else {
				copyFilters?.rental?.[fieldName] &&
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore-next-line
					delete copyFilters?.rental?.[fieldName]
			}
		}

		setOrderFilters(copyFilters)
	}

	useFlowtyFieldInput()

	return (
		<div className='flex flex-col gap-[1.5rem]'>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] font-[600] text-white'>
					Rental Fee
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='rental-fee-min'
						value={orderFilters?.rental?.["amount"]?.min ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						name='amount'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='rental-fee-max'
						value={orderFilters?.rental?.["amount"]?.max ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						name='amount'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] text-left font-[600]'>
					Refundable Deposit
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='refundable-deposit-min'
						value={orderFilters?.rental?.["deposit"]?.min ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "min")
						}
						name='deposit'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='refundable-deposit-max'
						value={orderFilters?.rental?.["deposit"]?.max ?? ""}
						onChange={e =>
							onChange(Number(e.target.value), e.target.name || "", "max")
						}
						name='deposit'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
			<div className='text-left flex flex-col gap-[0.5rem]'>
				<span className='text-[0.875rem] text-left font-[600]'>
					Duration (Days)
				</span>
				<div className='flex items-center space-x-2'>
					<input
						data-testid='rental-duration-min'
						value={rentalTerms.min || ""}
						onChange={e => {
							onChange(Number(e.target.value), e.target.name || "", "min")
						}}
						name='term'
						type='number'
						placeholder='Min'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
					<input
						data-testid='rental-duration-max'
						value={rentalTerms.max || ""}
						onChange={e => {
							onChange(Number(e.target.value), e.target.name || "", "max")
						}}
						name='term'
						type='number'
						placeholder='Max'
						className='w-1/2 px-3 py-0.5 font-semibold bg-transparent border border-[#FCFCFC] rounded-[5px]'
					/>
				</div>
			</div>
		</div>
	)
}
