import React from "react"

interface SeekProps {
	direction: "left" | "right"
	handleSeek: (direction: "left" | "right") => void
}

const Seek = ({ direction, handleSeek }: SeekProps) => {
	return (
		<button
			aria-label={direction}
			onKeyDown={() => handleSeek(direction)}
			className='flex items-center justify-center w-[41px] h-[41px] rounded-full p-[8px] bg-seekStepBackground hover:opacity-80'
			onClick={() => handleSeek(direction)}
		>
			<svg
				width='24'
				height='25'
				viewBox='0 0 24 25'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				transform={`rotate(${direction === "left" ? 180 : 0})`}
			>
				<path
					d='M9 5.29834L16 12.2983L9 19.2983'
					stroke='#F8F9FA'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</button>
	)
}

export default Seek
