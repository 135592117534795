import { FCLTransactionResult, TokenMetadata } from "flowty-common"
import {
	Err,
	expirationDaysToSeconds,
	getCatalogEntryForType,
	sendMutation,
} from "../../utils/Utils"
import {
	CreateStorefrontListing,
	RemoveStorefrontListing,
} from "./StorefrontListingTypes"
import { Config, ContractNames, StorefrontListingRequest } from "../../types"
import {
	getBulkDelistStorefrontListingTxn,
	getBulkStorefrontListingTxn,
	getDelistStorefrontListingTxn,
	getStorefrontListingTxn,
} from "./transactions"
import { encodeStorefrontListingRequests } from "./bulkListUtils"
import { PaymentTokenToIdentifier } from "../../utils/TokensIdentifier"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class StorefrontListing {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	createStorefrontListing = async ({
		nftData,
		buyer,
		expiry,
		salePrice,
		token,
		txAvailableCallback,
		nftProviderPathIdentifier,
		ftReceiverAddress,
	}: CreateStorefrontListing): Promise<FCLTransactionResult> => {
		const expiryInSeconds = expirationDaysToSeconds(expiry)
		const price = Number(salePrice).toFixed(4)
		if (!nftData) throw new Error("Flow NFT it's null")
		const { contractAddress, contractName, id, type, nftOwner } = nftData

		const catalogIdentifier = await getCatalogEntryForType(type, this.config)
		const txContent = getStorefrontListingTxn(
			this.config,
			token,
			nftData,
			!!catalogIdentifier
		)

		const txArguments = []
		const isDapper = ["DUC", "FUT"].includes(token.symbol)

		if (catalogIdentifier && isDapper) {
			// Dapper Wallet transaction
			txArguments.push(
				fcl.arg(catalogIdentifier, t.String),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(price.toString(), t.UFix64),
				fcl.arg(null, t.Optional(t.String)), // TODO: we can allow custom-named listings if we let this get set somehow. Maybe for bundles?
				fcl.arg(buyer, t.Optional(t.String)), // TODO: this is the buyer for when private listings are allowed
				fcl.arg(expiryInSeconds.toString(), t.UInt64)
			)
		} else if (catalogIdentifier) {
			// Non-dapper but the collection is in the NFT Catalog
			const providerArg = this.config.crescendo
				? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
				: fcl.arg(nftProviderPathIdentifier, t.String)

			txArguments.push(
				fcl.arg(catalogIdentifier, t.String),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(price.toString(), t.UFix64),
				fcl.arg(null, t.Optional(t.String)), // TODO: we can allow custom-named listings if we let this get set somehow. Maybe for bundles?
				fcl.arg(buyer, t.Optional(t.String)), // TODO: this is the buyer for when private listings are allowed
				fcl.arg(expiryInSeconds.toString(), t.UInt64),
				providerArg,
				fcl.arg(nftOwner, t.Address),
				fcl.arg(ftReceiverAddress, t.Address)
			)
		} else {
			// Non-dapper and the collection is not in the NFT Catalog
			const providerArg = this.config.crescendo
				? fcl.arg(nftProviderPathIdentifier || "0", t.UInt64)
				: fcl.arg(nftProviderPathIdentifier, t.String)

			if (this.config.crescendo) {
				txArguments.push(fcl.arg(nftData.type, t.String))
			} else {
				txArguments.push(
					fcl.arg(contractAddress, t.Address),
					fcl.arg(contractName, t.String)
				)
			}

			txArguments.push(
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(price.toString(), t.UFix64),
				fcl.arg(null, t.Optional(t.String)), // TODO: we can allow custom-named listings if we let this get set somehow. Maybe for bundles?
				fcl.arg(buyer, t.Optional(t.String)), // TODO: this is the buyer for when private listings are allowed
				fcl.arg(expiryInSeconds.toString(), t.UInt64),
				providerArg,
				fcl.arg(nftOwner, t.Address),
				fcl.arg(ftReceiverAddress, t.Address)
			)
		}

		if (this.config.crescendo && !isDapper) {
			txArguments.push(
				fcl.arg(PaymentTokenToIdentifier[token.symbol], t.String)
			)
		}

		console.log("createStorefrontListing", {
			buyer,
			expiry,
			ftReceiverAddress,
			nftData,
			nftProviderPathIdentifier,
			salePrice,
			token,
			txArguments,
		})

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}

	removeStorefrontListing = async ({
		listingResourceID,
		listingType,
		txAvailableCallback,
	}: RemoveStorefrontListing): Promise<FCLTransactionResult> => {
		console.log("removeStorefrontListing", { listingResourceID, listingType })

		const txContent = getDelistStorefrontListingTxn(this.config, listingType)
		const txArguments = [fcl.arg(listingResourceID.toString(), t.UInt64)]

		try {
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("removeStorefrontListing", e)
			throw e
		}
	}

	removeBulkListing = async (
		listingResourceIDs: string[],
		listingType: string,
		txAvailableCallback: (transactionId: string) => void
	): Promise<FCLTransactionResult> => {
		const txArguments = [fcl.arg(listingResourceIDs, t.Array(t.UInt64))]
		const txContent = getBulkDelistStorefrontListingTxn(this.config)

		try {
			console.log("bulk delisting...", { txArguments, txContent })
			const res = await sendMutation(
				txContent,
				txArguments,
				txAvailableCallback
			)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("removeBulkListing", e)
			throw e
		}
	}

	createStorefrontBulkListing = async (
		requests: StorefrontListingRequest[],
		paymentReceiverAddress: string,
		paymentReceiverPathIdentifier: string,
		token: TokenMetadata,
		txAvailableCallback: (transactionId: string) => void
	): Promise<FCLTransactionResult | null> => {
		const paymentReceiverPath = {
			domain: "public",
			identifier: paymentReceiverPathIdentifier,
		}

		const paymentVaultTypeIdentifier = this.config.getIdentifier(
			token.contractName as ContractNames,
			"Vault"
		)

		const txContent = getBulkStorefrontListingTxn(this.config, token)
		const transactionTypeIdentifier = this.config.getIdentifier(
			"TransactionTypes",
			"StorefrontListingRequest"
		)

		const encodedStorefrontListingRequests = encodeStorefrontListingRequests(
			requests,
			transactionTypeIdentifier,
			this.config.crescendo,
			fcl.arg,
			t
		)

		const args = ["DUC", "FUT"].includes(token.symbol)
			? [encodedStorefrontListingRequests]
			: [
					encodedStorefrontListingRequests,
					fcl.arg(paymentReceiverAddress, t.Address),
					fcl.arg(paymentReceiverPath, t.Path),
					fcl.arg(paymentVaultTypeIdentifier, t.String),
			  ]

		try {
			const res = sendMutation(txContent, args, txAvailableCallback)
			return await res
		} catch (err) {
			console.error(err)
			throw err
		}
	}
}
