export const FlowtyClaimableBadge: React.FunctionComponent = () => {
	return (
		<div
			className={
				"rounded flex items-center gap-1 border px-[6px] py-[4px] bg-[#69FFC03D] border-[#69FFC085]"
			}
		>
			<p className={"text-[10px] font-[600] leading-none text-[#69FFC0CC]"}>
				CLAIMABLE
			</p>
		</div>
	)
}
