import { Trait } from "flowty-common"

export const traitNames = [
	"#1 Serial",
	"Jersey Match",
	"Perfect Serial",
] as const

interface SpecialTsProps {
	traits?: Trait[]
}

export type TraitName = (typeof traitNames)[number]

export const returnTsSpecialTraits = ({
	traits = [],
}: SpecialTsProps): Record<TraitName, boolean> => {
	const result: Record<TraitName, boolean> = {
		"#1 Serial": false,
		"Jersey Match": false,
		"Perfect Serial": false,
	}

	traits.forEach(trait => {
		if (
			traitNames.includes(trait.name as TraitName) &&
			String(trait.value).toLowerCase() === "true"
		) {
			result[trait.name as TraitName] = true
		}
	})

	return result
}
