import {
	NotificationType,
	LoanExpirationWarningNotification,
} from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"
import { useCountdown } from "./Countdown"

export type LoanExpirationNotificationItemsProps =
	LoanExpirationWarningNotification & {
		loggedUserAddress?: string
		queueMessage?: ({
			type,
			id,
		}: {
			type: NotificationType.LoanExpirationWarning
			id: string
		}) => void
	}

export const LoanExpirationNotificationItems: React.FunctionComponent<
	LoanExpirationNotificationItemsProps
> = ({
	amount,
	transactionID,
	imageUrl,
	timestamp,
	title,
	token,
	nftId,
	nftType,
	lenderAddress,
	type,
	listingType,
	listingResourceID,
	loggedUserAddress,
	expiresOn,
}) => {
	const countdown = useCountdown(expiresOn)
	if (countdown === "Expired") {
		return null
	}
	const message = getNotificationMessage({
		amount: amount,
		countdown: countdown,
		loggedUserAddress,
		title,
		token,
		type,
		wallet: lenderAddress,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			token={token}
			nftId={nftId}
			nftType={nftType}
			listingResourceID={listingResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType={listingType}
			type={type}
		/>
	)
}
