import {
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import React, { useCallback, useMemo } from "react"
import { useSwiper } from "swiper/react"
import { LoanCarouselCard } from "../LoanCarouselCard/LoanCarouselCard"
import { PurchaseCarouselCard } from "../PurchaseCarouselCard/PurchaseCarouselCard"
import { RentalCarouselCard } from "../RentalCarouselCard/RentalCarouselCard"

interface CarouselCardProps {
	order: Order
	selectedOrder?: Order | null
	index: number
	updateSelectedOrder: (order: Order) => void
}

export const CarouselCard: React.FunctionComponent<CarouselCardProps> = ({
	order,
	selectedOrder,
	index,
	updateSelectedOrder,
}) => {
	const swiper = useSwiper()

	const handleClick = useCallback(() => {
		swiper.slideTo(index)
		updateSelectedOrder(order)
	}, [selectedOrder, index, order, updateSelectedOrder, swiper])

	const renderCarouselCard = useMemo(() => {
		const type = order?.listingKind

		if (type === "loan") {
			return (
				<LoanCarouselCard
					order={order}
					selectedOrder={selectedOrder as OpenSearchListingAvailableData}
				/>
			)
		}

		if (type === "rental") {
			return (
				<RentalCarouselCard
					order={order}
					selectedOrder={selectedOrder as OpensearchRentalAvailableData}
				/>
			)
		}

		if (type === "storefront") {
			return (
				<PurchaseCarouselCard
					order={order}
					selectedOrder={selectedOrder as OpensearchStorefrontAvailableData}
				/>
			)
		}

		return null
	}, [order, selectedOrder])

	return (
		<div onClick={handleClick} data-testid='carousel-card'>
			{renderCarouselCard}
		</div>
	)
}
