import { AccordionItem } from "./AccordionItem"
import { AccordionItemType } from "./types/FlowtyAccordionTypes"

export interface FlowtyAccordionProps {
	items: AccordionItemType[]
	isCreatorHub?: boolean
}

export const FlowtyAccordion: React.FunctionComponent<FlowtyAccordionProps> = ({
	items,
	isCreatorHub = false,
}) => {
	return (
		<div className='text-white w-full flex flex-col gap-2'>
			{items.map((item, index) => (
				<AccordionItem item={item} key={index} isCreatorHub={isCreatorHub} />
			))}
		</div>
	)
}
