import React from "react"
import { StorefrontHistory } from "./StorefrontHistory"
import { UserProfileTabButtons } from "./UserProfileData"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../stores/AuthStore"
import { CopyWalletAddressButton } from "./CopyWalletAddressButton"
import { FlowtyTabs, TabConfig } from "ds-flowty"

interface UserStorefrontProfileDataProps extends AuthStoreProp {
	activeTabBtn: UserProfileTabButtons
}

const UserStorefrontProfileDataComponent: React.FC<
	UserStorefrontProfileDataProps
> = ({ activeTabBtn = UserProfileTabButtons.Listed, authStore }) => {
	const userActivityTab: TabConfig[] = [
		{ content: <StorefrontHistory />, id: "tag-1", label: "User Activity" },
	]
	return (
		<div className='pt-4 flex flex-col justify-center'>
			<div className='self-center'>
				<div className='flex justify-center items-center'>
					<CopyWalletAddressButton address={`${authStore?.loggedUser?.addr}`} />
				</div>
			</div>

			<div className='user-profile-tabnav mt-3'>
				<FlowtyTabs
					activeTab={"tag-1"}
					tabs={userActivityTab}
					onChange={() => {}}
					type='primary'
					isCenter
				/>
			</div>
		</div>
	)
}

export const UserStorefrontProfileData = inject("authStore")(
	observer(UserStorefrontProfileDataComponent)
)
