import { Config } from "../../types"

export const redeemItemTx = (config: Config): string => {
	return `
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import LostAndFound from ${config.contractAddresses.LostAndFound}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import StringUtils from ${config.contractAddresses.StringUtils}

transaction (receiverAddress: Address, ticketID: UInt64, collectionIdentifier: String, nftTypeIdentifier: String) {
    let receiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let redeemer: Address
    let catalogEntry: NFTCatalog.NFTCatalogMetadata

    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")
        let publicCollectionPath = self.catalogEntry.collectionData.publicPath
        let storageCollectionPath = self.catalogEntry.collectionData.storagePath
        self.redeemer = acct.address
        let contractAddress = AddressUtils.parseAddress(self.catalogEntry.nftType)!
        let segments = StringUtils.split(self.catalogEntry.nftType.identifier, ".")
        let contractName = segments[2] // A.1234.TopShot.NFT (TopShot == contractName)
        let nftContract = getAccount(contractAddress).contracts.borrow<&{NonFungibleToken}>(name: contractName)
            ?? panic("Contract is not of type NonFungibleToken")
        let nftType = CompositeType(nftTypeIdentifier) ?? panic("invalid nftTypeIdentifier")

        if acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: storageCollectionPath) == nil {
            let collection: @{NonFungibleToken.Collection} <- nftContract.createEmptyCollection(nftType: nftType)
            // save it to the account
            acct.storage.save(<-collection, to: storageCollectionPath)

            // create a public capability for the collection
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                at: publicCollectionPath
            )
        }

        self.receiver = getAccount(receiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)
        assert(self.receiver.check(), message: "receiver not configured correctly!")
    }
    execute {
        let manager = LostAndFound.borrowShelfManager()
        let shelf = manager.borrowShelf(redeemer: self.receiver.address)!
        assert(shelf != nil, message: "shelf not found")
        shelf.redeem(type: self.catalogEntry.nftType, ticketID: ticketID, receiver: self.receiver)
    }
}`
}
