import { Config } from "../types"

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getRoyaltyRate = (config: Config): string => {
	return `import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}

pub fun main(addr: Address, nftID: UInt64, path: String): UFix64 {
  let p = StoragePath(identifier: path)!
  let c = getAuthAccount(addr).borrow<&{NonFungibleToken.CollectionPublic}>(from: p) ?? panic("collection not found")
  let nft = c.borrowNFT(id: nftID)
  return FlowtyUtils.getRoyaltyRate(nft)
}`
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _getRoyaltyRateCrescendo = (config: Config): string => {
	return `import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}

access(all) fun main(addr: Address, nftID: UInt64, path: String): UFix64 {
  let p = StoragePath(identifier: path)!
  let c = getAuthAccount<auth(Storage) &Account>(addr).storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: p) ?? panic("collection not found")
  let nft = c.borrowNFT(nftID) ?? panic("nft not found")
  return FlowtyUtils.getRoyaltyRate(nft)
}`
}

export const getRoyaltyRate = (config: Config): string => {
	return config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getRoyaltyRateCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _getRoyaltyRate(config)
}
