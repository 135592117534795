import { Config } from "../types"

export const getChildAccountsScript = (config: Config): string => {
	if (config.crescendo) {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return _scriptGetChildAccountsCrescendo(config)
	}
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	return _scriptGetChildAccounts(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _scriptGetChildAccountsCrescendo = (
	config: Config
): string => `import HybridCustody from ${config.contractAddresses.HybridCustody}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

access(all) struct Display {
    access(all) let name: String
    access(all) let description: String
    access(all) let thumbnail: String

    init(_ d: MetadataViews.Display) {
        self.name = d.name
        self.description = d.description
        self.thumbnail = d.thumbnail.uri()
    }
}

access(all) struct ChildAccount {
    access(all) let address: Address
    access(all) let display: Display?

    init(_ a: Address, _ d: MetadataViews.Display?) {
        self.address = a
        self.display = d != nil ? Display(d!) : nil
    }
}

access(all) fun main(parent: Address): {Address: ChildAccount}? {
    let acct = getAuthAccount<auth(Storage, Capabilities) &Account>(parent)
    let m = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    if m == nil {
        return nil
    }
    let manager = m!

    let addresses = manager.getChildAddresses()

    let accounts: {Address: ChildAccount} = {}

    for a in addresses {
        if let child = manager.borrowAccount(addr: a) {
            var display: MetadataViews.Display? = nil
            if let d = child.resolveView(Type<MetadataViews.Display>()) {
                display = d as! MetadataViews.Display
            } else {
								let name = a.toString()
								display = MetadataViews.Display(
										name: name,
										description: "",
										thumbnail: MetadataViews.HTTPFile(
											url: "https://api-ufj4afzoca-uc.a.run.app/6.x/thumbs/png?seed=".concat(name)
										)
								)
            }
            accounts[a] = ChildAccount(a, display)
        }
    }

    return accounts.length > 0 ? accounts : nil
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _scriptGetChildAccounts = (
	config: Config
): string => `import HybridCustody from ${config.contractAddresses.HybridCustody}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

pub struct Display {
    pub let name: String
    pub let description: String
    pub let thumbnail: String

    init(_ d: MetadataViews.Display) {
        self.name = d.name
        self.description = d.description
        self.thumbnail = d.thumbnail.uri()
    }
}

pub struct ChildAccount {
    pub let address: Address
    pub let display: Display?

    init(_ a: Address, _ d: MetadataViews.Display?) {
        self.address = a
        self.display = d != nil ? Display(d!) : nil
    }
}

pub fun main(parent: Address): {Address: ChildAccount}? {
    let acct = getAuthAccount(parent)
    let m = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    if m == nil {
        return nil
    }
    let manager = m!

    let addresses = manager.getChildAddresses()

    let accounts: {Address: ChildAccount} = {}

    for a in addresses {
        if let child = manager.borrowAccount(addr: a) {
            var display: MetadataViews.Display? = nil
            if let d = child.resolveView(Type<MetadataViews.Display>()) {
                display = d as! MetadataViews.Display
            } else {
								let name = a.toString()
								display = MetadataViews.Display(
										name: name,
										description: "",
										thumbnail: MetadataViews.HTTPFile(
											url: "https://api-ufj4afzoca-uc.a.run.app/6.x/thumbs/png?seed=".concat(name)
										)
								)
            }
            accounts[a] = ChildAccount(a, display)
        }
    }

    return accounts.length > 0 ? accounts : nil
}`
