import React from "react"
import { ReactComponent as Checked } from "../../../assets/checkboxChecked.svg"
import { ReactComponent as Unchecked } from "../../../assets/checkboxBase.svg"
import {
	CollectionEditionType,
	CollectionType,
} from "../../../types/FlowtyCreatorHubTypes"
import { useField } from "formik"

type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement>

export type CreatorHubRadioButtonProps = {
	name: string
	value: CollectionType | CollectionEditionType
	disabled?: boolean
}

export const CreatorHubRadioButton: React.FC<
	CreatorHubRadioButtonProps & RadioButtonProps
> = ({ value, name, disabled }) => {
	const [field] = useField({ name, type: "radio", value })
	const isChecked = field.checked
	return (
		<button
			className={`flex items-center gap-2 ${
				disabled ? "pointer-events-none" : ""
			} `}
			onClick={() => field.onChange({ target: { name, value } })}
			onKeyDown={e => {
				if (e.key === "Enter" || e.key === " ") {
					field.onChange({ target: { name, value } })
				}
			}}
			tabIndex={0}
		>
			<input
				type='radio'
				name={name}
				value={value}
				disabled={disabled}
				checked={isChecked}
				onChange={() => field.onChange({ target: { name, value } })}
				className='hidden'
			/>
			<div
				className={`${
					isChecked ? "h-[16px] w-[16px]" : "h-[20px] w-[20px]"
				} transition-all duration-300 flex items-center justify-center`}
			>
				{isChecked ? (
					<Checked className='h-full w-full' />
				) : (
					<Unchecked className='h-full w-full' />
				)}
			</div>
		</button>
	)
}
